<template>
  <div class="help-usermessage">
    <div class="usermessage">
      <div class="usermessage-box">
        <div class="usermessage-title">
          <h3>{{ getString(strings.Global_Edit) }}</h3>
        </div>
        <div class="usermessage-right">
          <!-- <a-icon
          type="close"
          class="icon-remove"
          :style="{ fontSize: '16px' }"
          @click="HideUserMessage"
        /> -->
          <img
            class="close-button-img"
            src="../../assets/img/popupsImg/colse_new.svg"
            @click.stop="HideUserMessage"
          />
        </div>
        <a-form :form="form" :label-col="{ span: 5 }" @submit="handleSubmit">
          <div class="usermessage-content">
            <div class="user-content-userheader">
              <div class="user-avatar-display">
                <div class="user-header">
                  <span>{{ getString(strings.Global_Change_Avatar) }}：</span>
                  <div v-if="userData.isMember" class="user-header-ismenber">
                    <img :src="userImage" />
                  </div>
                  <div v-else class="user-header-menber">
                    <img :src="userImage" />
                  </div>
                </div>
              </div>
              <div class="user-avatar">
                <div
                  class="user-avatar-box"
                  v-for="(item, index) in userAvatar"
                  :key="index"
                  @click="setUserAvatar(item, index)"
                >
                  <img :src="item" alt="" class="user-avatar-default" />
                  <div
                    v-if="index == userAvatarBorder"
                    :class="
                      index == userAvatarBorder ? 'user-avatars' : 'user-avatar'
                    "
                  >
                    <img
                      src="../../../static/img/userData/clink_select.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div class="user-avatar-upload">
                <div @click="UpUserHeader">
                  <img
                    src="../../../static/img/userData/upload_avatar.png"
                    alt=""
                  />
                  <span>{{ getString(strings.Global_Local_Upload) }}</span>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  @change="handleFile"
                  class="hiddenInput"
                />
              </div>
            </div>
            <div class="user-content-usermessage">
              <a-form-item :label="getString(strings.Global_Name)">
                <a-input
                  v-decorator="[
                    'username',
                    {
                      rules: [
                        {
                          message: '不能为空',
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>

              <a-form-item :label="getString(strings.Global_Introduction)">
                <a-textarea
                  v-decorator="[
                    'introduce',
                    {
                      rules: [
                        {
                          message: '不能为空',
                        },
                      ],
                    },
                  ]"
                />
              </a-form-item>
            </div>
          </div>
          <a-form-item class="user-submit">
            <a-button @click="HideUserMessage">{{
              getString(strings.Global_Cancel)
            }}</a-button>
            <a-button type="primary" html-type="submit">
              {{ getString(strings.Global_Ok) }}
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>
<script>
import userMessage from "../../assets/css/usermessage/userMessage.less";
import HttpServerConfig from "../../common/HttpServerConfig";
import strings from "../../common/lang/strings";
import getString from "../../common/lang/language";
import {
  postUserChangeUsername,
  postUserChangeAvatar,
  postUserChangeMotto,
  postUserListSystemAvatars,
  postUserMe,
} from "../../common/netWork/base_api";
import httpImageToPrefix from "../../utils/httpImageToPrefix";
import { postMindmapUploadImage } from "../../common/netWork/mind_map_api";
import { doLogin } from "../../common/netWork/base";

export default {
  inject: ["reload"],
  data() {
    return {
      userMessageBg: false,
      userImage: null,
      userAvatar: null,
      form: this.$form.createForm(this, { name: "coordinated" }),
      userData: "",
      userAvatarBorder: 0,
      strings, //语言包
      userAvatarType: "system", //系统头像system或自定义头像custom
    };
  },
  mounted() {
    this.postUserMe();
  },
  methods: {
    getString(i) {
      return getString(i);
    },
    postUserMe() {
      postUserMe(null, (userData) => {
        if (userData == null || userData == "") {
          return;
        }
        this.userData = userData;
        this.userData.avatar = httpImageToPrefix(userData.avatar);
        this.userImage = this.userData.avatar;
        this.getUserName();
        this.getUserIntroduce();
        this.getUserDataAvatar();
      });
    },
    //获取用户名
    getUserName() {
      this.form.setFieldsValue({
        username: this.userData.username,
      });
    },
    //获取用户简介
    getUserIntroduce() {
      this.form.setFieldsValue({
        introduce: this.userData.motto,
      });
    },
    //获取用户头像
    getUserDataAvatar() {
      postUserListSystemAvatars(null, (res) => {
        for (var i = 0; i < res.systemAvatars.length; i++) {
          res.systemAvatars[i] = HttpServerConfig.getMediaSrc(
            res.systemAvatars[i]
          );
        }
        this.userAvatar = res.systemAvatars;
      });
    },
    //修改头像
    UpUserHeader() {
      this.$el.querySelector(".hiddenInput").click();
    },
    //设置用户头像
    setUserAvatar(url, index) {
      this.userImage = url;
      this.userAvatarBorder = index;
      this.userAvatarType = "system";
    },
    handleFile(e) {
      let $target = e.$target || e.srcElement;
      let file = $target.files[0];
      var reader = new FileReader();
      reader.onload = (data) => {
        let res = data.target || data.srcElement;
        this.userImage = res.result;
      };
      reader.readAsDataURL(file);
      this.userAvatarType = "custom";
    },
    //关闭设置面板
    HideUserMessage() {
      this.$emit("HideUserMessage", false);
    },
    //上传更改
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          //为空补充数据不做请求
          if (values.username == "" && values.introduce == "") {
            this.getUserName();
            this.getUserIntroduce();
          } else if (values.username == "") {
            this.getUserName();
          } else if (values.introduce == "") {
            this.getUserIntroduce();
          }
          if (
            values.username != "" &&
            values.username != this.userData.username
          ) {
            postUserChangeUsername(
              { username: values.username },
              (res) => {
                postUserMe(
                  {},
                  (rs) => {
                    doLogin(rs);

                    this.userData.username = values.username;
                    this.$message.success(
                      getString(strings.Message_Tips_Name_Modification_Success)
                    );
                    this.HideUserMessage();
                  },
                  (e) => {}
                );
              },
              (err) => {
                this.$message.error(err.desc);
              }
            );
          }
          if (
            values.introduce != "" &&
            values.introduce != this.userData.motto
          ) {
            postUserChangeMotto(
              { motto: values.introduce },
              (res) => {
                postUserMe(
                  {},
                  (rs) => {
                    doLogin(rs);

                    this.userData.motto = values.introduce;
                    this.$message.success(
                      getString(
                        strings.Message_Tips_Signature_Modification_Success
                      )
                    );
                    this.HideUserMessage();
                  },
                  (e) => {}
                );
              },
              (err) => {
                this.$message.error(err.desc);
              }
            );
          }
          if (this.userImage != this.userData.avatar) {
            let isBase64 = this.userAvatarType == "custom";
            var that = this;
            function blobData() {
              var arr = that.userImage.split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
              while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
              }
              return new Blob([u8arr], { type: mime });
            }
            var blob = null;
            if (isBase64) {
              blob = blobData();
            }
            let avatar = new Promise((resolve) => {
              if (isBase64) {
                if (blob.type.indexOf("image/") > -1) {
                  postMindmapUploadImage(
                    blob,
                    (res) => {
                      resolve(res);
                    },
                    (error) => {
                      this.$message.error(error.desc);
                    },
                    null,
                    {
                      "X-Type": "avatar",
                      "X-ID": "-",
                    }
                  );
                }
              } else {
                resolve({ imageUrl: this.userImage });
              }
            });
            avatar.then((res) => {
              postUserChangeAvatar(
                { avatar: res.imageUrl },
                (res) => {
                  postUserMe(
                    {},
                    (rs) => {
                      doLogin(rs);
                      this.userData.avatar = httpImageToPrefix(rs.imageUrl);
                      this.$message.success(
                        getString(
                          strings.Message_Tips_Avatar_Modification_Success
                        )
                      );
                      this.HideUserMessage();
                      this.reload();
                    },
                    (e) => {}
                  );
                },
                (err) => {
                  this.$message.error(err.desc);
                }
              );
            });
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.close-button-img {
  width: 16px;
  cursor: pointer;
  opacity: 0.5;
}
.close-button-img:hover {
  opacity: 1;
}
</style>