<template>
  <div class="play-auido-box">
    <div class="play-content" v-if="playAccout == 'START_PLAY_AUDIO'">
      <div class="play-button">
        <img
          src="../../../../static/img/audio/start_online_audio.png"
          alt=""
          @click="playAudio(1)"
        />
      </div>
      <div class="play-time">
        {{ audioPlayTime }}
      </div>
    </div>

    <div class="play-gif" v-if="playAccout == 'SUSPEND_AUDIO'">
      <img src="../../../../static/img/audio/play_gif_audio.gif" alt="" />
    </div>
    <div class="play-content" v-if="playAccout == 'SUSPEND_AUDIO'">
      <div class="play-button">
        <img
          src="../../../../static/img/audio/stop_audio.png"
          alt=""
          @click="playAudio(2)"
        />
      </div>
      <div class="play-time">
        {{ audioPlayTime }}
      </div>
      <div class="play-time-width">
        <a-slider
          :default-value="0"
          :value="suspedPlayTime"
          :tooltipVisible="false"
          :max="playTimeWidth"
          @click="audioPlayBuffered"
          @change="audioPlayBuffered"
        />
      </div>
    </div>

    <div class="play-content" v-if="playAccout == 'STOP_AUDIO'">
      <div class="play-button">
        <img
          src="../../../../static/img/audio/start_online_audio.png"
          alt=""
          @click="playAudio(3)"
        />
      </div>
      <div class="play-time">
        {{ audioPlayTime }}
      </div>
      <div class="play-time-width">
        <a-slider
          :default-value="0"
          :value="suspedPlayTime"
          :tooltipVisible="false"
          :max="playTimeWidth"
          @click="audioPlayBuffered"
          @change="audioPlayBuffered"
        />
      </div>
    </div>
    <audio
      id="playAudio"
      ref="playAudio"
      preload
      :src="audioHttp(httpAudioData)"
    ></audio>
    <!-- ../../../../static/img/audio/chenmoshijin.mp3 -->
  </div>
</template>

<script>
import Colors from "../../../utils/Colors";
import httpImageToPrefix from "../../../utils/httpImageToPrefix";
import dataTimeToFormats from "../../../utils/timestampToTime";
import LoadLocalXJMind from '../../../viewmodel/core/tools/mapfile/LoadLocalXJMind';

export default {
  data() {
    return {
      audioPlayTime: "00:00", //播放时间
      playAccout: "START_PLAY_AUDIO", //播放状态
      playTimeWidth: 0, //播放进度条长度
      playCountTime: null, // 播放总时长
      suspedPlayTime: 0, //提供给进度条的数字
    };
  },
  props: {
    httpAudioData: {
      type: String,
      defualt() {
        return "";
      },
    },
  },
  created() {},
  mounted() {
    this.setHttpAudioTime();
  },
  methods: {
    /**
     * @method setHttpAudioTime (获取线上录音总时长)
     * @load 加载音频
     * @oncanplay 监听事件
     * @duration 获取音频时长
     */
    setHttpAudioTime() {
      let audio = new Audio();
      audio.src = this.audioHttp(this.httpAudioData);
      audio.load();
      audio.oncanplay = () => {
        this.audioPlayTime = dataTimeToFormats.sToHs(
          parseInt(audio.duration.toFixed(0))
        );
      };
    },
    /**
     * @method playAudio 控制音频（播放/暂停）
     * @param {string} key 必传参数（播放状态）
     */
    playAudio(key) {
      switch (key) {
        case 1:
          this.playAccout = "SUSPEND_AUDIO";
          this.playOrSuspedAudio();
          break;
        case 2:
          this.playAccout = "STOP_AUDIO";
          this.playOrSuspedAudio();
          break;
        case 3:
          this.playAccout = "SUSPEND_AUDIO";
          this.playOrSuspedAudio();
          break;
        default:
          break;
      }
    },
    /**
     * @method playOrSuspedAudio 播放或暂停音频
     * @playCountTime 每秒获取一次播放时长  
     * @currentTime 当前播放时长
     */
    playOrSuspedAudio() {
      let audio = document.getElementById("playAudio");
      if (this.playAccout == "SUSPEND_AUDIO") {
        audio.play();
        //播放时长
        this.playTimeWidth = parseInt(audio.duration.toFixed(0));
        this.playCountTime = setInterval(() => {
          this.audioPlayTime = dataTimeToFormats.sToHs(
            parseInt(audio.currentTime.toFixed(0))
          );
          this.suspedPlayTime = parseInt(audio.currentTime.toFixed(0));
          //播放时长等于总时长暂停播放
          if (
            parseInt(audio.currentTime.toFixed(0)) ==
            parseInt(audio.duration.toFixed(0))
          ) {
            this.playAccout = "STOP_AUDIO";
            clearTimeout(this.playCountTime);
          }
        }, 1000);
      } else {
        audio.pause();
        clearTimeout(this.playCountTime);
      }
    },
    /**
     * @method audioPlayBuffered 更改音频播放进度
     * @STOP_AUDIO 暂停播放
     * @SUSPEND_AUDIO 开始播放
     * @param {number} value 传入的播放时长
     */
    audioPlayBuffered(value) {
      this.playAccout = "STOP_AUDIO";
      clearTimeout(this.playCountTime);
      let audio = document.getElementById("playAudio");
      this.audioPlayTime = dataTimeToFormats.sToHs(value);
      this.suspedPlayTime = value;
      audio.currentTime = value;
      this.playAccout = "SUSPEND_AUDIO";
      this.playOrSuspedAudio();
    },
    //处理http audio
    audioHttp(httpAudioData) {
      let audioUrl = LoadLocalXJMind.getLocalFilePath(httpImageToPrefix(httpAudioData));
      return audioUrl;
    },
    //根据背景色调整颜色
    //功能按钮颜色
    checkAddIconColor(btnColor) {
      let isDarkColor = Colors.isLightColor(btnColor);
      let color = "";
      let boxShadow = "";
      let Textcolor = "";
      if (isDarkColor) {
        color = "rgba(255,255,255,0.5)";
        boxShadow = "0px 3px 6px rgba(255,255,255,0.5)";
        Textcolor = "#666";
      } else {
        Textcolor = "#fff";
        color = "rgba(0,0,0,0.3)";
        boxShadow = "0px 3px 6px rgba(0,0,0,0.5)";
      }

      let nameColor = document.getElementsByClassName("play-auido-box");
      let playTime = document.getElementsByClassName("play-time");
      nameColor[0].style.background = color;
      nameColor[0].style.boxShadow = boxShadow;
      playTime[0].style.color = Textcolor;
    },
  },
  watch: {
    "$store.state.saveImageBgcolor"(newBg) {
      this.checkAddIconColor(newBg);
    },
    httpAudioData(audioTime) {
      if (audioTime != null) {
        this.setHttpAudioTime();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.play-auido-box {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  box-shadow: 0px 3px 6px #d8d8d8;
  background-color: #ffffff;
  min-width: 80px;
  min-height: 40px;
  position: relative;
  .play-gif {
    position: absolute;
    top: -21px;
    left: 110px;
    img {
      width: 47%;
    }
  }
  .play-content {
    margin: 8px 9px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .play-button {
      img {
        cursor: pointer;
      }
    }
    .play-time {
      font-size: 12px;
      height: 14px;
      font-family: Noto Sans SC;
      font-weight: 400;
      line-height: 17px;
      color: #ec705a;
      opacity: 1;
      margin-left: 10px;
    }
    .play-time-width {
      margin-left: 6px;
      width: 98px;
    }
  }
}
@media screen and(min-width:250px) and (max-width: 1400px) {
  .play-auido-box {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    box-shadow: 0px 3px 6px #d8d8d8;
    background-color: #ffffff;
    min-width: 80px;
    min-height: 10px;
    position: relative;
    .play-gif {
      position: absolute;
      top: -21px;
      left: 110px;
      img {
        width: 47%;
      }
    }
    .play-content {
      margin: 5px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .play-button {
        img {
          cursor: pointer;
        }
      }
      .play-time {
        font-size: 12px;
        height: 14px;
        font-family: Noto Sans SC;
        font-weight: 400;
        line-height: 17px;
        color: #ec705a;
        opacity: 1;
        margin-left: 10px;
      }
      .play-time-width {
        margin-left: 6px;
        width: 98px;
      }
    }
  }
}
</style>