const numberOfMapsTourist = 1; // 未登录导图数量超过1个弹登录
const numberOfMaps = 2; // 已登录免费用户导图数量超过2个，则每次打开都弹购买弹窗
const menuListType = { // 导出按钮类型
    exportWord: "export_Word",
    exportTxt: "export_TXT",
    exportSave: "export_save",
    exportSaveAs: "export_save_as",
    shareLink: "share_link",
    sharedGroup: "shared_Group",
}
const newGuideNodeLocation = { //新手引导节点坐标
    subtopics: {
        left: 500,
        top: 5
    }
}
const memberExclusive = { //会员专享

}
const configurationObj = {
    numberOfMapsTourist,
    numberOfMaps,
    menuListType,
    newGuideNodeLocation
}
export default configurationObj