
import Config from "../../../core/core/calcule/Config"
import MindElementCalculation from "../../../core/core/calcule/elementCalculation/MindElementCalculation"
import Colors from "../../../utils/Colors"
import UiUtil from "../../../utils/UiUtil"
import IdGenerator from "../base/IdGenerator"
import CGPoint from "../base/basedata/CGPoint"
import MindElementShapeType from "../../datatype/MindElementShapeType"
import MindElementType from "../../datatype/MindElementType"
import NodeLayoutType from "../../datatype/NodeLayoutType"
import FormPointData from "../../mindelementdata/mindcontent/FormPointData"
import LineElementContent from "../../mindelementdata/mindcontent/LineElementContent"
import BaseLayout from "./BaseLayout"
import Point from "../base/Point"
import LineOrientation from "../../datatype/LineOrientation"
import EncircleNodesPointsCalculation from "../../../core/core/calcule/EncircleNodesPointsCalculation"
import LineColorMode from "../base/LineColorMode"
import LineLayout from "../../datatype/LineLayout"
import MindElementAlignmentType from "../../datatype/MindElementAlignmentType"
import BaseMindElementDataCalculation from "../../../core/core/calcule/elementCalculation/BaseMindElementDataCalculation"

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/8
 * Copyright(c) 2020 mindyushu.com
 */

class FormHorizontalLayut extends BaseLayout {

    constructor() {
        super();
        this.maxLevel = 0;
        this.lineWidth = 1;
        this.lineDPWidth = 1;
    }

    initConfig() {
        super.initConfig();
        this.maxLevel = 0;

        this.NodeHorizontalSpacee = this.addSubjectSpcaeHorizontal(Config.NodeVerticalSpacee);
        this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee);
        this.setMaxLevelAndLineHidden(this.rootTreeNode);
    }

    setMaxLevelAndLineHidden(node) {
        if (node == null || node.isEmpty()) {
            return;
        }
        let lineData = this.getLineData(node);
        if (!lineData.isEmpty() && !this.isRootNode(node)) {
            lineData.isHidden = true;
        }
        if (node.value.layout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            return;
        }
        this.maxLevel = Math.max(this.maxLevel, node.value.level);
        for (let index = 0; index < node.children.length; index++) {
            this.setMaxLevelAndLineHidden(node.children[index]);
        }
    }

    onLayout(isChange) {
        if (this.rootTreeNode == null) {
            return;
        }
        let formLine = this.getFormLineData();
        if (!formLine.isEmpty()) {
            this.lineDPWidth = formLine.lineContent.lineWidth;
        }
        this.lineWidth = this.UiUtil.dip2px(this.lineDPWidth);
        this.setElementsPoint(isChange);
        this.setGeneralizationElementsPoint(isChange);
        // this.setEncirclePoint();
        // this.setFloatExplainPoint(isChange);
    }

    setElementsPoint(isChange) {
        if ((!isChange || this.rootTreeNode.value.y == -1 || this.rootTreeNode.value.x == -1) && this.rootTreeNode.value.type == MindElementType.MAIN_SUBJECT) {
            let left = (Config.Mind_Width - new UiUtil().getScreenWidth()) / 2;
            let top = (Config.Mind_Height - new UiUtil().getScreenHeight()) / 2;
            this.rootTreeNode.value.y = (new UiUtil().getScreenHeight() - this.rootTreeNode.value.height) / 2 + top;
            this.rootTreeNode.value.x = 20 + left;
        }
        this.initConfig();
        this.setElementLine(this.rootTreeNode, isChange);
        this.setNodeElementsPoint(this.rootTreeNode, isChange);
        this.setNodeSize();
        this.hideRootFormLine();
        this.setFormLine();
        // this.setEncirclePoint();
    }

    setFormLine() {
        let formLine = this.getFormLineData();
        if (formLine.isEmpty()) {
            formLine.id = IdGenerator.shared.getId();
            formLine.type = MindElementType.FORM_LINE;
            this.lineMindElementDataDict.put(formLine.id, formLine);
            formLine.lineContent = new LineElementContent(new CGPoint(), new CGPoint(), 0x333333, this.rootTreeNode.value.id);
            formLine.lineContent.lineWidth = this.lineWidth;
            formLine.lineContent.color = this.getGolbalLineColor();
        } else {
            formLine.lineContent.color = this.getGolbalLineColor();
        }
        formLine.isHidden = false;
        formLine.x = this.rootTreeNode.value.x - this.lineWidth/2;
        formLine.y = this.rootTreeNode.value.y - this.lineWidth/2;
        formLine.width = this.rootTreeNode.value.width + this.lineWidth;
        if (this.rootTreeNode.children.length > 0) {
            formLine.height = this.getTreeHeight(this.rootTreeNode) + this.lineWidth;
        } else {
            formLine.height = this.rootTreeNode.value.height + this.lineWidth;
        }
        formLine.lineContent.formPoint = new Array();
        formLine.lineContent.formPoint.push(new FormPointData(0, 0, formLine.width, 0));
        formLine.lineContent.formPoint.push(new FormPointData(formLine.width, 0, formLine.width, formLine.height));
        formLine.lineContent.formPoint.push(new FormPointData(formLine.width, formLine.height, 0, formLine.height));
        formLine.lineContent.formPoint.push(new FormPointData(0, formLine.height, 0, 0));

        this.setHorizontalLinePoint(this.rootTreeNode);
        this.setVerticalLinePoint(this.rootTreeNode);
    }

    setHorizontalLinePoint(node) {
        let formLine = this.getFormLineData();
        if (formLine.isEmpty() || this.maxLevel == 0 || node.value.isHidden) {
            if (this.isRootNode(node) && this.maxLevel == 0) {
                formLine.lineContent.formPoint.push(
                    new FormPointData(
                        node.value.x - formLine.x,
                        node.value.y - formLine.y + node.value.height + this.lineWidth / 2,
                        node.value.x + node.value.width - formLine.x + this.lineWidth,
                        node.value.y - formLine.y + node.value.height + this.lineWidth / 2));
            }
            return;
        }

        if (this.isRootNode(node)) {
            formLine.lineContent.formPoint.push(
                new FormPointData(
                    node.value.x - formLine.x,
                    node.value.y - formLine.y + node.value.height + this.lineWidth / 2,
                    node.value.x + node.value.width - formLine.x + this.lineWidth,
                    node.value.y - formLine.y + node.value.height + this.lineWidth / 2));

            if (node.children.length > 0) {
                node.children.forEach(item => {
                    this.setHorizontalLinePoint(item);
                });
            }
        } else {
            if (node.value.layout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                return;
            }
            let parentNode = this.getNodeById(node.value.parentNodeId);
            if (parentNode.isEmpty()) {
                return;
            }
            formLine.lineContent.formPoint.push(
                    new FormPointData(node.value.x - formLine.x - this.lineWidth,
                            node.value.y - formLine.y - this.lineWidth / 2,
                            node.value.x + node.value.width - formLine.x + this.lineWidth,
                            node.value.y - formLine.y - this.lineWidth / 2));
            formLine.lineContent.formPoint.push(
                new FormPointData(
                    node.value.x - formLine.x - this.lineWidth,
                    node.value.y - formLine.y + node.value.height + this.lineWidth / 2,
                    node.value.x + node.value.width - formLine.x + this.lineWidth,
                    node.value.y - formLine.y + node.value.height + this.lineWidth / 2));
            node.children.forEach(item => {
                this.setHorizontalLinePoint(item);
            });
        }
    }

    setVerticalLinePoint(node) {

        let formLine = this.getFormLineData();
        if (formLine.isEmpty() || node.value.isHidden) {
            return;
        }
        if (this.isRootNode(node)) {
            if (node.children.length != 0) {
                node.children.forEach(item => {
                    this.setVerticalLinePoint(item);
                });
            }
        } else {
            let parentNode = this.getNodeById(node.value.parentNodeId);
            if (parentNode.isEmpty()) {
                return;
            }
            if (node.value.layout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                let leftNodeWidth = this.getNodeLeft(node);               
                let width = this.getTreeWidth(node)
                if (node.value.layout != NodeLayoutType.LAYOUT_FORM) {
                    width += this.NodeHorizontalSpacee * 2
                }
                formLine.lineContent.formPoint.push(new FormPointData(
                            parentNode.value.x - formLine.x + leftNodeWidth - this.lineWidth/2, 
                            parentNode.value.y + parentNode.value.height - formLine.y + this.lineWidth,
                            parentNode.value.x - formLine.x + leftNodeWidth - this.lineWidth/2, 
                            formLine.height + this.lineWidth/2));

                formLine.lineContent.formPoint.push(new FormPointData(
                    parentNode.value.x - formLine.x + leftNodeWidth + width - this.lineWidth/2, 
                    parentNode.value.y + parentNode.value.height - formLine.y + this.lineWidth,
                    parentNode.value.x - formLine.x + leftNodeWidth + width - this.lineWidth/2, 
                    formLine.height + this.lineWidth/2));

                return;
            }
            let siblingsNodeDatas = this.getSiblingsMindElementDataById(node.value.id);
            let allSiblingsNodesPosition = this.getNodeInNodesPosition(siblingsNodeDatas, node.value);
            let leftNodeWidth = this.getNodeLeft(node);
            if (this.isRootNode(parentNode)) {
                formLine.lineContent.formPoint.push(new FormPointData(
                    parentNode.value.x - formLine.x + leftNodeWidth - this.lineWidth/2, 
                    parentNode.value.y + parentNode.value.height - formLine.y + this.lineWidth,
                    parentNode.value.x - formLine.x + leftNodeWidth - this.lineWidth/2, 
                    parentNode.value.y + parentNode.value.height - formLine.y + node.value.height + this.lineWidth));

                formLine.lineContent.formPoint.push(new FormPointData(
                    leftNodeWidth + node.value.width + this.lineWidth, 
                    parentNode.value.height + this.lineWidth/2,
                    leftNodeWidth + node.value.width + this.lineWidth, 
                    parentNode.value.height + node.value.height + this.lineWidth + this.lineWidth/2));
            } else if (parentNode.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                if (node.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                        formLine.lineContent.formPoint.push(new FormPointData(
                            parentNode.value.x - formLine.x + leftNodeWidth - this.lineWidth/2, 
                            parentNode.value.y + parentNode.value.height - formLine.y + this.lineWidth,
                            parentNode.value.x - formLine.x + leftNodeWidth - this.lineWidth/2, 
                            parentNode.value.y + parentNode.value.height - formLine.y + node.value.height + this.lineWidth));
                    formLine.lineContent.formPoint.push(new FormPointData(
                        parentNode.value.x - formLine.x + leftNodeWidth + node.value.width + this.lineWidth/2, 
                        parentNode.value.y + parentNode.value.height - formLine.y + this.lineWidth,
                        parentNode.value.x - formLine.x + leftNodeWidth + node.value.width + this.lineWidth/2, 
                        parentNode.value.y + parentNode.value.height - formLine.y + node.value.height + this.lineWidth));
                } else {
                }

            }
            if (node.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                node.children.forEach(item => {
                    this.setVerticalLinePoint(item);
                });
            }
        }
    }

    setNodeSize() {
        if (this.rootTreeNode.isEmpty() || this.rootTreeNode.children.isEmpty() ||
            this.rootTreeNode.children[0].value.isHidden) {
            return;
        }
        
        let maxWidth = this.getTreeWidth(this.rootTreeNode);
        if (this.rootTreeNode.value.width < maxWidth) {
            new BaseMindElementDataCalculation().changeDataWidth(this.rootTreeNode.value, maxWidth, (maxWidth - this.rootTreeNode.value.width));
        }
        // let maxLevelList = this.getSameLevelNodeByLevel(this.rootTreeNode, this.maxLevel);
        // if (maxLevelList.length == 0) {
        //     return;
        // }
    }

    setMindElementDataPointAndSize(data, customWidth, width) {
        let newAddWidth = customWidth - width;
        switch (data.alignmentType) {
            case MindElementAlignmentType.RIGHT:
                newAddWidth = (customWidth - width) * 2;
                break;
            case MindElementAlignmentType.MIDDLE:
            case MindElementAlignmentType.UNKNOWN:
                newAddWidth = (customWidth - width);
                break;
            case MindElementAlignmentType.LEFT:
                newAddWidth = 0;
                break;
            default:
                newAddWidth = (customWidth - width);
        }
        data.width = customWidth;
        if (data.imageContent != null) {
            data.imageContent.x = data.imageContent.x + newAddWidth / 2;
        }
        if (data.textContent != null) {
            data.textContent.x = data.textContent.x + newAddWidth / 2;
        }
        if (data.generalizationContent != null) {
            data.generalizationContent.x = data.generalizationContent.x + newAddWidth / 2;
        }
        if (data.iconElementContents != null) {
            let iconCount = data.iconElementContents.length;
            if (iconCount > 0) {
                for (let index = 0; index < iconCount; index++) {
                    data.iconElementContents[index].x = data.iconElementContents[index].x + newAddWidth / 2;
                }
            }
        }
        if (data.linkElementContent != null) {
            data.linkElementContent.x = data.linkElementContent.x + newAddWidth / 2;
        }
        if (data.remarksElementContent != null) {
            data.remarksElementContent.x = data.remarksElementContent.x + newAddWidth / 2;
        }
        if (data.connectMapElementContent != null) {
            data.connectMapElementContent.x = data.connectMapElementContent.x + newAddWidth / 2;
        }

        if (data.mindElementShape == MindElementShapeType.Circular) {
            newAddWidth = customWidth - data.height;
            data.height = customWidth;

            if (data.imageContent != null) {
                data.imageContent.y = data.imageContent.y + newAddWidth / 2;
            }
            if (data.textContent != null) {
                data.textContent.y = data.textContent.y + newAddWidth / 2;
            }
            if (data.generalizationContent != null) {
                data.generalizationContent.y = data.generalizationContent.y + newAddWidth / 2;
            }
            if (data.iconElementContents != null) {
                let iconCount = data.iconElementContents.length
                if (iconCount > 0) {
                    for (let index = 0; index < iconCount; index++) {
                        data.iconElementContents[index].y = data.iconElementContents[index].y + newAddWidth / 2;
                    }
                }
            }
            if (data.linkElementContent != null) {
                data.linkElementContent.y = data.linkElementContent.y + newAddWidth / 2;
            }
            if (data.remarksElementContent != null) {
                data.remarksElementContent.y = data.remarksElementContent.y + newAddWidth / 2;
            }
            if (data.connectMapElementContent != null) {
                data.connectMapElementContent.y = data.connectMapElementContent.y + newAddWidth / 2;
            }
        }
    }

    setEncirclePoint() {

    }

    setNodeRightLayouElementsPoint(node, isChange) {
        this.setDataRightLayouPoint(node.value, isChange);
        if (node.children.length == 0) {
            return;
        }
        for (var index = 0; index < node.children.length; index++) {
            this.setNodeRightLayouElementsPoint(node.children[index], isChange);
        }
    }

    setNodeElementsLinePoint(node, isChange) {
        this.setElementLine(node, isChange);
        if (node.children.length == 0) {
            return;
        }
        for (let index = 0; index < node.children.length; index++) {
            this.setNodeElementsLinePoint(node.children[index], isChange);
        }
    }

    setNodeElementsPoint(node, isChange) {
        this.setDataPoint(node.value, isChange);
        if (node.children.length == 0) {
            return;
        }
        if (this.isRootNode(node)) {
            for (let index = 0; index < node.children.length; index++) {
                this.setNodeElementsPoint(node.children[index], isChange);
            }
        } else if (node.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            for (let index = 0; index < node.children.length; index++) {
                this.setNodeElementsPoint(node.children[index], isChange);
            }
        } else if (node.children.length > 0 && !node.children[0].value.isHidden) {
            this.resetNodeLayout(node);
        }
    }

    setDataPoint(data, isChange) {
        let node = this.getNodeById(data.id);
        if (node.isEmpty() || node.value.isHidden) {
            return;
        }

        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        this.pushCurrentLayoutNode(node);

        if (data.id == this.rootTreeNode.value.id) {
            return;
        } else {
            if (data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                this.setNodeHeight(node);
                this.setNodeWidth(node);
                let leftNodeWidth = this.getNodeLeft(node);
                data.y = parentNodeData.y + parentNodeData.height + this.lineWidth;
                data.x = parentNodeData.x + leftNodeWidth;
            } else {
                let nodeWidth = this.getTreeWidth(node);
                if (data.layout == NodeLayoutType.LAYOUT_RIGHT ||
                        data.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                    let layout = this.getNodeLayout(node);
                    let nodeTopHeight = layout.getTreeTopHeight(node);

                    if (this.isRootNode(parentNode)) {
                        if (this.isUnderline(node.value)) {
                            data.y = parentNodeData.y + parentNodeData.height + this.lineWidth + nodeTopHeight - data.height + this.NodeVerticalSpacee;
                        } else {
                            data.y = parentNodeData.y + parentNodeData.height + this.lineWidth + nodeTopHeight - data.height/2 + this.NodeVerticalSpacee;
                        }
                        let leftNodeWidth = this.getNodeLeft(node);
                        data.x = parentNodeData.x + leftNodeWidth + this.NodeHorizontalSpacee;
                    } else {
                        if (this.isUnderline(node.value)) {
                            data.y = parentNodeData.y + parentNodeData.height + nodeTopHeight - data.height + this.NodeVerticalSpacee;
                        } else {
                            data.y = parentNodeData.y + parentNodeData.height + nodeTopHeight - data.height/2 + this.NodeVerticalSpacee;
                        }
                        let leftNodeWidth = this.getNodeLeft(node);
                        data.x = parentNodeData.x + leftNodeWidth + this.NodeHorizontalSpacee;
                    }
                } else if (data.layout == NodeLayoutType.LAYOUT_BOTTOM) {
                    let layout = this.getNodeLayout(node);
                    let nodeLeftWidth = layout.getTreeLeftWidth(node);
                    let leftNodeWidth = this.getNodeLeft(node);

                    if (this.isRootNode(parentNode)) {
                        data.y = parentNodeData.y + parentNodeData.height + this.lineWidth + this.NodeVerticalSpacee;
                        data.x = parentNodeData.x + leftNodeWidth + nodeLeftWidth - data.width/2 + this.NodeHorizontalSpacee;
                    } else {
                        data.y = parentNodeData.y + parentNodeData.height + this.NodeVerticalSpacee;
                        data.x = parentNodeData.x + leftNodeWidth + nodeLeftWidth - data.width/2 + this.NodeHorizontalSpacee;
                    }
                } else if (data.layout == NodeLayoutType.LAYOUT_LEFT ||
                        data.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
                    let layout = this.getNodeLayout(node);
                    let nodeTopHeight = layout.getTreeTopHeight(node);
                    let leftNodeWidth = this.getNodeLeft(node);
                    if (this.isRootNode(parentNode)) {
                        if (this.isUnderline(node.value)) {
                            data.y = parentNodeData.y + parentNodeData.height + nodeTopHeight - data.height + this.NodeVerticalSpacee;
                        } else {
                            data.y = parentNodeData.y + parentNodeData.height + nodeTopHeight - data.height/2 + this.NodeVerticalSpacee;
                        }

                        data.x = parentNodeData.x + leftNodeWidth + this.NodeHorizontalSpacee + nodeWidth - data.width;                        
                    } else {
                        if (this.isUnderline(node.value)) {
                            data.y = parentNodeData.y + parentNodeData.height + nodeTopHeight - data.height + this.NodeVerticalSpacee;
                        } else {
                            data.y = parentNodeData.y + parentNodeData.height + nodeTopHeight - data.height/2 + this.NodeVerticalSpacee;
                        }
                        data.x = parentNodeData.x + leftNodeWidth + this.NodeHorizontalSpacee + nodeWidth - data.width;                        
                    }
                } else if (data.layout == NodeLayoutType.LAYOUT_TOP) {
                    let layout = this.getNodeLayout(node);
                    let nodeLeftWidth = layout.getTreeLeftWidth(node);
                    let nodeHeight = this.getTreeHeight(node);
                    let leftNodeWidth = this.getNodeLeft(node);
                    if (this.isRootNode(parentNode)) {
                        data.y = parentNodeData.y + parentNodeData.height + nodeHeight - data.height + this.NodeVerticalSpacee;

                        data.x = parentNodeData.x + leftNodeWidth + nodeLeftWidth - data.width/2 + this.NodeHorizontalSpacee;
                    } else {
                        data.y = parentNodeData.y + parentNodeData.height + nodeHeight - data.height + this.NodeVerticalSpacee;

                        data.x = parentNodeData.x + leftNodeWidth + nodeLeftWidth - data.width/2 + this.NodeHorizontalSpacee;
                    }
                } else if (data.layout == NodeLayoutType.LAYOUT_TREE_RIGHT) {
                    let leftNodeWidth = this.getNodeLeft(node);
                    if (this.isRootNode(parentNode)) {
                        data.y = parentNodeData.y + parentNodeData.height + this.NodeVerticalSpacee;

                        data.x = parentNodeData.x + leftNodeWidth + this.NodeHorizontalSpacee;
                    } else {
                        data.y = parentNodeData.y + parentNodeData.height + this.NodeVerticalSpacee;
                        data.x = parentNodeData.x + leftNodeWidth + this.NodeHorizontalSpacee;
                    }
                } else if (data.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) {
                    let nodeHeight = this.getTreeHeight(node);
                    let leftNodeWidth = this.getNodeLeft(node);
                    if (this.isRootNode(parentNode)) {
                        data.y = parentNodeData.y + parentNodeData.height + nodeHeight - data.height + this.NodeVerticalSpacee;
                        data.x = parentNodeData.x + leftNodeWidth + this.NodeHorizontalSpacee;
                    } else {
                        data.y = parentNodeData.y + parentNodeData.height + nodeHeight - data.height + this.NodeVerticalSpacee;
                        data.x = parentNodeData.x + leftNodeWidth + this.lineWidth + this.NodeHorizontalSpacee;
                    }
                } else if (data.layout == NodeLayoutType.LAYOUT_TREE_LEFT) {
                    let leftNodeWidth = this.getNodeLeft(node);
                    if (this.isRootNode(parentNode)) {
                        data.y = parentNodeData.y + parentNodeData.height + this.lineWidth + this.NodeVerticalSpacee;
                        data.x = parentNodeData.x + leftNodeWidth + this.NodeHorizontalSpacee + (nodeWidth - data.width);
                    } else {
                        data.y = parentNodeData.y + parentNodeData.height + this.NodeVerticalSpacee;
                        data.x = parentNodeData.x + leftNodeWidth + (nodeWidth - data.width) + this.NodeHorizontalSpacee;
                    }
                } else if (data.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                    let nodeHeight = this.getTreeHeight(node);
                    let leftNodeWidth = this.getNodeLeft(node);
                    if (this.isRootNode(parentNode)) {
                        data.y = parentNodeData.y + parentNodeData.height + nodeHeight - data.height + this.lineWidth + this.NodeVerticalSpacee;
                        data.x = parentNodeData.x + leftNodeWidth + this.NodeHorizontalSpacee + (nodeWidth - data.width);
                    } else {
                        data.y = parentNodeData.y + parentNodeData.height + nodeHeight - data.height + this.NodeVerticalSpacee;
                        data.x = parentNodeData.x + leftNodeWidth + (nodeWidth - data.width) + this.NodeHorizontalSpacee;
                    }
                } else if (data.layout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT) {
                    let layout = this.getNodeLayout(node);
                    let nodeLeftWidth = layout.getLeftTreeWidth(node);
                    let leftNodeWidth = this.getNodeLeft(node);
                    if (this.isRootNode(parentNode)) {
                        data.y = parentNodeData.y + parentNodeData.height + this.lineWidth + this.NodeVerticalSpacee;
                        data.x = parentNodeData.x + leftNodeWidth + this.NodeHorizontalSpacee + nodeLeftWidth - data.width;
                    } else {
                        data.y = parentNodeData.y + parentNodeData.height + this.NodeVerticalSpacee;
                        data.x = parentNodeData.x + leftNodeWidth + this.NodeHorizontalSpacee + nodeLeftWidth - data.width;                        
                    }
                } else if (data.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT) {
                    let nodeHeight = this.getTreeHeight(node);
                    let layout = this.getNodeLayout(node);
                    let nodeLeftWidth = layout.getLeftTreeWidth(node);
                    let leftNodeWidth = this.getNodeLeft(node);
                    if (this.isRootNode(parentNode)) {
                        data.y = parentNodeData.y + parentNodeData.height + nodeHeight - data.height + this.lineWidth + this.NodeVerticalSpacee;
                        data.x = parentNodeData.x + leftNodeWidth + this.NodeHorizontalSpacee + (nodeLeftWidth - data.width);
                    } else {
                        data.y = parentNodeData.y + parentNodeData.height + nodeHeight - data.height + this.NodeVerticalSpacee;
                        data.x = parentNodeData.x + leftNodeWidth + (nodeLeftWidth - data.width) + this.NodeHorizontalSpacee;
                    }
                } else if (data.layout == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                    let layout = this.getNodeLayout(node);
                    let nodeTopHeight = layout.getTreeTopHeight(node);
                    let leftNodeWidth = this.getNodeLeft(node);
                    if (this.isRootNode(parentNode)) {
                        if (this.isUnderline(node.value)) {
                            data.y = parentNodeData.y + parentNodeData.height + nodeTopHeight - data.height + this.NodeVerticalSpacee;
                        } else {
                            data.y = parentNodeData.y + parentNodeData.height + nodeTopHeight - data.height/2 + this.NodeVerticalSpacee;
                        }
                        data.x = parentNodeData.x + leftNodeWidth + this.NodeHorizontalSpacee;
                    } else {
                        if (this.isUnderline(node.value)) {
                            data.y = parentNodeData.y + parentNodeData.height + nodeTopHeight - data.height + this.NodeVerticalSpacee;
                        } else {
                            data.y = parentNodeData.y + parentNodeData.height + nodeTopHeight - data.height/2 + this.NodeVerticalSpacee;
                        }
                        data.x = parentNodeData.x + leftNodeWidth + this.NodeHorizontalSpacee;
                    }
                } else if (data.layout == NodeLayoutType.LAYOUT_FISH_LEFT) {
                    let layout = this.getNodeLayout(node);
                    let nodeTopHeight = layout.getTreeTopHeight(node);
                    let leftNodeWidth = this.getNodeLeft(node);
                    if (this.isRootNode(parentNode)) {
                        if (this.isUnderline(node.value)) {
                            data.y = parentNodeData.y + parentNodeData.height + nodeTopHeight - data.height + this.NodeVerticalSpacee;
                        } else {
                            data.y = parentNodeData.y + parentNodeData.height + nodeTopHeight - data.height/2 + this.NodeVerticalSpacee;
                        }
                        data.x = parentNodeData.x + leftNodeWidth + this.NodeHorizontalSpacee + nodeWidth - data.width;
                    } else {
                        if (this.isUnderline(node.value)) {
                            data.y = parentNodeData.y + parentNodeData.height + nodeTopHeight - data.height + this.NodeVerticalSpacee;
                        } else {
                            data.y = parentNodeData.y + parentNodeData.height + nodeTopHeight - data.height/2 + this.NodeVerticalSpacee;
                        }
                        data.x = parentNodeData.x + leftNodeWidth + this.NodeHorizontalSpacee + nodeWidth - data.width;
                    }
                } else if (data.layout == NodeLayoutType.LAYOUT_FORM) {
                    let leftNodeWidth = this.getNodeLeft(node);
                    data.y = parentNodeData.y + parentNodeData.height + this.lineWidth;
                    data.x = parentNodeData.x + leftNodeWidth;
                } else {
                    let layout = this.getNodeLayout(node);
                    let nodeTopHeight = layout.getTreeTopHeight(node);
                    let leftNodeWidth = this.getNodeLeft(node);
                    if (this.isRootNode(parentNode)) {
                        if (this.isUnderline(node.value)) {
                            data.y = parentNodeData.y + parentNodeData.height + nodeTopHeight - data.height;
                        } else {
                            data.y = parentNodeData.y + parentNodeData.height + nodeTopHeight - data.height/2;
                        }
                        data.x = parentNodeData.x + leftNodeWidth + this.NodeHorizontalSpacee;
                    } else {
                        if (this.isUnderline(node.value)) {
                            data.y = parentNodeData.y + parentNodeData.height + nodeTopHeight - data.height;
                        } else {
                            data.y = parentNodeData.y + parentNodeData.height + nodeTopHeight - data.height/2;
                        }
                        data.x = parentNodeData.x + leftNodeWidth;
                    }
                }
            }
        }
        this.setElementLine(node, isChange);
    }

    setDataRightLayouPoint(data, isChange) {
        let node = this.getNodeById(data.id);
        if (node.isEmpty() || node.value.isHidden) {
            return;
        }
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        let siblingsNodeDatas = this.getSiblingsMindElementDataById(data.id);
        data.layout = NodeLayoutType.LAYOUT_RIGHT;
        MindElementCalculation.set(data).calcule();
        if (data.id == this.rootTreeNode.value.id) {
            return;
        } else {
            let allSiblingsNodesHeight = this.getSiblingsNodesHeight(siblingsNodeDatas);
            let parentNodeY = 0;

            if (((parentNodeData.type == MindElementType.SUBJECT ||
                parentNodeData.type == MindElementType.SON_SUBJECT ||
                parentNodeData.type == MindElementType.CONTENT_GENERALIZATION) &&
                !this.isUnderline(parentNodeData)) ||
                parentNodeData.type == MindElementType.MAIN_SUBJECT) {
                parentNodeY = parentNodeData.y + parentNodeData.height / 2;
            } else {
                parentNodeY = parentNodeData.y + parentNodeData.height;
            }
            let siblingsNodeDatasCount = siblingsNodeDatas.length;
            let siblingsNodeTopHeight = this.getTreeTopHeight(this.getNodeById(siblingsNodeDatas[0].id));
            if (siblingsNodeDatasCount == 1) {
                if ((node.value.type == MindElementType.SUBJECT ||
                    node.value.type == MindElementType.SON_SUBJECT ||
                    node.value.type == MindElementType.CONTENT_GENERALIZATION) &&
                    !this.isUnderline(node.value)) {
                    data.y = parentNodeY - node.value.height / 2;

                } else {
                    data.y = parentNodeY - node.value.height;

                }
            } else {
                let siblingsNodeBottomHeight = siblingsNodeDatasCount > 1 ? this.getTreeBottomHeight(this.getNodeById(siblingsNodeDatas[siblingsNodeDatasCount - 1].id)) : 0;
                let nodeY = parentNodeY - (allSiblingsNodesHeight - siblingsNodeTopHeight - siblingsNodeBottomHeight) / 2;

                for (let index = 0; index < siblingsNodeDatasCount; index++) {
                    let cell = siblingsNodeDatas[index];
                    if (cell.id == data.id) {
                        if (index == 0) {
                            if ((cell.type == MindElementType.SUBJECT ||
                                cell.type == MindElementType.SON_SUBJECT ||
                                cell.type == MindElementType.MAIN_SUBJECT ||
                                cell.type == MindElementType.CONTENT_GENERALIZATION) && !this.isUnderline(cell)) {
                                nodeY = nodeY - node.value.height / 2;
                            } else {
                                nodeY = nodeY - node.value.height;
                            }
                        } else {
                            let cellTop = this.getTreeTopHeight(this.getNodeById(cell.id));
                            if ((cell.type == MindElementType.SUBJECT ||
                                cell.type == MindElementType.SON_SUBJECT ||
                                cell.type == MindElementType.MAIN_SUBJECT ||
                                cell.type == MindElementType.CONTENT_GENERALIZATION) && !this.isUnderline(cell)) {
                                nodeY = nodeY + (cellTop - cell.height / 2);
                            } else {
                                nodeY = nodeY + (cellTop - cell.height);
                            }
                        }
                        break;
                    } else {
                        let cellNode = this.getNodeById(cell.id);
                        if (index == 0) {
                            nodeY = nodeY + this.getTreeBottomHeight(cellNode);
                        } else {
                            nodeY += this.getTreeHeight(this.getNodeById(cell.id));
                        }
                        nodeY += cell.type == MindElementType.SON_SUBJECT ? Config.SonNodeVerticalSpacee : Config.NodeVerticalSpacee;
                    }
                }
                data.y = nodeY;
            }
            data.x = parentNodeData.x + parentNodeData.width + (data.type == MindElementType.SON_SUBJECT ? Config.SonNodeHorizontalSpacee : Config.NodeHorizontalSpacee);
        }
    }

    setElementLine(node, isChange) {
        if (!this.isGeneralizationNode(node)) {
            let lineData = this.getLineData(node);
            if (lineData.isEmpty() || this.isRootNode(node)) {
                return;
            }
            lineData.isHidden = true;
            return;
        }
        let data = node.value;
        if (data.isHidden) {
            return;
        }
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        if (parentNodeData.isEmpty()) {
            return;
        }

        let isCreateLineData = false;
        let lineData = this.getLineData(node);
        lineData.parentNodeId = parentNodeData.id;
        if (lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            this.lineMindElementDataDict.put(lineData.id, lineData);
            this.textElementLineMindElementDataDict.put(node.value.id, lineData);
            isCreateLineData = true;
        }
        lineData.layout = NodeLayoutType.LAYOUT_RIGHT;
        if (data.type == MindElementType.SUBJECT || data.type == MindElementType.CONTENT_GENERALIZATION) {
            lineData.type = MindElementType.LINE;
            if (this.isUnderline(data)) {
                lineData.y = data.y + data.height;
            } else {
                lineData.y = data.y + data.height / 2;
            }

            if (this.globalLayout == LineLayout.CURVE_LINE ||
                this.globalLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                this.globalLayout == LineLayout.STRAIGHT_CURVE_LINE ||
                this.globalLayout == LineLayout.CURVE_LINE_AVERAGE ||
                this.globalLayout == LineLayout.CURVE_LINE_2 ||
                this.globalLayout == LineLayout.STRAIGHT_LINE ||
                this.globalLayout == LineLayout.STRAIGHT_LINE_2 ||
                this.globalLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_LINE ||
                this.globalLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE) {
                lineData.x = parentNodeData.x + parentNodeData.width - this.getSubjectNodeLineSpace(node);
            } else {
                lineData.x = parentNodeData.x + parentNodeData.width;
            }

            lineData.width = data.x - lineData.x;
            lineData.height = parentNodeData.y - lineData.y + parentNodeData.height / 2;
            let startPoint = new Point(0, lineData.height);
            let endPoint = new Point(lineData.width, 0);
            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent(startPoint, endPoint, 0x333333, data.id);
            } else {
                lineData.lineContent.setStartPoint(startPoint);
                lineData.lineContent.setEndPoint(endPoint);
            }
            lineData.lineContent.orientation = LineOrientation.RIGHT;
            lineData.lineContent.lineLayout = this.globalLayout;
        } else {

            lineData.type = MindElementType.SON_LINE;

            if (this.isUnderline(parentNodeData)) {
                lineData.y = parentNodeData.y + parentNodeData.height;
                lineData.x = parentNodeData.x + parentNodeData.width;
            } else {
                lineData.y = parentNodeData.y + parentNodeData.height / 2;
                lineData.x = parentNodeData.x + parentNodeData.width;
            }

            let curveStartPoint = new Point(0, 0);
            let curveEndPoint = new Point(0, 0);
            let straightEndPoint = new Point(0, 0);

            if (this.isUnderline(data)) {
                lineData.height = data.y - lineData.y + data.height;
                lineData.width = data.x - lineData.x;
            } else {
                lineData.height = data.y - lineData.y + data.height / 2;
                lineData.width = data.x - lineData.x;
            }

            if (this.globalLayout == LineLayout.STRAIGHT_LINE ||
                this.globalLayout == LineLayout.STRAIGHT_LINE_2) {
                curveStartPoint = new Point(0, 0);
                curveEndPoint = new Point(lineData.width - lineData.width / 4, lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            } else if (this.globalLayout == LineLayout.CURVE_LINE ||
                this.globalLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                this.globalLayout == LineLayout.STRAIGHT_CURVE_LINE) {
                curveStartPoint = new Point(0, 0);
                curveEndPoint = new Point(lineData.width - lineData.width / 6, lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            } else if (this.globalLayout == LineLayout.CURVE_LINE_2 ||
                this.globalLayout == LineLayout.CURVE_LINE_AVERAGE) {
                curveStartPoint = new Point(0, 0);
                curveEndPoint = new Point(lineData.width, lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            } else {
                curveStartPoint = new Point(0, 0);
                curveEndPoint = new Point(lineData.width / 2, lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            }

            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent();
                lineData.lineContent.setLineElementContent(curveStartPoint, curveEndPoint, 0x333333, data.id, straightEndPoint);
            } else {
                lineData.lineContent.setCurveStartPoint(curveStartPoint);
                lineData.lineContent.setCurveEndPoint(curveEndPoint);
                lineData.lineContent.setStraightEndPoint(straightEndPoint);
            }

            lineData.lineContent.orientation = LineOrientation.RIGHT;
            lineData.lineContent.lineLayout = this.globalLayout;

        }

        if (isCreateLineData) {
            // let lineColorMode = new LineColorMode();
            let lineNodeContent = lineData.lineContent;

            let referenceLine = this.getReferenceLine(node.value.id);
            if (!referenceLine.isEmpty()) {
                if (this.isUnderline(data) && this.isBorderColor(data)) {
                    lineNodeContent.color = data.borderColor;
                } else {
                    lineNodeContent.color = referenceLine.lineContent.color;
                }
                lineNodeContent.lineWidth = referenceLine.lineContent.lineWidth;
                lineData.isShadow = referenceLine.isShadow;
            } else {
                lineNodeContent.color = node.value.styleColor;
                if (this.isUnderline(data)) {
                    lineNodeContent.lineWidth = data.borderWidth;
                } else {
                    lineNodeContent.lineWidth = this.mindMapStyleColor.getSubjectLineWidth();
                }
            }
            lineData.backgroundColor = Colors.transparent;
            lineData.borderColor = Colors.transparent;
            lineData.borderWidth = 0;
            let len = parentNode.children.length
            if (len > 1) {
                for (let index = 0; index < len; index++) {
                    let cell = parentNode.children[index]
                    if (cell.value.id != node.value.id) {
                        let brotherLine = this.getLineData(cell);
                        lineNodeContent.lineThicken = brotherLine.isEmpty() ? false : brotherLine.lineContent.lineThicken;
                        return
                    }
                }
            }
        }
    }

    setGeneralizationElementsPoint(isChange) {
        let list = this.getGeneralizationMindElementDatas();
        let listCount = list.length;
        for (let index = 0; index < listCount; index++) {
            let node = list[index];

            if (node.value.isHidden) {
                continue;
            }
            let mindElementData = node.value;
            let generalizationContent = mindElementData.generalizationContent;
            let top = 1000000;
            let bottom = 0;
            let left = 0;

            MindElementCalculation.set(node.value).calcule();
            for (let targetIndex = 0; targetIndex < generalizationContent.targetIds.length; targetIndex++) {
                let targetNode = this.getNodeById(generalizationContent.targetIds[targetIndex]);
                if (!this.isIncludeNodeInCurrentLayout(targetNode) ||
                        targetNode.isEmpty() ||
                        targetNode.value.isHidden ||
                        targetNode.value.layout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    continue;
                }
                let targetTop = targetNode.getNodeTop();
                let targetBottom = targetNode.getNodeBottom();
                left = this.rootTreeNode.value.x + this.rootTreeNode.value.width + new UiUtil().dip2px(12) + Config.GeneralizationLineWidth + Config.GeneralizationSpacing;

                if (targetTop < top) {
                    top = targetTop;
                }
                if (targetBottom > bottom) {
                    bottom = targetBottom;
                }
            }
            generalizationContent.targetHeight = bottom - top;
            mindElementData.y = top / 2 + bottom / 2 - mindElementData.height / 2;
            mindElementData.x = left;
            this.setGeneralizationElementLine(node, isChange);

            for (let childrenIndex = 0; childrenIndex < node.children.length; childrenIndex++) {
                this.setNodeRightLayouElementsPoint(node.children[childrenIndex], isChange);
            }
            for (let childrenIndex = 0; childrenIndex < node.children.length; childrenIndex++) {
                this.setNodeElementsLinePoint(node.children[childrenIndex], isChange);
            }
        }
    }

    setGeneralizationElementLine(node, isChange) {
        let mindElementData = node.value;
        let generalizationContent = mindElementData.generalizationContent;
        let isCreateLineData = false;
        let lineData = this.getGeneralizationLineData(node);
        if (!isChange || lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            isCreateLineData = true;
        }
        let generalizationParentNode = this.getNodeById(node.value.generalizationContent.targetIds[0]);
        lineData.parentNodeId = mindElementData.id;
        lineData.type = MindElementType.LEFTBRACELINE;
        lineData.y = mindElementData.y + mindElementData.height / 2 - generalizationContent.targetHeight / 2;
        lineData.x = mindElementData.x - Config.GeneralizationLineWidth - Config.GeneralizationSpacing;
        lineData.width = Config.GeneralizationLineWidth;
        lineData.height = generalizationContent.targetHeight;


        let startPoint = new Point(0, 0);
        let endPoint = new Point(0, lineData.height);
        if (lineData.lineContent == null) {
            lineData.lineContent = new LineElementContent(startPoint, endPoint, mindElementData.borderColor, mindElementData.id);
        } else {
            lineData.lineContent.setStartPoint(startPoint);
            lineData.lineContent.setEndPoint(endPoint);
        }
        lineData.lineContent.orientation = LineOrientation.RIGHT;
        this.generalizationLineMindElementDataDict.put(lineData.id, lineData);

        if (isCreateLineData) {
            lineData.backgroundColor = Colors.clear;
            lineData.borderWidth = 0;
            lineData.borderColor = Colors.clear;
            let referenceLine = this.getReferenceLine(node.value.id);
            if (!referenceLine.isEmpty()) {
                if (this.isUnderline(data) && this.isBorderColor(data)) {
                    lineNodeContent.color = data.borderColor;
                } else {
                    lineNodeContent.color = referenceLine.lineContent.color;
                }
                lineData.lineContent.lineWidth = referenceLine.lineContent.lineWidth == 0 ? 1.5 : referenceLine.lineContent.lineWidth;
                lineData.isShadow = referenceLine.isShadow;
            } else {
                let generalizationParentNodeLine = this.getLineData(generalizationParentNode);
                if (generalizationParentNodeLine.isEmpty()) {
                    lineData.lineContent.lineWidth = generalizationParentNode.value.borderWidth == 0 ? 1.5 : generalizationParentNode.value.borderWidth;
                    let color = generalizationParentNode.value.borderColor;
                    if (color == Colors.clear) {
                        color = generalizationParentNode.value.backgroundColor;
                    }
                    if (color == Colors.clear) {
                        color = Colors.black60;
                    }
                    lineData.lineContent.color = color;
                } else {
                    lineData.lineContent.lineWidth = generalizationParentNodeLine.lineContent.lineWidth == 0 ? 1.5 : generalizationParentNodeLine.lineContent.lineWidth;
                    lineData.lineContent.color = generalizationParentNodeLine.lineContent.color;
                }
            }
        }
    }

    getTreeHeight(node) {
        if (node.isEmpty() || node.value.isHidden) {
            return 0;
        }
        if (!this.isRootNode(node) && node.value.layout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            let layout = this.getNodeLayout(node);
            return layout.getTreeHeight(node);
        }

        let height = node.value.height;
        let childHeight = 0;
        if (this.isRootNode(node)) {
            for (let index = 0; index < node.children.length; index++) {
                const chlid = node.children[index];
                if (chlid.value.isHidden) {
                    continue;
                } else if (chlid.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    let cellHeight = this.getTreeHeight(chlid)
                    if (cellHeight > childHeight) {
                        childHeight = cellHeight;
                    }
                } else {
                    let layout = this.getNodeLayout(chlid);
                    let cellHeight = layout.getTreeHeight(chlid) + this.NodeVerticalSpacee * 2
                    if (cellHeight > childHeight) {
                        childHeight = cellHeight;
                    }
                }
            }
        } else {
            for (let index = 0; index < node.children.length; index++) {
                const chlid = node.children[index];
                if (chlid.value.isHidden) {
                    continue;
                } else if (chlid.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    let cellHeight = this.getTreeHeight(chlid)
                    if (cellHeight > childHeight) {
                        childHeight = cellHeight;
                    }
                } else {
                    let layout = this.getNodeLayout(chlid);
                    let cellHeight = layout.getTreeHeight(chlid) + this.NodeVerticalSpacee * 2
                    if (cellHeight > childHeight) {
                        childHeight = cellHeight;
                    }
                }
            }
        }

        height += (childHeight + (childHeight > 0 ? this.lineWidth : 0));
        return height;
    }

    getTreeWidth(node) {
        if (node.isEmpty() || node.value.isHidden) {
            return 0;
        }
        if (!this.isRootNode(node) && node.value.layout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            let layout = this.getNodeLayout(node);
            return layout.getTreeWidth(node);
        }

        let width = node.value.width;
        let maxChildWidth = 0;
        if (this.isRootNode(node)) {
            for (let index = 0; index < node.children.length; index++) {
                const chlid = node.children[index];
                let chlidWidth = 0;
                if (chlid.value.isHidden) {
                    continue;
                } else if (chlid.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    chlidWidth = this.getTreeWidth(chlid) + ((index < node.children.length - 1) ? this.lineWidth : 0);
                } else {
                    let layout = this.getNodeLayout(chlid);
                    chlidWidth = layout.getTreeWidth(chlid) + this.NodeHorizontalSpacee * 2;
                }
                maxChildWidth += chlidWidth;
            }
        } else {
            for (let index = 0; index < node.children.length; index++) {
                const chlid = node.children[index];
                let chlidWidth = 0;
                if (chlid.value.isHidden) {
                    continue;
                } else if (chlid.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    chlidWidth = this.getTreeWidth(chlid) + (index < node.children.length - 1 ? this.lineWidth : 0);
                } else {
                    let layout = this.getNodeLayout(chlid);
                    chlidWidth = layout.getTreeWidth(chlid) + this.NodeHorizontalSpacee * 2;
                }
                maxChildWidth += chlidWidth;
            }
        }
        // if (this.isRootNode(node)) {
        width = Math.max(maxChildWidth, width);
        // } else if (maxChildWidth > 0) {
        //     width += maxChildWidth + this.lineWidth;
        // }
        return width;
    }

    changeLayoutToForm(node) {
        if (!node.isEmpty()) {
            node.value.mindElementOriginalShape = node.value.mindElementShape;
            node.value.mindElementShape = MindElementShapeType.Corner;
            if (node.value.mindElementOriginalShape != MindElementShapeType.Corner) {
                MindElementCalculation.set(node.value).calcule();
            }
        }
        node.children.forEach(item => {
            this.changeLayoutToForm(item);
        });
    }

    changeLayoutFromForm(node) {
        if (!node.isEmpty()) {
            if (node.value.mindElementShape != node.value.mindElementOriginalShape) {
                node.value.mindElementShape = node.value.mindElementOriginalShape;
                MindElementCalculation.set(node.value).calcule();
            }
        }
        node.children.forEach(item => {
            this.changeLayoutFromForm(item);
        });
    }

    setNodeHeight(node) {
        let height = this.getSameLevelNodeMaxHeight(node.value.level);
        new BaseMindElementDataCalculation().changeDataHeight(node.value, height, (height - node.value.height));
    }

    setNodeWidth(node) {
        let parentNode = this.getNodeById(node.value.parentNodeId)
        if (parentNode.isEmpty() || parentNode.children[parentNode.children.length - 1].value.id != node.value.id) {
            let width = this.getTreeWidth(node);
            new BaseMindElementDataCalculation().changeDataWidth(node.value, width, (width - node.value.width));
        } else {
            let parentNodeWidth = this.getTreeWidth(parentNode);
            let leftWidth = this.getNodeLeft(node)
            if (node.value.width < parentNodeWidth - leftWidth) {
                new BaseMindElementDataCalculation().changeDataWidth(node.value, 
                    parentNodeWidth - leftWidth, 
                    (parentNodeWidth - leftWidth - node.value.width));
            }
        }
    }

    getSameLevelNodeMaxWidth(level) {
        let list = this.getSameLevelNodeByLevel(this.rootTreeNode, level);
        let width = 0;
        list.forEach(item => {
            if (item.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL && item.value.width > width) {
                width = item.value.width;
            }
        });
        return width;
    }

    getSameLevelNodeMaxHeight(level) {
        let list = this.getSameLevelNodeByLevel(this.rootTreeNode, level);
        let height = 0;
        list.forEach(item => {
            if (item.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL && item.value.height > height) {
                height = item.value.height;
            }
        });
        return height;
    }

    getSameLevelNodeByLevel(node, level) {
        let list = new Array();
        if (node.isEmpty() || node.value.isHidden || node.value.layout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            return list;
        }
        if (node.value.level == level) {
            list.push(node);
        }
        if (node.value.level < level) {
            if (node.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                node.children.forEach(item => {
                    let datas = this.getSameLevelNodeByLevel(item, level);
                    if (datas.length > 0) {
                        list = list.concat(datas);
                    }
                });
            }
            
        }
        return list;
    }

    getGolbalLineColor() {
        if (this.rootTreeNode == null || this.rootTreeNode.isEmpty() || this.rootTreeNode.children.length == 0) {
            return Colors.black60;
        }
        let line = this.getLineData(this.rootTreeNode.children[0]);
        if (!line.isEmpty() && line.lineContent != null) {
            return line.lineContent.color;
        }
        return Colors.black60;
    }

    isGeneralizationNode(node) {
        let keys = this.generalizationMindElementDataDict.keys()
        for (let index = 0; index < keys.length; index++) {
            let generalizationNode = this.generalizationMindElementDataDict.get(keys[index]);
            if (generalizationNode.value.isHidden) {
                continue;
            }
            if (generalizationNode.containsData(node.value.id)) {
                return true;
            }
        }
        return false;
    }

    getTreeTopHeight(node) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }

        let nodeSelfTop = 0;
        if (((node.value.type == MindElementType.SUBJECT || 
            node.value.type == MindElementType.SON_SUBJECT ||
            node.value.type == MindElementType.CONTENT_GENERALIZATION) && 
            !this.isUnderline(node.value)) || node.value.type == MindElementType.MAIN_SUBJECT) {
            nodeSelfTop = node.value.height / 2;
        } else {
            nodeSelfTop = node.value.height;
        }
        nodeSelfTop += this.getEncircleTopHeight(node.value);
        if (node.children.isEmpty() || node.children[0].value.isHidden) {
            return nodeSelfTop;
        }
        let childCount = node.children.length;
        let firstTop = this.getTreeTopHeight(node.children[0]) + this.getEncircleTopHeight(node.value);
        if (childCount == 1) {
            return Math.max(nodeSelfTop, firstTop);
        }
        let childTotle = this.getEncircleTopHeight(node.value) + this.getEncircleBottomHeight(node.value);
        for (let index = 0; index < childCount; index++) {
            let cell = node.children[index];
            let height = this.getTreeHeight(cell);
            childTotle += height;
            if (index < childCount - 1) {
                childTotle += cell.value.type == MindElementType.SON_SUBJECT ? Config.SonNodeVerticalSpacee : Config.NodeVerticalSpacee;
            }
        }
        let lastBottom = this.getTreeBottomHeight(node.children[childCount - 1]);
        let height = (childTotle - firstTop - lastBottom) / 2 + firstTop;
        return Math.max(height, nodeSelfTop);
    }

    getTreeBottomHeight(node) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        let nodeSelfBottom = 0;
        if (((node.value.type == MindElementType.SUBJECT || 
            node.value.type == MindElementType.SON_SUBJECT ||
            node.value.type == MindElementType.CONTENT_GENERALIZATION) && 
            !this.isUnderline(node.value)) || node.value.type == MindElementType.MAIN_SUBJECT) {
            nodeSelfBottom = node.value.height / 2;
        } else {
            nodeSelfBottom = 0;
        }
        nodeSelfBottom += this.getEncircleBottomHeight(node.value);
        if (node.children.isEmpty() || node.children[0].value.isHidden) {
            return nodeSelfBottom;
        }

        let childCount = node.children.length;
        let lastBottom = this.getTreeBottomHeight(node.children[childCount - 1]) + this.getEncircleBottomHeight(node.value);
        if (childCount == 1) {
            return Math.max(lastBottom, nodeSelfBottom);
        }
        let childTotle = this.getEncircleTopHeight(node.value) + this.getEncircleBottomHeight(node.value);
        for (let index = 0; index < childCount; index++) {
            let cell = node.children[index];
            let height = this.getTreeHeight(cell);
            childTotle += height;
            if (index < childCount - 1) {
                childTotle += cell.value.type == MindElementType.SON_SUBJECT ? Config.SonNodeVerticalSpacee : Config.NodeVerticalSpacee;
            }
        }
        let firstTop = this.getTreeTopHeight(node.children[0]);
        let height = (childTotle - firstTop - lastBottom) / 2 + lastBottom;
        return Math.max(height, nodeSelfBottom);
    }

    getEncircleTopHeight(data) {
        let encircleMindElementDataDictArr = this.encircleMindElementDataDict.keys();

        for (let index = 0; index < encircleMindElementDataDictArr.length; index++) {
            let key = encircleMindElementDataDictArr[index];
            let mind = this.encircleMindElementDataDict.get(key);
            if (mind.lineContent == null) {
                return 0;
            }
            let node = this.getNodeById(mind.parentNodeId);
            if (node.isEmpty()) {
                continue;
            }
            let targetIds = new Array();
            if (mind.lineContent != null) {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                targetIds.push(mind.parentNodeId);
            }
            for (let index = 0; index < targetIds.length; index++) {
                let id = targetIds[index];
                if (id == data.id) {
                    if (this.isTopNode(id, targetIds)) {
                        if (mind.lineContent.isContainText()) {
                            return new EncircleNodesPointsCalculation().space + mind.lineContent.textContent.height + mind.lineContent.padding;
                        } else {
                            return new EncircleNodesPointsCalculation().space + mind.lineContent.padding;
                        }
                    } else {
                        return 0
                    }
                }
            }
        }
        return 0;
    }

    getEncircleBottomHeight(data) {
        let encircleMindElementDataDictArr = this.encircleMindElementDataDict.keys();
        for (let index = 0; index < encircleMindElementDataDictArr.length; index++) {
            let key = encircleMindElementDataDictArr[index];
            let mind = this.encircleMindElementDataDict.get(key);
            if (mind.lineContent == null) {
                return 0;
            }
            let node = this.getNodeById(mind.parentNodeId);
            if (node.isEmpty()) {
                continue;
            }
            let targetIds = new Array();
            if (mind.lineContent != null) {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                targetIds.push(mind.parentNodeId);
            }
            for (let index = 0; index < targetIds.length; index++) {
                let id = targetIds[index];
                if (id == data.id) {
                    if (this.isBottomNode(id, targetIds)) {
                        return new EncircleNodesPointsCalculation().space + mind.lineContent.padding;
                    } else {
                        return 0;
                    }
                }
            }
        }
        return 0;
    }

    getSubjectNodeLineSpace(node) {
        let margin = this.rootTreeNode.value.width / 4;
        if (this.globalLayout == LineLayout.CURVE_LINE_2) {
            margin = this.rootTreeNode.value.width / 3 * 2;
        }
        let baseWidth = this.rootTreeNode.value.width / 2 - margin;
        let totalLineNumber = this.rootTreeNode.children.length;
        if (totalLineNumber == 0) {
            return 0;
        }
        let topNumber = 0;
        let nodeIndex = -1;
        for (let index = 0; index < totalLineNumber; index++) {
            let cell = this.rootTreeNode.children[index];
            if (cell.value.id == node.value.id) {
                nodeIndex = index;
            }
            if (node.value.type == MindElementType.SUBJECT && !this.isUnderline(node.value)) {
                if (cell.value.y + cell.value.height / 2 < this.rootTreeNode.value.y + this.rootTreeNode.value.height / 2) {
                    topNumber += 1;
                }
            } else {
                if (cell.value.y + cell.value.height < this.rootTreeNode.value.y + this.rootTreeNode.value.height / 2) {
                    topNumber += 1;
                }
            }
        }

        if (nodeIndex == -1) {
            return 0;
        }
        let originalTopNumber = topNumber;
        let originalBottomNumber = totalLineNumber - topNumber;

        let bottomNumber = originalBottomNumber;
        if (originalBottomNumber > originalTopNumber) {
            topNumber = bottomNumber;
            if (nodeIndex > originalTopNumber - 1) {
                nodeIndex = nodeIndex + (originalBottomNumber - originalTopNumber);
            }
        } else if (originalBottomNumber < topNumber) {
            bottomNumber = topNumber;
            if (nodeIndex > topNumber - 1) {
                nodeIndex = nodeIndex + topNumber - originalBottomNumber;
            }
        }
        if (nodeIndex < topNumber) {
            let cellWidth = baseWidth / (topNumber + 1);
            return (topNumber - nodeIndex) * cellWidth + margin;
        } else {
            let cellWidth = baseWidth / (bottomNumber + 1);
            return (nodeIndex - topNumber + 1) * cellWidth + margin;
        }
    }

    getLayoutType() {
        return NodeLayoutType.LAYOUT_FORM_HORIZONTAL;
    }

    getFloatExplainLineOrientation() {
        return LineOrientation.BOTTOM;
    }

    getNodeTop(node) {
        if (node.isEmpty() || node.value.isHidden) {
            return 0;
        }
        let siblingsNodeDatas = this.getSiblingsMindElementDataById(node.value.id);
        let allSiblingsNodesPosition = this.getNodeInNodesPosition(siblingsNodeDatas, node.value);
        let topNodeHeight = 0;
        if (allSiblingsNodesPosition == 0) {
            topNodeHeight = 0;
        } else if (allSiblingsNodesPosition > 0) {
            for (let j = 0; j < allSiblingsNodesPosition; j++) {
                let siblingsNode = this.getNodeById(siblingsNodeDatas[j].id);
                let treeHeight = this.getTreeHeight(siblingsNode);
                if (this.isRootNode(this.getNodeById(node.value.parentNodeId))) {
                    topNodeHeight += treeHeight + this.lineWidth;
                } else {
                    topNodeHeight += treeHeight + (j == 0 ? 0: this.lineWidth);
                }
                if (siblingsNode.value.layout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    topNodeHeight += this.NodeVerticalSpacee * 2;
                }
            }
        }
        return topNodeHeight;
    }

    getNodeLeft(node) {
        if (node.isEmpty() || node.value.isHidden) {
            return 0;
        }
        let siblingsNodeDatas = this.getSiblingsMindElementDataById(node.value.id);
        let allSiblingsNodesPosition = this.getNodeInNodesPosition(siblingsNodeDatas, node.value);
        let leftNodeWidth = 0;
        if (allSiblingsNodesPosition == 0) {
            leftNodeWidth = 0;
        } else if (allSiblingsNodesPosition > 0) {
            for (let j = 0; j < allSiblingsNodesPosition; j++) {
                let siblingsNode = this.getNodeById(siblingsNodeDatas[j].id);
                let treeWidth = this.getTreeWidth(siblingsNode);
                if (siblingsNode.value.layout == NodeLayoutType.LAYOUT_FORM) {
                    if (this.isRootNode(this.getNodeById(node.value.parentNodeId))) {
                        leftNodeWidth += treeWidth + (j < siblingsNodeDatas.length - 1 ?  this.lineWidth : 0);
                    } else {
                        leftNodeWidth += treeWidth + (j < siblingsNodeDatas.length - 1 ?  this.lineWidth : 0);
                    }
                } else if (siblingsNode.value.layout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    leftNodeWidth += treeWidth + this.NodeVerticalSpacee * 2;
                } else if (this.isRootNode(this.getNodeById(node.value.parentNodeId))) {
                    leftNodeWidth += treeWidth + (j < siblingsNodeDatas.length - 1 ?  this.lineWidth : 0);
                } else {
                    leftNodeWidth += treeWidth + (j < siblingsNodeDatas.length - 1 ?  this.lineWidth : 0);
                }
            }
        }
        return leftNodeWidth;
    }
}
export default FormHorizontalLayut
