import Config from "../../../core/core/calcule/Config"
import EncircleNodesPointsCalculation from "../../../core/core/calcule/EncircleNodesPointsCalculation"
import NodesRectCalculation from "../../../core/core/calcule/NodesRectCalculation"
import Colors from "../../../utils/Colors"
import UiUtil from "../../../utils/UiUtil"
import IdGenerator from "../base/IdGenerator"
import LineColorMode from "../base/LineColorMode"
import LineOrientation from "../../datatype/LineOrientation"
import LineLayout from "../../datatype/LineLayout"
import MindElementShapeType from "../../datatype/MindElementShapeType"
import MindElementType from "../../datatype/MindElementType"
import NodeLayoutType from "../../datatype/NodeLayoutType"
import LineElementContent from "../../mindelementdata/mindcontent/LineElementContent"
import BaseLayout from "./BaseLayout"
import Point from "../../../viewmodel/core/base/Point"
import EncircleShapeType from "../../datatype/EncircleShapeType"
import RectsMergeCalculation from "./RectsMergeCalculation"
import EncircleTitleLayoutType from "../../datatype/EncircleTitleLayoutType"
import MindElementCalculation from "../../../core/core/calcule/elementCalculation/MindElementCalculation"

/**
 * ProjectName: MindMap
 * Created by tony on 2020/9/10
 * Copyright(c) 2020 mindyushu.com
 */

class TreeRightLayout extends BaseLayout {

    constructor() {
        super();
    }

    initConfig() {
        super.initConfig();
        this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee);
        this.SonNodeVerticalSpacee = this.addSonSubjectSpcaeVertical(Config.SonNodeVerticalSpacee);
        this.SonNodeHorizontalSpacee = this.addSonSubjectSpcaeHorizontal(Config.SonNodeHorizontalSpacee * 1.3);
        this.NodeHorizontalSpacee = this.addSubjectSpcaeHorizontal(Config.NodeHorizontalSpacee * 1.5);
    }


    onLayout(isChange) {
        if (this.rootTreeNode == null) {
            return;
        }
        this.setElementsPoint(isChange);
        this.hideRootFormLine();
        this.setGeneralizationElementsPoint(isChange);
        // this.setEncirclePoint();
        // this.setFloatExplainPoint(isChange);
    }

    setElementsPoint(isChange) {
        if ((!isChange || this.rootTreeNode.value.y == -1 || this.rootTreeNode.value.x == -1) && this.rootTreeNode.value.type == MindElementType.MAIN_SUBJECT) {
            let left = (Config.Mind_Width - new UiUtil().getScreenWidth()) / 2;
            let top = (Config.Mind_Height - new UiUtil().getScreenHeight()) / 2;
            this.rootTreeNode.value.y = (new UiUtil().getScreenHeight() - this.rootTreeNode.value.height) / 2 + top;
            this.rootTreeNode.value.x = 20 + left;
            
        }
        this.initConfig();
        this.setNodeElementsPoint(this.rootTreeNode, isChange);
        this.setNodeElementsLinePoint(this.rootTreeNode, isChange);
    }

    setEncirclePoint() {
        let key = this.encircleMindElementDataDict.keys()
        let length = key.length
        for (let index = 0; index < length; index++) {
            let key = this.encircleMindElementDataDict.keys()[index];
            let mind = this.encircleMindElementDataDict.get(key);
            let targetIds = new Array();
            if (mind.lineContent == null) {
                continue;
            }
            if (mind.lineContent.targetIds.isEmpty()) {
                targetIds.push(mind.lineContent.targetId > -1 ? mind.lineContent.targetId : mind.parentNodeId);
            } else {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                continue;
            }
            let textHeight = mind.lineContent != null && mind.lineContent.isContainText() ? mind.lineContent.textContent.height : 0;
            if (mind.lineContent != null && mind.lineContent.isContainText()) {
                mind.lineContent.textContent.x = new EncircleNodesPointsCalculation().space;
                mind.lineContent.textContent.y = 0;
            }
            if (targetIds.length == 1) {
                let node = this.getNodeById(targetIds[0]);
                if (node.isEmpty()) {
                    continue;
                }

                let padding = mind.lineContent == null ? 0 : mind.lineContent.padding;
                let type = mind.lineContent == null ? EncircleShapeType.LAYOUT_TRAPEZOID : mind.lineContent.encircleShapeType;

                let rect = new NodesRectCalculation(this.explainMindElementDataDict).calcule(node);
                mind.x = rect.x - new EncircleNodesPointsCalculation().space - padding;
                mind.y = rect.y - new EncircleNodesPointsCalculation().space - padding - textHeight;
                mind.width = rect.width() + new EncircleNodesPointsCalculation().space * 2 + padding * 2;
                mind.height = rect.height() + new EncircleNodesPointsCalculation().space * 2 + padding * 2 + textHeight;

                let encircleNodesPoint = new Array();
                encircleNodesPoint.push(new Point(0, 0));
                encircleNodesPoint.push(new Point(mind.width, 0));
                encircleNodesPoint.push(new Point(mind.width, mind.height));
                encircleNodesPoint.push(new Point(0, mind.height));
                encircleNodesPoint.push(new Point(0, 0));

                if (mind.lineContent == null) {
                    let lineContent = new LineElementContent(new Point(), new Point(), node.value.styleColor, node.value.id);
                    lineContent.encircleNodesPoint = encircleNodesPoint;
                    lineContent.strokeColor = node.value.styleColor;
                    mind.lineContent = lineContent;
                } else {
                    mind.lineContent.encircleNodesPoint = encircleNodesPoint;
                }
            } else {
                mind.lineContent.encircleShapeType = mind.lineContent.encircleShapeType == EncircleShapeType.LAYOUT_TRAPEZOID ?
                    EncircleShapeType.LAYOUT_RECTANGLE_2 : mind.lineContent.encircleShapeType;

                let rectList = new Array();
                for (let index = 0; index < targetIds.length; index++) {
                    let id = targetIds[index];
                    let node = this.getNodeById(id);
                    if (node.isEmpty()) {
                        continue;
                    }

                    let rect = new NodesRectCalculation(this.explainMindElementDataDict).calcule(node);
                    rectList.push(rect);
                }
                if (rectList.isEmpty()) {
                    continue;
                }
                let padding = mind.lineContent == null ? 0 : mind.lineContent.padding;

                let rect = new RectsMergeCalculation().calcule(rectList);
                mind.x = rect.x - new EncircleNodesPointsCalculation().space - padding;
                mind.y = rect.y - new EncircleNodesPointsCalculation().space - padding - textHeight;
                mind.width = rect.width() + new EncircleNodesPointsCalculation().space * 2 + padding * 2;
                mind.height = rect.height() + new EncircleNodesPointsCalculation().space * 2 + padding * 2 + textHeight;

                mind.lineContent.encircleNodesPoint = new Array();
            }
            if (mind.lineContent != null && mind.lineContent.isContainText()) {
                if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_MIDDLE ||
                        mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_MIDDLE) {
                    mind.lineContent.textContent.x = (mind.width - mind.lineContent.textContent.width)/2;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_RIGHT ||
                        mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_RIGHT) {
                    mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width - new EncircleNodesPointsCalculation().space;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_FULL) {
                    mind.lineContent.textContent.width = mind.width;
                    mind.lineContent.textContent.x = 0;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_RIGHT) {
                    mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_LEFT) {
                    mind.lineContent.textContent.x = 0;
                    mind.lineContent.textContent.y = 0;
                } else {
                    mind.lineContent.textContent.x = new EncircleNodesPointsCalculation().space;
                    mind.lineContent.textContent.y = 0;
                }
            }

        }
    }

    setNodeElementsLinePoint(node, isChange) {
        this.setElementLine(node, isChange);
        if (node.children.length == 0) {
            return;
        }
        for (let index = 0; index < node.children.length; index++) {
            this.setNodeElementsLinePoint(node.children[index], isChange);
        }
    }

    setNodeElementsPoint(node, isChange) {
        this.setDataPoint(node.value, isChange);
        if (node.children.length == 0 || this.isLayout(node.value)) {
            return;
        }

        if (this.isRootNode(node) || node.value.layout == NodeLayoutType.LAYOUT_TREE_RIGHT) {
            for (let index = 0; index < node.children.length; index++) {
                this.setNodeElementsPoint(node.children[index], isChange);
            }
        } else {
            this.resetNodeLayout(node);
        }

    }

    setDataPoint(data, isChange) {
        let node = this.getNodeById(data.id);
        if (node.isEmpty() || node.value.isHidden) {
            return;
        }
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        let siblingsNodeDatas = this.getSiblingsMindElementDataById(data.id);
        this.pushCurrentLayoutNode(node);
        MindElementCalculation.set(data).calcule();
        if (data.id == this.rootTreeNode.value.id) {
            return;
        } else {

            let allSiblingsNodesPosition = this.getNodeInNodesPosition(siblingsNodeDatas, data);

            if (allSiblingsNodesPosition == 0) {
                let parentNodeBottom = this.isFromLeftRightLayout && this.isRootNode(parentNode) ?
                    (parentNodeData.y + this.getNodeHeight(parentNodeData) + 
                    (node.value.type == MindElementType.SON_SUBJECT ? this.SonNodeVerticalSpacee : this.NodeVerticalSpacee) * 2 + this.getEncircleTopHeight(data) + this.getNodeFloatExplainHeight(node)) :
                    (parentNodeData.y + this.getNodeHeight(parentNodeData) + 
                    (node.value.type == MindElementType.SON_SUBJECT ? this.SonNodeVerticalSpacee : this.NodeVerticalSpacee) * 2 + this.getEncircleTopHeight(data) + this.getNodeFloatExplainHeight(node)) ;
                if (this.isRootNode(parentNode)) {
                    parentNodeBottom = parentNodeData.y + this.getNodeHeight(parentNodeData) + this.NodeVerticalSpacee * 1.5 + this.getEncircleTopHeight(data) + this.getNodeFloatExplainHeight(node);
                } else {
                    parentNodeBottom = parentNodeData.y + this.getNodeHeight(parentNodeData) + this.SonNodeVerticalSpacee * 4 + this.getEncircleTopHeight(data) + this.getNodeFloatExplainHeight(node);
                }
                if (data.layout == NodeLayoutType.LAYOUT_RIGHT) {
                    let layout = this.getNodeLayout(node);
                    if (this.isUnderline(node.value)) {
                        data.y = parentNodeBottom + layout.getTreeTopHeight(node) - node.value.height;
                    } else {
                        data.y = parentNodeBottom + layout.getTreeTopHeight(node) - node.value.height / 2;
                    }
                } else if (data.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                    let layout = this.getNodeLayout(node);
                    if (this.isUnderline(node.value)) {
                        data.y = parentNodeBottom + layout.getTreeTopHeight(node) - node.value.height;
                    } else {
                        data.y = parentNodeBottom + layout.getTreeTopHeight(node) - node.value.height / 2;
                    }
                } else if (data.layout == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                    let layout = this.getNodeLayout(node);
                    if (this.isUnderline(node.value)) {
                        data.y = parentNodeBottom + layout.getTreeTopHeight(node) - node.value.height;
                    } else {
                        data.y = parentNodeBottom + layout.getTreeTopHeight(node) - node.value.height / 2;
                    }
                } else {
                    data.y = parentNodeBottom;
                }
                if (this.isFromLeftRightLayout) {
                    if (data.type == MindElementType.SON_SUBJECT) {
                        if (parentNodeData.id == this.rootTreeNode.value.id) {
                            data.x = parentNodeData.x + parentNodeData.width / 2 + this.SonNodeHorizontalSpacee / 2;
                        } else {
                            data.x = parentNodeData.x + this.SonNodeHorizontalSpacee;
                            if ((data.x - parentNodeData.x) - this.SonNodeHorizontalSpacee / 2 > parentNodeData.width / 2) {
                                data.x = parentNodeData.x + parentNodeData.width / 2 + this.SonNodeHorizontalSpacee / 2;
                            }
                        }
                    } else {
                        if (parentNodeData.id == this.rootTreeNode.value.id) {
                            data.x = parentNodeData.x + parentNodeData.width / 2 + this.NodeHorizontalSpacee / 2;
                        } else {
                            data.x = parentNodeData.x + this.NodeHorizontalSpacee;
                            if ((data.x - parentNodeData.x) - this.NodeHorizontalSpacee / 2 > parentNodeData.width / 2) {
                                data.x = parentNodeData.x + parentNodeData.width / 2 + this.NodeHorizontalSpacee / 2;
                            }
                        }
                    }

                } else {
                    data.x = parentNodeData.x + (this.isRootNode(parentNode) ? 
                    (node.value.type == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee) * 2 : 
                    (node.value.type == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee));
                    if ((data.x - parentNodeData.x) - (node.value.type == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee) / 2 > parentNodeData.width / 2) {
                        data.x = parentNodeData.x + parentNodeData.width / 2 + (node.value.type == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee) / 2;
                    }
                }
            } else {
                let perData = siblingsNodeDatas[allSiblingsNodesPosition - 1];
                let perNode = this.getNodeById(perData.id);
                let dataTreeHeight = 0;
                if (perData.layout == NodeLayoutType.LAYOUT_RIGHT) {
                    let layout = this.getNodeLayout(perNode);
                    if (this.isUnderline(node.value)) {
                        dataTreeHeight = layout.getTreeBottomHeight(perNode) + perNode.value.height;
                    } else {
                        dataTreeHeight = layout.getTreeBottomHeight(perNode) + perNode.value.height / 2;
                    }
                } else if (perData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                    let layout = this.getNodeLayout(perNode);
                    if (this.isUnderline(node.value)) {
                        dataTreeHeight = layout.getTreeBottomHeight(perNode) + perNode.value.height;
                    } else {
                        dataTreeHeight = layout.getTreeBottomHeight(perNode) + perNode.value.height / 2;
                    }
                } else {
                    dataTreeHeight = this.getTreeHeight(perNode) - this.getEncircleTopHeight(perData) - this.getNodeFloatExplainHeight(perNode);
                }
                if (data.layout == NodeLayoutType.LAYOUT_RIGHT) {
                    let layout = this.getNodeLayout(node);
                    if (this.isUnderline(node.value)) {
                        data.y = parseInt(perData.y) + dataTreeHeight + this.getEncircleTopHeight(data) +
                            (data.type == MindElementType.SUBJECT ? this.NodeVerticalSpacee : this.SonNodeVerticalSpacee) +
                            layout.getTreeTopHeight(node) - node.value.height;
                    } else {
                        data.y = parseInt(perData.y) + dataTreeHeight + this.getEncircleTopHeight(data) +
                            (data.type == MindElementType.SUBJECT ? this.NodeVerticalSpacee : this.SonNodeVerticalSpacee) +
                            layout.getTreeTopHeight(node) - node.value.height / 2;
                    }
                    layout.clearDatas();
                } else if (data.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                    let layout = this.getNodeLayout(node);
                    if (this.isUnderline(node.value)) {
                        data.y = parseInt(perData.y) + dataTreeHeight + this.getEncircleTopHeight(data) + this.getNodeFloatExplainHeight(node) +
                            (data.type == MindElementType.SUBJECT ? this.NodeVerticalSpacee : this.SonNodeVerticalSpacee) +
                            layout.getTreeTopHeight(node) - node.value.height;
                    } else {
                        data.y = parseInt(perData.y) + dataTreeHeight + this.getEncircleTopHeight(data) + this.getNodeFloatExplainHeight(node) +
                            (data.type == MindElementType.SUBJECT ? this.NodeVerticalSpacee : this.SonNodeVerticalSpacee) +
                            layout.getTreeTopHeight(node) - node.value.height / 2;
                    }
                    layout.clearDatas();
                } else {
                    data.y = parseInt(perData.y) + dataTreeHeight + this.getEncircleTopHeight(data) + this.getNodeFloatExplainHeight(node) +
                        (data.type == MindElementType.SUBJECT ? this.NodeVerticalSpacee : this.SonNodeVerticalSpacee);
                }
                data.x = perData.x;
            }

        }
        this.setBorderThicken(node);
        this.setElementLine(node,isChange);
    }

    setElementLine(node, isChange) {
        let data = node.value;
        if (data.isHidden || this.isRootNode(node)) {
            return;
        }
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        if (!this.isGeneralizatioData(data) && !this.isRootNode(parentNode) && (parentNodeData.isEmpty() || parentNodeData.layout != NodeLayoutType.LAYOUT_TREE_RIGHT)) {
            return;
        }

        let isCreateLineData = false;
        let lineData = this.getLineData(node);
        lineData.parentNodeId = parentNodeData.id;
        let lineLayout = this.getNodeLineLayout(node, lineData);
        if (lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            this.lineMindElementDataDict.put(lineData.id, lineData);
            this.textElementLineMindElementDataDict.put(node.value.id, lineData);
            isCreateLineData = true;
        }
        lineData.isHidden = false;
        lineData.layout = NodeLayoutType.LAYOUT_TREE_RIGHT;

        if (data.type == MindElementType.SUBJECT || data.type == MindElementType.CONTENT_GENERALIZATION) {
            lineData.type = MindElementType.LINE;
            lineData.y = parentNodeData.y + parentNodeData.height;
            if (lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_LINE ||
                lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE) {
                lineData.x = data.x - this.NodeHorizontalSpacee / 2;
            } else if (lineLayout == LineLayout.CURVE_LINE) {
                lineData.x = data.x - this.NodeHorizontalSpacee / 2;
            } else {
                lineData.x = data.x - this.NodeHorizontalSpacee / 2;
            }

            lineData.width = data.x - lineData.x;
            if (this.isUnderline(data)) {
                lineData.height = data.y - lineData.y + data.height;
            } else {
                lineData.height = data.y - lineData.y + data.height / 2;
            }

            let startPoint = new Point(0, 0);
            let endPoint = new Point(lineData.width, lineData.height);
            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent(startPoint, endPoint, 0x333333, data.id);
            } else {
                lineData.lineContent.setStartPoint(startPoint);
                lineData.lineContent.setEndPoint(endPoint);
            }
            lineData.lineContent.orientation = LineOrientation.RIGHT;
            lineData.lineContent.lineLayout = lineLayout;

        } else {
            lineData.type = MindElementType.SON_LINE;
            lineData.y = parentNodeData.y + parentNodeData.height;
            if (parentNode.value.id == this.rootTreeNode.value.id) {
                lineData.x = data.x - this.SonNodeHorizontalSpacee;
            } else {
                lineData.x = data.x - this.SonNodeHorizontalSpacee;
            }
            if (this.isUnderline(data)) {
                lineData.width = data.x - lineData.x;
                lineData.height = data.y - lineData.y + data.height;
            } else {
                lineData.width = data.x - lineData.x;
                lineData.height = data.y - lineData.y + data.height / 2;
            }

            let curveStartPoint = new Point(0, 0);
            let curveEndPoint = new Point(0, 0);
            let straightEndPoint = new Point(0, 0);

            if (lineLayout == LineLayout.STRAIGHT_LINE ||
                lineLayout == LineLayout.STRAIGHT_LINE_2) {
                curveStartPoint = new Point(lineData.width / 2, 0);
                curveEndPoint = new Point(lineData.width - lineData.width / 4, lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            } else if (lineLayout == LineLayout.CURVE_LINE ||
                lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                lineLayout == LineLayout.STRAIGHT_CURVE_LINE ) {
                curveStartPoint = new Point(lineData.width / 2, 0);
                curveEndPoint = new Point(lineData.width - lineData.width / 6, lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            } else if (lineLayout == LineLayout.CURVE_LINE_2) {
                curveStartPoint = new Point(lineData.width / 2, 0);
                curveEndPoint = new Point(lineData.width, lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            } else {
                curveStartPoint = new Point(lineData.width / 2, 0);
                curveEndPoint = new Point(lineData.width / 2, lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            }

            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent();
                lineData.lineContent.setLineElementContent(curveStartPoint, curveEndPoint, 0x333333, data.id, straightEndPoint);
            } else {
                lineData.lineContent.setCurveStartPoint(curveStartPoint);
                lineData.lineContent.setCurveEndPoint(curveEndPoint);
                lineData.lineContent.setStraightEndPoint(straightEndPoint);
            }
            lineData.lineContent.orientation = LineOrientation.RIGHT;
            lineData.lineContent.lineLayout = lineLayout;
        }


        if (isCreateLineData) {
            // let lineColorMode = new LineColorMode();
            let lineNodeContent = lineData.lineContent;

            let referenceLine = this.getReferenceLine(node.value.id);
            if (!referenceLine.isEmpty()) {
                if (this.isUnderline(data) && this.isBorderColor(data)) {
                    lineNodeContent.color = data.borderColor;
                } else {
                    lineNodeContent.color = referenceLine.lineContent.color;
                }
                let grandpaNode = this.getNodeById(parentNode.value.parentNodeId);
                if (referenceLine.lineContent.lineWidth == 0 &&
                        !grandpaNode.isEmpty() &&
                        grandpaNode.value.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                    lineNodeContent.lineWidth = parentNode.value.borderWidth > 0 ? parentNode.value.borderWidth : 1.5;
                } else {
                    lineNodeContent.lineWidth = referenceLine.lineContent.lineWidth;
                }
                lineNodeContent.dottedLine = referenceLine.lineContent.dottedLine;
                lineData.isShadow = referenceLine.isShadow;
            } else {
                lineNodeContent.color = node.value.styleColor;
                if (this.isUnderline(data)) {
                    lineNodeContent.lineWidth = data.borderWidth;
                } else {
                    lineNodeContent.lineWidth = this.mindMapStyleColor.getSubjectLineWidth();
                }
            }
            if (lineData.type == MindElementType.LINE && !this.settingData.lineColor.isEmpty()) {
                if (!this.originalRootTreeNode.isEmpty() && parentNode.value.id == this.originalRootTreeNode.value.id) {
                    parentNode = this.originalRootTreeNode;
                }
                // let parentNodeChildrenSize = parentNode.children.length;
                // let lineColorLength = this.settingData.lineColor.length;
                // for (let index = 0; index < parentNodeChildrenSize; index++) {
                //     if (parentNode.children[index].value.id == node.value.id) {
                //         let colorPoint = index % lineColorLength;
                //         lineNodeContent.color = this.settingData.lineColor[colorPoint];
                //         break;
                //     }
                // }
                let colorPoint = (parentNode.children.length - 1) % this.settingData.lineColor.length;
                lineNodeContent.color = this.settingData.lineColor[Math.abs(colorPoint)]
            }
            lineData.backgroundColor = Colors.clear;
            lineData.borderColor = Colors.clear;
            lineData.borderWidth = 0.0;
        }
        this.setLineThicken(lineData);
    }

    setGeneralizationElementsPoint(isChange) {
        let list = this.getGeneralizationMindElementDatas();
        let listCount = list.length;
        for (let index = 0; index < listCount; index++) {
            let node = list[index];
            if (node.value.isHidden || !this.isIncludeGeneralizationInCurrentLayout(node)
                || this.isLayout(node.value)) {
                continue;
            }
            let mindElementData = node.value;
            let generalizationContent = mindElementData.generalizationContent;
            let top = 1000000;
            let bottom = 0;
            let right = 0;
            let encircleSpace = 0;
            let targetNodes = []
            for (let targetIndex = 0; targetIndex < generalizationContent.targetIds.length; targetIndex++) {
                let targetNode = this.getNodeById(generalizationContent.targetIds[targetIndex]);

                if (targetNode.isEmpty() || targetNode.value.isHidden) {
                    mindElementData.isHidden = true;
                    continue
                }
                targetNodes.push(targetNode)
                for (let j = 0; j < index; j++) {
                    let checkNode = list[j];
                    if (checkNode.value.isHidden) {
                        continue
                    }
                    if (checkNode.value.generalizationContent.targetIds.length == 0) {
                        continue
                    }
                    let checkTargetNode = this.getNodeById(checkNode.value.generalizationContent.targetIds[0]);
                    if (!checkTargetNode.isEmpty() && targetNode.isChildNode(checkTargetNode)) {
                        targetNodes.push(checkNode)
                    }
                }
            }
            for (let targetIndex = 0; targetIndex < targetNodes.length; targetIndex++) {
                let targetNode = targetNodes[targetIndex];
                if (targetNode.isEmpty() || targetNode.value.isHidden) {
                    mindElementData.isHidden = true;
                    continue;
                }

                let targetTop = targetNode.getNodeTop();
                let targetBottom = targetNode.getNodeBottom();
                let targetRight = targetNode.getNodeRight();

                if (targetTop < top) {
                    top = targetTop;
                }
                if (targetBottom > bottom) {
                    bottom = targetBottom;
                }
                if (targetRight > right) {
                    right = targetRight;
                }
                let space = this.getEncircleSpaceAndPadding(targetNode.value)
                if (space > 0) {
                    encircleSpace = Math.max(space, encircleSpace)
                }
            }
            if (top == 1000000 && right == 0) {
                continue;
            }
            generalizationContent.targetHeight = bottom - top;
            mindElementData.y = top / 2 + bottom / 2 - mindElementData.height / 2;
            mindElementData.x = right + Config.GeneralizationLineWidth + Config.GeneralizationSpacing * 2 + encircleSpace;
            this.setGeneralizationElementLine(node, isChange);

            for (let childrenIndex = 0; childrenIndex < node.children.length; childrenIndex++) {
                this.setNodeElementsPoint(node.children[childrenIndex], isChange);
            }
            for (let childrenIndex = 0; childrenIndex < node.children.length; childrenIndex++) {
                this.setNodeElementsLinePoint(node.children[childrenIndex], isChange);
            }
        }
    }

    setGeneralizationElementLine(node, isChange) {
        let mindElementData = node.value;
        let generalizationContent = mindElementData.generalizationContent;
        let isCreateLineData = false;
        let lineData = this.getGeneralizationLineData(node);
        if (!isChange || lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            isCreateLineData = true;
        }
        let generalizationParentNode = this.getNodeById(node.value.generalizationContent.targetIds[0]);
        lineData.parentNodeId = mindElementData.id;
        lineData.type = MindElementType.LEFTBRACELINE;
        lineData.y = mindElementData.y + mindElementData.height / 2 - generalizationContent.targetHeight / 2;
        lineData.x = mindElementData.x - Config.GeneralizationLineWidth - Config.GeneralizationSpacing;
        lineData.width = Config.GeneralizationLineWidth;
        lineData.height = generalizationContent.targetHeight;


        let startPoint = new Point(0, 0);
        let endPoint = new Point(0, lineData.height);
        if (lineData.lineContent == null) {
            lineData.lineContent = new LineElementContent(startPoint, endPoint, mindElementData.borderColor, mindElementData.id);
        } else {
            lineData.lineContent.setStartPoint(startPoint);
            lineData.lineContent.setEndPoint(endPoint);
        }
        lineData.lineContent.orientation = LineOrientation.RIGHT;
        this.generalizationLineMindElementDataDict.put(lineData.id, lineData);

        if (isCreateLineData) {
            lineData.backgroundColor = Colors.clear;
            lineData.borderWidth = 0;
            lineData.borderColor = Colors.clear;
            let referenceLine = this.getReferenceLine(node.value.id);
            if (!referenceLine.isEmpty()) {
                if (this.isUnderline(data) && this.isBorderColor(data)) {
                    lineNodeContent.color = data.borderColor;
                } else {
                    lineNodeContent.color = referenceLine.lineContent.color;
                }
                lineData.lineContent.lineWidth = referenceLine.lineContent.lineWidth == 0 ? 1.5 : referenceLine.lineContent.lineWidth;
                lineData.isShadow = referenceLine.isShadow;
            } else {
                let generalizationParentNodeLine = this.getLineData(generalizationParentNode);
                if (generalizationParentNodeLine.isEmpty()) {
                    lineData.lineContent.lineWidth = generalizationParentNode.value.borderWidth == 0 ? 1.5 : generalizationParentNode.value.borderWidth;
                    let color = generalizationParentNode.value.borderColor;
                    if (color == Colors.clear) {
                        color = generalizationParentNode.value.backgroundColor;
                    }
                    if (color == Colors.clear) {
                        color = Colors.black60;
                    }
                    lineData.lineContent.color = color;
                } else {
                    lineData.lineContent.lineWidth = generalizationParentNodeLine.lineContent.lineWidth;
                    lineData.lineContent.color = generalizationParentNodeLine.lineContent.color;
                }
            }
        }
    }

    getTreeHeight(node) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (this.dataHeightMap.containsKey(node.value.id)) {
            return this.dataHeightMap.get(node.value.id);
        }

        if (this.isRootNode(node) && node.children.length != this.rootTreeNode.children.length) {
            node = this.getNodeById(node.value.id);
        }
        let height = 0;
        if (!this.isRootNode(node) && node.value.layout != NodeLayoutType.LAYOUT_TREE_RIGHT) {
            let layout = this.getNodeLayout(node);
            height = layout.getTreeHeight(node);
            this.dataHeightMap.put(node.value.id, height);
            return height;
        }

        height = this.getNodeHeight(node.value) + this.getEncircleTopHeight(node.value) + this.getEncircleBottomHeight(node.value);
        height += this.getNodeFloatExplainHeight(node);
        let chlidHeight = 0;
        let nodeGeneralizationHeight = this.getNodeGeneralizationHeight(node);
        height = Math.max(height, nodeGeneralizationHeight);
        let length = node.children.length
        for (let index = 0; index < length; index++) {
            let chlid = node.children[index]

            let verticalSpacee = chlid.value.type == MindElementType.SON_SUBJECT ? this.SonNodeVerticalSpacee : this.NodeVerticalSpacee;
            if (index == 0) {
                if (this.isRootNode(node)) {
                    verticalSpacee = this.NodeVerticalSpacee * 1.5;
                } else {
                    verticalSpacee = this.SonNodeVerticalSpacee * 4;
                }
            }
            if (!chlid.children.length == 0) {
                // chlidHeight += this.getTreeHeight(chlid) + (chlidHeight > 0 ? verticalSpacee : 0);
                chlidHeight += this.getTreeHeight(chlid) + verticalSpacee;
            } else {
                if (chlid.value.isHidden) {
                    continue;
                }
                let nodeHeight = this.getNodeHeight(chlid.value);
                let chlidGeneralizationHeight = this.getNodeGeneralizationHeight(chlid);
                nodeHeight = Math.max(nodeHeight, chlidGeneralizationHeight);
                // chlidHeight += nodeHeight + (chlidHeight > 0 ? verticalSpacee : 0);
                chlidHeight += nodeHeight + verticalSpacee;
            }
        }
        // let nodeHeight = chlidHeight > 0 ? (chlidHeight + height + this.NodeVerticalSpacee) : height;
        let treeHeight = chlidHeight + height;
        this.dataHeightMap.put(node.value.id, treeHeight);
        return treeHeight;
    }

    getTreeWidth(node, isContainSelfGeneralization = true) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        let newNode = node;
        if (this.isRootNode(node) && node.children.length != this.rootTreeNode.children.length) {
            newNode = this.getNodeById(node.value.id);
        }
        let width = 0;
        if (!this.isRootNode(newNode) && newNode.value.layout != NodeLayoutType.LAYOUT_TREE_RIGHT) {
            let layout = this.getNodeLayout(newNode);
            return layout.getTreeWidth(newNode, isContainSelfGeneralization);
        }
        width = newNode.value.width;
        if (isContainSelfGeneralization) {
            let nodeGeneralizationWidth = this.getNodeGeneralizationWidth(newNode);
            nodeGeneralizationWidth = nodeGeneralizationWidth > 0 ? nodeGeneralizationWidth + Config.GeneralizationLineWidth + Config.GeneralizationSpacing * 2 : 0;
            width = width + nodeGeneralizationWidth;
        }

        let siblingsMaxWidth = 0;
        let childType = node.value.type;
        let length = newNode.children.length
        for (let index = 0; index < length; index++) {
            let chlid = newNode.children[index]
            let chlidWidth = 0;
            if (!chlid.children.length == 0) {
                let chlidSonWith = this.getTreeWidth(chlid);
                if (chlidSonWith > chlidWidth) {
                    chlidWidth = chlidSonWith;
                }
            } else {
                if (chlid.value.isHidden) {
                    continue;
                }
                let nodeidth = chlid.value.width;
                let chlidGeneralizationWidth = this.getNodeGeneralizationWidth(chlid);
                chlidGeneralizationWidth = chlidGeneralizationWidth > 0 ? chlidGeneralizationWidth + Config.GeneralizationLineWidth + Config.GeneralizationSpacing * 2 : 0;
                nodeidth = nodeidth + chlidGeneralizationWidth;
                chlidWidth += nodeidth;
            }
            if (siblingsMaxWidth < chlidWidth) {
                siblingsMaxWidth = parseInt(chlidWidth);
            }
            childType = chlid.value.type;
        }

        if (siblingsMaxWidth > 0 && !newNode.children.isEmpty()) {
            let childNodeHorizontalSpace = this.nodeInParentHorizontalSpace(newNode.children[0], node);
            if (siblingsMaxWidth + childNodeHorizontalSpace > width) {
                return parseInt(childNodeHorizontalSpace + siblingsMaxWidth);
            } else {
                return width;
            }
        } else {
            return width;
        }
        // if (this.isRootNode(newNode)) {
        //     if (this.isFromLeftRightLayout) {
        //         if (siblingsMaxWidth > (newNode.value.width / 2 -
        //             (childType == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee) / 2)) {
        //             return newNode.value.width / 2 + (childType == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee) / 2+ siblingsMaxWidth;
        //         } else {
        //             return width;
        //         }
        //     } else {
        //         if (siblingsMaxWidth > newNode.value.width -  (childType == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee) * 2) {
        //             return  (childType == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee) * 2 + siblingsMaxWidth;
        //         } else {
        //             return width;
        //         }
        //     }
        // } else {
        //     if (siblingsMaxWidth > newNode.value.width -  (childType == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee)) {
        //         return  (childType == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee) + siblingsMaxWidth;
        //     } else {
        //         return width;
        //     }
        // }

    }

    getEncircleTopHeight(data) {
        let encircleMindElementDataDictArr = this.encircleMindElementDataDict.keys();
        let len = encircleMindElementDataDictArr.length;
        for (let index = 0; index < len; index++) {
            let key = encircleMindElementDataDictArr[index];
            let mind = this.encircleMindElementDataDict.get(key);
            if (mind.lineContent == null) {
                return 0;
            }
            let node = this.getNodeById(mind.parentNodeId);
            if (node.isEmpty()) {
                continue;
            }
            let targetIds = new Array();
            if (mind.lineContent != null) {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                targetIds.push(mind.parentNodeId);
            }
            for (let index = 0; index < targetIds.length; index++) {
                let id = targetIds[index];
                if (id == data.id) {
                    if (this.isTopNode(id, targetIds)) {
                        if (mind.lineContent.isContainText()) {
                            return new EncircleNodesPointsCalculation().space + mind.lineContent.textContent.height + mind.lineContent.padding;
                        } else {
                            return new EncircleNodesPointsCalculation().space + mind.lineContent.padding;
                        }
                    } else {
                        return 0
                    }
                }
            }
        }
        return 0;
    }

    getEncircleBottomHeight(data) {
        let encircleMindElementDataDictArr = this.encircleMindElementDataDict.keys();
        let len = encircleMindElementDataDictArr.length;
        for (let index = 0; index < len; index++) {
            let key = encircleMindElementDataDictArr[index];
            let mind = this.encircleMindElementDataDict.get(key);
            if (mind.lineContent == null) {
                return 0;
            }
            let node = this.getNodeById(mind.parentNodeId);
            if (node.isEmpty()) {
                continue;
            }
            let targetIds = new Array();
            if (mind.lineContent != null) {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                targetIds.push(mind.parentNodeId);
            }
            for (let index = 0; index < targetIds.length; index++) {
                let id = targetIds[index];
                if (id == data.id) {
                    if (this.isBottomNode(id, targetIds)) {
                        return new EncircleNodesPointsCalculation().space + mind.lineContent.padding;
                    } else {
                        return 0;
                    }
                }
            }
        }
        return 0;
    }

    nodeInParentHorizontalSpace(node, parentNode) {
        if (this.isFromLeftRightLayout && this.isRootNode(parentNode)) {
            return parentNode.value.width / 2 + (node.value.type == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee);
        } else {
            return node.value.type == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee;
        }
    }

    getLayoutType() {
        return NodeLayoutType.LAYOUT_TREE_RIGHT;
    }

    getFloatExplainLineOrientation() {
        return LineOrientation.BOTTOM;
    }

}

export default TreeRightLayout
