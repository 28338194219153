import MindType from "../../../../viewmodel/datatype/MindType"
import MindDisplayType from "../../../../viewmodel/datatype/MindDisplayType"
import NodeLayoutType from "../../../../viewmodel/datatype/NodeLayoutType"
import LineLayout from "../../../../viewmodel/datatype/LineLayout"
import MindElementType from "../../../../viewmodel/datatype/MindElementType"
import MindElementShapeType from "../../../../viewmodel/datatype/MindElementShapeType"
import LineMindTypeNodeUnit from "../../../../viewmodel/mindelementdata/LineMindTypeNodeUnit"
import MindElementCalculation from "../../calcule/elementCalculation/MindElementCalculation"
import MindDateFormat from "../MindDateFormat"
import Colors from "../../../../utils/Colors"
import LineMindTypeNode from "../../../../viewmodel/mindelementdata/LineMindTypeNode"
import MindBGStripesType from "../../../../viewmodel/datatype/MindBGStripesType"
if (
    typeof navigator === "object" &&
    typeof navigator.userAgent === "string" &&
    navigator.userAgent.indexOf("Electron") >= 0
) {
    var { ipcRenderer } = window.require("electron");

}
import {
    postMindmapNew,
} from "../../../../common/netWork/mind_map_api";
import Config from "../../calcule/Config"
import Strings from "../../../../utils/Strings"
import IdGenerator from "../../../../viewmodel/core/base/IdGenerator"
import SettingData from "../../../../viewmodel/core/minddata/SettingData"
import MindElementAlignmentType from "../../../../viewmodel/datatype/MindElementAlignmentType"
import HashMap from "../../../../viewmodel/core/base/HashMap"

class MarkdownFileImport {
    constructor(delegate, value, isResetId = true) {
        this.delegate = delegate;
        this.value = value;
        this.lineSeparatorByN = "\n";
        this.lineSeparatorByR = "\r";
        this.lineSeparatorByRN = "\r\n";
        this.lineSeparatorByNR = "\n\r";

        this.lineIndentByT = "\t";
        this.lineIndentByXmind = "#";
        this.lineIndentByXingmind = "*";
        this.lineIndentByArrow = "> ";
        this.lineIndentBySpace = "  ";
        this.lineIndentBySpace2 = "　　";
        this.base64 = 'base64';
        this.base64str = '(data:';
        this.lineIndentByTitleForMarkdown = "__";

        this.NumberChineseCharacters = ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十",
                                                "十一", "十二", "十三", "十四", "十五", "十六", "十七", "十八", "十九"];
        this.LetterCharacters = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", ""];
        
        this.StartHeadStrSeparator = "------------------------------"
        this.StartHeadStr = new Array();
        this.NotEndingRowStr = new HashMap();
        this.EndingRowStr = new HashMap();
        this.SameLevelEndingRowStr = new HashMap();
        this.allRowWidth = new HashMap();
        this.maxRowWidth = 10;
        this.symbolArr = [";", "；", ",", "，", ".", "。", "、", ":", "：", 
                          "?", "？", "|", "…", "-", "ˉ", "ˇ", "¨", "‘", 
                          "”", "～", "‖", "｀", "(", ")", "〈", "〉", "【", "】",
                          "［", "］", "｛", "｝", "[", "]", "{", "}", "!", "！"]
        this.title = "";
        this.dataList = new Array();
        this.mainMindNodeUnit;
        this.mindType = MindType.LINE_MAP;
        this.mindDisplayType = MindDisplayType.MindMap;
        this.layout = NodeLayoutType.LAYOUT_LEFT_RIGHT;
        this.lineLayout = LineLayout.CURVE_LINE;
        this.mindElementShape = MindElementShapeType.Semicircle;
        this.mindSubElementShape = MindElementShapeType.CornerShadow;
        this.name = "";
        this.subtitle = "";
        this.baseLevel = 0;
        this.mindBGColor = Colors.white;
        this.settingData = new SettingData();
        this.textRowMap = new HashMap();
        this.initData(isResetId);
    }

    initData(isResetId) {
        if (isResetId) {
            IdGenerator.shared.resetId()
        }
        this.mainMindNodeUnit = new LineMindTypeNodeUnit(this.mindBGColor, MindType.LINE_MAP, 25, false);
        this.mainMindNodeUnit.setMindBGColor(this.mainMindNodeUnit.mindMapStyleColor.getMindMapFillColor());
        this.mainMindNodeUnit.setMindDisplayType(this.mindDisplayType);
        this.mainMindNodeUnit.setElementLayout(this.layout, true);
        this.mainMindNodeUnit.globalLineLayout = this.lineLayout;
        this.mainMindNodeUnit.createRootNode();

        this.NotEndingRowStr.put(",", ",");
        this.NotEndingRowStr.put("，", ",");
        this.NotEndingRowStr.put("、", ",");
        this.NotEndingRowStr.put(":", ",");
        this.NotEndingRowStr.put("：", ",");
        this.NotEndingRowStr.put(".", ".");
        this.NotEndingRowStr.put("。", "。");

        this.EndingRowStr.put(".", ".");
        this.EndingRowStr.put(":", ":");
        this.EndingRowStr.put("：", "：");
        this.EndingRowStr.put("。", "。");
        this.EndingRowStr.put("?", "?");
        this.EndingRowStr.put("？", "？");
        this.EndingRowStr.put(";", ";");
        this.EndingRowStr.put("；", "；");
        this.EndingRowStr.put("!", "!");
        this.EndingRowStr.put("！", "！");
        this.EndingRowStr.put("|", "|");

        this.SameLevelEndingRowStr.put("|", "|");
        this.SameLevelEndingRowStr.put("。", "。");
        this.SameLevelEndingRowStr.put(".", ".");
        this.SameLevelEndingRowStr.put(";", ";");
        this.SameLevelEndingRowStr.put("；", "；");
        this.SameLevelEndingRowStr.put("?", "?");
        this.SameLevelEndingRowStr.put("？", "？");
        this.SameLevelEndingRowStr.put("!", "!");
        this.SameLevelEndingRowStr.put("！", "！");

        this.StartHeadStr.push(this.StartHeadStrSeparator + "首先");
        this.addHeadEndChar("首先", true);
        this.addHeadEndChar("其次", true);
        this.addHeadEndChar("再次", true);
        this.StartHeadStr.push(this.StartHeadStrSeparator + "Firstly");
        this.addHeadEndChar("Firstly", false);
        this.addHeadEndChar("Secondly", false);
        this.addHeadEndChar("Thirdly", false);
        this.addHeadEndChar("Fourtthly", false);
        
        this.StartHeadStr.push(this.StartHeadStrSeparator + "1");
        for (let index = 1; index < 15; index++) {
            this.addHeadEndChar("" + index, false);
        }
        this.StartHeadStr.push(this.StartHeadStrSeparator + "01");
        for (let index = 1; index < 15; index++) {
            this.addHeadEndChar("0" + index, false);
        }
        this.StartHeadStr.push(this.StartHeadStrSeparator + "第" + this.NumberChineseCharacters[0]);
        for (let index = 0; index < 15; index++) {
            if (this.NumberChineseCharacters.length > index) {
                this.addHeadEndChar("第" + this.NumberChineseCharacters[index], true);
            }
        }
        this.StartHeadStr.push(this.StartHeadStrSeparator + this.NumberChineseCharacters[0]);
        for (let index = 0; index < 15; index++) {
            if (this.NumberChineseCharacters.length > index) {
                this.addHeadEndChar(this.NumberChineseCharacters[index], false);
            }
        }
        this.StartHeadStr.push(this.StartHeadStrSeparator + this.LetterCharacters[0].toLowerCase());
        for (let index = 0; index < 15; index++) {
            if (this.LetterCharacters.length > index) {
                this.addHeadEndChar(this.LetterCharacters[index].toLowerCase(), false);
            }
        }
        this.StartHeadStr.push(this.StartHeadStrSeparator + this.LetterCharacters[0].toUpperCase());
        for (let index = 0; index < 15; index++) {
            if (this.LetterCharacters.length > index) {
                this.addHeadEndChar(this.LetterCharacters[index].toUpperCase(), false);
            }
        }
    }

    format() {
        this.dataList = this.dataSeparator();
        let nodeCount = this.dataList.length;
        if (nodeCount < 0 || this.mainMindNodeUnit.rootTreeNode == null) {
            return;
        }
        this.creatNode();
        this.setMapStyle();
        this.mainMindNodeUnit.refreshLayout(false, new SettingData());
        this.save();
    }

    getTextContent() {
        this.dataList = this.dataSeparator()
        let nodeCount = this.dataList.count
        if (nodeCount < 0) {
            return ""
        }

        var text = "";
        for (let index = 0; index < nodeCount; index++) {
            let value = this.dataList[index];
            if (value == null || value.length == 0) {
                continue;
            }
            text += (text.length == 0 ?  value : this.lineSeparatorByN + value);
        }
        return text;
    }

    setMapStyle() {
        if (this.mainMindNodeUnit.rootTreeNode == null || this.mainMindNodeUnit.rootTreeNode.isEmpty()) {
            return;
        }
        if (this.mainMindNodeUnit.rootTreeNode.children.length > 10) {
            this.layout = NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE;
            this.mainMindNodeUnit.setElementLayout(this.layout, true);
            this.mainMindNodeUnit.refreshLayout(false, this.settingData);
            this.mainMindNodeUnit.setFramework(4, this.settingData);
        } else if (this.mainMindNodeUnit.rootTreeNode.children.length > 4) {
            this.layout = NodeLayoutType.LAYOUT_RIGHT;
            this.mainMindNodeUnit.setElementLayout(this.layout, true);
            this.mainMindNodeUnit.refreshLayout(false, this.settingData);
            this.mainMindNodeUnit.setFramework(7, this.settingData);
        } else {
            this.layout = NodeLayoutType.LAYOUT_BRACKETS_RIGHT;
            this.mainMindNodeUnit.setElementLayout(this.layout, true);
            this.mainMindNodeUnit.refreshLayout(false, this.settingData);
            this.mainMindNodeUnit.setFramework(9, this.settingData);
        }

        if (this.mainMindNodeUnit.rootTreeNode.children.length > 2) {
            let rainbowColoresIndex = Math.min(Math.ceil(Math.random() * (Colors.RainbowColores.length - 1)), Colors.RainbowColores.length - 1);
            let lineColors = Colors.RainbowColores[rainbowColoresIndex];
            if (lineColors.length > 0) {
                this.settingData.lineColor = []
                for (let index = 1; index < lineColors.length; index++) {
                    this.settingData.lineColor.push(lineColors[index]);
                }
            }
            this.mainMindNodeUnit.onSelectedRainbowr(lineColors);
        }
        this.mainMindNodeUnit.rootTreeNode.value.alignmentType = MindElementAlignmentType.MIDDLE;
    }

    getTextContent() {
        this.dataList = this.dataSeparator()
        let nodeCount = this.dataList.count
        if (nodeCount < 0) {
            return ""
        }

        var text = "";
        for (let index = 0; index < nodeCount; index ++) {
            let value = this.dataList[index];
            if (value == null || value.length == 0) {
                continue;
            }
            text += text.length == 0 ?  value : lineSeparatorByN + value;
        }
        return text;
    }

    save() {
        let mindDateFormat = new MindDateFormat();
        mindDateFormat.mindBGColor = Colors.white;
        mindDateFormat.mindDisplayType = this.mindDisplayType;
        mindDateFormat.styleIndex = 3;
        mindDateFormat.version = Config.MindFormatVersion;
        mindDateFormat.mindGlobalAudio = "";
        mindDateFormat.mindBGStripesType = MindBGStripesType.NonExistent;
        mindDateFormat.settingData = this.settingData

        let freeNodes = new Array();
        mindDateFormat = mindDateFormat.setDatas(
            this.mainMindNodeUnit.rootTreeNode,
            new Array(),
            new Array(),
            new Array(),
            freeNodes,
            new Array(),
            new Array())
            .setLayout(this.layout, this.lineLayout);
        let mindNodeJson = mindDateFormat.toJSONString();
        let mindTitle = new Strings().isEmpty(this.name) ? "MindMap" : this.name;
        IdGenerator.shared.resetId()
        if (mindNodeJson != null) { //创建导图到服务器，content= mindNodeJson, ...根据服务器返回，跳转进入导图
            const obj = {
                parentEntityId: '',
                name: mindTitle,
                content: mindNodeJson,
                type: this.mindType,
                cover: '',
                subtitle: '',
            }
            postMindmapNew(
                obj,
                (res) => {
                    // this.delegate.$message.success(getString(strings.Message_Tips_Create_Success));
                    let mindId = res.id;
                    if (this.delegate.$tools.isElectron()) {
                        ipcRenderer.send("openCalendarWindow", "/mindmap?id=" + mindId);
                    } else {
                        const routerData = this.delegate.$router.resolve({
                            path: "/mindmap",
                            query: {
                                id: mindId,
                            },
                        });
                        this.createATagJump(routerData.href);
                    }
                },
                (error) => {
                    this.delegate.$message.error(error.desc);
                }
            );
            this.delegate.reload();
            this.delegate.showModal = false;//隐藏导入文件弹窗
        }
    }

    createATagJump(url) {
        const a = document.createElement('a');//创建a标签浏览器不会阻止弹出窗口
        a.setAttribute('href', url);
        a.setAttribute('target', '_blank');
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            document.body.removeChild(a);
        }, 5000);
    }

    creatNode() {
        let nodeCount = this.dataList.length;
        let mainMindNodeUnit = this.mainMindNodeUnit;
        if (mainMindNodeUnit.rootTreeNode == null) {
            return;
        }
        let rootData = mainMindNodeUnit.rootTreeNode;
        let dataListFirstElement = this.dataList[0];
        let rootInfo = this.getNodeLevel(dataListFirstElement);
        if (rootInfo.text.length == 0) {
            rootData.value.textContent.text = dataListFirstElement;
        } else {
            rootData.value.textContent.text = rootInfo.text;
            this.baseLevel = rootInfo.level;
        }

        rootData.value.mindElementShape = this.mindElementShape;
        this.cutTerms(rootData);
        MindElementCalculation.set(rootData.value).caluleTextForData();
        if (dataListFirstElement.length > 14) {
            this.name = this.removeSpecialTagForDisplay(dataListFirstElement.substring(0, 14));
        } else {
            this.name = this.removeSpecialTagForDisplay(dataListFirstElement);
        }

        if (nodeCount == 1) {
            return;
        }
        let perviewNode = mainMindNodeUnit.rootTreeNode;
        let textPerviewLevel = 0;
        for (let index = 1; index < nodeCount; index++) {
            let text = this.dataList[index];
            if (text.length > Config.Number_Imported_File_Node_Characters) {
                text = text.substr(0,Config.Number_Imported_File_Node_Characters);
            }
            let info = this.getNodeLevel(text);
            if (info.text.length == 0) {
                continue;
            }
            text = info.text;
            let isTitle = this.isTitleForMarkdown(text)
            let level = info.level - this.baseLevel;
            
            text = this.removeSpecialTagForDisplay(text)
            let newNode;

            if (level == 0) {
                newNode = mainMindNodeUnit.addChildMindElement(mainMindNodeUnit.rootTreeNode.value.id);
                newNode.value.textContent.text = text;
                perviewNode = newNode;
            } else if (perviewNode.value.level < level || textPerviewLevel < level) {
                newNode = mainMindNodeUnit.addChildMindElement(perviewNode.value.id);
                newNode.value.textContent.text = text;
                perviewNode = newNode;
            } else if (perviewNode.value.level == level || textPerviewLevel == level) {
                let parentNode = mainMindNodeUnit.getNodeById(perviewNode.value.parentNodeId);
                if (parentNode.isEmpty()) {
                    newNode = mainMindNodeUnit.addChildMindElement(mainMindNodeUnit.rootTreeNode.value.id);
                    newNode.value.textContent.text = text;
                    perviewNode = newNode;
                } else {
                    newNode = mainMindNodeUnit.addChildMindElement(parentNode.value.id);
                    newNode.value.textContent.text = text;
                    perviewNode = newNode;
                }
            } else {
                let parentNode = this.getNodeByLevel(perviewNode, level - 1);
                if (parentNode.isEmpty()) {
                    newNode = mainMindNodeUnit.addChildMindElement(mainMindNodeUnit.rootTreeNode.value.id);
                    newNode.value.textContent.text = text;
                    perviewNode = newNode;
                } else {
                    newNode = mainMindNodeUnit.addChildMindElement(parentNode.value.id);
                    newNode.value.textContent.text = text;
                    perviewNode = newNode;
                }
            }

            textPerviewLevel = level;
            if (newNode != null) {
                if (newNode.value.type == MindElementType.SUBJECT) {
                    newNode.value.mindElementShape = this.mindSubElementShape;
                }
                this.cutTerms(newNode);
                MindElementCalculation.set(newNode.value).caluleTextForData();
            }
        }
        if (rootData.children.length < 4) {
            this.layout = NodeLayoutType.LAYOUT_RIGHT;
        } else {
            this.layout = NodeLayoutType.LAYOUT_LEFT_RIGHT;
        }
    }
    cutTerms(node) {
        if (node.isEmpty() || node.value.textContent == null || node.value.textContent.text.length < 5) {
            return;
        }

        MindElementCalculation.set(node.value).caluleTextForData()
        let lineWidth = node.value.textContent.width;
        if ((node.value.type == MindElementType.MAIN_SUBJECT || node.value.type == MindElementType.SUBJECT) && lineWidth < 200) {
            return;
        } else if (node.value.type == MindElementType.SON_SUBJECT && lineWidth < 500) {
            return;
        }

        let text = node.value.textContent.text;
        let txtCount = text.length;

        let lineNum = this.caluleTextLineNum(node, (Math.ceil((txtCount) / 2)));
        let line1 = text.substring(0, lineNum);
        let line2 = text.substring(lineNum);

        text = line1 + this.lineSeparatorByN + line2;
        text = this.removeSpecialTagForDisplay(text)
        node.value.textContent.text = text;
    }

    caluleTextLineNum(node, lineNum) {
        let text = node.value.textContent.text;
        if (lineNum > text.length - 1) {
            return lineNum;
        }
        let line1 = text.substring(0, lineNum);
        let line2 = text.substring(lineNum);

        node.value.textContent.text = line1;
        MindElementCalculation.set(node.value).caluleTextForData();
        let line1Width = node.value.textContent.width;
        node.value.textContent.text = line2;
        MindElementCalculation.set(node.value).caluleTextForData();
        let line2Width = node.value.textContent.width;

        node.value.textContent.text = text;

        if (line1Width < line2Width) {
            return this.caluleTextLineNum(node, lineNum + 1);
        } else {
            return lineNum;
        }
    }

    getNodeLevel(text, base = 0, perText = "") {
        if (text.startsWith(this.lineIndentByArrow)) {
            let index = this.lineIndentByArrow.indexOf(this.lineIndentByArrow);
            if (index == -1) {
                return new NodeLevelData(text, base);
            }
            let contentText = text.replace(this.lineIndentByArrow, "");
            return this.getNodeLevel(contentText, base + 1, perText);
        } else if (text.startsWith(this.lineIndentByT)) {
            let index = this.lineIndentByT.indexOf(this.lineIndentByT);
            if (index == -1) {
                return new NodeLevelData(text, base);
            }

            let contentText = text.replace(this.lineIndentByT, "");
            return this.getNodeLevel(contentText, base + 1, perText);
        } else if (text.startsWith(this.lineIndentByXmind)) {
            let index = this.lineIndentByXmind.indexOf(this.lineIndentByXmind);
            if (index == -1) {
                return new NodeLevelData(text, base);
            }
            let contentText = text.replace(this.lineIndentByXmind, "");
            return this.getNodeLevel(contentText, base + 1, perText);
        } else if (text.startsWith(this.lineIndentByXingmind)) {
            let index = this.lineIndentByXingmind.indexOf(this.lineIndentByXingmind);
            if (index == -1) {
                return new NodeLevelData(text, base);
            }
            let contentText = text.replace(this.lineIndentByXingmind, "");
            return this.getNodeLevel(contentText, base + 1, perText);
        } else if (text.startsWith(this.lineIndentBySpace)) {
            let index = this.lineIndentBySpace.indexOf(this.lineIndentBySpace);
            if (index == -1) {
                return new NodeLevelData(text, base);
            }
            let contentText = text.replace(this.lineIndentBySpace, "");
            return this.getNodeLevel(contentText, base + 1, perText);
        } else if (text.startsWith(this.lineIndentBySpace2)) {
            let index = this.lineIndentBySpace2.indexOf(this.lineIndentBySpace2);
            if (index == -1) {
                return new NodeLevelData(text, base);
            }
            let contentText = text.replace(this.lineIndentBySpace2, "");
            return this.getNodeLevel(contentText, base + 1, perText);
        }

        return new NodeLevelData(text, base);
    }

    removeRowEndSymbol(text) {
        if (text == null) {
            return ""
        }
        if (text.length < 2) {
            return text
        }
        let endChart = text.substring(text.length - 1, text.length)
        if (this.NotEndingRowStr.containsKey(endChart)) {
            return this.removeRowEndSymbol(text.substring(0, text.length - 1))
        } else {
            return text
        }
    }

    dataSeparator() {
        var list = [];
        let separator = this.lineSeparatorByN;
        if (this.value.includes(this.lineSeparatorByRN)) {
            separator = this.lineSeparatorByRN;
        } else if (this.value.includes(this.lineSeparatorByNR)) {
            separator = this.lineSeparatorByNR;
        } else if (this.value.includes(this.lineSeparatorByN)) {
            separator = this.lineSeparatorByN;
        } else if (this.value.includes(this.lineSeparatorByR)) {
            separator = this.lineSeparatorByR;
        }
        
        let parts = this.value.split(separator);
        let count = parts.length;
        if (count > 0) {
            let lastLabel = ""
            let lastLevelData = null
            for (let index = 0; index < count; index++) {
                let text = parts[index]
                let label = this.removeSpecialTagForDisplay(text.trim());
                if (label.length > 0) {
                    if (!label.includes(this.base64) && !label.includes(this.base64str)) {
                        if (label.length > 1 || !this.symbolArr.includes(label)) {
                            list.push(label);
                            let levelData = this.getNodeLevel(text, 0)
                            if (lastLevelData == null) {
                                this.textRowMap.put(label, levelData)
                            } else {
                                let labelIsLineStart = false
                                let labelIsLineStartFirst = false
                                let lastLabelIsLineStart = false
                                let isSameSerialNumberSeries = false
                                let lastStartHeadStrSeparatorValue = ""
                                let sameSerialNumberSeriesList = new Array()
                                for (let j = 0; j < this.StartHeadStr.length; j++) {
                                    let cellHead = this.StartHeadStr[j]
                                    if (cellHead.startsWith(this.StartHeadStrSeparator)) {
                                        lastStartHeadStrSeparatorValue = cellHead.substring(this.StartHeadStrSeparator.length, cellHead.length)
                                        sameSerialNumberSeriesList = new Array()
                                    } else {
                                        sameSerialNumberSeriesList.push(cellHead)
                                    }
                                    if (!labelIsLineStart && label.startsWith(cellHead)) {
                                        labelIsLineStart = true
                                        if (j > 0) {
                                            if (cellHead.includes(lastStartHeadStrSeparatorValue)) {
                                                isSameSerialNumberSeries = false
                                                labelIsLineStartFirst = true
                                            } else {
                                                labelIsLineStartFirst = false
                                                for (let i = 0; i < sameSerialNumberSeriesList.length; i++) {
                                                    const element = sameSerialNumberSeriesList[i];
                                                    if (lastLabel.startsWith(element)) {
                                                        isSameSerialNumberSeries = true
                                                        break
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    if (!lastLabelIsLineStart && lastLabel.startsWith(cellHead)) {
                                        lastLabelIsLineStart = true
                                    }
                                }
                                if (!lastLabelIsLineStart && labelIsLineStart) {
                                    if (levelData.level <= lastLevelData.level && labelIsLineStartFirst) {
                                        levelData.level = lastLevelData.level + 1
                                    }
                                } else if (lastLabelIsLineStart && labelIsLineStart) {
                                    if (isSameSerialNumberSeries) {
                                        levelData.level = lastLevelData.level
                                    } else if (labelIsLineStartFirst) {
                                        levelData.level = lastLevelData.level + 1
                                    }
                                }
                                this.textRowMap.put(label, levelData)
                            }
                            
                            lastLabel = label
                            lastLevelData = levelData
                        }
                    }
                }
            }
        }
        if (list.length == 0) {
            list.push(this.value);
            let levelData = this.getNodeLevel(this.value, 0)
            this.textRowMap.put(this.value, levelData)
        }
        if (list.length > Config.Number_Imported_File_Nodes) {
            list.length = Config.Number_Imported_File_Nodes;
        }
        return list;
    }

    getNodeByLevel(node, level) {
        if (node.value.level == level) {
            return node;
        } else {
            let parentNode = this.mainMindNodeUnit.getNodeById(node.value.parentNodeId);
            if (!parentNode.isEmpty()) {
                return this.getNodeByLevel(parentNode, level);
            } else {
                return new LineMindTypeNode();
            }
        }
    }

    removeSpecialTagForDisplay(text) {
        if (text.endsWith(this.lineIndentByXingmind)) {
            text = text.substr(0, text.length - this.lineIndentByXingmind.length)
        }
        if (text.startsWith(this.lineIndentByTitleForMarkdown) &&
            text.endsWith(this.lineIndentByTitleForMarkdown)) {
            text = text.substr(this.lineIndentByTitleForMarkdown.length, text.length - this.lineIndentByTitleForMarkdown.length)
            text = text.substr(0, text.length - this.lineIndentByTitleForMarkdown.length)
        }
        return this.removeRowEndSymbol(text)
    }

    isTitleForMarkdown(text) {
        if (text.startsWith(this.lineIndentByTitleForMarkdown) &&
            text.endsWith(this.lineIndentByTitleForMarkdown)) {
            return true
        }
        return false
    }

    isSameLevelEndChar(fullText, text) {
        if (fullText == null || fullText.length == 1 ||
            text == null || text.length == 0) {
            return true
        }
        if (!fullText.includes(text)) {
            return true
        }
        let parts = fullText.split(text);
        if (parts.length > 0) {
            let checkText = parts[0].trim()
            let keys = this.SameLevelEndingRowStr.keys()
            for (let index = 0; index < keys.length; index++) {
                if (checkText.endsWith(keys[index])) {
                    return true
                }
            }
        }
        return false
    }

    splitStrByEndChar(text) {
        let splitArray = [];
        if (text == null || text.length == 0) {
            return splitArray;
        }
        let specialSymbols = [".", "|", "*", "$", "?"];
        let keys = this.EndingRowStr.keys()
        for (let index = 0; index < keys.length; index++) {
            const key = keys[index];
            let symbol = key;
            for (let specialSymbolsIndex = 0; specialSymbolsIndex < specialSymbols.length; specialSymbolsIndex++) {
                if (specialSymbols[specialSymbolsIndex] == symbol) {
                    symbol = "\\" + symbol;
                    break;
                }
            }
            if (symbol == ":" && (text.indexOf("http://") || text.indexOf("https://"))) {
                continue;
            }
            let arr = text.split(symbol);

            if (arr == null || arr.length <= 1) {
                continue;
            }
            if (arr.length > 1) {
                for (let splitIndex = 0; splitIndex < arr.length; splitIndex++) {
                    if (arr[splitIndex] != null && arr[splitIndex].length > 0) {
                        let list = this.splitStrByEndChar(arr[splitIndex]);
                        if (list.length > 1) {
                            splitArray = splitArray.concat(list)
                        } else {
                            splitArray.push(arr[splitIndex]);
                        }
                    }
                }
                break;
            }
        }
        if (splitArray.length == 0) {
            splitArray.push(text);
        }
        return splitArray;
    }

    addHeadEndChar(head, isAddSelf) {
        if (head == null || head.length == 0) {
            return;
        }
        this.StartHeadStr.push(head + ".");
        this.StartHeadStr.push(head + "。");
        this.StartHeadStr.push(head + ":");
        this.StartHeadStr.push(head + "：");
        this.StartHeadStr.push(head + "、");
        this.StartHeadStr.push(head + ",");
        this.StartHeadStr.push(head + "，");
        this.StartHeadStr.push(head + ")");
        this.StartHeadStr.push(head + "）");
        this.StartHeadStr.push(head + "》");
        this.StartHeadStr.push(head + ">");
        this.StartHeadStr.push(head + "->");
        this.StartHeadStr.push(head + "|");
        this.StartHeadStr.push("(" + head);
        this.StartHeadStr.push("（" + head);
        this.StartHeadStr.push("《" + head);
        this.StartHeadStr.push("[" + head);
        this.StartHeadStr.push("【" + head);
        this.StartHeadStr.push("|" + head);
        this.StartHeadStr.push("「" + head);
        this.StartHeadStr.push(head + " ");
        if (isAddSelf) {
            this.StartHeadStr.push(head);
        }
    }
}

class NodeLevelData {
    constructor(text, level) {
        this.text = text;
        this.level = level;
    }
}

export default MarkdownFileImport