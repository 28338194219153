<template>
  <div @click.stop="hideImg" v-if="showBox" class="big-img-box">
      <div class="center-big-img" @click.stop="openVideo">
          <!-- //../../../assets/img/Usehelp-English/7-DE-InsertRemarkLink-web.mp4 -->
        <video :src="videoUrl" style="" class="center-big-video-url" controls="controls" autoplay="autoplay" :poster="imgUrl">
        </video>
      </div>
      <!-- <img class="center-big-img" :style="{backgroundUrl:''}" :src="imgUrl" alt=""> -->
  </div>
</template>

<script>
import HttpServerConfig from '../../../common/HttpServerConfig';
import LoadLocalXJMind from '../../../viewmodel/core/tools/mapfile/LoadLocalXJMind';
export default {
    data() {
        return {
            showBox: false,
            imgUrl: "",
            videoUrl: "",
            backgroundSize: 'contain'
        }
    },
    created() {
        this.bus.$on("examineVideoImg", (msg) => {
            // console.log(msg);
            this.showBox = true;
            this.imgUrl = LoadLocalXJMind.getLocalFilePath(HttpServerConfig.getMediaSrc(msg.src))
            this.videoUrl = LoadLocalXJMind.getLocalFilePath(HttpServerConfig.getMediaSrc(msg.videoSrc))
        });
    },
    methods: {
        hideImg () {
            this.showBox = false;
        },
        openVideo(){
            this.showBox = true; 
        }
    }
}
</script>

<style lang="less" scoped>
    .big-img-box {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 99999;
        background-color: rgba(0,0,0,.1);
        .center-big-img {
            position: absolute;
            background-position: center center;
            background-repeat:no-repeat;
            // background-size: contain;
            max-width: 80%;
            max-height: 90%;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }
        @media screen and(max-width:800px){
            .center-big-img {
                position: absolute;
                background-position: center center;
                background-repeat:no-repeat;
                // background-size: contain;
                width: 100%;
                max-height: 90%;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
            }
            .center-big-video-url{
                max-width:90%;
            }
        }
        @media screen and(min-width:801px){
            .center-big-video-url{
                max-height:60%;
                max-width: 80%;
                object-fit: inherit;
            }
        }
    }
</style>