<template>
  <div
    id="taskList"
    class="element-menu-list"
    v-show="showTaskMenuList"
    v-clickoutside="outsideCloseIconCompoenntAccout"
    :style="{ left: newMenuX + 'px', top: newMenuY + 'px' }"
  >
    <template v-if="MindTaskMenuType == 'completion'">
      <div
        v-for="(item, index) in showCompletionMenuListData"
        :key="index"
        class="element-menu-children-list"
      >
        <div
          v-for="(each,count) in item"
          :key="count"
          @click="checkMenuElement(each.name)"
        >
          <div v-if="each.show" class="each-menu">
            <img :src="each.iconUrl" class="each-icon" :alt="each.name" />
            <div v-if="each.id == 16" class="each-icon-name">{{getString(each.name)}}</div>
            <div v-else class="each-icon-name">{{each.name}}</div>
            <!-- <img v-if="each.needMember && !isMember()" src="../../../assets/img/user/icon/vip.svg" class="need-member" alt="vip"> -->
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="MindTaskMenuType == 'weight'">
      <div
        v-for="(item, index) in showWeightDataMenu"
        :key="index"
        class="element-menu-children-list"
      >
        <div
          v-for="each in item"
          :key="each.id"
          @click="checkMenuElement(each.name)"
        >
          <div v-if="each.show" class="each-menu">
            <img :src="each.iconUrl" class="each-icon" alt="" />
            <!-- <div class="each-icon-name">{{getString(each.name)}}</div> -->
            <div class="each-icon-name">{{getString(each.name)}}</div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="MindTaskMenuType == 'edit'">
      <div
        v-for="(item, index) in showEditDataMenu"
        :key="index"
        class="element-menu-children-list"
      >
        <div
          v-for="each in item"
          :key="each.id"
          @click="checkMenuElement(each.name)"
        >
          <div v-if="each.show" class="each-menu">
            <img :src="each.iconUrl" class="each-icon" alt="" />
            <div class="each-icon-name">{{getString(each.name)}}</div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import getString from '../../../common/lang/language';
import strings from '../../../common/lang/strings';
import Template from '../../../views/template/Template.vue';
import ComplieChildMenuForm from "../ComplieRightMenuChild/ComplieChildMenuForm";
import ComplieChildMenuNodeQuickStyle from "../ComplieRightMenuChild/ComplieChildMenuNodeQuickStyle";

export default {
  name: "ComplieLeftContentMenu",
  props: [
    "showTaskMenuList",
    "newMenuY",
    "newMenuX",
    "MindTaskMenuType",
    "hiddenMindButtonArray",
    "fullHeight",
    "fullWidth",
  ],
  data() {
    return {
      showCompletionMenuListData: [
        [
          {
            id: 16,
            name: strings.Global_Edit_Task,//"编辑任务"
            iconUrl: require("../../../assets/img/canvasMenu/canvasMenu1.svg"),
            type: "completion",
            show: true
          },
        ],
        [
          {
            id: 0,
            name: "0%",
            iconUrl: require("../../../assets/img/task/completion0.svg"),
            type: "completion",
            show: true
          },
          {
            id: 1,
            name: "10%",
            iconUrl: require("../../../assets/img/task/completion1.svg"),
            type: "completion",
            show: true
          },
          {
            id: 2,
            name: "20%",
            iconUrl: require("../../../assets/img/task/completion2.svg"),
            type: "completion",
            show: true
          },
          {
            id: 3,
            name: "30%",
            iconUrl: require("../../../assets/img/task/completion3.svg"),
            type: "completion",
            show: true
          },
          {
            id: 4,
            name: "40%",
            iconUrl: require("../../../assets/img/task/completion4.svg"),
            type: "completion",
            show: true
          },
          {
            id: 5,
            name: "50%",
            iconUrl: require("../../../assets/img/task/completion5.svg"),
            type: "completion",
            show: true
          },
          {
            id: 6,
            name: "60%",
            iconUrl: require("../../../assets/img/task/completion6.svg"),
            type: "completion",
            show: true
          },
          {
            id: 7,
            name: "70%",
            iconUrl: require("../../../assets/img/task/completion7.svg"),
            type: "completion",
            show: true
          },
          {
            id: 8,
            name: "80%",
            iconUrl: require("../../../assets/img/task/completion8.svg"),
            type: "completion",
            show: true
          },
          {
            id: 9,
            name: "90%",
            iconUrl: require("../../../assets/img/task/completion9.svg"),
            type: "completion",
            show: true
          },
          {
            id: 10,
            name: "100%",
            iconUrl: require("../../../assets/img/task/completion10.svg"),
            type: "completion",
            show: true
          }

        ],
      ], // 菜单里面的数据
      showWeightDataMenu: [
        [
          {
            id: 15,
            name: strings.Global_Edit_Task,//"编辑任务"
            iconUrl: require("../../../assets/img/canvasMenu/canvasMenu1.svg"),
            type: "weight",
            show: true
          },

        ],
        [
          {
            id: 11,
            name: strings.Mind_Lower,//strings.Global_Edit,
            iconUrl: require("../../../assets/img/task/weight1.svg"),
            type: "weight",
            show: true
          },
          {
            id: 12,
            name: strings.Mind_Ordinary,
            iconUrl: require("../../../assets/img/task/weight2.svg"),
            type: "weight",
            show: true
          },
          {
            id: 13,
            name: strings.Mind_Urgent,
            iconUrl: require("../../../assets/img/task/weight3.svg"),
            type: "weight",
            show: true
          },
          {
            id: 14,
            name: strings.Mind_Extremely_Urgent,
            iconUrl: require("../../../assets/img/task/weight4.svg"),
            type: "weight",
            show: true
          }
        ]
      ],
      showEditDataMenu: [
        [
          {
            id: 16,
            name: strings.Global_Edit_Task,//"编辑任务"
            iconUrl: require("../../../assets/img/canvasMenu/canvasMenu1.svg"),
            type: "edit",
            show: true
          },
          {
            id: 17,
            name: strings.Global_Detele_Task,//"删除任务"
            iconUrl: require("../../../assets/img/canvasMenu/canvasMenu9.svg"),
            type: "edit",
            show: true
          },
        ],
      ],
      strings:strings,
      muneShapeLeft:160,
      muneShapeTop:0,
      muneStyleLeft:160,
      muneStyleTop:0,
    };
  },
  created(){
    // this.insertPicturesIsNeedMembers()
  },
  mounted(){
    this.getMenuLocation();
  },
  components: {
    ComplieChildMenuForm,
    ComplieChildMenuNodeQuickStyle
  },
  methods: {
    ...mapMutations(["showCropperImg", "showCropperImgUrl"]),

    getMenuLocation(){
      if(this.fullWidth - this.newMenuX > 268){
        this.muneLeft = 160;
      }
    },

    getString(i){
      return getString(i)
    },
    outsideCloseIconCompoenntAccout() {
      let obj = {name:'colse'};
      this.$emit("checkMenuTask", obj);
    },
    checkMenuElement(name) {
      let obj = {};
      obj.name = name;
      this.$emit("checkMenuTask", obj);
    },
  },
  watch: {
    showTaskMenuList(newVal, old) {
      // console.log('改变了菜单状态',newVal)
    },
    newMenuX:{
      handler(newVal){
        let root = document.getElementById("canvas-father")
        let scrollLeft = root.scrollLeft;
        newVal = newVal - scrollLeft
        if(this.fullWidth - newVal > 428){
          this.muneShapeLeft = 160;
        }else{
          this.muneShapeLeft = -268;
        }
        if(this.fullWidth - newVal > 358){
          this.muneStyleLeft = 230;
        }else{
          this.muneStyleLeft = -128;
        }
      },
      deep:true
    },
    newMenuY:{
      handler(newVal){
        let root = document.getElementById("canvas-father")
        let scrollTop = root.scrollTop;
        newVal = newVal - scrollTop
        if(this.fullHeight - (118 + newVal) >= 581){
          this.muneShapeTop = 0;
        }else{
          this.muneShapeTop = this.fullHeight - (118 + newVal + 581);
        }
        // this.muneStyleTop = 0;
      },
      deep:true
    }
  },
};
</script>

<style lang='less' scoped>
/* 元素菜单列表开始 */
.element-menu-list {
  // width: 150px;
  // height: 300px;
  position: absolute;
  left: 200px;
  top: 300px;
  z-index: 999;
  background-color: #fff;
  user-select: "none";
  box-shadow: 0px 3px 10px rgba(80, 80, 80, 0.16);
  border: 1px solid #ccc;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 6px;
  .hiddenImgInput {
    display: none;
  }

  .element-menu-children-list {
    border-bottom: 1px solid #ddd8d8;
    .each-menu {
      display: flex;
      align-items: center;
      width: 130px;;
      height: 32px;
      cursor: pointer;
      position: relative;
      .checkMindShape {
        display: none;
        position: absolute;
        background-color: #fff;
      }

      .each-icon {
        width: 18px;
        height: 18px;
        display: block;
        margin-left: 20px;
      }
      .each-icon-name {
        margin-left: 11px;
        font-size: 13px;
        color: #666;
        white-space: nowrap;
      }
      .each-icon-triange {
        // width: 12px;
        // height: 12px;
        display: block;
        margin-left: 140px;
        margin-top: 3px;
        position: absolute;
      }
      .need-member{
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }
      &:hover {
        background-color: #e0e0e0;
      }
      &:hover .checkMindShape {
        display: block;
        z-index: 999;
      }
    }

    &:last-child {
      border-bottom: transparent;
    }
  }

  .off-chart {
    border-bottom: 1px solid #eee;
  }
}
/* 元素菜单列表结束 */
</style>