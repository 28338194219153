// package mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent;

/**
 * Created by tony on 2020/5/27
 */

class FormPointData {
    constructor(startX = -1, startY = -1, endX = -1, endY = -1){
        this.startX = startX;
        this.startY = startY;
        this.endX = endX;
        this.endY = endY;
    }


    FormPointData(startX, startY, endX, endY) {
        this.startX = startX;
        this.startY = startY;
        this.endX = endX;
        this.endY = endY;
    }
}
export default FormPointData