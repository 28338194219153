<template>
  <div class="task_edit_bg">
  
    <div class="task_edit_content" id="taskEditBg"
    :style="{left:(fullWidth-302)+'px'}"
    v-show="showEditTask">
        <div class="task_edit_content_head">
          <span class="task_edit_content_head_title">{{getString(this.strings.Mind_Task_Information)}}:{{nodeName}}</span>
          <span class="task_edit_content_head_delete" @click.stop="deleteEditTask">
            {{getString(this.strings.Global_Detele_Task)}}
          </span>
        </div>
        <div class="task_edit_content_content">
          <div class="task_edit_content_weight">
            <div class="task_edit_content_weight_name">
              <label style="float: right;">{{getString(this.strings.Mind_Style_Add_Icon_Priority)}}:</label>
            </div>
            <div class="task_edit_content_weight_select">
              <a-select default-value="lucy" v-model="taskWeight" @change="setTask(false)" style="width: 193px;height:30px">
                <a-select-option value="1">
                  {{getString(this.strings.Mind_Lower)}}
                </a-select-option>
                <a-select-option value="2">
                  {{getString(this.strings.Mind_Ordinary)}}
                </a-select-option>
                <a-select-option value="3">
                  {{getString(this.strings.Mind_Urgent)}}
                </a-select-option>
                <a-select-option value="4">
                  {{getString(this.strings.Mind_Extremely_Urgent)}}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="task_edit_content_completion">
            <div class="task_edit_content_completion_name">
              <label style="float: right;">{{getString(this.strings.Mind_Style_Add_Icon_Progress)}}:</label>
            </div>
            <!-- < -->
            <div class="task_edit_content_completion_slider">
              <a-slider
              style="top:-5px;"
              @change="setTask(false)"
                v-model="taskCompletion"
                :tooltip-visible="false"
                :min="0"
                :max="100"
              />
            </div>
            <div class="task_edit_content_completion_number">
              <span>{{taskCompletion}}</span>
            </div>
          </div>
          <div class="task_edit_content_start">
            <div class="task_edit_content_start_name" >
              <label style="float: right;">{{getString(this.strings.Mind_Start_Date)}}:</label>
            </div>
            <div class="task_edit_content_start_date">
              <a-date-picker @change="onChangeStart" format='YYYY-MM-DD' v-model="taskStartTime" style="width: 193px;" :placeholder="getString(this.strings.Mind_Please_Select_An_Start_Date)" />
            </div>
          </div>
          <div class="task_edit_content_end">
            <div class="task_edit_content_end_name" >
              <label style="float: right;">{{getString(this.strings.Mind_End_Date)}}:</label>
            </div>
            <div class="task_edit_content_end_date">
              <a-date-picker @change="onChangeEnd" format="YYYY-MM-DD" v-model="taskEndTime" style="width: 193px;" :placeholder="getString(this.strings.Mind_Please_Select_An_End_Date)"  />
            </div>
          </div>
          <div class="task_edit_content_name">
            <div class="task_edit_content_name_name">
              <label style="float: right;"><span style="color:red;">*</span>{{getString(this.strings.Mind_person_Charge)}}:</label>
            </div>
            <div class="task_edit_content_name_input">
              <a-input @change="setTask(false)" @focus="focusInput" @blur="blurInput" v-model="taskName" style="width: 193px;" :placeholder="getString(this.strings.Mind_Enter_Names_Separated_By_Commas)" />
            </div>
          </div>
          <div class="task_edit_content_btn">
            <div class="task_edit_content_btn1" @click.stop="colseEditTask">{{getString(this.strings.Mind_colse)}}</div>
            <div class="task_edit_content_btn2" @click.stop="setTask(true)">{{getString(this.strings.Global_Ok)}}</div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import getString from '../../../common/lang/language';
import strings from '../../../common/lang/strings';
import Template from '../../../views/template/Template.vue';
import ComplieChildMenuForm from "../ComplieRightMenuChild/ComplieChildMenuForm";
import ComplieChildMenuNodeQuickStyle from "../ComplieRightMenuChild/ComplieChildMenuNodeQuickStyle";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
import dataTimeToFormats from '../../../utils/timestampToTime';
import Util from '../../../utils/Util';
import People from '../../../viewmodel/mindelementdata/mindcontent/People';

export default {
  props: [
    "showEditTask",
    "dataNode",
    "fullHeight",
    "fullWidth",
  ],
  data() {
    return {
      strings:strings,
      taskWeight:"1",
      taskCompletion:0,
      taskStartTime:dataTimeToFormats.timestampToTime(Util.getCurrentTimeInsecond()),
      taskEndTime:'',
      taskName:'',
      dataNodeTask:null,
      nodeName:'',
      node:null,

    };
  },
  created(){
    // this.insertPicturesIsNeedMembers()
  },
  mounted(){
    this.dragDown1();
  },
  components: {
    ComplieChildMenuForm,
    ComplieChildMenuNodeQuickStyle
  },
  methods: {
    ...mapMutations(["setIsShowEditTask","useShortcut"]),
    getString(i){
      return getString(i)
    },
    deleteEditTask(){
      let that = this;
        this.$confirm({
          title: getString(strings.Mind_Tips),
          content: getString(strings.Mind_Are_You_Sure_To_Delete_This_Task),
          centered: true,
          okText: getString(strings.Mind_Edit_Confirm),
          cancelText: getString(strings.Mind_Group_Cancel),
          onOk() {
            EditMindmapVM.deleteTask();
            that.node = EditMindmapVM.getSelectedNode();
            that.dataNodeTask = that.node.value.taskContent;
            // if(that.node == null || that.node == undefined){
            //   that.nodeName = "未选中节点";
            // }else{
            //   if(that.node.value.textContent != null){
            //     if(that.node.value.textContent.text != null && that.node.value.textContent.text != ""){
            //       that.nodeName = that.node.value.textContent.text;
            //     }
            //   }else if(that.node.value.generalizationContent != null){
            //     if(that.node.value.generalizationContent.text != null && that.node.value.generalizationContent.text != ""){
            //       that.nodeName = that.node.value.generalizationContent.text;
            //     }
            //   }
            // }
              
            that.getTaskContent();
          },
          onCancel() {
          },
        });
    },
    colseEditTask(){
      this.setIsShowEditTask(false);
    },
    focusInput(){
      document.getElementById("taskEditBg").removeEventListener("mousedown",this.dragDown);
      // document.onmousemove = null;
      // document.onmouseup = null
    },
    blurInput(){
      this.dragDown1();
    },
    dragDown1(){
      document.getElementById("taskEditBg").addEventListener("mousedown",this.dragDown);
    },
    dragDown:function(e){
      window.event ? window.event.cancelBubble = true : e.stopPropagation();
      let oDiv = document.getElementById("taskEditBg");
      let disX = e.clientX - oDiv.offsetLeft;
      let disY = e.clientY - oDiv.offsetTop;
      document.onmousemove = (el)=>{
        let left = el.clientX - disX;
        let top = el.clientY - disY;
        oDiv.style.left = left + 'px';
        oDiv.style.top = top + 'px';
      }

      document.onmouseup = (e)=>{
        document.onmousemove = null;
        document.onmouseup = null;
      }
    },
    getTaskContent(){
      let time = Util.getCurrentTimeInsecond();
      if(this.dataNodeTask == null || this.dataNodeTask == ""){
        this.taskWeight = "1";
        this.taskCompletion = 0;
        this.taskStartTime = dataTimeToFormats.timestampToTime(time);
        this.taskEndTime = '';
        this.taskName = '';
      }else{
        this.taskWeight = this.dataNodeTask.priority+"";
        this.taskCompletion = this.dataNodeTask.taskProgress;
        if(this.dataNodeTask.startTime > 0){
          let time = dataTimeToFormats.timestampToTime(this.dataNodeTask.startTime);
          this.taskStartTime = time;
        }else{
          this.taskStartTime = '';
        }
        if(this.dataNodeTask.endTime > 0){
          let time = dataTimeToFormats.timestampToTime(this.dataNodeTask.endTime)
          this.taskEndTime = time;
        }else{
          this.taskEndTime = '';
        }
        let name = '';
        for(var index = 0;index < this.dataNodeTask.peoples.length;index++){
          if(index == 0){
            name = this.dataNodeTask.peoples[index].name;
          }else{
            name = name + ',' + this.dataNodeTask.peoples[index].name;
          }
        }
        this.taskName = name;
      }
      
    },

    onChangeStart(value,datestring){
      this.taskStartTime = datestring;
      this.setTask(false);
    },
    onChangeEnd(value,datestring){
      this.taskEndTime = datestring;
      this.setTask(false);
    },

    setTask(boolean){
      if((this.dataNodeTask == null || this.dataNodeTask == "")&& !boolean){
        return;
      }
      if(this.taskName == "" || this.taskName == null ){
        this.$message.error(getString(strings.Mind_The_Task_Leader_Cannot_Be_Empty));
        return;
      }
      if(this.node == undefined || this.node == null ){
        this.$message.error(getString(strings.Global_Add_Target_Tips));
        return;
      }
      let startTaskTime;
      let endTaskTime;
      if(this.taskStartTime == "" || this.taskStartTime == null ){
        startTaskTime = -1;
      }else{
        let StartTime = this.taskStartTime+" 00:00:01";
        StartTime = StartTime.replaceAll("-","/");
        startTaskTime = parseInt(new Date(StartTime).getTime()/1000);
      }
      if(this.taskEndTime == "" || this.taskEndTime == null ){
        endTaskTime = -1;
      }else{
        let endTime = this.taskEndTime + " 23:59:59"
        endTime = endTime.replaceAll("-","/");
        endTaskTime = parseInt(new Date(endTime).getTime()/1000);
      } 
      if((startTaskTime > endTaskTime) && startTaskTime>-1 && endTaskTime>-1){
        //getString(strings.Message_Tips_Share_Link_Does_Not_Exist)
        this.$message.error(getString(strings.Mind_The_End_Time_Less_Start_Time));
        return;
      }
      let strName = this.taskName.replace(/[\uff0c]/g,",");
      let names = strName.split(",");
      let peoples = [];
      for(var index=0;index<names.length;index++){
        let people = new People(names[index]);
        peoples.push(people);
      }
      let priority = parseInt(this.taskWeight);
      let taskProgress = this.taskCompletion;
      EditMindmapVM.setNodeTask(startTaskTime,endTaskTime,peoples,priority,taskProgress,this.node.value.id);
      this.dataNodeTask = EditMindmapVM.getSelectedNode().value.taskContent;
    }

  },
  watch: {
    '$store.state.tsakSelectedNodeId': {
      handler(newVal){
        this.node = EditMindmapVM.getSelectedNode();
        this.dataNodeTask = this.node.value.taskContent;
        if(this.node == null || this.node == undefined){
          this.nodeName = "未选中节点";
        }else{
          if(this.node.value.textContent != null){
            if(this.node.value.textContent.text != null && this.node.value.textContent.text != ""){
              this.nodeName = this.node.value.textContent.text;
            }
          }else if(this.node.value.generalizationContent != null){
            if(this.node.value.generalizationContent.text != null && this.node.value.generalizationContent.text != ""){
              this.nodeName = this.node.value.generalizationContent.text;
            }
          }
        }
           
        // console.log(this.node,this.dataNodeTask,this.nodeName);                                                                                                                                                                        ;
        this.getTaskContent();
      },
      deep:true
    },
    showEditTask(newVal){
      if(newVal){
        this.useShortcut(false);
      }else{
        this.useShortcut(true);
      }
    }
  },
};
</script>

<style lang='less' scoped>
.task_edit_bg{
  position: fixed;
  z-index: 999;
}

/* 元素菜单列表开始 */
  .task_edit_content{
    z-index: 999;
    position: absolute;
    width: 308px;
    height: 302px;
    float: right;
    top: 0px;
    left: 20px;
    background-color: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #f0f0f0;
    user-select: none;
    .task_edit_content_head{
      position: relative;
      width: 308px;
      height: 36px;
      background-color: #EFEFEF;
      border-radius: 4px 4px 0 0;
      .task_edit_content_head_title{
        width: 138px;
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 20px;
        color: #333333;
        opacity: 1;
        position: absolute;
        margin-left: 16px;
        margin-top: 8px;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        overflow: hidden;
      }
      .task_edit_content_head_delete{
        height: 27px;
        border: 1px solid #DDDDDD;
        opacity: 1;
        border-radius: 2px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 27px;
        color: #666666;
        position: relative;
        float: right;
        text-align: center;
        margin-right: 8px;
        margin-top: 5px;
        padding: 0 4px;
        cursor: pointer;
      }
      .task_edit_content_head_delete:hover{
        color: #ff7354;
        border-color: #ff7354;
      }
    }
    .task_edit_content_content{
      position: relative;
      width: 308px;
      height: 242px;
      .task_edit_content_weight{
        position: relative;
        width: 308px;
        height: 30px;
        margin-top: 14px;
        .task_edit_content_weight_name{
          width: 89px;
          height: 30px;
          position: absolute;
          line-height: 30px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .task_edit_content_weight_select{
          width: 209px;
          height: 30px;
          position: absolute;
          line-height: 30px;
          margin-left: 97px;
        }
      }
      .task_edit_content_completion{
        position: relative;
        width: 308px;
        height: 30px;
        margin-top: 8px;
        .task_edit_content_completion_name{
          width: 89px;
          height: 30px;
          position: absolute;
          line-height: 30px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .task_edit_content_completion_slider{
          width: 179px;
          height: 30px;
          position: absolute;
          line-height: 30px;
          margin-left: 97px;
        }
        .task_edit_content_completion_number{
          position: absolute;
          height: 30px;
          line-height: 30px;
          width: 30px;
          margin-left: 276px;
        }
      }
      .task_edit_content_start{
        position: relative;
        width: 308px;
        height: 30px;
        margin-top: 8px;
        .task_edit_content_start_name{
          width: 89px;
          height: 30px;
          position: absolute;
          line-height: 30px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .task_edit_content_start_date{
          width: 209px;
          height: 30px;
          position: absolute;
          line-height: 30px;
          margin-left: 97px;
        }
      }
      .task_edit_content_end{
        position: relative;
        width: 308px;
        height: 30px;
        margin-top: 8px;
        .task_edit_content_end_name{
          width: 89px;
          height: 30px;
          position: absolute;
          line-height: 30px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .task_edit_content_end_date{
          width: 209px;
          height: 30px;
          position: absolute;
          line-height: 30px;
          margin-left: 97px;
        }
      }
      .task_edit_content_name{
        position: relative;
        width: 308px;
        height: 30px;
        margin-top: 8px;
        .task_edit_content_name_name{
          width: 89px;
          height: 30px;
          position: absolute;
          line-height: 30px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .task_edit_content_name_input{
          width: 209px;
          height: 30px;
          position: absolute;
          line-height: 30px;
          margin-left: 97px;
        }
      }
      .task_edit_content_btn{
        position: relative;
        width: 308px;
        height: 30px;
        margin-top: 19px;
        .task_edit_content_btn1{
          width: 68px;
          height: 30px;
          border: 1px solid #EEEEEE;
          opacity: 1;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 30px;
          color: #999999;
          text-align: center;
          position: absolute;
          margin-left: 61px;
          cursor: pointer;
        }
        .task_edit_content_btn1:hover{
          color: #ff7354;
          border-color: #ff7354;
        }
        .task_edit_content_btn2{
          width: 68px;
          height: 30px;
          background: #FD492B;
          opacity: 1;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 30px;
          color: #ffffff;
          text-align: center;
          position: absolute;
          margin-left: 179px;
          cursor: pointer;
        }
        .task_edit_content_btn2:hover{
          color: #fff;
          background-color: #ff7354;
        }
      }
    }

  }
/* 元素菜单列表结束 */
</style>