// package mind.yushu.com.mindmap.core.basemode;

import CGPoint from "../../../viewmodel/core/base/basedata/CGPoint";
import CGRect from "../../../viewmodel/core/base/basedata/Rect";
import LineMindTypeNode from "../../../viewmodel/mindelementdata/LineMindTypeNode";
import Config from "../calcule/Config";

// import mind.yushu.com.mindmap.viewmodel.core.base.basedata.CGPoint;
// import mind.yushu.com.mindmap.viewmodel.core.base.basedata.CGRect;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.LineMindTypeNode;

/**
 * ProjectName: MindMap
 * Created by tony on 2/2/21
 * Copyright(c) 2020 mindyushu.com
 */

class MoveMindElementDataInfo {
    constructor() {
        this.node = new LineMindTypeNode();
        this.endPoint = new CGPoint();
        this.startPoint = new CGPoint();
        this.rect = new CGRect();
        this.index = -1;
    }


    isHit() {
        return this.index > -1 && !this.node.isEmpty() && this.startPoint.y > -1 && this.startPoint.x > -1;
    }
}

export default  MoveMindElementDataInfo