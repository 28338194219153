<template>
  <div class="template">
    <div class="bg-color" v-if="showBg"></div>
    <div class="complie-box">
      <div class="complie-box-model">
        <Complie-AddModel-Remark
          :class="modelAccout ? 'modelAccoutShow' : 'modelAccoutHide'"
        />
        <!-- 个性化模态框 -->
        <Complie-Left-Menu-Share
          :class="
            hideModelAccout
              ? 'modelLeftMenuShareShow'
              : 'modelLeftMenuShareHide'
          "
          v-if="hideModelAccout"
        />
        <!-- <ComplieLeftMenuShareTIps
          :class="
            hideDgModelAccout ? 'modeldgMenuShareShow' : 'modeldgMenuShareHide'
          "
        /> -->
        <LoginComponents
          :class="showLoginModelAccout ? 'modelLoginShow' : 'modelLoginHide'"
          @HideUserModel="HideUserModel"
        />
        <LookUserMessage v-if="userDataModel" />
        <!-- 商品模块 -->
        <div class="shoping-models" @click.stop="HideShoppingModel(false)">
          <transition name="slide-fade" mode="out-in">
            <ShoppingModel v-if="isShowFullType" @shoppJumpLogin="shoppJumpLogin"/>
          </transition>
        </div>
      </div>
      <div class="comlie-content">
        <Complie-Header :templateUserData="templateData" />
      </div>
      <!-- <div class="comlie-content-menu"> -->
      <!-- 顶部And左侧个性化组价
        <Complie-Left-Menu />
        <Complie-Header-Menu /> -->
      <!-- </div> -->
      <div class="comlie-center-content">
        <a-layout id="components-layout-demo-side">
          <a-layout>
            <a-layout-content style="margin-right: 10px">
              <div class="comlie-left-content">
                <ComplieLeftContent
                  v-show="!hideDgModelAccout"
                  :initTemplateData="templateData"
                />
                <ComplieLeftOutline v-show="hideDgModelAccout" />
                <!-- 导图播放 -->
                <div
                  class="template_play"
                  v-if="getMindmapPattern()"
                  @click="mindmapPlay()"
                >
                  <!-- <div class="mp-play iconfont-mindmap-play">&#58888;</div> -->
                  <span class="mp-play">
                    <img v-if="isDarkColor" src="../../assets/img/mindNewEditMenuIocn/playMind.svg" alt="" />
                    <img v-else src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/playMindWhite.svg" alt="" />
                  </span>
                  <span>{{ getString(strings.Mindmap_Review) }}</span>
                </div>
                <!-- 收藏 -->
                <div class="template_collection" @click="colectionLogoType">
                  <!-- <div
                    class="iconfont icon-shoucang-befor"
                    v-if="!colectionLogo"
                  >
                    &#58950;
                  </div>
                  <div class="iconfont icon-shoucang" v-else>&#58913;</div> -->
                  <span class="collect">
                      <img v-if="colectionLogo&&isDarkColor" src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/collectNot.svg" alt="" />
                      <img v-else-if="colectionLogo&&!isDarkColor" src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/collectWhite.svg" alt="" />
                      <img v-else src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/collect.svg" alt="" />
                  </span>
                  <span>{{ getString(strings.User_Collection) }}</span>
                </div>
                <!-- 使用模板 -->
                <div class="tempalte_model" @click="getUseTemplate">
                  {{ getString(strings.Using_Templates) }}
                </div>
                <div
                  class="complie-left-audio"
                  v-if="httpAudio != '' && httpAudio != undefined"
                >
                  <ComplieAudioLeft :httpAudioData="httpAudio" />
                </div>
              </div>
            </a-layout-content>
          </a-layout>
          <!-- <div class="comlie-right-menu-box">
           
            右侧个性化组件
          </div> -->
        </a-layout>
      </div>
    </div>
    <!-- 个性化加载封面 -->
    <!-- <ComplieShowImg /> -->
    <ComplieShowImg />
    <a-modal
      class="report-member-model"
      :centered="true"
      v-model="storageIsMeberModelAccout"
      :title="getString(strings.Mind_Edit_Join_Membership)"
      :cancelText="getString(strings.Global_Cancel)"
      :okText="getString(strings.Mind_Edit_Upgrade)"
      @cancel="storageIsMeberCancelHandleOk"
      @ok="storageIsMeberDeleteHandleOk"
    >
      <div class="report-crisps-style-box">
        <div
          class="report-crisps-style"
          style="
            font-size: 18px;
            font-family: Noto Sans SC;
            text-align: center;
            display: block;
          "
        >
          {{ getString(strings.Insufficient_Storage_Space_Tempalte_Tips) }}
        </div>
      </div>
    </a-modal>
    <!-- 导图播放 -->
    <ComplieMindmapPlayModal />

    

    <ComplieHelp />
    <SettingUpPopup 
    :contactShow="showContactUs" 
    @showContactModal="showContactModal"
    />
    <AboutProject
      :showAboutProject="showAboutProject"
      @showAboutModal="showAboutModal"
    />
    <Usermessage
      v-if="userMessage"
      @HideUserMessage="HideUserMessage"
    />
    <!-- 用户中心 -->
    <!-- <LookUserMessage v-if="userDataModel" /> -->

  </div>
</template>
<script>

if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer, remote } = window.require("electron");
  // var ipcRenderer = window.ipcRenderer
}
import AboutProject from "../../components/aboutProject/aboutProject";
import SettingUpPopup from "../../components/electronMenu/SettingUpPopup";
import Usermessage from "../../components/userMessage/UserMessage";
import LookUserMessage from "../../components/userMessage/LookUserMessage";
import ComplieHelp from "../../components/complieComponents/ComplieHelp/ComplieHelp";

import { mapMutations } from "vuex";

import EditMindmapVM from "../../viewmodel/mindelementdata/vm/EditMindmapVM";

import ShoppingModel from "../../components/shopping/ShoppingModel";
// import LookUserMessage from "../../components/userMessage/LookUserMessage";
import ComplieHeader from "../../components/complieComponents/ComplieHeader";
import LoginComponents from "../../components/loginComponents/LoginComponents";
import ComplieLeftContent from "../../components/complieComponents/ComplieLeftContent";
import ComplieLeftOutline from "../../components/complieComponents/ComplieLeftOutline";
import ComplieShowImg from "../../components/complieComponents/ComplieShowImg/ComplieShowImg";
import ComplieAudioLeft from "../../components/complieComponents/ComplieHeader/ComplieAudioLeft";
import ComplieMindmapPlayModal from "../../components/complieComponents/ComplieHeader/ComplieMindmapPlayModal";
import ComplieLeftMenuShare from "../../components/complieComponents/ComplieLeftMenuChild/ComplieLeftMenuShare";
import ComplieLeftMenuShareTIps from "../../components/complieComponents/ComplieLeftMenuChild/ComplieLeftMenuShareTIps";
import ComplieAddModelRemark from "../../components/complieComponents/ComplieHeader/ComplieAddModel/ComplieAddModelRemark";

import Colors from "../../utils/Colors";
import httpImageToPrefix from "../../utils/httpImageToPrefix";

import strings from "../../common/lang/strings";
import getString from "../../common/lang/language";
import { postUserLogout,postUserMe } from "../../common/netWork/base_api";
import { doLogin, doLogout } from "../../common/netWork/base";
import {
  postTemplateCollect,
  postTemplateGet,
  postTemplatePlay,
  postTemplateUncollect,
  postTemplateUseTemplate,
} from "../../common/netWork/template_api";

import publicModalStyle from "../../assets/css/publicModalStyle/publicModalStyle.less";
import MindDisplayType from "../../viewmodel/datatype/MindDisplayType";
import { getJwt } from "../../common/netWork/base";
import MindMe from '../../viewmodel/facade/MindMe';

if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer } = window.require("electron");
  // var ipcRenderer = window.ipcRenderer
}
export default {
  inject: ['showSettingModel'],
  data() {
    return {
      templateData: {},
      hideModelAccout: false,
      mindMapId: null,
      showLoginModelAccout: false,
      hideDgModelAccout: false,
      showBg: false,
      colectionLogo: true,
      userDataModel: false,
      modelAccout: false,
      httpAudio: "", //音频
      isShowFullType: false, //购买
      strings: strings,
      storageIsMeberModelAccout: false, //存储空间不足提示框
      
      nowElectron:false,//是否是electron环境
      //electron环境下的弹窗
      showContactUs:false,
      showAboutProject:false,
      userMessage: false,
      isDarkColor:false
    };
  },
  components: {
    ComplieHeader,
    ShoppingModel,
    ComplieShowImg,
    LoginComponents,
    LookUserMessage,
    ComplieAudioLeft,
    ComplieLeftOutline,
    ComplieLeftContent,
    ComplieLeftMenuShare,
    ComplieAddModelRemark,
    ComplieMindmapPlayModal,
    ComplieLeftMenuShareTIps,
    AboutProject,
    SettingUpPopup,
    Usermessage,
    ComplieHelp,
  },
  created() {
    this.nowElectron = this.$tools.isElectron();
    if (
      window.navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i // midp|iphone os|ipad|ucweb|windows mobile|
      )
    ) {
      this.$router.push("/download");
    } else {
    }
  },
  mounted() {
    if(this.nowElectron){
      this.isLoginStatus();
      
      //判断当前状态是否是登录状态
      this.isLoginStatus();
      //mind+的菜单弹出的弹窗  
      ipcRenderer.on('settingValMind', (event, message) => {//联系我们
        if(message == 'SettingUpMind'){
          this.showContactUs = true;
        }
      });
      ipcRenderer.on('aboutMind', (event, message) => {//关于
        if(message == 'aboutMind'){
          this.showAboutProject = true;
        }
      });
      ipcRenderer.on('setUpMind', (event, message) => {//设置
        if(message == 'setUpMind'){
          this.showSettingModel();
        }
      });
      ipcRenderer.on('helpMind', (event, message) => {//帮助
        if(message == 'helpMind'){
          this.helpModal(true);
        }
      });
      //我的
      
      ipcRenderer.on('personalInformationMind', (event, message) => {//查看个人信息
        let userMeData = MindMe.getMeMessage();
        if(message == 'personalInformationMind' && userMeData != null){
          this.showUserDataModel({
            accout: true,
            userId: userMeData.userId,
            xfrom: "crisps",
            fromId: userMeData.userId,
          });
        }
      });
      
      ipcRenderer.on('setInformationMind', (event, message) => {//设置信息
        if(message == 'setInformationMind'){
          // this.helpModal(true);
          this.ShowBg = !this.ShowBg;
          this.userMessage = !this.userMessage;
        }
      });

      ipcRenderer.on('SignOutMind', (event, message) => {//退出登录
        if(message == 'SignOutMind'){
          // this.helpModal(true);
          this.pushOutLogin();
          // this.isLoginStatus();
        }
      });
      ipcRenderer.on('GlobalLoginMind', (event, message) => {//登录注册
        if(message == 'GlobalLoginMind'){
          // this.helpModal(true);
          // this.ShowLoginModel();
          // this.isLoginStatus();
          this.showLoginModelAccout = !this.showLoginModelAccout;
          this.showBg = !this.showBg;
        }
      });

      ipcRenderer.on('judgeLoginStatus', (event, message) => {//判断子页面和父页面的登录状态
        if(!message){
          location.reload();
        }
      });
      ipcRenderer.on('mindmapReview', (event, message) => {//导图回顾
        if(message == 'mindmapReview'){
          this.mindmapPlayPauseShowSetup({
            showSetup: false,
            playAccout: "SHOW_PLAY_SETUP",
          });
        }
      });
    }
    if(this.$route.query.sheetId == null || this.$route.query.sheetId == ""){
      this.postTemplateGet();
    }else{
      this.postTemplateSheetGet();
    }
    
    this.getTitle();

    //进入当前页触发
    var that = this;
    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState == "visible") {
        let userData = localStorage.getItem("meMessage");
        if (userData != null) {
          that.postUserMe();
          return;
        }
      }
    });
  },
  methods: {
    ...mapMutations([
      "showLoginModel",
      "isShowShoppingModel",
      "mindmapPlayPauseShowSetup",
      "showUserDataModel",
      "helpModal",
      "detailsToUserMessageModel",
    ]),
    getString(i) {
      return getString(i);
    },
    storageIsMeberCancelHandleOk() {
      this.storageIsMeberModelAccout = false;
    },
    storageIsMeberDeleteHandleOk() {
      this.storageIsMeberModelAccout = false;
      this.isShowShoppingModel(true);
    },
    postUserMe() {
      var self = this;
      let msg = localStorage.getItem("meMessage");
      if (msg) {
        return;
      }
      postUserMe(
        {},
        (res) => {
          doLogin(res);
          localStorage.setItem("meMessage", JSON.stringify(res));
        },
        (e) => {
        },
        () => {
          self.postUserMe();
        }
      );
    },
    postTemplateGet() {
      let id = this.$route.query.id;
      postTemplateGet(
        { templateId: id },
        (res) => {
          if (res.collected) {
            this.colectionLogo = false;
          } else {
            this.colectionLogo = true;
          }
          this.templateData = res;
          this.templateData.userAvatar = httpImageToPrefix(res.userAvatar);
          this.checkAddIconColor(
            JSON.parse(this.templateData.content).mindBGColor
          );
          this.httpAudio = JSON.parse(
            this.templateData.content
          ).mindGlobalAudio;
        },
        (error) => {
          this.$message.error(error.desc);
        },
        (data) => {
        }
      );
    },
    postTemplateSheetGet() {
      let id = this.$route.query.id;
      let sheetId = this.$route.query.sheetId;
      postTemplateGet(
        { templateId: id,sheetId:sheetId },
        (res) => {
          if (res.collected) {
            this.colectionLogo = false;
          } else {
            this.colectionLogo = true;
          }
          this.templateData = res;
          this.templateData.userAvatar = httpImageToPrefix(res.userAvatar);
          this.checkAddIconColor(
            JSON.parse(this.templateData.content).mindBGColor
          );
          this.httpAudio = JSON.parse(
            this.templateData.content
          ).mindGlobalAudio;
        },
        (error) => {
          this.$message.error(error.desc);
        },
        (data) => {
        }
      );
    },
    getUseTemplate() {
      if (!this.loginStatus()) {
        return
      }
      let templateId = this.templateData.id;
      postTemplateUseTemplate(
        { templateId: templateId },
        (res) => {
          if (this.$tools.isElectron()) {
            ipcRenderer.send(
              "openCalendarWindow",
              "/mindmap?id=" + res.mindMapId
            );
            this.$emit("clickCreated", item);
          } else {
            //a标签打开新页面
            let aLink = document.createElement('a')
            aLink.target="_blank"
            aLink.setAttribute("href", window.location.origin + "/mindmap?id="+ res.mindMapId);
            document.body.appendChild(aLink);
            aLink.click();
          }
        },
        (error) => {
          if (error.code == 94) {//需要付费
            this.isShowShoppingModel(true);
          } else if (error.code == 51) {//存储空间不足
            this.storageIsMeberModelAccout = true;
          } else {
            this.$message.error(error.desc);
          }
        }
      );
    },
    getTitle() {
      setTimeout(() => {
        document.title = EditMindmapVM.getTitle() + " - 寻简思维导图";
      }, 100);
    },
    //收藏
    colectionLogoType() {
      if (this.colectionLogo) {
        //收藏
        postTemplateCollect(
          {
            templateId: this.$route.query.id,
            collectionId: "",
          },
          () => {
            this.colectionLogo = !this.colectionLogo;
          }
        );
      } else {
        //取消收藏
        postTemplateUncollect(
          {
            templateId: this.$route.query.id,
          },
          () => {
            this.colectionLogo = !this.colectionLogo;
          }
        );
      }
    },
    //功能按钮颜色
    checkAddIconColor(btnColor) {
      let isDarkColor = this.isDarkColor = Colors.isLightColor(btnColor);
      let color = "";
      if (isDarkColor) {
        color = "#666";
      } else {
        color = "#fff";
      }
      let ele = document.querySelectorAll("iconfont");
      let nameColor = document.getElementsByClassName("template_collection");
      let playColor = document.getElementsByClassName("template_play");

      nameColor[0].style.color = color;
      playColor[0].style.color = color;

      // for (let i = 0; i < ele.length; i++) {
      //   ele[i].style.color = color;
      // }
    },
    //导图播放
    mindmapPlay() {
      this.mindmapPlayPauseShowSetup({
        showSetup: false,
        playAccout: "SHOW_PLAY_SETUP",
      });
    },
    //当前导图模式
    getMindmapPattern() {
      return EditMindmapVM.mindDisplayType == MindDisplayType.MindMap
        ? true
        : false;
    },
    //确认支付未登录显示登录模块
    shoppJumpLogin() {
      setTimeout(() => {
        this.showLoginModelAccout = true;
        this.showBg = true;
      }, 100);
    },
    //判断当前登录状态
    isLoginStatus(){
      let languages = {
          "eleAbout":getString(this.strings.Mind_About),
          "eleSetting":getString(this.strings.Mind_Group_Setting_Up),
          "eleHelp":getString(this.strings.Mind_Edit_Left_Menu_Help),
          "eleContactUs":getString(this.strings.Setting_Up),
          "eleMine":getString(this.strings.Mind_Edit_Mine),
          "eleSee":getString(this.strings.See_Checking_Data),
          "eleModify":getString(this.strings.Modify_Checking_Data),
          "eleSignOut":getString(this.strings.Sign_Out),
          "eleLogin":getString(this.strings.User_Lolgin_Registered)+"/"+getString(this.strings.User_Lolgin_Login),
          "eleEdit":getString(this.strings.Global_Edit),
          "eleRevoke":getString(this.strings.Mind_Edit_Revoke),
          "eleRestore":getString(this.strings.Mind_Edit_Restore),
          "eleCut":getString(this.strings.Mind_Shear),
          "eleCopy":getString(this.strings.Mind_Edit_Node_Menu_Copy),
          "elePaste":getString(this.strings.Mind_Edit_Node_Menu_Paste),
          "eleSelectAll":getString(this.strings.Mind_Group_Check_All),
          "find": getString(this.strings.Global_Text_Find),
          "see": getString(this.strings.Mind_See),
          "outline": getString(this.strings.Public_Header_Outline),
          "enlarge": getString(this.strings.Mind_Enlarge),
          "narrow": getString(this.strings.Mind_Narrow),
          "style": getString(this.strings.Mind_Edit_Right_Style),
          "mindmapReview": getString(this.strings.Mindmap_Review),
        }
      if(!MindMe.isLogin()){
        ipcRenderer.send("isMindSignIn", false, languages);
      }else{
        ipcRenderer.send("isMindSignIn", true, languages);
      }
    },
    showAboutModal(obj) {
      //隐藏弹框
      // if(obj.show == false) {
      this.showAboutProject = obj.show;
      // }
      if (obj.isVersion != undefined) {
        // obj.isVersion
        this.versionUpdating({}).then((res) => {
          //请求最新版本号
          // res = {
          //   newVersion:'新版本2.0.0',
          //   desc:'内容详情', //更新内容详情
          //   url:'https://r.mindyushu.com/dl/release/windows/mindmap_windows_v2.0.0_amd64.exe', //安装包下载链接
          //   isDirectDownloadLink: false //指明上面的Url是否是下载直链（如果为false，则需要跳转到浏览器手动点下载）
          // }
          if (res.newVersion == "") {
            //当前是最新版本
            this.isLatestVersion = true;
            this.$message.success('当前已是最新版本')
          } else {
            //当前不是最新版本
            this.isLatestVersion = false;
            this.setVersionUpdatingModal(res);
            this.showAboutProject = false; // 隐藏版本信息页面
          }
        });
      }
    },
    showContactModal(val){
      this.showContactUs = val;
    },
    HideUserMessage(Account) {
      this.ShowBg = !this.ShowBg;
      this.userMessage = Account;
      this.detailsToUserMessageModel(false);
    },
     //退出登录
    pushOutLogin() {
      postUserLogout(
        {},
        (data) => {},
        (e) => {
          console.log("退出失败");
        },
        () => {
          doLogout();
          //刷新群组页面信息
          if(this.nowElectron){
            this.isLoginStatus();
          }
        }
      );
    },
    HideUserModel(newval) {
      this.showLoginModelAccout = newval;
      this.showBg = newval;
    },
    //登录状态
    loginStatus(){
      if (!getJwt("jwt")) {
        this.showLoginModel(true);
        return false;
      }else{
        return true;
      }
    },
    HideShoppingModel(){
      this.isShowFullType = false;
      this.isShowShoppingModel(false);
    },
  },
  watch: {
    $route: {
        handler() {
          this.postTemplateSheetGet();
          // this.headerMenu = false;
          // this.hideDgModelAccout = 
          // setTimeout(()=>{
          //   this.headerMenu = true;
          // },10)

        },
        deep: true,
    },
    "$store.state.hideModel"(newHideModel) {
      this.hideModelAccout = newHideModel;
    },
    "$store.state.showLogin"(newLoginShowLogin) {
      if(!this.isShowFullType){//只显示购物车或登录一个弹框
        this.showLoginModelAccout = newLoginShowLogin;
        this.showBg = newLoginShowLogin;
        // console.log("showLogin")
      }
      this.$store.state.useShortcut = !newLoginShowLogin;//是否可以使用快捷键
    },
    "$store.state.showShopping"(newShowShopping, olShowShopping) {
      this.showBg = newShowShopping;
      this.isShowFullType = newShowShopping;
      this.$store.state.useShortcut = !newShowShopping;//是否可以使用快捷键
    },
    "$store.state.showDaGangModels"(newshowDaGangModels) {
      this.hideDgModelAccout = newshowDaGangModels;
      if (newshowDaGangModels) {
        this.checkAddIconColor(16777215);
      } else {
        this.checkAddIconColor(
          JSON.parse(this.templateData.content).mindBGColor
        );
      }
      //this.showBg = newshowDaGangModels;
    },
    "$store.state.userModelData.accout"(newShowUserModelData) {
      this.userDataModel = newShowUserModelData;
      this.showBg = newShowUserModelData;
    },
    "$store.state.showAddRemark"(newAddRemarkAccout) {
      this.modelAccout = newAddRemarkAccout;
    },
    "$store.state.canvanDatas.audio"(newHttpAudioData) {
      if (newHttpAudioData != "") {
        this.httpAudio = newHttpAudioData;
      }
    },
    
  },
};
</script>
<style lang="less" scoped>
html {
  overflow: hidden;
}

// 导图播放Icon
@font-face {
  font-family: "iconfontMindmapPlay";
  src: url("../../assets/font-icon/mindmap_play/iconfont.eot");
  src: url("../../assets/font-icon/mindmap_play/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/mindmap_play/iconfont.woff2") format("woff2"),
    url("../../assets/font-icon/mindmap_play/iconfont.woff") format("woff"),
    url("../../assets/font-icon/mindmap_play/iconfont.ttf") format("truetype"),
    url("../../assets/font-icon/mindmap_play/iconfont.svg#iconfont")
      format("svg");
}
.iconfont-mindmap-play {
  margin-right: 10px;
  font-family: "iconfontMindmapPlay" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "iconfont";
  src: url("../../assets/font-icon/crisps/iconfont.eot"); /* IE9 */
  src: url("../../assets/font-icon/crisps/iconfont.eot")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../../assets/font-icon/crisps/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/crisps/iconfont.woff2") format("woff"),
    url("../../assets/font-icon/crisps/iconfont.ttf") format("truetype"),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
      url("../../assets/font-icon/crisps/iconfont.ttf#iconfont") format("svg"); /* iOS 4.1- */
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 24px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.icon-shoucang-befor {
  color: #f78e7b !important;
}
/**背景 */
.bg-color {
  background-color: black;
  opacity: 0.5;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  transition: 0.5s;
}
.modeldgMenuShareShow {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: all 1s linear;
}
.modeldgMenuShareHide {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.template {
  /* height: 100vh; */
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  user-select: none;
}
.comlie-content-menu {
  width: 100%;
  height: 100%;
  height: 68px;
  position: relative;
  z-index: 1200;
}
.comlie-content {
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16);
  z-index: 100;
  width: 100%;
  position: absolute;
}

.template_play {
  z-index: 10;
  position: absolute;
  right: 211px;
  height: 46px;
  opacity: 1;
  border-radius: 61px;
  transition: 0.5s;
  font-size: 16px;
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: 100px;
}
.template_play .mp-play{
  margin-right: 10px;
}

.template_collection {
  z-index: 10;
  position: absolute;
  right: 316px;
  height: 46px;
  opacity: 1;
  border-radius: 61px;
  transition: 0.5s;
  font-size: 16px;
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: 100px;
  margin-right: 10px;
}

.template_collection .collection {
  display: flex;
  align-items: center;
}

.template_collection .collect {
  margin-right: 10px;
}

.complie-left-audio {
  z-index: 10;
  position: absolute;
  left: 0px;
  height: 46px;
  opacity: 1;
  border-radius: 61px;
  transition: 0.5s;
  font-size: 16px;
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 140px;
}
.complie-left-audio input {
  border: none;
}
.template_share {
  z-index: 10;
  position: absolute;
  right: 254px;
  height: 46px;
  opacity: 1;
  border-radius: 61px;
  transition: 0.5s;
  font-size: 16px;
  color: #666666;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: 100px;
}

.template_share .share {
  display: flex;
  align-items: center;
}

.template_share img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.comlie-center-content .tempalte_model {
  z-index: 10;
  position: absolute;
  right: 0px;
  width: 132px;
  height: 46px;
  background: #fd492b;
  opacity: 1;
  border-radius: 61px;
  transition: 0.5s;
  font-size: 16px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  right: 36px;
  top: 100px;
}
.shoping-models {
  width: 100%;
  position: absolute;
  z-index: 15000;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.comlie-center-content .tempalte_model:hover {
  background: #ff765e;
  transition: 0.5s;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.16);
}

.complie-box-model {
  transition: 0.5s;
}
.cropperImgAccoutShow {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: all 1s linear;
}
.cropperImgAccoutHide {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.modelAccoutShow {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: all 1s linear;
}
.modelAccoutHide {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.modelLinkAccoutShow {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: all 1s linear;
}
.modelLinkAccoutHide {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.modelLeftMenuShareShow {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: all 1s linear;
}
.modelLeftMenuShareHide {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
.modelLoginShow {
  display: block;
  visibility: visible;
  opacity: 1;
  transition: all 1s linear;
}
.modelLoginHide {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}
</style>