<template>
    <div  ref="CommonBgColorRef" class="CommonBgColor-box" >
        <div class="CommonBgColor-Chunk">
            <CommonBgColorChunk />
        </div>
        <div class="CommonBgColor-More">
            <CommonMoreBgColor/>
        </div>  
    </div>
</template>
<script>
import CommonBgColorChunk from "./CommonChildBgColor/CommonBgColorChunk"
import CommonMoreBgColor from "./CommonChildBgColor/CommonMoreBgColor"
import { mapMutations } from "vuex";
import UiUtil from '../../../utils/UiUtil';

export default {
    props: [
        "palletTop"
    ],
    data(){
        return{
        }
    },
    components:{
        CommonBgColorChunk,
        CommonMoreBgColor
    },
    created(){
        this.useShortcut(false);
    },
    destroyed(){
       this.useShortcut(true);
    },
    mounted() {
        let element = this.$refs.CommonBgColorRef;
        this.boxWidth = element.clientWidth;
        this.boxHeight = element.clientHeight;

        let mindRoot = document.getElementById("canvas-father")
        let isRootChild =  (new UiUtil()).isElementChild(element, mindRoot)
        
        if (isRootChild) {
            let top = (new UiUtil()).getTopByWindow(element)
            let rootRect = mindRoot.getBoundingClientRect()
            if (top - rootRect.top < mindRoot.scrollTop) {
                element.style.marginTop = (mindRoot.scrollTop - top + rootRect.top) + "px";
            }
            
        }
    },

    methods:{
      ...mapMutations([
        "useShortcut",
      ]),
        
    }
}
</script>
<style scoped>
.CommonBgColor-box{
    position: fixed;
    z-index: 99999;
    width: 285px;
    /* height: 100%; */
}
.CommonBgColor-More{
    position: absolute;
    z-index: 9999;
    top: 0px;
    right: 285px;
}
</style>