/**
 * Created by tony on 2019/12/26
 */
import Config from "../../../core/core/calcule/Config"
import EncircleNodesPointsCalculation from "../../../core/core/calcule/EncircleNodesPointsCalculation"
import NodesRectCalculation from "../../../core/core/calcule/NodesRectCalculation"
import Colors from "../../../utils/Colors"
import UiUtil from "../../../utils/UiUtil"
import IdGenerator from "../base/IdGenerator"
import LineColorMode from "../base/LineColorMode"
import LineOrientation from "../../datatype/LineOrientation"
import LineLayout from "../../datatype/LineLayout"
import MindElementShapeType from "../../datatype/MindElementShapeType"
import MindElementType from "../../datatype/MindElementType"
import NodeLayoutType from "../../datatype/NodeLayoutType"
import LineElementContent from "../../mindelementdata/mindcontent/LineElementContent"
import BaseLayout from "./BaseLayout"
import Point from "../base/Point"
import EncircleShapeType from "../../datatype/EncircleShapeType"
import RectsMergeCalculation from "./RectsMergeCalculation"
import EncircleTitleLayoutType from "../../datatype/EncircleTitleLayoutType"
import LineMindTypeNode from "../../mindelementdata/LineMindTypeNode"
import CGPoint from "../base/basedata/CGPoint"
import HashMap from "../base/HashMap"
import Util from "../../../utils/Util"

class NewFreeTreeLayout extends BaseLayout {

    constructor() {
        super()
        this.angle = 180.0;
        this.startAngle = 0.0;
        this.sonSubjectAngle = 45.0;
        this.subjectRadius = new UiUtil().dip2px(80);
        this.sonSubjectRadius = new UiUtil().dip2px(50);
        this.nodeAngle = new HashMap();

        this.NodeVerticalSpacee = Config.NodeVerticalSpacee;
        this.SonNodeHorizontalSpacee = Config.SonNodeHorizontalSpacee;
        this.NodeHorizontalSpacee = Config.NodeHorizontalSpacee;
    }

    initConfig() {
        super.initConfig();
        this.subjectRadius = this.addSubjectSpcaeHorizontal(Config.NodeHorizontalSpacee + this.getSubjectLineWidth());

        this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee * 0.5);
        this.SonNodeVerticalSpacee = this.addSonSubjectSpcaeVertical(Config.SonNodeVerticalSpacee * 0.5)
        this.NodeHorizontalSpacee = this.addSubjectSpcaeHorizontal(Config.NodeHorizontalSpacee + this.getSubjectLineWidth());
        this.SonNodeHorizontalSpacee = this.addSonSubjectSpcaeHorizontal(Config.SonNodeHorizontalSpacee * 2);

        // if (this.rootTreeNode.children.length == 1 || !this.isFromLeftRightLayout) {
        //     this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee * 3.0);
        // } else {
        //     let rootNodeHeight = this.getTreeHeight(this.rootTreeNode)
        //     if (rootNodeHeight > 500) {
        //         this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee * 1.0);
        //     } else if (rootNodeHeight > 400) {
        //         this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee * 2.0);
        //     } else if (rootNodeHeight > 300) {
        //         this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee * 3.5);
        //     } else if (rootNodeHeight > 200) {
        //         this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee * 5.0);
        //     } else if (rootNodeHeight > 100) {
        //         this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee * 6.0);
        //     } else {
        //         this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee * 1.0);
        //     }
        // }
        
    }

    onLayout(isChange) {
        if (this.rootTreeNode == null) {
            return;
        }
        this.setElementsPoint(isChange);
        this.hideRootFormLine();
        this.setGeneralizationElementsPoint(isChange);
    }

    setElementsPoint(isChange) {
        if ((!isChange || this.rootTreeNode.value.y == -1 || this.rootTreeNode.value.x == -1) && this.rootTreeNode.value.type == MindElementType.MAIN_SUBJECT) {
            let left = (Config.Mind_Width - (new UiUtil).getScreenWidth()) / 2;
            let top = (Config.Mind_Height - (new UiUtil).getScreenHeight()) / 2;
            this.rootTreeNode.value.y = ((new UiUtil).getScreenHeight() - this.rootTreeNode.value.height) / 2 + top;
            this.rootTreeNode.value.x = 20 + left;
        }
        
        this.initConfig()
        this.setRootChildNodePoint();
    }

    setNodeElementsPoint(node, isChange) {
        this.setDataPoint(node.value, isChange);
        if (node.children.length == 0 || this.isLayout(node.value)) {
            return;
        }
        if (this.isRootNode(node) || node.value.layout == NodeLayoutType.LAYOUT_RIGHT) {
            let nodeChildrenLength = node.children.length
            for (var index = 0; index < nodeChildrenLength; index++) {
                this.setNodeElementsPoint(node.children[index], isChange);
            }
        } else {
            this.resetNodeLayout(node);
        }
    }

    setNodeElementsLinePoint(node, isChange) {
        this.setElementLine(node, isChange);
        if (node.children.length == 0) {
            return;
        }
        for (let index = 0; index < node.children.length; index++) {
            this.setNodeElementsLinePoint(node.children[index], isChange);
        }
    }

    setRootChildNodePoint() {
        if (this.rootTreeNode.children.length == 0 ||
            this.rootTreeNode.children[0].value.isHidden) {
            return
        }
        let rootVerticalCentrePoint = this.rootTreeNode.value.y + this.rootTreeNode.value.height/2
        let minX = this.rootTreeNode.value.x + this.rootTreeNode.value.width/2 + 10;
        let count = this.rootTreeNode.children.length
        let maxMoveCell = 20
        let maxValue = this.rootTreeNode.value.width/2 + this.addSubjectSpcaeHorizontal(Config.NodeHorizontalSpacee +this.getSubjectLineWidth());
        
        let topCount = 0
        let topEndIndex = -1
        let bottomCount = 0
        let bottomStartIndex = 10000
        let radius = Math.max(this.rootTreeNode.value.width / 2, this.rootTreeNode.value.height / 2) + this.NodeHorizontalSpacee;
        for (let index = 0; index < count; index++) {
            let parentNode = this.rootTreeNode;
            let cell = this.rootTreeNode.children[index]
            let data = cell.value
            if (this.isFromLeftRightLayout && data.isFreeLayoutNode && 
                this.settingData.branchNodeFreelayout.isValue() && data.type == MindElementType.SUBJECT) {
                if (data.x > 0 && data.y > 0) {
                    continue
                }
            }
            let parentCenterPoint = new CGPoint((parentNode.value.x) + (parentNode.value.width / 2),
                (parentNode.value.y) + (parentNode.value.height / 2));            
            
            let allSiblingsNodesPosition = index;
            let dataSiblingsNodesAngle = 360 / count;
            let dataAngle = dataSiblingsNodesAngle * allSiblingsNodesPosition - 45;
            // if (this.nodeAngle.containsKey(parentNode.value.id)) {//this.nodeAngle.keys().includes(parentNode.value.id)
            //     if (parentNode.children.length <= 4) {
            //         dataAngle = this.sonSubjectAngle * allSiblingsNodesPosition;
            //         dataAngle = dataAngle + this.nodeAngle.get(parentNode.value.id) - ((parentNode.children.length - 1) * this.sonSubjectAngle) / 2;
            //     } else {
            //         dataAngle = dataAngle + this.nodeAngle.get(parentNode.value.id);
            //     }
            // }
            console.log("--dataAngle--", dataSiblingsNodesAngle, dataAngle);
            this.nodeAngle.put(data.id, dataAngle);
            if (dataAngle >= 270 || dataAngle < 90) {
                let x = parentCenterPoint.x + ((radius) * Util.cosd(dataAngle));
                let y = parentCenterPoint.y + ((radius) * Util.sind(dataAngle));
    
                data.y = y - (data.height);
                data.x = x;
            } else {
                let x = parentCenterPoint.x + ((radius) * Util.cosd(dataAngle));
                let y = parentCenterPoint.y + ((radius) * Util.sind(dataAngle));

                data.y = y;
                data.x = x - data.width;
            }
            this.pushCurrentLayoutNode(cell);
        }

        for (let index = 0; index < count; index++) {
            let cell = this.rootTreeNode.children[index]
            let data = cell.value
            if (data.isHidden) {
                continue
            }            
            let dataAngle = this.nodeAngle.get(data.id);
            if (dataAngle >= 270 || dataAngle < 90) {
                // data.layout = NodeLayoutType.LAYOUT_RIGHT;
                this.setElementLine(cell, true);
            } else {
                // data.layout = NodeLayoutType.LAYOUT_LEFT;                
                this.setElementLeftLine(cell, true)
            }
            this.resetNodeLayout(cell);  
            // data.layout = NodeLayoutType.LAYOUT_FREE_TREE;             
        }
    }

    setElementLine(node, isChange) {
        let data = node.value;
        if (data.isHidden || this.isRootNode(node) || !this.isIncludeNodeInCurrentLayout(node)) {
            return;
        }
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        if (!this.isGeneralizatioData(data) && !this.isRootNode(parentNode) && 
            parentNodeData.isEmpty()) {
            return;
        }
        let rootLineWidth = 6
        if (this.rootTreeNode.children.length > 0) {
            let rootLineData = this.getLineData(this.rootTreeNode.children[0]);
            if (!rootLineData.isEmpty()) {
                rootLineWidth = rootLineData.lineContent.lineWidth * 3
            }
        }

        let isCreateLineData = false;
        let lineData = this.getLineData(node);
        lineData.isFreeTreeNode = true

        lineData.parentNodeId = parentNodeData.id;
        let lineLayout = this.getNodeLineLayout(node, lineData);
        if (lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            this.lineMindElementDataDict.put(lineData.id, lineData);
            this.textElementLineMindElementDataDict.put(node.value.id, lineData);
            isCreateLineData = true;
        }
        lineData.isHidden = false;
        lineData.level = data.level
        lineData.layout = NodeLayoutType.LAYOUT_RIGHT;
        if (data.type == MindElementType.SUBJECT || 
            data.type == MindElementType.CONTENT_GENERALIZATION) {
            lineData.type = MindElementType.LINE;
            var startX = 0;
            var startY = 0;
            var endX = 0;
            var endY = 0;
            startX = parentNodeData.x + parentNodeData.width/2;
            startY = parentNodeData.y + parentNodeData.height / 2;
            endX = data.x;
            endX = data.x;
            if (this.isUnderline(data)) {
                endY = data.y + data.height;
            } else {
                endY = data.y + data.height / 2;
            }

            lineData.y = endY;
            lineData.x = startX;
            
            lineData.width = endX - startX;
            lineData.height = startY - endY;

            let startPoint = new Point(0, lineData.height);
            let endPoint = new Point(lineData.width, 0);
            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent(startPoint, endPoint, 0x333333, data.id);
            } else {
                lineData.lineContent.setStartPoint(startPoint);
                lineData.lineContent.setEndPoint(endPoint);
            }
            if (this.isUnderline(data) && data.layout == NodeLayoutType.LAYOUT_RIGHT) {                
                lineData.lineContent.endControlPointX = endPoint.x + data.width;
                lineData.lineContent.endControlPointY = endPoint.y;
            } else {
                lineData.lineContent.endControlPointX = endPoint.x;
                lineData.lineContent.endControlPointY = endPoint.y;
            }            
            if (isCreateLineData) {
                lineData.lineContent.startControlPointX = Math.ceil(Math.random()*8) + 1
                lineData.lineContent.startControlPointY = Math.ceil(Math.random()*8) + 1
            }
            lineData.lineContent.curveStartPointX = this.rootTreeNode.value.width / 2;
            lineData.lineContent.curveStartPointY = this.rootTreeNode.value.height / 2;
            if (node.children.length == 0 || node.children[0].value.isHidden) {
                lineData.borderThicken = true
            } else {
                lineData.borderThicken = false
            }
            lineData.lineContent.orientation = LineOrientation.RIGHT;
            lineData.lineContent.cornerRadius = rootLineWidth
            
        } else {

            lineData.type = MindElementType.SON_LINE;

            if (this.isUnderline(parentNodeData)) {
                lineData.y = parentNodeData.y + parentNodeData.height;
                lineData.x = parentNodeData.x + parentNodeData.width;
            } else {
                lineData.y = parentNodeData.y + parentNodeData.height / 2;
                lineData.x = parentNodeData.x + parentNodeData.width;
            }

            let curveStartPoint = new Point(0, 0);
            let curveEndPoint = new Point(0, 0);
            let straightEndPoint = new Point(0, 0);
            if (node.children.length == 0 || node.children[0].value.isHidden) {
                lineData.borderThicken = true
            } else {
                lineData.borderThicken = false
            }
            if (this.isUnderline(data) && data.layout == NodeLayoutType.LAYOUT_RIGHT) {
                lineData.height = data.y - lineData.y + data.height;
                lineData.width = data.x - lineData.x;
            } else {
                lineData.height = data.y - lineData.y + data.height / 2;
                lineData.width = data.x - lineData.x;
            }
            curveStartPoint = new Point(-1, 0);
            curveEndPoint = new Point(lineData.width, lineData.height);
            straightEndPoint = new Point(lineData.width, lineData.height);
            let originalStraightEndPointX = straightEndPoint.x
            if (data.layout == NodeLayoutType.LAYOUT_RIGHT) {
                let lineHeight = Math.abs(lineData.height)
                if (lineHeight > 160) {
                    straightEndPoint.x += Math.min(30, data.width)
                } else if (lineHeight > 140) {
                    straightEndPoint.x += Math.min(25, data.width)
                } else if (lineHeight > 110) {
                    straightEndPoint.x += Math.min(20, data.width)
                } else if (lineHeight > 90) {
                    straightEndPoint.x += Math.min(15, data.width)
                } else if (lineHeight > 45) {
                    straightEndPoint.x += Math.min(10, data.width)
                } else if (lineHeight > 20) {
                    straightEndPoint.x += Math.min(5, data.width)
                }
            }
            
            curveEndPoint.x = straightEndPoint.x
            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent();
                lineData.lineContent.setLineElementContent(curveStartPoint, curveEndPoint, 0x333333, data.id, straightEndPoint);
            } else {
                lineData.lineContent.setCurveStartPoint(curveStartPoint);
                lineData.lineContent.setCurveEndPoint(curveEndPoint);
                lineData.lineContent.setStraightEndPoint(straightEndPoint);
            }

            lineData.lineContent.cornerRadius = rootLineWidth/(lineData.level)
            if (parentNode.children.length != 1) {
                let nodeIndex = 0
                for (let index = 0; index < parentNode.children.length; index++) {
                    if (parentNode.children[index].value.id == data.id) {
                        nodeIndex = index;
                        break;
                    }
                }
                if (nodeIndex == 0 || nodeIndex != (parentNode.children.length - 1) / 2) {
                    if (straightEndPoint.y < curveStartPoint.y) { //向上
                        curveStartPoint.y -= ((rootLineWidth/(lineData.level - 1) - rootLineWidth/(lineData.level)) / 2)
                        lineData.lineContent.setCurveStartPoint(curveStartPoint);
                    } else if (straightEndPoint.y > curveStartPoint.y) {
                        curveStartPoint.y += ((rootLineWidth/(lineData.level - 1) - rootLineWidth/(lineData.level)) / 2)
                        lineData.lineContent.setCurveStartPoint(curveStartPoint);
                    }
                }
            }
            if (this.isUnderline(data) && data.layout == NodeLayoutType.LAYOUT_RIGHT) {                
                lineData.lineContent.endControlPointX = Math.min(originalStraightEndPointX + data.width, data.x - lineData.x + data.width)
                lineData.lineContent.endControlPointY = straightEndPoint.y;
            } else {
                lineData.lineContent.endControlPointX = originalStraightEndPointX;
                lineData.lineContent.endControlPointY = straightEndPoint.y
            }
            
            if (isCreateLineData || lineData.lineContent.startControlPointX <= 0 || lineData.lineContent.startControlPointX > 6 && node.children.length > 0) {
                lineData.lineContent.startControlPointX = Math.ceil(Math.random()*8) + 1
                lineData.lineContent.startControlPointY = Math.ceil(Math.random()*5) + 1
            }
            if (curveStartPoint.x > straightEndPoint.x) {
                lineData.lineContent.orientation = LineOrientation.RIGHT;
            } else {
                lineData.lineContent.orientation = LineOrientation.RIGHT;
            }            
        }
        lineData.lineContent.text = data.textContent != null ? data.textContent.text : ""
        lineData.lineContent.lineLayout = lineLayout;
        if (isCreateLineData) {
            let lineNodeContent = lineData.lineContent;

            let referenceLine = this.getReferenceLine(node.value.id);
            if (!referenceLine.isEmpty()) {
                lineNodeContent.color = referenceLine.lineContent.color;
                let grandpaNode = this.getNodeById(parentNode.value.parentNodeId);
                if (referenceLine.lineContent.lineWidth == 0 &&
                        !grandpaNode.isEmpty() &&
                        grandpaNode.value.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                    lineNodeContent.lineWidth = parentNode.value.borderWidth > 0 ? parentNode.value.borderWidth : 1.5;
                } else {
                    lineNodeContent.lineWidth = referenceLine.lineContent.lineWidth;
                }
                lineNodeContent.dottedLine = referenceLine.lineContent.dottedLine;
                lineData.isShadow = referenceLine.isShadow;
            } else {
                lineNodeContent.color = node.value.styleColor;
                if (this.isUnderline(data)) {
                    lineNodeContent.lineWidth = data.borderWidth;
                } else {
                    lineNodeContent.lineWidth = this.mindMapStyleColor.getSubjectLineWidth();
                }
            }
            if (lineData.type == MindElementType.LINE && !this.settingData.lineColor.isEmpty()) {
                if (!this.originalRootTreeNode.isEmpty() && parentNode.value.id == this.originalRootTreeNode.value.id) {
                    parentNode = this.originalRootTreeNode;
                }
                // let parentNodeChildrenSize = parentNode.children.length;
                // let lineColorLength = this.settingData.lineColor.length;
                // for (let index = 0; index < parentNodeChildrenSize; index++) {
                //     if (parentNode.children[index].value.id == node.value.id) {
                //         let colorPoint = index % lineColorLength;
                //         lineNodeContent.color = this.settingData.lineColor[colorPoint];
                //         break;
                //     }
                // }
                let colorPoint = (parentNode.children.length - 1) % this.settingData.lineColor.length;
                lineNodeContent.color = this.settingData.lineColor[Math.abs(colorPoint)]
            }
            lineData.backgroundColor = Colors.transparent;
            lineData.borderColor = Colors.transparent;
            lineData.borderWidth = 0;
        } else {
            let childSize = parentNode.children.length;
            if (lineData.lineContent.lineWidth == 0 && childSize > 2 &&
                parentNode.children[0].value.id != data.id &&
                parentNode.children[childSize - 1].value.id != data.id) {
                let firstLine = this.getLineData(parentNode.children[0]);
                let lastLine = this.getLineData(parentNode.children[childSize - 1]);

                if (!firstLine.isEmpty() && !lastLine.isEmpty() &&
                    firstLine.lineContent.lineWidth > 0 &&
                    lastLine.lineContent.lineWidth > 0) {
                    lineData.lineContent.lineWidth = firstLine.lineContent.lineWidth;
                }
            }
        }
        
        this.setLineThicken(lineData);
    }


    setElementLeftLine(node, isChange) { //设置线条。
        if (node.value.isHidden || this.isRootNode(node)) {
            return;
        }
        let data = node.value;
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        
        if (!this.isGeneralizatioData(data) && !this.isRootNode(parentNode) &&
            (parentNodeData.isEmpty() || parentNodeData.layout != NodeLayoutType.LAYOUT_LEFT)) {
            return;
        }
        if (parentNodeData.id != IdGenerator.INVALID_ID) {
            let isCreateLineData = false;
            let lineData = this.getLineData(node);
            lineData.parentNodeId = parentNodeData.id;
            lineData.isFreeTreeNode = true
            let lineLayout = this.getNodeLineLayout(node, lineData);
            if (lineData.id == IdGenerator.INVALID_ID) {
                lineData.id = IdGenerator.shared.getId();
                this.lineMindElementDataDict.put(lineData.id, lineData);
                this.textElementLineMindElementDataDict.put(node.value.id, lineData);
                isCreateLineData = true;
            }
            let rootLineWidth = 6

            if (this.rootTreeNode.children.length > 0) {
                let rootLineData = this.getLineData(this.rootTreeNode.children[0]);
                if (!rootLineData.isEmpty()) {
                    rootLineWidth = rootLineData.lineContent.lineWidth * 3
                }
            }
            lineData.isHidden = false;
            lineData.level = data.level
            lineData.layout = NodeLayoutType.LAYOUT_LEFT;
            if (data.type == MindElementType.SUBJECT || data.type == MindElementType.CONTENT_GENERALIZATION) {
                lineData.type = MindElementType.LINE;
                if (this.isUnderline(data)) {
                    lineData.y = data.y + data.height;
                } else {
                    lineData.y = data.y + data.height / 2;
                }                
                lineData.x = data.x + data.width;
                lineData.width = parentNodeData.x - lineData.x + parentNodeData.width/2;
                lineData.height = parentNodeData.y - lineData.y + parentNodeData.height / 2;

                let startPoint = new Point((lineData.width), (lineData.height));
                let endPoint = new Point(0, 0);

                if (lineData.lineContent == null) {
                    lineData.lineContent = new LineElementContent(startPoint, endPoint, 0xff333333, data.id);
                } else {
                    lineData.lineContent.setStartPoint(startPoint);
                    lineData.lineContent.setEndPoint(endPoint);
                }

                if (this.isUnderline(data) && data.layout == NodeLayoutType.LAYOUT_LEFT) {
                    lineData.lineContent.endControlPointX = endPoint.x - data.width
                    lineData.lineContent.endControlPointY = endPoint.y
                } else {
                    lineData.lineContent.endControlPointX = endPoint.x
                    lineData.lineContent.endControlPointY = endPoint.y
                }
                
                if (isCreateLineData) {
                    lineData.lineContent.startControlPointX = Math.ceil(Math.random()*8) + 1
                    lineData.lineContent.startControlPointY = Math.ceil(Math.random()*8) + 1
                }

                lineData.lineContent.curveStartPointX = this.rootTreeNode.value.width / 2;
                lineData.lineContent.curveStartPointY = this.rootTreeNode.value.height / 2;
                if (node.children.length == 0 || node.children[0].value.isHidden) {
                    lineData.borderThicken = true
                } else {
                    lineData.borderThicken = false
                }
                lineData.lineContent.orientation = LineOrientation.LEFT;
                lineData.lineContent.lineLayout = lineLayout;
                lineData.lineContent.cornerRadius = rootLineWidth

            } else {
                lineData.type = MindElementType.SON_LINE;

                if (this.isUnderline(parentNodeData)) {
                    lineData.y = parentNodeData.y + parentNodeData.height;
                    lineData.x = data.x + data.width;
                } else {
                    lineData.y = parentNodeData.y + parentNodeData.height / 2;
                    lineData.x = data.x + data.width;
                }
                var curveStartPoint = new Point(0, 0);
                var curveEndPoint = new Point(0, 0);
                var straightEndPoint = new Point(0, 0);

                if (this.isUnderline(data)) {
                    lineData.height = data.y - lineData.y + data.height;
                    lineData.width = parentNodeData.x - lineData.x;
                } else {
                    lineData.height = data.y - lineData.y + data.height / 2;
                    lineData.width = parentNodeData.x - lineData.x;
                }                
                curveStartPoint = new Point(lineData.width + 1, 0);
                curveEndPoint = new Point(0, lineData.height);
                straightEndPoint = new Point(0, lineData.height);
                let originalStraightEndPointX = straightEndPoint.x
                if (data.layout == NodeLayoutType.LAYOUT_LEFT) {
                    let lineHeight = Math.abs(lineData.height)
                    if (lineHeight > 160) {
                        straightEndPoint.x += Math.min(30, data.width)
                    } else if (lineHeight > 140) {
                        straightEndPoint.x += Math.min(25, data.width)
                    } else if (lineHeight > 110) {
                        straightEndPoint.x += Math.min(20, data.width)
                    } else if (lineHeight > 90) {
                        straightEndPoint.x += Math.min(15, data.width)
                    } else if (lineHeight > 45) {
                        straightEndPoint.x += Math.min(10, data.width)
                    } else if (lineHeight > 20) {
                        straightEndPoint.x += Math.min(5, data.width)
                    }
                }
                if (lineData.lineContent == null) {
                    lineData.lineContent = new LineElementContent();
                    lineData.lineContent.setLineElementContent(curveStartPoint, curveEndPoint, 0x333333, data.id, straightEndPoint);
                } else {
                    lineData.lineContent.setCurveStartPoint(curveStartPoint);
                    lineData.lineContent.setCurveEndPoint(curveEndPoint);
                    lineData.lineContent.setStraightEndPoint(straightEndPoint);
                }

                lineData.lineContent.cornerRadius = rootLineWidth/(lineData.level)
                if (parentNode.children.length != 1) {
                    let nodeIndex = 0
                    for (let index = 0; index < parentNode.children.length; index++) {
                        if (parentNode.children[index].value.id == data.id) {
                            nodeIndex = index;
                            break;
                        }
                    }
                    if (nodeIndex == 0 || nodeIndex != (parentNode.children.length - 1) / 2) {
                        if (straightEndPoint.y < curveStartPoint.y) { //向上
                            curveStartPoint.y -= ((rootLineWidth/(lineData.level - 1) - rootLineWidth/(lineData.level)) / 2)
                            lineData.lineContent.setCurveStartPoint(curveStartPoint);
                        } else if (straightEndPoint.y > curveStartPoint.y) {
                            curveStartPoint.y += ((rootLineWidth/(lineData.level - 1) - rootLineWidth/(lineData.level)) / 2)
                            lineData.lineContent.setCurveStartPoint(curveStartPoint);
                        }
                    }
                }
                if (this.isUnderline(data) && data.layout == NodeLayoutType.LAYOUT_LEFT) {
                    lineData.lineContent.endControlPointX = originalStraightEndPointX - data.width
                    lineData.lineContent.endControlPointY = straightEndPoint.y
                } else {
                    lineData.lineContent.endControlPointX = originalStraightEndPointX
                    lineData.lineContent.endControlPointY = straightEndPoint.y
                }
                
                if (isCreateLineData || lineData.lineContent.startControlPointX <= 0 || lineData.lineContent.startControlPointX > 6 && node.children.length > 0) {
                    lineData.lineContent.startControlPointX = Math.ceil(Math.random()*8) + 1
                    lineData.lineContent.startControlPointY = Math.ceil(Math.random()*5) + 1
                }
                if (node.children.length == 0 || node.children[0].value.isHidden) {
                    lineData.borderThicken = true
                } else {
                    lineData.borderThicken = false
                }
                lineData.lineContent.orientation = LineOrientation.LEFT;
                lineData.lineContent.lineLayout = lineLayout;
            }
            lineData.lineContent.text = data.textContent != null ? data.textContent.text : ""
            if (isCreateLineData) {
                let lineNodeContent = lineData.lineContent;
    
                let referenceLine = this.getReferenceLine(node.value.id);
                if (!referenceLine.isEmpty()) {
                    lineNodeContent.color = referenceLine.lineContent.color;
                    let grandpaNode = this.getNodeById(parentNode.value.parentNodeId);
                    if (referenceLine.lineContent.lineWidth == 0 &&
                            !grandpaNode.isEmpty() &&
                            grandpaNode.value.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
                        lineNodeContent.lineWidth = parentNode.value.borderWidth > 0 ? parentNode.value.borderWidth : 1.5;
                    } else {
                        lineNodeContent.lineWidth = referenceLine.lineContent.lineWidth;
                    }
                    lineNodeContent.dottedLine = referenceLine.lineContent.dottedLine;
                    lineData.isShadow = referenceLine.isShadow;
                } else {
                    lineNodeContent.color = node.value.styleColor;
                    if (this.isUnderline(data)) {
                        lineNodeContent.lineWidth = data.borderWidth;
                    } else {
                        lineNodeContent.lineWidth = this.mindMapStyleColor.getSubjectLineWidth();
                    }
                }
                if (lineData.type == MindElementType.LINE && !this.settingData.lineColor.isEmpty()) {
                    if (!this.originalRootTreeNode.isEmpty() && parentNode.value.id == this.originalRootTreeNode.value.id) {
                        parentNode = this.originalRootTreeNode;
                    }
                    // let parentNodeChildrenSize = parentNode.children.length;
                    // let lineColorLength = this.settingData.lineColor.length;
                    // for (let index = 0; index < parentNodeChildrenSize; index++) {
                    //     if (parentNode.children[index].value.id == node.value.id) {
                    //         let colorPoint = index % lineColorLength;
                    //         lineNodeContent.color = this.settingData.lineColor[colorPoint];
                    //         break;
                    //     }
                    // }
                    let colorPoint = (parentNode.children.length - 1) % this.settingData.lineColor.length;
                    lineNodeContent.color = this.settingData.lineColor[Math.abs(colorPoint)]
                }
                lineData.backgroundColor = Colors.transparent;
                lineData.borderColor = Colors.transparent;
                lineData.borderWidth = 0;
            } else {
                let childSize = parentNode.children.length;
                if (lineData.lineContent.lineWidth == 0 && childSize > 2 &&
                    parentNode.children[0].value.id != data.id &&
                    parentNode.children[childSize - 1].value.id != data.id) {
                    let firstLine = this.getLineData(parentNode.children[0]);
                    let lastLine = this.getLineData(parentNode.children[childSize - 1]);
    
                    if (!firstLine.isEmpty() && !lastLine.isEmpty() &&
                        firstLine.lineContent.lineWidth > 0 &&
                        lastLine.lineContent.lineWidth > 0) {
                        lineData.lineContent.lineWidth = firstLine.lineContent.lineWidth;
                    }
                }
            }
            
            this.setLineThicken(lineData);
        }
    }

    setGeneralizationElementsPoint(isChange) {

        let list = this.getGeneralizationMindElementDatas();
        let listCount = list.length;
        for (let index = 0; index < listCount; index++) {
            let node = list[index];
            if (node.value.isHidden ||
                !this.isIncludeGeneralizationInCurrentLayout(node) ||
                this.isLayout(node.value)) {
                continue
            }
            let mindElementData = node.value;
            let generalizationContent = mindElementData.generalizationContent;
            let top = 1000000;
            let bottom = 0;
            let right = 0;
            let encircleSpace = 0;
            for (let targetIndex = 0; targetIndex < generalizationContent.targetIds.length; targetIndex++) {
                let targetNode = this.getNodeById(generalizationContent.targetIds[targetIndex]);
                if (targetNode.isEmpty() || targetNode.value.isHidden) {
                    mindElementData.isHidden = true;
                    continue
                }
                let targetLeft = targetNode.getNodeLeft();
                let targetTop = targetNode.getNodeTop();
                let targetBottom = targetNode.getNodeBottom();
                let targetRight = targetNode.getNodeRight();

                if (targetTop < top) {
                    top = targetTop;
                }
                if (targetBottom > bottom) {
                    bottom = targetBottom;
                }
                if (targetRight > right) {
                    right = targetRight;
                }
                let space = this.getEncircleSpaceAndPadding(targetNode.value)
                if (space > 0) {
                    encircleSpace = Math.max(space, encircleSpace)
                }
                
            }
            if (top == 1000000 && right == 0) {
                continue;
            }
            generalizationContent.targetHeight = bottom - top;
            mindElementData.y = top / 2 + bottom / 2 - mindElementData.height / 2;
            mindElementData.x = encircleSpace + right + Config.GeneralizationLineWidth + Config.GeneralizationSpacing * 2;
            this.setGeneralizationElementLine(node, isChange);

            for (let childrenIndex = 0; childrenIndex < node.children.length; childrenIndex++) {
                this.setNodeElementsPoint(node.children[childrenIndex], isChange);
            }
            for (let childrenIndex = 0; childrenIndex < node.children.length; childrenIndex++) {
                this.setNodeElementsLinePoint(node.children[childrenIndex], isChange);
            }
        }
    }


    setGeneralizationElementLine(node, isChange) {
        let mindElementData = node.value;
        let generalizationContent = mindElementData.generalizationContent;
        let isCreateLineData = false;
        let lineData = this.getGeneralizationLineData(node);
        if (!isChange || lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            isCreateLineData = true;
        }
        let generalizationParentNode = this.getNodeById(node.value.generalizationContent.targetIds[0]);
        lineData.parentNodeId = mindElementData.id;
        lineData.type = MindElementType.LEFTBRACELINE;
        lineData.y = mindElementData.y + mindElementData.height / 2 - generalizationContent.targetHeight / 2;
        lineData.x = mindElementData.x - Config.GeneralizationLineWidth - Config.GeneralizationSpacing;
        lineData.width = Config.GeneralizationLineWidth;
        lineData.height = generalizationContent.targetHeight;


        let startPoint = new Point(0, 0);
        let endPoint = new Point(0, lineData.height);
        if (lineData.lineContent == null) {
            lineData.lineContent = new LineElementContent(startPoint, endPoint, mindElementData.borderColor, mindElementData.id);
        } else {
            lineData.lineContent.setStartPoint(startPoint);
            lineData.lineContent.setEndPoint(endPoint);
        }
        lineData.lineContent.orientation = LineOrientation.RIGHT;
        this.generalizationLineMindElementDataDict.put(lineData.id, lineData);

        if (isCreateLineData) {
            lineData.backgroundColor = Colors.clear;
            lineData.borderWidth = 0;
            lineData.borderColor = Colors.clear;
            let referenceLine = this.getReferenceLine(node.value.id);
            if (!referenceLine.isEmpty()) {
                lineData.lineContent.color = referenceLine.lineContent.color;
                lineData.lineContent.lineWidth = referenceLine.lineContent.lineWidth == 0 ? 1.5 : referenceLine.lineContent.lineWidth;
                lineData.isShadow = referenceLine.isShadow;
            } else {
                let generalizationParentNodeLine = this.getLineData(generalizationParentNode);
                if (generalizationParentNodeLine.isEmpty()) {
                    lineData.lineContent.lineWidth = generalizationParentNode.value.borderWidth == 0 ? 1.5 : generalizationParentNode.value.borderWidth;
                    let color = generalizationParentNode.value.borderColor;
                    if (color == Colors.clear) {
                        color = generalizationParentNode.value.backgroundColor;
                    }
                    if (color == Colors.clear) {
                        color = Colors.black60;
                    }
                    lineData.lineContent.color = color;
                } else {
                    lineData.lineContent.lineWidth = generalizationParentNodeLine.lineContent.lineWidth == 0 ? 1.5 : generalizationParentNodeLine.lineContent.lineWidth;
                    lineData.lineContent.color = generalizationParentNodeLine.lineContent.color;
                }
            }
        }
    }



    getTreeHeight(node) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (this.dataHeightMap.containsKey(node.value.id)) {
            return this.dataHeightMap.get(node.value.id);
        }

        if (this.isRootNode(node) && node.children.length != this.rootTreeNode.children.length) {
            node = this.getNodeById(node.value.id);
        }
        let hieght = 0;
        if (this.isRootNode(node) || node.value.layout == NodeLayoutType.LAYOUT_RIGHT) {
            let topHeight = this.getTreeTopHeight(node);
            let bottomHeight = this.getTreeBottomHeight(node);
            hieght = topHeight + bottomHeight;
        } else {
            let layout = this.getNodeLayout(node);
            hieght = layout.getTreeHeight(node);
            if (node.value.layout == NodeLayoutType.LAYOUT_FORM || node.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                hieght += this.getEncircleBottomHeight(node.value) + this.getEncircleTopHeight(node.value);
            }
            layout.clearDatas();
        }
        this.dataHeightMap.put(node.value.id, hieght);
        return hieght;
    }

    getTreeTopHeight(node) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (this.dataTopHeightMap.containsKey(node.value.id)) {
            return this.dataTopHeightMap.get(node.value.id);
        }
        if (this.isRootNode(node) && node.children.length != this.rootTreeNode.children.length) {
            node = this.getNodeById(node.value.id);
        }

        let nodeSelfTop = 0;
        if (((node.value.type == MindElementType.SUBJECT || node.value.type == MindElementType.SON_SUBJECT) && 
        !this.isUnderline(node.value)) || 
        node.value.type == MindElementType.MAIN_SUBJECT || node.value.type == MindElementType.CONTENT_GENERALIZATION) {
            nodeSelfTop = node.value.height / 2;
        } else {
            nodeSelfTop = node.value.height;
        }

        let nodeEncircleTopHeight = this.getEncircleTopHeight(node.value);
        nodeSelfTop += nodeEncircleTopHeight;

        let floatExplainData = this.getNodeExplain(node);
        if (!floatExplainData.isEmpty()) {
            nodeSelfTop += floatExplainData.height + Config.NodeFloatExplainSpace;
            if (!Colors.isClear(floatExplainData.borderColor)) {
                nodeSelfTop += floatExplainData.borderWidth
            }
        }

        let generalizationNode = this.getNodeGeneralization(node);
        if (!generalizationNode.isEmpty() &&
            node.value.layout != NodeLayoutType.LAYOUT_FORM &&
            node.value.layout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL &&
            generalizationNode.value.generalizationContent != null &&
            !generalizationNode.value.generalizationContent.targetIds.isEmpty() &&
            generalizationNode.value.generalizationContent.targetIds[0] == node.value.id) {
            let generalizationNodeHeight = this.getTreeHeight(generalizationNode);
            let generalizationTargetNodeHeight = this.getGeneralizationTargetHeight(generalizationNode);
            if (generalizationTargetNodeHeight > 0 && generalizationNodeHeight > generalizationTargetNodeHeight) {
                nodeSelfTop += (generalizationNodeHeight - generalizationTargetNodeHeight) / 2;
            }
            //nodeSelfTop = Math.max(nodeSelfTop, this.getTreeTopHeight(generalizationNode));
        }

        if (node.children.isEmpty() || node.children[0].value.isHidden) {
            this.dataTopHeightMap.put(node.value.id, nodeSelfTop);
            return nodeSelfTop;
        }
        if (!this.isRootNode(node) &&
            node.value.layout != NodeLayoutType.LAYOUT_RIGHT &&
            node.value.layout != NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
            let layoutTop = nodeSelfTop;
            if (node.value.layout == NodeLayoutType.LAYOUT_BOTTOM ||
                node.value.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                node.value.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_FORM ||
                node.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                layoutTop = nodeSelfTop;
            } else if (node.value.layout == NodeLayoutType.LAYOUT_TOP ||
                node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                let layout = this.getNodeLayout(node);
                layoutTop = layout.getTreeHeight(node) - node.value.height + nodeSelfTop;
                layout.clearDatas();
            } else if (node.value.layout == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                let layout = this.getNodeLayout(node);
                layoutTop = layout.getTreeTopHeight(node);
                layout.clearDatas();
            } else if (node.value.layout == NodeLayoutType.LAYOUT_FISH_LEFT) {
                let layout = this.getNodeLayout(node);
                layoutTop = layout.getTreeTopHeight(node);
                layout.clearDatas();
            } else if (node.value.layout == NodeLayoutType.LAYOUT_TRIANGLE) {
                let layout = this.getNodeLayout(node);
                layoutTop = layout.getTreeHeight(node);
                layout.clearDatas();
            }
            this.dataTopHeightMap.put(node.value.id, layoutTop);
            return layoutTop;
        }
        let childCount = node.children.length;
        let firstTop = this.getTreeTopHeight(node.children[0]) + nodeEncircleTopHeight;
        if (childCount == 1) {
            let topHeight = Math.max(nodeSelfTop, firstTop);
            this.dataTopHeightMap.put(node.value.id, topHeight);
            return topHeight;
        }

        let childTotle = nodeEncircleTopHeight + this.getEncircleBottomHeight(node.value);
        for (let index = 0; index < childCount; index++) {
            let cell = node.children[index];
            let height = this.getTreeHeight(cell);
            childTotle += height;
            if (index < childCount - 1) {
                childTotle += cell.value.type == MindElementType.SON_SUBJECT ? this.SonNodeVerticalSpacee : this.NodeVerticalSpacee;
            }
        }
        let lastBottom = this.getTreeBottomHeight(node.children[childCount - 1]);
        let height = (childTotle - firstTop - lastBottom) / 2 + firstTop;
        let topHeight = Math.max(height, nodeSelfTop);
        this.dataTopHeightMap.put(node.value.id, topHeight);
        return topHeight;
    }

    getTreeBottomHeight(node) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (this.dataBottomHeightMap.containsKey(node.value.id)) {
            return this.dataBottomHeightMap.get(node.value.id);
        }
        if (this.isRootNode(node) && node.children.length != this.rootTreeNode.children.length) {
            node = this.getNodeById(node.value.id);
        }
        let nodeSelfBottom = 0;
        if (((node.value.type == MindElementType.SUBJECT ||
            node.value.type == MindElementType.SON_SUBJECT) &&
            !this.isUnderline(node.value)) ||
            node.value.type == MindElementType.MAIN_SUBJECT || node.value.type == MindElementType.CONTENT_GENERALIZATION) {
            nodeSelfBottom = node.value.height / 2 + this.getNodeExplainHeight(node.value) + Config.NodeExplainTop;
        } else {
            nodeSelfBottom = + this.getNodeExplainHeight(node.value) + Config.NodeExplainTop;
        }

        let nodeEncircleBottomHeight = this.getEncircleBottomHeight(node.value);
        nodeSelfBottom += nodeEncircleBottomHeight;

        let generalizationNode = this.getNodeGeneralization(node);
        if (!generalizationNode.isEmpty() &&
            node.value.layout != NodeLayoutType.LAYOUT_FORM &&
            node.value.layout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL &&
            generalizationNode.value.generalizationContent != null &&
            !generalizationNode.value.generalizationContent.targetIds.isEmpty() &&
            generalizationNode.value.generalizationContent.targetIds[generalizationNode.value.generalizationContent.targetIds.length-1]== node.value.id) {
            let generalizationNodeHeight = this.getTreeHeight(generalizationNode);
            let generalizationTargetNodeHeight = this.getGeneralizationTargetHeight(generalizationNode);
            if (generalizationTargetNodeHeight > 0 && generalizationNodeHeight > generalizationTargetNodeHeight) {
                nodeSelfBottom += (generalizationNodeHeight - generalizationTargetNodeHeight) / 2;
            }
            //nodeSelfBottom = Math.max(nodeSelfBottom, this.getTreeBottomHeight(generalizationNode));
        }

        if (node.children.isEmpty() || node.children[0].value.isHidden) {
            this.dataBottomHeightMap.put(node.value.id, nodeSelfBottom);
            return nodeSelfBottom;
        }
        if (!this.isRootNode(node) &&
            node.value.layout != NodeLayoutType.LAYOUT_RIGHT &&
            node.value.layout != NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
            let layoutBottom = nodeSelfBottom;
            if (node.value.layout == NodeLayoutType.LAYOUT_BOTTOM ||
                node.value.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                node.value.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT) {
                let layout = this.getNodeLayout(node);
                layoutBottom = layout.getTreeHeight(node) - node.value.height + nodeSelfBottom;
                layout.clearDatas();
            } else if (node.value.layout == NodeLayoutType.LAYOUT_TOP ||
                node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                layoutBottom = nodeSelfBottom;
            } else if (node.value.layout == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                let layout = this.getNodeLayout(node);
                layoutBottom = layout.getTreeBottomHeight(node);
                layout.clearDatas();
            } else if (node.value.layout == NodeLayoutType.LAYOUT_FISH_LEFT) {
                let layout = this.getNodeLayout(node);
                layoutBottom = layout.getTreeBottomHeight(node);
                layout.clearDatas();
            } else if (node.value.layout == NodeLayoutType.LAYOUT_TRIANGLE) {
                layoutBottom = 0;
            } else if (node.value.layout == NodeLayoutType.LAYOUT_FORM || node.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                let layout = this.getNodeLayout(node);
                if (this.isUnderline(node.value)) {
                    layoutBottom = layout.getTreeHeight(node) - node.value.height;
                } else {
                    layoutBottom = layout.getTreeHeight(node) - node.value.height / 2;
                }
                layoutBottom += this.getEncircleBottomHeight(node.value);
                layout.clearDatas();
            }
            this.dataBottomHeightMap.put(node.value.id, layoutBottom);
            return layoutBottom;
        }

        let childCount = node.children.length;
        let lastBottom = this.getTreeBottomHeight(node.children[childCount - 1]) + nodeEncircleBottomHeight;
        if (childCount == 1) {
            let bottomHeight = Math.max(lastBottom, nodeSelfBottom);
            this.dataBottomHeightMap.put(node.value.id, bottomHeight);
            return bottomHeight;
        }
        let childTotle = this.getEncircleTopHeight(node.value) + nodeEncircleBottomHeight;
        for (let index = 0; index < childCount; index++) {
            let cell = node.children[index];
            let height = this.getTreeHeight(cell);
            childTotle += height;
            if (index < childCount - 1) {
                childTotle += cell.value.type == MindElementType.SON_SUBJECT ? this.SonNodeVerticalSpacee : this.NodeVerticalSpacee;
            }
        }
        let firstTop = this.getTreeTopHeight(node.children[0]);
        let height = (childTotle - firstTop - lastBottom) / 2 + lastBottom;

        let bottomHeight = Math.max(height, nodeSelfBottom);
        this.dataBottomHeightMap.put(node.value.id, bottomHeight);
        return bottomHeight;
    }

    getTreeWidth(node) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (!this.isRootNode(node) && node.value.layout != NodeLayoutType.LAYOUT_RIGHT) {
            let layout = this.getNodeLayout(node);
            return layout.getTreeWidth(node);
        }
        if (this.isRootNode(node) && node.children.length != this.rootTreeNode.children.length) {
            node = this.getNodeById(node.value.id);
        }
        let width = node.value.width;
        let nodeGeneralizationWidth = this.getNodeGeneralizationWidth(node);
        nodeGeneralizationWidth = nodeGeneralizationWidth > 0 ? nodeGeneralizationWidth + Config.GeneralizationLineWidth + Config.GeneralizationSpacing * 2 : 0;
        width = width + nodeGeneralizationWidth;
        let siblingsMaxWidth = 0;
        for (let index = 0; index < node.children.length; index++) {
            let chlidWidth = 0;
            let horizontalSpacee = this.getNodeHorizontalSpace(node.children[index], node);
            if (!node.children[index].children.isEmpty()) {
                chlidWidth += this.getTreeWidth(node.children[index]) + horizontalSpacee;
            } else {
                if (node.children[index].value.isHidden) {
                    continue;
                }
                let nodeidth = node.children[index].value.width;
                let chlidGeneralizationWidth = this.getNodeGeneralizationWidth(node.children[index]);
                chlidGeneralizationWidth = chlidGeneralizationWidth > 0 ? chlidGeneralizationWidth + Config.GeneralizationLineWidth + Config.GeneralizationSpacing * 2 : 0;
                nodeidth = nodeidth + chlidGeneralizationWidth;
                chlidWidth += nodeidth + horizontalSpacee;
            }
            if (siblingsMaxWidth < chlidWidth) {
                siblingsMaxWidth = chlidWidth;
            }
        }
        return siblingsMaxWidth + width;
    }

    getSiblingsNodesHeight(nodes) {
        if (nodes.length == 0) {
            return 0;
        }
        let height = this.getTreeHeight(this.getNodeById(nodes[0].id));
        if (nodes.length > 1) {
            for (var index = 1; index < nodes.length; index++) {
                let data = nodes[index];
                if (data.isHidden) {
                    continue
                }
                height += this.getTreeHeight(this.getNodeById(data.id)) + (data.type == MindElementType.SON_SUBJECT ? this.SonNodeVerticalSpacee : this.NodeVerticalSpacee);
            }
        }
        return height;
    }

    getEncircleTopHeight(data) {
        let encircleMindElementDataDictArr = this.encircleMindElementDataDict.keys();

        for (let index = 0; index < encircleMindElementDataDictArr.length; index++) {
            let key = encircleMindElementDataDictArr[index];
            let mind = this.encircleMindElementDataDict.get(key);
            if (mind.lineContent == null) {
                return 0;
            }
            let node = this.getNodeById(mind.parentNodeId);
            if (node.isEmpty()) {
                continue;
            }
            let targetIds = new Array();
            if (mind.lineContent != null) {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                targetIds.push(mind.parentNodeId);
            }
            for (let index = 0; index < targetIds.length; index++) {
                let id = targetIds[index];
                if (id == data.id) {
                    if (this.isTopNode(id, targetIds)) {
                        if (mind.lineContent.isContainText()) {
                            return new EncircleNodesPointsCalculation().space + mind.lineContent.textContent.height + mind.lineContent.padding;
                        } else {
                            return new EncircleNodesPointsCalculation().space + mind.lineContent.padding;
                        }
                    } else {
                        return 0
                    }
                }
            }
        }
        return 0;
    }

    getEncircleBottomHeight(data) {
        let encircleMindElementDataDictArr = this.encircleMindElementDataDict.keys();
        for (let index = 0; index < encircleMindElementDataDictArr.length; index++) {
            let key = encircleMindElementDataDictArr[index];
            let mind = this.encircleMindElementDataDict.get(key);
            if (mind.lineContent == null) {
                return 0;
            }
            let node = this.getNodeById(mind.parentNodeId);
            if (node.isEmpty()) {
                continue;
            }
            let targetIds = new Array();
            if (mind.lineContent != null) {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                targetIds.push(mind.parentNodeId);
            }
            for (let index = 0; index < targetIds.length; index++) {
                let id = targetIds[index];
                if (id == data.id) {
                    if (this.isBottomNode(id, targetIds)) {
                        return new EncircleNodesPointsCalculation().space + mind.lineContent.padding;
                    } else {
                        return 0;
                    }
                }
            }
        }
        return 0;
    }

    getNodeHorizontalSpace(node, parentNode) {
        if (parentNode == null || node == null || parentNode.isEmpty() || node.isEmpty() || parentNode.children.isEmpty()) {
            return this.addSonSubjectSpcaeHorizontal(Config.SonNodeHorizontalSpacee);
        }
        let lineData = this.getLineData(parentNode.children[0]);
        let lineLayout = this.getNodeLineLayout(node, lineData);
        let nodeList = new NodesRectCalculation().getNodesForHeight(parentNode);
        
        if (parentNode.value.type == MindElementType.MAIN_SUBJECT) {  
            return super.getNodeHorizontalSpace()  
        } else {
            let lineData = this.getLineData(node);
            let lineLayout = this.getNodeLineLayout(node, lineData);
            if (lineLayout == LineLayout.STRAIGHT_LINE ||
                    lineLayout == LineLayout.STRAIGHT_LINE_2 ||
                    lineLayout == LineLayout.CURVE_LINE ||
                    lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                    lineLayout == LineLayout.STRAIGHT_CURVE_LINE ||
                    lineLayout == LineLayout.CURVE_LINE_2 ||
                    lineLayout == LineLayout.CURVE_LINE_AVERAGE) {
                let space = this.addSonSubjectSpcaeHorizontal((Config.SonNodeHorizontalSpacee * 2.50)) + this.getNodeContainEncircleLine(node, parentNode);
                if (parentNode.children.length > 6) {
                    if (lineLayout == LineLayout.CURVE_LINE_AVERAGE ||
                        lineLayout == LineLayout.STRAIGHT_LINE_2 ||
                        lineLayout == LineLayout.STRAIGHT_LINE ||
                        lineLayout == LineLayout.CURVE_LINE ||
                        lineLayout == LineLayout.CURVE_LINE_2 ||
                        lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                        lineLayout == LineLayout.STRAIGHT_CURVE_LINE ) {
                        space = space + Math.min(3 * (parentNode.children.length - 6), 40)
                    }
                } else if (nodeList.length > 10) {
                    if (lineLayout == LineLayout.CURVE_LINE_AVERAGE ||
                        lineLayout == LineLayout.STRAIGHT_LINE_2 ||
                        lineLayout == LineLayout.STRAIGHT_LINE ||
                        lineLayout == LineLayout.CURVE_LINE ||
                        lineLayout == LineLayout.CURVE_LINE_2 ||
                        lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                        lineLayout == LineLayout.STRAIGHT_CURVE_LINE ) {
                            space = space + Math.min(3 * (nodeList.length - 6), 40)
                    }
                }
                return space;
            } else if (lineLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE ||
                        lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE) {
                return this.addSonSubjectSpcaeHorizontal((Config.SonNodeHorizontalSpacee * 2.20)) + this.getNodeContainEncircleLine(node, parentNode);
            } else {
                return this.addSonSubjectSpcaeHorizontal(Config.SonNodeHorizontalSpacee * 2) + this.getNodeContainEncircleLine(node, parentNode);
            }
        }
    }

    getLayoutType() {
        return NodeLayoutType.LAYOUT_RIGHT;
    }

    getFloatExplainLineOrientation() {
        return LineOrientation.BOTTOM;
    }

}

export default NewFreeTreeLayout
