import BaseInputView from './BaseInputView'

/**
 * Created by tony on 2019/12/17.
 */

class MainSubjectInputView extends BaseInputView {
    constructor(viewCanvs, data, vuethis) {
        super(viewCanvs, data, vuethis);
    }
}

export default MainSubjectInputView