<template>
  <!--  关联线结构  -->
  <div class="ComplieHeaderImg" id="association-line-structure" ref="associationLineStructureBox">
    <div class="complie-header-imgbox">
      <!-- <ul class="menu-use-box"> -->
        <div
          class="complie-com-box"
          v-for="(item, index) in imgDataList"
          :key="index"
          :class=" index == imgDataList.length - 1 ? 'complie-com-box-last' : ''"
          @click="getCorrelationLineType(item.lineType, mapEditDarkMode?item.imgWhite:item.imgData)"
        >
          <div class="complie-left-icon">
            <img v-if="mapEditDarkMode" class="menu-line-icon" :src="item.imgWhite" />
            <img v-else class="menu-line-icon" :src="item.imgData" />
          </div>
        </div>
      <!-- </ul> -->
    </div>
  </div>
</template>
<script>
import { mapMutations,mapState } from "vuex";
import ConnectLineType from "../../../viewmodel/datatype/ConnectLineType";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
export default {
  name: "ComplieChildMenuCorrelationLine",
  data() {
    return {
      imgDataList: [
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/joinCurve.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/joinCurveWhite.svg"),
          lineType: ConnectLineType.CURVE_LINE,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/joinStraight.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/joinStraightWhite.svg"),
          lineType: ConnectLineType.STRAIGHT_ARROW_LINE,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/joinZigZag.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/joinZigZagWhite.svg"),
          lineType: ConnectLineType.RIGHT_ANGLE_LINE,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/joinDotStraight.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/joinDotStraightWhite.svg"),
          lineType: ConnectLineType.STRAIGHT_CIRCULAR_LINE,
        },
      ],
      imgDataAccout: 0,
      layoutType: "",
      viewModel: null,
      mindOperateUIControllerView: null,
    };
  },
  computed: {
    ...mapState({
      mapEditDarkMode: (state) => state.mapEditIsDardarkMode.darkMode,//深色模式
    }),
  },
  created() {},
  mounted() {
    this.setDardarkMode();
  },
  methods: {
    ...mapMutations(["correlationLineStyleLayout"]),
    getCorrelationLineType(type, url) {
      EditMindmapVM.changeConnectLineShape(type);
      this.correlationLineStyleLayout(url);
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        let boxDom = this.$refs.associationLineStructureBox;
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        if (boxDom) {
          if (dardarkMode.darkMode) {
            boxDom.style.border = '1px solid rgba(255,255,255,.3)';
            boxDom.style.background = dardarkMode.bgColor;
          } else {
            boxDom.style.border = 0;
            boxDom.style.background = "#fff";
          }
        }
      });
    },
  },
  watch: {
    "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.menu-use-content-inside {
  width: 268px;
  height: 184px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.1);
}
.menu-use-content-inside ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.menu-use-content-inside ul li {
  width: 45px;
  height: 45px;
  float: left;
  list-style: none;
  margin: 8px 8px;
  padding: 3px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-use-content-inside ul li:hover {
  background: #e0e0e0;
}
.menu-theme-line {
  background: #e0e0e0;
}
.ComplieHeaderImg {
  width: 198px;
  height: 193px;
  background: #ffffff;
  border: 1px solid #cccccc;
  opacity: 1;
  border-radius: 8px;
  position: absolute;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  margin-left: -70px;
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.1);
}
.complie-header-imgbox {
  margin: 7px 25px;
}
.complie-com-box {
  width: 138px;
  height: 44px;
  border-bottom: 1px dashed var(--exportMenuDashedColor);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.complie-com-box:hover{
  background-color: var(--exportMenuBg);
}
.complie-com-box-last {
  border-bottom: transparent;
}
.complie-left-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
}
</style>