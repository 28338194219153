import Config from "../../../core/core/calcule/Config";
import Colors from "../../../utils/Colors";
import Util from "../../../utils/Util";
import LinePath from "../../../viewmodel/core/base/LinePath";
import Point from "../../../viewmodel/core/base/Point";

class PriorityView {

        constructor(context, MindElementData,mindTextSizeChangedDelegate) {
            this.context = context;
            this.data = MindElementData;
            this.mindTextSizeChangedDelegate = mindTextSizeChangedDelegate
            this.paint;
            this.cellSize = 96;
            this.cellSpace = 48;
            this.initView();
        }

        initView() {
            this.paint = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            this.onDraw();
            
        this.paint.addEventListener('mouseup', (ev)=>{
            if (ev.button == 2) {
                window.event ? window.event.cancelBubble = true : ev.stopPropagation();
                this.mindTextSizeChangedDelegate.onSelected(this.data, new Point(-1, -1), { type: 'weightClick' }, ev);
            }
        });
        }

        refreshView() {
            this.paint.innerHTML = "";
            this.onDraw();
        }

        onDraw() {
            let priority = Math.max(1, this.data.taskContent.priority);
            let bgColor = 0;
            if (!Colors.isClear(this.data.backgroundColor)) {
                bgColor = this.data.backgroundColor;
            } else {
                bgColor = this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor;
            }
            this.paint.setAttribute("width", 20);
            this.paint.setAttribute("height", 3);
            this.paint.setAttribute("viewBox", "10 0 380 100");
            this.paint.style.float ="right";
            this.paint.style.marginTop ="-3px";


            for (var priorityIndex = 0; priorityIndex < 4; priorityIndex++) {
                let paintStar = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                paintStar.setAttribute("stroke-width",1);
                let path = new LinePath();
                let circleCenter = new Point(this.cellSize/2 + (this.cellSpace + this.cellSize) * priorityIndex , this.cellSize/2);
                if (priorityIndex < priority) {
                    if (Colors.isDarkColor(bgColor)) {
                        paintStar.setAttribute('stroke',Colors.getUiColor(Colors.white));
                        paintStar.setAttribute('fill',Colors.getUiColor(Colors.white));
                    } else {
                        paintStar.setAttribute('stroke',Colors.getUiColorByAlpha (Colors.red, 0.8));
                        paintStar.setAttribute('fill',Colors.getUiColorByAlpha (Colors.red, 0.8));
                    }
                } else {
                    if (Colors.isDarkColor(bgColor)) {
                        paintStar.setAttribute('stroke',Colors.getUiColorByAlpha (Colors.black30, 0.6));
                        paintStar.setAttribute('fill',Colors.getUiColorByAlpha (Colors.black30, 0.6));
                    } else {
                        paintStar.setAttribute('stroke',Colors.getUiColorByAlpha (Colors.black50, 0.4));
                        paintStar.setAttribute('fill',Colors.getUiColorByAlpha (Colors.black50, 0.4));
                    }
                }
                let radius = this.cellSize/2;
                let innerRadius = radius * 0.55;
                let angle = 72;

                let startPoint = new Point();
                for (var index = 0; index < 5; index++) {
                    let point = Util.getCirclePoint(circleCenter, (270 + index * angle), radius);
                    let innerPoint = Util.getCirclePoint(circleCenter, (270 + angle/2 + index * angle), innerRadius);
                    if (index == 0) {
                        path.moveToByPoint(point);
                        startPoint = point;
                    } else {
                        path.lineToByPoint(point);
                    }
                    path.lineToByPoint(innerPoint);
                    
                }
                path.lineToByPoint(startPoint);
                paintStar.setAttribute("d", path.getLine())
                this.paint.appendChild(paintStar)
            }
            this.context.appendChild(this.paint);
        }
    }

    
export default PriorityView