import Util from "../../../utils/Util";
import MindElementData from "../../../viewmodel/mindelementdata/MindElementData";
import MindCopyManager from "./MindCopyManager";
import MindElementDataDeserialize from "../dataformat/MindElementDataDeserialize";

class MindCopyStyleData {
    constructor() {
        this.styleData = new MindElementData().emptyMindNode();
        this.setCopyStyleDataTime = -1;
    }

    toJSONString() {
        let nodeStr = JSON.stringify(this, function (key, val) {
            if (typeof val === "function") {
                return val + '';
            }
            return val
        });
        return nodeStr;
    }

    setData(data) {
        this.styleData = data.copy();
        this.setCopyStyleDataTime = Util.getCurrentTime();
        this.save();
    }

    save() {
        let json = this.toJSONString();
        let key = MindCopyManager.CopyStyleDataKey;
        window.localStorage.setItem(key,json,this.setCopyStyleDataTime)
    }

    getData() {
        let key = MindCopyManager.CopyStyleDataKey;
        let json = window.localStorage.getItem(key)
        let mind = JSON.parse(json)
        if (mind == null || mind["styleData"] == null || mind["setCopyStyleDataTime"] == null) {
            return null;
        }
        
        let mindData = MindElementDataDeserialize.deserialize(mind["styleData"]);
        if (mindData == null || mindData.isEmpty()) {
            return null;
        }
        this.setCopyStyleDataTime = mind["setCopyStyleDataTime"];
        this.styleData = mindData;
        return this.styleData;
    }

    isHasCopyStyleData() {
        this.styleData = this.getData();
        if(this.styleData == undefined  ){
            return false;
        }
        return !this.styleData.isEmpty() && this.setCopyStyleDataTime > 0 && Util.getCurrentTime() - this.setCopyStyleDataTime < MindCopyManager.TimeDifference;
    }
}
export default MindCopyStyleData