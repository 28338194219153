/**
 * ProjectName: MindMap
 * Created by tony on 2020/8/6
 * Copyright(c) 2020 mindyushu.com
 */
import HashMap from "../../core/base/HashMap";
import Colors from "../../../utils/Colors"
import LineLayout from "../../../viewmodel/datatype/LineLayout";
import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType"
import DoubleBubbleMindNodeUnit from "../../../viewmodel/mindelementdata/DoubleBubbleMindNodeUnit"
import LineMindTypeNode from "../../../viewmodel/mindelementdata/LineMindTypeNode"
import TimeMindTypeNodeUnit from "../../../viewmodel/mindelementdata/TimeMindTypeNodeUnit"
import SettingData from "./SettingData";
import MindType from "../../datatype/MindType";
import MindDateFormat from "../../../core/core/dataformat/MindDateFormat";
import TimeLineMindDateFormat from "../../../core/core/dataformat/TimeLineMindDateFormat";
import DoubleBubbleMindDateFormat from "../../../core/core/dataformat/DoubleBubbleMindDateFormat";
import LineMindTypeNodeUnit from "../../mindelementdata/LineMindTypeNodeUnit";
import IdGenerator from "../base/IdGenerator";
import MindMapStyleColor from "../base/MindMapStyleColor";
import MindElementType from "../../datatype/MindElementType";
import MindDisplayType from "../../datatype/MindDisplayType";

class MindHistoryData {

    constructor() {
        this.mindType = MindType.LINE_MAP;
        this.mindkey = "";
        this.mainMindNodeUnit = null;
        this.timeMindTypeNodeUnit = null;
        this.DoubleBubbleMindNodeUnit = null;
        this.nodeConnectLineDataDict = null; //节点连接线
        this.mindMapStyleColor = null;
        this.freeNodes = new Array();
        this.mindBGColor = Colors.white;
        this.settingData = null;
        this.globalLayout = NodeLayoutType.LAYOUT_RIGHT;
        this.globalLineLayout = LineLayout.CURVE_LINE_2;
        this.contentJson = ""

    }

    copyBaseData(type, globalLayout, globalLineLayout, mindBGColor) {
        this.mindType = type;
        this.globalLayout = globalLayout;
        this.globalLineLayout = globalLineLayout;
        this.mindBGColor = mindBGColor;
        return this;
    }

    setContentJson(content) {
        this.contentJson = content;
        return this;
    }

    clearDatas() {
        this.mainMindNodeUnit = null;
        this.timeMindTypeNodeUnit = null
        this.DoubleBubbleMindNodeUnit = null
        this.nodeConnectLineDataDict = null; //节点连接线
        this.freeNodes = new Array();
        this.mindBGColor = Colors.white;
        this.settingData = null;
        this.mindMapStyleColor = null;
    }

    parse() {
        if (this.contentJson == null || this.contentJson.length == 0) {
            return;
         }
 
         if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
             let mind = new MindDateFormat().format(this.contentJson);
               this.setMindDataFormCache(mind);
         } else if (this.mindType == MindType.TIME_MAP) {
            let mind = new TimeLineMindDateFormat().format(this.contentJson);
            this.setTimeLineMindDataFormCache(mind);
         } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            let mind = new DoubleBubbleMindDateFormat().format(this.contentJson);
            this.setDoubleBubbleMindDateFormCache(mind);
         }
    }

    getNodeById(id) {
        let node = new LineMindTypeNode();
        if (this.mainMindNodeUnit != null) {
            node = this.mainMindNodeUnit.getNodeById(id);
            if (!node.isEmpty()) {
                return node;
            }
        }
        let len = this.freeNodes.length;
        for (let index = 0; index < len; index++) {
            let unit = this.freeNodes[index];
            node = unit.getNodeById(id);
            if (!node.isEmpty()) {
                return node;
            }
        }
        if (this.doubleBubbleMindNodeUnit != null) {
            node = this.doubleBubbleMindNodeUnit.getNodeById(id);
            if (!node.isEmpty()) { 
                return node;
            }
        }

        if (this.timeMindTypeNodeUnit != null) {
            let data = this.timeMindTypeNodeUnit.getNodeById(id);
            if (!data.isEmpty()) {
                return new LineMindTypeNode(data);
            }
        }

        if ((node == null || node.isEmpty()) && this.nodeConnectLineDataDict != null && this.nodeConnectLineDataDict.containsKey(id)) {
            return new LineMindTypeNode(this.nodeConnectLineDataDict.get(id));
        }
        return node;
    }

    setMindDataFormCache(data) {
        this.mindkey = data.mindkey;
        this.mainMindNodeUnit = new LineMindTypeNodeUnit();
        this.nodeConnectLineDataDict = new HashMap();
        if (data.rootNode != null) {
            this.mainMindNodeUnit.globalLayout = data.globalLayout;
            this.mainMindNodeUnit.setRootNode(data.rootNode);
            this.mindBGColor = data.mindBGColor;
            this.settingData = data.settingData.copy()
            this.mainMindNodeUnit.setLineColors(data.settingData.lineColor);
            this.mindMapStyleColor = new MindMapStyleColor(data.styleIndex);
            this.mainMindNodeUnit.setMindMapStyleColor(data.styleIndex)
            this.mindGlobalAudio = data.mindGlobalAudio;
            this.mindBGStripesType = data.mindBGStripesType;
            this.mainMindNodeUnit.setMindBGColor(this.mindBGColor);
        }
        this.mindDisplayType = data.mindDisplayType;
        this.mainMindNodeUnit.mindDisplayType = data.mindDisplayType;
        this.globalLineLayout = data.globalLineLayout;
        this.mainMindNodeUnit.globalLineLayout = data.globalLineLayout;
        data.generalizationLine.forEach(line => {
            this.mainMindNodeUnit.generalizationLineMindElementDataDict.put(line.id, line);
        });
        data.explainData.forEach(cell => {
            this.mainMindNodeUnit.explainMindElementDataDict.put(cell.id, cell);
        });
        data.generalizationNode.forEach(node => {
            this.mainMindNodeUnit.setGeneralizationNode(node);
        });
        let rootLineCount = data.rootLine.length;
        for (let nber = 0; nber < rootLineCount; nber++) {
            let line = data.rootLine[nber];
            if (line.lineContent != null) {
                IdGenerator.shared.setId(line.id);
                let node = this.mainMindNodeUnit.getNodeById(line.lineContent.targetId);
                let targetLine = this.mainMindNodeUnit.getNodeLine(line.lineContent.targetId);
                if (!node.isEmpty() &&
                        (targetLine.isEmpty() ||
                        line.type == MindElementType.LAYOUT_CUSTOM_LINE && targetLine.type != MindElementType.LAYOUT_CUSTOM_LINE)) {
                    let isAdd = false;
                    if (line.type == MindElementType.LAYOUT_CUSTOM_LINE) {
                        if (node.value.layout != NodeLayoutType.LAYOUT_RADIATE) {
                            continue;
                        }
                    } else {                   
                        let values = this.mainMindNodeUnit.lineMindElementDataDict.values();
                        let valuesCount = values.length;
                        for (let index = 0; index < valuesCount; index++) {
                            let lineData = values[index];
                            if ((lineData.type == MindElementType.LINE ||
                                lineData.type == MindElementType.SON_LINE) &&
                                lineData.lineContent != null &&
                                lineData.lineContent.targetId == line.lineContent.targetId &&
                                lineData.parentNodeId == line.parentNodeId) {
                                isAdd = true;
                                break;
                            }
                        }
                    }
                    if (isAdd) {
                        continue;
                    }

                    this.mainMindNodeUnit.lineMindElementDataDict.put(line.id, line);
                    this.mainMindNodeUnit.textElementLineMindElementDataDict.put(line.lineContent.targetId, line);
                }
            }
        }
        
        data.nodeConnectLinet.forEach(line => {
            this.nodeConnectLineDataDict.put(line.id, line);
            IdGenerator.shared.setId(line.id);
        });
        data.encircle.forEach(line => {
            this.mainMindNodeUnit.encircleMindElementDataDict.put(line.id, line);
            IdGenerator.shared.setId(line.id);
        });
        this.freeNodes = [];
        data.freeNodes.forEach(unit => {
            if (unit.rootNode != null) {
                let freeNode = this.createFreeNodeUnit();
                freeNode.setRootNode(unit.rootNode);
                freeNode.mindDisplayType = this.mindDisplayType;
                unit.rootLine.forEach(line => {
                    freeNode.lineMindElementDataDict.put(line.id, line);
                    IdGenerator.shared.setId(line.id);
                    freeNode.textElementLineMindElementDataDict.put(line.lineContent.targetId, line);
                });
                unit.generalizationLine.forEach(line => {
                    freeNode.generalizationLineMindElementDataDict.put(line.id, line);
                    IdGenerator.shared.setId(line.id);
                });
                unit.generalizationNode.forEach(node => {
                    freeNode.setGeneralizationNode(node);
                });
                unit.encircle.forEach(line => {
                    freeNode.encircleMindElementDataDict.put(line.id, line);
                    IdGenerator.shared.setId(line.id);
                });
                unit.explainData.forEach(cell => {
                    freeNode.explainMindElementDataDict.put(cell.id, cell);
                    IdGenerator.shared.setId(cell.id);
                });
                if (unit.globalLayout == NodeLayoutType.LAYOUT_UNKNOWN) {
                    freeNode.setElementLayout(data.globalLayout, true);
                } else {
                    freeNode.setElementLayout(unit.globalLayout, true);
                }
                if (unit.globalLineLayout == LineLayout.UNKNOWN) {
                    freeNode.globalLineLayout = data.globalLineLayout;
                } else {
                    freeNode.globalLineLayout = unit.globalLineLayout;
                }
            }
        });
        this.setGlobalLayout(data.globalLayout, true);
     }
  
     setTimeLineMindDataFormCache(data) {
        this.mindkey = data.mindkey;
        if (data == null || data.title == null || data.title == undefined) {
            return
        }
        this.timeMindTypeNodeUnit = new TimeMindTypeNodeUnit();
        this.nodeConnectLineDataDict = new HashMap();
        this.timeMindTypeNodeUnit.title = data.title;
        this.mindDisplayType = data.mindDisplayType == undefined || data.mindDisplayType == null ? MindDisplayType.MindMap : data.mindDisplayType;
        IdGenerator.shared.setId(data.title.id);
        this.timeMindTypeNodeUnit.baseLine = data.baseLine;
        IdGenerator.shared.setId(data.baseLine.id);
        this.mindBGColor = data.mindBGColor;
        this.settingData = data.settingData.copy()
        this.mindMapStyleColor = new MindMapStyleColor(data.styleIndex);
        this.timeMindTypeNodeUnit.setMindMapStyleColor(data.styleIndex);
        this.mindGlobalAudio = data.mindGlobalAudio;
        this.mindBGStripesType = data.mindBGStripesType;
        data.mainMindElement.forEach(node => {
            this.timeMindTypeNodeUnit.mainMindElementDataDict.put(node.id, node);
            IdGenerator.shared.setId(node.id);
            IdGenerator.shared.setId(node.head.id);
            IdGenerator.shared.setId(node.title.id);
            IdGenerator.shared.setId(node.desc.id);
        });
        this.timeMindTypeNodeUnit.setElementLayout(data.globalLayout);
        this.freeNodes = [];
        data.freeNodes.forEach(unit => {
            let freeNode = this.createFreeNodeUnit();
            freeNode.setRootNode(unit.rootNode);
            freeNode.setFrameworkModle(unit.frameworkIndex);

            unit.rootLine.forEach(line => {
                freeNode.lineMindElementDataDict.put(line.id, line);
                IdGenerator.shared.setId(line.id);
                freeNode.textElementLineMindElementDataDict.put(line.lineContent.targetId, line);
            });
            unit.generalizationLine.forEach(line => {
                freeNode.generalizationLineMindElementDataDict.put(line.id, line);
                IdGenerator.shared.setId(line.id);
            });
            unit.generalizationNode.forEach(node => {
                freeNode.setGeneralizationNode(node);
            });
            unit.encircle.forEach(line => {
                freeNode.encircleMindElementDataDict.put(line.id, line);
                IdGenerator.shared.setId(line.id);
            });
            unit.explainData.forEach(cell => {
                freeNode.explainMindElementDataDict.put(cell.id, cell);
                IdGenerator.shared.setId(cell.id);
            });
            if (unit.globalLayout == NodeLayoutType.LAYOUT_UNKNOWN) {
                freeNode.setElementLayout(data.globalLayout, true);
            } else {
                freeNode.setElementLayout(unit.globalLayout, true);
            }
        });
        data.nodeConnectLinet.forEach(line => {
            this.nodeConnectLineDataDict.put(line.id, line);
            IdGenerator.shared.setId(line.id);
        });
     }
  
     setDoubleBubbleMindDateFormCache(data) {
        this.mindkey = data.mindkey;
        this.nodeConnectLineDataDict = new HashMap();
        this.doubleBubbleMindNodeUnit = new DoubleBubbleMindNodeUnit(MindType.DOUBLE_BUBBLE_MAP, 0);
        if (data.rootNode != null) {
            this.doubleBubbleMindNodeUnit.setRootNode(data.rootNode);
            this.mindBGColor = data.mindBGColor;
            this.settingData = data.settingData.copy()
            this.mindMapStyleColor = new MindMapStyleColor(data.styleIndex);
            this.doubleBubbleMindNodeUnit.setMindMapStyleColor(data.styleIndex);
            this.mindGlobalAudio = data.mindGlobalAudio;
            this.mindBGStripesType = data.mindBGStripesType;
        }
        this.mindDisplayType = data.mindDisplayType == undefined || data.mindDisplayType == null ? MindDisplayType.MindMap : data.mindDisplayType;
        this.globalLineLayout = data.globalLineLayout;
        this.doubleBubbleMindNodeUnit.globalLineLayout = data.globalLineLayout;
        if (data.rootTreeBrotherNode != null) {
            this.doubleBubbleMindNodeUnit.setRootTreeBrotherNode(data.rootTreeBrotherNode);
        }
        this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas = data.commonGroundMindElementDatas;

        data.rootLine.forEach(line => {
            if (line.lineContent != null) {
                let node = this.doubleBubbleMindNodeUnit.getNodeById(line.lineContent.targetId);
                if (!node.isEmpty()) {
                    this.doubleBubbleMindNodeUnit.lineMindElementDataDict.put(line.id, line);
                    IdGenerator.shared.setId(line.id);
                    this.doubleBubbleMindNodeUnit.textElementLineMindElementDataDict.put(line.lineContent.targetId, line);
                }
            }
        });

        data.nodeConnectLinet.forEach(line => {
            this.nodeConnectLineDataDict.put(line.id, line);
            IdGenerator.shared.setId(line.id);
        });
        this.freeNodes = [];
        data.freeNodes.forEach(unit => {
            let freeNode = this.createFreeNodeUnit();
            freeNode.setRootNode(unit.rootNode);
            unit.rootLine.forEach(line => {
                freeNode.lineMindElementDataDict.put(line.id, line);
                IdGenerator.shared.setId(line.id);
                freeNode.textElementLineMindElementDataDict.put(line.lineContent.targetId, line);
            });
            unit.generalizationLine.forEach(line => {
                freeNode.generalizationLineMindElementDataDict.put(line.id, line);
                IdGenerator.shared.setId(line.id);
            });
            unit.generalizationNode.forEach(node => {
                freeNode.setGeneralizationNode(node);
            });
            unit.encircle.forEach(line => {
                freeNode.encircleMindElementDataDict.put(line.id, line);
                IdGenerator.shared.setId(line.id);
            });
            unit.explainData.forEach(cell => {
                freeNode.explainMindElementDataDict.put(cell.id, cell);
                IdGenerator.shared.setId(cell.id);
            });
            if (unit.globalLayout == NodeLayoutType.LAYOUT_UNKNOWN) {
                freeNode.setElementLayout(data.globalLayout, true);
            } else {
                freeNode.setElementLayout(unit.globalLayout, true);
            }
            if (unit.globalLineLayout == LineLayout.UNKNOWN) {
                freeNode.globalLineLayout = data.globalLineLayout;
            } else {
                freeNode.globalLineLayout = unit.globalLineLayout;
            }
        });
        this.setGlobalLayout(data.globalLayout, true);
     }
     
     setGlobalLayout(type, isInit = false) {
        this.globalLayout = type; 
        if (this.mainMindNodeUnit != null) {
            this.mainMindNodeUnit.setElementLayout(this.globalLayout, isInit);
        }
        if (this.timeMindTypeNodeUnit != null) {
            this.timeMindTypeNodeUnit.setElementLayout(this.globalLayout);
        }
        if (this.doubleBubbleMindNodeUnit != null) {
            this.doubleBubbleMindNodeUnit.setElementLayout(NodeLayoutType.LAYOUT_DOUBLE_BUBBLE);
        }
    }

    createFreeNodeUnit() {
        let mindNodeUnit = new LineMindTypeNodeUnit(this.mindBGColor, this.mindType, this.mindMapStyleColor.currentSelectedIndex, true);
        if (this.mindType == MindType.TIME_MAP) {
            mindNodeUnit.setElementLayout(NodeLayoutType.LAYOUT_RIGHT, true);
            mindNodeUnit.globalLineLayout = LineLayout.RIGHT_ANGLE_CORNER_LINE;
        } else {
            if (this.getGlobalLayout() == NodeLayoutType.LAYOUT_FORM || 
                this.getGlobalLayout() == NodeLayoutType.LAYOUT_FORM_HORIZONTAL || 
                this.getGlobalLayout() == NodeLayoutType.LAYOUT_TRIANGLE) {
                mindNodeUnit.setElementLayout(NodeLayoutType.LAYOUT_RIGHT, true);
            } else {
                mindNodeUnit.setElementLayout(this.getFreeGlobalLayout(), true);
            }
            mindNodeUnit.globalLineLayout = this.globalLineLayout;
        }

        mindNodeUnit.setMindBGColor(this.mindBGColor);
        this.freeNodes.push(mindNodeUnit);

        return mindNodeUnit;
    }

    getFreeGlobalLayout() {
        if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            return NodeLayoutType.LAYOUT_BUBBLE;
        } else if (this.globalLayout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT) {
            return NodeLayoutType.LAYOUT_TOP_TREE_RIGHT;
        } else if (this.globalLayout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT) {
            return NodeLayoutType.LAYOUT_TREE_RIGHT;
        } else if (this.globalLayout == NodeLayoutType.LAYOUT_LEFT_RIGHT ||
            this.globalLayout == NodeLayoutType.LAYOUT_RIGHT_LEFT ||
            this.globalLayout == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE ||
            this.globalLayout == NodeLayoutType.LAYOUT_HORIZONTAL ||
            this.globalLayout == NodeLayoutType.LAYOUT_VERTICAL ||
            this.globalLayout == NodeLayoutType.LAYOUT_FISH_RIGHT ||
            this.globalLayout == NodeLayoutType.LAYOUT_FISH_LEFT ||
            this.globalLayout == NodeLayoutType.LAYOUT_FORM ||
            this.globalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL ||
            this.globalLayout == NodeLayoutType.LAYOUT_TRIANGLE) {
            return NodeLayoutType.LAYOUT_RIGHT;
        } else {
            return this.globalLayout;
        }
     }

     getGlobalLayout() {
        if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            return NodeLayoutType.LAYOUT_TREE_LEFT;
        } else {
            return this.globalLayout;
        }
    }
}
export default MindHistoryData
