import Colors from "../../../utils/Colors";
import IconLayoutType from "../../../viewmodel/datatype/IconLayoutType";
import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType";
import BaseInputView from "./BaseInputView";

class SubjectInputView extends BaseInputView {
    constructor(viewCanvs, data, vuethis) {
        super(viewCanvs, data, vuethis);
    }
}

export default SubjectInputView