<template>
  <div class="latex_edit_bg">
    <div class="latex_edit_content" id="latexEditBg"
    :style="{left:(fullWidth-867)+'px'}"
    v-show="showEditLatex">
        <div class="latex_edit_content_head" id="LaTeXDrag">
          <span class="latex_edit_content_head_title">{{getString(this.strings.Mind_LaTeX_Expression)}}</span>
          <span class="latex_edit_content_head_delete" @click.stop="deleteEditLatex">
            <img
            class="close-button-img"
            src="../../../assets/img/popupsImg/colse_new.svg"
            @click.stop="hideLatex()"
            />
          </span>
        </div>
        <div class="latex_edit_content_content">
          <div class="latex_edit_content_weight">
            <div class="latex_edit_content_weight_name">
              <label v-if="latexName2 == '' || latexName2==null" style="float: left;color:#999999">{{getString(this.strings.Mind_LaTeX_Formula_Display_Area)}}</label>
              <!-- <div v-else id="latexId" style="text-align: center;"></div> -->
              <div v-else v-katex="latexName2" id="latexId" style="text-align: center;"></div>
              <!-- <input v-else v-model="latexName2" id="latexId" style="width:801px;height:45px;border:0px;"/> -->
            </div>
            <div :style="{'font-size':latexFontSize+'px','position': 'absolute','opacity':0}" v-katex="latexName2" id="latexHideId">
            </div>
          </div>
          <div class="latex_edit_content_completion">
            <div class="latex_edit_content_completion_left">
              <div class="latex_edit_content_completion_left_top">
                <div class="quick_btn_exhibition" id="quickScroll">
                  <span 
                    :class="selectIndex == index?'quick_title_name_select':'quick_title_name'" 
                    v-for="(temp , index) in latexQuickTitle" 
                    :key="index"
                    @click="clickQuickTile(temp,index)">
                    {{temp.name}}
                  </span>
                </div>
                <div v-show="selectTitleId == 0" class="quick_letter_btn">
                  <div class="function_btn" v-for="(letterTemp , index) in latexQuickLately" :key="index" v-katex="letterTemp.text" @click="clickQuickBtn(letterTemp,index)">
                    <!-- {{letterTemp.name}} -->
                  </div>
                </div>
                <div v-show="selectTitleId == 1" class="quick_letter_btn">
                  <div class="letter_btn" v-for="(letterTemp , index) in latexQuickLetter" :key="index" v-katex="letterTemp.text" @click="clickQuickBtn(letterTemp,index)">
                    <!-- {{letterTemp.name}} -->
                  </div>
                </div>
                <div v-show="selectTitleId == 2" class="quick_letter_btn">
                  <div class="letter_btn" v-for="(letterTemp , index) in latexQuickMath" :key="index" v-katex="letterTemp.text" @click="clickQuickBtn(letterTemp,index)">
                    <!-- {{letterTemp.name}} -->
                  </div>
                </div>
                <div v-show="selectTitleId == 3" class="quick_letter_btn">
                  <div class="letter_btn" v-for="(letterTemp , index) in latexQuickStyle" :key="index" v-katex="letterTemp.text" @click="clickQuickStyleBtn(letterTemp,index)">
                    <!-- {{letterTemp.name}} -->
                  </div>
                </div>
                <div v-show="selectTitleId == 4" class="quick_letter_btn">
                  <div class="letter_btn" v-for="(letterTemp , index) in latexQuickDivision" :key="index" v-katex="letterTemp.text" @click="clickQuickBtn(letterTemp,index)">
                    <!-- {{letterTemp.name}} -->
                  </div>
                </div>
                <div v-show="selectTitleId == 5" class="quick_letter_btn">
                  <div class="letter_btn" v-for="(letterTemp , index) in latexQuickVariable" :key="index" v-katex="letterTemp.text" @click="clickQuickBtn(letterTemp,index)">
                    <!-- {{letterTemp.name}} -->
                  </div>
                </div>
                <div v-show="selectTitleId == 6" class="quick_letter_btn">
                  <div class="function_btn" v-for="(letterTemp , index) in latexQuickFunction" :key="index" v-katex="letterTemp.text" @click="clickQuickBtn(letterTemp,index)">
                    <!-- {{letterTemp.name}} -->
                  </div>
                </div>
                <div v-show="selectTitleId == 7" class="quick_letter_btn">
                  <div class="letter_btn" v-for="(letterTemp , index) in latexQuickRelationship" :key="index" v-katex="letterTemp.text" @click="clickQuickBtn(letterTemp,index)">
                    <!-- {{letterTemp.name}} -->
                  </div>
                </div>
                <div v-show="selectTitleId == 8" class="quick_letter_btn">
                  <div class="letter_btn" v-for="(letterTemp , index) in latexQuickArrow" :key="index" v-katex="letterTemp.text" @click="clickQuickBtn(letterTemp,index)">
                    <!-- {{letterTemp.name}} -->
                  </div>
                </div>
                <div v-show="selectTitleId == 9" class="quick_letter_btn">
                  <div class="letter_btn" v-for="(letterTemp , index) in latexQuickMiscellaneous" :key="index" v-katex="letterTemp.text" @click="clickQuickBtn(letterTemp,index)">
                    <!-- {{letterTemp.name}} -->
                  </div>
                </div>
                <div v-show="selectTitleId == 10" class="quick_letter_btn">
                  <div class="letter_btn" v-for="(letterTemp , index) in latexQuickStress" :key="index" v-katex="letterTemp.text" @click="clickQuickBtn(letterTemp,index)">
                    <!-- {{letterTemp.name}} -->
                  </div>
                </div>
              </div>
              <div class="latex_edit_content_completion_left_bottom">
                <a-textarea class="latex_edit_content_completion_left_bottom_textarea" id="inputLatex" @focus="focusInput()" @blur="blurInput()" v-model="latexName1" :placeholder="'请输入表达式'" @change="latexChange"></a-textarea>
              </div>
              <div class="update_me_latex" @click="addMeTemp">
                <img src="../../../assets/img/popupsImg/add_template.svg"/>
                <span style="margin-left: 5px;">{{getString(this.strings.Mind_LaTeX_Add_Template)}}</span>
              </div>
              <div class="latex_revoke" :class="latexHistoryList.length>0?'latex_revoke_yes':'latex_revoke_no'" @click="latexRevokeClick">
                <img src="../../../assets/img/popupsImg/revoke_latex.svg" alt="撤销"/>
                <div class="latex_revoke_title"><span>{{getString(this.strings.Mind_Edit_Revoke)}}</span></div>
              </div>
              <div class="latex_roll_back" :class="cursor>0?'latex_roll_back_yes':'latex_roll_back_no'" @click="latexRollBackClick">
                <img src="../../../assets/img/popupsImg/roll_back.svg" alt="恢复"/>
                
                <div class="latex_roll_back_title"><span>{{getString(this.strings.Mind_Edit_Restore)}}</span></div>
              </div>
            </div>
            <div class="latex_edit_content_completion_rigth">
              <div class="latex_edit_content_completion_rigth_select">
                {{getString(this.strings.Mind_LaTeX_Common_Template)}}
                <a-popover title="LaTeX使用帮助" trigger="hover" placement="left">
                  <template slot="content">
                    <p>1.{{getString(this.strings.Mind_LaTeX_Used_Help_1)}}</p>
                    <p> &nbsp;&nbsp;&nbsp;&nbsp;①{{getString(this.strings.Mind_LaTeX_Used_Help_1_1)}}</p>
                    <p> &nbsp;&nbsp;&nbsp;&nbsp;②{{getString(this.strings.Mind_LaTeX_Used_Help_1_2)}}</p>
                    <p> &nbsp;&nbsp;&nbsp;&nbsp;③{{getString(this.strings.Mind_LaTeX_Used_Help_1_3)}}</p>
                    <p> &nbsp;&nbsp;&nbsp;&nbsp;④{{getString(this.strings.Mind_LaTeX_Used_Help_1_4)}}
                    </p>
                    <p>2.{{getString(this.strings.Mind_LaTeX_Used_Help_2)}}</p>
                    <p>3.{{getString(this.strings.Mind_LaTeX_Used_Help_3)}}</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;{{getString(this.strings.Mind_LaTeX_Used_Help_3_1)}}</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;{{getString(this.strings.Mind_LaTeX_Used_Help_3_2)}}</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;{{getString(this.strings.Mind_LaTeX_Used_Help_3_3)}}</p>
                  </template>
                  <img src="../../../assets/img/popupsImg/used_help.svg"/>
                </a-popover>
              </div>
              <div class="latex_edit_content_completion_rigth_temps">
                <div v-for="(tmp , index) in meLatexOrSystemTemp" :key="index" 
                class="temp_box" 
                @click.stop="clickTempBtn(tmp,index)"
                @mouseover="mouseOverColl(index)"
                @mouseleave="mouseLeaveColl(index)">
                  <div style="width: 155px;height: 46px;overflow: hidden;">
                    <div v-katex="{ expression: tmp.value, options: { throwOnError: false }}"></div>
                  </div>
                  <img v-show="showMouseDelete && mouseIndex == index && tmp.id == null" 
                  @click.stop="deleteMeTemp(tmp)"
                  class="temp_box_delate" 
                  src="../../../assets/img/popupsImg/delete_temp.svg"/>
                </div>
              </div>
            </div>
          </div>
          <div class="latex_edit_content_btn">
            <div class="latex_edit_content_btn1" @click.stop="hideLatex()">{{getString(this.strings.Mind_colse)}}</div>
            <div class="latex_edit_content_btn2" @click.stop="setLatex(true)">{{getString(this.strings.Global_Ok)}}</div>
            <!-- <div v-katex="'\\frac{a_i}{1+x}'"></div> -->
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import getString from '../../../common/lang/language';
import strings from '../../../common/lang/strings';
import Template from '../../../views/template/Template.vue';
import ComplieChildMenuForm from "../ComplieRightMenuChild/ComplieChildMenuForm";
import ComplieChildMenuNodeQuickStyle from "../ComplieRightMenuChild/ComplieChildMenuNodeQuickStyle";
import { postMindmapUploadMyLatex } from "../../../common/netWork/mind_map_api";
import { postMindmapMyLatex } from "../../../common/netWork/networkcache";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
import dataTimeToFormats from '../../../utils/timestampToTime';
import Util from '../../../utils/Util';
import Config from '../../../core/core/calcule/Config';
import { postBaseGlobalConfig } from "../../../common/netWork/networkcache";
import { getJwt } from '../../../common/netWork/base';
import MindMe from '../../../viewmodel/facade/MindMe';

export default {
  props: [
    "showEditLatex",
    "fullHeight",
    "fullWidth",
  ],
  data() {
    return {
      latexFontSize:Config.latexFontSize,
      generalConfig:null,
      strings:strings,
      latexName1:'',
      latexName2:'',
      dataNodeLatex:null,
      nodeName:'',
      node:null,
      selectIndex:1,
      selectTitleId:1,
      isLatex:true,
      showMouseDelete:false,
      mouseIndex:1,
      meLatexTemp:[],
      meLatexOrSystemTemp:[],
      latexTemps:[
        {
          value:'e^{ i \\pi}+1=0',
          id:1
        },
        {
          value:'F = G{{Mm} \\over {{r^2}}}',
          id:2
        },
        {
          value:'\\vec{F}=m\\vec{a}',
          id:3
        },
        {
          value:'x = \\frac{-b \\pm \\sqrt{b^2-4ac}}{2a}',
          id:4
        },
        {
          value:'{e^{ix}} = \\cos {x} + i\\sin {x}',
          id:5
        },
        {
          value:'(1+x)^{n} = 1+\\frac{n x}{ 1! } + \\frac {n(n-1)x^{2}}{2!} + \\cdots',
          id:6
        },

        {
          value:'S_{n}=\\frac{n \\left( a_{1}+a_{n}\\right)}{2}',
          id:7
        },
        {
          value:'\\frac{\\sin A}{a}=\\frac{\\sin B}{b}=\\frac{\\sin C}{c}=\\frac{1}{2R}',
          id:8
        },
        {
          value:'\\left\\{\\begin{matrix} x=a + r\\text{cos}\\theta \\ y=b + r\\text{sin}\\theta \\end{matrix}\\right.',
          id:9
        },
        {
          value:'frac{\\sin A}{a}=\\frac{\\sin B}{b}=\\frac{\\sin C}{c}=\\frac{1}{2R}',
          id:10
        },
        {
          value:'f(x) = {{{a_0}} \\over 2} + \\sum\\limits_{n = 1}^\\infty  {({a_n}\\cos {nx} + {b_n}\\sin {nx})}',
          id:11
        },
        {
          value:'(1+x)^{n} = 1+\\frac{n x}{ 1! } + \\frac {n(n-1)x^{2}}{2!} + \\cdots',
          id:12
        },
        {
          value:'\\sum_{n=1}^{\\infty}\\frac{1+n}{1-n}=\\bigcup_{A\\in\\Im}C\\cup B',
          id:13
        },

        {
          value:'O = \\begin{bmatrix} 0 & 0 & \\cdots & 0 \\\\ 0 & 0 & \\cdots & 0 \\\\\\vdots & \\vdots & \\ddots & \\vdots \\ 0 & 0 & \\cdots & 0 \\end{bmatrix}',
          id:14
        },
        {
          value:'\\vec \\bf V_1 \\times \\vec \\bf V_2 =  \\begin{vmatrix} \\hat \\imath &\\hat \\jmath &\\hat k \\\\ \\frac{\\partial X}{\\partial u} &  \\frac{\\partial Y}{\\partial u} & 0 \\\\ \\frac{\\partial X}{\\partial v} &  \\frac{\\partial Y}{\\partial v} & 0 \\end{vmatrix}',
          id:15
        },
        {
          value:'2H_2 + O_2 \\to 2H_2O',
          id:16
        },
        {
          value:'2Al + 3{H_2}S{O_4} = A{l_2}{(S{O_4})_3} + 3{H_2} \\uparrow',
          id:17
        },
        {
          value:'Fe + {H_2}S{O_4} = FeS{O_4} + {H_2} \\uparrow',
          id:18
        },
      ],
      latexQuickLately:[],
      latexQuickTitle:[
        {
          name:getString(strings.Mind_Edit_Recently_Used),
          id:0,
        },
        {
          name:getString(strings.Mind_LaTeX_Quick_Title_2),
          id:1,
        },
        {
          name:getString(strings.Mind_LaTeX_Quick_Title_3),
          id:2,
        },
        {
          name:getString(strings.Mind_LaTeX_Quick_Title_4),
          id:3,
        },
        {
          name:getString(strings.Mind_LaTeX_Quick_Title_5),
          id:4,
        },
        {
          name:getString(strings.Mind_LaTeX_Quick_Title_6),
          id:5,
        },
        {
          name:getString(strings.Mind_LaTeX_Quick_Title_7),
          id:6,
        },
        {
          name:getString(strings.Mind_LaTeX_Quick_Title_8),
          id:7,
        },
        {
          name:getString(strings.Mind_LaTeX_Quick_Title_9),
          id:8,
        },
        {
          name:getString(strings.Mind_LaTeX_Quick_Title_10),
          id:9,
        },
        {
          name:getString(strings.Mind_LaTeX_Quick_Title_11),
          id:10,
        },
      ],
      latexQuickLetter:[
        {
          name:"$\\alpha$",
          text:"\\alpha"
        },
        {
          name:"$\\kappa$",
          text:"\\kappa"
        },
        {
          name:"$\\psi$",
          text:"\\psi"
        },
        {
          name:"Δ",
          text:"\\Delta"
        },
        {
          name:"$\\Theta$",
          text:"\\Theta"
        },
        {
          name:"$\\beta$",
          text:"\\beta"
        },
        {
          name:"$\\lambda$",
          text:"\\lambda"
        },
        {
          name:"$\\rho$",
          text:"\\rho"
        },
        {
          name:"$\\varepsilon$",
          text:"\\varepsilon"
        },
        {
          name:"$\\Gamma$",
          text:"\\Gamma"
        },
        {
          name:"$\\Upsilon$",
          text:"\\Upsilon"
        },
        {
          name:"$\\chi$",
          text:"\\chi"
        },
        {
          name:"$\\mu$",
          text:"\\mu"
        },
        {
          name:"$\\sigma$",
          text:"\\sigma"
        },
        {
          name:"$\\varkappa$",
          text:"\\varkappa"
        },
        {
          name:"$\\Lambda$",
          text:"\\Lambda"
        },
        {
          name:"$\\Xi$",
          text:"\\Xi"
        },
        {
          name:"$\\delta$",
          text:"\\delta"
        },
        {
          name:"$\\nu$",
          text:"\\nu"
        },
        {
          name:"$\\tau$",
          text:"\\tau"
        },
        {
          name:"$\\varphi$",
          text:"\\varphi"
        },
        {
          name:"$\\Omega$",
          text:"\\Omega"
        },
        {
          name:"$\\epsilon$",
          text:"\\epsilon"
        },
        {
          name:"$o$",
          text:"o"
        },
        {
          name:"$\\theta$",
          text:"\\theta"
        },
        {
          name:"$\\varpi$",
          text:"\\varpi"
        },
        {
          name:"$\\Phi$",
          text:"\\Phi"
        },
        {
          name:"$\\aleph$",
          text:"\\aleph"
        },
        {
          name:"$\\eta$",
          text:"\\eta"
        },
        {
          name:"$\\omega$",
          text:"\\omega"
        },
        {
          name:"$\\upsilon$",
          text:"\\upsilon"
        },
        {
          name:"$\\varrho$",
          text:"\\varrho"
        },
        {
          name:"$\\Pi$",
          text:"\\Pi"
        },
        {
          name:"$\\gamma$",
          text:"\\gamma"
        },
        {
          name:"$\\phi$",
          text:"\\phi"
        },
        {
          name:"$\\xi$",
          text:"\\xi"
        },
        {
          name:"$\\varsigma$",
          text:"\\varsigma"
        },
        {
          name:"$\\Psi$",
          text:"\\Psi"
        },
        {
          name:"$\\iota$",
          text:"\\iota"
        },
        {
          name:"$\\pi$",
          text:"\\pi"
        },
        {
          name:"$\\zeta$",
          text:"\\zeta"
        },
        {
          name:"$\\vartheta$",
          text:"\\vartheta"
        },
        {
          name:"$\\Sigma$",
          text:"\\Sigma"
        },
      ],
      latexQuickMath:[
        {
          name:"()",
          text:"()",
        },
        {
          name:"[]",
          text:"[]",
        },
        {
          name:"\\{\\}",
          text:"\\{\\}",
        },
        {
          name:"+",
          text:"+",
        },
        {
          name:"-",
          text:"-",
        },
        {
          name:"\\times",
          text:"\\times",
        },
        {
          name:"\\div",
          text:"\\div",
        },
        {
          name:"=",
          text:"=",
        },
        {
          name:"\\neq",
          text:"\\neq",
        },
        {
          name:">",
          text:">",
        },
        {
          name:"<",
          text:"<",
        },
        {
          name:"\\leq",
          text:"\\leq",
        },
        {
          name:"\\geq",
          text:"\\geq",
        },

        {
          name:"\\sim",
          text:"\\sim",
        },
        {
          name:"\\approx",
          text:"\\approx",
        },
        {
          name:"\\pm",
          text:"\\pm",
        },
        {
          name:"\\mp",
          text:"\\mp",
        },
        {
          name:"\\cong",
          text:"\\cong",
        },
        {
          name:"\\equiv",
          text:"\\equiv",
        },
        {
          name:"\\cdot",
          text:"\\cdot",
        },
        {
          name:"\\star",
          text:"\\star",
        },
        {
          name:"\\log_{a}{b}",
          text:"\\log_{a}{b}",
        },
        {
          name:"\\lg{a}",
          text:"\\lg{a}",
        },
        {
          name:"a^{b}",
          text:"a^{b}",
        },
        {
          name:"a_{b}",
          text:"a_{b}",
        },
        {
          name:"c_a^b",
          text:"c_a^b"
        },
        {
          name:"_{a}^{b}\\textrm{c}",
          text:"_{a}^{b}\\textrm{c}"
        },
        {
          name:"\\frac{a}{x}",
          text:"\\frac{a}{x}"
        },
        {
          name:"$\\overline{a}$",
          text:"\\overline{a}"
        },
        {
          name:"$\\underline{a}$",
          text:"\\underline{a}"
        },
        {
          name:"$\\sqrt{a}$",
          text:"\\sqrt{a}"
        },
        {
          name:"$\\widehat{a}$",
          text:"\\widehat{a}"
        },
        {
          name:"$\\sqrt[n]{a}$",
          text:"\\sqrt[n]{a}"
        },
        {
          name:"$\\widetilde{a}$",
          text:"\\widetilde{a}"
        },

        {
          name:"\\propto",
          text:"\\propto",
        },
        {
          name:"\\ll",
          text:"\\ll"
        },
        {
          name:"\\gg",
          text:"\\gg"
        },
        {
          name:"\\in",
          text:"\\in"
        },
        {
          name:"\\subset",
          text:"\\subset"
        },
        {
          name:"\\subseteq",
          text:"\\subseteq"
        },
        {
          name:"\\prec",
          text:"\\prec"
        },
        {
          name:"\\simeq",
          text:"\\simeq"
        },
        {
          name:"\\supset",
          text:"\\supset"
        },
        {
          name:"\\asymp",
          text:"\\asymp"
        },
        {
          name:"\\doteq",
          text:"\\doteq"
        },
        {
          name:"\\succ",
          text:"\\succ"
        },
        {
          name:"\\sqsubseteq",
          text:"\\sqsubseteq"
        },
        {
          name:"\\sqsupseteq",
          text:"\\sqsupseteq"
        },
        {
          name:"\\ni",
          text:"\\ni"
        },
        {
          name:"\\models",
          text:"\\models"
        },
        {
          name:"\\perp",
          text:"\\perp"
        },
        {
          name:"\\mid",
          text:"\\mid"
        },
        {
          name:"\\parallel",
          text:"\\parallel"
        },
        {
          name:"\\acute{a}",
          text:"\\acute{a}"
        },
        {
          name:"\\breve{a}",
          text:"\\breve{a}"
        },
        {
          name:"\\check{a}",
          text:"\\check{a}"
        },
        {
          name:"\\ddot{a}",
          text:"\\ddot{a}"
        },
        {
          name:"\\dot{a}",
          text:"\\dot{a}"
        },
        {
          name:"\\grave{a}",
          text:"\\grave{a}"
        },
        {
          name:"\\hat{a}",
          text:"\\hat{a}"
        },
         {
          name:"\\tilde{a}",
          text:"\\tilde{a}"
        },
        {
          name:"\\vec{a}",
          text:"\\vec{a}"
        },
      ],
      latexQuickDivision:[
        {
          name:"$|$",
          text:"|"
        },
        {
          name:"$\\lfloor$",
          text:"\\lfloor"
        },
        {
          name:"$/$",
          text:"/"
        },
        {
          name:"$\\Uparrow$",
          text:"\\Uparrow"
        },
        {
          name:"$\\vert$",
          text:"\\vert"
        },
        {
          name:"$\\}$",
          text:"\\}"
        },
        {
          name:"$\\rfloor$",
          text:"\\rfloor"
        },
         {
          name:"$\\backslash$",
          text:"\\backslash"
        },
        {
          name:"$\\uparrow$",
          text:"\\uparrow"
        },
        {
          name:"$\\|$",
          text:"\\|"
        },
        {
          name:"$\\langle$",
          text:"\\langle"
        },
        {
          name:"$\\lceil$",
          text:"\\lceil"
        },
        {
          name:"$\\Downarrow$",
          text:"\\Downarrow"
        },

        {
          name:"$\\Vert$",
          text:"\\Vert"
        },
        {
          name:"$\\rangle$",
          text:"\\rangle"
        },
        {
          name:"$\\rceil$",
          text:"\\rceil"
        },
        {
          name:"$\\downarrow$",
          text:"\\downarrow"
        },
      ],
      latexQuickVariable:[
        {
          name:"$\\sum$",
          text:"\\sum"
        },
        {
          name:"$\\int$",
          text:"\\int"
        },
        {
          name:"$\\biguplus$",
          text:"\\biguplus"
        },
        {
          name:"$\\bigoplus$",
          text:"\\bigoplus"
        },
        {
          name:"$\\bigvee$",
          text:"\\bigvee"
        },
        {
          name:"$\\prod$",
          text:"\\prod"
        },
        {
          name:"$\\oint$",
          text:"\\oint"
        },
         {
          name:"$\\bigcap$",
          text:"\\bigcap"
        },
        {
          name:"$\\bigotimes$",
          text:"\\bigotimes"
        },
        {
          name:"$\\bigwedge$",
          text:"\\bigwedge"
        },
        {
          name:"$\\coprod$",
          text:"\\coprod"
        },
        {
          name:"$\\bigcup$",
          text:"\\bigcup"
        },
        {
          name:"$\\bigodot$",
          text:"\\bigodot"
        },
        {
          name:"$\\bigsqcup$",
          text:"\\bigsqcup"
        },
      ],
      latexQuickFunction:[
        {
          name:"$\\arccos$",
          text:"\\arccos"
        },
        {
          name:"$\\arcsin$",
          text:"\\arcsin"
        },
        {
          name:"$\\arctan$",
          text:"\\arctan"
        },
        {
          name:"$\\arg$",
          text:"\\arg"
        },
        {
          name:"$\\cos$",
          text:"\\cos"
        },
        {
          name:"$\\cosh$",
          text:"\\cosh"
        },
        {
          name:"$\\cot$",
          text:"\\cot"
        },
         {
          name:"$\\coth$",
          text:"\\coth"
        },
        {
          name:"$\\csc$",
          text:"\\csc"
        },
        {
          name:"$\\deg$",
          text:"\\deg"
        },
        {
          name:"$\\det$",
          text:"\\det"
        },
        {
          name:"$\\dim$",
          text:"\\dim"
        },
        {
          name:"$\\exp$",
          text:"\\exp"
        },
        {
          name:"$\\gcd$",
          text:"\\gcd"
        },
        {
          name:"$\\hom$",
          text:"\\hom"
        },
        {
          name:"$\\inf$",
          text:"\\inf"
        },
        {
          name:"$\\ker$",
          text:"\\ker"
        },


        {
          name:"$\\lg$",
          text:"\\lg"
        },
        {
          name:"$\\lim$",
          text:"\\lim"
        },
         {
          name:"$\\liminf$",
          text:"\\liminf"
        },
        {
          name:"$\\limsup$",
          text:"\\limsup"
        },
        {
          name:"$\\limsup$",
          text:"\\limsup"
        },
        {
          name:"$\\ln$",
          text:"\\ln"
        },
        {
          name:"$\\dim$",
          text:"\\dim"
        },
        {
          name:"$\\log$",
          text:"\\log"
        },
        {
          name:"$\\max$",
          text:"\\max"
        },
        {
          name:"$\\min$",
          text:"\\min"
        },
        {
          name:"$\\Pr$",
          text:"\\Pr"
        },
        {
          name:"$\\sec$",
          text:"\\sec"
        },
        {
          name:"$\\sin$",
          text:"\\sin"
        },
        {
          name:"$\\sinh$",
          text:"\\sinh"
        },
        {
          name:"$\\sup$",
          text:"\\sup"
        },
        {
          name:"$\\tan$",
          text:"\\tan"
        },
        {
          name:"$\\tanh$",
          text:"\\tanh"
        },
      ],
      latexQuickRelationship:[
        {
          name:"$\\ast$",
          text:"\\ast"
        },
        {
          name:"$\\pm$",
          text:"\\pm"
        },
        {
          name:"$\\cap$",
          text:"\\cap"
        },
        {
          name:"$\\star$",
          text:"\\star"
        },
        {
          name:"$\\mp$",
          text:"\\mp"
        },
        {
          name:"$\\cup$",
          text:"\\cup"
        },
        {
          name:"$\\amalg$",
          text:"\\amalg"
        },
         {
          name:"$\\uplus$",
          text:"\\uplus"
        },
        {
          name:"$\\circ$",
          text:"\\circ"
        },
        {
          name:"$\\odot$",
          text:"\\odot"
        },
        {
          name:"$\\sqcap$",
          text:"\\sqcap"
        },
        {
          name:"$\\bullet$",
          text:"\\bullet"
        },
        {
          name:"$\\ominus$",
          text:"\\ominus"
        },
        {
          name:"$\\sqcup$",
          text:"\\sqcup"
        },
        {
          name:"$\\oplus$",
          text:"\\oplus"
        },
        {
          name:"$\\wedge$",
          text:"\\wedge"
        },
        {
          name:"$\\oslash$",
          text:"\\oslash"
        },
        {
          name:"$\\vee$",
          text:"\\vee"
        },
        {
          name:"$\\times$",
          text:"\\times"
        },
         {
          name:"$\\otimes$",
          text:"\\otimes"
        },
        {
          name:"$\\dagger$",
          text:"\\dagger"
        },
        {
          name:"$\\div$",
          text:"\\div"
        },
        {
          name:"$\\wr$",
          text:"\\wr"
        },
        {
          name:"$\\ddagger$",
          text:"\\ddagger"
        },
        {
          name:"$\\setminus$",
          text:"\\setminus"
        },
        {
          name:"$\\bot$",
          text:"\\bot"
        },
        {
          name:"$\\top$",
          text:"\\top"
        },
        {
          name:"$\\square$",
          text:"\\square"
        },
        {
          name:"$\\equiv$",
          text:"\\equiv"
        },
        {
          name:"$\\leq$",
          text:"\\leq"
        },
        {
          name:"$\\geq$",
          text:"\\geq"
        },
        {
          name:"$\\perp$",
          text:"\\perp"
        },
        {
          name:"$\\cong$",
          text:"\\cong"
        },
        {
          name:"$\\prec$",
          text:"\\prec"
        },
        {
          name:"$\\succ$",
          text:"\\succ"
        },
        {
          name:"$\\mid$",
          text:"\\mid"
        },
        {
          name:"$\\neq$",
          text:"\\neq"
        },
        {
          name:"$\\parallel$",
          text:"\\parallel"
        },
        {
          name:"$\\sim$",
          text:"\\sim"
        },
        {
          name:"$\\ll$",
          text:"\\ll"
        },
        {
          name:"$\\gg$",
          text:"\\gg"
        },
        {
          name:"$\\simeq$",
          text:"\\simeq"
        },
        {
          name:"$\\subset$",
          text:"\\subset"
        },
        {
          name:"$\\supset$",
          text:"\\supset"
        },
        {
          name:"$\\approx$",
          text:"\\approx"
        },
        {
          name:"$\\subseteq$",
          text:"\\subseteq"
        },
        {
          name:"$\\supseteq$",
          text:"\\supseteq"
        },
        {
          name:"$\\asymp$",
          text:"\\asymp"
        },
        {
          name:"$\\sqsubset$",
          text:"\\sqsubset"
        },
        {
          name:"$\\sqsupset$",
          text:"\\sqsupset"
        },
        {
          name:"$\\doteq$",
          text:"\\doteq"
        },
        {
          name:"$\\sqsubseteq$",
          text:"\\sqsubseteq"
        },
        {
          name:"$\\sqsupseteq$",
          text:"\\sqsupseteq"
        },
        {
          name:"$\\propto$",
          text:"\\propto"
        },
        {
          name:"$\\models$",
          text:"\\models"
        },
        {
          name:"$\\in$",
          text:"\\in"
        },
        {
          name:"$\\ni$",
          text:"\\ni"
        },
        {
          name:"$\\notin$",
          text:"\\notin"
        },
        {
          name:"$\\sqsubset$",
          text:"\\sqsubset"
        },
        {
          name:"$\\sqsupset$",
          text:"\\sqsupset"
        },
        {
          name:"$\\neq$",
          text:"\\neq"
        },
      ],
      latexQuickArrow:[
        {
          name:"$\\Leftarrow$",
          text:"\\Leftarrow"
        },
        {
          name:"$\\Longleftarrow$",
          text:"\\Longleftarrow"
        },
        {
          name:"$\\Uparrow$",
          text:"\\Uparrow"
        },
        {
          name:"$\\rightarrow$",
          text:"\\rightarrow"
        },
        {
          name:"$\\longrightarrow$",
          text:"\\longrightarrow"
        },
        {
          name:"$\\downarrow$",
          text:"\\downarrow"
        },
        {
          name:"$\\Rightarrow$",
          text:"\\Rightarrow"
        },
         {
          name:"$\\Longrightarrow$",
          text:"\\Longrightarrow"
        },
        {
          name:"$\\Downarrow$",
          text:"\\Downarrow"
        },
        {
          name:"$\\leftrightarrow$",
          text:"\\leftrightarrow"
        },
        {
          name:"$\\longleftrightarrow$",
          text:"\\longleftrightarrow"
        },
        {
          name:"$\\updownarrow$",
          text:"\\updownarrow"
        },
        {
          name:"$\\Leftrightarrow$",
          text:"\\Leftrightarrow"
        },
        {
          name:"$\\Longleftrightarrow$",
          text:"\\Longleftrightarrow"
        },
        {
          name:"$\\Updownarrow$",
          text:"\\Updownarrow"
        },
        {
          name:"$\\mapsto$",
          text:"\\mapsto"
        },
        {
          name:"$\\nearrow$",
          text:"\\nearrow"
        },
        {
          name:"$\\searrow$",
          text:"\\searrow"
        },
        {
          name:"$\\swarrow$",
          text:"\\swarrow"
        },
         {
          name:"$\\nwarrow$",
          text:"\\nwarrow"
        },
      ],
      latexQuickMiscellaneous:[
        {
          name:"$\\infty$",
          text:"\\infty"
        },
        {
          name:"$\\forall$",
          text:"\\forall"
        },
        {
          name:"$\\wp$",
          text:"\\wp"
        },
        {
          name:"$\\nabla$",
          text:"\\nabla"
        },
        {
          name:"$\\exists$",
          text:"\\exists"
        },
        {
          name:"$\\angle$",
          text:"\\angle"
        },
        {
          name:"$\\partial$",
          text:"\\partial"
        },
         {
          name:"$\\emptyset$",
          text:"\\emptyset"
        },
        {
          name:"$\\imath$",
          text:"\\imath"
        },
        {
          name:"$\\jmath$",
          text:"\\jmath"
        },
        {
          name:"$\\triangle$",
          text:"\\triangle"
        },
        {
          name:"$\\ell$",
          text:"\\ell"
        },
        {
          name:"$\\hbar$",
          text:"\\hbar"
        },
        {
          name:"$\\vdots$",
          text:"\\vdots"
        },
        {
          name:"$\\mho$",
          text:"\\mho"
        },
        {
          name:"$\\ddots$",
          text:"\\ddots"
        },
        {
          name:"$\\prime$",
          text:"\\prime"
        },
        {
          name:"$\\Im$",
          text:"\\Im"
        },
        {
          name:"$\\square$",
          text:"\\square"
        },
         {
          name:"$\\Re$",
          text:"\\Re"
        },
      ],
      latexQuickStress:[
        {
          name:"$\\acute{a}$",
          text:"\\acute{a}"
        },
        {
          name:"$\\breve{a}$",
          text:"\\breve{a}"
        },
        {
          name:"$\\check{a}$",
          text:"\\check{a}"
        },
        {
          name:"$\\ddot{a}$",
          text:"\\ddot{a}"
        },
        {
          name:"$\\dot{a}$",
          text:"\\dot{a}"
        },
        {
          name:"$\\grave{a}$",
          text:"\\grave{a}"
        },
        {
          name:"$\\hat{a}$",
          text:"\\hat{a}"
        },
         {
          name:"$\\tilde{a}$",
          text:"\\tilde{a}"
        },
        {
          name:"$\\vec{a}$",
          text:"\\vec{a}"
        },
      ],
      latexQuickStyle:[
        {
          name:"\\mathcal{A}",
          text:"\\mathcal{A}"
        },
        {
          name:"\\mathbb{A}",
          text:"\\mathbb{A}"
        },
        {
          name:"\\mathfrak{A}",
          text:"\\mathfrak{A}"
        },
        {
          name:"\\mathsf{A}",
          text:"\\mathsf{A}"
        },
        {
          name:"\\mathbf{A}",
          text:"\\mathbf{A}"
        },
        {
          name:"{\\color{#FF0000}A}",
          text:"{\\color{#FF0000}A}"
        },
        {
          name:"{\\color{#FF7D00}A}",
          text:"{\\color{#FF7D00}A}"
        },
        {
          name:"{\\color{#FFFF00}A}",
          text:"{\\color{#FFFF00}A}"
        },
        {
          name:"{\\color{#00FF00}A}",
          text:"{\\color{#00FF00}A}"
        },
        {
          name:"{\\color{#00FFFF}A}",
          text:"{\\color{#00FFFF}A}"
        },
        {
          name:"{\\color{#0000FF}A}",
          text:"{\\color{#0000FF}A}"
        },
        {
          name:"{\\color{#FF00FF}A}",
          text:"{\\color{#FF00FF}A}"
        },
      ],
      latexHistoryList:[],
      isSystem:false,
      maxHistory:100,
      cursor:0,

    };
  },
  created(){
    // this.insertPicturesIsNeedMembers()
  },
  mounted(){
    this.baseGlobalConfig();
    this.dragDown1();
    
    const latexQuickLately = JSON.parse(localStorage.getItem("latexQuickLately"));
    if(latexQuickLately != "" && latexQuickLately != null && latexQuickLately.length > 0){
      this.latexQuickLately = latexQuickLately;
    }

    this.getMeLatexTemp();
    this.getInitLatexName();

    setTimeout(() => {
      // this.initMathJax();
      // MathJax.Hub.Queue(["Typeset",MathJax.Hub]);
      // 获取要绑定事件的元素
      const scrollDiv = document.getElementById("quickScroll");
      // document.addEventListener('DOMMouseScroll', handler, false)
      // 添加滚轮滚动监听事件，一般是用下面的方法，上面的是火狐的写法
      scrollDiv.addEventListener("mousewheel", handler, false);
      let that = this;
      // 滚动事件的出来函数
      function handler(event) {
        // 获取滚动方向
        const detail = event.wheelDelta || event.detail;
        // 定义滚动方向，其实也可以在赋值的时候写
        const moveForwardStep = 1;
        const moveBackStep = -1;
        // 定义滚动距离
        let step = 0;
        // 判断滚动方向,这里的100可以改，代表滚动幅度，也就是说滚动幅度是自定义的
        if (detail < 0) {
          step = moveForwardStep * 60;
        } else {
          step = moveBackStep * 60;
        }
        // 对需要滚动的元素进行滚动操作
        scrollDiv.scrollLeft += step;

        if (
          scrollDiv.scrollWidth - scrollDiv.scrollLeft ==
            scrollDiv.clientWidth &&
          that.isReightScrollButton
        ) {
          let sheetsListConunt = that.initMindSheetDatas.length - 1;
          that.getSheetPaginationList(
            that.initMindSheetDatas[sheetsListConunt].index
          );
        }
        if (
          scrollDiv.scrollWidth - scrollDiv.scrollLeft - scrollDiv.clientWidth >
          0
        ) {
          that.isReightScrollButton = true;
        } else {
          that.isReightScrollButton = false;
        }
        if (scrollDiv.scrollLeft > 0) {
          that.isLeftScrollButton = true;
        } else {
          that.isLeftScrollButton = false;
        }
      }
    }, 300);
  },
  components: {
    ComplieChildMenuForm,
    ComplieChildMenuNodeQuickStyle
  },
  methods: {
    ...mapMutations(["setIsShowEditLatex","useShortcut","isShowShoppingModel","showLoginModel"]),
    getString(i){
      return getString(i)
    },

    hideLatex(){
      this.latexHistoryList = [];
      this.cursor = 0;
      let obj = {};
      obj.show = false;
      this.$emit("hideLatex",obj);
    },

    mouseOverColl(index){
      this.showMouseDelete = true;
      this.mouseIndex = index;
    },
    mouseLeaveColl(){
      this.showMouseDelete = false;
      this.mouseIndex = -1;
    },

    onMathJaxReady() {
      const el = document.getElementById('latexId');
      renderByMathjax(el);
    },

    // 通用配置接口
    baseGlobalConfig() {
      postBaseGlobalConfig(
        {},
        (res) => {
          this.generalConfig = res;
          
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
    },
    colseEditLatex(){
      this.setIsShowEditLatex(false);
    },
    dragDown1(){
      document.getElementById("LaTeXDrag").addEventListener("mousedown",this.dragDown);
    },
    dragDown:function(e){
      window.event ? window.event.cancelBubble = true : e.stopPropagation();
      let oDiv = document.getElementById("latexEditBg");
      let disX = e.clientX - oDiv.offsetLeft;
      let disY = e.clientY - oDiv.offsetTop;
      document.onmousemove = (el)=>{
        let left = el.clientX - disX;
        let top = el.clientY - disY;
        oDiv.style.left = left + 'px';
        oDiv.style.top = top + 'px';
      }

      document.onmouseup = (e)=>{
        document.onmousemove = null;
        document.onmouseup = null;
      }
    },
    latexTransforma(text){
        let span = document.getElementById('latexId');
        span.innerHTML = '';
        window.MathJax.texReset();
        var options = window.MathJax.getMetricsFor(span,true);
        options.display = true;
        let svgHtml =  window.MathJax.tex2svg(text);
        span.appendChild(svgHtml);
    },
    latexChange(value){
      this.isSystem = false;
      this.latexName2 = this.latexName1;
      if(this.latexName1 == '' || this.latexName1 == null){
        this.latexName2 = '';
      }
      
      setTimeout(() => {
        // this.latexTransforma(this.latexName2);
        let output = document.getElementById('latexId');
        if((output == undefined || output.innerText == null || output.innerText == '') &&
         (this.latexName != '' && this.latexName != null)){
          this.isLatex = false;
        }else{
          this.isLatex = true;
        }
      }, 200);
    },

    setLatex(){
      //判断公式是否包含汉字
      // var reg = new RegExp("[\\u4E00-\\u9FFF]+","g");
      // if(reg.test(this.latexName1)){
      //   this.$message.error("不允许包含汉字");
      //   return;
      // }
      if (!getJwt("jwt")) {
        this.showLoginModel(true);
        return;
      }
      if(this.generalConfig.laTexNeedMember){
        if (!MindMe.isUpgrade()) {
          // this.$message.error(this.getString(this.strings.Member_Exclusive));
          let that = this;
          this.$confirm({
            title: getString(strings.Mind_Tips),
            content: getString(strings.Member_Exclusive),
            centered: true,
            okText: getString(strings.Mind_Edit_Confirm),
            cancelText: getString(strings.Global_Cancel),
            onOk() {
              that.isShowShoppingModel(true);
            },
            onCancel() {},
          });
          return;
        }
      }
      if(!this.isLatex){
        this.$message.error(this.getString(this.strings.Mind_LaTeX_Error));
        return;
      }
      if(EditMindmapVM.selectedNodeId <= -1 || this.nodeName == '未选中节点'){
        this.$message.error(this.getString(this.strings.Global_Add_Target_Tips));
        return;
      }
      // mjx-math
      if(this.latexName1 == ''){
        EditMindmapVM.delLatext();
      }else{
        EditMindmapVM.addLatext(this.latexName1);
      }
      this.hideLatex();
    },

    getMeLatexTemp(){
      this.meLatexOrSystemTemp = [];
      let isSendLatex = false;
      postMindmapMyLatex(
        {},
        (res) => {
          
          let latexMeTemp = [];
          let latexTempMy = [];
          let latexTempS = [];
          if(res.json == '' || res.json == null ){
            this.meLatexOrSystemTemp = this.latexTemps;
            return;
          }
          this.meLatexTemp = JSON.parse(res.json);
          latexTempMy = JSON.parse(res.json);
          let maLatexTempContent = this.meLatexTemp.length;
          if(this.meLatexTemp == null || maLatexTempContent<=0){
            this.meLatexOrSystemTemp = this.latexTemps;
            return;
          }
          for(var index = 0 ; index < maLatexTempContent ; index++){
            let textLatex = this.meLatexTemp[index];
            let obj = {
              value:textLatex,
            }
            latexMeTemp.push(obj);
          }
          latexTempS = latexMeTemp;
          
          this.meLatexOrSystemTemp = latexTempS.concat(this.latexTemps);

          isSendLatex = true;
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
    },
    addMeTemp(){
      let latexMe = [this.latexName1]
      this.meLatexTemp = latexMe.concat(this.meLatexTemp);
      let tempLatex = JSON.stringify(this.meLatexTemp);
      let obj={json:tempLatex};
      postMindmapUploadMyLatex(
        obj,
        (res) => {
          this.getMeLatexTemp();
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
    },
    addHistoryList(){
      let countHost = this.latexHistoryList.length;
      if(countHost >= this.maxHistory){
        this.latexHistoryList.splice(countHost-1,1)
      }
      if(this.cursor>0){
        this.latexHistoryList.splice(0,this.cursor);
      }
      this.cursor = 0;
      this.latexHistoryList.splice(0,0,this.latexName1);
    },
    latexRevokeClick(){
      if(this.cursor == this.latexHistoryList.length){
        return;
      }
      this.cursor++;
      this.isSystem = true;
      this.latexName1 = this.latexHistoryList[this.cursor];
      this.latexName2 = this.latexHistoryList[this.cursor];
    },
    latexRollBackClick(){
      if(this.cursor <= 0){
        return;
      }
      this.cursor--;
      this.isSystem = true;
      this.latexName1 = this.latexHistoryList[this.cursor];
      this.latexName2 = this.latexHistoryList[this.cursor];
    },

    clickQuickTile(item,index){
      this.selectTitleId = item.id;
      this.selectIndex = index;
    },
    clickQuickBtn(item,index){
      this.isSystem = false;
      if(this.latexName1 == null || this.latexName1 == '' || this.latexName1 == undefined){
        this.latexName1 = item.text;
      }else{
        this.latexName1 = this.latexName1 + item.text;
      }
      this.latexChange();
      this.setCursorPosition(this.latexName1.length,0);
      if(this.latexQuickLately.length >= 40){
        let indexFew = this.latexQuickLately.length-39;
        this.latexQuickLately.splice(39,indexFew);
      }
      for(var index = 0;index<this.latexQuickLately.length;index++){
        let itemLately = this.latexQuickLately[index];
        if(item.text == itemLately.text){
          this.latexQuickLately.splice(index,1);
        }
      }
      var latelyUsedBtns = [];
      latelyUsedBtns.push(item);
      latelyUsedBtns = latelyUsedBtns.concat(this.latexQuickLately);
      this.latexQuickLately = [];
      localStorage.setItem("latexQuickLately",JSON.stringify(latelyUsedBtns));
      this.latexQuickLately = latelyUsedBtns;
    },
    clickQuickStyleBtn(item,index){
      this.isSystem = false;
      let cursorPosition;
      let addCount;
      if(item.text.indexOf('#') <= 0){
        if(this.latexName1 == null || this.latexName1 == '' || this.latexName1 == undefined){
          this.latexName1 = item.text;
        }else{
          this.latexName1 = this.latexName1 + item.text;
        } 
        cursorPosition = this.latexName1.length;
      }else{
        let ctrl = document.getElementById("inputLatex");
        let textSelect;
        let textStart;
        let textEnd;
        if (document.selection) { 
            ctrl.focus (); // 获取焦点
            var Sel = document.selection.createRange(); // 创建选定区域
            textSelect = Sel.text;                      // 获取当前选定区的文本内容长度
            textStart = Sel.anchorOffset;
            textEnd = Sel.focusOffset;
        } else if (ctrl.selectionStart || ctrl.selectionStart == '0') {
            textStart =ctrl.selectionStart; // 获取选定区的开始点 
            textEnd = ctrl.selectionEnd;
            textSelect = this.latexName1.substring(ctrl.selectionStart,ctrl.selectionEnd)
        }
        if(textSelect == "" || textSelect==null){
          if(this.latexName1 == null || this.latexName1 == '' || this.latexName1 == undefined){
            this.latexName1 = item.text;
            cursorPosition = this.latexName1.length -1;
            addCount = 1;
          }else{
            this.latexName1 =  this.latexName1.substring(0,textStart) + item.text + this.latexName1.substring(textEnd+1);
            cursorPosition = item.text.length + textStart -1;
            addCount = 1;
          } 
        }else{
          let newText = item.text.replace('A',textSelect);
          this.latexName1 =  this.latexName1.substring(0,textStart) + newText + this.latexName1.substring(textEnd);
          cursorPosition = newText.length + textStart -1;
          addCount = textSelect.length;
        }
      }
      this.latexChange();
      this.setCursorPosition(cursorPosition,addCount);
      if(this.latexQuickLately.length >= 40){
        let indexFew = this.latexQuickLately.length-39;
        this.latexQuickLately.splice(39,indexFew);
      }
      for(var index = 0;index<this.latexQuickLately.length;index++){
        let itemLately = this.latexQuickLately[index];
        if(item.text == itemLately.text){
          this.latexQuickLately.splice(index,1);
        }
      }
      var latelyUsedBtns = [];
      latelyUsedBtns.push(item);
      latelyUsedBtns = latelyUsedBtns.concat(this.latexQuickLately);
      this.latexQuickLately = [];
      localStorage.setItem("latexQuickLately",JSON.stringify(latelyUsedBtns));
      this.latexQuickLately = latelyUsedBtns;
    },
    clickTempBtn(item,index){
      this.isSystem = false;
      if(this.latexName1 == null || this.latexName1 == '' || this.latexName1 == undefined){
        this.latexName1 = item.value;
      }else{
        this.latexName1 = this.latexName1 + item.value;
      }
      this.latexChange();
      this.setCursorPosition(this.latexName1.length,0);
    },

    deleteMeTemp(item){
      for(var index = 0; index<this.meLatexTemp.length;index++){
        let meTemp = this.meLatexTemp[index]
        if(item.value == meTemp){
          this.meLatexTemp.splice(index,1);
        }
      }
      let tempLatex = JSON.stringify(this.meLatexTemp);
      let obj={json:tempLatex};
      return new Promise((resolve, reject) => {
          postMindmapUploadMyLatex(
            obj,
            (res) => {
              resolve(res);
              this.getMeLatexTemp()
            },
            (error) => {
              this.$message.error(error.desc);
              reject(error);
            }
          );
        });
    },
    focusInput(){
       this.useShortcut(false);
    },
    blurInput(){
      this.useShortcut(true);
    },
    setCursorPosition(count,addCount){
      var Browser_Name=navigator.appName;
      var isIE=(Browser_Name=="Microsoft Internet Explorer");//判读是否为ie浏览器
      var textNode=document.getElementById("inputLatex");//keyword为要操作的文本框的ID，根据情况修改
      if(isIE){//IE
        var range = textNode.createTextRange();  // 创建选定区
        range.collapse(true);                // 设置为折叠,即光标起点和结束点重叠在一起
        range.moveEnd('character', count);     // 移动结束点
        range.moveStart('character', count-addCount);   // 移动开始点
        range.select();                      // 选定当前区域
      }else{
        textNode.focus();
        this.$nextTick(() => {
          textNode.setSelectionRange(count-addCount,count);
        });
      }
    },

    getInitLatexName(){
      let node = EditMindmapVM.getSelectedNode();
        this.dataNodeLatex = node.value.latexContent;
        if(node == null || node == undefined){
          this.nodeName = "未选中节点";
        }else{
          this.nodeName = "";
          if(this.dataNodeLatex == null || this.dataNodeLatex == undefined){
            this.latexName1 = "";
            this.latexName2 = "";
          }else{
            this.latexName1 = node.value.latexContent.text;
            this.latexName2 = node.value.latexContent.text;
          }
         
        }
    }

  },
  watch: {
    '$store.state.tsakSelectedNodeId': {
      handler(newVal){
        this.getInitLatexName();
           
      },
      deep:true
    },
    showEditLatex(newVal){
      if(newVal){
        this.useShortcut(false);
      }else{
        this.useShortcut(true);
      }
    },
    latexName1(newVal){
      if(!this.isSystem){
        this.addHistoryList();
      }
    }
  },
};
</script>
<style>
  @import "../../../../node_modules/katex/dist/katex.min.css";
</style>
<style lang='less' scoped>
.latex_edit_bg{
  position: fixed;
  z-index: 999;
}

/* 元素菜单列表开始 */
  .latex_edit_content{
    z-index: 999;
    position: absolute;
    width: 867px;
    height: 581px;
    float: right;
    top: 0px;
    left: 20px;
    background-color: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #f0f0f0;
    user-select: none;
    box-shadow: 0px 0px 5px #999;
    
    .latex_edit_content_head{
      position: relative;
      width: 867px;
      height: 36px;
      background: #F3F3F3;
      opacity: 1;       
      border-radius: 4px 4px 0 0;
      .latex_edit_content_head_title{
        width: 138px;
        height: 20px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 20px;
        color: #333333;
        opacity: 1;
        position: absolute;
        margin-left: 16px;
        margin-top: 8px;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        overflow: hidden;
      }
      .latex_edit_content_head_delete{
        height: 27px;
        opacity: 1;
        border-radius: 2px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 27px;
        color: #666666;
        position: relative;
        float: right;
        text-align: center;
        margin-right: 8px;
        margin-top: 3px;
        padding: 0 4px;
        cursor: pointer;
        .close-button-img {
          width: 16px;
          cursor: pointer;
          opacity: 0.5;
        }
        .close-button-img:hover {
          opacity: 1;
        }
      }
    }
    .latex_edit_content_content{
      position: relative;
      width: 308px;
      height: 242px;
      .latex_edit_content_weight{
        position: relative;
        width: 827px;
        height: 77px;
        margin-top: 20px;
        margin-left: 20px;
        .latex_edit_content_weight_name{
          width: 827px;
          height: 77px;
          background: #FFFFFF;
          border: 1px solid #EEEEEE;
          opacity: 1;
          border-radius: 4px;
          position: absolute;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          padding: 15px 12px;
        }
        .latex_edit_content_weight_select{
          width: 209px;
          height: 30px;
          position: absolute;
          line-height: 30px;
          margin-left: 97px;
        }
      }
      .latex_edit_content_completion{
        position: relative;
        width: 827px;
        height: 359px;
        margin-top: 20px;
        margin-left: 20px;
        .latex_edit_content_completion_left{
          width: 650px;
          height: 359px;
          position: absolute;
          line-height: 30px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          .latex_edit_content_completion_left_top{
            position: relative;
            width: 650px;
            height: 191px;
            background: #EFEFEF;
            border: 1px solid #EEEEEE;
            opacity: 1;
            border-radius: 4px;
            .quick_btn_exhibition{
              width: 650px;
              height: 36px;
              background: #FFFFFF;
              border: 1px solid #EEEEEE;
              opacity: 1;
              border-radius: 4px 4px 0px 0px;
              overflow-y: hidden;
              overflow-x: auto;
              white-space: nowrap;
              display: inline-block;
              .quick_title_name{
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 500;
                line-height: 36px;
                color: #999999;
                opacity: 1;
                padding: 0 10px;
                text-align: center;
              }
              .quick_title_name_select{
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 500;
                line-height: 36px;
                color: #333333;
                opacity: 1;
                padding: 0 10px;
                text-align: center;
              }
            }
            .quick_btn_exhibition::-webkit-scrollbar {
              display: none; /* Chrome Safari */
            }
            .quick_letter_btn{
              position: relative;
              width: 650px;
              height: 150px;
              margin-top: -5px;
              padding: 0px 4px 10px 0px;
              .letter_btn{
                position: relative;
                float: left;
                width: 30px;
                height: 30px;
                text-align: center;
                background: #FFFFFF;
                opacity: 1;
                border-radius: 1px;
                margin-left: 4px;
                margin-top: 5px;
                cursor: pointer;
              }
              .function_btn{
                position: relative;
                float: left;
                width: 60px;
                height: 30px;
                text-align: center;
                background: #FFFFFF;
                opacity: 1;
                border-radius: 1px;
                margin-left: 4px;
                margin-top: 5px;
                cursor: pointer;
              }
            }
          }
          .latex_edit_content_completion_left_bottom{
            position: relative;
            width: 650px;
            height: 153px;
            background: #FFFFFF;
            opacity: 1;
            border-radius: 4px;
            margin-top: 15px;
            .latex_edit_content_completion_left_bottom_textarea{
              width: 650px;
              height: 153px;
              border: 1px solid #EEEEEE;
              border-radius: 4px;
              resize:none;
              padding: 15px 12px;
            }
          }
          .update_me_latex{
            display: flex;
            right: 15px;
            margin-top: -30px;
            position: absolute;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
            opacity: 1;
            cursor: pointer;
          }
          .latex_revoke{
            display: flex;
            left: 15px;
            margin-top: -20px;
            position: absolute;
            cursor: pointer;
            .latex_revoke_title{
              position: absolute;
              display: none;
              margin-top: -20px;
              background-color: #c5c5c5;
              width: 40px;
              height: 20px;
              line-height: 20px;
              text-align: center;
              margin-left: -10px;
            }
          }
          .latex_revoke_yes{
            opacity: 1;
          }
          .latex_revoke_no{
            opacity: 0.6;
          }
          .latex_revoke:hover{
            .latex_revoke_title{
              display: block;
            }
          }
          .latex_roll_back{
            display: flex;
            left: 40px;
            margin-top: -20px;
            position: absolute;
            cursor: pointer;
            .latex_roll_back_title{
              position: absolute;
              display: none;
              margin-top: -20px;
              background-color: #c5c5c5;
              width: 40px;
              height: 20px;
              line-height: 20px;
              text-align: center;
              margin-left: -10px;
            }
          }
          .latex_roll_back:hover{
            .latex_roll_back_title{
              display: block;
            }
          }
          .latex_roll_back_yes{
            opacity: 1;
          }
          .latex_roll_back_no{
            opacity: 0.6;
          }
        }
        .latex_edit_content_completion_rigth{
          width: 167px;
          height: 359px;
          position: absolute;
          margin-left: 660px;
          border: 1px solid #EEEEEE;
          .latex_edit_content_completion_rigth_select{
            width: 167px;
            height: 47px;
            position: relative;
            text-align: center;
            line-height: 47px;
            border-bottom:1px solid #EEEEEE;
          }
          .latex_edit_content_completion_rigth_temps{
            width: 167px;
            height: 312px;
            position: relative;
            padding: 0px 5px;
            overflow-y: auto;
            // border-bottom:1px solid #EEEEEE;
            .temp_box{
              width: 155px;
              height: 46px;
              border: 1px solid #EEEEEE;
              opacity: 1;
              position: relative;
              margin-top: 5px;
              text-align: center;
              line-height: 46px;
              cursor: pointer;
              .temp_box_delate{
                position: absolute;
                right: -4px;
                top: -4px;
              }
            }
            .temp_box:hover{
              background: #F4F4F5;
            }
          }
          .latex_edit_content_completion_rigth_temps::-webkit-scrollbar {
            width: 2px;
            /* height: 340px; */
            scrollbar-arrow-color: #8f8f8f8f;
          }
          .latex_edit_content_completion_rigth_temps::-webkit-scrollbar-thumb {
            border-radius: 16px;
            background: #8f8f8f;
          }
          .latex_edit_content_completion_rigth_temps::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 5px #ffffff;
            border-radius: 16px;
            background: #e6e6e6;
          }
        }
      }
      .latex_edit_content_btn{
        position: relative;
        width: 308px;
        height: 30px;
        margin-top: 19px;
        .latex_edit_content_btn1{
          width: 68px;
          height: 30px;
          border: 1px solid #EEEEEE;
          opacity: 1;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 30px;
          color: #999999;
          text-align: center;
          position: absolute;
          margin-left: 341px;
          cursor: pointer;
        }
        .latex_edit_content_btn1:hover{
          color: #ff7354;
          border-color: #ff7354;
        }
        .latex_edit_content_btn2{
          width: 68px;
          height: 30px;
          background: #FD492B;
          opacity: 1;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 30px;
          color: #ffffff;
          text-align: center;
          position: absolute;
          margin-left: 459px;
          cursor: pointer;
        }
        .latex_edit_content_btn2:hover{
          color: #fff;
          background-color: #ff7354;
        }
      }
    }

  }
/* 元素菜单列表结束 */
</style>