import Config from "../../../core/core/calcule/Config"
import UiUtil from "../../../utils/UiUtil"
import TimeLineNodeType from "../../datatype/TimeLineNodeType"
import TimeDotElementContent from "../../mindelementdata/mindcontent/TimeDotElementContent"
import TimeLineLayout from "./TimeLineLayout"
import TimeNodeLayoutType from "../../datatype/TimeNodeLayoutType"
import Util from "../../../utils/Util"


/**
 * ProjectName: MindMap
 * Created by tony on 5/27/21
 * Copyright(c) 2020 mindyushu.com
 */

class TimeHorizontalCrisscrossLayout extends TimeLineLayout {
    constructor() {
        super();
        this.UiUtil = new UiUtil();
        this.Util = Util;
        this.baseLineHeight = this.UiUtil.dip2px(10);
        this.ladderAngle = 30.0;
        this.verticalSpace = this.UiUtil.dip2px(10);
        this.verticalHeight = 0;
        this.cellWidth = 0;
    }

    initConfigInfo() {
        this.radius = this.getRadius();
        this.baseLineHeight = this.radius * 2 + this.UiUtil.dip2px(this.baseLine.timeLineContent.lineWidth) * 2;
        this.baseLineTitleSpace = this.UiUtil.dip2px(10) + this.UiUtil.dip2px(this.baseLine.timeLineContent.lineWidth);

        if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD ||
            this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD_TITLE) {
            this.baseLineHeight += this.UiUtil.dip2px(15);
        } else if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_ARROW_HEAD) {
            this.baseLineHeight += this.UiUtil.dip2px(17);
        } else {
            this.baseLineHeight += this.UiUtil.dip2px(10);
        }

        this.verticalSpace = this.addSubjectSpcaeVertical(this.UiUtil.dip2px(10));
        this.sonSubjectAndLineSpace = this.addSonSubjectSpcaeVertical(0);

        switch (this.timeNodeLayoutType) {
            case TimeNodeLayoutType.NORMAL:
            case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                this.subjectSpace = this.addSubjectSpcaeVertical(this.UiUtil.dip2px(10));
                this.firstSubjectBaseLineTopSpace = this.UiUtil.dip2px(8);
                break;
            case TimeNodeLayoutType.TITLE_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                this.subjectSpace = this.addSubjectSpcaeVertical(this.UiUtil.dip2px(10));
                this.firstSubjectBaseLineTopSpace = this.UiUtil.dip2px(20);
                break;
            default:
        }
    }

    setElementsPoint(isChange) {
        if (!isChange && this.title.x < 0) {
            let left = (Config.Mind_Width - this.UiUtil.getScreenWidth()) / 2;
            let top = (Config.Mind_Height - this.UiUtil.getScreenHeight()) / 2;
            this.title.y = (top) + this.title.height + 20;
            this.title.x = (this.UiUtil.getScreenWidth() - (this.title.width)) / 2 + left;
        }
        this.initConfigInfo();

        let nodeList = this.getOrderNodes();


        if (nodeList.isEmpty()) {
            this.baseLine.timeLineContent.dots = new Array();
            return;
        }
        this.cellWidth = Math.min(this.getMaxBevelWidth(nodeList) * 2, this.UiUtil.dip2px(260));

        let allNodeVerticalSpace = ((this.cellWidth / 2) * this.Util.tan(this.ladderAngle));
        let totleWidth = this.cellWidth / 2 * nodeList.length;

        this.baseLine.x = this.title.x + this.title.width + this.firstSubjectBaseLineTopSpace + this.baseLineTitleSpace + this.radius;
        this.baseLine.y = this.title.y + this.title.height / 2 - allNodeVerticalSpace;
        this.baseLine.width = totleWidth;
        this.baseLine.height = allNodeVerticalSpace;

        this.space = (this.baseLineHeight - this.radius) / 2;

        let dots = new Array();

        let mainMindElementDataDictArray = this.mainMindElementDataDict.keys();
        let mainMindElementDataDictLength = mainMindElementDataDictArray.length

        for (let index = 0; index < mainMindElementDataDictLength; index++) {
            let key = mainMindElementDataDictArray[index];
            let timeNode = this.mainMindElementDataDict.get(key);
            let head = timeNode.head;
            let title = timeNode.title;
            let dotElementContent = new TimeDotElementContent();
            dotElementContent.targetId = timeNode.id;
            dotElementContent.radius = this.radius;
            if (this.settingData.useTimeLineColor) {
                dotElementContent.color = this.baseLine.timeLineContent.color;
            } else {
                dotElementContent.color = this.getHeadConnectLineColor(head, timeNode.title);
            }
            dots.push(dotElementContent);
        }
        let dotX = this.baseLine.x;
        let dotY = this.baseLine.y + this.baseLine.height;
        let nodeSize = nodeList.length;
        let lastNodeWidth = 0;
        for (let index = 0; index < nodeSize; index++) {
            let timeNode = nodeList[index];
            let timeNodeHeight = this.getTimeNodeHeight(timeNode);
            let dotElementContent = dots[index];
            let head = timeNode.head;
            let title = timeNode.title;
            let desc = timeNode.desc;
            dotElementContent.radius = this.radius;
            if (this.timeNodeLayoutType == TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM ||
                this.timeNodeLayoutType == TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM) {
                dotElementContent.radius = head.width / 2;
            }
            if (this.settingData.useTimeLineColor) {
                dotElementContent.color = this.baseLine.timeLineContent.color;
            } else {
                dotElementContent.color = this.getHeadConnectLineColor(head, timeNode.title);
            }
            let nodeWidth = this.getTimeMindTypeNodeWidthByHorizontal(timeNode);
            dotX = this.baseLine.x + this.cellWidth / 2 * (index + 1);
            let isTop = index % 2 == 0;
            if (isTop) {
                dotY = this.baseLine.y;
            } else {
                dotY = this.baseLine.y + allNodeVerticalSpace;
            }
            dotElementContent.x = dotX - this.baseLine.x;
            dotElementContent.y = dotY - this.baseLine.y;
            switch (this.timeNodeLayoutType) {
                case TimeNodeLayoutType.NORMAL:
                    head.isHidden = false;
                    title.isHidden = false;
                    desc.isHidden = false;
                    head.x = dotX - head.width / 2;
                    title.x = dotX - title.width / 2;
                    desc.x = dotX - (desc.width) / 2;
                    if (isTop) {
                        head.y = dotY - head.height - this.radius - this.verticalSpace;
                        title.y = head.y - title.height - this.subjectSpace;
                        desc.y = title.y - desc.height - this.titleDescMaxSpace - this.sonSubjectAndLineSpace;
                    } else {
                        head.y = dotY + this.radius + this.verticalSpace;
                        title.y = head.y + head.height + this.subjectSpace;
                        desc.y = title.y + title.height + this.titleDescMaxSpace + this.sonSubjectAndLineSpace;
                    }
                    break;
                case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                    head.isHidden = true;
                    title.isHidden = false;
                    desc.isHidden = false;
                    title.x = dotX - title.width / 2;
                    desc.x = dotX - (desc.width) / 2;
                    desc.y = title.y + this.BaseLayout.getNodeHeight(title) + this.titleDescMaxSpace;
                    if (isTop) {
                        title.y = dotY - title.height - this.radius - this.verticalSpace;
                        desc.y = title.y - desc.height - this.titleDescMaxSpace - this.sonSubjectAndLineSpace;
                    } else {
                        title.y = dotY + this.radius + this.verticalSpace;
                        desc.y = title.y + title.height + this.titleDescMaxSpace + this.sonSubjectAndLineSpace;
                    }
                    break;
                case TimeNodeLayoutType.TITLE_BOTTOM:
                    head.isHidden = true;
                    title.isHidden = false;
                    desc.isHidden = true;
                    title.x = dotX - title.width / 2;
                    if (isTop) {
                        title.y = dotY - title.height - this.radius - this.verticalSpace;
                    } else {
                        title.y = dotY + this.radius + this.verticalSpace;
                    }
                    break;
                case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                    head.isHidden = false;
                    title.isHidden = false;
                    desc.isHidden = true;
                    head.x = dotX - head.width / 2;
                    title.x = dotX - title.width / 2;
                    desc.x = dotX - (desc.width) / 2;
                    if (isTop) {
                        head.y = dotY - head.height - this.radius - this.verticalSpace;
                        title.y = head.y - title.height - this.verticalSpace;
                    } else {
                        head.y = dotY + this.radius + this.verticalSpace;
                        title.y = head.y + head.height + this.verticalSpace;
                    }
                    break;
                case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                    head.isHidden = false;
                    title.isHidden = false;
                    desc.isHidden = false;
                    head.x = dotX - head.width / 2;
                    title.x = dotX - title.width / 2;
                    desc.x = dotX - (desc.width) / 2;
                    if (isTop) {
                        head.y = dotY - head.height / 2;
                        title.y = head.y - title.height - this.verticalSpace;
                        desc.y = title.y - desc.height - this.titleDescMaxSpace - this.sonSubjectAndLineSpace;
                    } else {
                        head.y = dotY - head.height / 2;
                        title.y = head.y + head.height + this.verticalSpace;
                        desc.y = title.y + title.height + this.titleDescMaxSpace + this.sonSubjectAndLineSpace;
                    }
                    break;
                case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                    head.isHidden = false;
                    title.isHidden = false;
                    desc.isHidden = true;
                    head.x = dotX - head.width / 2;
                    title.x = dotX - title.width / 2;
                    desc.x = dotX - (desc.width) / 2;
                    if (isTop) {
                        head.y = dotY - head.height / 2;
                        title.y = head.y - title.height - this.verticalSpace;
                    } else {
                        head.y = dotY - head.height / 2;
                        title.y = head.y + head.height + this.verticalSpace;
                    }
                    break;
                default:
            }
            lastNodeWidth = nodeWidth;
        }

        this.baseLine.timeLineContent.lineContentWidth = this.baseLine.width;
        this.baseLine.timeLineContent.lineContentHeight = this.baseLineHeight;
        this.baseLine.timeLineContent.dots = this.getOrderDot(dots);

    }

    getOrderDot(dots) {
        let list = new Array();
        dots.forEach(node => {
            list.push(node);
        });
        list.sort(function (data1, data2) {
            if (data1.y == data2.y) {
                return 0;
            } else if (data1.x < data2.x) {
                return -1;
            } else {
                return 1;
            }
        })
        return list;
    }

    getDataMinBottom(data) {
        return (this.Util.tan(this.ladderAngle) * data.width / 2);
    }

    getMaxBevelWidth(list) {
        let result = 0;
        if (list.isEmpty()) {
            return result;
        }
        let size = list.length;
        let lastNodeWidth = 0;
        for (let index = 0; index < size; index++) {
            let node = list[index];
            let nodeWidth = this.getTimeMindTypeNodeWidthByHorizontal(node);
            let width = lastNodeWidth / 2 + nodeWidth / 2;
            result = this.addSubjectSpcaeHorizontal(Math.max(result, width));
            lastNodeWidth = nodeWidth;
        }
        return result;
    }

    getTimeNodeHeight(node) {
        let result = 0;
        switch (this.timeNodeLayoutType) {
            case TimeNodeLayoutType.NORMAL:
                result = node.head.height + this.verticalSpace + node.title.height + this.verticalSpace + node.desc.height + this.titleDescMaxSpace + this.radius + this.verticalSpace + this.sonSubjectAndLineSpace;
                break;
            case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                result = node.title.height + this.verticalSpace + node.desc.height + this.titleDescMaxSpace + this.radius + this.verticalSpace + this.sonSubjectAndLineSpace;
                break;
            case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                result = node.head.height / 2 + this.verticalSpace + node.title.height + this.verticalSpace + node.desc.height + this.titleDescMaxSpace + this.sonSubjectAndLineSpace;
                break;
            case TimeNodeLayoutType.TITLE_BOTTOM:
                result = node.title.height + this.verticalSpace + this.radius;
                break;
            case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                result = node.head.height + this.verticalSpace + node.title.height + this.radius + this.verticalSpace;
                break;
            case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                result = node.head.height / 2 + this.verticalSpace + node.title.height;
                break;
            default:
        }
        return result;
    }
}

export default TimeHorizontalCrisscrossLayout