
import MindElementContent from './MindElementContent.js'
// package mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent;

// import java.util.ArrayList;

// import mind.yushu.com.mindmap.utils.UiUtil;
import UiUtil from '../../../utils/UiUtil.js';

// import mind.yushu.com.mindmap.viewmodel.datatype.StatisticsChapeType;
import StatisticsChapeType from '../../datatype/StatisticsChapeType'


// import mind.yushu.com.mindmap.viewmodel.datatype.StatisticsType;

/**
 * ProjectName: MindMap
 * Created by tony on 1/12/21
 * Copyright(c) 2020 mindyushu.com
 */

class StatisticsContent extends MindElementContent {
    constructor() {
        super()
        this.radius = new UiUtil().dip2px(100);
        this.cellsData = new Array();
        this.type = StatisticsChapeType.CakeShape_1;
        this.title = "";
        this.titleX = 0;
        this.titleY = 0;
        this.titleColor = 0x555555;
        this.titleFontSize = 20;
        this.titleTextBold = false;
    }



    // public StatisticsContent() {
    // }

    copy() {
        let data = new StatisticsContent();
        data.x = this.x;
        data.y = this.y;
        data.width = this.width;
        data.height = this.height;

        data.radius = this.radius;
        data.type = this.type;
        data.title = this.title;
        data.titleColor = this.titleColor;
        data.titleFontSize = this.titleFontSize;
        data.titleTextBold = this.titleTextBold;

        let count = this.cellsData.length;
        if (count > 0) {
            for (let index = 0; index < count; index++) {
                data.cellsData.push(this.cellsData[index].copy());
            }
        }
        return data;
    }

    stickStyle(data) {
        if (data == null) {
            return;
        }
        this.type = data.type;
        this.radius = data.radius;
        this.title = data.title;
        this.titleColor = data.titleColor;
        this.titleFontSize = data.titleFontSize;
        this.titleTextBold = data.titleTextBold;
        let count = this.cellsData.length;
        let dataCount = data.cellsData.length;
        if (count > 0 && dataCount > 0) {
            for (let index = 0; index < count; index++) {
                if (index > dataCount - 1) {
                    this.cellsData[index].stickStyle(data.cellsData[index]);
                } else {
                    this.cellsData[index].stickStyle(data.cellsData[index%dataCount]);
                }
            }
        }
    }

    stickColorStyle( data) {
        if (data == null) {
            return;
        }
        this.titleColor = data.titleColor;
        let count = this.cellsData.length;
        let dataCount = data.cellsData.length;
        if (count > 0 && dataCount > 0) {
            for (let index = 0; index < count; index++) {
                if (index > dataCount - 1) {
                    this.cellsData[index].stickColorStyle(data.cellsData[index]);
                } else {
                    this.cellsData[index].stickColorStyle(data.cellsData[index%dataCount]);
                }
            }
        }
    }

    equal(data, ignorePoint = false, ignoreSize = false) {
        let result = false;
        if (!ignorePoint) {
            result = result || data.x != this.x;
            result = result || data.y != this.y;
        }
        if (!ignoreSize) {
            result = result || data.width != this.width;
            result = result || data.height != this.height;
        }
        result = result || data.type != this.type;
        result = result || data.radius != this.radius;
        result = result || data.title != this.title;
        result = result || data.titleColor != this.titleColor;
        result = result || data.titleFontSize != this.titleFontSize;
        result = result || data.titleTextBold != this.titleTextBold;
        if (!result && data.cellsData != null && this.cellsData != null) {
            result = result || data.cellsData.length != this.cellsData.length;
        }

        if (!result) {
            let count = this.cellsData.length;
            if (count > 0) {
                for (let index = 0; index < count; index++) {
                    result = result || !this.cellsData[index].equal(data.cellsData[index]);
                }
            }
        }
        return !result;
    }
}

export default StatisticsContent