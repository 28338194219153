//顶部布局的数据计算
import Config from "../../../core/core/calcule/Config"
import EncircleNodesPointsCalculation from "../../../core/core/calcule/EncircleNodesPointsCalculation"
import NodesRectCalculation from "../../../core/core/calcule/NodesRectCalculation"
import Colors from "../../../utils/Colors"
import UiUtil from "../../../utils/UiUtil"
import IdGenerator from "../base/IdGenerator"
import LineColorMode from "../base/LineColorMode"
import LineOrientation from "../../datatype/LineOrientation"
import LineLayout from "../../datatype/LineLayout"
import MindElementType from "../../datatype/MindElementType"
import NodeLayoutType from "../../datatype/NodeLayoutType"
import LineElementContent from "../../mindelementdata/mindcontent/LineElementContent"
import BaseLayout from "./BaseLayout"
import Point from "../../../viewmodel/core/base/Point"
import EncircleShapeType from "../../datatype/EncircleShapeType"
import RectsMergeCalculation from "./RectsMergeCalculation"
import HashMap from "../base/HashMap"
import EncircleTitleLayoutType from "../../datatype/EncircleTitleLayoutType"


/**
 * ProjectName: MindMap
 * Created by tony on 6/25/21
 * Copyright(c) 2020 mindyushu.com
 */


class TopLayout extends BaseLayout {

    constructor() {
        super();
        this.dataLeftWidthMap = new HashMap();
        this.dataRightWidthMap = new HashMap();
        this.UiUtil = new UiUtil();
        this.EncircleNodesPointsCalculation = new EncircleNodesPointsCalculation();
    }

    initConfig() {
        super.initConfig();
        this.dataLeftWidthMap.clear();
        this.dataRightWidthMap.clear();
        this.SonNodeHorizontalSpacee = this.addSonSubjectSpcaeHorizontal(Config.BottomLayoutSonNodeHorizontalSpacee);
        this.NodeHorizontalSpacee = this.addSubjectSpcaeHorizontal(Config.BottomLayoutNodeHorizontalSpacee);

        this.SonNodeVerticalSpacee = this.addSonSubjectSpcaeVertical(Config.BottomLayoutSonNodeVerticalSpacee);

        if (this.globalLayout == LineLayout.CURVE_LINE_AVERAGE || this.globalLayout == LineLayout.STRAIGHT_LINE_2) {
            this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.BottomLayoutNodeVerticalSpacee / 2 * 3 + this.getSubjectLineWidth());
        } else if (this.globalLayout == LineLayout.RIGHT_ANGLE_LINE || this.globalLayout == LineLayout.RIGHT_ANGLE_CORNER_LINE) {
            if (!this.settingData.lineThicken) {
                this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.BottomLayoutNodeVerticalSpacee * 1.45 + this.getSubjectLineWidth());
            } else {
                this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.BottomLayoutNodeVerticalSpacee * 1.1 + this.getSubjectLineWidth());
            }
        } else if (this.globalLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE) {
            if (!this.settingData.lineThicken) {
                this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.BottomLayoutNodeVerticalSpacee * 1.45 + this.getSubjectLineWidth());
            } else {
                this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.BottomLayoutNodeVerticalSpacee * 1.15 + this.getSubjectLineWidth());
            }            
        } else if (this.globalLayout == LineLayout.CURVE_LINE ||
            this.globalLayout == LineLayout.STRAIGHT_CURVE_LINE || 
            this.globalLayout == LineLayout.CURVE_LINE_2) {
            this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.BottomLayoutNodeVerticalSpacee * 1.3 + this.getSubjectLineWidth());
        } else if (this.globalLayout == LineLayout.CURVE_LINE_CIRCULAR) {
            this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.BottomLayoutNodeVerticalSpacee * 1.4 + this.getSubjectLineWidth());
        }  else {
            this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.BottomLayoutNodeVerticalSpacee + this.getSubjectLineWidth());
        }
    }

    onLayout(isChange) {
        this.dataLeftWidthMap.clear();
        this.dataRightWidthMap.clear();
        this.setElementsPoint(isChange);
        this.hideRootFormLine();
        this.setGeneralizationElementsPoint(isChange);
        // this.setEncirclePoint();
        // this.setFloatExplainPoint(isChange);
    }

    setElementsPoint(isChange) {
        if ((!isChange || this.rootTreeNode.value.y <= -1 || this.rootTreeNode.value.x <= -1) &&
            this.rootTreeNode.value.type == MindElementType.MAIN_SUBJECT) {
            let left = (Config.Mind_Width - this.UiUtil.getScreenWidth()) / 2;
            let top = (Config.Mind_Height - this.UiUtil.getScreenHeight()) / 2;
            this.rootTreeNode.value.y = ((this.UiUtil.getScreenHeight()) - ((this.rootTreeNode.value.height))) / 2 + (top);
            this.rootTreeNode.value.x = ((this.UiUtil.getScreenWidth()) - ((this.rootTreeNode.value.width))) / 2 + (left);
        }
        this.initConfig();
        this.setNodeElementsPoint(this.rootTreeNode, isChange);
    }

    setEncirclePoint() {
        let encircleMindElementDataDict = this.encircleMindElementDataDict.keys();
        for (let index = 0; index < encircleMindElementDataDict.length; index++) {
            let mind = this.encircleMindElementDataDict.get(encircleMindElementDataDict[index]);
            let targetIds = new Array();
            if (mind.lineContent == null) {
                continue;
            }
            if (mind.lineContent.targetIds.isEmpty()) {
                targetIds.push(mind.lineContent.targetId > -1 ? mind.lineContent.targetId : mind.parentNodeId);
            } else {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                continue;
            }
            let padding = mind.lineContent == null ? 0 : mind.lineContent.padding;
            let textHeight = mind.lineContent != null && mind.lineContent.isContainText() ? mind.lineContent.textContent.height : 0;
            if (mind.lineContent != null && mind.lineContent.isContainText()) {
                mind.lineContent.textContent.x = new EncircleNodesPointsCalculation().space;
                mind.lineContent.textContent.y = 0;
            }
            if (targetIds.length == 1) {
                let node = this.getNodeById(targetIds[0]);
                if (node.isEmpty()) {
                    continue;
                }
                let rect = new NodesRectCalculation(this.explainMindElementDataDict).calcule(node);
                mind.x = (rect.x) - (new EncircleNodesPointsCalculation().space) - padding;
                mind.y = (rect.y) - (new EncircleNodesPointsCalculation().space) - padding - textHeight;
                mind.width = (rect.width()) + (new EncircleNodesPointsCalculation().space * 2 + padding * 2);
                mind.height = (rect.height()) + (new EncircleNodesPointsCalculation().space * 2 + padding * 2) + textHeight;

                let type = mind.lineContent == null ? EncircleShapeType.LAYOUT_TRAPEZOID : mind.lineContent.encircleShapeType;
                let points = new EncircleNodesPointsCalculation().calcule(node, type, padding);
                let encircleNodesPoint = new Array();

                for (let index = 0; index < points.length; index++) {
                    let point = points[index];
                    encircleNodesPoint.push(new Point((point.x) - mind.x, (point.y) - mind.y));
                }
                if (mind.lineContent == null) {
                    let lineContent = new LineElementContent(new Point(), new Point(), node.value.styleColor, node.value.id);
                    lineContent.encircleNodesPoint = encircleNodesPoint;
                    lineContent.strokeColor = node.value.styleColor;
                    mind.lineContent = lineContent;
                } else {
                    mind.lineContent.encircleNodesPoint = encircleNodesPoint;
                }
            } else {
                mind.lineContent.encircleShapeType = mind.lineContent.encircleShapeType == EncircleShapeType.LAYOUT_TRAPEZOID ?
                    EncircleShapeType.LAYOUT_RECTANGLE_2 : mind.lineContent.encircleShapeType;

                let rectList = new Array();
                for (let index = 0; index < targetIds.length; index++) {
                    let id = targetIds[index];
                    let node = this.getNodeById(id);
                    if (node.isEmpty()) {
                        continue;
                    }

                    let rect = new NodesRectCalculation(this.explainMindElementDataDict).calcule(node);
                    rectList.push(rect);
                }
                if (rectList.isEmpty()) {
                    continue;
                }

                let rect = new RectsMergeCalculation().calcule(rectList);
                mind.x = rect.x - new EncircleNodesPointsCalculation().space - padding;
                mind.y = rect.y - new EncircleNodesPointsCalculation().space - padding - textHeight;
                mind.width = rect.width() + new EncircleNodesPointsCalculation().space * 2 + padding * 2;
                mind.height = rect.height() + new EncircleNodesPointsCalculation().space * 2 + padding * 2 + textHeight;

                mind.lineContent.encircleNodesPoint = new Array();
            }
            if (mind.lineContent != null && mind.lineContent.isContainText()) {
                if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_MIDDLE ||
                        mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_MIDDLE) {
                    mind.lineContent.textContent.x = (mind.width - mind.lineContent.textContent.width)/2;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_RIGHT ||
                        mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_RIGHT) {
                    mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width - this.EncircleNodesPointsCalculation.space;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_FULL) {
                    mind.lineContent.textContent.width = mind.width;
                    mind.lineContent.textContent.x = 0;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_RIGHT) {
                    mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_LEFT) {
                    mind.lineContent.textContent.x = 0;
                    mind.lineContent.textContent.y = 0;
                } else {
                    mind.lineContent.textContent.x = this.EncircleNodesPointsCalculation.space;
                    mind.lineContent.textContent.y = 0;
                }
            }
        }
    }

    setNodeElementsPoint(node, isChange) {
        this.setDataPoint(node.value, isChange);
        let childCount = node.children.length;
        if (childCount == 0 || this.isLayout(node.value)) {
            return;
        }

        if (this.isRootNode(node) || node.value.layout == NodeLayoutType.LAYOUT_TOP) {
            for (let index = 0; index < node.children.length; index++) {
                this.setNodeElementsPoint(node.children[index], isChange);
            }
        } else {
            this.resetNodeLayout(node);
        }
    }

    setDataPoint(data, isChange) {
        if (data.isHidden || this.isLayout(data)) {
            return;
        }
        let node = this.getNodeById(data.id);
        this.pushCurrentLayoutNode(node);
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        // let parentNodeData = this.getNodeById(data.parentNodeId).value;
        let siblingsNodeDatas = this.getSiblingsMindElementDataById(data.id);
        if (data.id == this.rootTreeNode.value.id) {
            return;
        } else {
            // let allSiblingsNodesWidth = this.getSiblingsNodesWidth(siblingsNodeDatas);
            let allSiblingsNodesPosition = this.getNodeInNodesPosition(siblingsNodeDatas, data);
            // let leftNodeWidth = 0;
            // let dataTreeWidth = this.getTreeWidth(this.getNodeById(data.id));
            let horizontalSpacee = data.type == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee;
            if (allSiblingsNodesPosition > 0) {
                let preNode = this.getNodeById(siblingsNodeDatas[allSiblingsNodesPosition - 1].id);
                if (node.value.x <= 0 || node.value.y <= 0) {
                    node.value.x = 10000;
                    node.value.y = 10000;
                }
                let originalX = node.value.x;
                let originalY = node.value.y;
                let layout = this.resetNodeLayout(node);

                let nodeRect = new NodesRectCalculation().calcule(node);
                let preRect = new NodesRectCalculation().calcule(preNode);
                data.x = preRect.x + preRect.width() + horizontalSpacee + (nodeRect.width() - (nodeRect.x + nodeRect.width() - node.value.x));
                
                if (data.layout == NodeLayoutType.LAYOUT_FORM || data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    let nodeHeight = this.getTreeHeight(node);
                    data.y = parentNodeData.y - nodeHeight - (data.type == MindElementType.SON_SUBJECT ? this.SonNodeVerticalSpacee : this.NodeVerticalSpacee);
                } else {
                    data.y = parentNodeData.y - this.getBrotherNodeFloatExplainMaxHeight(parentNode) - data.height - (data.type == MindElementType.SON_SUBJECT ?  this.SonNodeVerticalSpacee : this.NodeVerticalSpacee);
                }

                let lineData = this.getLineData(node);
                if (node.value.type == MindElementType.SUBJECT &&
                    !lineData.isEmpty() && lineData.lineContent != null &&
                    lineData.lineContent.lineThicken &&
                    (this.globalLayout == LineLayout.RIGHT_ANGLE_LINE ||
                        this.globalLayout == LineLayout.RIGHT_ANGLE_CORNER_LINE ||
                        this.globalLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE)) {
                    data.y -= this.UiUtil.dip2px(lineData.lineContent.lineWidth * 3.5);
                }
                this.resetLayoutData(layout, node, node.value.x - originalX, node.value.y - originalY);
            } else {
                let parentNodeCenterX = parentNodeData.x + parentNodeData.width / 2;
                let allSiblingsNodesWidth = this.getSiblingsNodesWidth(siblingsNodeDatas);
                if (siblingsNodeDatas.length > 1) {
                    let dataTreeLeftWidth = this.getTreeLeftWidth(node);
                    let siblingsLastNodeRightWidth  = this.getTreeRightWidth(this.getNodeById(siblingsNodeDatas[siblingsNodeDatas.length- 1].id));
                    let nodeX = parentNodeCenterX - (allSiblingsNodesWidth - dataTreeLeftWidth - siblingsLastNodeRightWidth) / 2;
                    
                    if (data.layout == NodeLayoutType.LAYOUT_FORM || data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                        let nodeHeight = this.getTreeHeight(node);
                        data.x = nodeX - dataTreeLeftWidth;
                        data.y = parentNodeData.y - nodeHeight - 
                            (data.type == MindElementType.SON_SUBJECT ?  this.SonNodeVerticalSpacee : this.NodeVerticalSpacee);
                    } else {
                        data.x = nodeX - data.width/2;
                        data.y = parentNodeData.y - data.height - this.getBrotherNodeFloatExplainMaxHeight(parentNode) - 
                            (data.type == MindElementType.SON_SUBJECT ?  this.SonNodeVerticalSpacee : this.NodeVerticalSpacee);
                    }
                    let lineData = this.getLineData(node);
                    if (node.value.type == MindElementType.SUBJECT &&
                            !lineData.isEmpty() && lineData.lineContent != null &&
                            lineData.lineContent.lineThicken &&
                            (this.globalLayout == LineLayout.RIGHT_ANGLE_LINE ||
                                this.globalLayout == LineLayout.RIGHT_ANGLE_CORNER_LINE ||
                                this.globalLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE)) {
                        data.y -= this.UiUtil.dip2px(lineData.lineContent.lineWidth * 3.5);
                    }
                } else {
                    if (data.layout == NodeLayoutType.LAYOUT_FORM || data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                        let nodeWidth = this.getTreeWidth(node);
                        let nodeHeight = this.getTreeHeight(node);
                        data.x = parentNodeCenterX - nodeWidth/2;
                        data.y = parentNodeData.y - nodeHeight - 
                            (data.type == MindElementType.SON_SUBJECT ?  this.SonNodeVerticalSpacee : this.NodeVerticalSpacee);
                    } else {
                        data.x = parentNodeCenterX - data.width/2;
                        data.y = parentNodeData.y - this.getBrotherNodeFloatExplainMaxHeight(parentNode) - data.height - 
                            (data.type == MindElementType.SON_SUBJECT ? this.SonNodeVerticalSpacee : this.NodeVerticalSpacee);
                    }
                    let lineData = this.getLineData(node);
                    if (node.value.type == MindElementType.SUBJECT &&
                        !lineData.isEmpty() && lineData.lineContent != null &&
                        lineData.lineContent.lineThicken &&
                        (this.globalLayout == LineLayout.RIGHT_ANGLE_LINE ||
                            this.globalLayout == LineLayout.RIGHT_ANGLE_CORNER_LINE ||
                            this.globalLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE)) {
                        data.y -= this.UiUtil.dip2px(lineData.lineContent.lineWidth * 3.5);
                    }
                }
            }
        }
        this.setBorderThicken(node);
        this.setElementLine(node, isChange);
    }

    setElementLine(node, isChange) {
        let data = node.value;
        if (data.isHidden || this.isRootNode(node)) {
            return;
        }
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        if (!this.isGeneralizatioData(data) && (parentNodeData.isEmpty() || parentNodeData.layout != NodeLayoutType.LAYOUT_TOP)) {
            return;
        }

        if (parentNodeData.id != IdGenerator.INVALID_ID) {
            let isCreateLineData = false;
            let lineData = this.getLineData(node);
            lineData.parentNodeId = parentNodeData.id;
            let lineLayout = this.getNodeLineLayout(node, lineData);
            if (lineData.id == IdGenerator.INVALID_ID) {
                lineData.id = IdGenerator.shared.getId();
                this.lineMindElementDataDict.put(lineData.id, lineData);
                this.textElementLineMindElementDataDict.put(node.value.id, lineData);
                isCreateLineData = true;
            }
            lineData.isHidden = false;
            lineData.layout = NodeLayoutType.LAYOUT_TOP;
            if (data.type == MindElementType.SUBJECT || 
                data.type == MindElementType.CONTENT_GENERALIZATION) {
                let parentCentreX = parentNodeData.x + parentNodeData.width / 2;

                if (lineLayout == LineLayout.CURVE_LINE_AVERAGE) {
                    let count = parentNode.children.length;
                    let cellSpace = parentNodeData.width / (count + 1);
                    cellSpace = Math.min(cellSpace, this.UiUtil.dip2px(15));
                    let top = (parentNodeData.width - cellSpace * (count-1))/2;
                    for (let index = 0; index < count; index++) {
                        if (parentNode.children[index].value.id == data.id) {
                            parentCentreX = parentNodeData.x + (index) * cellSpace + top;
                            break;
                        }
                    }
                } else {
                    parentCentreX = parentNodeData.x + parentNodeData.width / 2;
                }

                let dataCentreX = data.x + data.width / 2;
                lineData.type = MindElementType.LINE;
                if (data.layout == NodeLayoutType.LAYOUT_FORM || data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    let nodeWidth = this.getTreeWidth(node);
                    let nodeHeight = this.getTreeHeight(node);
                    dataCentreX = data.x + nodeWidth / 2;
                    if (lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_LINE ||
                            lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE) {
                        lineData.height = parentNodeData.y + parentNodeData.height / 2 - data.y - nodeHeight;
                    } else {
                        lineData.height = parentNodeData.y - data.y - nodeHeight;
                    }
                    lineData.y = data.y + nodeHeight;
                } else {
                    if (lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_LINE ||
                        lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE) {
                        lineData.height = parentNodeData.y + parentNodeData.height / 2 - data.y - data.height;
                    } else {
                        lineData.height = parentNodeData.y - data.y - data.height;
                    }
                    lineData.y = data.y + data.height;
                }
                
                if (parentCentreX > dataCentreX) {
                    lineData.x = dataCentreX;
                } else {
                    lineData.x = parentCentreX;
                }
                lineData.width = parentCentreX > dataCentreX ? (parentCentreX - dataCentreX) : (dataCentreX - parentCentreX);
                let startPoint;
                let endPoint;
                if (parentCentreX > dataCentreX) {
                    startPoint = new Point(lineData.width, lineData.height);
                    endPoint = new Point(0, 0);
                } else {
                    startPoint = new Point(0, lineData.height);
                    endPoint = new Point(lineData.width, 0);
                }
                if (lineData.lineContent == null) {
                    lineData.lineContent = new LineElementContent(startPoint, endPoint, 0x333333, data.id);
                } else {
                    lineData.lineContent.setStartPoint(startPoint);
                    lineData.lineContent.setEndPoint(endPoint);
                }
                lineData.lineContent.lineLayout = lineLayout;
                lineData.lineContent.orientation = LineOrientation.TOP;
            } else {
                let parentCentreX = parentNodeData.x + parentNodeData.width / 2;
                let dataCentreX = data.x + data.width / 2;
                lineData.type = MindElementType.SON_LINE;
                if (data.layout == NodeLayoutType.LAYOUT_FORM || data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    let nodeWidth = this.getTreeWidth(node);
                    let nodeHeight = this.getTreeHeight(node);
                    dataCentreX = data.x + nodeWidth/2;
                    lineData.y = data.y + nodeHeight;
                } else {
                    lineData.y = data.y + data.height;
                }
                if (lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE ||
                    lineLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE) {
                    lineData.y = lineData.y + 5
                }
                if (parentCentreX > dataCentreX) {
                    lineData.x = dataCentreX;
                } else {
                    lineData.x = parentCentreX;
                }

                lineData.width = parentCentreX > dataCentreX ? (parentCentreX - dataCentreX) : (dataCentreX - parentCentreX);
                lineData.height = parentNodeData.y - lineData.y;
                let startPoint;
                let endPoint;
                if (parentCentreX > dataCentreX) {
                    startPoint = new Point(lineData.width, lineData.height);
                    endPoint = new Point(0, 0);
                } else {
                    startPoint = new Point(0, lineData.height);
                    endPoint = new Point((lineData.width), 0);
                }

                let curveStartPoint = startPoint;
                let curveEndPoint = endPoint;
                let straightEndPoint = endPoint;

                if (lineData.lineContent == null) {
                    lineData.lineContent = new LineElementContent();
                    lineData.lineContent.setLineElementContent(curveStartPoint, curveEndPoint, 0x333333, data.id, straightEndPoint);
                } else {
                    lineData.lineContent.setCurveStartPoint(curveStartPoint);
                    lineData.lineContent.setCurveEndPoint(curveEndPoint);
                    lineData.lineContent.setStraightEndPoint(straightEndPoint);
                }

                lineData.lineContent.lineLayout = lineLayout;
                lineData.lineContent.orientation = LineOrientation.TOP;
            }

            if (isCreateLineData) {
                // let lineColorMode = new LineColorMode();
                let lineNodeContent = lineData.lineContent;

                let referenceLine = this.getReferenceLine(node.value.id);
                if (!referenceLine.isEmpty()) {
                    if (this.isUnderline(data) && this.isBorderColor(data)) {
                        lineNodeContent.color = data.borderColor;
                    } else {
                        lineNodeContent.color = referenceLine.lineContent.color;
                    }
                    lineNodeContent.lineWidth = referenceLine.lineContent.lineWidth;
                    lineNodeContent.dottedLine = referenceLine.lineContent.dottedLine;
                    lineData.isShadow = referenceLine.isShadow;
                } else {
                    lineNodeContent.color = node.value.styleColor;
                    if (this.isUnderline(data)) {
                        lineNodeContent.lineWidth = data.borderWidth;
                    } else {
                        lineNodeContent.lineWidth = this.mindMapStyleColor.getSubjectLineWidth();
                    }
                }
                if (lineData.type == MindElementType.LINE && !this.settingData.lineColor.isEmpty()) {
                    // let parentNodeChildrenSize = parentNode.children.length;
                    // let lineColorLength = this.settingData.lineColor.length;
                    // for (let index = 0; index < parentNodeChildrenSize; index++) {
                    //     if (parentNode.children[index].value.id == node.value.id) {
                    //         let colorPoint = index % lineColorLength;
                    //         lineNodeContent.color = this.settingData.lineColor[colorPoint];
                    //         break;
                    //     }
                    // }
                    let colorPoint = (parentNode.children.length - 1) % this.settingData.lineColor.length;
                    lineNodeContent.color = this.settingData.lineColor[Math.abs(colorPoint)]
                }
                lineData.backgroundColor = Colors.clear;
                lineData.borderColor = Colors.clear;
                lineData.borderWidth = 0;
            }
            this.setLineThicken(lineData);
        }
    }

    getBrotherNodeFloatExplainMaxHeight( node) {
        let parentNode = this.getNodeById(node.value.parentNodeId);
        if (parentNode.isEmpty()) {
            return 0;
        }
        let maxHeinght = 0;
        let parentNodeChildrenCount = parentNode.children.length;
        for(var index = 0; index < parentNodeChildrenCount; index++){
            let child = parentNode.children[index];
            let height = this.getNodeFloatExplainHeight(child);
            maxHeinght = Math.max(height, maxHeinght);
        }
        return maxHeinght;
    }

    setGeneralizationElementsPoint(isChange) {
        let list = this.getGeneralizationMindElementDatas();
        let listCount = list.length;
        for (let index = 0; index < listCount; index++) {
            let node = list[index];

            if (node.value.isHidden ||
                !this.isIncludeGeneralizationInCurrentLayout(node) ||
                this.isLayout(node.value)) {
                continue;
            }
            let mindElementData = node.value;
            let generalizationContent = mindElementData.generalizationContent;
            let top = 1000000;
            let bottom = 0;
            let right = 0;
            let left = 1000000;
            let encircleSpace = 0;
            let targetNodes = []
            for (let targetIndex = 0; targetIndex < generalizationContent.targetIds.length; targetIndex++) {
                let targetNode = this.getNodeById(generalizationContent.targetIds[targetIndex]);

                if (targetNode.isEmpty() || targetNode.value.isHidden) {
                    mindElementData.isHidden = true;
                    continue
                }
                targetNodes.push(targetNode)
                for (let j = 0; j < index; j++) {
                    let checkNode = list[j];
                    if (checkNode.value.isHidden) {
                        continue
                    }
                    if (checkNode.value.generalizationContent.targetIds.length == 0) {
                        continue
                    }
                    let checkTargetNode = this.getNodeById(checkNode.value.generalizationContent.targetIds[0]);
                    if (!checkTargetNode.isEmpty() && targetNode.isChildNode(checkTargetNode)) {
                        targetNodes.push(checkNode)
                    }
                }
            }
            for (let targetIndex = 0; targetIndex < targetNodes.length; targetIndex++) {
                let targetNode = targetNodes[targetIndex];
                if (targetNode.isEmpty() || targetNode.value.isHidden) {
                    continue;
                }
                let targetTop = targetNode.getNodeTop();
                let targetBottom = targetNode.getNodeBottom();
                let targetRight = targetNode.getNodeRight();
                let targetLeft = targetNode.getNodeLeft();
                if (targetTop < top) {
                    top = targetTop;
                }
                if (targetBottom > bottom) {
                    bottom = targetBottom;
                }
                if (targetRight > right) {
                    right = targetRight;
                }
                if (left > targetLeft) {
                    left = targetLeft;
                }
                let space = this.getEncircleSpaceAndPadding(targetNode.value)
                if (space > 0) {
                    encircleSpace = Math.max(space, encircleSpace)
                }
            }
            if (top == 1000000 && right == 0) {
                continue;
            }
            generalizationContent.targetHeight = bottom - top;
            generalizationContent.targetWidth = right - left;
            mindElementData.y = top - Config.GeneralizationLineWidth - Config.GeneralizationSpacing * 2 - mindElementData.height - encircleSpace;
            mindElementData.x = left + (right - left - mindElementData.width) / 2;
            this.setGeneralizationElementLine(node, isChange);
            let nodeChildrenLength = node.children.length;
            for (let childIndex = 0; childIndex < nodeChildrenLength; childIndex++) {
                let child = node.children[childIndex]
                this.setNodeElementsPoint(child, isChange);
            }
        }
    }

    setSonElementSytle(parentNode) {
        if (parentNode.children.length == 0) {
            return;
        }

        for (let index = 0; index < parentNode.children.length; index++) {
            let node = parentNode.children[index];
            node.value.backgroundColor = parentNode.value.styleFillColor;
            node.value.borderColor = parentNode.value.styleColor;
            if (node.value.borderWidth == 0) {
                node.value.borderWidth = new LineColorMode().subjectBorderWidth;
            }
            this.setSonElementSytle(node);
        }
    }

    setGeneralizationElementLine(node, isChange) {
        if (node.value.isHidden) {
            return;
        }
        let mindElementData = node.value;
        let generalizationContent = mindElementData.generalizationContent;
        let isCreateLineData = false;
        let lineData = this.getGeneralizationLineData(node);
        if (!isChange || lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            isCreateLineData = true;
        }

        lineData.parentNodeId = mindElementData.id;
        lineData.type = MindElementType.LEFTBRACELINE;
        lineData.y = mindElementData.y + Config.GeneralizationSpacing + mindElementData.height;
        lineData.x = mindElementData.x - (generalizationContent.targetWidth - mindElementData.width) / 2;
        lineData.width = generalizationContent.targetWidth;
        lineData.height = Config.GeneralizationLineWidth;

        let startPoint = new Point(0, lineData.height);
        let endPoint = new Point((lineData.width), lineData.height);

        if (lineData.lineContent == null) {
            lineData.lineContent = new LineElementContent(startPoint, endPoint, mindElementData.borderColor, mindElementData.id);
        } else {
            lineData.lineContent.setStartPoint(startPoint);
            lineData.lineContent.setEndPoint(endPoint);
        }

        lineData.lineContent.orientation = LineOrientation.TOP;
        this.generalizationLineMindElementDataDict.put(lineData.id, lineData);

        if (isCreateLineData) {
            lineData.backgroundColor = Colors.clear;
            lineData.borderWidth = 0;
            lineData.borderColor = Colors.clear;
            lineData.lineContent.color = mindElementData.borderColor == Colors.clear ? mindElementData.styleColor : mindElementData.borderColor;
        }
    }

    getTreeWidth(node) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (!this.isRootNode(node) && node.value.layout != NodeLayoutType.LAYOUT_BOTTOM) {
            let layout = this.getNodeLayout(node);
            let width = layout.getTreeWidth(node, true);
            layout.clearDatas();
            return width;
        }
        return this.getTreeLeftWidth(node) + this.getTreeRightWidth(node);
    }

    getTreeLeftWidth( node) {
        if (this.dataLeftWidthMap.containsKey(node.value.id)) {
            return this.dataLeftWidthMap.get(node.value.id);
        }
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (this.isRootNode(node) && node.children.length != this.rootTreeNode.children.length) {
            node = this.getNodeById(node.value.id);
        }

        let nodeSelfLeft = node.value.width / 2;

        let generalizationNode = this.getNodeGeneralization(node);
        if (!generalizationNode.isEmpty() &&
                (generalizationNode.value.generalizationContent.targetIds.isEmpty() ||
                        generalizationNode.value.generalizationContent.targetIds[0] == node.value.id)) {
        }
        if (node.children.isEmpty() || node.children[0].value.isHidden) {
            this.dataLeftWidthMap.put(node.value.id, nodeSelfLeft);
            return nodeSelfLeft;
        }
        if (!this.isRootNode(node) &&
                node.value.layout != NodeLayoutType.LAYOUT_TOP &&
                !node.children.isEmpty() && !node.children[0].value.isHidden) {
            let layoutLeft = nodeSelfLeft;
            if (node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) {
                layoutLeft = nodeSelfLeft;
            } else if (node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                let layout = this.getNodeLayout(node);
                let treeWidth = layout.getTreeWidth(node);
                if (treeWidth <= node.value.width) {
                    layoutLeft = nodeSelfLeft;
                } else {
                    layoutLeft = treeWidth - node.value.width/2;
                }
            } else if (node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT) {
                let layout = this.getNodeLayout(node);
                let treeWidth = layout.getLeftTreeWidth(node);
                if (treeWidth <= node.value.width) {
                    layoutLeft = nodeSelfLeft;
                } else {
                    layoutLeft = treeWidth - node.value.width/2;
                }
            } else if (node.value.layout == NodeLayoutType.LAYOUT_FORM || node.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                let layout = this.getNodeLayout(node);
                let treeWidth = layout.getTreeWidth(node);
                layoutLeft = treeWidth/2;
            }
            this.dataLeftWidthMap.put(node.value.id, layoutLeft);
            return layoutLeft;
        }
        let childCount = node.children.length;
        let firstLeft = this.getTreeLeftWidth(node.children[0]);
        if (childCount == 1) {
            let leftWidth = Math.max(nodeSelfLeft, firstLeft);
            this.dataLeftWidthMap.put(node.value.id, leftWidth);
            return leftWidth;
        }

        let childTotle = 0;
        for (var index = 0; index < childCount; index++) {
            let cell = node.children[index];
            let width = this.getTreeWidth(cell);
            childTotle += width;
            if (index < childCount - 1) {
                childTotle += cell.value.type == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee;
            }
        }
        let lastRight = this.getTreeRightWidth(node.children[childCount - 1]);
        let width = (childTotle - firstLeft - lastRight) / 2 + firstLeft;
        let leftWidth = Math.max(width, nodeSelfLeft);
        this.dataLeftWidthMap.put(node.value.id, leftWidth);
        return leftWidth;
    }

    getTreeRightWidth( node) {
        if (this.dataRightWidthMap.containsKey(node.value.id)) {
            return this.dataRightWidthMap.get(node.value.id);
        }
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (this.isRootNode(node) && node.children.length != this.rootTreeNode.children.length) {
            node = this.getNodeById(node.value.id);
        }

        let nodeSelfRigth = node.value.width / 2;

        let generalizationNode = this.getNodeGeneralization(node);
        if (!generalizationNode.isEmpty() &&
                (generalizationNode.value.generalizationContent.targetIds.isEmpty() ||
                        generalizationNode.value.generalizationContent.targetIds[0] == node.value.id)) {
//            nodeSelfRigth = Math.max(nodeSelfRigth, getTreeRightWidth(generalizationNode));
        }
        if (node.children.isEmpty() || node.children[0].value.isHidden) {
            this.dataRightWidthMap.put(node.value.id, nodeSelfRigth);
            return nodeSelfRigth;
        }
        if (!this.isRootNode(node) &&
                node.value.layout != NodeLayoutType.LAYOUT_TOP &&
                !node.children.isEmpty() && !node.children[0].value.isHidden) {
            let layoutRigth = nodeSelfRigth;
            if (node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                layoutRigth = nodeSelfRigth;
            } else if (node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) {
                let layout = this.getNodeLayout(node);
                let treeWidth = layout.getTreeWidth(node);
                if (treeWidth <= node.value.width) {
                    layoutRigth = nodeSelfRigth;
                } else {
                    layoutRigth = treeWidth - node.value.width/2;
                }
            } else if (node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT) {
                let layout = this.getNodeLayout(node);
                let treeWidth = layout.getRightTreeWidth(node);
                if (treeWidth <= node.value.width) {
                    layoutRigth = nodeSelfRigth;
                } else {
                    layoutRigth = treeWidth - node.value.width/2;
                }
            } else if (node.value.layout == NodeLayoutType.LAYOUT_FORM || node.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                let layout = this.getNodeLayout(node);
                let treeWidth = layout.getTreeWidth(node);
                layoutRigth = treeWidth/2;
            }
            this.dataRightWidthMap.put(node.value.id, layoutRigth);
            return layoutRigth;
        }
        let childCount = node.children.length;
        let firstLeft = this.getTreeRightWidth(node.children[0]);
        let firstRight = this.getTreeRightWidth(node.children[0]);
        if (childCount == 1) {
            let rightWidth = Math.max(nodeSelfRigth, firstRight);
            this.dataRightWidthMap.put(node.value.id, rightWidth);
            return rightWidth;
        }

        let childTotle = 0;
        for (let index = 0; index < childCount; index++) {
            let cell = node.children[index];
            let width = this.getTreeWidth(cell);
            childTotle += width;
            if (index < childCount - 1) {
                childTotle += cell.value.type == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee;
            }
        }
        let lastRight = this.getTreeLeftWidth(node.children[childCount - 1]);
        let width = (childTotle - firstLeft - lastRight) / 2 + lastRight;
        let rightWidth = Math.max(width, nodeSelfRigth);
        this.dataRightWidthMap.put(node.value.id, rightWidth);
        return rightWidth;
    }

    getTreeHeight(node) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (!this.isRootNode(node) && node.value.layout != NodeLayoutType.LAYOUT_TOP) {
            let layout = this.getNodeLayout(node);
            return layout.getTreeHeight(node);
        }
        let height = this.getNodeHeight(node.value);
        let nodeGeneralizationHeight = this.getNodeGeneralizationHeight(node);
        nodeGeneralizationHeight = nodeGeneralizationHeight > 0 ? nodeGeneralizationHeight + Config.GeneralizationLineWidth + Config.GeneralizationSpacing * 2 : 0;
        height = height + nodeGeneralizationHeight;
        let siblingsMaxHeight = 0;

        let childrenLength = node.children.length;
        for (let index = 0; index < childrenLength; index++) {
            let chlid = node.children[index];
            let chlidHeight = 0;
            let verticalSpacee = chlid.value.type == MindElementType.SON_SUBJECT ?
                this.SonNodeVerticalSpacee : this.NodeVerticalSpacee;
            let lineData = this.getLineData(chlid);
            if (chlid.value.type == MindElementType.SUBJECT &&
                !lineData.isEmpty() && lineData.lineContent != null &&
                lineData.lineContent.lineThicken &&
                (this.globalLayout == LineLayout.RIGHT_ANGLE_LINE ||
                    this.globalLayout == LineLayout.RIGHT_ANGLE_CORNER_LINE ||
                    this.globalLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE)) {
                verticalSpacee += UiUtil.dip2px(lineData.lineContent.lineWidth * 3.5);
            }
            if (!chlid.children.isEmpty()) {
                chlidHeight += (this.getTreeHeight(chlid) + verticalSpacee);
            } else {
                if (chlid.value.isHidden) {
                    continue;
                }
                let nodeHeight = this.getNodeHeight(chlid.value);
                let chlidGeneralizationHeight = this.getNodeGeneralizationHeight(chlid);
                chlidGeneralizationHeight = chlidGeneralizationHeight > 0 ?
                    chlidGeneralizationHeight + Config.GeneralizationLineWidth + Config.GeneralizationSpacing * 2 : 0;
                nodeHeight = nodeHeight + chlidGeneralizationHeight;
                chlidHeight += nodeHeight + verticalSpacee;
            }

            if (chlidHeight > siblingsMaxHeight) {
                siblingsMaxHeight = chlidHeight;
            }
        }
        return siblingsMaxHeight + height;
    }

    getEncircleLeftWidth(data) {
        let encircleMindElementDataDictArr = this.encircleMindElementDataDict.keys();

        for (let index = 0; index < encircleMindElementDataDictArr.length; index++) {
            let key = encircleMindElementDataDictArr[index];
            let mind = this.encircleMindElementDataDict.get(key);
            if (mind.lineContent == null) {
                return 0;
            }
            let node = this.getNodeById(mind.parentNodeId);
            if (node.isEmpty()) {
                continue;
            }
            let targetIds = new Array();
            if (mind.lineContent != null) {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                targetIds.push(mind.parentNodeId);
            }
            for (let index = 0; index < targetIds.length; index++) {
                let id = targetIds[index];
                if (id == data.id) {
                    if (this.isTopNode(id, targetIds)) {
                        return new EncircleNodesPointsCalculation().space + mind.lineContent.padding;
                    } else {
                        return 0
                    }
                }
            }
        }
        return 0;
    }

    getEncircleRightWidth(data) {
        let encircleMindElementDataDictArr = this.encircleMindElementDataDict.keys();
        for (let index = 0; index < encircleMindElementDataDictArr.length; index++) {
            let key = encircleMindElementDataDictArr[index];
            let mind = this.encircleMindElementDataDict.get(key);
            if (mind.lineContent == null) {
                return 0;
            }
            let node = this.getNodeById(mind.parentNodeId);
            if (node.isEmpty()) {
                continue;
            }
            let targetIds = new Array();
            if (mind.lineContent != null) {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                targetIds.push(mind.parentNodeId);
            }
            for (let index = 0; index < targetIds.length; index++) {
                let id = targetIds[index];
                if (id == data.id) {
                    if (this.isBottomNode(id, targetIds)) {
                        return new EncircleNodesPointsCalculation().space + mind.lineContent.padding;
                    } else {
                        return 0;
                    }
                }
            }
        }
        return 0;
    }
    getSiblingsNodesWidth(nodes) {
        if (nodes.length == 0) {
            return 0;
        }

        let width = this.getTreeWidth(this.getNodeById(nodes[0].id));
        if (nodes.length> 1) {
            for (var index = 1; index < nodes.length; index++) {
                let data = nodes[index];
                if (data.isHidden) {
                    continue;
                }
                width += this.getTreeWidth(this.getNodeById(data.id)) + (data.type == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee);
            }
        }
        return width;
    }

    getLayoutType() {
        return NodeLayoutType.LAYOUT_TOP;
    }
}

export default TopLayout