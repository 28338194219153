<template>
  <div class="login-content-box">
    <div class="login-right">
      <div class="row">
        <div v-if="spinning" class="loading-box">
          <a-spin
            class="loading-child"
            tip="Loading..."
            :spinning="spinning"
            :delay="500"
          >
          </a-spin>
        </div>
        <div v-else class="login-content">
          <div class="login" v-if="showUrlRaffirm && codeUrl">
            <div class="code-box">
              <div id="qrCode" ref="qrCodeDiv">
                <img class="qrcodeIco" id="qrCodeIco" :src="codeUrl" alt="加载失败">
              </div>
              
              <!-- <div class="center-box">
                <img
                  id="qrCodeIco"
                  src="../../../assets/img/code/logobai.png"
                  alt=""
                />
              </div> -->
              <!-- <vue-qr text="codeUrl" :logoSrc="imageUrl" id="qrCode" ref="qrCodeDiv" ></vue-qr> -->
            </div>
             <p class="code-bottom-text">{{thirdPartyCode.notice}}</p>
          </div>
          <div id="qrCode" v-else ref="qrCodeDiv">
                加载失败
          </div>
          <p class="r-overdue ss" v-if="showStaleDated">
            <span>
              <!-- {{getString(strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click)}} -->
              <!-- internationalization -->
              二维码已失效
            </span>
            <a href="javascript:;" @click="getGouqi()">{{getString(strings.User_Lolgin_Refresh)}}</a>
          </p> 

          <div class="rr-affirm" v-if="showRaffirm">
            <div>
              <img src="../../../assets/img/code/affirm0saoma.png" alt="" />
            </div>
            <p class="raffzi" style="margin: 10% 0px">
              <!-- internationalization -->
              扫描成功
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import vueQr from "vue-qr";
import { mapMutations } from "vuex";
import {
  postUserQrcodeGet,
  postUserQrcodeStatus,
  postUserMe,

  postUserMpwxLoginQrcode,
  postUserThirdLogin,
} from "../../../common/netWork/base_api";
import { doLogin, setJwt } from "../../../common/netWork/base";
import QRCode from "qrcodejs2";
import sign from "../../../assets/css/login/sign.less";
import strings from "../../../common/lang/strings";
import getString from "../../../common/lang/language";
export default {
  name: "Login",
  inject: ['reload'],
  props:{
     currentThirdPartyCode: {
      type: Object,
      defualt() {
        return {};
      },
    },
  },
  data() {
    return {
      spinning: true, //是否展示加载动画
      confirmDirty: false,
      autoCompleteResult: [],
      ShowCode: true,
      imageUrl: require("../../../assets/img/code/logobai.png"),
      code: "", //二维码对象
      codeUrl: "", //网址
      showUrlRaffirm: true,
      showStaleDated: false, //二维码过期
      showRaffirm: false, //扫描成功
      strings: strings,
    };
  },
  components: {
    // vueQr,
  },
  computed:{
    thirdPartyCode(){
      return this.currentThirdPartyCode;
    }
  },
  created() {},
  mounted: function () {
    // this.getCode()
    this.$nextTick(() => {
      this.getCode();
      // this.bindQRCode();
    });
  },
  methods: {
    ...mapMutations(["showLoginModel","setRefreshMyGroupList"]),
    getString(i) {
      return getString(i);
    },
    bindQRCode(url) {
      return new QRCode(this.$refs.qrCodeDiv, {
        text: url,
        width: 200,
        height: 200,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        // correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
      });
      //控制logo图标的位置
      // var margin = ($("#qrCode").height() - $("#qrCodeIco").height()) / 2;
      // $("#qrCodeIco").css("margin", margin);
    },
    userMessage(obj = {}) {
      let that = this;
      return new Promise(function (resolve, reject) {
        postUserMe(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
          }
        );
      });
    },
    rollPoling(url) {
      
      const params ={
            type:this.currentThirdPartyCode.type,
            accessToken: url, 
            avatar:'',
            username:'',
            openId:'',
          }
      // this.bus.$emit("BindingMobileInfo", params);
      sessionStorage.setItem('BindingMobileInfo',JSON.stringify(params))
      // console.log(params);
      let timer = setInterval(() => {
        postUserThirdLogin(
          params,
          (data) => {
            if  (!!data) {
              if (localStorage.getItem('accept')=='false') {
                this.$message.error('请同意服务条款和隐私政策'); // <!-- internationalization
                return false
              }   
              // this.showRaffirm = true;
              // this.showUrlRaffirm = false;
              clearInterval(timer);
              setJwt(data.jwt);
              // setTimeout(() => {
                this.$message.success(this.currentThirdPartyCode.label+getString(strings.Message_Tips_Scan_Code_Login_Success));
                this.userMessage().then((res) => {
                  doLogin(res);
                  this.showLoginModel(false);
                  this.$emit("ShowHome", false);
                  // if(this.$route.path.includes('Home')){
                  //   this.$router.push("/Home/MyMind");
                  // }
                  this.$emit('loginSucceededFun');
                
                });
              // }, 2000);

              return;
            } 
             
          },
          (error) => {
            // error.code = 69
            if (error.code == 160) {
             //等待扫码
              // this.thirdPartyCode.notice = error.desc;
            }else if (error.code == 20) {
              if (localStorage.getItem('accept')=='false') {
                this.$message.error('请同意服务条款和隐私政策'); // <!-- internationalization
                return false
              }   
              // this.$message.warring('账户未绑定手机号，请绑定手机号');//internationalization 
              let headPortraitIndex = error.desc.indexOf(':');
              let userNameIndex = error.desc.indexOf(',');
              // console.log(headPortraitIndex,userNameIndex);
              this.$emit('getThirdPartyLoginUser',{headPortrait:error.desc.slice(headPortraitIndex+1,userNameIndex),userName:error.desc.slice(userNameIndex+1)})
              clearInterval(timer);
              this.ClickLoginShow()
              /*this.showRaffirm = true;
              this.showUrlRaffirm = false;
              setTimeout(() => {
                this.ClickLoginShow()
              }, 3000);*/
            }else if (error.code == 69) {
              this.showStaleDated = true;
              //二维码过期
              clearInterval(timer);
              return
            }
          }
        );
      }, 3000);
      this.timer = timer;
    },
    getCode() {
      this.spinning = true;
      postUserMpwxLoginQrcode(
        {},
        (data) => {
          this.codeUrl = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket='+encodeURI(data.ticket);
          this.spinning = false;
          // this.bindQRCode(this.codeUrl);
          this.rollPoling(data.ticket);
        },
        (error) => {
          this.codeUrl = "";
          this.spinning = false;
          this.$message.error(error.desc);
        }
      );
    },
    // 二维码界面
    ClickShowCode() {
      //去除轮询
      clearInterval(this.timer);
      this.$emit("ClickShowCode", this.ShowCode);
    },
    // 切换绑定手机号
    ClickLoginShow() {
      this.$emit("ClickLoginShow", "BindingMobile");
    },
    //刷新二维码
    getGouqi() {
      clearInterval(this.timer);
      this.getCode();
      this.showStaleDated = false;
    },
  },
};
</script>

<style scoped>
.Login-content {
  /* background-image: url(../../assets/img/page/reg_bg_min.jpg); */
  background-size: cover;
  /* width: 1900px; */
  min-height: 1920px;
  min-height: 99vh;
}
.hello {
  width: 600px;
  min-width: 800px;
  min-height: 99.9vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-content-box {
  position: relative;
  border-radius: 0px 8px 8px 0px;
  display: flex;
  justify-content: center;
  height: 505px;
  margin: 0 auto;
  width: inherit;
  position: relative;
  background-color: #ffffff;
}
 
.login-right {
  margin-top: 160px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
.login-right .row {
  width: 363px;
  text-align: center;
  margin-top: -4em;
}

.code-box .qrcodeIco {
  width: 200px;
  height: 200px;
}
.code-box {
  position: relative;
  /* width: 100px; */
  margin: 0 auto;
}
.center-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 68px;
  height: 68px;
  background-color: #fff;
}
#qrCode {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: #000000;
}
.login-content {
  margin: auto;
}
.login {
  height: 100%;
  position: relative;
  margin-top: 2px;
}

/*ant design style*/
.ant-form {
  width: 400px;
}
.Login-Button {
  width: 80%;
}
.checked-box {
  position: relative;
  top: -30px !important;
}
#register_agreement {
  border-color: red;
}

.account-login {
  color: #fd492b;
  font-size: 14px;
  padding-left: 20px;
}
.account-login:hover {
  color: #ff7354;
  font-size: 14px;
}
.acc-ion {
  margin-right: 4px;
}
/*QR code*/
/* .Code-titel {
  width: 100%;
  text-align: center;
} */
/* .Login-code {
  width: 52px;
  height: 52px;
  position: absolute;
  right: 0px;
} */
.code-bottom-text {
  text-align: center;
  color: #666666;
  margin: 8px 0;

}
</style>
<style>
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: red;
  border: none;
  color: rgba(0, 0, 0, 0.8);
}
</style>

<style lang="less" scoped>
@media screen and(min-width:250px) and(max-width:500px) {
  .Login-header {
    display: none;
  }
  .flag-list-box {
    width: 258px !important;
  }
  .login-content-box {
    height: 100%;
  }
  .Login-header {
    width: 100%;
    position: static;
  }
  .hello {
    width: 100%;
    min-width: 0;
    .login-right {
      padding: 0;
      margin-top: 0;
      margin: 0 auto;
      width: 100%;
      .row {
        width: 100%;
      }
      .lright-cont {
        margin: 10px;
      }
    }
  }
  .get-back {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 2;
    font-size: 16px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
 
  .login-content {
    padding-top: 0;
    .login {
      margin-top: 15px;
      .ant-form {
        width: 100%;
        .ant-form-item {
          .ant-col {
            .ant-form-item-control {
              .ant-form-item-children {
                .dr-agroup {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
    .lbtwo {
      padding-top: 0px;
      height: 23px;
    }
  }
  .Login-Button {
    height: 48px;
    margin-top: 11px;
    width: 100%;
  }
  
}

/* 加载动画开始 */
.loading-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
}
.loading-child {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) ;
}
.ant-spin-dot-item {
  background-color: #fd492b;
}
.ant-spin {
  color: #fd492b;
}
.ant-spin .ant-spin-dot-item {
  background-color: #fd492b !important;
}
/* 加载动画结束 */
</style>