// package mind.yushu.com.mindmap.viewmodel.datatype;

// import com.google.gson.annotations.SerializedName;

/**
 * ProjectName: MindMap
 * Created by tony on 2020/8/9
 * Copyright(c) 2020 mindyushu.com
 */

let StyleModelTyle = {
    Normal:1,
    Custom:2

    // StyleModelTyle(value) {
    //     this.value = value;
    // }

    // public static StyleModelTyle from(final int value) {
    //     StyleModelTyle result = Normal;
    //     for (final StyleModelTyle type : values()) {
    //         if (type.getValue() == value) {
    //             result = type;
    //         }
    //     }
    //     return result;
    // }

    // public int getValue() {
    //     return value;
    // }
}
export default StyleModelTyle
