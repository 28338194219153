// package mind.yushu.com.mindmap.viewmodel.datatype;

/**
 * ProjectName: MindMap
 * Created by tony on 2020/6/2
 * Copyright(c) 2020 mindyushu.com
 */

const ViewStatus = {
    Unknown :"",
    DidLoad :"",
    WillAppear:"",
    WillDisappear:""
}

export default ViewStatus