<template>
  <div class="PageCheckBox">
    <div class="content">
      <span>导图中图片是否点击弹出大图</span>
      <a-switch defaultChecked @change="onChange" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    onChange(checked) {
        return false
    },
  },
};
</script>

<style scoped>
  .PageCheckBox{
    line-height: 31px;
    width: 100%;
    margin-left: 1em;
  }
  .PageCheckBox .content{
    width: 100%;
    height: 28px;
    color: #666666;
    font-size: 16px;
  }
  .content button{
    margin-top: 5px;
    float: right;
  }
</style>