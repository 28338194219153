/**
 * Created by tony on 2021/12/22
 */

import Util from "../../../utils/Util";
import CGPoint from "../../../viewmodel/core/base/basedata/CGPoint";

 class PointAndTime {
    constructor() {
        this.point = new CGPoint();
        this.id = -1;
        this.time = -1;
    }

    getPoint() {
        return this.point;
    }

    setPoint(point, id = -1) {
        this.point = point;
        this.id = id;
        this.time = Util.getCurrentTime();
    }

    getId() {
        return this.id;
    }

    setId(id) {
        this.id = id;
    }

    getTime() {
        return this.time;
    }

    setTime(time) {
        this.time = time;
    }

    isJustSetPoint() {
        return Util.getCurrentTime() - this.time < 4;
    }
}
export default PointAndTime