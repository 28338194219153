<template>
  <div class="move-father-box" v-if="showModificationOrder">
    <div class="move-child-box">
      <div class="move-header">
        <div class="crumbs-box">
          <span
            class="mind-element"
            v-for="(item, index) in crumbsArr"
            :key="index"
            @click.stop="getParentFile(moveToArchiveItem, index)"
          >
            {{ item.name }}<a-icon type="right" />
          </span>
        </div>
        <div class="close-button">
          <!-- <a-icon
            type="close"
            class="icon-remove"
            @click="hideModificationOrder"
          /> -->
          <img class="close-button-img"  src="../../../assets/img/popupsImg/colse_new.svg"  @click.stop="hideModificationOrder" />
        </div>
      </div>
      <div class="move-content">
        <div
          class="my-mind-content-1"
          v-if="modificationOrderArchiveData != null"
        >
          <div
            v-for="(item, index) in draggingModificationOrderArchiveData"
            :key="index"
            v-dragging="{
              item: item,
              list: draggingModificationOrderArchiveData,
              group: 'modificationOrder',
            }"
          >
            <div
              :class="{ active: item.id == selectedArvhiveObj.id }"
              class="my-mind-list"
            >
              <!-- <span :style="{color:item.createdColorDecimal}" class="fileiconfont my-mind-icon">&#xe761;</span> -->
              <div class="my-mind-list-text">
                <div class="order">{{ index + 1 }}</div>
                <img
                  class="archive-img"
                  v-if="archiveIcon"
                  src="../../../assets/img/arvhive/arvhive.svg"
                  alt=""
                />
                <img
                  class="archive-img"
                  v-else-if="item.isDir"
                  src="../../../assets/img/arvhive/bookcaseIcon.svg"
                  alt=""
                />
                <img
                  class="archive-img"
                  v-else
                  src="../../../assets/img/arvhive/mindMapIcon.png"
                  alt=""
                />
                <div class="my-mind-main-heading-move">
                  {{ item.name }}
                </div>
                <!-- <div class="my-mind-main-subheading-move">
                                  {{ item.subtitle }}
                              </div> -->
              </div>
              <div class="my-mind-time-move">
                <img
                  class="archive-img"
                  src="../../../assets/img/arvhive/modificationOrder.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="move-footer">
        <div @click.stop="hideModificationOrder()" class="button-class button-class-colse">
          {{ getString(strings.Global_Cancel) }}
        </div>
        <div @click.stop="hideModificationOrder()" class="button-class button-class-ok">
          {{ getString(strings.Global_Ok) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
import Vue from "vue";
import VueDND from "awe-dnd";
Vue.use(VueDND);
import { postMindmapRearrangeArchive } from "../../../common/netWork/mind_map_api";
function ajaxPromise(callback, params, that) {
  return new Promise(function (resolve, reject) {
    callback(
      params,
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
        that.$message.error(error.desc);
      }
    );
  });
}
export default {
  name: "MyMoveFile",
  props: [
    "showModificationOrder",
    "modificationOrderArchiveData",
    "crumbsArr",
    "moveToArchiveItem",
  ],
  data() {
    return {
      strings,
      selectedArvhiveObj: {},
      archiveIcon: true, //  是否显示归档图标
      drageBeforeIndex: -1, //更改位置排序的index
      drage: [], //保存更改排序之前的数据
      drageTo: [], //保存之后排序之前的数据
      draggingModificationOrderArchiveData: [],//拖拽之后修改的数据
      tempModificationOrderArchiveData: [],
    };
  },
  mounted() {
    let that = this;
    this.$dragging.$on("dragged", (value) => {
      that.drage = value.draged;
      that.drageTo = value.to;
      that.drageBeforeIndex = -1;
      let indexDraged = this.tempModificationOrderArchiveData.map(item => item.id).indexOf(value.draged.id);
      let indexTo = this.tempModificationOrderArchiveData.map(item => item.id).indexOf(value.to.id);
      if (indexTo>indexDraged) {//判断向下拖拽
        if(indexTo+1<this.modificationOrderArchiveData.length){//交换数据的下标+1不能大于modificationOrderArchiveData的长度
          that.drageBeforeIndex = this.modificationOrderArchiveData[indexTo+1].index; //drageBeforeIndex为交换数据的下一条数据的index
          // console.log(value,that.drageBeforeIndex);
        }
      } else {
        that.drageBeforeIndex = value.to.index;
      }

    });
    this.$dragging.$on('dragend', (value) => {
      if (!that.drage.id) {
        return;
      }
      const obj = {
        archiveId: that.drage.id,
        beforeIndex: that.drageBeforeIndex,
      };
      // console.log(obj);
      ajaxPromise(postMindmapRearrangeArchive, obj, this).then((res) => {
        this.getParentFile(this.moveToArchiveItem, 1);
      });
    });
  },
  methods: {
    getString(i) {
      return getString(i);
    },
    hideModificationOrder() {
      this.$emit("hideModificationOrder");
    },
    getParentFile(item, index) {
      this.$emit("getParentFile", item, index);
    },
  },
  watch: {
    modificationOrderArchiveData(newVal) {
      this.modificationOrderArchiveData = newVal;
      this.tempModificationOrderArchiveData = JSON.parse(JSON.stringify(newVal));//深拷贝
      this.draggingModificationOrderArchiveData = JSON.parse(JSON.stringify(newVal));
      if (this.modificationOrderArchiveData.length > 0) {
        if (this.modificationOrderArchiveData[0].parentId != "") {
          this.archiveIcon = false;
        } else {
          this.archiveIcon = true;
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.move-father-box {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 300;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);

  .move-child-box {
    width: 448px;
    height: auto;
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .move-child-box .move-header {
    height: 40px;
    line-height: 40px;
    padding-top: 0px;
    padding-left: 20px;
    padding-right: 11px;
    // text-align: center;
    font-size: 16px;
    color: #333333;
    position: relative;
    background: #efefef;
    border-radius: 5px 5px 0 0;
  }
  .move-child-box .move-header .mind-element {
    font-size: 18px;
    /* font-weight: 700; */
    cursor: pointer;
    color: #333;
    font-weight: 550;
  }

  .move-child-box .move-header .mind-element:nth-last-child(1) i {
    display: none;
  }
  .crumbs-box{
    position: absolute;
  }
  .close-button {
    position: absolute;
    right: 11px;
    // .icon-remove {
    //   color: #999999;
    //   font-size: 20px;
    //   transition: 0.3s;
    // }

    // .icon-remove:hover {
    //   color: #fd492b;
    //   transition: 0.3s;
    //   transform: rotate(180deg);
    // }
  }

  .move-child-box .move-content {
    overflow: hidden;
    height: auto;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 20px;
    // padding: 0 20px;
    background: #f1f1f1;
    width: 408px;
    margin-left: 20px;
    &::-webkit-scrollbar {
      width: 3px;
      // display: none;
    }
    &::-webkit-scrollbar-track {
      background: rgb(239, 239, 239);
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #bfbfbf;
    }
  }
  .move-child-box .move-footer {
    display: flex;
    justify-content: center;
  }
  .move-child-box .move-footer .button-class {
    margin: 15px;
    width: 65px;
    height: 30px;
    line-height: 30px;
    border-radius: 2px;
    text-align: center;
    font-size: 16px;
    // background-color: #eeeeee;
    cursor: pointer;
    // color: #999999;
  }
  // .move-child-box .move-footer .button-class:last-child {
  //   background-color: #fd492b;
  //   color: #fff;
  // }
  .button-class-ok{
  background: #FD492B;
  color: #fff;
  }
  .button-class-colse{
    border: 1px solid #F0F2F8;
    color: #999999;
  }
  .button-class-colse:hover {
    color: #FD492B;
    border: 1px solid #FD492B;
  }
  .button-class-ok:hover{
    background: #ff7354;
  }

  /* 列表视图 */
  .my-mind-content-1 {
    padding: 0;
  } 
  .my-mind-content-1 .my-mind-list {
    width: 100%;
    height: 42px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;
    background-color: #f8f8f8;
  }
  .my-mind-list:hover{
    background-color: #f1f1f1;
  }
  // .my-mind-content-1 .my-mind-list.active{
  //   background-color: rgba(0,0,0,.08);
  // }
  .my-mind-content-1 .my-mind-list .my-mind-img {
    display: block;
    width: 40px;
    height: 40px;
    font-size: 30px;
    line-height: 31px;
    text-align: center;
    margin-left: 40px;
    /* border: 1px solid #999; */
    /* box-shadow: 0 0 5px #999; */
    border-radius: 6px;
  }

  .my-mind-content-1 .my-mind-list .my-mind-icon {
    display: block;
    width: 40px;
    height: 40px;
    font-size: 40px;
    line-height: 41px;
    text-align: center;
    margin-left: 20px;
  }
  .my-mind-content-1 .my-mind-list .my-mind-list-text {
    margin: 0 0 0 5px;
    display: flex;
    align-items: center;
  }
  .my-mind-content-1 .my-mind-list .my-mind-list-text .order {
    width: 15px;
    text-align: right;
    font-size: 14px;
    color: #333333;
    margin-right: 10px;
  }
  .my-mind-content-1 .my-mind-list .my-mind-list-text .archive-img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
  .my-mind-content-1 .my-mind-list .my-mind-main-heading-move {
    font-size: 14px;
    font-family: Noto Sans SC;
    font-weight: 400;
    color: #333333;
    width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .my-mind-content-1 .my-mind-list .my-mind-main-subheading-move {
    width: 100%;
    /* width: 240px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #999;
  }
  .my-mind-content-1 .my-mind-list .my-mind-time-move {
    /* margin-left: auto; */
    margin-right: 10px;
    font-size: 14px;
    font-family: Noto Sans SC;
    font-weight: 400;
    color: #333333;
  }
  .my-mind-content-1 .gray-box {
    position: absolute;
    /* visibility:hidden; */
    /* z-index: -1; */
    opacity: 0;
  }
  .my-mind-content-1 .my-mind-list:hover .gray-box {
    opacity: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #f1f1f1;
    z-index: 1;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    visibility: visible;
  }

  /* .my-mind-content-1 .my-mind-list:hover .gray-box-active{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.1);
  z-index: 1;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
} */
  .my-mind-content-1 .more-father-box {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    /* background-color: lime; */
  }
  .my-mind-content-1 .more-function {
    margin-left: auto;
    margin-right: 20px;
    color: #333;
  }
  .my-mind-content-1 .my-mind-time-title {
    /* margin-left: auto; */
    margin-right: 55px;
  }

  .my-mind-content-1 .my-mind-list-head .my-mind-img {
    box-shadow: 0 0 5px #fff;
  }

  .close-button-img{
    width:18px;
    cursor: pointer;
    opacity: 0.5;
    margin-top: -3px;
  }
  .close-button-img:hover{
    opacity: 0.8;
  }
}
</style>