
import Util from "../../../utils/Util";
import MindElementData from "../MindElementData";

class MindNodesNearbyData {
    constructor() {
        this.dataList = [];
    }

    setData(mainMindNodeUnit, freeNodes, timeMindTypeNodeUnit, doubleBubbleMindNodeUnit) {

        this.setNodeUnitData(mainMindNodeUnit)
        for (let index = 0; index < freeNodes.length; index++) {
            this.setNodeUnitData(freeNodes[index])
        }
        if (doubleBubbleMindNodeUnit != null) {
            if (doubleBubbleMindNodeUnit.rootTreeNode != null &&
                !doubleBubbleMindNodeUnit.rootTreeNode.isEmpty()) {
                    this.setNodeDataList(doubleBubbleMindNodeUnit.rootTreeNode)
            }
            if (doubleBubbleMindNodeUnit.rootTreeBrotherNode != null &&
                !doubleBubbleMindNodeUnit.rootTreeBrotherNode.isEmpty()) {
                    this.setNodeDataList(doubleBubbleMindNodeUnit.rootTreeBrotherNode)
            }
            for (var index = 0; index < doubleBubbleMindNodeUnit.commonGroundMindElementDatas.length; index++) {
                let cell = doubleBubbleMindNodeUnit.commonGroundMindElementDatas[index]
                if (cell != null && !cell.isEmpty()) {
                    this.dataList.push(cell);
                }
            }
        }
        if (timeMindTypeNodeUnit != null && timeMindTypeNodeUnit.title != null &&
            !timeMindTypeNodeUnit.title.isEmpty()) {
            this.dataList.push(timeMindTypeNodeUnit.title);
            timeMindTypeNodeUnit.mainMindElementDataDict.values().forEach(data => {
                if (!data.head.isHidden) {
                    this.dataList.push(data.head);
                }
                if (!data.title.isHidden) {
                    this.dataList.push(data.title);
                }
                if (!data.desc.isHidden) {
                    this.dataList.push(data.desc);
                }
            });
        }
        
    }
    
    getLeftData(data) {
        if (data == null || data.isEmpty() || data.isHidden) {
            return new MindElementData().emptyMindNode() 
        }
        let count = this.dataList.length;
        
        let minDistance = 500;
        let dataLeft = data.x + data.width;
        let dataVerticalCenter = data.y + data.height/2;
        let targetData = null;
        let targetDataDistance = 9000000;

        for (let index = 0; index < count; index++) {
            let cell = this.dataList[index];
            if (cell == null || cell.isEmpty() || cell.isHidden) {
                continue;
            }
            if (dataLeft > cell.x || cell.x - dataLeft > minDistance || Math.abs(cell.y - dataVerticalCenter) > minDistance) {
                continue;
            }

            let cellDistance0 = Util.getPointSpacingByXY(dataLeft, data.y, cell.x, cell.y)
            let cellDistance1 = Util.getPointSpacingByXY(dataLeft, data.y, cell.x, cell.y + cell.height/2)
            let cellDistance2 = Util.getPointSpacingByXY(dataLeft, data.y, cell.x, cell.y + cell.height)

            let cellDistance01 = Util.getPointSpacingByXY(dataLeft, data.y + data.height/2, cell.x, cell.y)
            let cellDistance11 = Util.getPointSpacingByXY(dataLeft, data.y + data.height/2, cell.x, cell.y + cell.height/2)
            let cellDistance21 = Util.getPointSpacingByXY(dataLeft, data.y + data.height/2, cell.x, cell.y + cell.height)

            let cellDistance02 = Util.getPointSpacingByXY(dataLeft, data.y + data.height, cell.x, cell.y)
            let cellDistance12 = Util.getPointSpacingByXY(dataLeft, data.y + data.height, cell.x, cell.y + cell.height/2)
            let cellDistance22 = Util.getPointSpacingByXY(dataLeft, data.y + data.height, cell.x, cell.y + cell.height)

            let minCellDistance = Math.min(cellDistance0, cellDistance1, cellDistance2, 
                cellDistance01, cellDistance11, cellDistance21,
                cellDistance02, cellDistance12, cellDistance22)
            if (minCellDistance < targetDataDistance) {
                targetData = cell;
                targetDataDistance = minCellDistance;
            }
        }
        if (targetData != null && !targetData.isEmpty()) {
            return targetData;
        }
        return new MindElementData().emptyMindNode()
    }

    getRightData(data) {
        if (data == null || data.isEmpty() || data.isHidden) {
            return new MindElementData().emptyMindNode() 
        }
        let count = this.dataList.length;
        
        let minDistance = 500;
        let dataRight = data.x;
        let dataVerticalCenter = data.y + data.height/2;
        let targetData = null;
        let targetDataDistance = 9000000;
        for (let index = 0; index < count; index++) {
            let cell = this.dataList[index];
            if (cell == null || cell.isEmpty() || cell.isHidden) {
                continue;
            }
            if (dataRight < cell.x + cell.width || dataRight - cell.x - cell.width > minDistance || 
                (Math.abs(cell.x + cell.width/2 - data.x) > minDistance && 
                 Math.abs(cell.x + cell.width - data.x) > minDistance &&
                 Math.abs(cell.x - data.x) > minDistance &&

                 Math.abs(cell.x + cell.width/2 - dataHorizontalCenter) > minDistance && 
                 Math.abs(cell.x + cell.width - dataHorizontalCenter) > minDistance &&
                 Math.abs(cell.x - dataHorizontalCenter) > minDistance &&
                 
                 Math.abs(cell.x + cell.width/2 - data.x + data.width) > minDistance && 
                 Math.abs(cell.x + cell.width - data.x + data.width) > minDistance &&
                 Math.abs(cell.x - data.x + data.width) > minDistance)) {
                continue;
            }

            let cellDistance0 = Util.getPointSpacingByXY(dataRight, data.y, cell.x + cell.width, cell.y)
            let cellDistance1 = Util.getPointSpacingByXY(dataRight, data.y, cell.x + cell.width, cell.y + cell.height/2)
            let cellDistance2 = Util.getPointSpacingByXY(dataRight, data.y, cell.x + cell.width, cell.y + cell.height)

            let cellDistance01 = Util.getPointSpacingByXY(dataRight, data.y + data.height/2, cell.x + cell.width, cell.y)
            let cellDistance11 = Util.getPointSpacingByXY(dataRight, data.y + data.height/2, cell.x + cell.width, cell.y + cell.height/2)
            let cellDistance21 = Util.getPointSpacingByXY(dataRight, data.y + data.height/2, cell.x + cell.width, cell.y + cell.height)

            let cellDistance02 = Util.getPointSpacingByXY(dataRight, data.y + data.height, cell.x + cell.width, cell.y)
            let cellDistance12 = Util.getPointSpacingByXY(dataRight, data.y + data.height, cell.x + cell.width, cell.y + cell.height/2)
            let cellDistance22 = Util.getPointSpacingByXY(dataRight, data.y + data.height, cell.x + cell.width, cell.y + cell.height)

            let minCellDistance = Math.min(cellDistance0, cellDistance1, cellDistance2, 
                cellDistance01, cellDistance11, cellDistance21,
                cellDistance02, cellDistance12, cellDistance22)

            if (minCellDistance < targetDataDistance) {
                targetData = cell;
                targetDataDistance = minCellDistance;
            }
        }
        if (targetData != null && !targetData.isEmpty()) {
            return targetData;
        }
        return new MindElementData().emptyMindNode()
    }

    getTopData(data) {
        if (data == null || data.isEmpty() || data.isHidden) {
            return new MindElementData().emptyMindNode() 
        }
        let count = this.dataList.length;
        
        let minDistance = 500;
        let dataTop = data.y;
        let dataHorizontalCenter = data.x + data.width/2;
        let targetData = null;
        let targetDataDistance = 9000000;
        for (let index = 0; index < count; index++) {
            let cell = this.dataList[index];
            if (cell == null || cell.isEmpty() || cell.isHidden) {
                continue;
            }
            if (dataTop < cell.y + cell.height || dataTop - cell.y > minDistance || 
                (Math.abs(cell.x + cell.width/2 - data.x) > minDistance && 
                 Math.abs(cell.x + cell.width - data.x) > minDistance &&
                 Math.abs(cell.x - data.x) > minDistance &&

                 Math.abs(cell.x + cell.width/2 - dataHorizontalCenter) > minDistance && 
                 Math.abs(cell.x + cell.width - dataHorizontalCenter) > minDistance &&
                 Math.abs(cell.x - dataHorizontalCenter) > minDistance &&
                 
                 Math.abs(cell.x + cell.width/2 - data.x + data.width) > minDistance && 
                 Math.abs(cell.x + cell.width - data.x + data.width) > minDistance &&
                 Math.abs(cell.x - data.x + data.width) > minDistance)) {
                continue;
            }
            let cellDistance0 = Util.getPointSpacingByXY(data.x, data.y, cell.x, cell.y + cell.height)
            let cellDistance1 = Util.getPointSpacingByXY(data.x, data.y, cell.x + cell.width / 2, cell.y + cell.height)
            let cellDistance2 = Util.getPointSpacingByXY(data.x, data.y, cell.x + cell.width, cell.y + cell.height)

            let cellDistance01 = Util.getPointSpacingByXY(data.x + data.width/2, data.y, cell.x, cell.y + cell.height)
            let cellDistance11 = Util.getPointSpacingByXY(data.x + data.width/2, data.y, cell.x + cell.width / 2, cell.y + cell.height)
            let cellDistance21 = Util.getPointSpacingByXY(data.x + data.width/2, data.y, cell.x + cell.width, cell.y + cell.height)

            let cellDistance02 = Util.getPointSpacingByXY(data.x + data.width, data.y, cell.x, cell.y + cell.height)
            let cellDistance13 = Util.getPointSpacingByXY(data.x + data.width, data.y, cell.x + cell.width / 2, cell.y + cell.height)
            let cellDistance23 = Util.getPointSpacingByXY(data.x + data.width, data.y, cell.x + cell.width, cell.y + cell.height)

            let minCellDistance = Math.min(cellDistance0, cellDistance1, cellDistance2, 
                cellDistance01, cellDistance11, cellDistance21,
                cellDistance02, cellDistance13, cellDistance23)
            if (minCellDistance < targetDataDistance) {
                targetData = cell;
                targetDataDistance = minCellDistance;
            }
        }
        if (targetData != null && !targetData.isEmpty()) {
            return targetData;
        }
        return new MindElementData().emptyMindNode()
    }

    getBottomData(data) {
        if (data == null || data.isEmpty() || data.isHidden) {
            return new MindElementData().emptyMindNode() 
        }
        let count = this.dataList.length;
        
        let minDistance = 500;
        let dataBottom = data.y + data.height;
        let dataHorizontalCenter = data.x + data.width/2;
        let targetData = null;
        let targetDataDistance = 9000000;
        for (let index = 0; index < count; index++) {
            let cell = this.dataList[index];
            if (cell == null || cell.isEmpty() || cell.isHidden) {
                continue;
            }
            if (dataBottom > cell.y || cell.y + cell.height - dataBottom > minDistance || 
                (Math.abs(cell.x + cell.width/2 - data.x) > minDistance && 
                 Math.abs(cell.x + cell.width - data.x) > minDistance &&
                 Math.abs(cell.x - data.x) > minDistance &&

                 Math.abs(cell.x + cell.width/2 - dataHorizontalCenter) > minDistance && 
                 Math.abs(cell.x + cell.width - dataHorizontalCenter) > minDistance &&
                 Math.abs(cell.x - dataHorizontalCenter) > minDistance &&
                 
                 Math.abs(cell.x + cell.width/2 - data.x + data.width) > minDistance && 
                 Math.abs(cell.x + cell.width - data.x + data.width) > minDistance &&
                 Math.abs(cell.x - data.x + data.width) > minDistance)) {
                continue;
            }

            let cellDistance0 = Util.getPointSpacingByXY(data.x, data.y + data.height, cell.x, cell.y)
            let cellDistance1 = Util.getPointSpacingByXY(data.x, data.y + data.height, cell.x + cell.width / 2, cell.y)
            let cellDistance2 = Util.getPointSpacingByXY(data.x, data.y + data.height, cell.x + cell.width, cell.y)

            let cellDistance01 = Util.getPointSpacingByXY(data.x + data.width/2, data.y + data.height, cell.x, cell.y)
            let cellDistance11 = Util.getPointSpacingByXY(data.x + data.width/2, data.y + data.height, cell.x + cell.width / 2, cell.y)
            let cellDistance21 = Util.getPointSpacingByXY(data.x + data.width/2, data.y + data.height, cell.x + cell.width, cell.y)

            let cellDistance02 = Util.getPointSpacingByXY(data.x + data.width, data.y + data.height, cell.x, cell.y)
            let cellDistance13 = Util.getPointSpacingByXY(data.x + data.width, data.y + data.height, cell.x + cell.width / 2, cell.y)
            let cellDistance23 = Util.getPointSpacingByXY(data.x + data.width, data.y + data.height, cell.x + cell.width, cell.y)

            let minCellDistance = Math.min(cellDistance0, cellDistance1, cellDistance2, 
                cellDistance01, cellDistance11, cellDistance21,
                cellDistance02, cellDistance13, cellDistance23)
            if (minCellDistance < targetDataDistance) {
                targetData = cell;
                targetDataDistance = minCellDistance;
            }
        }
        if (targetData != null && !targetData.isEmpty()) {
            return targetData;
        }
        return new MindElementData().emptyMindNode()
    }

    setNodeUnitData(unit) {
        if (unit == null || unit.rootTreeNode == null || unit.rootTreeNode.isEmpty()) {
            return
        }
        this.setNodeDataList(unit.rootTreeNode)
        let generalizationValues = unit.generalizationMindElementDataDict.values()
        let generalizationValuesLength = generalizationValues.length
        for (let index = 0; index < generalizationValuesLength; index++) {
            let node = generalizationValues[index]
            if (node == null || node.isEmpty() || node.value.isHidden) {
                continue;
            }
            this.setNodeDataList(node)
        }
        let explainMindDatas = unit.explainMindElementDataDict.values();
        let explainMindDatasLength = explainMindDatas.length;
        for (let index = 0; index < explainMindDatasLength; index++) {
            let cell = explainMindDatas[index]
            if (cell != null && !cell.isEmpty() && !cell.isHidden) {
                this.dataList.push(cell);
            }
        }
    }


    setNodeDataList(node) {
        if (node == null || node.isEmpty()) {
            return
        }
        if (node.value.isHidden) {
            return;
        }
        this.dataList.push(node.value);
        node.children.forEach(child => {
            this.setNodeDataList(child);
        });
    }
}
export default MindNodesNearbyData