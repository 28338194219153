import CGRect from "../base/basedata/Rect";
import Rect from "../base/basedata/Rect"

class RectsMergeCalculation {

    calcule(list) {
        if (list.length == 0) {
            return new CGRect(0, 0, 0, 0);
        }
        let left = 1000000;
        let top = 1000000;
        let right = -10000;
        let bottom = -10000;

        for (let index = 0; index < list.length; index++) {
            let rect = list[index];
            left = Math.min(rect.x, left);
            top = Math.min(rect.y, top);
            right = Math.max(rect.width() + rect.x, right);
            bottom = Math.max(rect.y + rect.height(), bottom);
        }
        return new CGRect(left, top, right - left, bottom - top);
    }
}

export default RectsMergeCalculation
