import FontCell from "./FontCell"

class UserFont {
    constructor(value) {
        this.list = new Array()
    }

    push(name, className, file) {
        let cell = new FontCell()
        cell.name = name
        cell.class = className
        cell.file = file
        var maxId = 99
        for (let index = 0; index < this.list.length; index++) {
            if (this.list[index].file == file) {
                this.list.splice(index, 1)
                break
            }
        }
        for (let index = 0; index < this.list.length; index++) {
            maxId = Math.max(maxId, this.list[index].id)
        }
        cell.id = maxId + 1;
        this.list.splice(0, 0, cell);
        return cell
    }

    format(vaule) {
        if (vaule == null || vaule.length == 0) {
            return;
        }
        let mind = JSON.parse(vaule)
        if (mind.list != null && mind.list.length > 0) {            
            for (let index = 0; index < mind.list.length; index++) {
                let cellJson = mind.list[index]
                if (cellJson != null) {
                    let cell = new FontCell()
                    for (var key in cellJson) {
                        let keyValue = cellJson[key]
                        cell[key] = keyValue
                    }
                    this.list.push(cell)
                }
            }
        }
    }

    toJSONString() {
        let content = JSON.stringify(this, function (key, val) {
            if (typeof val === "function") {
                return val + '';
            }
            if (typeof val === "number") {
                let newValue = Math.round(val*100)/100;
                if (newValue == val) {
                    return val
                } else {
                    return newValue
                }
            } else {
                return val
            }
        });
        return content
    }
}
export default UserFont