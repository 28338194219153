<template>
  <!--  关联线起点终点  -->
  <div class="ComplieHeaderImg" id="association-line-start-end" ref="associationLineStartEndBox">
    <div v-if="startOrEnd == 'start'" class="complie-header-imgbox">
      <!-- <ul class="menu-use-box"> -->
        <div
          class="complie-com-box"
          v-for="(item, index) in imgDataListStart"
          :key="index"
          :class=" index == imgDataListStart.length - 1 ? 'complie-com-box-last' : ''"
          @click="getCorrelationLineStartOrEndType(item.lineType, mapEditDarkMode?item.imgWhite:item.imgData)"
        >
          <div class="complie-left-icon">
            <img v-if="mapEditDarkMode" class="menu-line-icon" :src="item.imgWhite" />
            <img v-else class="menu-line-icon" :src="item.imgData" />
          </div>
        </div>
      <!-- </ul> -->
    </div>
    <div v-else class="complie-header-imgbox">
      <!-- <ul class="menu-use-box"> -->
        <div
          class="complie-com-box"
          v-for="(item, index) in imgDataListEnd"
          :key="index"
          :class=" index == imgDataListEnd.length - 1 ? 'complie-com-box-last' : ''"
          @click="getCorrelationLineStartOrEndType(item.lineType, mapEditDarkMode?item.imgWhite:item.imgData)"
        >
          <div class="complie-left-icon">
            <img v-if="mapEditDarkMode" class="menu-line-icon" :src="item.imgWhite" />
            <img v-else class="menu-line-icon" :src="item.imgData" />
          </div>
        </div>
      <!-- </ul> -->
    </div>
  </div>
</template>
<script>
import { mapMutations,mapState } from "vuex";
import LinePointShape from '../../../viewmodel/datatype/LinePointShape';
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
export default {
  name: "ComplieChildMenuCorrelationLine",
  props:[
    "startOrEnd",
    ],
  data() {
    return {
      imgDataListStart: [
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineStartIcon1.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/associationLineWhite.svg"),
          lineType: LinePointShape.NORMAL,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineStartIcon2.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineStartIcon2.svg"),
          lineType: LinePointShape.CIRCULAR,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineStartIcon4.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineStartIcon4.svg"),
          lineType: LinePointShape.CIRCULAR_RADIATION,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineStartIcon3.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineStartIcon3.svg"),
          lineType: LinePointShape.CIRCULAR_HOLLOW,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineStartIcon5.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineStartIcon5.svg"),
          lineType: LinePointShape.ARROW,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineStartIcon6.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineStartIcon6.svg"),
          lineType: LinePointShape.BAMBOO_ARROW,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineStartIcon7.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineStartIcon7.svg"),
          lineType: LinePointShape.SIMPLE_ARROW,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineStartIcon8.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineStartIcon8.svg"),
          lineType: LinePointShape.T_SHAPED,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineStartIcon9.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineStartIcon9.svg"),
          lineType: LinePointShape.DIAMOND,
        },
      ],
      imgDataListEnd: [
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineEndIcon1.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/associationLineWhite.svg"),
          lineType: LinePointShape.NORMAL,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineEndIcon2.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineEndIcon2.svg"),
          lineType: LinePointShape.CIRCULAR,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineEndIcon3.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineEndIcon3.svg"),
          lineType: LinePointShape.CIRCULAR_RADIATION,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineEndIcon4.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineEndIcon4.svg"),
          lineType: LinePointShape.CIRCULAR_HOLLOW,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineEndIcon5.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineEndIcon5.svg"),
          lineType: LinePointShape.ARROW,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineEndIcon6.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineEndIcon6.svg"),
          lineType: LinePointShape.BAMBOO_ARROW,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineEndIcon7.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineEndIcon7.svg"),
          lineType: LinePointShape.SIMPLE_ARROW,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineEndIcon8.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineEndIcon8.svg"),
          lineType: LinePointShape.T_SHAPED,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/AssociationLineEndIcon9.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/associationLineStartEnd/darkModeIcon/AssociationLineEndIcon9.svg"),
          lineType: LinePointShape.DIAMOND,
        },
      ],
      imgDataAccout: 0,
      layoutType: "",
      viewModel: null,
      mindOperateUIControllerView: null,
    };
  },
  computed: {
    ...mapState({
      mapEditDarkMode: (state) => state.mapEditIsDardarkMode.darkMode,//深色模式
    }),
  },
  created() {},
  mounted() {
    this.setDardarkMode();
  },
  methods: {
    ...mapMutations([
      "correlationLineStartStyle",
      "correlationLineEndStyle"
    ]),
    getCorrelationLineStartOrEndType(type, url) {
      if(this.startOrEnd == "start"){
        EditMindmapVM.changeConnectLineStartShape(type);
        this.correlationLineStartStyle(url);
      }else{
        EditMindmapVM.changeConnectLineEndShape(type);
        this.correlationLineEndStyle(url);
      }
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        let boxDom = this.$refs.associationLineStartEndBox;
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        if (boxDom) {
          if (dardarkMode.darkMode) {
            boxDom.style.border = '1px solid rgba(255,255,255,.3)';
            boxDom.style.background = dardarkMode.bgColor;
          } else {
            boxDom.style.border = 0;
            boxDom.style.background = "#fff";
          }
        }
      });
    },
  },
  watch: {
    "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.menu-use-content-inside {
  width: 268px;
  height: 284px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.1);
}
.menu-use-content-inside ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.menu-use-content-inside ul li {
  width: 45px;
  height: 45px;
  float: left;
  list-style: none;
  margin: 8px 8px;
  padding: 3px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-use-content-inside ul li:hover {
  background: #e0e0e0;
}
.menu-theme-line {
  background: #e0e0e0;
}
.ComplieHeaderImg {
  width: 198px;
  height: 293px;
  background: #ffffff;
  border: 1px solid #cccccc;
  opacity: 1;
  border-radius: 8px;
  position: absolute;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  margin-left: -70px;
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
  &::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #bfbfbf;
  }
}
.complie-header-imgbox {
  margin: 7px 25px;
}
.complie-com-box {
  width: 138px;
  height: 44px;
  border-bottom: 1px dashed var(--exportMenuDashedColor);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.complie-com-box:hover{
  background-color: var(--exportMenuBg);
}
.complie-com-box-last {
  border-bottom: transparent;
}
.complie-left-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
}
</style>