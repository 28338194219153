

class FrameworkCell {
    constructor(id, imagePath) {
        this.id = id;
        this.imagePath = imagePath;
    }
    FrameworkCell(id, imagePath) {
        this.id = id;
        this.imagePath = imagePath;
    }

}

export default FrameworkCell
