
import Config from "../../../core/core/calcule/Config";
import OutlineMindElementCalculation from "../../../core/core/calcule/OutlineMindElementCalculation";
import MindElementType from "../../datatype/MindElementType";
import LineMindTypeNode from "../../mindelementdata/LineMindTypeNode";
import IdGenerator from "../base/IdGenerator";
import BaseLayout from "./BaseLayout";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/10/19
 * Copyright(c) 2020 mindyushu.com
 */

class OutlineLayout extends BaseLayout {
    constructor() {
        super()
        this.spacVertical = 0;
        this.preNode;
        this.startTop = 0;
    }

    onLayout(isChange) {
        new OutlineMindElementCalculation(this.rootTreeNode.value).calcule();
        if (this.rootTreeNode.value.type == MindElementType.MAIN_SUBJECT) {
            this.startTop = Config.MindOutlineStartTop + this.rootTreeNode.value.height + 10;
            this.setPreviousNode(null);
        } else {
            if (this.preNode == null || this.preNode.isEmpty()) {
                this.startTop = Config.MindOutlineStartTop + this.rootTreeNode.value.height + this.spacVertical * 2;
            }
        }
        this.setElementsPoint(isChange);
    }

    setPreviousNode(node) {
        this.preNode = node;
    }

    getPreviousNode() {
        return this.preNode;
    }

    setElementsPoint(isChange) {
        this.setNodeElementsPoint(this.rootTreeNode, isChange);
    }

    setNodeElementsPoint(node, isChange) {
        this.setDataPoint(node.value, isChange);
        if (node.children.length == 0) {
            return;
        }
        for (let index = 0; index < node.children.length; index++) {
            this.setNodeElementsPoint(node.children[index], isChange);
        }
    }

    setDataPoint(data, isChange) {
        let node = this.getNodeById(data.id);
        if (node.isEmpty()) {
            return;
        }
        this.pushCurrentLayoutNode(node);
        new OutlineMindElementCalculation(data).caluleText().calcule();
        if (data.id == this.rootTreeNode.value.id && data.type == MindElementType.MAIN_SUBJECT) {
            return;
        } else if (this.preNode == null || this.preNode.isEmpty()) {
            data.outlineY = this.startTop;
            data.outlineX = 0;
        } else {
            data.outlineY = this.preNode.value.outlineY + this.preNode.value.height + this.spacVertical;
            data.outlineX = 0;
        }
        if (data.type == MindElementType.MAIN_SUBJECT) {
            this.preNode = node;
            return;
        }
        let generalizationNode = this.getNodeGeneralizationElements(data.id);
        if (!generalizationNode.isEmpty()) {
            generalizationNode.value.level = data.level;
            new OutlineMindElementCalculation(generalizationNode.value).caluleText().calcule();
            generalizationNode.value.outlineY = data.outlineY + data.height; //+ spacVertical
            generalizationNode.value.outlineX = 0;
            this.preNode = generalizationNode;
        } else {
            this.preNode = node;
        }
    }

    getNodeGeneralizationElements(id) {
        for (let i = 0; i < this.generalizationMindElementDataDict.values().length; i++) {
            const node = this.generalizationMindElementDataDict.values()[i];
            let mindElementData = node.value;
            if (mindElementData.generalizationContent == null) {
                continue;
            }
            if (mindElementData.generalizationContent.targetIds.indexOf(id) != -1) {
                return node;
            }
        }
        return new LineMindTypeNode();
    }


    getTreeHeight(node) {
        if (node.value.id == IdGenerator.INVALID_ID) {
            return 0;
        }
        let height = node.value.height;
        let chlidHeight = 0;
        let nodeGeneralizationHeight = this.getNodeGeneralizationHeight(node);
        height = Math.max(height, nodeGeneralizationHeight);
        for (let i = 0; i < node.children.length; i++) {
            const chlid = node.children[i];
            let verticalSpacee = chlid.value.type == MindElementType.SON_SUBJECT ? Config.SonNodeVerticalSpacee : Config.NodeVerticalSpacee;
            if (!chlid.children.isEmpty()) {
                chlidHeight += this.getTreeHeight(chlid) + (chlidHeight > 0 ? verticalSpacee : 0);
            } else {
                let nodeHeight = chlid.value.height;
                let chlidGeneralizationHeight = this.getNodeGeneralizationHeight(chlid);
                nodeHeight = Math.max(nodeHeight, chlidGeneralizationHeight);
                chlidHeight += nodeHeight + (chlidHeight > 0 ? verticalSpacee : 0);
            }
        }
        return Math.max(chlidHeight, height);
    }


    getTreeWidth(node) {
        if ((node.value.id == IdGenerator.INVALID_ID)) {
            return 0;
        }
        let width = node.value.width;
        let nodeGeneralizationWidth = this.getNodeGeneralizationWidth(node);
        nodeGeneralizationWidth = nodeGeneralizationWidth > 0 ? nodeGeneralizationWidth + Config.GeneralizationLineWidth + Config.GeneralizationSpacing * 2 : 0;
        width = width + nodeGeneralizationWidth;
        let siblingsMaxWidth = 0;

        for (let i = 0; i < node.children.length; i++) {
            const chlid = node.children[i];
            let chlidWidth = 0;
            let horizontalSpacee = chlid.value.type == MindElementType.SON_SUBJECT ? Config.SonNodeHorizontalSpacee : Config.NodeHorizontalSpacee;
            if (!chlid.children.isEmpty()) {
                chlidWidth += this.getTreeWidth(chlid) + horizontalSpacee;
            } else {
                let nodeidth = chlid.value.width;
                let chlidGeneralizationWidth = this.getNodeGeneralizationWidth(chlid);
                chlidGeneralizationWidth = chlidGeneralizationWidth > 0 ? chlidGeneralizationWidth + Config.GeneralizationLineWidth + Config.GeneralizationSpacing * 2 : 0;
                nodeidth = nodeidth + chlidGeneralizationWidth;
                chlidWidth += nodeidth + horizontalSpacee;
            }
            if (siblingsMaxWidth < chlidWidth) {
                siblingsMaxWidth = chlidWidth;
            }
        }
        return siblingsMaxWidth + width;
    }
}
export default OutlineLayout
