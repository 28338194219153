import CreatMindItmeData from "../views/uidata/CreatMindItmeData";
import Strings from "./lang/strings";
import getString from "./lang/language";
import NodeLayoutType from "../viewmodel/datatype/NodeLayoutType";
import MindType from "../viewmodel/datatype/MindType";
import LineLayout from "../viewmodel/datatype/LineLayout";
import MindDisplayType from "../viewmodel/datatype/MindDisplayType";
import strings from "./lang/strings";
import CreateMapDatas from "./CreateMapDatas";

//创建导图的数据
var templateData = [];

var creatMindTypeLeftRight = [];
var creatMindTypeUnilateral = [];
var creatMindTypeBottom = [];
var creatMindTypeBubble = [];
var creatMindTypeTimeVertical = [];


function createdType() {    

    let createMapDatas = new CreateMapDatas()
    creatMindTypeLeftRight = createMapDatas.creatMindTypeLeftRight
    creatMindTypeUnilateral = createMapDatas.creatMindTypeUnilateral
    creatMindTypeBottom = createMapDatas.creatMindTypeBottom
    creatMindTypeBubble = createMapDatas.creatMindTypeBubble
    creatMindTypeTimeVertical = createMapDatas.creatMindTypeTimeVertical

    templateData.push(createMapDatas.creatMindTypeLeftRight);
    templateData.push(createMapDatas.creatMindTypeUnilateral);
    templateData.push(createMapDatas.creatMindTypeBottom);
    templateData.push(createMapDatas.creatMindTypeBubble);
    templateData.push(createMapDatas.creatMindTypeTimeVertical);

    for (let i = 0; i < templateData.length; i++) {
      for (let j = 0; j < templateData[i].length; j++) {
        if (templateData[i][j] != null) {
          templateData[i][
            j
          ].icon = require("../assets/img/mindNewEditMenuIocn/oneKeyDiscoloration/skeleton2/" +
            templateData[i][j].icon);
        }
      }
    }
  }

  createdType();

export default templateData