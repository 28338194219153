class LinearGradient {
    constructor(parentNode, colorArray, direction, idString) {
        this.parentNode = parentNode;   //父节点
        this.colorArray = colorArray; //颜色数组
        this.direction = direction;  //方向
        this.idString = idString; // 渐变的id
        this.linearGradientTag;
        this.setColor(colorArray, direction, idString)
    }


    setColor(val, direction, idString) {
        if (val == null || val.length == 0) {
            return;
        }
        this.colorArray = val;
        this.direction = direction;
        if (idString != null) {
            this.idString = idString;
        }
        if (this.linearGradientTag != null) {
            this.linearGradientTag.parentNode.removeChild(this.linearGradientTag);
            this.linearGradientTag = null;
        }

        this.linearGradientTag = document.createElementNS('http://www.w3.org/2000/svg', "linearGradient");
        this.parentNode.appendChild(this.linearGradientTag);
        this.linearGradientTag.setAttribute("id", this.idString);
        this.linearGradientTag.setAttribute("x1", this.direction.x1);
        this.linearGradientTag.setAttribute("y1", this.direction.y1);
        this.linearGradientTag.setAttribute("x2", this.direction.x2);
        this.linearGradientTag.setAttribute("y2", this.direction.y2);

        for (let index = 0; index < this.colorArray.length; index++) {
            let createdStop = document.createElementNS('http://www.w3.org/2000/svg', "stop");
            this.linearGradientTag.appendChild(createdStop);
            createdStop.setAttribute('offset', this.colorArray[index].offset);
            createdStop.setAttribute('stop-color', this.colorArray[index].stopColor);
        }

        return idString

    }

}

export default LinearGradient