
import MindElementCalculation from "../../../core/core/calcule/elementCalculation/MindElementCalculation"
import BaseElementView from "../elementviews/BaseElementView"
import Colors from "../../../utils/Colors"
import Strings from "../../../utils/Strings"
import UiUtil from "../../../utils/UiUtil"
import Util from "../../../utils/Util"
import CGPoint from "../../../viewmodel/core/base/basedata/CGPoint"

import StatisticsChapeType from "../../../viewmodel/datatype/StatisticsChapeType"
import LinePath from "../../../viewmodel/core/base/LinePath"
import Point from "../../../viewmodel/core/base/Point"
import Config from "../../../core/core/calcule/Config"
import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType"



// import mind.yushu.com.mindmap.core.calcule.elementCalculation.MindElementCalculation;
// import mind.yushu.com.mindmap.core.common.UiHandlers;
// import mind.yushu.com.mindmap.main.edit.EditMindmapActivity;
// import mind.yushu.com.mindmap.main.edit.mindElementViews.BaseElementView;
// import mind.yushu.com.mindmap.main.edit.mindElementViews.delegate.MindTextDelegate;      //不需要new
// import mind.yushu.com.mindmap.main.edit.mindElementViews.delegate.MindViewDragDelegate; //不需要new
// import mind.yushu.com.mindmap.utils.Colors;
// import mind.yushu.com.mindmap.utils.Strings;
// import mind.yushu.com.mindmap.utils.UiUtil;
// import mind.yushu.com.mindmap.utils.Util;
// import mind.yushu.com.mindmap.viewmodel.core.base.basedata.CGPath;       //不需要翻译的
// import mind.yushu.com.mindmap.viewmodel.core.base.basedata.CGPoint;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.MindElementData;  //没有new
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.StatisticsCellData;  //没有new
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.StatisticsContent;  //没有new

// /**
//  * ProjectName: MindMap
//  * Created by tony on 1/12/21
//  * Copyright(c) 2020 mindyushu.com
//  */

// @SuppressLint("ViewConstructor")
class outlineStatisticsElementView extends BaseElementView {
    constructor(viewCanvs, data, mindTextSizeChangedDelegate, backgroundColor) {
        super(viewCanvs, data)
        this.CLICK_INTERVAL_TIME = 200;
        this.contextSpace = new UiUtil().dip2px(10);

        this.mindTextSizeChangedDelegate = mindTextSizeChangedDelegate;   //用于存储vue
        this.onTouchListener;
        this.userGesture;
        this.onLongClickListener;
        this.lastClickTime = 0;
        this.backgroundColor = backgroundColor
        // this.paint = new Paint(Paint.ANTI_ALIAS_FLAG);
        // this.selectedPaint = new Paint();
        // this.linePaint = new Paint();
        // this.chartTextPaint = new Paint(Paint.ANTI_ALIAS_FLAG);
        // this.chartTextTitlePaint = new Paint(Paint.ANTI_ALIAS_FLAG);
        // this.chartPaint = new Paint(Paint.ANTI_ALIAS_FLAG);
        this.selectedIndex = -1;
        this.selectedDownPoint = new CGPoint(-1, -1);
        this.chartG = null; //用于包裹扇形图或者柱状图的g标签。
        this.removeArray = new Array();  //存储所有扇形以外的元素，用于下次渲染的时候删除。
        this.sectorDomList = new Array(); //存储扇形dom。
        this.sectorDomList = new Array(); //存储第二层扇形。
        this.sectorTitleList = new Array(); //标题数组
        this.sectorContentList = new Array();  //内容数组
        this.sectorValueList = new Array(); //所有数值数组
        this.sectorArrow = null; //     箭头
        this.sectorArrowLine = null; // 箭头线
        this.GraphLine = null; //曲线图的线
        this.onClickListener = null; //单击
        this.rightClick = null;
        this.mindNodeUpNumberViewWidth = new UiUtil().dip2px(24);
        this.outlineCanvs = viewCanvs
        this.onDraw();
    }

    // setAllTextColor() {     //修改文字和表格箭头线的颜色

    // }

    refresh() {
        super.refresh();
        if (this.isRefresh) {
            this.onDraw();
        }
    }
    initView() {

    }
    setBackgroundColor(color) {
        // let isDarkColor = Colors.isLightColor(color);
        // let newcolor = "";
        // if (isDarkColor) {
        //   newcolor = Colors.black50;
        // } else {
        //   newcolor = Colors.white15;
        // }

        this.setAllTextColor(color);
        this.backgroundColor = color
        this.onDraw();
    }

    onDraw(canvas) {
        this.drawLine(canvas);
    }

    drawLine(canvas) {
        // console.log("is ouline");
        if (this.mindElementData == null ||
            !this.mindElementData.isStatisticsContent()) {
            return;
        }
        // console.log("before is ouline");
        if (this.context != null) {
            this.context.parentNode.removeChild(this.context);
            this.context = null;

            for (let i = 0; i < this.removeArray.length; i++) {
                if (this.removeArray[i] != null) {
                    this.removeArray[i].parentNode.removeChild(this.removeArray[i])
                }
            }
            this.removeArray = []
        }



        if (this.context == undefined || this.context == null) {
            this.context = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            this.context.style.width = this.mindElementData.width + "px";
            this.context.style.minHeight = this.mindElementData.height + "px";
            this.outlineCanvs.appendChild(this.context)
            this.chartG = document.createElementNS('http://www.w3.org/2000/svg', "g");
            this.context.appendChild(this.chartG);
        }

        // if( this.removeArray.length != 0) { //清空所有dom
        //     for(let i = 0; i < this.removeArray.length; i++ ) {
        //         if (this.removeArray[i] != null) {
        //             this.removeArray[i].parentNode.removeChild(this.removeArray[i])
        //         }
        //     }
        //     this.removeArray = []
        // }
        //创建导图的时候设置文字颜色。
        if (this.mindTextSizeChangedDelegate != undefined && this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor != undefined) {
            let backgroundColor = this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor
            let isDarkColor = Colors.isLightColor(backgroundColor);
            let newcolor;
            if (isDarkColor) {
                newcolor = Colors.black50
            } else {
                newcolor = Colors.white15
            }
            for (let index = 0; index < this.mindElementData.statisticsContent.cellsData.length; index++) {
                this.mindElementData.statisticsContent.cellsData[index].explainColor = newcolor;
            }
        }

        let statisticsContent = this.mindElementData.statisticsContent;
        switch (statisticsContent.type) {
            case StatisticsChapeType.CakeShape_1:
                this.drawCakeShape1();
                break;
            case StatisticsChapeType.CakeShape_2:
                this.drawCakeShape2();
                break;
            case StatisticsChapeType.CakeShape_3:
                this.drawCakeShape3();
                break;
            case StatisticsChapeType.CakeShape_4:
                this.drawCakeShape4();
                break;
            case StatisticsChapeType.CakeShape_5:
                this.drawCakeShape5();
                break;
            case StatisticsChapeType.Histogram_1:
                this.drawHistogram1();
                break;
            case StatisticsChapeType.Histogram_2:
                this.drawHistogram2();
                break;
            case StatisticsChapeType.Histogram_3:
                this.drawHistogram3(canvas);
                break;
            default:
                this.drawCakeShape1();
        }

        if (this.isSelected) {
            // selectedPaint.setStyle(Paint.Style.STROKE);
            // selectedPaint.setStrokeCap(Paint.Cap.ROUND);
            // selectedPaint.setStrokeWidth(UiUtil.dip2px(2));
            // selectedPaint.setAntiAlias(true);
            // selectedPaint.setColor(0x88ff3300);
            // selectedPaint.setPathEffect(new CornerPathEffect(UiUtil.dip2px(6)));
            // canvas.drawRect(new RectF(0, 0, mindElementData.width, mindElementData.height), selectedPaint);
        }
    }

    drawHistogram3() {
        this.chartG.style.width = this.mindElementData.statisticsContent.width + 'px';
        this.chartG.style.height = this.mindElementData.statisticsContent.height + 'px';
        //this.chartG.style.transform = "translate("+ this.mindElementData.statisticsContent.x  + 'px,' + this.mindElementData.statisticsContent.y  + 'px)';
        let statisticsContent = this.mindElementData.statisticsContent;

        let formLine = document.createElementNS('http://www.w3.org/2000/svg', "path")
        this.context.appendChild(formLine);
        this.removeArray.push(formLine)
        let formArrow = document.createElementNS('http://www.w3.org/2000/svg', "path")
        this.context.appendChild(formArrow);
        this.removeArray.push(formArrow)
        let strokeWidth = new UiUtil().dip2px(2);
        let formLinePath = new LinePath();
        let textColor;
        if (this.backgroundColor != null && Colors.isDarkColor(this.backgroundColor)) {
            textColor = Colors.getUiColor(Colors.white);
        } else {
            textColor = Colors.getUiColor(Colors.black50);
        }
        // let textColor = Colors.getUiColor(Colors.black50);  //需要一个判断是深色还是浅色模式下。
        formLinePath.moveTo(this.contextSpace, this.contextSpace);
        formLinePath.lineTo(this.contextSpace, statisticsContent.cellsData[0].y + statisticsContent.cellsData[0].height + strokeWidth / 2);
        formLinePath.lineTo(this.mindElementData.width - this.contextSpace, statisticsContent.cellsData[0].y + statisticsContent.cellsData[0].height + strokeWidth / 2);
        this.renderObj({ dom: formLine, d: formLinePath, stroke: textColor, strokeWidth, fill: 'none' });
        this.drawArrow(formArrow,
            new CGPoint(this.contextSpace, statisticsContent.cellsData[0].y + statisticsContent.cellsData[0].height + strokeWidth / 2),
            new CGPoint(this.contextSpace, this.contextSpace),
            textColor);
        let length3d = statisticsContent.cellsData[0].width;
        let moveY = length3d / 4;
        this.sectorArrow = formArrow; //     箭头
        this.sectorArrowLine = formLine; // 箭头线



        //统计曲线
        let curvePath = new LinePath();
        let count = statisticsContent.cellsData.length;
        let cubicToLine = document.createElementNS('http://www.w3.org/2000/svg', "path");
        this.chartG.appendChild(cubicToLine);
        for (let index = 0; index < count; index++) {
            let cell = statisticsContent.cellsData[index];
            let x = cell.x + cell.width / 2;
            let y = cell.y;
            if (index == 0) {
                curvePath.moveTo(x, y);
            } else {
                let preCellData = statisticsContent.cellsData[index - 1];
                let preX = preCellData.x + preCellData.width / 2;
                let preY = preCellData.y;
                if (preY > y) {
                    curvePath.cubicTo(
                        preX + (x - preX) / 2,
                        Math.max(preY, cell.y),
                        preX + (x - preX) / 2,
                        Math.min(preY, cell.y),
                        x, cell.y);
                } else {
                    curvePath.cubicTo(
                        preX + (x - preX) / 2,
                        Math.min(preY, cell.y),
                        preX + (x - preX) / 2,
                        Math.max(preY, cell.y),
                        x, cell.y);
                }
            }
        }

        this.renderObj({ dom: cubicToLine, d: curvePath, stroke: textColor, strokeWidth: new UiUtil().dip2px(4), fill: 'none' });
        this.GraphLine = cubicToLine;


        //画节点
        for (let index = 0; index < statisticsContent.cellsData.length; index++) {
            let cell = statisticsContent.cellsData[index];
            let circular1 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
            let circular2 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
            this.context.appendChild(circular1);        //由于放到chartG里面会出现y为0时图标被底部直线遮挡的问题。所以放到了context中
            this.context.appendChild(circular2);

            this.removeArray.push(circular2)
            this.removeArray.push(circular1)
            if (this.selectedIndex == index) {
                this.drawCircular(cell.x + cell.width / 2, cell.y, new UiUtil().dip2px(20), circular1, Colors.getUiColor(cell.color));
                this.drawCircular(cell.x + cell.width / 2, cell.y, new UiUtil().dip2px(12), circular2, Colors.getUiColor(Colors.white15));
            } else {
                this.drawCircular(cell.x + cell.width / 2, cell.y, new UiUtil().dip2px(10), circular1, Colors.getUiColor(cell.color));
                this.drawCircular(cell.x + cell.width / 2, cell.y, new UiUtil().dip2px(6), circular2, Colors.getUiColor(Colors.white15));
            }

            let explainTitle = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            this.context.appendChild(explainTitle);
            this.removeArray.push(explainTitle)
            let explainTitleSize = MindElementCalculation.caluleText(cell.explainTitle, cell.explainTitleFontSize, false);
            let explainTitleText = document.createElement("div")
            explainTitle.appendChild(explainTitleText);
            explainTitle.setAttribute('x', cell.explainTitleX);
            explainTitle.setAttribute('y', cell.explainTitleY);
            explainTitle.setAttribute('width', explainTitleSize.getWidth());
            explainTitle.setAttribute('height', explainTitleSize.getHeight());
            this.setTextDiv({
                dom: explainTitleText, fontSize: cell.explainTitleFontSize,
                color: Colors.getUiColor(this.getTextColor(cell.explainTitleColor)), innerHTML: cell.explainTitle
            });

            let explainDetail = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            this.context.appendChild(explainDetail);
            this.removeArray.push(explainDetail)
            let explainDetailSize = MindElementCalculation.caluleText(cell.explain, new UiUtil().dip2px(cell.explainFontSize), false);
            let explainDetailText = document.createElement("div")
            explainDetail.appendChild(explainDetailText);
            explainDetail.setAttribute('x', cell.explainX);
            explainDetail.setAttribute('y', cell.explainY);
            explainDetail.setAttribute('width', explainDetailSize.getWidth());
            explainDetail.setAttribute('height', explainDetailSize.getHeight());
            this.setTextDiv({
                dom: explainDetailText, fontSize: new UiUtil().dip2px(cell.explainFontSize),
                color: Colors.getUiColor(this.getTextColor(cell.explainColor)), innerHTML: cell.explain
            });

            let explainValue = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            this.context.appendChild(explainValue);
            this.removeArray.push(explainValue)
            let explainValueSize = MindElementCalculation.caluleText(cell.value + '', new UiUtil().dip2px(cell.explainTitleFontSize), false);

            let valueX = cell.x + (cell.width - explainValueSize.getWidth()) / 2;
            let valueY = cell.y - moveY - new UiUtil().dip2px(10) - explainValueSize.getHeight();
            let explainValueText = document.createElement("div")
            explainValue.appendChild(explainValueText);
            explainValue.setAttribute('x', valueX);
            explainValue.setAttribute('y', valueY);
            explainValue.setAttribute('width', explainValueSize.getWidth());
            explainValue.setAttribute('height', explainValueSize.getHeight());
            this.setTextDiv({
                dom: explainValueText, fontSize: new UiUtil().dip2px(cell.explainTitleFontSize),
                color: Colors.getUiColor(cell.explainTitleColor), innerHTML: cell.value
            });


            this.sectorTitleList.push(explainTitleText); //标题数组
            this.sectorContentList.push(explainDetailText);  //内容数组
            this.sectorValueList.push(explainValueText); //所有数值数组
        }

        if (this.backgroundColor != null) {
            this.setAllTextColor(this.backgroundColor);
        }
    }

    drawHistogram2() {
        this.chartG.style.width = this.mindElementData.statisticsContent.width + 'px';
        this.chartG.style.height = this.mindElementData.statisticsContent.height + 'px';
        this.chartG.style.transform = "translate(" + this.mindElementData.statisticsContent.x + 'px,' + this.mindElementData.statisticsContent.y + 'px)';
        let statisticsContent = this.mindElementData.statisticsContent;

        let formLine = document.createElementNS('http://www.w3.org/2000/svg', "path")
        this.context.appendChild(formLine);
        this.removeArray.push(formLine)
        let formArrow = document.createElementNS('http://www.w3.org/2000/svg', "path")
        this.context.appendChild(formArrow);
        this.removeArray.push(formArrow)
        let strokeWidth = new UiUtil().dip2px(2);
        let formLinePath = new LinePath();
        let textColor;
        if (this.backgroundColor != null && Colors.isDarkColor(this.backgroundColor)) {
            textColor = Colors.getUiColor(Colors.white);
        } else {
            textColor = Colors.getUiColor(Colors.black50);
        }
        // let textColor = Colors.getUiColor(Colors.black50);  //需要一个判断是深色还是浅色模式下。
        formLinePath.moveTo(this.contextSpace, this.contextSpace);
        formLinePath.lineTo(this.contextSpace, statisticsContent.cellsData[0].y + statisticsContent.cellsData[0].height + strokeWidth / 2);
        formLinePath.lineTo(this.mindElementData.width - this.contextSpace, statisticsContent.cellsData[0].y + statisticsContent.cellsData[0].height + strokeWidth / 2);
        this.renderObj({ dom: formLine, d: formLinePath, stroke: textColor, strokeWidth, fill: 'none' });
        this.drawArrow(formArrow,
            new CGPoint(this.contextSpace, statisticsContent.cellsData[0].y + statisticsContent.cellsData[0].height + strokeWidth / 2),
            new CGPoint(this.contextSpace, this.contextSpace),
            textColor);
        let length3d = statisticsContent.cellsData[0].width;
        let moveX = length3d / 2;
        let moveY = length3d / 4;
        let bottomSpace = new UiUtil().dip2px(3);
        this.sectorArrow = formArrow; //     箭头
        this.sectorArrowLine = formLine; // 箭头线



        for (let index = 0; index < statisticsContent.cellsData.length; index++) {
            // StatisticsCellData cell = statisticsContent.cellsData.get(index);
            // chartPaint.setStyle(Paint.Style.FILL_AND_STROKE);
            // chartPaint.setStrokeCap(Paint.Cap.ROUND);
            // chartPaint.setAntiAlias(true);
            // chartPaint.setStrokeWidth(strokeWidth);
            let front = document.createElementNS('http://www.w3.org/2000/svg', "path")     //大圆形
            this.chartG.appendChild(front);
            let bottom = document.createElementNS('http://www.w3.org/2000/svg', "path")     //大圆形
            this.chartG.appendChild(bottom);
            let top = document.createElementNS('http://www.w3.org/2000/svg', "path")     //大圆形
            this.chartG.appendChild(top);
            let frontPath = new LinePath();
            let bottomPath = new LinePath();
            let topPath = new LinePath();

            let cell = statisticsContent.cellsData[index];
            let color = cell.color;
            let x = cell.x;
            let y = cell.y;
            let width = cell.width;
            let height = cell.height;
            let addColor = 0
            if (this.selectedIndex == index) {
                addColor = -0.1
                x = x - new UiUtil().dip2px(2);
                width = width + new UiUtil().dip2px(2) * 2;
                y = y - new UiUtil().dip2px(2);
                height = height + new UiUtil().dip2px(2) * 2;
            }

            if (cell.value != 0) {   //数据为0时不渲染。
                frontPath.moveTo(x, y);
                frontPath.lineTo(x + width, y);
                frontPath.lineTo(x + width, y + height - moveY - bottomSpace);
                frontPath.lineTo(x, y + height - moveY - bottomSpace);
                frontPath.lineTo(x, y);
                this.renderObj({ dom: front, d: frontPath, stroke: 'none', strokeWidth: 0, fill: Colors.getNumberToRgbDark(color, 0 + addColor) });


                topPath.moveTo(x, y);
                topPath.circle(moveX, moveY, 0, 0, 1, x + width, y);
                topPath.circle(moveX, moveY, 0, 0, 1, x, y);
                this.renderObj({ dom: top, d: topPath, stroke: 'none', strokeWidth: 0, fill: Colors.getNumberToRgbDark(color, -0.15 + addColor) });
            }


            bottomPath.moveTo(x, y + height - moveY - bottomSpace);
            bottomPath.circle(moveX, moveY, 0, 0, 1, x + width, y + height - moveY - bottomSpace);
            bottomPath.circle(moveX, moveY, 0, 0, 1, x, y + height - moveY - bottomSpace);
            this.renderObj({ dom: bottom, d: bottomPath, stroke: 'none', strokeWidth: 0, fill: Colors.getNumberToRgbDark(color, 0 + addColor) });

            let explainTitle = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            this.context.appendChild(explainTitle);
            this.removeArray.push(explainTitle)
            let explainTitleSize = MindElementCalculation.caluleText(cell.explainTitle, cell.explainTitleFontSize, false);
            let explainTitleText = document.createElement("div")
            explainTitle.appendChild(explainTitleText);
            explainTitle.setAttribute('x', cell.explainTitleX);
            explainTitle.setAttribute('y', cell.explainTitleY);
            explainTitle.setAttribute('width', explainTitleSize.getWidth());
            explainTitle.setAttribute('height', explainTitleSize.getHeight());
            this.setTextDiv({
                dom: explainTitleText, fontSize: cell.explainTitleFontSize,
                color: Colors.getUiColor(this.getTextColor(cell.explainTitleColor)), innerHTML: cell.explainTitle
            });

            let explainDetail = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            this.context.appendChild(explainDetail);
            this.removeArray.push(explainDetail)
            let explainDetailSize = MindElementCalculation.caluleText(cell.explain, new UiUtil().dip2px(cell.explainFontSize), false);
            let explainDetailText = document.createElement("div")
            explainDetail.appendChild(explainDetailText);
            explainDetail.setAttribute('x', cell.explainX);
            explainDetail.setAttribute('y', cell.explainY);
            explainDetail.setAttribute('width', explainDetailSize.getWidth());
            explainDetail.setAttribute('height', explainDetailSize.getHeight());
            this.setTextDiv({
                dom: explainDetailText, fontSize: new UiUtil().dip2px(cell.explainFontSize),
                color: Colors.getUiColor(this.getTextColor(cell.explainColor)), innerHTML: cell.explain
            });

            let explainValue = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            this.context.appendChild(explainValue);
            this.removeArray.push(explainValue)
            let explainValueSize = MindElementCalculation.caluleText(cell.value + '', new UiUtil().dip2px(cell.explainTitleFontSize), false);
            let valueX = x + (width - explainValueSize.getWidth()) / 2;
            let valueY = y - moveY - new UiUtil().dip2px(10) - explainValueSize.getHeight();
            let explainValueText = document.createElement("div")
            explainValue.appendChild(explainValueText);
            explainValue.setAttribute('x', valueX);
            explainValue.setAttribute('y', valueY);
            explainValue.setAttribute('width', explainValueSize.getWidth());
            explainValue.setAttribute('height', explainValueSize.getHeight());
            this.setTextDiv({
                dom: explainValueText, fontSize: new UiUtil().dip2px(cell.explainTitleFontSize),
                color: Colors.getUiColor(cell.explainTitleColor), innerHTML: cell.value
            });

            this.sectorTitleList.push(explainTitleText); //标题数组
            this.sectorContentList.push(explainDetailText);  //内容数组
            this.sectorValueList.push(explainValueText); //所有数值数组
        }

        // if (selectedIndex > -1 && selectedIndex < statisticsContent.cellsData.size() &&
        //         selectedDownPoint.x > -1 && selectedDownPoint.y > -1) {
        //     StatisticsCellData cell = statisticsContent.cellsData.get(selectedIndex);

        //     Size explainTitleSize = MindElementCalculation.caluleText(cell.explainTitle, cell.explainTitleFontSize, false);
        //     Size explainSize = MindElementCalculation.caluleText(cell.explain, cell.explainFontSize, false);
        //     Size valueSize = MindElementCalculation.caluleText(cell.value + "", cell.explainTitleFontSize, false);
        //     int spaceV = UiUtil.dip2px(6);
        //     int topMargin = UiUtil.dip2px(30);
        //     int selectedBgWidth = Math.max(explainTitleSize.getWidth(), explainSize.getWidth()) + topMargin * 2;
        //     int selectedBgHeight = valueSize.getHeight() + explainTitleSize.getHeight() +
        //             explainSize.getHeight() + spaceV * (Strings.isEmpty(cell.explain) ? 1 : 2) + UiUtil.dip2px(20);
        //     CGPoint startPoint = new CGPoint(cell.x + (cell.width - selectedBgWidth) / 2, selectedDownPoint.y - selectedBgHeight/2);
        //     if (startPoint.y < 0) {
        //         startPoint.y = 0;
        //     }
        //     Path bgPath = new Path();

        //     bgPath.moveTo(startPoint.x + UiUtil.dip2px(10), startPoint.y);
        //     bgPath.lineTo(startPoint.x + selectedBgWidth, startPoint.y);
        //     bgPath.lineTo(startPoint.x + selectedBgWidth, startPoint.y + selectedBgHeight);
        //     bgPath.lineTo(startPoint.x, startPoint.y + selectedBgHeight);
        //     bgPath.lineTo(startPoint.x, startPoint.y);
        //     bgPath.lineTo(startPoint.x + UiUtil.dip2px(10), startPoint.y);

        //     chartPaint.setStrokeWidth(UiUtil.dip2px(8));
        //     chartPaint.setStyle(Paint.Style.STROKE);
        //     chartPaint.setColor(Colors.getUiColor(cell.color));
        //     chartPaint.setPathEffect(new CornerPathEffect(UiUtil.dip2px(6)));
        //     originalCanvas.drawPath(bgPath, chartPaint);

        //     chartPaint.setStrokeWidth(UiUtil.dip2px(8));
        //     chartPaint.setStyle(Paint.Style.FILL);
        //     chartPaint.setColor(Colors.getUiColor(Colors.white));
        //     chartPaint.setPathEffect(new CornerPathEffect(UiUtil.dip2px(6)));
        //     originalCanvas.drawPath(bgPath, chartPaint);

        //     originalCanvas.drawText(cell.value + "", cell.x + valueSize.getWidth(),
        //             startPoint.y + valueSize.getHeight() + UiUtil.dip2px(10) - UiUtil.dip2px(2), chartTextTitlePaint);
        //     originalCanvas.drawText(cell.explainTitle, cell.explainTitleX + explainTitleSize.getWidth(),
        //             startPoint.y + selectedBgHeight / 2 + explainTitleSize.getHeight() / 2 - UiUtil.dip2px(2),
        //             chartTextTitlePaint);
        //     originalCanvas.drawText(cell.explain, cell.explainX + explainSize.getWidth(),
        //             startPoint.y + selectedBgHeight - UiUtil.dip2px(12), chartTextPaint);
        // }
        if (this.backgroundColor != null) {
            this.setAllTextColor(this.backgroundColor);
        }
    }


    drawHistogram1() {
        this.chartG.style.width = this.mindElementData.statisticsContent.width + 'px';
        this.chartG.style.height = this.mindElementData.statisticsContent.height + 'px';
        this.chartG.style.transform = "translate(" + this.mindElementData.statisticsContent.x + 'px,' + this.mindElementData.statisticsContent.y + 'px)';
        let statisticsContent = this.mindElementData.statisticsContent;

        let formLine = document.createElementNS('http://www.w3.org/2000/svg', "path")
        this.context.appendChild(formLine);
        this.removeArray.push(formLine)
        let formArrow = document.createElementNS('http://www.w3.org/2000/svg', "path")
        this.context.appendChild(formArrow);
        this.removeArray.push(formArrow)
        let strokeWidth = new UiUtil().dip2px(2);
        let formLinePath = new LinePath();
        let textColor;
        if (this.backgroundColor != null && Colors.isDarkColor(this.backgroundColor)) {
            textColor = Colors.getUiColor(Colors.white);
        } else {
            textColor = Colors.getUiColor(Colors.black50);
        }
        // let textColor = Colors.getUiColor(Colors.black50);  //需要一个判断是深色还是浅色模式下。
        formLinePath.moveTo(this.contextSpace, this.contextSpace);
        formLinePath.lineTo(this.contextSpace, statisticsContent.cellsData[0].y + statisticsContent.cellsData[0].height + strokeWidth / 2);
        formLinePath.lineTo(this.mindElementData.width - this.contextSpace, statisticsContent.cellsData[0].y + statisticsContent.cellsData[0].height + strokeWidth / 2);
        this.renderObj({ dom: formLine, d: formLinePath, stroke: textColor, strokeWidth, fill: 'none' });
        this.drawArrow(formArrow,
            new CGPoint(this.contextSpace, statisticsContent.cellsData[0].y + statisticsContent.cellsData[0].height + strokeWidth / 2),
            new CGPoint(this.contextSpace, this.contextSpace),
            textColor);
        let angle = 45.0;
        let length3d = statisticsContent.cellsData[0].width * 0.6;
        let moveX = length3d * Util.cosd(angle);
        let moveY = length3d * Util.sind(angle);
        this.sectorArrow = formArrow; //     箭头
        this.sectorArrowLine = formLine; // 箭头线

        //柱状图
        for (let index = 0; index < statisticsContent.cellsData.length; index++) {
            let front = document.createElementNS('http://www.w3.org/2000/svg', "path")     //大圆形
            this.chartG.appendChild(front);
            let side = document.createElementNS('http://www.w3.org/2000/svg', "path")     //大圆形
            this.chartG.appendChild(side);
            let top = document.createElementNS('http://www.w3.org/2000/svg', "path")     //大圆形
            this.chartG.appendChild(top);
            let frontPath = new LinePath();
            let sidePath = new LinePath();
            let topPath = new LinePath();

            let cell = statisticsContent.cellsData[index];
            let color = cell.color;
            let x = cell.x;
            let y = cell.y;
            let width = cell.width;
            let height = cell.height;
            let addColor = 0
            if (this.selectedIndex == index) {
                addColor = -0.1
                x = x - new UiUtil().dip2px(2);
                width = width + new UiUtil().dip2px(2) * 2;
                y = y - new UiUtil().dip2px(2);
                height = height + new UiUtil().dip2px(2) * 2;
            }

            frontPath.moveTo(x, y);
            frontPath.lineTo(x + width, y);
            frontPath.lineTo(x + width, y + height);
            frontPath.lineTo(x, y + height);
            frontPath.lineTo(x, y);
            this.renderObj({ dom: front, d: frontPath, stroke: 'none', strokeWidth: 0, fill: Colors.getNumberToRgbDark(color, 0 + addColor) });

            topPath.moveTo(x, y);
            topPath.lineTo(x + width, y);
            topPath.lineTo(x + width + moveX, y - moveY);
            topPath.lineTo(x + moveX, y - moveY);
            topPath.lineTo(x, y);
            this.renderObj({ dom: top, d: topPath, stroke: 'none', strokeWidth: 0, fill: Colors.getNumberToRgbDark(color, -0.15 + addColor) });

            sidePath.moveTo(x + width, y);
            sidePath.lineTo(x + width + moveX, y - moveY);
            sidePath.lineTo(x + width + moveX, y + height - moveY);
            sidePath.lineTo(x + width, y + height);
            sidePath.lineTo(x + width, y);
            this.renderObj({ dom: side, d: sidePath, stroke: 'none', strokeWidth: 0, fill: Colors.getNumberToRgbDark(color, 0.15 + addColor) });

            let explainTitle = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            this.context.appendChild(explainTitle);
            this.removeArray.push(explainTitle)
            let explainTitleSize = MindElementCalculation.caluleText(cell.explainTitle, cell.explainTitleFontSize, false);
            let explainTitleText = document.createElement("div")
            explainTitle.appendChild(explainTitleText);
            explainTitle.setAttribute('x', cell.explainTitleX);
            explainTitle.setAttribute('y', cell.explainTitleY);
            explainTitle.setAttribute('width', explainTitleSize.getWidth());
            explainTitle.setAttribute('height', explainTitleSize.getHeight());
            this.setTextDiv({
                dom: explainTitleText, fontSize: cell.explainTitleFontSize,
                color: Colors.getUiColor(this.getTextColor(cell.explainTitleColor)), innerHTML: cell.explainTitle
            });

            let explainDetail = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            this.context.appendChild(explainDetail);
            this.removeArray.push(explainDetail)
            let explainDetailSize = MindElementCalculation.caluleText(cell.explain, new UiUtil().dip2px(cell.explainFontSize), false);
            let explainDetailText = document.createElement("div")
            explainDetail.appendChild(explainDetailText);
            explainDetail.setAttribute('x', cell.explainX);
            explainDetail.setAttribute('y', cell.explainY);
            explainDetail.setAttribute('width', explainDetailSize.getWidth());
            explainDetail.setAttribute('height', explainDetailSize.getHeight());
            this.setTextDiv({
                dom: explainDetailText, fontSize: new UiUtil().dip2px(cell.explainFontSize),
                color: Colors.getUiColor(this.getTextColor(cell.explainColor)), innerHTML: cell.explain
            });

            let explainValue = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            this.context.appendChild(explainValue);
            this.removeArray.push(explainValue)
            let explainValueSize = MindElementCalculation.caluleText(cell.value + '', new UiUtil().dip2px(cell.explainTitleFontSize), false);
            let valueX = cell.x + (cell.width + moveX - explainValueSize.getWidth()) / 2;
            let valueY = cell.y - moveY - new UiUtil().dip2px(10) - explainValueSize.getHeight();
            let explainValueText = document.createElement("div")
            explainValue.appendChild(explainValueText);
            explainValue.setAttribute('x', valueX);
            explainValue.setAttribute('y', valueY);
            explainValue.setAttribute('width', explainValueSize.getWidth());
            explainValue.setAttribute('height', explainValueSize.getHeight());
            this.setTextDiv({
                dom: explainValueText, fontSize: new UiUtil().dip2px(cell.explainTitleFontSize),
                color: Colors.getUiColor(cell.explainTitleColor), innerHTML: cell.value
            });

            this.sectorTitleList.push(explainTitleText); //标题数组
            this.sectorContentList.push(explainDetailText);  //内容数组
            this.sectorValueList.push(explainValueText); //所有数值数组
        }

        // //弹框文字
        // if (selectedIndex > -1 && selectedIndex < statisticsContent.cellsData.size() &&
        //         selectedDownPoint.x > -1 && selectedDownPoint.y > -1) {
        //     StatisticsCellData cell = statisticsContent.cellsData.get(selectedIndex);

        //     Size explainTitleSize = MindElementCalculation.caluleText(cell.explainTitle, cell.explainTitleFontSize, false);
        //     Size explainSize = MindElementCalculation.caluleText(cell.explain, cell.explainFontSize, false);
        //     Size valueSize = MindElementCalculation.caluleText(cell.value + "", cell.explainTitleFontSize, false);
        //     int spaceV = UiUtil.dip2px(6);
        //     int topMargin = UiUtil.dip2px(30);
        //     int selectedBgWidth = Math.max(explainTitleSize.getWidth(), explainSize.getWidth()) + topMargin * 2;
        //     int selectedBgHeight = valueSize.getHeight() + explainTitleSize.getHeight() +
        //             explainSize.getHeight() + spaceV * (Strings.isEmpty(cell.explain) ? 1 : 2) + UiUtil.dip2px(20);
        //     CGPoint startPoint = new CGPoint(cell.x + (cell.width - selectedBgWidth) / 2, selectedDownPoint.y - selectedBgHeight/2);
        //     if (startPoint.y < 0) {
        //         startPoint.y = 0;
        //     }
        //     Path bgPath = new Path();

        //     bgPath.moveTo(startPoint.x + UiUtil.dip2px(10), startPoint.y);
        //     bgPath.lineTo(startPoint.x + selectedBgWidth, startPoint.y);
        //     bgPath.lineTo(startPoint.x + selectedBgWidth, startPoint.y + selectedBgHeight);
        //     bgPath.lineTo(startPoint.x, startPoint.y + selectedBgHeight);
        //     bgPath.lineTo(startPoint.x, startPoint.y);
        //     bgPath.lineTo(startPoint.x + UiUtil.dip2px(10), startPoint.y);

        //     chartPaint.setStrokeWidth(UiUtil.dip2px(8));
        //     chartPaint.setStyle(Paint.Style.STROKE);
        //     chartPaint.setColor(Colors.getUiColor(cell.color));
        //     chartPaint.setPathEffect(new CornerPathEffect(UiUtil.dip2px(6)));
        //     originalCanvas.drawPath(bgPath, chartPaint);

        //     chartPaint.setStrokeWidth(UiUtil.dip2px(8));
        //     chartPaint.setStyle(Paint.Style.FILL);
        //     chartPaint.setColor(Colors.getUiColor(Colors.white));
        //     chartPaint.setPathEffect(new CornerPathEffect(UiUtil.dip2px(6)));
        //     originalCanvas.drawPath(bgPath, chartPaint);

        //     originalCanvas.drawText(cell.value + "", cell.x + valueSize.getWidth(),
        //             startPoint.y + valueSize.getHeight() + UiUtil.dip2px(10) - UiUtil.dip2px(2), chartTextTitlePaint);
        //     originalCanvas.drawText(cell.explainTitle, cell.explainTitleX + explainTitleSize.getWidth(),
        //             startPoint.y + selectedBgHeight / 2 + explainTitleSize.getHeight() / 2 - UiUtil.dip2px(2),
        //             chartTextTitlePaint);
        //     originalCanvas.drawText(cell.explain, cell.explainX + explainSize.getWidth(),
        //             startPoint.y + selectedBgHeight - UiUtil.dip2px(12), chartTextPaint);
        // }
        if (this.backgroundColor != null) {
            this.setAllTextColor(this.backgroundColor);
        }
    }


    drawCakeShape5() {
        this.chartG.style.width = this.mindElementData.statisticsContent.width + 'px';
        this.chartG.style.height = this.mindElementData.statisticsContent.height + 'px';
        this.chartG.style.transform = "translate(" + this.mindElementData.statisticsContent.x + 'px,' + this.mindElementData.statisticsContent.y + 'px)';
        let statisticsContent = this.mindElementData.statisticsContent;
        for (let index = 0; index < statisticsContent.cellsData.length; index++) {
            let cell = statisticsContent.cellsData[index];
            let sector = document.createElementNS('http://www.w3.org/2000/svg', "path")     //大圆形
            this.chartG.appendChild(sector);
            let sector1 = document.createElementNS('http://www.w3.org/2000/svg', "path")     //中圆形
            this.chartG.appendChild(sector1);
            let path = new LinePath();
            let smallPath = new LinePath();
            let radius = statisticsContent.radius;
            let centerX = cell.x;
            let centerY = cell.y;
            let smallRadius = radius / 2;
            let smallRadius2 = radius / 2 - 6;
            let controllPoninRadius = (radius - radius / 4); //控制点的半径
            let twist = 20; //控制点偏移的角度
            if (this.selectedIndex == index) {
                let selectedMoveX = new UiUtil().dip2px(10) * Util.cosd(cell.startAngle + cell.angle / 2);
                let selectedMoveY = new UiUtil().dip2px(10) * Util.sind(cell.startAngle + cell.angle / 2);
                centerX += selectedMoveX
                centerY += selectedMoveY
            }

            let startNewX = centerX + (Util.cosd(cell.startAngle)) * radius;      //最外层的弧线
            let startNewY = centerY + (Util.sind(cell.startAngle)) * radius;
            let oppositeStartNewX = centerX - (Util.cosd(cell.startAngle)) * radius;  //用于画完整的圆形
            let oppositeStartNewY = centerY - (Util.sind(cell.startAngle)) * radius; //用于画完整的圆形。
            let endNewX = centerX + Util.cosd(cell.startAngle + cell.angle) * radius;
            let endNewY = centerY + Util.sind(cell.startAngle + cell.angle) * radius;

            let smallStartNewX = centerX + (Util.cosd(cell.startAngle)) * smallRadius;
            let smallStartNewY = centerY + (Util.sind(cell.startAngle)) * smallRadius;
            let oppositeSmallStartNewX = centerX - (Util.cosd(cell.startAngle)) * smallRadius;  //用于画完整的圆形。
            let oppositeSmallStartNewY = centerY - (Util.sind(cell.startAngle)) * smallRadius; //用于画完整的圆形。
            let smallEndNewX = centerX + Util.cosd(cell.startAngle + cell.angle) * smallRadius;
            let smallEndNewY = centerY + Util.sind(cell.startAngle + cell.angle) * smallRadius;

            let smallStartNewX2 = centerX + (Util.cosd(cell.startAngle)) * smallRadius2;
            let smallStartNewY2 = centerY + (Util.sind(cell.startAngle)) * smallRadius2;
            let oppositeSmallStartNewX2 = centerX - (Util.cosd(cell.startAngle)) * smallRadius2;  //用于画完整的圆形
            let oppositeSmallStartNewY2 = centerY - (Util.sind(cell.startAngle)) * smallRadius2; //用于画完整的圆形。
            let smallEndNewX2 = centerX + Util.cosd(cell.startAngle + cell.angle) * smallRadius2;
            let smallEndNewY2 = centerY + Util.sind(cell.startAngle + cell.angle) * smallRadius2;

            let startCX = centerX + Util.cosd(cell.startAngle + twist) * controllPoninRadius;
            let startCY = centerY + Util.sind(cell.startAngle + twist) * controllPoninRadius;
            // let endCX = centerX +  Util.cosd(cell.startAngle - twist) * controllPoninRadius;
            // let endCY = centerY +  Util.sind(cell.startAngle - twist) * controllPoninRadius;

            let startCX2 = centerX + Util.cosd(cell.startAngle + cell.angle + twist) * controllPoninRadius;
            let startCY2 = centerY + Util.sind(cell.startAngle + cell.angle + twist) * controllPoninRadius;
            // let endCX2 = centerX +  Util.cosd(cell.startAngle + cell.angle - twist) * controllPoninRadius;
            // let endCY2 = centerY +  Util.sind(cell.startAngle + cell.angle - twist) * controllPoninRadius;

            //画扇形的弧线
            path.moveTo(startNewX, startNewY);
            path.cubicOneTo(startCX, startCY, smallStartNewX, smallStartNewY)
            path.lineTo(smallStartNewX, smallStartNewY);
            if (cell.angle >= 180) { //大圆
                if (cell.angle == 360) {
                    path.circle(smallRadius, smallRadius, 0, 0, 1, oppositeSmallStartNewX, oppositeSmallStartNewY);
                    path.circle(smallRadius, smallRadius, 0, 0, 1, smallEndNewX, smallEndNewY);
                } else {
                    path.circle(smallRadius, smallRadius, 0, 1, 1, smallEndNewX, smallEndNewY);
                }
            } else {    //小圆形
                path.circle(smallRadius, smallRadius, 0, 0, 1, smallEndNewX, smallEndNewY);
            }
            path.cubicOneTo(startCX2, startCY2, endNewX, endNewY)
            // path.lineTo(endNewX,endNewY);
            if (cell.angle >= 180) { //大圆
                if (cell.angle == 360) {
                    path.circle(radius, radius, 0, 0, 0, oppositeStartNewX, oppositeStartNewY);
                    path.circle(radius, radius, 0, 0, 0, startNewX, startNewY);
                } else {
                    path.circle(radius, radius, 0, 1, 0, startNewX, startNewY);
                }
            } else {    //小圆形
                path.circle(radius, radius, 0, 0, 0, startNewX, startNewY);
            }
            this.renderCanvas(path, sector, Colors.getUiColor(cell.color));

            //画中扇形的弧线
            smallPath.moveTo(smallStartNewX, smallStartNewY);
            smallPath.lineTo(smallStartNewX2, smallStartNewY2);
            if (cell.angle >= 180) { //大圆
                if (cell.angle == 360) {
                    smallPath.circle(smallRadius2, smallRadius2, 0, 0, 1, oppositeSmallStartNewX2, oppositeSmallStartNewY2);
                    smallPath.circle(smallRadius2, smallRadius2, 0, 0, 1, smallEndNewX2, smallEndNewY2);
                } else {
                    smallPath.circle(smallRadius2, smallRadius2, 0, 1, 1, smallEndNewX2, smallEndNewY2);
                }
            } else {    //小圆形
                smallPath.circle(smallRadius2, smallRadius2, 0, 0, 1, smallEndNewX2, smallEndNewY2);
            }
            smallPath.lineTo(smallEndNewX, smallEndNewY);
            if (cell.angle >= 180) { //大圆
                if (cell.angle == 360) {
                    smallPath.circle(smallRadius, smallRadius, 0, 0, 0, oppositeSmallStartNewX, oppositeSmallStartNewY);
                    smallPath.circle(smallRadius, smallRadius, 0, 0, 0, smallStartNewX, smallStartNewY);
                } else {
                    smallPath.circle(smallRadius, smallRadius, 0, 1, 0, smallStartNewX, smallStartNewY);
                }
            } else {    //小圆形
                smallPath.circle(smallRadius, smallRadius, 0, 0, 0, smallStartNewX, smallStartNewY);
            }
            this.renderCanvas(smallPath, sector1, Colors.getNumberToRgbDark(cell.color, 0.2));


            //画关联线还有圆形
            if (cell.explainLines.length > 1) {
                let line = document.createElementNS('http://www.w3.org/2000/svg', "path");
                let linepath = new LinePath();
                linepath.moveTo(cell.explainLines[0].x, cell.explainLines[0].y);
                for (let lineIndex = 1; lineIndex < cell.explainLines.length; lineIndex++) {
                    linepath.lineTo(cell.explainLines[lineIndex].x, cell.explainLines[lineIndex].y);
                }
                this.renderLine(linepath, line, cell.lineColor, cell.lineWidth)
                this.context.appendChild(line);
                this.removeArray.push(line)
                let lineCircularRadius = new UiUtil().dip2px(cell.lineWidth) * 2;
                let circular1 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
                let circular2 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
                this.drawCircular(cell.explainLines[0].x, cell.explainLines[0].y, lineCircularRadius, circular1, Colors.getUiColor(cell.lineColor))
                this.drawCircular(cell.explainLines[0].x, cell.explainLines[0].y, lineCircularRadius * 2, circular2, Colors.getUiColorByAlpha(cell.lineColor, 0.3))
                this.context.appendChild(circular2);
                this.context.appendChild(circular1);
                this.removeArray.push(circular2)
                this.removeArray.push(circular1)
            }

            let explainTitleContent = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            let explainContent = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            this.context.appendChild(explainTitleContent);
            this.context.appendChild(explainContent);
            this.removeArray.push(explainTitleContent)
            this.removeArray.push(explainContent)
            //写编辑还有详情
            let explainTitleSize = MindElementCalculation.caluleText(cell.explainTitle, cell.explainTitleFontSize, false);
            let explainSize = MindElementCalculation.caluleText(cell.explain, cell.explainFontSize, false);

            let explainTitleContentText = document.createElement("div")
            let explainContentText = document.createElement("div")
            explainTitleContent.appendChild(explainTitleContentText);
            explainContent.appendChild(explainContentText);

            explainTitleContent.setAttribute('x', cell.explainTitleX);
            explainTitleContent.setAttribute('y', cell.explainTitleY);
            explainTitleContent.setAttribute('width', explainTitleSize.getWidth());
            explainTitleContent.setAttribute('height', explainTitleSize.getHeight());
            this.setTextDom(explainTitleContentText, cell, (new UiUtil().dip2px(cell.explainTitleFontSize)), cell.explainTitle);

            explainContent.setAttribute('x', cell.explainX);
            explainContent.setAttribute('y', cell.explainY);
            explainContent.setAttribute('width', explainSize.getWidth());
            explainContent.setAttribute('height', explainSize.getHeight());
            this.setTextDom(explainContentText, cell, (new UiUtil().dip2px(cell.explainFontSize)), cell.explain);

            this.sectorTitleList.push(explainTitleContentText); //标题数组
            this.sectorContentList.push(explainContentText);  //内容数组
        }
        if (this.backgroundColor != null) {
            this.setAllTextColor(this.backgroundColor);
        }
    }

    drawCakeShape4() {

        this.chartG.style.width = this.mindElementData.statisticsContent.width + 'px';
        this.chartG.style.height = this.mindElementData.statisticsContent.height + 'px';
        this.chartG.style.transform = "translate(" + this.mindElementData.statisticsContent.x + 'px,' + this.mindElementData.statisticsContent.y + 'px)';
        let statisticsContent = this.mindElementData.statisticsContent;
        for (let index = 0; index < statisticsContent.cellsData.length; index++) {
            let cell = statisticsContent.cellsData[index];
            let sector = document.createElementNS('http://www.w3.org/2000/svg', "path")     //大圆形
            this.chartG.appendChild(sector);
            let sector1 = document.createElementNS('http://www.w3.org/2000/svg', "path")     //中圆形
            this.chartG.appendChild(sector1);
            let path = new LinePath();
            let smallPath = new LinePath();
            let radius = statisticsContent.radius;
            let centerX = cell.x;
            let centerY = cell.y;
            let smallRadius = radius / 2;
            let smallRadius2 = radius / 2 - 6;
            let controllPoninRadius = radius - radius / 5; //控制点的半径
            let twist = 10; //控制点偏移的角度
            if (this.selectedIndex == index) {
                let selectedMoveX = new UiUtil().dip2px(10) * Util.cosd(cell.startAngle + cell.angle / 2);
                let selectedMoveY = new UiUtil().dip2px(10) * Util.sind(cell.startAngle + cell.angle / 2);
                centerX += selectedMoveX
                centerY += selectedMoveY
            }

            let startNewX = centerX + (Util.cosd(cell.startAngle)) * radius;      //最外层的弧线
            let startNewY = centerY + (Util.sind(cell.startAngle)) * radius;
            let oppositeStartNewX = centerX - (Util.cosd(cell.startAngle)) * radius;  //用于画完整的圆形
            let oppositeStartNewY = centerY - (Util.sind(cell.startAngle)) * radius; //用于画完整的圆形。
            let endNewX = centerX + Util.cosd(cell.startAngle + cell.angle) * radius;
            let endNewY = centerY + Util.sind(cell.startAngle + cell.angle) * radius;

            let smallStartNewX = centerX + (Util.cosd(cell.startAngle)) * smallRadius;
            let smallStartNewY = centerY + (Util.sind(cell.startAngle)) * smallRadius;
            let oppositeSmallStartNewX = centerX - (Util.cosd(cell.startAngle)) * smallRadius;  //用于画完整的圆形。
            let oppositeSmallStartNewY = centerY - (Util.sind(cell.startAngle)) * smallRadius; //用于画完整的圆形。
            let smallEndNewX = centerX + Util.cosd(cell.startAngle + cell.angle) * smallRadius;
            let smallEndNewY = centerY + Util.sind(cell.startAngle + cell.angle) * smallRadius;

            let smallStartNewX2 = centerX + (Util.cosd(cell.startAngle)) * smallRadius2;
            let smallStartNewY2 = centerY + (Util.sind(cell.startAngle)) * smallRadius2;
            let oppositeSmallStartNewX2 = centerX - (Util.cosd(cell.startAngle)) * smallRadius2;  //用于画完整的圆形
            let oppositeSmallStartNewY2 = centerY - (Util.sind(cell.startAngle)) * smallRadius2; //用于画完整的圆形。
            let smallEndNewX2 = centerX + Util.cosd(cell.startAngle + cell.angle) * smallRadius2;
            let smallEndNewY2 = centerY + Util.sind(cell.startAngle + cell.angle) * smallRadius2;

            let startCX = centerX + Util.cosd(cell.startAngle + twist) * controllPoninRadius;
            let startCY = centerY + Util.sind(cell.startAngle + twist) * controllPoninRadius;
            let endCX = centerX + Util.cosd(cell.startAngle - twist) * controllPoninRadius;
            let endCY = centerY + Util.sind(cell.startAngle - twist) * controllPoninRadius;

            let startCX2 = centerX + Util.cosd(cell.startAngle + cell.angle + twist) * controllPoninRadius;
            let startCY2 = centerY + Util.sind(cell.startAngle + cell.angle + twist) * controllPoninRadius;
            let endCX2 = centerX + Util.cosd(cell.startAngle + cell.angle - twist) * controllPoninRadius;
            let endCY2 = centerY + Util.sind(cell.startAngle + cell.angle - twist) * controllPoninRadius;

            //画扇形的弧线
            path.moveTo(startNewX, startNewY);
            path.cubicTo(startCX, startCY, endCX, endCY, smallStartNewX, smallStartNewY)
            path.lineTo(smallStartNewX, smallStartNewY);
            if (cell.angle >= 180) { //大圆
                if (cell.angle == 360) {
                    path.circle(smallRadius, smallRadius, 0, 0, 1, oppositeSmallStartNewX, oppositeSmallStartNewY);
                    path.circle(smallRadius, smallRadius, 0, 0, 1, smallEndNewX, smallEndNewY);
                } else {
                    path.circle(smallRadius, smallRadius, 0, 1, 1, smallEndNewX, smallEndNewY);
                }
            } else {    //小圆形
                path.circle(smallRadius, smallRadius, 0, 0, 1, smallEndNewX, smallEndNewY);
            }
            path.cubicTo(endCX2, endCY2, startCX2, startCY2, endNewX, endNewY)
            // path.lineTo(endNewX,endNewY);
            if (cell.angle >= 180) { //大圆
                if (cell.angle == 360) {
                    path.circle(radius, radius, 0, 0, 0, oppositeStartNewX, oppositeStartNewY);
                    path.circle(radius, radius, 0, 0, 0, startNewX, startNewY);
                } else {
                    path.circle(radius, radius, 0, 1, 0, startNewX, startNewY);
                }
            } else {    //小圆形
                path.circle(radius, radius, 0, 0, 0, startNewX, startNewY);
            }
            this.renderCanvas(path, sector, Colors.getUiColor(cell.color));

            //画中扇形的弧线
            smallPath.moveTo(smallStartNewX, smallStartNewY);
            smallPath.lineTo(smallStartNewX2, smallStartNewY2);
            if (cell.angle >= 180) { //大圆
                if (cell.angle == 360) {
                    smallPath.circle(smallRadius2, smallRadius2, 0, 0, 1, oppositeSmallStartNewX2, oppositeSmallStartNewY2);
                    smallPath.circle(smallRadius2, smallRadius2, 0, 0, 1, smallEndNewX2, smallEndNewY2);
                } else {
                    smallPath.circle(smallRadius2, smallRadius2, 0, 1, 1, smallEndNewX2, smallEndNewY2);
                }
            } else {    //小圆形
                smallPath.circle(smallRadius2, smallRadius2, 0, 0, 1, smallEndNewX2, smallEndNewY2);
            }
            smallPath.lineTo(smallEndNewX, smallEndNewY);
            if (cell.angle >= 180) { //大圆
                if (cell.angle == 360) {
                    smallPath.circle(smallRadius, smallRadius, 0, 0, 0, oppositeSmallStartNewX, oppositeSmallStartNewY);
                    smallPath.circle(smallRadius, smallRadius, 0, 0, 0, smallStartNewX, smallStartNewY);
                } else {
                    smallPath.circle(smallRadius, smallRadius, 0, 1, 0, smallStartNewX, smallStartNewY);
                }
            } else {    //小圆形
                smallPath.circle(smallRadius, smallRadius, 0, 0, 0, smallStartNewX, smallStartNewY);
            }
            this.renderCanvas(smallPath, sector1, Colors.getNumberToRgbDark(cell.color, 0.2));


            //画关联线还有圆形
            if (cell.explainLines.length > 1) {
                let line = document.createElementNS('http://www.w3.org/2000/svg', "path");
                let linepath = new LinePath();
                linepath.moveTo(cell.explainLines[0].x, cell.explainLines[0].y);
                for (let lineIndex = 1; lineIndex < cell.explainLines.length; lineIndex++) {
                    linepath.lineTo(cell.explainLines[lineIndex].x, cell.explainLines[lineIndex].y);
                }
                this.renderLine(linepath, line, cell.lineColor, cell.lineWidth)
                this.context.appendChild(line);
                this.removeArray.push(line)
                let lineCircularRadius = new UiUtil().dip2px(cell.lineWidth) * 2;
                let circular1 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
                let circular2 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
                this.drawCircular(cell.explainLines[0].x, cell.explainLines[0].y, lineCircularRadius, circular1, Colors.getUiColor(cell.lineColor))
                this.drawCircular(cell.explainLines[0].x, cell.explainLines[0].y, lineCircularRadius * 2, circular2, Colors.getUiColorByAlpha(cell.lineColor, 0.3))
                this.context.appendChild(circular2);
                this.context.appendChild(circular1);
                this.removeArray.push(circular2)
                this.removeArray.push(circular1)
            }

            let explainTitleContent = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            let explainContent = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            this.context.appendChild(explainTitleContent);
            this.context.appendChild(explainContent);
            this.removeArray.push(explainTitleContent)
            this.removeArray.push(explainContent)
            //写编辑还有详情
            let explainTitleSize = MindElementCalculation.caluleText(cell.explainTitle, cell.explainTitleFontSize, false);
            let explainSize = MindElementCalculation.caluleText(cell.explain, cell.explainFontSize, false);

            let explainTitleContentText = document.createElement("div")
            let explainContentText = document.createElement("div")
            explainTitleContent.appendChild(explainTitleContentText);
            explainContent.appendChild(explainContentText);

            explainTitleContent.setAttribute('x', cell.explainTitleX);
            explainTitleContent.setAttribute('y', cell.explainTitleY);
            explainTitleContent.setAttribute('width', explainTitleSize.getWidth());
            explainTitleContent.setAttribute('height', explainTitleSize.getHeight());
            this.setTextDom(explainTitleContentText, cell, (new UiUtil().dip2px(cell.explainTitleFontSize)), cell.explainTitle);

            explainContent.setAttribute('x', cell.explainX);
            explainContent.setAttribute('y', cell.explainY);
            explainContent.setAttribute('width', explainSize.getWidth());
            explainContent.setAttribute('height', explainSize.getHeight());
            this.setTextDom(explainContentText, cell, (new UiUtil().dip2px(cell.explainFontSize)), cell.explain);

            this.sectorTitleList.push(explainTitleContentText); //标题数组
            this.sectorContentList.push(explainContentText);  //内容数组
        }
        if (this.backgroundColor != null) {
            this.setAllTextColor(this.backgroundColor);
        }
    }

    drawCakeShape3() {

        this.chartG.style.width = this.mindElementData.statisticsContent.width + 'px';
        this.chartG.style.height = this.mindElementData.statisticsContent.height + 'px';
        this.chartG.style.transform = "translate(" + this.mindElementData.statisticsContent.x + 'px,' + this.mindElementData.statisticsContent.y + 'px)';
        let statisticsContent = this.mindElementData.statisticsContent;
        for (let index = 0; index < statisticsContent.cellsData.length; index++) {
            let cell = statisticsContent.cellsData[index];
            let sector = document.createElementNS('http://www.w3.org/2000/svg', "path")     //大圆形
            this.chartG.appendChild(sector);
            let sector1 = document.createElementNS('http://www.w3.org/2000/svg', "path")     //中圆形
            this.chartG.appendChild(sector1);
            let path = new LinePath();
            let smallPath = new LinePath();
            let radius = statisticsContent.radius;
            let centerX = cell.x;
            let centerY = cell.y;
            let smallRadius = radius / 2;
            let smallRadius2 = radius / 2 - 4;

            if (this.selectedIndex == index) {
                let selectedMoveX = new UiUtil().dip2px(10) * Util.cosd(cell.startAngle + cell.angle / 2);
                let selectedMoveY = new UiUtil().dip2px(10) * Util.sind(cell.startAngle + cell.angle / 2);
                centerX += selectedMoveX
                centerY += selectedMoveY
            }

            let startNewX = centerX + (Util.cosd(cell.startAngle)) * radius;      //最外层的弧线
            let startNewY = centerY + (Util.sind(cell.startAngle)) * radius;
            let oppositeStartNewX = centerX - (Util.cosd(cell.startAngle)) * radius;  //用于画完整的圆形
            let oppositeStartNewY = centerY - (Util.sind(cell.startAngle)) * radius; //用于画完整的圆形。
            let endNewX = centerX + Util.cosd(cell.startAngle + cell.angle) * radius;
            let endNewY = centerY + Util.sind(cell.startAngle + cell.angle) * radius;

            let smallStartNewX = centerX + (Util.cosd(cell.startAngle)) * smallRadius;
            let smallStartNewY = centerY + (Util.sind(cell.startAngle)) * smallRadius;
            let oppositeSmallStartNewX = centerX - (Util.cosd(cell.startAngle)) * smallRadius;  //用于画完整的圆形。
            let oppositeSmallStartNewY = centerY - (Util.sind(cell.startAngle)) * smallRadius; //用于画完整的圆形。
            let smallEndNewX = centerX + Util.cosd(cell.startAngle + cell.angle) * smallRadius;
            let smallEndNewY = centerY + Util.sind(cell.startAngle + cell.angle) * smallRadius;

            let smallStartNewX2 = centerX + (Util.cosd(cell.startAngle)) * smallRadius2;
            let smallStartNewY2 = centerY + (Util.sind(cell.startAngle)) * smallRadius2;
            let oppositeSmallStartNewX2 = centerX - (Util.cosd(cell.startAngle)) * smallRadius2;  //用于画完整的圆形
            let oppositeSmallStartNewY2 = centerY - (Util.sind(cell.startAngle)) * smallRadius2; //用于画完整的圆形。
            let smallEndNewX2 = centerX + Util.cosd(cell.startAngle + cell.angle) * smallRadius2;
            let smallEndNewY2 = centerY + Util.sind(cell.startAngle + cell.angle) * smallRadius2;

            //画扇形的弧线
            path.moveTo(startNewX, startNewY);
            path.lineTo(smallStartNewX, smallStartNewY);
            if (cell.angle >= 180) { //大圆
                if (cell.angle == 360) {
                    path.circle(smallRadius, smallRadius, 0, 0, 1, oppositeSmallStartNewX, oppositeSmallStartNewY);
                    path.circle(smallRadius, smallRadius, 0, 0, 1, smallEndNewX, smallEndNewY);
                } else {
                    path.circle(smallRadius, smallRadius, 0, 1, 1, smallEndNewX, smallEndNewY);
                }
            } else {    //小圆形
                path.circle(smallRadius, smallRadius, 0, 0, 1, smallEndNewX, smallEndNewY);
            }
            path.lineTo(endNewX, endNewY);
            if (cell.angle >= 180) { //大圆
                if (cell.angle == 360) {
                    path.circle(radius, radius, 0, 0, 0, oppositeStartNewX, oppositeStartNewY);
                    path.circle(radius, radius, 0, 0, 0, startNewX, startNewY);
                } else {
                    path.circle(radius, radius, 0, 1, 0, startNewX, startNewY);
                }
            } else {    //小圆形
                path.circle(radius, radius, 0, 0, 0, startNewX, startNewY);
            }
            this.renderCanvas(path, sector, Colors.getUiColor(cell.color));

            //画中扇形的弧线
            smallPath.moveTo(smallStartNewX, smallStartNewY);
            smallPath.lineTo(smallStartNewX2, smallStartNewY2);
            if (cell.angle >= 180) { //大圆
                if (cell.angle == 360) {
                    smallPath.circle(smallRadius2, smallRadius2, 0, 0, 1, oppositeSmallStartNewX2, oppositeSmallStartNewY2);
                    smallPath.circle(smallRadius2, smallRadius2, 0, 0, 1, smallEndNewX2, smallEndNewY2);
                } else {
                    smallPath.circle(smallRadius2, smallRadius2, 0, 1, 1, smallEndNewX2, smallEndNewY2);
                }
            } else {    //小圆形
                smallPath.circle(smallRadius2, smallRadius2, 0, 0, 1, smallEndNewX2, smallEndNewY2);
            }
            smallPath.lineTo(smallEndNewX, smallEndNewY);
            if (cell.angle >= 180) { //大圆
                if (cell.angle == 360) {
                    smallPath.circle(smallRadius, smallRadius, 0, 0, 0, oppositeSmallStartNewX, oppositeSmallStartNewY);
                    smallPath.circle(smallRadius, smallRadius, 0, 0, 0, smallStartNewX, smallStartNewY);
                } else {
                    smallPath.circle(smallRadius, smallRadius, 0, 1, 0, smallStartNewX, smallStartNewY);
                }
            } else {    //小圆形
                smallPath.circle(smallRadius, smallRadius, 0, 0, 0, smallStartNewX, smallStartNewY);
            }
            this.renderCanvas(smallPath, sector1, Colors.getNumberToRgbDark(cell.color, 0.2));


            //画关联线还有圆形
            if (cell.explainLines.length > 1) {
                let line = document.createElementNS('http://www.w3.org/2000/svg', "path");
                let linepath = new LinePath();
                linepath.moveTo(cell.explainLines[0].x, cell.explainLines[0].y);
                for (let lineIndex = 1; lineIndex < cell.explainLines.length; lineIndex++) {
                    linepath.lineTo(cell.explainLines[lineIndex].x, cell.explainLines[lineIndex].y);
                }
                this.renderLine(linepath, line, cell.lineColor, cell.lineWidth)
                this.context.appendChild(line);
                this.removeArray.push(line)
                let lineCircularRadius = new UiUtil().dip2px(cell.lineWidth) * 2;
                let circular1 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
                let circular2 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
                this.drawCircular(cell.explainLines[0].x, cell.explainLines[0].y, lineCircularRadius, circular1, Colors.getUiColor(cell.lineColor))
                this.drawCircular(cell.explainLines[0].x, cell.explainLines[0].y, lineCircularRadius * 2, circular2, Colors.getUiColorByAlpha(cell.lineColor, 0.3))
                this.context.appendChild(circular2);
                this.context.appendChild(circular1);
                this.removeArray.push(circular2)
                this.removeArray.push(circular1)
            }

            let explainTitleContent = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            let explainContent = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            this.context.appendChild(explainTitleContent);
            this.context.appendChild(explainContent);
            this.removeArray.push(explainTitleContent)
            this.removeArray.push(explainContent)
            //写编辑还有详情
            let explainTitleSize = MindElementCalculation.caluleText(cell.explainTitle, cell.explainTitleFontSize, false);
            let explainSize = MindElementCalculation.caluleText(cell.explain, cell.explainFontSize, false);

            let explainTitleContentText = document.createElement("div")
            let explainContentText = document.createElement("div")
            explainTitleContent.appendChild(explainTitleContentText);
            explainContent.appendChild(explainContentText);

            explainTitleContent.setAttribute('x', cell.explainTitleX);
            explainTitleContent.setAttribute('y', cell.explainTitleY);
            explainTitleContent.setAttribute('width', explainTitleSize.getWidth());
            explainTitleContent.setAttribute('height', explainTitleSize.getHeight());
            this.setTextDom(explainTitleContentText, cell, (new UiUtil().dip2px(cell.explainTitleFontSize)), cell.explainTitle);

            explainContent.setAttribute('x', cell.explainX);
            explainContent.setAttribute('y', cell.explainY);
            explainContent.setAttribute('width', explainSize.getWidth());
            explainContent.setAttribute('height', explainSize.getHeight());
            this.setTextDom(explainContentText, cell, (new UiUtil().dip2px(cell.explainFontSize)), cell.explain);

            this.sectorTitleList.push(explainTitleContentText); //标题数组
            this.sectorContentList.push(explainContentText);  //内容数组
        }
        if (this.backgroundColor != null) {
            this.setAllTextColor(this.backgroundColor);
        }
    }

    drawCakeShape2() {
        this.chartG.style.width = this.mindElementData.statisticsContent.width + 'px';
        this.chartG.style.height = this.mindElementData.statisticsContent.height + 'px';
        this.chartG.style.transform = "translate(" + this.mindElementData.statisticsContent.x + 'px,' + this.mindElementData.statisticsContent.y + 'px)';
        let statisticsContent = this.mindElementData.statisticsContent;
        for (let index = 0; index < statisticsContent.cellsData.length; index++) {
            let cell = statisticsContent.cellsData[index];
            let sector = document.createElementNS('http://www.w3.org/2000/svg', "path")     //大圆形
            this.chartG.appendChild(sector);
            let sector1 = document.createElementNS('http://www.w3.org/2000/svg', "path")     //中圆形
            this.chartG.appendChild(sector1);
            let sector2 = document.createElementNS('http://www.w3.org/2000/svg', "path")     //小圆形
            this.chartG.appendChild(sector2);
            let path = new LinePath();
            let smallPath = new LinePath();
            let circlePath = new LinePath();
            let radius = statisticsContent.radius;
            let centerX = cell.x;
            let centerY = cell.y;
            let smallRadius = radius - 20;
            let smallRadius2 = radius - 20 - 4;
            let smallRadius3 = radius - 20 - 4 - 10;

            if (this.selectedIndex == index) {
                let selectedMoveX = new UiUtil().dip2px(10) * Util.cosd(cell.startAngle + cell.angle / 2);
                let selectedMoveY = new UiUtil().dip2px(10) * Util.sind(cell.startAngle + cell.angle / 2);
                centerX += selectedMoveX
                centerY += selectedMoveY
            }

            let startNewX = centerX + (Util.cosd(cell.startAngle)) * radius;      //最外层的弧线
            let startNewY = centerY + (Util.sind(cell.startAngle)) * radius;
            let oppositeStartNewX = centerX - (Util.cosd(cell.startAngle)) * radius;  //用于画完整的圆形
            let oppositeStartNewY = centerY - (Util.sind(cell.startAngle)) * radius; //用于画完整的圆形。
            let endNewX = centerX + Util.cosd(cell.startAngle + cell.angle) * radius;
            let endNewY = centerY + Util.sind(cell.startAngle + cell.angle) * radius;

            let smallStartNewX = centerX + (Util.cosd(cell.startAngle)) * smallRadius;
            let smallStartNewY = centerY + (Util.sind(cell.startAngle)) * smallRadius;
            let oppositeSmallStartNewX = centerX - (Util.cosd(cell.startAngle)) * smallRadius;  //用于画完整的圆形。
            let oppositeSmallStartNewY = centerY - (Util.sind(cell.startAngle)) * smallRadius; //用于画完整的圆形。
            let smallEndNewX = centerX + Util.cosd(cell.startAngle + cell.angle) * smallRadius;
            let smallEndNewY = centerY + Util.sind(cell.startAngle + cell.angle) * smallRadius;

            let smallStartNewX2 = centerX + (Util.cosd(cell.startAngle)) * smallRadius2;
            let smallStartNewY2 = centerY + (Util.sind(cell.startAngle)) * smallRadius2;
            let oppositeSmallStartNewX2 = centerX - (Util.cosd(cell.startAngle)) * smallRadius2;  //用于画完整的圆形
            let oppositeSmallStartNewY2 = centerY - (Util.sind(cell.startAngle)) * smallRadius2; //用于画完整的圆形。
            let smallEndNewX2 = centerX + Util.cosd(cell.startAngle + cell.angle) * smallRadius2;
            let smallEndNewY2 = centerY + Util.sind(cell.startAngle + cell.angle) * smallRadius2;

            let smallStartNewX3 = centerX + (Util.cosd(cell.startAngle)) * smallRadius3;  //最内层的弧线
            let smallStartNewY3 = centerY + (Util.sind(cell.startAngle)) * smallRadius3;
            let oppositeSmallStartNewX3 = centerX - (Util.cosd(cell.startAngle)) * smallRadius3;  //用于画完整的圆形
            let oppositeSmallStartNewY3 = centerY - (Util.sind(cell.startAngle)) * smallRadius3; //用于画完整的圆形。
            let smallEndNewX3 = centerX + Util.cosd(cell.startAngle + cell.angle) * smallRadius3;
            let smallEndNewY3 = centerY + Util.sind(cell.startAngle + cell.angle) * smallRadius3;

            //画扇形的弧线
            path.moveTo(startNewX, startNewY);
            path.lineTo(smallStartNewX, smallStartNewY);
            if (cell.angle >= 180) { //大圆
                if (cell.angle == 360) {
                    path.circle(smallRadius, smallRadius, 0, 0, 1, oppositeSmallStartNewX, oppositeSmallStartNewY);
                    path.circle(smallRadius, smallRadius, 0, 0, 1, smallEndNewX, smallEndNewY);
                } else {
                    path.circle(smallRadius, smallRadius, 0, 1, 1, smallEndNewX, smallEndNewY);
                }
            } else {    //小圆形
                path.circle(smallRadius, smallRadius, 0, 0, 1, smallEndNewX, smallEndNewY);
            }
            path.lineTo(endNewX, endNewY);
            if (cell.angle >= 180) { //大圆
                if (cell.angle == 360) {
                    path.circle(radius, radius, 0, 0, 0, oppositeStartNewX, oppositeStartNewY);
                    path.circle(radius, radius, 0, 0, 0, startNewX, startNewY);
                } else {
                    path.circle(radius, radius, 0, 1, 0, startNewX, startNewY);
                }
            } else {    //小圆形
                path.circle(radius, radius, 0, 0, 0, startNewX, startNewY);
            }
            this.renderCanvas(path, sector, Colors.getUiColor(cell.color));

            //画中扇形的弧线
            smallPath.moveTo(smallStartNewX, smallStartNewY);
            smallPath.lineTo(smallStartNewX2, smallStartNewY2);
            if (cell.angle >= 180) { //大圆
                if (cell.angle == 360) {
                    smallPath.circle(smallRadius2, smallRadius2, 0, 0, 1, oppositeSmallStartNewX2, oppositeSmallStartNewY2);
                    smallPath.circle(smallRadius2, smallRadius2, 0, 0, 1, smallEndNewX2, smallEndNewY2);
                } else {
                    smallPath.circle(smallRadius2, smallRadius2, 0, 1, 1, smallEndNewX2, smallEndNewY2);
                }
            } else {    //小圆形
                smallPath.circle(smallRadius2, smallRadius2, 0, 0, 1, smallEndNewX2, smallEndNewY2);
            }
            smallPath.lineTo(smallEndNewX, smallEndNewY);
            if (cell.angle >= 180) { //大圆
                if (cell.angle == 360) {
                    smallPath.circle(smallRadius, smallRadius, 0, 0, 0, oppositeSmallStartNewX, oppositeSmallStartNewY);
                    smallPath.circle(smallRadius, smallRadius, 0, 0, 0, smallStartNewX, smallStartNewY);
                } else {
                    smallPath.circle(smallRadius, smallRadius, 0, 1, 0, smallStartNewX, smallStartNewY);
                }
            } else {    //小圆形
                smallPath.circle(smallRadius, smallRadius, 0, 0, 0, smallStartNewX, smallStartNewY);
            }
            this.renderCanvas(smallPath, sector1, Colors.getNumberToRgbDark(cell.color, 0.2));

            //画小扇形的弧线
            circlePath.moveTo(centerX, centerY);
            circlePath.lineTo(smallStartNewX3, smallStartNewY3);
            if (cell.angle >= 180) { //大圆
                if (cell.angle == 360) {
                    circlePath.circle(smallRadius3, smallRadius3, 0, 0, 1, oppositeSmallStartNewX3, oppositeSmallStartNewY3);
                    circlePath.circle(smallRadius3, smallRadius3, 0, 0, 1, smallEndNewX3, smallEndNewY3);
                } else {
                    circlePath.circle(smallRadius3, smallRadius3, 0, 1, 1, smallEndNewX3, smallEndNewY3);
                }
            } else {    //小圆形
                circlePath.circle(smallRadius3, smallRadius3, 0, 0, 1, smallEndNewX3, smallEndNewY3);
            }
            circlePath.lineTo(centerX, centerY);
            this.renderCanvas(circlePath, sector2, Colors.getUiColor(cell.color));

            //画关联线还有圆形
            if (cell.explainLines.length > 1) {
                let line = document.createElementNS('http://www.w3.org/2000/svg', "path");
                let linepath = new LinePath();
                linepath.moveTo(cell.explainLines[0].x, cell.explainLines[0].y);
                for (let lineIndex = 1; lineIndex < cell.explainLines.length; lineIndex++) {
                    linepath.lineTo(cell.explainLines[lineIndex].x, cell.explainLines[lineIndex].y);
                }
                this.renderLine(linepath, line, cell.lineColor, cell.lineWidth)
                this.context.appendChild(line);
                this.removeArray.push(line)
                let lineCircularRadius = new UiUtil().dip2px(cell.lineWidth) * 2;
                let circular1 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
                let circular2 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
                this.drawCircular(cell.explainLines[0].x, cell.explainLines[0].y, lineCircularRadius, circular1, Colors.getUiColor(cell.lineColor))
                this.drawCircular(cell.explainLines[0].x, cell.explainLines[0].y, lineCircularRadius * 2, circular2, Colors.getUiColorByAlpha(cell.lineColor, 0.3))
                this.context.appendChild(circular2);
                this.context.appendChild(circular1);
                this.removeArray.push(circular2)
                this.removeArray.push(circular1)
            }

            let explainTitleContent = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            let explainContent = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            this.context.appendChild(explainTitleContent);
            this.context.appendChild(explainContent);
            this.removeArray.push(explainTitleContent)
            this.removeArray.push(explainContent)
            //写编辑还有详情
            let explainTitleSize = MindElementCalculation.caluleText(cell.explainTitle, cell.explainTitleFontSize, false);
            let explainSize = MindElementCalculation.caluleText(cell.explain, cell.explainFontSize, false);

            let explainTitleContentText = document.createElement("div")
            let explainContentText = document.createElement("div")
            explainTitleContent.appendChild(explainTitleContentText);
            explainContent.appendChild(explainContentText);

            explainTitleContent.setAttribute('x', cell.explainTitleX);
            explainTitleContent.setAttribute('y', cell.explainTitleY);
            explainTitleContent.setAttribute('width', explainTitleSize.getWidth());
            explainTitleContent.setAttribute('height', explainTitleSize.getHeight());
            this.setTextDom(explainTitleContentText, cell, (new UiUtil().dip2px(cell.explainTitleFontSize)), cell.explainTitle);

            explainContent.setAttribute('x', cell.explainX);
            explainContent.setAttribute('y', cell.explainY);
            explainContent.setAttribute('width', explainSize.getWidth());
            explainContent.setAttribute('height', explainSize.getHeight());
            this.setTextDom(explainContentText, cell, (new UiUtil().dip2px(cell.explainFontSize)), cell.explain);

            this.sectorTitleList.push(explainTitleContentText); //标题数组
            this.sectorContentList.push(explainContentText);  //内容数组
        }
        if (this.backgroundColor != null) {
            this.setAllTextColor(this.backgroundColor);
        }
    }


    renderObj(obj = { dom, d, stroke, strokeWidth }) {  //设置path
        if (obj.d != undefined) {
            obj.dom.setAttribute("d", obj.d.getLine())
        }
        if (obj.stroke != undefined) {
            obj.dom.setAttribute("stroke", obj.stroke)
        }
        if (obj.strokeWidth != undefined) {
            obj.dom.setAttribute("stroke-width", obj.strokeWidth)
        }
        if (obj.fill != undefined) {
            obj.dom.setAttribute("fill", obj.fill)
        }
    }

    setTextDiv(obj = {}) {  //设置文字dom
        obj.dom.style.userSelect = 'none'
        if (obj.innerHTML != undefined) {
            obj.dom.innerHTML = obj.innerHTML
        }

        if (obj.fontSize != undefined) {
            obj.dom.style.fontSize = obj.fontSize + 'px'
        }

        if (obj.color != undefined) {
            obj.dom.style.color = obj.color
        }

        if (obj.fontFamily == undefined) {
            obj.dom.style.fontFamily = Config.textFontFamily // "SimSun"
        } else {
            obj.dom.style.fontFamily = obj.fontFamily
        }
    }

    //自己添加的一个函数
    renderCanvas(path, dom, color) {      //渲染扇形
        dom.setAttribute("d", path.getLine())
        dom.setAttribute("fill", color)
    }

    //自己添加的一个函数
    renderLine(path, dom, color, width) {      //渲染线条
        dom.setAttribute("d", path.getLine())
        dom.setAttribute("stroke", Colors.getUiColor(color))
        dom.setAttribute("stroke-width", width)
        dom.setAttribute("fill", 'none')
    }

    drawCircular(x, y, radius, dom, fillColor) { //渲染圆形
        dom.setAttribute("cx", x)
        dom.setAttribute("cy", y)
        dom.setAttribute("r", radius)
        dom.setAttribute("fill", fillColor)
    }

    setTextDom(dom, cell, fontsize, text) {  //设置文字dom
        dom.innerHTML = text
        dom.style.fontSize = fontsize + 'px'
        dom.style.color = Colors.getUiColor(cell.explainColor)
        dom.style.userSelect = 'none'
        dom.style.fontFamily = Config.textFontFamily //"SimSun"
    }

    drawCakeShape1() { //画扇形
        this.chartG.style.width = this.mindElementData.statisticsContent.width + 'px';
        this.chartG.style.height = this.mindElementData.statisticsContent.height + 'px';
        this.chartG.style.transform = "translate(" + this.mindElementData.statisticsContent.x + 'px,' + this.mindElementData.statisticsContent.y + 'px)'

        let statisticsContent = this.mindElementData.statisticsContent;

        for (let index = 0; index < statisticsContent.cellsData.length; index++) {
            let cell = statisticsContent.cellsData[index];
            let sector = document.createElementNS('http://www.w3.org/2000/svg', "path")
            this.chartG.appendChild(sector);
            let path = new LinePath();
            let radius = statisticsContent.radius;
            let centerX = cell.x;
            let centerY = cell.y;
            let startNewX = centerX + (Util.cosd(cell.startAngle)) * radius;
            let startNewY = centerY + (Util.sind(cell.startAngle)) * radius;
            let endNewX = centerX + Util.cosd(cell.startAngle + cell.angle) * radius;
            let endNewY = centerY + Util.sind(cell.startAngle + cell.angle) * radius;
            let oppositeStartNewX = centerX - (Util.cosd(cell.startAngle)) * radius;  //用于画完整的圆形
            let oppositeStartNewY = centerY - (Util.sind(cell.startAngle)) * radius; //用于画完整的圆形。
            if (this.selectedIndex == index) {
                let selectedMoveX = new UiUtil().dip2px(10) * Util.cosd(cell.startAngle + cell.angle / 2);
                let selectedMoveY = new UiUtil().dip2px(10) * Util.sind(cell.startAngle + cell.angle / 2);
                centerX += selectedMoveX
                centerY += selectedMoveY
                startNewX += selectedMoveX
                startNewY += selectedMoveY
                endNewX += selectedMoveX
                endNewY += selectedMoveY
                oppositeStartNewX += selectedMoveX
                oppositeStartNewY += selectedMoveY
            }
            //画扇形的弧线
            path.moveTo(centerX, centerY);
            path.lineTo(startNewX, startNewY);


            // console.log('centerX',centerX);
            // console.log('centerY',centerY);
            // console.log('startNewX',startNewX);
            // console.log('startNewY',startNewY);
            // console.log('endNewX',endNewX);
            // console.log('endNewY',endNewY);

            if (cell.angle >= 180) { //大圆
                if (cell.angle == 360) {
                    path.circle(radius, radius, 0, 0, 1, oppositeStartNewX, oppositeStartNewY);
                    path.circle(radius, radius, 0, 0, 1, endNewX, endNewY);
                } else {
                    path.circle(radius, radius, 0, 1, 1, endNewX, endNewY);
                }
            } else {    //小圆形
                path.circle(radius, radius, 0, 0, 1, endNewX, endNewY);
            }
            path.lineTo(centerX, centerY);
            this.renderCanvas(path, sector, Colors.getUiColor(cell.color));


            //画关联线还有圆形
            if (cell.explainLines.length > 1) {

                let line = document.createElementNS('http://www.w3.org/2000/svg', "path");
                let linepath = new LinePath();
                linepath.moveTo(cell.explainLines[0].x, cell.explainLines[0].y);
                for (let lineIndex = 1; lineIndex < cell.explainLines.length; lineIndex++) {
                    linepath.lineTo(cell.explainLines[lineIndex].x, cell.explainLines[lineIndex].y);
                }
                this.renderLine(linepath, line, cell.lineColor, cell.lineWidth)
                this.context.appendChild(line);
                this.removeArray.push(line)
                let lineCircularRadius = new UiUtil().dip2px(cell.lineWidth) * 2;
                let circular1 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
                let circular2 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
                this.drawCircular(cell.explainLines[0].x, cell.explainLines[0].y, lineCircularRadius, circular1, Colors.getUiColor(cell.lineColor))
                this.drawCircular(cell.explainLines[0].x, cell.explainLines[0].y, lineCircularRadius * 2, circular2, Colors.getUiColorByAlpha(cell.lineColor, 0.3))
                this.context.appendChild(circular2);
                this.context.appendChild(circular1);
                this.removeArray.push(circular2)
                this.removeArray.push(circular1)

            }

            let explainTitleContent = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            let explainContent = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
            this.context.appendChild(explainTitleContent);
            this.context.appendChild(explainContent);
            this.removeArray.push(explainTitleContent)
            this.removeArray.push(explainContent)
            //写编辑还有详情
            let explainTitleSize = MindElementCalculation.caluleText(cell.explainTitle, cell.explainTitleFontSize, false);
            let explainSize = MindElementCalculation.caluleText(cell.explain, cell.explainFontSize, false);
            let explainTitleContentText = document.createElement("div")
            let explainContentText = document.createElement("div")
            explainTitleContent.appendChild(explainTitleContentText);
            explainContent.appendChild(explainContentText);

            explainTitleContent.setAttribute('x', cell.explainTitleX);
            explainTitleContent.setAttribute('y', cell.explainTitleY);
            explainTitleContent.setAttribute('width', explainTitleSize.getWidth());
            explainTitleContent.setAttribute('height', explainTitleSize.getHeight());
            this.setTextDom(explainTitleContentText, cell, (new UiUtil().dip2px(cell.explainTitleFontSize)), cell.explainTitle);

            explainContent.setAttribute('x', cell.explainX);
            explainContent.setAttribute('y', cell.explainY);
            explainContent.setAttribute('width', explainSize.getWidth());
            explainContent.setAttribute('height', explainSize.getHeight());
            this.setTextDom(explainContentText, cell, (new UiUtil().dip2px(cell.explainFontSize)), cell.explain);

            this.sectorTitleList.push(explainTitleContentText); //标题数组
            this.sectorContentList.push(explainContentText);  //内容数组

        }
        if (this.backgroundColor != null) {
            this.setAllTextColor(this.backgroundColor);
        }
    }

    setAllTextColor(bgcolor) {
        let isDarkColor = Colors.isLightColor(bgcolor);
        let newcolor = "";
        if (isDarkColor) {
            newcolor = Colors.black50;
        } else {
            newcolor = Colors.white15;
        }

        // console.log('设置文字颜色',color);
        let titleLen = this.sectorTitleList.length;
        let contentLen = this.sectorContentList.length;
        let valueLen = this.sectorValueList.length;
        //保存当前颜色数据
        for (let index = 0; index < this.mindElementData.statisticsContent.cellsData.length; index++) {
            this.mindElementData.statisticsContent.cellsData[index].explainColor = newcolor;
            this.mindElementData.statisticsContent.cellsData[index].explainTitleColor = newcolor;
            // explainTitleColor
        }
        let color = Colors.getUiColor(newcolor);

        for (let index = 0; index < titleLen; index++) {
            let cell = this.sectorTitleList[index];
            cell.style.color = color;
            cell.style.userSelect = 'none'
        }

        for (let index = 0; index < contentLen; index++) {
            let cell = this.sectorContentList[index];
            cell.style.color = color;
            cell.style.userSelect = 'none'
        }

        for (let index = 0; index < valueLen; index++) {
            let cell = this.sectorValueList[index];
            cell.style.color = color;
            cell.style.userSelect = 'none'
        }

        if (this.sectorArrow != null) {
            this.sectorArrow.setAttribute('fill', color)
        }

        if (this.sectorArrowLine != null) {
            this.sectorArrowLine.setAttribute('stroke', color)
        }
        if (this.GraphLine != null) {
            this.GraphLine.setAttribute('stroke', color)
        }
        // this.sectorArrow = null; //     箭头
        // this.sectorArrowLine = null; // 箭头线
    }
    //     private void drawCircular(Canvas canvas, Point point, int radius, int fillColor) {
    //         Paint circlePaint = new Paint();
    //         circlePaint.setStyle(Paint.Style.FILL);
    //         circlePaint.setStrokeCap(Paint.Cap.ROUND);
    //         circlePaint.setStrokeWidth(0.1f);
    //         circlePaint.setAntiAlias(true);
    //         circlePaint.setColor(Colors.getUiColor(fillColor));

    //         canvas.drawCircle(point.x, point.y, radius, circlePaint);
    //     }

    //     @Override
    setSelected(value) {
        if (this.isSelected == value) {
            return;
        }
        this.isRefresh = true;
        this.isSelected = value;
        // invalidate();
        this.setSelectedLayer()
    }


    setSelectedLayer() {    //用来画选中的元素的带圆角的矩形边框
        // if (this.selectedView != null && this.selectedView.parentNode != null) {
        //     this.removeClickListener()
        // }
        if (this.mindElementData == null) {
            return;
        }
        if (this.isSelected) {
            this.addSelectedView()
            let space = this.getSelectedSpace();
            let board = this.computedBoardSize()
            let width = board.width;
            let height = board.height;

            let linePath = new LinePath();
            let r = this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM || this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL ? 0 : 8;
            linePath.moveTo(- space + r, - space);
            linePath.lineTo(width - space - r, - space);
            linePath.circle(r, r, 0, 0, 1, width - space, - space + r)    //这里都是小圆
            linePath.lineTo(width - space, height - space - r);
            linePath.circle(r, r, 0, 0, 1, width - space - r, height - space)    //这里都是小圆
            linePath.lineTo(- space + r, height - space);
            linePath.circle(r, r, 0, 0, 1, - space, height - space - r)    //这里都是小圆
            linePath.lineTo(- space, - space + r);
            linePath.circle(r, r, 0, 0, 1, - space + r, - space)    //这里都是小圆
            this.renderCanvasframe(this.setRect, linePath)
        } else {
            this.renderCanvasframe(this.setRect, "")
            this.removeSelectedView()
        }
    }
    //自己添加的一个函数
    renderCanvasframe(ele, path) {
        if (path == "") {
            ele.setAttribute("d", "")
        } else {
            ele.setAttribute("d", path.getLine())
        }
        ele.setAttribute("stroke", 'rgb(253, 148, 130)')
        ele.setAttribute("stroke-width", 2)
        ele.setAttribute("fill", "none")
    }

    getSelectedSpace() {
        if (this.mindElementData == null) {
            return 6;
        }
        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM || this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            return 1;
        }
        if (this.mindElementData.backgroundColor == Colors.clear &&
            (this.mindElementData.borderWidth <= 0 || this.mindElementData.borderColor == Colors.clear)) {
            return 0;
        }
        return 6;
    }

    computedBoardSize() {   //选中边框的大小,自己拆出来的
        let space = this.getSelectedSpace();
        let width;
        let height;
        let x = -space;
        let y = -space;
        width = this.mindElementData.width + space * 2;
        height = this.mindElementData.height + space * 2;

        if (this.mindElementData.hiddenNumber > 0) {
            if (this.mindElementData.layout == NodeLayoutType.LAYOUT_LEFT) {
                x = -space - this.mindNodeUpNumberViewWidth;
                y = -space;
                width = this.mindElementData.width + space * 2 + this.mindNodeUpNumberViewWidth;
                height = this.mindElementData.height + space * 2;
            } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_RIGHT || this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM || this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                x = -space;
                y = -space;
                width = this.mindElementData.width + space * 2 + this.mindNodeUpNumberViewWidth;
                height = this.mindElementData.height + space * 2;
            } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BOTTOM) {
                x = -space;
                y = -space;
                width = this.mindElementData.width + space * 2;
                height = this.mindElementData.height + space * 2 + this.mindNodeUpNumberViewWidth;
            }
        } else {
            x = -space;
            y = -space;
            width = this.mindElementData.width + space * 2;
            height = this.mindElementData.height + space * 2;
        }
        return {
            width: width,
            height: height
        }
    }

    //     public void setOnLongClick(View.OnLongClickListener listener) {
    //         this.onLongClickListener = listener;
    //         if (listener == null) {
    //             this.setOnLongClickListener(null);
    //         } else {
    //             this.setOnLongClickListener(this);
    //         }
    //     }

    //     private Runnable onClickHandler = new Runnable() {
    //         @Override
    //         public void run() {
    //             mindTextSizeChangedDelegate.onSelected(mindElementData, new Point(-1, -1));
    //         }
    //     };

    //     @SuppressLint("ClickableViewAccessibility")console
    setClickListener() {

        if (this.rightClick == null) {
            //右击事件
            this.rightClick = (ev) => {
                if (ev.button == 2 || (ev.button == 0 && ev.ctrlKey == true)) {
                    this.mindTextSizeChangedDelegate.onSelected(this.mindElementData, new Point(-1, -1), { type: "rightClick" }, ev);
                    this.setSelected(true)
                }

            }
            this.context.addEventListener('mouseup', this.rightClick);

            this.onClickListener = (ev) => {    //单击事件
                this.mindTextSizeChangedDelegate.onSelected(this.mindElementData, new Point(-1, -1));
                this.setSelected(true)
            };
            this.context.addEventListener('mousedown', this.onClickListener);
        }


        //         if (userGesture != null) {
        //             this.setOnClickListener(null);
        //             this.setOnTouchListener(null);
        //         }
        //         if (userGesture == null) {
        //             userGesture = new OnClickListener() {
        //                 @Override
        //                 public void onClick(View v) {
        //                     UiHandlers.removeCallback(onClickHandler);
        //                     long currentTimeMillis = SystemClock.uptimeMillis();
        //                     //两次点击间隔时间小于300ms代表双击
        //                     if (currentTimeMillis - lastClickTime < CLICK_INTERVAL_TIME) {
        //                         mindTextSizeChangedDelegate.onDoubleClickSelected(mindElementData, new Point(-1, -1));
        //                         lastClickTime = currentTimeMillis;
        //                     } else {
        //                         lastClickTime = currentTimeMillis;
        //                         UiHandlers.postDelayed(onClickHandler, CLICK_INTERVAL_TIME + 10);

        //                     }
        //                 }
        //             };
        //         }
        //         if (onTouchListener == null) {
        //             onTouchListener = new OnTouchListener() {
        //                 @Override
        //                 public boolean onTouch(View arg0, MotionEvent event) {
        //                     switch (event.getAction() & MotionEvent.ACTION_MASK) {
        //                         case MotionEvent.ACTION_DOWN:
        //                             break;
        //                         case MotionEvent.ACTION_POINTER_DOWN:
        //                             break;
        //                         case MotionEvent.ACTION_MOVE:
        //                             break;
        //                         case MotionEvent.ACTION_UP:
        //                             setCellSelectedIndex(new CGPoint(event.getX(), event.getY()));
        //                             break;
        //                         case MotionEvent.ACTION_CANCEL:
        //                             break;
        //                     }
        //                     return false;
        //                 }
        //             };
        //         }
        //         this.setOnClickListener(userGesture);
        //         this.setOnTouchListener(onTouchListener);
    }

    //     @Override
    //     public boolean onLongClick(View view) {
    //         if (this.onLongClickListener != null) {
    //             this.onLongClickListener.onLongClick(view);
    //             return true;
    //         }
    //         return false;
    //     }

    //     @Override
    //     protected void createBackgroundView() {
    //     }

    drawArrow(dom, start, end, color) {
        let height = new UiUtil().dip2px(4);
        let newEnd = new CGPoint(end.x, end.y);
        let linePath = new LinePath();
        if (start.x == end.x) {
            if (end.y > start.y) {
                newEnd = new CGPoint(end.x, end.y - height);
            } else {
                newEnd = new CGPoint(end.x, end.y + height);
            }
        } else {
            if (end.x > start.x) {
                newEnd = new CGPoint(end.x - height, end.y);
            } else {
                newEnd = new CGPoint(end.x + height, end.y);
            }
        }

        let p = new UiUtil().getArrowPoint(start, newEnd, height);
        if (p.length != 3) {
            return;
        }
        linePath.moveTo(p[0].x, p[0].y);
        linePath.lineTo(p[1].x, p[1].y);
        linePath.lineTo(p[2].x, p[2].y);
        linePath.lineTo(p[0].x, p[0].y);

        dom.setAttribute('d', linePath.getLine());
        dom.setAttribute('fill', color);

        // Paint arrowPaint = new Paint();
        // arrowPaint.setStyle(Paint.Style.STROKE);
        // arrowPaint.setStrokeCap(Paint.Cap.SQUARE);
        // arrowPaint.setStrokeWidth(0);
        // arrowPaint.setAntiAlias(true);
        // arrowPaint.setColor(Colors.getUiColor(color));

        // Paint arrowFullPaint = new Paint();
        // arrowFullPaint.setStyle(Paint.Style.FILL);
        // arrowFullPaint.setFlags(Paint.ANTI_ALIAS_FLAG);
        // arrowFullPaint.setStrokeCap(Paint.Cap.ROUND);
        // arrowFullPaint.setStrokeWidth(1);
        // arrowFullPaint.setAntiAlias(true);
        // arrowFullPaint.setColor(Colors.getUiColor(color));

        // canvas.drawPath(linePath, arrowFullPaint);
        // canvas.drawPath(linePath, arrowPaint);
    }

    //     private void setCellSelectedIndex(CGPoint point) {
    //         StatisticsContent statisticsContent = mindElementData.statisticsContent;
    //         selectedDownPoint = point;
    //         switch (statisticsContent.type) {
    //             case CakeShape_1:
    //             case CakeShape_2:
    //             case CakeShape_3:
    //             case CakeShape_4:
    //             case CakeShape_5:
    //                 drawCakeShapeHit(point);
    //                 break;
    //             case Histogram_1:
    //             case Histogram_2:
    //             case Histogram_3:
    //                 drawHistogramHit(point);
    //                 break;
    //             default:
    //         }
    //     }

    //     private void drawCakeShapeHit(CGPoint point) {
    //         StatisticsContent statisticsContent = mindElementData.statisticsContent;
    //         CGPoint centerPoint = new CGPoint(statisticsContent.cellsData.get(0).x, statisticsContent.cellsData.get(0).y);
    //         float centerDistance = Util.getPointSpacing(new CGPoint(centerPoint.x, centerPoint.y), point);
    //         if (centerDistance > statisticsContent.radius) {
    //             if (selectedIndex > -1) {
    //                 selectedIndex = -1;
    //                 invalidate();
    //             }
    //             return;
    //         }
    //         double angleValue = ((double)point.x - (double)centerPoint.x) / (double)centerDistance;
    //         double angle = Math.acos(angleValue) * 180 / Math.PI;
    //         int pointY = (int)centerPoint.y + (int)(((double)centerDistance) *  Util.sind(angle));
    //         if (Math.abs(pointY - point.y) > UiUtil.dip2px(2)) {
    //             angle = 360 - angle;
    //         }
    //         int newSelectedIndex = -1;
    //         for (int index = 0; index < statisticsContent.cellsData.size(); index++) {
    //             StatisticsCellData cell = statisticsContent.cellsData.get(index);
    //             float startAngle = cell.startAngle%360;
    //             float endAngle = (cell.startAngle + cell.angle)%360;
    //             if ((startAngle > endAngle  && !(angle < startAngle && angle > endAngle)) ||
    //                 (angle > cell.startAngle%360 && angle < (cell.startAngle + cell.angle)%360)) {
    //                 if (selectedIndex == index) {
    //                     newSelectedIndex = -1;
    //                 } else {
    //                     newSelectedIndex = index;
    //                 }
    //                 break;
    //             }
    //         }
    //         selectedIndex = newSelectedIndex;
    //         invalidate();
    //     }

    //     private void drawHistogramHit(CGPoint point) {
    //         StatisticsContent statisticsContent = mindElementData.statisticsContent;
    //         int newSelectedIndex = -1;
    //         for (int index = 0; index < statisticsContent.cellsData.size(); index++) {
    //             StatisticsCellData cell = statisticsContent.cellsData.get(index);

    //             if (point.x > cell.x && point.x < cell.x + cell.width &&
    //                 point.y > cell.y && point.y < cell.y + cell.height) {
    //                 if (selectedIndex == index) {
    //                     newSelectedIndex = -1;
    //                 } else {
    //                     newSelectedIndex = index;
    //                 }
    //                 break;
    //             }
    //         }
    //         selectedIndex = newSelectedIndex;
    //         invalidate();
    //     }

    getTextColor(color) {
        // console.log(this.mindTextSizeChangedDelegate.initMindData);

        return color

        // if (Colors.isDarkColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor)) {
        //     return Colors.white;
        // } else {
        //     return Colors.black50;
        // }
    }
}

export default outlineStatisticsElementView
