class MindOriginDataRelativeRootInfo {

    constructor(data, referenceData, rootOriginRect, left, top, right, bottom, centreX, centreY, scaleX, scaleY) {
        this.data = data;
        this.referenceData = referenceData;
        this.rootOriginRect = rootOriginRect;
        this.left = left;
        this.top = top;
        this.right = right;
        this.bottom = bottom;
        this.centreX = centreX;
        this.centreY = centreY;
        this.scaleX = scaleX;
        this.scaleY = scaleY;
    }

    getMinX() {
        let arr = [Math.abs(this.left), Math.abs(this.right), Math.abs(this.centreX)];
        let min = Math.min.apply(null, arr);
        return min;
    }

    getMinY() {
        let arr = [Math.abs(this.top), Math.abs(this.bottom), Math.abs(this.centreY)];
        let min = Math.min.apply(null, arr);;
        
        return min;
    }
}
export default MindOriginDataRelativeRootInfo