import Util from "../../../utils/Util";
import HashMap from "../../../viewmodel/core/base/HashMap";
import LineMindTypeNode from "../../../viewmodel/mindelementdata/LineMindTypeNode";
import MindElementData from "../../../viewmodel/mindelementdata/MindElementData";
import MindCopyNodeData from "./MindCopyNodeData";
import MindCopyStyleData from "./MindCopyStyleData";

class MindCopyManager {
    constructor() {
        this.CopyStyleDataKey = "mind_copy_style_data_key";
        this.CopyNodeDataKey = "mind_copy_node_data_key";
        this.TimeDifference = 30000;//Copy有效时长
        this.styleData = new MindCopyStyleData()
        this.copyNode = new MindCopyNodeData()

        this.setCopyStyleDataTime = -1;
        this.setCopyNodeTime = -1;

        this.generalizationLines = new Array(); //概要线
        this.generalizationNodes = new Array(); //概要节点
        this.encircles = new Array(); //包裹节点
        this.lineMindElementDataDict = new HashMap(); //主体线字典， Int为线ID data为line
        this.textElementLineMindElementDataDict = new HashMap(); //主体节点对应的线，Int为节点ID，data为line
        this.explainMindElementDataDict = new HashMap(); //解释节点字典， Int为线ID
        this.pasteboardString = "";
        this.systemClipText = "";
    }

    setCopyStyleData(data) {
        this.styleData.setData(data);
    }

    setCopyNode(copyNode,
                encircles,
                generalizationNodes,
                generalizationLines,
                lineMindElementDataDict,
                textElementLineMindElementDataDict,
                explainMindElementDataDict) {
        this.encircles = new Array();
        this.generalizationLines = new Array();
        this.generalizationNodes = new Array();
        this.lineMindElementDataDict.clear();
        this.textElementLineMindElementDataDict.clear();
        this.explainMindElementDataDict.clear();

        let generalizationNodesContent = generalizationNodes.length;
        for(var index = 0;index<generalizationNodesContent;index++) {
            let cell = generalizationNodes[index];
            this.generalizationNodes.push(cell.copy());
        }

        let generalizationLinesContent = generalizationLines.length;
        for(var index = 0;index<generalizationLinesContent;index++){
            let cell = generalizationNodes[index];
            this.generalizationLines.push(cell.copy());
        }

        let encirclesContent = encircles.length;
        for(var index = 0;index<encirclesContent;index++) {
            let cell = encircles[index];
            this.encircles.push(cell.copy());
        }

        let lineMindElementDataDicts = lineMindElementDataDict.keys()
        let lineMindElementDataDictsContent = lineMindElementDataDicts.length;
        for(var index = 0;index<lineMindElementDataDictsContent;index++) {
            let id = lineMindElementDataDicts[index]
            this.lineMindElementDataDict.put(id, lineMindElementDataDict.get(id).copy());
        }

        let textElementLineMindElementDataDicts = textElementLineMindElementDataDict.keys()
        let textElementLineMindElementDataDictsContent = textElementLineMindElementDataDicts.length;
        for(var index = 0;index<textElementLineMindElementDataDictsContent;index++) {
            let id = textElementLineMindElementDataDicts[index]
            this.textElementLineMindElementDataDict.put(id, textElementLineMindElementDataDict.get(id).copy());
        }

        let explainMindElementDataDicts = explainMindElementDataDict.values()
        let explainMindElementDataDictsContent = explainMindElementDataDicts.length;
        for(var index = 0;index<explainMindElementDataDictsContent;index++){
            let cell = explainMindElementDataDicts[index]
            this.explainMindElementDataDict.put(cell.id, cell);
        }

        this.copyNode.setData(copyNode,
                            this.encircles,
                            this.generalizationNodes,
                            this.generalizationLines,
                            this.lineMindElementDataDict,
                            this.textElementLineMindElementDataDict,
                            this.explainMindElementDataDict);

        let nodeString = copyNode.getString();
        if (nodeString != null && nodeString.length > 0) {
            Util.setPasteboard(nodeString);
        }
        this.setCopyNodeTime = Util.getCurrentTime();
    }

    isHasCopyStyleData() {
        if (this.styleData == null) {
            return false;
        }
        return this.styleData.isHasCopyStyleData();
    }

    isHasCopyNode() {
        if (this.copyNode == null) {
            return false;
        }
        return this.copyNode.isHasCopyStyleData();
    }

    getStyleData() {
        return this.styleData.getData();
    }

    getCopyNode() {
        let node = this.copyNode.getData();
        this.encircles = this.copyNode.encircles;
        this.generalizationLines = this.copyNode.generalizationLines;
        this.generalizationNodes = this.copyNode.generalizationNodes
        this.lineMindElementDataDict = this.copyNode.lineMindElementDataDict
        this.textElementLineMindElementDataDict = this.copyNode.textElementLineMindElementDataDict

        return node;
    }

    getEncircleData( data) {
        let encirclesContent = this.encircles.length;
        for(var index = 0; index<encirclesContent ; index++){
            let mind = this.encircles[index];
            if (mind.parentNodeId == data.id) {
                return mind;
            }
            for(var tarIndex = 0; tarIndex<mind.lineContent.targetIds.length ; tarIndex++) {
                let id = mind.lineContent.targetIds[tarIndex];
                if (id == data.id) {
                    return mind;
                }
            }
        }
        return new MindElementData().emptyMindNode();
    }

    getExplainData( data) {
        let explainMindElementDataDictValues = this.explainMindElementDataDict.values();
        let explainMindElementDataDictValuesCount = explainMindElementDataDictValues.length;
        for (var index = 0; index < explainMindElementDataDictValuesCount; index++){
            let mind = explainMindElementDataDictValues[index];
            if (mind.parentNodeId == data.id) {
                return mind;
            }
        }
        return new MindElementData().emptyMindNode();
    }

    getNodeGeneralization( data) {
        if (data == null || data.isEmpty()) {
            return new LineMindTypeNode();
        }

        let generalizationNodesContent = this.generalizationNodes.length;
        for(var index = 0; index<generalizationNodesContent ; index++) { 
            let item = this.generalizationNodes[index]
            if (item.value.generalizationContent != null &&
                    item.value.generalizationContent.targetIds != null &&
                    item.value.generalizationContent.targetIds.indexOf(data.id)>-1) {
                return item;
            }
        }
        return new LineMindTypeNode();
    }

    getGeneralizationNodeLine( generalizationNodeId) {
        let generalizationLinesContent = this.generalizationLines.length;
        for(var index = 0; index<generalizationLinesContent ; index++) { 
            let line = this.generalizationLines[index]
            if (line.parentNodeId == generalizationNodeId) {
                return line;
            }
        }
        return new MindElementData().emptyMindNode();
    }

    getNodeLine(nodeId) {
        let lineMindElementDataDictKeys = this.lineMindElementDataDict.keys();
        let lineMindElementDataDictKeysContent = lineMindElementDataDictKeys.length;
        for(var index = 0;index<lineMindElementDataDictKeysContent;index++) {
            let key = lineMindElementDataDictKeys[index];
            let line = this.lineMindElementDataDict.get(key);
            if (line.lineContent != null && line.lineContent.targetId == nodeId) {
                return line;
            }
        }
        return new MindElementData().emptyMindNode();
    }

    setPasteboardString(value) {
        this.pasteboardString = value;
    }

    clearPasteboard() {

    }

    setSystemClipText(value) {
        this.systemClipText = value;
    }

    isSystemClipText() {
        return this.systemClipText != null && this.systemClipText.length > 0;
    }
}

export default new MindCopyManager()