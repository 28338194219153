// package mind.yushu.com.mindmap.viewmodel.datatype;

// import com.google.gson.annotations.SerializedName;

/**
 * ProjectName: MindMap
 * Created by tony on 2020/6/10
 * Copyright(c) 2020 mindyushu.com
 */

const MindBGStripesType = {
    NonExistent: -1,    //未定义
    Vertical: 0,        //竖
    Horizontal: 1,      //横
    Square: 2,          //方格
    SquareGreen: 3,     //墨绿d方格
    WavyLine: 4,        //曲线
    DashLine: 5,        //竖
    HollowCircular: 6,  //空心圆
    FaceWavyLine: 7    //对曲线


    // private final int value;

    // MindBGStripesType(final int value) {
    //     this.value = value;
    // }

    // public static MindBGStripesType from(final int value) {
    //     MindBGStripesType result = NonExistent;
    //     for (final MindBGStripesType type : values()) {
    //         if (type.getValue() == value) {
    //             result = type;
    //         }
    //     }
    //     return result;
    // }

    // public int getValue() {
    //     return value;
    // }
}

export default MindBGStripesType
