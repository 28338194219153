
import HttpServerConfig from "../../../common/HttpServerConfig";
import { postMindmapUploadImage } from "../../../common/netWork/mind_map_api";
import imageToStyle from "../../../utils/ImageToolkit";

class ImageFileUpload {
    constructor (delegate, point) {
        this.delegate = delegate;
        this.point = point;
    }

    upload(file) {
      if (file == null) {
        return
      }
      
      var meMessage = JSON.parse(localStorage.getItem("meMessage"));
      if (!meMessage.isMember) {
        let that = this;
        if(this.delegate.generalConfigurationParameters != null && 
          this.delegate.generalConfigurationParameters != false && 
        !this.delegate.generalConfigurationParameters.uploadImageFree) {
          this.delegate.$confirm({
            title: this.delegate.getString(this.delegate.strings.Mind_Tips),
            content: this.delegate.getString(this.delegate.strings.Member_Exclusive),
            centered: true,
            okText: this.delegate.getString(this.delegate.strings.Mind_Edit_Confirm),
            cancelText: this.delegate.getString(this.delegate.strings.Global_Cancel),
            onOk() {
              that.delegate.isShowShoppingModel(true);
            },
            onCancel() {

            },  
          });
          return true;
        } 
      }
      let that = this;
      let read = new FileReader();
      read.readAsDataURL(file);
      read.onload = function () {
        that.setUploadImg(read);
      };
    }


    setUploadImg(read) {   //本地图片上传。
      // console.log('read',read);
      let userImage = read.result;
        //转Blob
        function blobData() {
          var arr = userImage.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          return new Blob([u8arr], { type: mime });
        }
        var blob = blobData();
        this.delegate.spinning = true;
        if (blob.type.indexOf("image/") > -1) {
          let handler;
          //判断是否处在群组编辑页面。
          if(this.delegate.$router.history.current.name == 'gmap'  ) {
            handler = {
              "X-Type": "gmap-inner-image",
              "X-ID": this.delegate.$route.query.id
            }
          } else {
            handler = {
              "X-Type": "mindmap-inner-image",
              "X-ID": this.delegate.$route.query.id,
            }
          }
          postMindmapUploadImage(
            blob,
            (res) => {
              this.delegate.spinning = false;
              let imgUrlData = HttpServerConfig.getMediaSrc(res.imageUrl);
              new imageToStyle()
                .webToBase64(imgUrlData)
                .then((imageBase64) => {
                  let width;
                  let height;
                  let img = new Image();img.setAttribute('crossOrigin','Anonymouse');
                  img.src = imgUrlData;
                  img.onload = () => {
                    width = img.width;
                    height = img.height;
                    this.addImage(imgUrlData, width, height);
                  };
                });
            },
            (error) => {
              this.delegate.spinning = false;
              console.log(error);
            },
            null,
            handler
          );
        } else {
          this.delegate.spinning = false;
          console.log("file is not a image");
        }
    }

    addImage(imgUrlData,  width, height) {
        if (this.point == null || this.point.x == null ||
            this.point.x <= 0) {
            this.delegate.editMindmapContent.addImage(imgUrlData, 
                this.delegate.editMindmapContent.selectedNodeId, 
                width, height, null, this.point);
        } else {
            // let element = this.delegate.getElemeViewInDrawingBoardByPoint(this.point);
            // if (element) {
            //     this.delegate.editMindmapContent.addImage(imgUrlData, element.mindElementData.id, width, height, null, this.point);
            // } else {
                this.delegate.editMindmapContent.addImage(imgUrlData, null, width, height, null, this.point);
            // }
        }
    }

    
}

export default ImageFileUpload