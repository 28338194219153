<template >
    <div class="group-tag-page" @click.stop="storeCklickModal(false)" v-show="tagModalShow">
      <div @click.stop="storeCklickModal(true)" class="group-tag-page-center">
            <div class="group-tag-detail-list">
                <div class="group-tag-detail-name">
                    <div class="group-tag-detail-name-title">
                        {{title}}
                    </div>
                    <div class="group-tag-detail-name-close">
                        <img class="close-button-img" src="../../../../assets/img/popupsImg/colse_new.svg"  @click.stop="cancelStorage()" />
                    </div>
                </div>
                <div style="padding: 0 20px;">
                    {{content}}？
                </div>
            </div>
            <div class="group-tap-footer">
                <div @click.stop="cancelStorage()" class="group-tap-footer-button button-class-colse" style="cursor:pointer">
                    {{ $getStringObj.getString($Strings.Mind_Group_Cancel) }}
                </div>
                <div  @click.stop="affirmStorage(true)" class="group-tap-footer-button button-class-ok" style="cursor:pointer">
                   {{ $getStringObj.getString($Strings.Mind_Group_Confirm) }}
                </div>
            </div>
      </div>
  </div>

</template>

<script>
export default {
     props: [
        "show",
    ],
    data() {
        return {
            tagModalShow: false,
            textShow: '',
            confrim: false,
            title: '',
            content: '',
        }
    },
    methods: {
        storeCklickModal(val) {
            if (val == false) { //当隐藏模态框时
                this.storeCallbackAlter({show:false, confrim: this.confrim,contentName: this.content})
                
            }
        },
        storeCallbackAlter(obj){
            this.$emit("storeCallbackAlter",obj);
        },
        affirmStorage(val) {
            this.confrim = val;
            this.storeCklickModal(false);
        },
        cancelStorage() {
            this.confrim = false;
            this.storeCklickModal(false);
        },
    },
    watch: {
        show: {
            handler(newval, olval) {
                this.tagModalShow = newval.showAlter;
                this.title = newval.title
                this.content = newval.content
            },
            deep: true,
        }
    }
}
</script>

<style lang="less" scoped>
    .group-tag-page {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 10;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,.3);
        .group-tag-page-center {
            position: absolute;
            width: 300px;
            min-height: 150px;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background-color: #fff;
            border-radius: 4px;
            .group-tag-detail-list {
                width: 100%;
                font-size: 14px;
                line-height: 40px;
                color: #333333;
                margin: 0 auto;
                margin-bottom: 20px;
                .group-tag-detail-name {
                    // text-align: center;
                    font-size: 16px;
                    margin-bottom: 10px;
                    text-align: left;
                    font-size: 16px;
                    margin-bottom: 20px;
                    height: 40px;
                    position: relative;
                    padding: 0px 20px;
                    background: #efefef;
                    border-radius: 4px 4px 0 0;
                    .group-tag-detail-name-title{
                        position: absolute;
                    }
                    .group-tag-detail-name-close{
                        position: relative;
                        float: right;
                        .close-button-img{
                            width:16px;
                            cursor: pointer;
                            opacity: 0.5;
                        }
                        .close-button-img:hover{
                            opacity: 0.8;
                        }
                    }
                }
                .group-tag-detail-input {
                    width: 100%;
                    height: 38px;
                    border: 1px solid #E2E2E2;
                    background-color: #F0F2F8;
                    outline: none;
                    border-radius: 2px;
                    font-size: 12px;
                }
                .tag-warning {
                    color: #FD492B;
                }
            }
            .group-tap-footer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                .group-tap-footer-button {
                    padding: 0 12px;
                    margin: 0 20px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    border-radius: 2px;
                    font-size: 14px;
                    // color: #333;
                    background-color: #fff;
                }
                .button-class-ok{
                    background: #FD492B;
                    color: #fff;
                }
                .button-class-colse{
                    border: 1px solid #F0F2F8;
                    color: #999999;
                }
                .button-class-colse:hover {
                    color: #FD492B;
                    border: 1px solid #FD492B;
                    background-color: #fff;
                }
                .button-class-ok:hover{
                    background: #ff7354;
                }
                // .group-tap-footer-button:hover {
                //     background-color: #FD492B;
                //     color: #fff;
                // }
            }
        }
    }
</style>