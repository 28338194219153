import Colors from "../../../utils/Colors";
import UiUtil from "../../../utils/UiUtil";
import LinePath from "../../../viewmodel/core/base/LinePath";

/**
 * ProjectName: MindMap
 * Created by tony on 2/13/21
 * Copyright(c) 2020 mindyushu.com
 */


class MindNodeUpNumberView {
    constructor(fatherDom, size, baseInput, data) {
        this.contentG;
        this.lineHeight = new UiUtil().dip2px(2);
        this.lineView;
        this.size = size;
        this.fatherDom = fatherDom;
        this.numberLabel;
        this.mousdownFunction;
        this.baseInput = baseInput;
        this.data = data;
        this.initView();

    }


    initView() {
        this.contentG = document.createElementNS('http://www.w3.org/2000/svg', 'g')
        this.contentG.style.width = this.size.width + 'px';
        this.contentG.style.height = this.size.height + 'px';
        this.contentG.setAttribute('id', 'accddff');
        this.contentG.style.transform = 'translate(' + 20 + 'px,' + 10 + 'px)';
        this.contentG.style.cursor = 'pointer';
        this.fatherDom.appendChild(this.contentG);

        this.lineView = document.createElementNS('http://www.w3.org/2000/svg', 'path')
        this.contentG.appendChild(this.lineView);
        let linePath = new LinePath();
        linePath.moveTo(0, this.size.height / 2);
        linePath.circle(this.size.height / 2, this.size.height / 2, 0, 1, 1, this.size.width, this.size.height / 2);
        linePath.circle(this.size.height / 2, this.size.height / 2, 0, 1, 1, 0, this.size.height / 2);
        this.renderCanvas(linePath, this.lineView);

        this.numberLabel = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
        this.contentG.appendChild(this.numberLabel);
        this.numberLabel.setAttribute('width', this.size.width);
        this.numberLabel.setAttribute('height', this.size.height);

        this.numberLabel.style.width = this.size.width + 'px';
        this.numberLabel.style.height = this.size.height + 'px';
        this.numberLabel.style.textAlign = 'center';
        this.numberLabel.style.lineHeight = this.size.height + 'px';
        this.numberLabel.style.userSelect = 'none'
        this.bundling()

    }

    //自己添加的一个函数
    renderCanvas(path, element) {
        element.setAttribute("d", path.getLine())
        element.setAttribute("stroke", Colors.getUiColor(Colors.black50))
        element.setAttribute("fill", "none")
        element.setAttribute("stroke-width", 2)
    }

    setColor(bg, line) {
        let newLine = line;
        if (Colors.getUiColor(bg) == Colors.getUiColor(line)) {
            if (Colors.isDarkColor(bg)) {
                newLine = Colors.white;
            } else {
                newLine = Colors.black80;
            }
        }
        this.lineView.setAttribute("stroke", Colors.getUiColor(newLine))
        this.lineView.setAttribute("fill", Colors.getUiColor(bg))
        this.numberLabel.style.color = Colors.getUiColor(newLine);
    }

    bundling() {
        this.mousdownFunction = (ev) => {
            window.event ? window.event.cancelBubble = true : ev.stopPropagation();
            this.baseInput.mindTextSizeChangedDelegate.onMousedownShowChildren(this.data.id);
        }
        this.numberLabel.addEventListener('mousedown', this.mousdownFunction)
    }

    setPoint(x, y) {
        this.showDom();
        this.contentG.style.transform = 'translate(' + x + 'px,' + y + 'px)';
    }

    setNumber(value) {
        this.numberLabel.innerHTML = "" + value;
    }

    showDom() {
        this.contentG.style.display = 'block';
    }

    hideen() {
        this.contentG.style.display = 'none';
    }
}

export default MindNodeUpNumberView
