import LineMindTypeNode from "../../../viewmodel/mindelementdata/LineMindTypeNode";
import MindElementDataDeserialize from "./MindElementDataDeserialize";


class MindElementNodeDeserialize {

    deserialize(nodeValue) {
        let node = new LineMindTypeNode()
        for (var nodeKey in nodeValue) {
            let nodeKeyValue = nodeValue[nodeKey]
            node[nodeKey] = nodeKeyValue
            if (nodeKey == "value") {
                node[nodeKey] = MindElementDataDeserialize.deserialize(nodeKeyValue);
            } else if (nodeKey == "children") {
                let children = new Array();
                let childSize = nodeKeyValue.length
                for (let index = 0; index < childSize; index++) {
                    children.push(this.deserialize(nodeKeyValue[index]))
                }
                node[nodeKey] = children
            }
        }
        return node
    }
    
}
export default new MindElementNodeDeserialize()