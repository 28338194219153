import JSZip from 'jszip'
// import JSZipUtils from "jszip-utils/dist/jszip-utils.js";
import strings from "../../../../common/lang/strings"
import getString from "../../../../common/lang/language";
if (
    typeof navigator === "object" &&
    typeof navigator.userAgent === "string" &&
    navigator.userAgent.indexOf("Electron") >= 0
) {
    var { ipcRenderer } = window.require("electron");

}
import HashMap from '../../../../viewmodel/core/base/HashMap';
import MindMapZipConstant from '../../../../viewmodel/core/tools/mapfile/MindMapZipConstant';
import LoadLocalXJMind from '../../../../viewmodel/core/tools/mapfile/LoadLocalXJMind';
import HistoryData from '../../../../viewmodel/core/tools/mapfile/data/HistoryData';
import HistoryDataCell from '../../../../viewmodel/core/tools/mapfile/data/HistoryDataCell';
import Util from '../../../../utils/Util';
import UiUtil from '../../../../utils/UiUtil';
import { setTimeout } from 'core-js';
import HttpServerConfig from '../../../../common/HttpServerConfig';

class XJMindFIleImport {
    constructor(delegate, path, appPath, isOpen = true) { //path 为.xmind路径

        this.delegate = delegate;
        this.appPath = appPath;
        this.name = "";
        this.path = path;
        this.isLoading = false;
        this.isOpen = isOpen;
        this.rootId = "";
        this.zip = null;
        this.xjmindContentJson = "";
        if (path != null) {
            this.unzip();
        }
    }

    format() {
        this.open();
    }

    open() {
        if (!this.isOpen) {
            return;
        }
        var pathStr = ""
        if ((typeof this.path) == "string") {
            pathStr = this.path;
        } else {
            pathStr = this.path.path;
        }
        if (this.rootId != null && this.rootId.length > 10 && this.rootId.indexOf("-local") == -1) {
            ipcRenderer.send("openCalendarWindow", "/mindmap?path=" + encodeURIComponent(pathStr) + 
                                "&localpath=" + encodeURIComponent(this.getLocalPath()) + 
                                "&id=" + this.rootId);
        } else {
            ipcRenderer.send("openCalendarWindow", "/mindmap?path=" + encodeURIComponent(pathStr) + "&localpath=" + encodeURIComponent(this.getLocalPath()));
        }
        if (this.delegate != null && this.delegate.reload != null) {
            this.delegate.reload();
            this.delegate.showModal = false;//隐藏导入文件弹窗
        }
        this.addHistory(pathStr, this.getLocalPath(), this.name, this.rootId)
    }

    createATagJump(url) {
        const a = document.createElement('a');//创建a标签浏览器不会阻止弹出窗口
        a.setAttribute('href', url);
        a.setAttribute('target', '_blank');
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            document.body.removeChild(a);
        }, 5000);
    }

    loadError() {
        if (this.delegate != null) {
            if (this.delegate.reload != null) {
                this.delegate.showModal = false;//隐藏导入文件弹窗
                this.delegate.reload();
            }
            
            if (ipcRenderer == null) {
                this.delegate.$message.success(getString(strings.Public_Header_Download_Apps));
                setTimeout(() => {
                    new UiUtil().createATagOpenUrl("https://www.mindyushu.com/downloads.html");
                }, 1000);
            } else {
                this.delegate.$message.error(getString(strings.Load_File_Error));
            }
        }
    }

    unzip() {//解压.xmind, 获取content.js 内容给 this.xmindContentJson
        let that = this;
        if ((typeof this.path) == "string") {
            this.name = Util.getNameByUrl(this.path)
        } else {
            this.name = this.path.name.replace(".xjmm", '');
        }
        
        return new Promise(async resolve => {
            if (ipcRenderer == null) {
                that.loadError()
                return
            }
            const jszip = new JSZip();
            if ((typeof this.path) == "string") {
                let type = Util.getUrlType(this.path)
                let blob = await LoadLocalXJMind.asyncLoadLocalFile(HttpServerConfig.getLocalPathPrefix(this.path, "uuhuus22huh"));
                const files = new window.File(
                    [blob],
                    that.name + "." + type,
                    { type: type }
                );
                jszip.loadAsync(files).then(function (zip) {
                    let contentJson = MindMapZipConstant.MindZIPContentPath;
                    if (zip.files[contentJson] == null) {
                        contentJson = MindMapZipConstant.MindZIPInsideContentPath;
                    }
                    if (zip.files[contentJson]) {//判断是否有content文件
                        jszip.file(contentJson).async("string").then((content) => {
                            let contentData = JSON.parse(content)
                            if (contentData.data != null && contentData.data.length > 0) {
                                that.rootId = contentData.data[0].id;
                            }
                            if (that.appPath != null && that.appPath.length > 0) {
                                that.parseMindData(zip).then((success) => {
                                    if (success) {
                                        that.open()
                                    } else {
                                        that.loadError()
                                    }
                                })
                            }
                        });
                    } else {
                        that.loadError();
                    }
                });
                return
            }
            jszip.loadAsync(this.path).then((zip) => { // 读取zip
                let contentJson = MindMapZipConstant.MindZIPContentPath;
                if (zip.files[contentJson] == null) {
                    contentJson = MindMapZipConstant.MindZIPInsideContentPath;
                }
                if (zip.files[contentJson]) {//判断是否有content文件
                    jszip.file(contentJson).async("string").then((content) => {
                        let contentData = JSON.parse(content)
                        if (contentData.data != null && contentData.data.length > 0) {
                            that.rootId = contentData.data[0].id;
                        }
                        if (that.appPath != null && that.appPath.length > 0) {
                            that.parseMindData(zip).then((success) => {
                                if (success) {
                                    that.open()
                                } else {
                                    that.loadError()
                                }
                            })
                        }
                    });
                } else {
                    that.loadError();
                }
            });
        });
    }



    parseMindData(zip) {
        let that = this;
        return new Promise(resolve => {
            if (that.appPath == null || that.appPath.length == 0) {
                that.zip = zip;
                that.isLoading = true;
                resolve(null)
                return
            }
            that.zip = null;
            that.isLoading = false;

            let localPath = that.getLocalPath()
            that.createFolder(localPath, this.name) //创建缓存路径
            for (let key in zip.files) { //创建文件夹
                if (zip.files[key].dir) {//判断是否是目录
                    that.createFolder(localPath + MindMapZipConstant.FileSeparator + key, "")
                }
            }
            var fs = window.require('fs');
            let loadFileMap = new HashMap();
            for (let key in zip.files) { //获取要加载文件列表
                if (!zip.files[key].dir) {
                    loadFileMap.put(key, false)
                }
            }
            if (loadFileMap.size() == 0) {
                resolve(false)
            }
            for (let key in zip.files) { //创建文件
                if (!zip.files[key].dir) {//判断是否是文件
                    if (key.indexOf(".json") >= 0 || key.indexOf(".txt") >= 0) {
                        zip.file(zip.files[key].name).async('string').then(content => {
                            fs.writeFileSync(localPath + MindMapZipConstant.FileSeparator + key, content)
                            loadFileMap.put(key, true)
                            let values = loadFileMap.values()
                            let loadComplete = true
                            for (let index = 0; index < values.length; index++) {
                                const element = values[index];
                                if (!element) {
                                    loadComplete = false;
                                    break
                                }
                            }
                            if (loadComplete) {
                                resolve(true)
                            }
                        })
                    } else {
                        zip.file(zip.files[key].name).async('blob').then(content => {
                            let newFilePath = localPath + MindMapZipConstant.FileSeparator + key
                            var fr = new FileReader();
                            fr.readAsArrayBuffer(content);
                            fr.addEventListener("loadend",(e) => {
                                var buf = e.target.result;
                                if (buf != null && buf.byteLength > 10) {
                                    try {
                                        
                                        let data = null
                                        if (key.indexOf(".txt") > 0 ||
                                            key.indexOf(".doc") > 0 ||
                                            key.indexOf(".xls") > 0) {
                                            data = new Uint8Array(buf)
                                        } else {
                                            data = new Int8Array(buf)
                                        }
                                        if (data != null) {
                                            fs.writeFile(newFilePath, data, function (err) {
                                                // if (err) {
                                                //     console.log("end error-> ", newFilePath, err)
                                                // } else {
                                                //     console.log("end-> ", newFilePath)
                                                // }
                                                loadFileMap.put(key, true)
                                                let values = loadFileMap.values()
                                                let loadComplete = true
                                                for (let index = 0; index < values.length; index++) {
                                                    const element = values[index];
                                                    if (!element) {
                                                        loadComplete = false;
                                                        break
                                                    }
                                                }
                                                if (loadComplete) {
                                                    resolve(true)
                                                }
                                            });
                                        }
                                    } catch (error) {
                                        // console.log("error-> ", error)
                                        loadFileMap.put(key, true)
                                        let values = loadFileMap.values()
                                        let loadComplete = true
                                        for (let index = 0; index < values.length; index++) {
                                            const element = values[index];
                                            if (!element) {
                                                loadComplete = false;
                                                break
                                            }
                                        }
                                        if (loadComplete) {
                                            resolve(true)
                                        }
                                    }
                                } else {
                                    loadFileMap.put(key, true)
                                    let values = loadFileMap.values()
                                    let loadComplete = true
                                    for (let index = 0; index < values.length; index++) {
                                        const element = values[index];
                                        if (!element) {
                                            loadComplete = false;
                                            break
                                        }
                                    }
                                    if (loadComplete) {
                                        resolve(true)
                                    }
                                }
                            },false);
                        })
                    }
                    
                }
            }
        });
    }

    getLocalPath() {
        var path = this.appPath + MindMapZipConstant.MindZIPLocalFilesFolderName + MindMapZipConstant.FileSeparator + this.name
        let specialSymbolList = ["?", "\r", "\n"]
        for (let index = 0; index < specialSymbolList.length; index++) {
            const tag = specialSymbolList[index];
            if (path.indexOf(tag) > -1) {
                path = path.replace(tag, "")
            }
        }
        return path
    }

    getHistoryPath() {
        return this.appPath + MindMapZipConstant.MindZIPLocalFilesFolderName + MindMapZipConstant.FileSeparator + MindMapZipConstant.MindZIPLocalFilesHistoryName
    }

    getLocalContentPath() {
        return this.getLocalPath() + MindMapZipConstant.FileSeparator + MindMapZipConstant.MindZIPContentFolderName
    }

    getLocalFilesPath() {
        return this.getLocalPath() + MindMapZipConstant.FileSeparator + MindMapZipConstant.MindZIPFilesFolderName
    }

    setTempFile() {
        
    }

    createFolder(dirpath, dirname) {
        var fs = window.require('fs');
        var path = window.require('path');
        if (typeof dirname === "undefined") {
          if (fs.existsSync(dirpath)) {
          } else {
            this.createFolder(dirpath, path.dirname(dirpath));
          }
        } else {
          if (dirname !== path.dirname(dirpath)) {
            this.createFolder(dirpath);
            return;
          }
          if (fs.existsSync(dirname)) {
            fs.mkdirSync(dirpath,{recursive:true})
          } else {
            this.createFolder(dirname, path.dirname(dirname));
            fs.mkdirSync(dirpath,{recursive:true});
          }
        }
    }

    writeFile(path, content) {
        var fs = window.require('fs');
        fs.writeFileSync(path, content)
    } 
    
    addHistory(path, localpath, name, id) {
        if (path == null || localpath == null || localpath.length == 0) {
            return
        }
        let that = this
        this.loadHistoryData().then((historyData) => {
            let count = historyData.list.length;
            let list = []
            let historyDataCell = new HistoryDataCell()
            historyDataCell.name = name
            historyDataCell.time = Util.getCurrentTime()
            historyDataCell.path = path
            historyDataCell.localpath = localpath
            historyDataCell.id = id
            list.push(historyDataCell)
            if (count > 0) {                
                for (let index = 0; index < count; index++) {
                    const cell = historyData.list[index];
                    if (cell.localpath != localpath) {
                        list.push(cell)
                    }
                }
            }
            historyData.list = list
            let json = historyData.toJSONString()
            if (json != null && json.length > 0) {
                let historyPath = that.getHistoryPath()
                that.writeFile(historyPath, json);
            }
        });
    }

    changeHistoryItemId(path, localpath, oldId, newId) {
        if (path == null || path.length == 0) {
            return
        }
        let that = this
        this.loadHistoryData().then((historyData) => {
            let count = historyData.list.length;
            if (count > 0) {                
                for (let index = 0; index < count; index++) {
                    const cell = historyData.list[index];
                    if (cell.path == path && cell.localpath == localpath) {
                        cell.id = newId;
                    }
                }
            }
            let json = historyData.toJSONString()
            if (json != null && json.length > 0) {
                let historyPath = that.getHistoryPath()
                that.writeFile(historyPath, json);
            }
        });
    }

    changeHistoryItemPath(path, localpath) {
        if (path == null || path.length == 0) {
            return
        }
        let that = this
        this.loadHistoryData().then((historyData) => {
            let count = historyData.list.length;
            if (count > 0) {                
                for (let index = 0; index < count; index++) {
                    const cell = historyData.list[index];
                    if (cell.localpath == localpath) {
                        cell.path = path;
                    }
                }
            }
            let json = historyData.toJSONString()
            if (json != null && json.length > 0) {
                let historyPath = that.getHistoryPath()
                that.writeFile(historyPath, json);
            }
        });
    }

    removeHistory(path, localpath, name) {
        if (path == null || localpath == null || localpath.length == 0) {
            return
        }
        let that = this
        this.loadHistoryData().then((historyData) => {
            let count = historyData.list.length;
            let list = []
            if (count > 0) {                
                for (let index = 0; index < count; index++) {
                    const cell = historyData.list[index];
                    if (cell.path != path || cell.localpath != localpath) {
                        list.push(cell)
                    }
                }
            }
            historyData.list = list
            let json = historyData.toJSONString()
            if (json != null && json.length > 0) {
                let historyPath = that.getHistoryPath()
                that.writeFile(historyPath, json);
            }
        });
    }

    loadHistoryData() {
        let historyPath = this.getHistoryPath()
        return new Promise(resolve => {
            var fs = window.require('fs');
            let isExist = fs.existsSync(historyPath)
            let historyData = new HistoryData()
            historyData.list = [];
            if (isExist) {
                LoadLocalXJMind.loadJson(historyPath).then((json) => {
                    if (json != null && json.length > 0) {
                        let historyDataJson = JSON.parse(json)
                        if (historyData.list != null && historyDataJson.list.length > 0) {
                            for (let index = 0; index < historyDataJson.list.length; index++) {
                                const cell = historyDataJson.list[index];
                                let historyDataCell = new HistoryDataCell()
                                historyDataCell.name = cell.name
                                historyDataCell.time = cell.time
                                historyDataCell.path = cell.path
                                historyDataCell.id = cell.id == null ? "" : cell.id
                                historyDataCell.localpath = cell.localpath
                                historyData.list.push(historyDataCell)
                            }
                        }
                    }                    
                    resolve(historyData)
                });
            } else {
                resolve(historyData)
            }
        })
    }
}

export default XJMindFIleImport