import Config from "../../../core/core/calcule/Config";
import Colors from "../../../utils/Colors";
import UiUtil from "../../../utils/UiUtil";
import MindElementType from "../../../viewmodel/datatype/MindElementType";
import OutlineInputView from "./OutlineInputView";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/6/23
 * Copyright(c) 2020 mindyushu.com
 */

class OutlineGeneralizationInputView extends OutlineInputView {
    constructor(context, data, index, nextView, outlineVue) {
        super(context, data, index, nextView, outlineVue);
    }

    initView() {
        super.initView();
    }

    setRowMark() {
    }

    setRowMarkLayout() {
    }

    getStartLeft() {
        return this.mindElementData.level * Config.MindOutlineImageSize + Config.MindInputText_lineFragmentPadding - new UiUtil().dip2px(3);
    }

    setTextStyleData() {
        this.inputTextView.style.fontSize = Config.OutlineGeneralizationTextSize
        this.inputTextView.style.color = Colors.getUiColor(this.getTextColor())
        this.inputTextView.style.fontWeight = this.getTextBold() == true ? 'bold' : ''
        this.inputTextView.style.fontStyle = this.getTextItalics() == true ? 'italic' : ''
        this.inputTextView.style.textDecoration = this.getTextStrikethrough() == true ? 'line-through' : ''
        this.inputTextView.style.outline = '0'
        if (this.mindElementData.type == MindElementType.SON_SUBJECT) {
            this.textAndIconContent.style.marginLeft = this.clearNumberPx(this.editSmallDot.style.width) + 9 + this.mindElementData.textContent.x + 'px'
        } else {
            this.textAndIconContent.style.marginLeft = this.clearNumberPx(this.editSmallDot.style.width) + 13 + 'px'
        }
    }

    getTextColor() {
        let color = Colors.black40;
        if (this.mindElementData.textContent != null) {
            color = this.mindElementData.textContent.textColor;
        } else if (this.mindElementData.generalizationContent != null) {
            color = this.mindElementData.generalizationContent.textColor;
        }
        if (Colors.getUiColor(color) == -1 || Colors.getUiColor(color) == Colors.getUiColor(Colors.white)) {
            color = Colors.black40;
        }

        return color;
    }

    getTextUIEdgeInsets() {
        return Config.GeneralizationOutlineInputUIEdgeInsets;
    }
}
export default OutlineGeneralizationInputView