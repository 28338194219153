
import Config from "../../../core/core/calcule/Config"
import MindElementCalculation from "../../../core/core/calcule/elementCalculation/MindElementCalculation"
import Colors from "../../../utils/Colors"
import UiUtil from "../../../utils/UiUtil"
import Util from "../../../utils/Util"
import IdGenerator from "../base/IdGenerator"
import LineColorMode from "../base/LineColorMode"
import LineOrientation from "../../datatype/LineOrientation"
import MindElementType from "../../datatype/MindElementType"
import LineElementContent from "../../mindelementdata/mindcontent/LineElementContent"
import BaseLayout from "./BaseLayout"
import CGPoint from "../../../viewmodel/core/base/basedata/CGPoint"
import HashMap from "../../core/base/HashMap";
import Point from "../base/Point"
import NodeLayoutType from "../../datatype/NodeLayoutType"
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/4
 * Copyright(c) 2020 mindyushu.com
 */

class BubbleLayout extends BaseLayout {
    constructor() {
        super();
        this.angle = 180.0;
        this.startAngle = 0.0;
        this.sonSubjectAngle = 45.0;
        this.subjectRadius = new UiUtil().dip2px(80);
        this.sonSubjectRadius = new UiUtil().dip2px(50);
        this.nodeAngle = new HashMap();
    }

    initConfig() {
        super.initConfig();
        this.subjectRadius = this.addSubjectSpcaeHorizontal(new UiUtil().dip2px(80));
        this.sonSubjectRadius = this.addSonSubjectSpcaeHorizontal(new UiUtil().dip2px(50));
    }

    onLayout(isChange) {
        this.calculeAngle();
        this.setElementsPoint(isChange);
    }

    calculeAngle() {
        if (this.rootTreeNode == null || this.rootTreeNode.children.length == 0) {
            return;
        }
        let count = this.rootTreeNode.children.length;
        this.angle = 360 / count;
    }

    setElementsPoint(isChange) {
        if ((!isChange || this.rootTreeNode.value.y == -1 || this.rootTreeNode.value.x == -1) && this.rootTreeNode.value.type == MindElementType.MAIN_SUBJECT) {
            let left = (Config.Mind_Width - new UiUtil().getScreenWidth()) / 2;
            let top = (Config.Mind_Height - new UiUtil().getScreenHeight()) / 2;
            this.rootTreeNode.value.y = (new UiUtil().getScreenHeight() - this.rootTreeNode.value.height) / 2 + top;
            this.rootTreeNode.value.x = new UiUtil().dip2px(10) + left;
        }
        this.initConfig();
        this.hideRootFormLine();
        this.setNodeSize(this.rootTreeNode);
        this.resetNodeSize(this.rootTreeNode);
        this.setElementLine(this.rootTreeNode, isChange);
        this.setNodeElementsPoint(this.rootTreeNode, isChange);
    }

    setNodeElementsPoint(node, isChange) {
        this.setDataPoint(node.value, isChange);
        if (node.children.length == 0) {
            return;
        }
        for (let index = 0; index < node.children.length; index++) {
            this.setNodeElementsPoint(node.children[index], isChange);
        }
    }


    setNodeSize(node) {
        MindElementCalculation.set(node.value).calcule();
        if (node.children.length == 0) {
            return;
        }
        for (let index = 0; index < node.children.length; index++) {
            this.setNodeSize(node.children[index]);
        }
    }

    resetNodeSize(node) {
        let maxHeight = this.getBrotherMaxSize(node);
        if (maxHeight > node.value.height) {
            this.setNodeWidth(node, maxHeight - node.value.width);
            this.setNodeHeight(node, maxHeight - node.value.height, true);
        }
        if (node.children.length == 0) {
            return;
        }
        for (let index = 0; index < node.children.length; index++) {
            this.resetNodeSize(node.children[index]);
        }
    }

    getBrotherMaxSize(node) {
        let height = node.value.height;
        let parentNode = this.getNodeById(node.value.parentNodeId);
        if (parentNode.isEmpty()) {
            return height;
        }
        let parentNodeLen = parentNode.children.length;
        for (let index = 0; index < parentNodeLen; index++) {
            let child = parentNode.children[index]
            if (height < child.value.height) {
                height = child.value.height;
            }
        }
        return height;
    }


    setDataPoint(data, isChange) {
        if (data.isHidden) {
            return;
        }
        let node = this.getNodeById(data.id);
        let parentNode = this.getNodeById(data.parentNodeId);
        this.pushCurrentLayoutNode(node);
        let parentNodeData = parentNode.value;
        let siblingsNodeDatas = this.getSiblingsMindElementDataById(data.id);

        if (data.id == this.rootTreeNode.value.id) {
            return;
        } else {
            if (data.isFreeLayoutNode && this.settingData.branchNodeFreelayout.isValue() && data.type == MindElementType.SUBJECT) {
                if (data.x > 0 && data.y > 0) {
                    let startPoint = new Point(this.rootTreeNode.value.x + this.rootTreeNode.value.width/2, this.rootTreeNode.value.y + this.rootTreeNode.value.height/2)
                    let endPoint = new Point(data.x + data.width/2, data.y + data.height/2)
                    let angle = this.Util.getDegreesByTwoPoints(startPoint, endPoint);
                    this.nodeAngle.put(data.id, angle);
                    this.setElementLine(node, isChange);
                    return
                }
            }

            let parentCenterPoint = new CGPoint((parentNode.value.x) + (parentNode.value.width / 2),
                (parentNode.value.y) + (parentNode.value.height / 2));
            let radius = (parentNode.value.width / 2) + (data.width / 2);
            if (parentNode.value.bubbleRadius <= 0) {
                if (data.type == MindElementType.SUBJECT) {
                    radius = radius + this.subjectRadius;
                } else if (data.type == MindElementType.SON_SUBJECT) {
                    radius = radius + this.sonSubjectRadius;
                }
            } else {
                radius = radius + (parentNode.value.bubbleRadius);
            }
            let allSiblingsNodesPosition = this.getNodeInNodesPosition(siblingsNodeDatas, data);
            let count = this.getDispayChildrenNumber(parentNode);
            let dataSiblingsNodesAngle = 360 / count;
            let dataAngle = dataSiblingsNodesAngle * allSiblingsNodesPosition;
            if (this.nodeAngle.containsKey(parentNode.value.id)) {//this.nodeAngle.keys().includes(parentNode.value.id)
                if (parentNode.children.length <= 4) {
                    dataAngle = this.sonSubjectAngle * allSiblingsNodesPosition;
                    dataAngle = dataAngle + this.nodeAngle.get(parentNode.value.id) - ((parentNode.children.length - 1) * this.sonSubjectAngle) / 2;
                } else {
                    dataAngle = dataAngle + this.nodeAngle.get(parentNode.value.id);
                }
            }
            this.nodeAngle.put(data.id, dataAngle);
            let x = parentCenterPoint.x + ((radius) * Util.cosd(dataAngle));
            let y = parentCenterPoint.y + ((radius) * Util.sind(dataAngle));

            data.y = y - (data.height) / 2;
            data.x = x - (data.width) / 2;
            
        }
        this.setElementLine(node, isChange);
    }

    getDispayChildrenNumber(node) {
        let count = 0;
        node.children.forEach(child => {
            if (!child.value.isHidden) {
                count += 1;
            }
        });
        return count;
    }

    getIntersectionPoint(p1, p2) {
        //已知直线上的两点P1(X1,Y1) P2(X2,Y2)，直线的一般式方程AX+BY+C=0中， A = Y2 - Y1, B = X1 - X2, C = X2*Y1 - X1*Y2
        //直线l1：p1ax+p1by+p1c=0 直线l2：p2ax+p2by+p2c=0 交点坐标为((p1b*p2c-p2b*p1c)/(p1a*p2b-p2a*p1b)，(p2a*p1c-p1a*p2c)/(p1a*p2b-p2a*p1b))
        this.p1a = p1[1].y - p1[0].y;
        this.p1b = p1[0].x - p1[1].x;
        this.p1c = p1[1].x * p1[0].y - p1[0].x * p1[1].y;
        
        this.p2a = p2[1].y - p2[0].y;
        this.p2b = p2[0].x - p2[1].x;
        this.p2c = p2[1].x * p2[0].y - p2[0].x * p2[1].y;

        return new CGPoint((this.p1b * this.p2c - this.p2b * this.p1c) / (this.p1a * this.p2b - this.p2a * this.p1b), (this.p2a * this.p1c - this.p1a * this.p2c) / (this.p1a * this.p2b - this.p2a * this.p1b));
    }

    setElementLine(node, isChange) {
        if (node == null || node.isEmpty() || node.value.isHidden || this.isRootNode(node)) {
            return;
        }
        let data = node.value;
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        if (parentNodeData.isEmpty()) {
            return;
        }
        let isCreateLineData = false;
        let lineData = this.getLineData(node);
        lineData.parentNodeId = parentNodeData.id;
        let lineLayout = this.getNodeLineLayout(node, lineData);
        if (lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            this.lineMindElementDataDict.put(lineData.id, lineData);
            this.textElementLineMindElementDataDict.put(node.value.id, lineData);
            isCreateLineData = true;
        }
        lineData.isHidden = false
        lineData.type = MindElementType.LINE;
        let parentCenterPoint = new CGPoint((parentNode.value.x) + (parentNode.value.width / 2),
            (parentNode.value.y) + (parentNode.value.height / 2));
        let dataCenterPoint = new CGPoint((data.x) + (data.width / 2),
            (data.y) + (data.height / 2));

        let startPoint = new CGPoint(0, (lineData.height));
        let endPoint = new CGPoint((lineData.width), 0);
        let parentIntersectionPoint = parentNode.value.getRightAngleShapeHitPoint(dataCenterPoint, 0);
        let dataIntersectionPoint = data.getRightAngleShapeHitPoint(parentCenterPoint, 0);
        lineData.width = Math.abs(parentIntersectionPoint.x - dataIntersectionPoint.x);
        lineData.height = Math.abs(parentIntersectionPoint.y - dataIntersectionPoint.y);
        let startPointX = 0;
        let startPointY = 0;
        let endPointX = 0;
        let endPointY = 0;

        if (parentIntersectionPoint.x < dataIntersectionPoint.x) {
            lineData.x = parentIntersectionPoint.x;
            startPointX = 0;
            endPointX = lineData.width;
        } else {
            lineData.x = dataIntersectionPoint.x;
            startPointX = lineData.width;
            endPointX = 0;
        }
        if (parentIntersectionPoint.y < dataIntersectionPoint.y) {
            lineData.y = parentIntersectionPoint.y;
            startPointY = 0;
            endPointY = lineData.height;
        } else {
            lineData.y = dataIntersectionPoint.y;
            startPointY = lineData.height;
            endPointY = 0;
        }
        endPoint = new CGPoint(endPointX, endPointY);
        startPoint = new CGPoint(startPointX, startPointY);

        if (lineData.lineContent == null) {
            lineData.lineContent = new LineElementContent(startPoint, endPoint, 0x333333, data.id);
        } else {
            lineData.lineContent.setStartPoint(startPoint);
            lineData.lineContent.setEndPoint(endPoint);
        }
        lineData.lineContent.orientation = LineOrientation.RIGHT;
        lineData.lineContent.lineLayout = lineLayout;
        if (data.type == MindElementType.SON_SUBJECT) {
            lineData.lineContent.lineThicken = false;
        }
        if (isCreateLineData) {
            let lineNodeContent = lineData.lineContent;
            if (this.textElementLineMindElementDataDict.containsKey(parentNode.value.id)) {
                let parentNodeLine = this.textElementLineMindElementDataDict.get(parentNode.value.id);
                lineNodeContent.color = parentNodeLine.lineContent.color;
                lineNodeContent.lineWidth = parentNodeLine.lineContent.lineWidth;
                lineNodeContent.dottedLine = parentNodeLine.lineContent.dottedLine;
            } else {
                lineNodeContent.color = node.value.styleColor;
                if (this.isUnderline(data)) {
                    lineNodeContent.lineWidth = data.borderWidth;
                } else {
                    lineNodeContent.lineWidth = this.mindMapStyleColor.getSubjectLineWidth();
                }
            }
            if (lineData.type == MindElementType.LINE && !this.settingData.lineColor.isEmpty()) {
                let colorPoint = (parentNode.children.length - 1) % this.settingData.lineColor.length;
                lineNodeContent.color = this.settingData.lineColor[Math.abs(colorPoint)]
                // let parentNodeChildrenSize = parentNode.children.length;
                // let lineColorLength = this.settingData.lineColor.length;
                // for (let index = 0; index < parentNodeChildrenSize; index++) {
                //     if (parentNode.children[index].value.id == node.value.id) {
                //         let colorPoint = index % lineColorLength;
                //         lineNodeContent.color = this.settingData.lineColor[colorPoint];
                //         break;
                //     }
                // }
            }
            lineData.backgroundColor = Colors.clear;
            lineData.borderColor = Colors.clear;
            lineData.borderWidth = 0.0;
        }
        this.setLineThicken(lineData);
    }

    getLayoutType() {
        return NodeLayoutType.LAYOUT_BUBBLE;
    }
}

export default BubbleLayout
