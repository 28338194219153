<template>
  <div class="shopping-model" ref="shoppingModel" @click.stop="">
    <div class="bg-color" v-if="Showbg"></div>
    <div class="shopping-play-father-box" v-if="ShowPlayModel">
      <ShoppingPlayModel @HideShoppingPlayModel="HideShoppingPlayModel" />
    </div>
    <div class="shopping-box">
      <div class="row" v-if="msg === 0 || msg === 1">
        <div class="Vip-user">
          <div id="box">
            <div class="tab-tit">
              <span :class="{ active: msg === 0 }">
                <a href="javascript:;" @click="msg = 0">{{
                  getString(strings.Mind_Edit_Left_Menu_Open_Membership)
                }}</a>
              </span>
              <span @click="msg = 2" :class="{ active: msg === 2 }">
                <a href="javascript:;">{{ getString(strings.Mind_MBean) }}</a>
              </span>
              <span @click="msg = 1" :class="{ active: msg === 1 }">
                <a href="javascript:;">{{
                  getString(
                    strings.Mind_Edit_Left_Menu_Activation_Code_Exchange
                  )
                }}</a>
              </span>
            </div>
            <div class="tab-con">
              <div class="open-membership" v-show="msg === 0">
                <div class="membership-list">
                  <section class="membership-container">
                    <div
                      v-for="item in priceList"
                      :key="item.id"
                      @click="selectedCombo(item)"
                      :class="selectedObj.id == item.id ? 'selected-class' : ''"
                      class="open-account"
                    >
                      <main class="op-two">
                        <p v-if="item.extra" class="op-img">
                          {{ item.extra }}
                        </p>
                        <span class="nk">{{ item.title }}</span>
                        <span class="discounts"
                          >￥<i style="font-size: 24px">{{
                            item.price
                          }}</i></span
                        >
                        <span v-show="item.originalPrice != ''" class="pice"
                          >￥ {{ item.originalPrice }}</span
                        >
                        <img
                          src="../../assets/img/user/member/SelectMemberPackage.svg"
                          alt=""
                          class="select-member-package"
                          v-if="selectedObj.id == item.id"
                        />
                      </main>
                      <ul class="membership-rights">
                        <li
                          class="membership-rights-item"
                          v-for="(rightsItem, rightsIndex) in item.memberRights"
                          :key="rightsIndex"
                        >
                          {{ rightsItem.title }}
                        </li>
                      </ul>
                    </div>
                  </section>
                  <div class="open-alust">
                    <p class="op-two">
                      <span class="nk">{{
                        getString(strings.Mind_Edit_Left_Menu_Coupon)
                      }}</span>
                      <span class="shoppe">
                        <input
                          type="text"
                          @blur="changeCouponSearch"
                          @focus="focusSelect($event)"
                          v-model="discountCouponCode"
                          :placeholder="
                            getString(
                              strings.Mind_Edit_Left_Menu_Please_Enter_The_Coupon_Code
                            )
                          "
                          class="ai-group"
                        />
                      </span>
                    </p>
                    <!-- 验证优惠券 -->
                    <!-- <p v-show="discountCouponCode == ''" class="op-three">
                      <button>{{getString(strings.Mind_Edit_Left_Menu_Verification)}}</button>
                    </p>
                    <p v-show="discountCouponCode != ''" @click="employCoupon()" class="op-three-2">
                      <button>{{clipCouponsBtnText}}</button>
                    </p> -->
                  </div>
                  <div v-if="warningCoupon != ''" class="warningCoupon">
                    <a-icon type="exclamation-circle" /> {{ warningCoupon }}
                  </div>
                  <div class="membership-open-di">
                    <div class="pay-class">
                      <div class="payment-method">
                        {{
                          getString(strings.Mind_Edit_Left_Menu_Payment_Type)
                        }}
                      </div>
                      <div class="checkinput">
                        <input
                          type="radio"
                          v-model="selectedMethodCoupon"
                          id="alipay"
                          class="quanes"
                          value="alipay-web"
                        />
                        <label
                          for="alipay"
                          :class="
                            selectedMethodCoupon == 'alipay-web'
                              ? 'payment-method-selected'
                              : ''
                          "
                        >
                          <a-icon type="alipay-circle" class="ali-icon" />
                          {{ getString(strings.Alipay) }}
                          <img
                            v-if="selectedMethodCoupon == 'alipay-web'"
                            src="../../assets/img/user/member/selectedBg.svg"
                            alt=""
                            class="selectedBg"
                          />
                        </label>
                        <input
                          type="radio"
                          v-model="selectedMethodCoupon"
                          id="wechatpay"
                          class="quanes"
                          value="wechatpay-web"
                        />
                        <label
                          for="wechatpay"
                          :class="
                            selectedMethodCoupon == 'wechatpay-web'
                              ? 'payment-method-selected'
                              : ''
                          "
                        >
                          <img
                            src="../../assets/img/user/member/wechatPayment.png"
                            alt=""
                            style="margin-right: 10px"
                          />
                          {{ getString(strings.WeChat) }}
                          <img
                            v-if="selectedMethodCoupon == 'wechatpay-web'"
                            src="../../assets/img/user/member/selectedBg.svg"
                            alt=""
                            class="selectedBg"
                          />
                        </label>
                      </div>
                    </div>
                    <ul>
                      <li class="activity-mbean-price">
                        <p class="op-two" style="width: 80%">
                          <span class="shoppe">
                            {{ getString(strings.Mind_Shooping_Total) }}：
                          </span>
                          <b
                            class="op-three"
                            v-if="JSON.stringify(selectedObj) !== '{}'"
                          >
                            <span
                              v-if="
                                couponMessage.discount != 0 && couponsCanBeUsed
                              "
                              class="discounts"
                            >
                              {{ selectedObj.unitSymbol }}
                              {{
                                selectedObj.priceAmount - couponMessage.discount
                              }}
                            </span>
                            <span
                              class="discounts"
                              :class="{
                                'original-pice':
                                  couponMessage.discount != 0 &&
                                  couponsCanBeUsed,
                              }"
                            >
                              {{ selectedObj.unitSymbol }}
                              {{ selectedObj.priceAmount }}
                            </span>
                          </b>
                          <span
                            v-if="meMessage && meMessage.isMember == true"
                            class="si-btn"
                          >
                            <button @click="payMoney">
                              {{
                                getString(
                                  strings.Mind_Edit_Left_Menu_Immediate_Renewal
                                )
                              }}
                            </button>
                          </span>
                          <span v-else class="si-btn">
                            <button @click="payMoney">
                              {{
                                getString(strings.Mind_Shooping_Confirm_Payment)
                              }}
                            </button>
                          </span>
                        </p>
                      </li>
                      <li class="wu">
                        <p>
                          <span class="at-agrees">
                            <a
                              href="https://www.mindyushu.com/agreement.html"
                              target="_blank"
                              >{{
                                getString(
                                  strings.Mind_Edit_Left_Menu_Terms_Of_Service
                                )
                              }}</a
                            >
                            <a
                              href="https://www.mindyushu.com/privacy.html"
                              target="_blank"
                              >{{
                                getString(
                                  strings.Mind_Edit_Left_Menu_Privacy_Policy
                                )
                              }}</a
                            >
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="activation_code" v-show="msg === 1">
                <div class="membership-list">
                  <div class="open-alust">
                    <p class="op-two op-active">
                      <span class="nk">{{
                        getString(strings.Mind_Edit_Left_Menu_Activation_Code)
                      }}</span>
                      <span class="shoppe">
                        <input
                          type="text"
                          @blur="blurActiveInput"
                          @input="changeActiveSearch"
                          v-model="activeCode"
                          @focus="focusSelect($event)"
                          :placeholder="
                            getString(
                              strings.Mind_Edit_Left_Menu_Please_Input_Activation_Code
                            )
                          "
                          class="ai-group"
                        />
                      </span>
                    </p>
                  </div>
                  <div v-if="warningActive != ''" class="warningCoupon">
                    <a-icon type="exclamation-circle" /> {{ warningActive }}
                  </div>

                  <div class="open-di">
                    <ul>
                      <li class="wu">
                        <p>
                          <span class="at-agrees">
                            <a
                              href="https://www.mindyushu.com/agreement.html"
                              target="_blank"
                              >{{
                                getString(
                                  strings.Mind_Edit_Left_Menu_Terms_Of_Service
                                )
                              }}</a
                            >
                            <a
                              href="https://www.mindyushu.com/privacy.html"
                              target="_blank"
                              >{{
                                getString(
                                  strings.Mind_Edit_Left_Menu_Privacy_Policy
                                )
                              }}</a
                            >
                          </span>
                        </p>
                      </li>
                      <li class="si">
                        <p class="si-btn">
                          <button @click="conversionCode">
                            {{ getString(strings.Mind_Shooping_Redeem_Now) }}
                          </button>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="HideShoppingModel">
          <a-icon type="close-circle" class="icon-remove" @click="HideShoppingModel" />
        </div>
      </div>
      <!-- 显示购买M豆弹框 -->
      <section class="m-bean" v-else>
        <div class="m-bean-box">
          <div class="tab-tit">
            <span>
              <a href="javascript:;" @click="msg = 0">{{
                getString(strings.Mind_Edit_Left_Menu_Open_Membership)
              }}</a>
            </span>
            <span @click="msg = 2" class="active">
              <a href="javascript:;">{{ getString(strings.Mind_MBean) }}</a>
            </span>
            <span @click="msg = 1">
              <a href="javascript:;">{{
                getString(strings.Mind_Edit_Left_Menu_Activation_Code_Exchange)
              }}</a>
            </span>
          </div>
          <div class="HideShoppingModel">
            <a-icon
              type="close-circle"
              class="icon-remove"
              @click="HideShoppingModel"
            />
          </div>
        </div>
        <div class="me-m-bean">
          <section class="me-m-bean-content">
            <div class="me-m-bean-title">
              {{ getString(strings.Mind_My_MBean) }}
            </div>
            <div class="me-m-bean-container">
              <div class="my-bean-num">
                {{ myMBeans }}
              </div>
              <img src="../../assets/img/user/member/m_bean.png" alt="" />
            </div>
          </section>
        </div>
        <div class="m-bean-ship">
          <div
            class="m-bean-ship-box"
            v-for="item in mbeansPrices"
            :key="item.id"
            @click="selectedMbeansPrices(item)"
            :class="selectedmbeans.id == item.id ? 'selected-class' : ''"
          >
            <img
              src="../../assets/img/user/member/m_bean.png"
              alt=""
              width="16px"
              style="margin-top: 6px"
            />
            <div class="m-bean-ship-num" :title="item.title">
              {{ item.mbeansNum }}
            </div>
            <div class="m-bean-ship-line"></div>
            <div class="m-bean-ship-price">
              {{ unitSymbol }}{{ item.price }}
            </div>
            <img
              v-if="selectedmbeans.id == item.id"
              src="../../assets/img/user/member/selectedMbeansBg.svg"
              alt=""
              class="selectedBg"
            />
          </div>
        </div>
        <div class="mbean-open-di">
          <div class="pay-class">
            <div class="payment-method">
              {{ getString(strings.Mind_Edit_Left_Menu_Payment_Type) }}
            </div>
            <div class="checkinput">
              <input
                type="radio"
                v-model="selectedMethodCoupon"
                id="alipay"
                class="quanes"
                value="alipay-web"
              />
              <label
                for="alipay"
                :class="
                  selectedMethodCoupon == 'alipay-web'
                    ? 'payment-method-selected'
                    : ''
                "
              >
                <a-icon type="alipay-circle" class="ali-icon" />
                {{ getString(strings.Alipay) }}
                <img
                  v-if="selectedMethodCoupon == 'alipay-web'"
                  src="../../assets/img/user/member/selectedBg.svg"
                  alt=""
                  class="selectedBg"
                />
              </label>
              <input
                type="radio"
                v-model="selectedMethodCoupon"
                id="wechatpay"
                class="quanes"
                value="wechatpay-web"
              />
              <label
                for="wechatpay"
                :class="
                  selectedMethodCoupon == 'wechatpay-web'
                    ? 'payment-method-selected'
                    : ''
                "
              >
                <img
                  src="../../assets/img/user/member/wechatPayment.png"
                  alt=""
                  style="margin-right: 10px"
                />
                {{ getString(strings.WeChat) }}
                <img
                  v-if="selectedMethodCoupon == 'wechatpay-web'"
                  src="../../assets/img/user/member/selectedBg.svg"
                  alt=""
                  class="selectedBg"
                />
              </label>
            </div>
          </div>
          <ul>
            <li class="activity-mbean-price">
              <p class="op-two" style="width: 80%">
                <span class="shoppe">
                  {{ getString(strings.Mind_Shooping_Total) }}：
                </span>
                <b class="op-three">
                  <span class="discounts">
                    {{ unitSymbol }}
                    {{ selectedmbeans.price }}
                  </span>
                </b>
                <span
                  v-if="meMessage && meMessage.isMember == true"
                  class="si-btn"
                >
                  <button @click="payMoneyMbean">
                    {{ getString(strings.Mind_Shooping_Confirm_Payment) }}
                  </button>
                </span>
                <span v-else class="si-btn">
                  <button @click="payMoneyMbean">
                    {{ getString(strings.Mind_Shooping_Confirm_Payment) }}
                  </button>
                </span>
              </p>
            </li>
            <li class="wu">
              <p>
                <span class="at-agrees">
                  <a
                    href="https://www.mindyushu.com/agreement.html"
                    target="_blank"
                    >{{
                      getString(strings.Mind_Edit_Left_Menu_Terms_Of_Service)
                    }}</a
                  >
                  <a
                    href="https://www.mindyushu.com/privacy.html"
                    target="_blank"
                    >{{
                      getString(strings.Mind_Edit_Left_Menu_Privacy_Policy)
                    }}</a
                  >
                </span>
              </p>
            </li>
          </ul>
        </div>
      </section>
    </div>
    <!-- 微信支付结果 -->
    <a-modal
      :title="getString(strings.Mind_Edit_Left_Menu_Wx_Pay)"
      v-model="payModal"
      :ok-text="getString(strings.Global_Ok)"
      :cancel-text="getString(strings.Global_Cancel)"
      @cancel="hidepayModal"
      centered
      class="wx-play-model"
    >
      <div v-show="weixinPayShow == 0" class="payCode" ref="qrCodeDiv"></div>
      <div v-show="weixinPayShow == 1">
        <img
          class="pay-code-img"
          src="../../assets/img/user/paymentSuccessful.svg"
          alt=""
        />
        <div class="pay-show-text">
          {{ weixinPayText }}
        </div>
      </div>
      <div v-show="weixinPayShow == 2">
        <img
          class="pay-code-img"
          src="../../assets/img/user/weixinpaywarning.png"
          alt=""
        />
        <div class="pay-show-text">
          {{ weixinPayText }}
        </div>
      </div>
      <template slot="footer">
        <a-button class="alipay-button ant-btn-primary" @click="hidepayModal">{{
          getString(strings.Global_Ok)
        }}</a-button>
      </template>
    </a-modal>
    <!-- 支付宝支付结果 -->
    <a-modal
      style="z-index: 999999; position: relative"
      :title="getString(strings.Alipay_Payment)"
      v-model="AlipayModal"
      :ok-text="getString(strings.Global_Ok)"
      @cancel="hidepayModal"
      centered
    >
      <div v-show="aliPayShow == 0" class="alipay">
        {{ getString(strings.Please_Pay_On_The_Payment_Page) }}！
      </div>
      <div v-show="aliPayShow == 1">
        <img
          class="pay-code-img"
          src="../../assets/img/user/paymentSuccessful.svg"
          alt=""
        />
        <div class="pay-show-text">
          {{ aliPayText }}
        </div>
      </div>
      <div v-show="aliPayShow == 2">
        <img
          class="pay-code-img"
          src="../../assets/img/user/weixinpaywarning.png"
          alt=""
        />
        <div class="pay-show-text">
          {{ aliPayText }}
        </div>
      </div>
      <template slot="footer">
        <a-button class="alipay-button ant-btn-primary" @click="hidepayModal">{{
          getString(strings.Global_Ok)
        }}</a-button>
      </template>
    </a-modal>

    <a-modal :centered="true" v-model="couponDetailedInformation" footer>
      <div class="active-modal-content">
        <div class="active-modal-content-title">
          {{ getString(strings.Mind_Confirm_Use_Coupons) }}
        </div>
        <div class="active-modal-content-each">
          <span>{{ getString(strings.Mind_Edit_Left_Menu_Coupon) }}</span>
          <span>{{ couponMessage.id }}</span>
        </div>
        <div class="active-modal-content-each emphasis-color">
          <span>{{ couponMessage.title }}</span>
        </div>
        <div class="active-modal-content-each emphasis-color">
          <span>{{ couponMessage.subtitle }}</span>
        </div>
        <div class="move-footer">
          <div @click="confirmUseCoupons()" class="button-class">
            {{ getString(strings.Global_Cancel) }}
          </div>
          <div
            @click="confirmUseCoupons(true)"
            class="button-class confirm-bottom"
          >
            {{ getString(strings.Mind_Edit_Use) }}
          </div>
        </div>
      </div>
    </a-modal>
    <a-modal :centered="true" v-model="activeDetailedInformation" footer="">
      <div class="active-modal-content">
        <div class="active-modal-content-title">
          {{
            getString(strings.Mind_Edit_Left_Menu_Confirm_Exchange_Information)
          }}
        </div>

        <div class="active-modal-content-each">
          <span>{{
            getString(strings.Mind_Edit_Left_Menu_Exchange_Code)
          }}</span>
          <span>{{ activeMessage.id }}</span>
        </div>
        <div class="active-modal-content-each emphasis-color">
          <span>{{ activeMessage.title }}</span>
        </div>
        <div class="active-modal-content-each emphasis-color">
          <span>{{ activeMessage.subtitle }}</span>
        </div>
        <div class="move-footer">
          <div @click="activeHidden(2)" class="button-class">
            {{ getString(strings.Global_Cancel) }}
          </div>
          <div @click="activeHidden(1)" class="button-class confirm-bottom">
            {{ getString(strings.Mind_Edit_Left_Menu_Confirm_Exchange) }}
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import {
  postPayPrice,
  postPayMethods,
  postPayVerifyCoupon,
  postPayPay,
  postPayVerifyFcode,
  postPayUseFcode,
  postPayQueryOrder,
  postUserMe,
  postPayAvailableCoupons,
} from "../../common/netWork/base_api";
import ShoppingPlayModel from "./ShoppingPlayModel";
import QRCode from "qrcodejs2";
import LeftLayout from "../../viewmodel/core/layout/LeftLayout";
import { mapMutations } from "vuex";
import {
  postMemberMbeansPrices,
  postMemberMyMbeans,
} from "../../common/netWork/member_api";
import { doLogin, doLogout } from "../../common/netWork/base";
import getString from "../../common/lang/language";
import strings from "../../common/lang/strings";
import MindMe from '../../viewmodel/facade/MindMe';
import Config from '../../core/core/calcule/Config';
export default {
  // el: '#box',
  data() {
    return {
      msg: 0,
      ShowPlayModel: false,
      Showbg: false,
      moneyData: {
        language: "zh",
        region: "cn",
      },
      priceList: [], //商品列表
      memberRightList: [], //会员权益
      selectedMethodCoupon: "alipay-web", //支付方式
      discountCouponCode: "", //存储优惠券码
      selectedObj: {}, //当前选中的对象
      couponMessage: {
        discount: 0,
      }, //当前优惠的信息
      couponsCanBeUsedMemberType: 0, //可以使用优惠券的套餐
      couponsCanBeUsed: false, //当前套餐是否可以使用优惠券
      clipCoupons: false, //使用优惠券
      warningCoupon: "", //优惠券警告
      couponDetailedInformation: false, //优惠券框
      consent: true, //是否同意开通会员的隐私条款
      consentCode: true, //是否同意开通激活码的隐私条款
      payModal: false, //显示微信二维码
      AlipayModal: false, //支付宝支付弹框
      activeCode: "", //激活码
      activeMessage: {}, //激活码的详细信息
      activeDetailedInformation: false, //激活码框隐藏展示
      meMessage: "", //用户个人信息
      warningActive: "", //激活码警告
      weixinPayText: "", // 当前的扫码状态
      weixinPayShow: 0, //微信支付当前展示二维码还是成功失败
      aliPayShow: 0, //支付宝支付结果状态
      aliPayText: "",
      strings: strings,
      myMBeans: "--", //M豆
      mbeansPrices: [], //M豆价格列表
      selectedmbeans: {}, //当前选中的M豆价格
      unitSymbol: "￥",
      timerInterval: 0,
      clipCouponsBtnText: getString(strings.Mind_Edit_Left_Menu_Verification),
    };
  },
  components: {
    ShoppingPlayModel,
  },
  props: ["showMsg"],
  created() {
    this.getLocalstorageUser();
    this.pullPrice(this.moneyData).then((data) => {
      this.priceList = data.prices;
      // this.memberRightList = data.prices[0].memberRights;
      this.selectedObj = data.prices.find((item) => item.selected);
      this.getPostPayAvailableCoupons();
    });

    this.pullPayClass().then((data) => {
      let paymentMethod = data.methods;
      if (paymentMethod) {
        let len = paymentMethod.length;
        for (let i = 0; i < len; i++) {
          if (paymentMethod[i].selected) {
            this.selectedMethodCoupon = paymentMethod[0].name; //默认支付方式
          }
        }
      }
    });
    this.getMemberMbeansPrices();
    //显示购买M豆
    if (this.showMsg) {
      this.msg = 2;
    }
  },
  mounted() {
    // //获取浏览器使用语言
    // var lang = navigator.systemLanguage
    //   ? navigator.systemLanguage
    //   : navigator.language;
    // console.log('lang: ' + lang);
    // ShoppingRequest.postPayPrice(
    //   moneyData,
    //   (res) => {
    //     console.log(res);
    //   },
    //   (error) => {
    //     console.log(res);
    //   }
    // );
  },
  destroyed() {
    clearInterval(this.timerInterval);
  },
  methods: {
    ...mapMutations(["isShowShoppingModel", "setUserIfLogin"]),
    getString(i) {
      return getString(i);
    },
    //得到焦点选中内容
    focusSelect(event) {
      event.currentTarget.select();
    },
    getLocalstorageUser() {
      //获取localstorage中的用户信息
      let meMessage = localStorage.getItem("meMessage");
      if (meMessage != "") {
        meMessage = JSON.parse(meMessage);
      }
      this.meMessage = meMessage;
    },

    pullPrice(obj) {
      //拉取价格列表
      let that = this;
      return new Promise(function (resolve, reject) {
        postPayPrice(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
          }
        );
      });
    },

    pullPayClass() {
      //支付方式

      let that = this;
      return new Promise(function (resolve, reject) {
        postPayMethods(
          {},
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
          }
        );
      });
    },

    coupon(obj) {
      //优惠券
      let that = this;
      return new Promise(function (resolve, reject) {
        postPayVerifyCoupon(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },

    payCombo(obj) {
      //微信或支付宝支付
      let that = this;
      return new Promise(function (resolve, reject) {
        postPayPay(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
          }
        );
      });
    },

    pullActive(obj) {
      //获取激活码信息
      let that = this;
      return new Promise(function (resolve, reject) {
        postPayVerifyFcode(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },

    useActive(obj) {
      let that = this;
      return new Promise(function (resolve, reject) {
        postPayUseFcode(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
          }
        );
      });
    },

    weixinPayPoll(obj) {
      let that = this;
      return new Promise(function (resolve, reject) {
        postPayQueryOrder(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
          }
        );
      });
    },

    employCoupon() {
      //使用优惠券
      if (this.discountCouponCode == "") {
        this.warningCoupon = "";
        return;
      }
      let obj = {};
      obj.couponId = this.discountCouponCode;
      this.coupon(obj)
        .then((data) => {
          this.couponMessage = data;
          this.couponsCanBeUsedMemberType = data.memberType;
          this.warningCoupon = "";
          this.couponDetailedInformation = true;
        })
        .catch((error) => {
          this.warningCoupon = error.desc;
          // if (this.couponMessageTem.id == this.discountCouponCode) {
          //     if (error.code == 78) {
          //       this.warningCoupon =
          //         getString(strings.Mind_Group_Used) + this.couponMessageTem.title;
          //     }
          // }
          this.$message.error(this.warningCoupon);
          this.couponMessage = {
            discount: 0,
          };
        });
    },

    changeCouponSearch() {
      //改变优惠券搜素清空之前的优惠券信息
      this.couponMessage = {
        discount: 0,
      };
      this.couponsCanBeUsed = false;
      this.employCoupon();
    },

    blurCouponInput() {
      //优惠券
      if (this.discountCouponCode == "") {
        this.warningCoupon = "";
        return;
      }
      let obj = {};
      obj.couponId = this.discountCouponCode;
      this.coupon(obj)
        .then((data) => {
          this.warningCoupon = "";
        })
        .catch((error) => {
          if (error != null && error != false) {
            this.warningCoupon = error.desc;
          } else {
            this.warningCoupon = "";
          }
        });
      this.discountCouponCode;
    },

    changeActiveSearch() {
      //激活码
      this.activeMessage = {};
      this.conversionCode();
    },

    blurActiveInput() {
      //
      let obj = {};
      obj.fcode = this.activeCode;
      this.pullActive(obj)
        .then((data) => {
          this.warningActive = "";
        })
        .catch((e) => {
          if (error != null && error != false) {
            this.warningActive = e.desc;
          } else {
            this.warningActive = "";
          }
        });
    },

    selectedCombo(item) {
      //选中某个优惠券
      this.selectedObj = item;
      if (
        this.clipCoupons &&
        this.couponsCanBeUsedMemberType === this.selectedObj.memberType
      ) {
        this.couponsCanBeUsed = true;
      } else {
        this.couponsCanBeUsed = false;
      }
    },

    consentClick() {
      //点击开通会员页面的阅读隐私条款。
      if (this.consent == true) {
        this.consent = false;
      } else {
        this.consent = true;
      }
    },

    isLoginUser() {
      //判断登录
      return MindMe.isLogin();
    },

    payMoney() {
      if (!this.isLoginUser()) {
        this.$message.warning(getString(strings.Message_Tips_Please_Sign_In));
        this.shoppJumpLogin();
        return;
      }
      //支付按钮
      if (this.consent != true) {
        this.$message.warning(getString(strings.Message_Tips_Agree_To_Terms));
        return;
      }
      let obj = {};
      this.selectedObj;
      obj.orderType = 0; //订单类型 0-普通用户购买订单 1-优惠券批量购买订单 2-激活码批量购买订单
      obj.methodName = this.selectedMethodCoupon; //支付方式名称，比如alipay、wechatpay
      obj.priceId = this.selectedObj.id; //选择的价格Id
      if (this.couponMessage.discount != 0) {
        obj.couponId = this.couponMessage.id; //优惠券id，选填
      } 
      let totalAmount = this.selectedObj.priceAmount
      if (this.couponMessage.discount) {
          totalAmount = this.selectedObj.priceAmount - this.couponMessage.discount
      }
      this.payCombo(obj).then((data) => {
        // console.log("data", data);
        if (obj.methodName == "wechatpay-web") {
          this.payModal = true;
          setTimeout(() => {
            //不设置延迟无法找到父元素
            this.bindQRCode(data.codeUrl);

            this.weixinPayShow = 0;
            this.weixinPayText = "";
            let timer = setInterval(() => {
              let req = {};
              req.orderId = data.orderId;
              this.weixinPayPoll(req)
                .then((res) => {
                  if (res.isFinished) {
                    //轮询是否完成
                    this.payModal = true;
                    if (res.isPaid) {
                      this.weixinPayShow = 1;
                      this.weixinPayText =
                        getString(strings.Group_Mind_PaymentSuccessful) + "!";
                      localStorage.removeItem("meMessage");
                      postUserMe(
                        {},
                        (res) => {
                          doLogin(res);
                          this.setUserIfLogin(true);
                        },
                        (e) => {}
                      );
                      if (totalAmount && totalAmount > 0) {
                        Config.purchaseBz((totalAmount - 2) / 2)
                      }
                    } else if (res.isPaid == false) {
                      this.weixinPayShow = 2;
                      this.weixinPayText = res.information;
                    }
                    clearInterval(timer);
                  }
                })
                .catch((e) => {
                  clearInterval(timer);
                });
            }, data.queryInterval * 1000);
            this.timerInterval = timer;
          }, 100);
        } else {
          this.AlipayModal = true;
          this.toAlipayPayment(data.payUrl);

          setTimeout(() => {
            this.aliPayShow = 0;
            this.aliPayText = "";
            let timer = setInterval(() => {
              let req = {};
              req.orderId = data.orderId;
              this.weixinPayPoll(req)
                .then((res) => {
                  if (res.isFinished) {
                    //轮询是否完成
                    this.AlipayModal = true;
                    if (res.isPaid) {
                      this.aliPayShow = 1;
                      this.aliPayText =
                        getString(strings.Group_Mind_PaymentSuccessful) + "!";
                      localStorage.removeItem("meMessage");
                      postUserMe(
                        {},
                        (res) => {
                          doLogin(res);
                          this.setUserIfLogin(true);
                        },
                        (e) => {}
                      );
                      if (totalAmount && totalAmount > 0) {
                        Config.purchaseBz((totalAmount - 2) / 2)
                      }
                    } else {
                      this.aliPayShow = 2;
                      this.aliPayText = res.information;
                    }
                    clearInterval(timer);
                  } else {
                  }
                })
                .catch((e) => {
                  clearInterval(timer);
                });
            }, data.queryInterval * 1000);
            this.timerInterval = timer;
          }, 100);
        }
      });
    },
    toAlipayPayment(url) {
      const a = document.createElement("a"); //创建a标签浏览器不会阻止弹出窗口
      a.setAttribute("href", url);
      a.setAttribute("target", "_blank");
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
      }, 5000);
    },
    bindQRCode(url) {
      //清空之前的二维码
      this.$refs.qrCodeDiv.innerHTML = "";
      return new QRCode(this.$refs.qrCodeDiv, {
        text: url,
        width: 200,
        height: 200,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },

    hidepayModal() {
      this.payModal = this.AlipayModal = false;
      if (this.weixinPayShow != 0 || this.aliPayShow != 0) {
        this.HideShoppingModel();
      }
    },

    consentClickCode() {
      //点击激活码兑换的阅读隐私条款。
      if (this.consentCode == true) {
        this.consentCode = false;
      } else {
        this.consentCode = true;
      }
    },

    conversionCode() {
      if (!this.isLoginUser()) {
        this.$message.warning(getString(strings.Message_Tips_Please_Sign_In));
        this.shoppJumpLogin();
        return;
      }

      if (this.consentCode != true) {
        this.$message.warning(getString(strings.Message_Tips_Agree_To_Terms));
        return;
      }

      if (this.activeCode == "") {
        this.$message.warning(getString(strings.Message_Tips_Please_Code));
        return;
      }

      let obj = {};
      obj.fcode = this.activeCode;
      this.pullActive(obj)
        .then((data) => {
          // console.log('data',data)
          this.activeMessage = data;
          //显示激活码详细信息
          this.activeDetailedInformation = true;
          this.warningActive = "";
        })
        .catch((error) => {
          this.warningActive = error.desc;
          // console.log('error',error)
        });
    },

    activeHidden(val) {
      if (val == 2) {
        this.activeDetailedInformation = false;
      } else if (val == 1) {
        let obj = {};
        obj.fcode = this.activeMessage.id;

        this.useActive(obj)
          .then((data) => {
            this.activeDetailedInformation = false;
            this.$message.success(
              getString(strings.Message_Tips_Exchange_Success)
            );
          })
          .catch((error) => {});
      }
    },
    //确认使用优惠券
    confirmUseCoupons(val = false) {
      if (val) {
        this.couponsCanBeUsed = this.clipCoupons = true;
        this.selectedObj = this.priceList.find(
          (item) => item.memberType === this.couponsCanBeUsedMemberType
        );
        this.warningCoupon =
          getString(strings.Mind_Group_Used) + this.couponMessage.title;
      }
      this.couponDetailedInformation = false;
    },

    HideShoppingModel() {
      this.$emit("HideShoppingModel", false);
      this.isShowShoppingModel(false);
    },

    shoppJumpLogin() {
      this.HideShoppingModel();
      this.$emit("shoppJumpLogin", true);
    },

    PlayGo() {
      this.ShowPlayModel = !this.ShowPlayModel;
      this.Showbg = !this.Showbg;
    },

    HideShoppingPlayModel(Account) {
      this.ShowPlayModel = Account;
      this.Showbg = !this.Showbg;
    },
    // 获取M豆和价格列表
    getMemberMbeansPrices() {
      postMemberMbeansPrices({}, (res) => {
        this.mbeansPrices = res.prices;
        this.selectedmbeans = res.prices[0];
      });
      postMemberMyMbeans({}, (res) => {
        this.myMBeans = res.myMBeans;
      });
    },
    selectedMbeansPrices(item) {
      //选中某个M豆价格
      this.selectedmbeans = item;
    },
    payMoneyMbean() {
      if (!this.isLoginUser()) {
        this.$message.warning(getString(strings.Message_Tips_Please_Sign_In));
        this.shoppJumpLogin();
        return;
      }
      //支付按钮
      if (this.consent != true) {
        this.$message.warning(getString(strings.Message_Tips_Agree_To_Terms));
        return;
      }
      let obj = {};
      this.selectedObj;
      obj.orderType = 3; //订单类型 0-普通用户购买订单 1-优惠券批量购买订单 2-激活码批量购买订单 3-M豆购买
      obj.methodName = this.selectedMethodCoupon; //支付方式名称，比如alipay、wechatpay
      obj.priceId = this.selectedmbeans.id; //选择的价格Id

      // console.log(obj);
      this.payCombo(obj).then((data) => {
        if (obj.methodName == "wechatpay-web") {
          this.payModal = true;
          setTimeout(() => {
            //不设置延迟无法找到父元素
            this.bindQRCode(data.codeUrl);

            this.weixinPayShow = 0;
            this.weixinPayText = "";
            let timer = setInterval(() => {
              let req = {};
              req.orderId = data.orderId;
              this.weixinPayPoll(req)
                .then((res) => {
                  if (res.isFinished) {
                    //轮询是否完成
                    this.payModal = true;
                    if (res.isPaid) {
                      this.weixinPayShow = 1;
                      this.weixinPayText =
                        getString(strings.Group_Mind_PaymentSuccessful) + "!";
                      this.getMemberMbeansPrices();
                    } else if (res.isPaid == false) {
                      this.weixinPayShow = 2;
                      this.weixinPayText = res.information;
                    }
                    clearInterval(timer);
                  }
                })
                .catch((e) => {
                  clearInterval(timer);
                });
            }, data.queryInterval * 1000);
            this.timerInterval = timer;
          }, 100);
        } else {
          this.AlipayModal = true;
          // console.log(this.AlipayModal);
          this.toAlipayPayment(data.payUrl);
          setTimeout(() => {
            this.aliPayShow = 0;
            this.aliPayText = "";
            let timer = setInterval(() => {
              let req = {};
              req.orderId = data.orderId;
              this.weixinPayPoll(req)
                .then((res) => {
                  if (res.isFinished) {
                    //轮询是否完成
                    this.AlipayModal = true;
                    if (res.isPaid) {
                      this.aliPayShow = 1;
                      this.aliPayText =
                        getString(strings.Group_Mind_PaymentSuccessful) + "!";
                      this.getMemberMbeansPrices();
                    } else {
                      this.aliPayShow = 2;
                      this.aliPayText = res.information;
                    }
                    clearInterval(timer);
                  }
                })
                .catch((e) => {
                  clearInterval(timer);
                });
            }, data.queryInterval * 1000);
            this.timerInterval = timer;
          }, 100);
        }
      });
    },
    //获取优惠券
    getPostPayAvailableCoupons() {
      postPayAvailableCoupons(
        { priceId: "" },
        (res) => {
          let coupons = res.coupons;
          if (coupons && coupons.length > 0) {
            const couponsObj = coupons[0];
            let couponCode = couponsObj.id;
            this.discountCouponCode = couponCode;
            this.couponMessage = this.couponMessageTem = couponsObj;
            this.couponsCanBeUsedMemberType = couponsObj.memberType;
            this.confirmUseCoupons(true);
          }
        },
        (e) => {}
      );
    },
  },
};
</script>
<style lang="less" scoped>
.mindicon {
  font-family: "mindicon" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #666666;
}

* {
  margin: 0;
  padding: 0;
}

i {
  font-style: normal;
}

ul li {
  list-style: none;
}

.bg-color {
  background-color: black;
  opacity: 0.5;
  position: fixed;
  z-index: 9;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.HideShoppingModel {
  position: absolute;
  right: 1em;
  top: 1em;
  width: 24px;
  height: 24px;
}

.icon-remove {
  font-size: 22px;
  transition: 0.3s;
}

.icon-remove:hover {
  color: #fd492b;
  transition: 0.3s;
  font-size: 22px;
  transform: rotate(180deg);
}

/* .Vip-user {
  border-bottom: 1px solid rgb(218, 218, 218);
} */
.Vip-user p {
  /* margin: 6px 10px; */
  height: 30px;
  font-size: 16px;
  line-height: 30px;
}

.shopping-model {
  display: flex;
  margin: 0 auto;
  width: 905px;
  background-color: #ffffff;
  min-height: 620px;
  border-radius: 10px;
  position: relative;
}

.shopping-content {
  padding: 30px 50px;
}

.shopping-box {
  width: 100%;
}

.shopping-box .row {
  width: 94%;
  margin: 0.6em 2em;
}

.tab-tit {
  font-size: 0;
  display: flex;
  justify-content: center;
}

.tab-tit span {
  display: inline-block;
  height: 55px;
  line-height: 55px;
  /* width: 15%; */
  font-size: 15px;
  font-weight: 600;
  margin-right: 20px;
  text-align: center;
  color: #333;
  text-decoration: none;
}

.tab-tit span a {
  color: #666666;
}

.tab-tit .active a {
  color: #fd492b;
}

.tab-tit .active {
  border-bottom: 2px #fd492b solid;
}

.shopping-box .row .tab-con .membership-list {
  margin-top: 20px;
}

.shopping-box .row .tab-con .membership-container {
  display: flex;
}

.shopping-box .tab-con .membership-list .open-account {
  width: 25%;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;

  &:hover {
    margin-top: -15px;
    box-shadow: 0px 3px 6px rgba(249, 50, 16, 0.3);

    .op-two {
      height: 137px;
      border-radius: 2px 2px 0 0;
    }
  }
}

.shopping-box .tab-con .membership-list .selected-class {
  margin-top: -15px;

  .op-two {
    height: 137px;
    border-radius: 2px 2px 0 0;
  }
}

.shopping-box .tab-con .open-account .op-img {
  width: 64px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 14px;
  background: #ff0000;
  position: absolute;
  top: 0;
  left: 0;
}

.shopping-box .tab-con .open-account .op-img::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border-right: 32px solid #ff0000;
  border-left: 32px solid #ff0000;
  border-bottom: 10px solid transparent;
}

.shopping-box .tab-con .open-account .op-two {
  width: 100%;
  height: 122px;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .select-member-package {
    position: absolute;
    right: 16px;
    top: 16px;
  }
}

.shopping-box .tab-con .open-account:nth-child(1) {
  .op-two {
    background: #ff826d;
  }

  .membership-rights-item:nth-child(odd) {
    background: #ff7058;
  }

  .membership-rights-item:nth-child(even) {
    background: #ff7e68;
  }
}

.shopping-box .tab-con .open-account:nth-child(2) {
  .op-two {
    background: #df4a33;
  }

  .membership-rights-item:nth-child(odd) {
    background: #e54228;
  }

  .membership-rights-item:nth-child(even) {
    background: #eb5238;
  }
}

.shopping-box .tab-con .open-account:nth-child(3) {
  .op-two {
    background: #e32f11;
  }

  .membership-rights-item:nth-child(odd) {
    background: #c92b10;
  }

  .membership-rights-item:nth-child(even) {
    background: #d83317;
  }
}

.shopping-box .tab-con .open-account:nth-child(4) {
  .op-two {
    background: #cc1e00;
  }

  .membership-rights-item:nth-child(odd) {
    background: #af1a00;
  }

  .membership-rights-item:nth-child(even) {
    background: #c81d00;
  }
}

.membership-rights {
  width: 100%;

  .membership-rights-item {
    width: 100%;
    height: 30px;
    background: #ff7058;
    opacity: 1;
    font-size: 13px;
    font-family: Noto Sans SC;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
  }
}

.nk {
  font-size: 18px;
  /*font-weight: bold;*/
}

.shopping-box .tab-con .open-account .op-two .shoppe {
  font-size: 18px;
  padding-left: 20px;
}

.shopping-box .tab-con .open-account .op-three {
  width: 40%;
  text-align: right;
  margin-top: 1em;
  margin-right: 24px;
}

.discounts {
  font-size: 24px;
}

.pice {
  color: #fff;
  font-size: 16px;
  text-decoration: line-through;
}
.original-pice {
  color: #999999 !important;
  font-size: 22px !important;
  text-decoration: line-through;
  padding-left: 20px;
}

.tab-con .membership-list .open-alust {
  display: flex;
  height: 48px;
  margin-top: 30px;
  /* border: 1px #333 solid; */
}

.tab-con .membership-list .open-alust .op-two {
  width: 90%;
  margin-right: 20px;
}

.tab-con .membership-list .open-alust .op-two.op-active {
  width: 100%;
  margin-right: 0;
}

.tab-con .membership-list .open-alust .op-two .nk {
  position: absolute;
  padding-left: 2.2em;
  line-height: 48px;
}

.tab-con .membership-list .open-alust .op-two .shoppe input {
  width: 100%;
  height: 48px;
  padding-left: 11em;
  border: 1px #cccccc solid;
  border-radius: 10px;
  outline: none;
  font-size: 14px;
}

.tab-con .activation_code .membership-list .open-alust .op-two .shoppe input {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.11);
}

.tab-con .membership-list .open-alust .op-two .shoppe input:focus {
  border: 1px solid #fd492b;
}

.tab-con .membership-list .open-alust .op-three button {
  width: 125px;
  height: 48px;
  border-radius: 24px;
  background-color: #e8e8e8;
  border: none;
  cursor: pointer;
}

.tab-con .membership-list .open-alust .op-three button:hover {
  background: #d5d5d5;
}

.tab-con .membership-list .open-alust .op-three-2 button {
  width: 125px;
  height: 48px;
  border-radius: 24px;
  background-color: #fd492b;
  border: none;
  color: #fff;
  cursor: pointer;
}

.warningCoupon {
  width: 100%;
  padding-left: 52px;
  color: #fd492b;
}

button:focus {
  outline: none;
}

.open-membership {
  .membership-open-di {
    padding: 20px 0;

    .pay-class {
      width: 100%;

      .payment-method {
        font-size: 14px;
        font-family: Noto Sans SC;
        font-weight: 400;
        line-height: 20px;
        color: #666666;
        opacity: 1;
      }

      .checkinput {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;

        input[type="radio"] {
          display: none;
        }

        label {
          width: 120px;
          height: 40px;
          border: 1px solid #cccccc;
          opacity: 1;
          border-radius: 4px;
          margin-right: 40px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          .selectedBg {
            position: absolute;
            right: -1px;
            bottom: -1px;
          }

          .wx-icon,
          .ali-icon {
            font-size: 16px;
            width: 16px;
            height: 16px;
            margin-right: 10px;
          }

          .wx-icon {
            color: #09bb07;
            margin-top: -5px;
          }

          .ali-icon {
            color: #02a9f1;
          }
        }

        .payment-method-selected {
          border: 1px solid #ec705a;
        }
      }
    }

    .activity-mbean-price {
      width: 100%;
      margin-top: 40px;

      .op-two {
        .shoppe {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
        }

        .discounts {
          color: #fd402b;
          font-size: 30px;
          font-weight: 400;
        }

        .si-btn {
          font-size: 18px;
          color: #fff;
          position: absolute;
          left: 50%;
          transform: translate(-50%);

          button {
            width: 190px;
            height: 44px;
            background: linear-gradient(124deg, #ff4841 0%, #ff9d9c 100%);
            opacity: 1;
            border-radius: 36px;
            border: 0;
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }

    .wu {
      margin-top: 15px;
    }
  }
}

.open-di {
  .wu {
    margin-top: 20px;
  }

  .si-btn {
    font-size: 18px;
    color: #fff;

    button {
      width: 190px;
      height: 44px;
      background: #fd492b;
      opacity: 1;
      border-radius: 36px;
      border: 0;
      cursor: pointer;
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translate(-50%);
      &:hover {
        background: #ff7354;
      }
    }
  }
}

.small-model-box {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.small-model-content {
  box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.18);
  margin: 0px 20px;
  padding: 20px;
  width: 25%;
}

.small-model-header {
  text-align: center;
}

.small-model-header h1 {
  font-size: 26px;
}

.small-model-header h2 {
  font-size: 24px;
  color: #fb3848;
}

.small-model-header span {
  font-size: 18px;
  color: red;
}

.small-model-header .small-model-btn {
  width: 100%;
  height: 42px;
  background-color: #fb3848;
  border: 1px solid #fb3848;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  color: #ffffff;
  padding: 0 20px;
}

.small-model-header .small-model-btn:hover {
  width: 100%;
  height: 42px;
  background-color: #ad0917;
  border: 1px solid #ad0917;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  color: #ffffff;
}

.small-model-botton {
  margin-top: 50px;
}

.small-model-botton ul li {
  list-style: none;
  line-height: 30px;
}

/* 微信支付二维码 */
.payCode {
  display: block;
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.pay-code-img {
  display: block;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.pay-show-text {
  margin: 0 auto;
  margin-top: 15px;
  font-size: 16px;
  text-align: center;
  color: #999;
}
.alipay {
  text-align: center;
  font-size: 16px;
}
.alipay-button {
  width: 80px;
  height: 36px;
  opacity: 1;
  border-radius: 21px;
}

/* 激活码信息展示开始 */
.active-modal-content .active-modal-content-title {
  /*font-weight: bold;*/
  text-align: center;
  font-size: 20px;
  color: #333;
  margin-bottom: 38px;
}

.active-modal-content .active-modal-content-each {
  text-align: center;
  margin-top: 10px;
  /* font-size: 20px; */
}

.emphasis-color {
  color: #fd492b;
}

.active-modal-content .active-modal-content-each-last {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  /* font-size: 20px; */
}

.bottom-each {
  margin-bottom: 20px;
}

.move-footer {
  display: flex;
  flex-direction: row-reverse;
  /* justify-content: center; */
  /* align-items: right; */
}

.move-footer .button-class {
  margin: 0 9px;
  margin-top: 10px;
  width: 60px;
  height: 30px;
  border-radius: 15px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  background-color: #f0f2f8;
  cursor: pointer;
}

.move-footer .confirm-bottom {
  background-color: #fd492b;
  color: #fff;
}

.move-footer .confirm-bottom:active {
  background-color: #f7755e;
}

/* 激活码信息展示结束 */

@media (max-width: 1600px) and (min-width: 1400px) {
  .shopping-model {
    margin: 0 auto;
    width: 70%;
    background-color: #ffffff;
    min-height: 598px;
    border-radius: 10px;
    position: relative;
    display: flex;
  }
}

@media (max-width: 1400px) and (min-width: 1200px) {
  .shopping-model {
    margin: 0 auto;
    width: 80%;
    background-color: #ffffff;
    min-height: 598px;
    border-radius: 10px;
    position: relative;
  }
}

@media (max-width: 1200px) and (min-width: 0px) {
  .shopping-model {
    margin: 0 auto;
    width: 80%;
    background-color: #ffffff;
    min-height: 598px;
    border-radius: 10px;
    position: relative;
  }
}

.wx-play-model {
  z-index: 19999;
  position: absolute;
}

.m-bean {
  width: 100%;

  .m-bean-box {
    width: 100%;
    height: 150px;
    // height: 221px;
    background: linear-gradient(134deg, #f84d4d 0%, #ff9185 100%);
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
    padding-top: 0.6em;

    .tab-tit {
      font-size: 0;
      display: flex;
      justify-content: center;

      span {
        display: inline-block;
        height: 55px;
        line-height: 55px;
        /* width: 15%; */
        font-size: 18px;
        margin-right: 0;
        text-align: center;
        color: #fff;
        text-decoration: none;

        &:nth-child(2) {
          margin: 0 20px;
        }

        a {
          color: #fff;
        }
      }

      .active {
        border-bottom: 2px #fff solid;
      }
    }

    .HideShoppingModel {
      position: absolute;
      right: 1em;
      top: 1em;
      color: #fff;
      width: 24px;
      height: 24px;

      .icon-remove {
        font-size: 22px;
        transition: 0.3s;
      }

      .icon-remove:hover {
        color: #fff;
        transition: 0.3s;
        font-size: 22px;
        transform: rotate(180deg);
      }
    }
  }

  .me-m-bean {
    width: 328px;
    height: 70px;
    background: url(../../assets/img/user/member/my_m_bean_bg.svg);
    margin: 0 auto;
    position: relative;
    top: -70px;

    .me-m-bean-content {
      width: 92px;
      margin: 0 auto;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .me-m-bean-title {
        padding-top: 12px;
        font-size: 12px;
        font-weight: 400;
        color: #ffd4cc;
      }

      .me-m-bean-container {
        width: 85px;
        height: 85px;
        border-radius: 50%;
        background-color: #ff4d4a;
        // background: url(../../assets/img/user/member/my_m_bean_container.png);
        // background-size: 100% 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;

        .my-bean-num {
          width: auto;
          height: 33px;
          font-size: 24px;
          font-family: Noto Sans SC;
          font-weight: bold;
          line-height: 33px;
          color: #ffffff;
          opacity: 1;
        }

        img {
          width: 22px;
          height: 22px;
          margin-bottom: 8px;
        }
      }
    }
  }

  .m-bean-ship {
    width: 100%;
    // height: 200px;
    padding: 0 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .m-bean-ship-box {
      width: 86px;
      height: 98px;
      border: 1px solid #cccccc;
      opacity: 1;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      cursor: pointer;
      position: relative;
      // margin-top: 10px;
      &:nth-child(n + 2) {
        margin-left: 30px;
      }

      &:nth-child(n + 8) {
        margin-top: 20px;
      }

      .m-bean-ship-num {
        font-size: 24px;
        font-family: Noto Sans SC;
        font-weight: bold;
        line-height: 30px;
        color: #333333;
      }

      .m-bean-ship-line {
        width: 76px;
        height: 1px;
        background-color: #cccccc;
      }

      .m-bean-ship-price {
        font-size: 12px;
        font-family: Noto Sans SC;
        font-weight: 500;
        color: #666666;
      }
    }

    .selected-class {
      border: 1px solid #ec705a;
    }

    .selectedBg {
      position: absolute;
      right: -1px;
      top: -1px;
    }
  }

  .mbean-open-di {
    margin-top: 40px;
    padding: 30px;

    .pay-class {
      width: 100%;

      .payment-method {
        font-size: 14px;
        font-family: Noto Sans SC;
        font-weight: 400;
        line-height: 20px;
        color: #666666;
        opacity: 1;
      }

      .checkinput {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;

        input[type="radio"] {
          display: none;
        }

        label {
          width: 120px;
          height: 40px;
          border: 1px solid #cccccc;
          opacity: 1;
          border-radius: 4px;
          margin-right: 40px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          .selectedBg {
            position: absolute;
            right: -1px;
            bottom: -1px;
          }

          .wx-icon,
          .ali-icon {
            font-size: 16px;
            width: 16px;
            height: 16px;
            margin-right: 10px;
          }

          .wx-icon {
            color: #09bb07;
            margin-top: -5px;
          }

          .ali-icon {
            color: #02a9f1;
          }
        }

        .payment-method-selected {
          border: 1px solid #ec705a;
        }
      }
    }

    .activity-mbean-price {
      width: 100%;
      margin-top: 40px;

      .op-two {
        .shoppe {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
        }

        .discounts {
          color: #fd402b;
          font-size: 30px;
          font-weight: 400;
        }

        .si-btn {
          font-size: 18px;
          color: #fff;
          position: absolute;
          left: 50%;
          transform: translate(-50%);

          button {
            width: 190px;
            height: 44px;
            background: linear-gradient(124deg, #ff4841 0%, #ff9d9c 100%);
            opacity: 1;
            border-radius: 36px;
            border: 0;
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }

    .wu {
      margin-top: 15px;
    }
  }
}
</style>
