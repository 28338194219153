// package mind.yushu.com.mindmap.viewmodel.datatype;

// import com.google.gson.annotations.SerializedName;

/**
 * Created by tony on 2020/5/27
 */

let QuickStyleType = {

    EXTREMELY_IMPORTANT:1,//极其重要
    IMPORTANT:2,//重要
    COMMONLY:3,//一般
    DELETE:4,//删除
    ORDINARY:5,//正常
    YELLOW_BG_FULL_DrawOblique:6,//红纹理
    BLUE_BG_FULL_Gradient:7,//蓝色色渐变
    SHADOW_GREEN:8,//阴影红

    from:function(value) {
        for(let mealName of Object.values(this)){
            if(mealName == value){
                return value;
            }
        }
        return this.EXTREMELY_IMPORTANT;
    }
}

export default QuickStyleType
