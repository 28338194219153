
/**
 * Created by tony on 2021/03/27
 */
import Config from "../../../core/core/calcule/Config"
import Colors from "../../../utils/Colors"
import UiUtil from "../../../utils/UiUtil"
import IdGenerator from "../base/IdGenerator"
import LineColorMode from "../base/LineColorMode"
import LineOrientation from "../../datatype/LineOrientation"
import LineLayout from "../../datatype/LineLayout"
import MindElementShapeType from "../../datatype/MindElementShapeType"
import MindElementType from "../../datatype/MindElementType"
import NodeLayoutType from "../../datatype/NodeLayoutType"
import LineElementContent from "../../mindelementdata/mindcontent/LineElementContent"
import Point from "../../../viewmodel/core/base/Point"
import RightLayout from "./RightLayout"

class BracketsRightLayout extends RightLayout {
    constructor() {
        super();
    }

    initConfig() {
        super.initConfig();
        this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee);
        // if (this.rootTreeNode.children.length == 2) {
        //     this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee * 3);
        // } else if (this.rootTreeNode.children.length == 3) {
        //     this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee * 1.5);
        // }
        this.SonNodeHorizontalSpacee = this.addSonSubjectSpcaeHorizontal(Config.BracketsSonNodeHorizontalSpacee + Config.BracketsRightSpacee);
        this.NodeHorizontalSpacee = this.addSubjectSpcaeHorizontal(Config.BracketsNodeHorizontalSpacee + Config.BracketsRightSpacee);
    }

    setNodeElementsPoint(node, isChange) {
        this.setDataPoint(node.value, isChange);
        if (node.children.length == 0 || this.isLayout(node.value)) {
            return;
        }
        if (this.isRootNode(node) || node.value.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
            for (let index = 0; index < node.children.length; index++) {
                this.setNodeElementsPoint(node.children[index], isChange);
            }
        } else {
            this.resetNodeLayout(node);
        }
    }

    setElementsPoint(isChange) {
        if ((!isChange || this.rootTreeNode.value.y == -1 || this.rootTreeNode.value.x == -1) && this.rootTreeNode.value.type == MindElementType.MAIN_SUBJECT) {
            let left = (Config.Mind_Width - (new UiUtil).getScreenWidth()) / 2;
            let top = (Config.Mind_Height - (new UiUtil).getScreenHeight()) / 2;
            this.rootTreeNode.value.y = ((new UiUtil).getScreenHeight() - this.rootTreeNode.value.height) / 2 + top;
            this.rootTreeNode.value.x = 20 + left;
        }
        this.initConfig();
        this.setNodeElementsPoint(this.rootTreeNode, isChange);
        this.setNodeElementsLinePoint(this.rootTreeNode, isChange);
        // this.setEncirclePoint();
    }

    setElementLine(node, isChange) {
        let data = node.value;
        if (data.isHidden || this.isRootNode(node) || this.isLayout(node.value)) {
            return;
        }
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        if (!this.isGeneralizatioData(data) &&
            (parentNodeData.isEmpty() || parentNodeData.layout != NodeLayoutType.LAYOUT_BRACKETS_RIGHT)) {
            return;
        }

        let isCreateLineData = false;
        let lineData = this.getLineData(node);
        lineData.parentNodeId = parentNodeData.id;
        let lineLayout = this.getNodeLineLayout(node, lineData);
        if (lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            this.lineMindElementDataDict.put(lineData.id, lineData);
            this.textElementLineMindElementDataDict.put(node.value.id, lineData);
            isCreateLineData = true;
        }
        lineData.isHidden = false;
        lineData.layout = NodeLayoutType.LAYOUT_BRACKETS_RIGHT;
        if (data.type == MindElementType.SUBJECT || data.type == MindElementType.CONTENT_GENERALIZATION) {
            lineData.type = MindElementType.LINE;
            if (this.isUnderline(data)) {
                lineData.y = data.y + data.height;
            } else {
                lineData.y = data.y + data.height / 2;
            }
            lineData.x = parentNodeData.x + parentNodeData.width + Config.BracketsRightSpacee;

            lineData.width = data.x - lineData.x - Config.BracketsRightSpacee;
            lineData.height = parentNodeData.y - lineData.y + parentNodeData.height / 2;
            let startPoint = new Point(0, lineData.height);
            let endPoint = new Point(lineData.width, 0);
            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent(startPoint, endPoint, 0x333333, data.id);
            } else {
                lineData.lineContent.setStartPoint(startPoint);
                lineData.lineContent.setEndPoint(endPoint);
            }
            lineData.lineContent.orientation = LineOrientation.RIGHT;
        } else {

            lineData.type = MindElementType.SON_LINE;

            if (this.isUnderline(parentNodeData)) {
                lineData.y = parentNodeData.y + parentNodeData.height;
            } else {
                lineData.y = parentNodeData.y + parentNodeData.height / 2;
            }
            lineData.x = parentNodeData.x + parentNodeData.width + Config.BracketsRightSpacee;

            let curveStartPoint = new Point(0, 0);
            let curveEndPoint = new Point(0, 0);
            let straightEndPoint = new Point(0, 0);

            if (this.isUnderline(data)) {
                lineData.height = data.y - lineData.y + data.height;
                lineData.width = data.x - lineData.x - Config.BracketsRightSpacee;
            } else {
                lineData.height = data.y - lineData.y + data.height / 2;
                lineData.width = data.x - lineData.x - Config.BracketsRightSpacee;
            }

            if (lineLayout  == LineLayout.STRAIGHT_LINE ||
                lineLayout  == LineLayout.STRAIGHT_LINE_2) {
                curveStartPoint = new Point(0, 0);
                curveEndPoint = new Point(lineData.width - lineData.width / 4, lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            } else if (lineLayout  == LineLayout.CURVE_LINE ||
                lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                lineLayout == LineLayout.STRAIGHT_CURVE_LINE) {
                curveStartPoint = new Point(0, 0);
                curveEndPoint = new Point(lineData.width - lineData.width / 6, lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            } else if (lineLayout == LineLayout.CURVE_LINE_2 ||
                lineLayout == LineLayout.CURVE_LINE_AVERAGE) {
                curveStartPoint = new Point(0, 0);
                curveEndPoint = new Point(lineData.width, lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            } else {
                curveStartPoint = new Point(0, 0);
                curveEndPoint = new Point(lineData.width / 2, lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            }

            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent();
                lineData.lineContent.setLineElementContent(curveStartPoint, curveEndPoint, 0x333333, data.id, straightEndPoint);
            } else {
                lineData.lineContent.setCurveStartPoint(curveStartPoint);
                lineData.lineContent.setCurveEndPoint(curveEndPoint);
                lineData.lineContent.setStraightEndPoint(straightEndPoint);
            }

            lineData.lineContent.orientation = LineOrientation.RIGHT;

        }
        
        lineData.lineContent.lineLayout = lineLayout;
        if (isCreateLineData) {
            let lineColorMode = new LineColorMode();
            let lineNodeContent = lineData.lineContent;
            let referenceLine = this.getReferenceLine(node.value.id);
            if (!referenceLine.isEmpty()) {
                if (this.isUnderline(data) && this.isBorderColor(data)) {
                    lineNodeContent.color = data.borderColor;
                } else {
                    lineNodeContent.color = referenceLine.lineContent.color;
                }
                if (referenceLine.lineContent.lineWidth == 0) {
                    lineNodeContent.lineWidth = this.isUnderline(data) && data.borderWidth > 0 && !Colors.isClear(data.borderColor) ? data.borderWidth : lineColorMode.lineWidth;
                } else {
                    lineNodeContent.lineWidth = referenceLine.lineContent.lineWidth;
                }
                lineNodeContent.dottedLine = referenceLine.lineContent.dottedLine;
                lineData.isShadow = referenceLine.isShadow;
            } else {
                lineNodeContent.color = node.value.styleColor;
                if (this.isUnderline(data)) {
                    lineNodeContent.lineWidth = data.borderWidth;
                } else {
                    lineNodeContent.lineWidth = this.mindMapStyleColor.getSubjectLineWidth();
                }
            }
            if (lineData.type == MindElementType.LINE && !this.settingData.lineColor.isEmpty()) {
                let colorPoint = (parentNode.children.length - 1) % this.settingData.lineColor.length;
                lineNodeContent.color = this.settingData.lineColor[Math.abs(colorPoint)]

                // let parentNodeChildrenSize = parentNode.children.length;
                // let lineColorLength = this.settingData.lineColor.length;
                // for (let index = 0; index < parentNodeChildrenSize; index++) {
                //     if (parentNode.children[index].value.id == node.value.id) {
                //         let colorPoint = index % lineColorLength;
                //         lineNodeContent.color = this.settingData.lineColor[colorPoint];
                //         break;
                //     }
                // }
            }
            lineData.backgroundColor = Colors.transparent;
            lineData.borderColor = Colors.transparent;
            lineData.borderWidth = 0;
        }
        this.setLineThicken(lineData);

        if (data.isHidden || data.isEmpty()) {
            return;
        }

        if (parentNode.isEmpty()) {
            return;
        }

        let childrenSize = parentNode.children.length;
        if (childrenSize > 2) {
            for (let index = 0; index < childrenSize; index++) {
                if (parentNode.children[index].value.id == node.value.id) {
                    if (index == 0 || index == childrenSize - 1) {
                        if (lineData.lineContent.lineWidth == 0) {
                            let referenceLine = this.getReferenceLine(node.value.id);
                            if (referenceLine.isEmpty()) {
                                lineData.lineContent.lineWidth = 1.5;
                            } else {
                                lineData.lineContent.lineWidth = referenceLine.lineContent.lineWidth == 0 ? 1 : referenceLine.lineContent.lineWidth;
                            }
                        }
                    } else {
                        lineData.lineContent.lineWidth = 0;
                    }
                }
            }
        } else if (lineData.lineContent.lineWidth == 0) {
            let referenceLine = this.getReferenceLine(node.value.id);
            if (referenceLine.isEmpty()) {
                lineData.lineContent.lineWidth = 1.5;
            } else {
                lineData.lineContent.lineWidth = referenceLine.lineContent.lineWidth == 0 ? 1 : referenceLine.lineContent.lineWidth;
            }
        }
    }


    getTreeHeight(node) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (this.dataHeightMap.containsKey(node.value.id)) {
            return this.dataHeightMap.get(node.value.id);
        }
        let hieght = 0;
        if (this.isRootNode(node) || node.value.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
            let topHeight = this.getTreeTopHeight(node);
            let bottomHeight = this.getTreeBottomHeight(node);
            hieght = topHeight + bottomHeight;
        } else {
            let layout = this.getNodeLayout(node);
            hieght = layout.getTreeHeight(node);
        }
        this.dataHeightMap.put(node.value.id, hieght);
        return hieght;
    }

    getTreeWidth(node) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (!this.isRootNode(node) && node.value.layout != NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
            let layout = this.getNodeLayout(node);
            return layout.getTreeWidth(node);
        }

        let width = node.value.width;
        let nodeGeneralizationWidth = this.getNodeGeneralizationWidth(node);
        nodeGeneralizationWidth = nodeGeneralizationWidth > 0 ? nodeGeneralizationWidth + Config.GeneralizationLineWidth + Config.GeneralizationSpacing * 2 : 0;
        width = width + nodeGeneralizationWidth;
        let siblingsMaxWidth = 0;

        let nodeChildrenLength = node.children.length;
        for (let index = 0; index < nodeChildrenLength; index++) {
            let chlid = node.children[index];
            let chlidWidth = 0;
            let horizontalSpacee = chlid.value.type == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee;
            if (!chlid.children.isEmpty()) {
                chlidWidth += this.getTreeWidth(chlid) + horizontalSpacee;
            } else {
                if (chlid.value.isHidden) {
                    continue;
                }
                let nodeidth = chlid.value.width;
                let chlidGeneralizationWidth = this.getNodeGeneralizationWidth(chlid);
                chlidGeneralizationWidth = chlidGeneralizationWidth > 0 ? chlidGeneralizationWidth + Config.GeneralizationLineWidth + Config.GeneralizationSpacing * 2 : 0;
                nodeidth = nodeidth + chlidGeneralizationWidth;
                chlidWidth += nodeidth + horizontalSpacee;
            }
            if (siblingsMaxWidth < chlidWidth) {
                siblingsMaxWidth = chlidWidth;
            }
        }
        return siblingsMaxWidth + width;
    }

    getLayoutType() {
        return NodeLayoutType.LAYOUT_BRACKETS_RIGHT;
    }

}

export default BracketsRightLayout