
import Config from "../../../core/core/calcule/Config";
import Colors from "../../../utils/Colors";
import UiUtil from "../../../utils/UiUtil";
/**
 * ProjectName: MindMap
 * Created by tony on 5/2/21
 * Copyright(c) 2020 mindyushu.com
 */

class ExplainLabelView {
    constructor(context, MindElementData) {
        this.context = context;
        this.data = MindElementData;
        this.foreignObjectBox;
        this.label;
        this.initView();
    }
    setData(data) {
        this.data = data;
    }

    initView() {
        this.foreignObjectBox = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
        this.label = document.createElement("div");
        this.foreignObjectBox.appendChild(this.label);
        this.context.appendChild(this.foreignObjectBox);
    }

    setPosition(width, height, left, top) {
        this.label.style.whiteSpace = "nowrap"
        this.label.style.overflow = "hidden"
        this.label.style.textOverflow = "ellipsis"

        this.foreignObjectBox.setAttribute('width', width);
        this.foreignObjectBox.setAttribute('height', height);
        this.foreignObjectBox.setAttribute('x', left);
        this.foreignObjectBox.setAttribute('y', top);
        this.foreignObjectBox.style.userSelect = "none";

        this.label.innerHTML = this.data.explain;
        let labelWidht = width;
        this.label.style.width = labelWidht + 'px';
        this.label.style.height = height + 'px';
        this.label.style.fontSize = Config.NodeExplainFontSize + 'px';
        this.label.style.padding = Config.NodeExplainPadding + 'px';
        //避免全部展示的时候出现省略号。
        this.label.style.paddingLeft = Config.NodeExplainLeftAndRightPadding - 2 + 'px';
        this.label.style.paddingRight = Config.NodeExplainLeftAndRightPadding - 2 + 'px';
        this.label.style.lineHeight = height - Config.NodeExplainPadding * 2 - 2 + "px";

        this.label.style.borderRadius = new UiUtil().dip2px(4) + 'px';
        this.label.style.borderStyle = "solid";
        this.label.style.borderWidth = 1 + 'px';
        this.label.style.borderColor = Colors.getUiColorByAlpha(Colors.black50, 0.5);
        this.label.style.backgroundColor = Colors.getUiColorByAlpha(Colors.white, 0.8);
        this.label.style.textAlign = "center";

    }

    refreshView() {

    }

}

export default ExplainLabelView
