import Point from "../../../viewmodel/core/base/Point"

import Colors from "../../../utils/Colors"
import UiUtil from '../../../utils/UiUtil'
import CGPoint from '../../../viewmodel/core/base/basedata/CGPoint'
import LinePath from "../../../viewmodel/core/base/LinePath"
import LineLayout from "../../../viewmodel/datatype/LineLayout"
import LineOrientation from "../../../viewmodel/datatype/LineOrientation"
import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType"
import LineElementContent from "../../../viewmodel/mindelementdata/mindcontent/LineElementContent"
/**
 * Created by tony on 2019/12/29
 */
import BaseElementView from "./BaseElementView";
import Util from "../../../utils/Util"
import MindElementType from "../../../viewmodel/datatype/MindElementType"

class SonSubjectLineView extends BaseElementView {
    constructor(viewCanvs, data) {
        super(viewCanvs, data);
        this.path;  //线条
        this.arrow; //箭头
        this.circularViewList = []
        this.curveStartPoint;
        this.curveEndPoint;
        this.straightEndPoint;
        this.color;
        this.isAnimation = false;
        this.duration = 1;
        this.onDraw()
    }


    setAnimation(animation, duration) {
        this.isAnimation = animation;
        this.duration = duration;
        this.onDraw();
        this.isAnimation = false;
    }

    refresh() {
        if (!this.isRefresh) {
            return;
        }
        super.refresh();
        this.onDraw();
    }

    onDraw() {
        this.drawLine();
    }

    drawLine() {
        if (this.mindElementData == null || this.mindElementData.lineContent == null) {
            return;
        }
        if (this.arrow != null && this.arrow.parentNode != null) {    //删除箭头
            this.arrow.parentNode.removeChild(this.arrow);
            this.arrow = null
        }
        if (this.circularViewList.length > 0) {
            for (let i = 0; i < this.circularViewList.length; i++) {
                if (this.circularViewList[i] != null && this.circularViewList[i].parentNode != null) {
                    this.circularViewList[i].parentNode.removeChild(this.circularViewList[i]);
                }
            }
            this.circularViewList = []
        }
        if (this.path == null) {
            this.path = document.createElementNS('http://www.w3.org/2000/svg', "path")
            this.context.appendChild(this.path)
        }
        if (this.mindElementData.lineContent.lineWidth == 0) {
            this.path.style.display = "none"
            return;
        } else {
            this.path.style.display = "block"
        }
        let lineMindElementContent = this.mindElementData.lineContent;

        this.curveStartPoint = lineMindElementContent.curveStartPoint();
        this.curveEndPoint = lineMindElementContent.curveEndPoint();
        this.straightEndPoint = lineMindElementContent.straightEndPoint();
        this.color = lineMindElementContent.color;
        this.lineWidth = this.isFreeLine() ? lineMindElementContent.cornerRadius : lineMindElementContent.lineWidth

        if ((this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT || this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT) &&
            (lineMindElementContent.lineLayout == LineLayout.RIGHT_ANGLE_LINE ||
                lineMindElementContent.lineLayout == LineLayout.RIGHT_ANGLE_CORNER_LINE ||
                lineMindElementContent.lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_LINE ||
                lineMindElementContent.lineLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE ||
                lineMindElementContent.lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE)) {
            this.curveStartPoint = (new CGPoint).init(this.curveEndPoint.x, this.curveStartPoint.y);
        }
        if (this.mindElementData.isShadow && Math.abs(this.curveStartPoint.x - this.straightEndPoint.x) > 0 && Math.abs(this.curveStartPoint.y - this.straightEndPoint.y) > 0) {
            this.context.setAttribute('filter', 'url(#linemapLineShadow)');
        } else {
            this.context.setAttribute('filter', '');
        }
        if ((lineMindElementContent.lineLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE ||
            lineMindElementContent.lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE) &&
            !this.isFreeLine() && 
            (this.mindElementData.layout != NodeLayoutType.LAYOUT_BRACKETS_LEFT && this.mindElementData.layout != NodeLayoutType.LAYOUT_BRACKETS_RIGHT)) {
            this.drawArrow();
        }
        if (this.isFreeLine()) {
            this.drawFreeCurve();
        } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_CIRCLE ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_RADIATE) {
            this.drawCircle();
        } else if (lineMindElementContent.lineLayout == LineLayout.CURVE_LINE ||
            lineMindElementContent.lineLayout == LineLayout.CURVE_LINE_CIRCULAR) {
            this.drawCurve();
        } else if (lineMindElementContent.lineLayout == LineLayout.STRAIGHT_CURVE_LINE) {
            this.drawStraightCurve();
        } else if (lineMindElementContent.lineLayout == LineLayout.CURVE_LINE_2 ||
            lineMindElementContent.lineLayout == LineLayout.CURVE_LINE_AVERAGE) {
            this.drawCurve2();
        } else if (lineMindElementContent.lineLayout == LineLayout.RIGHT_ANGLE_LINE) {
            this.drawStraightLine(0);
        } else if (lineMindElementContent.lineLayout == LineLayout.STRAIGHT_LINE ||
            lineMindElementContent.lineLayout == LineLayout.STRAIGHT_LINE_2) { //开始点和控制点终点坐标都为0
            this.drawStraightLine();
        } else {
            this.drawStraightLine(6);
        }
    }

    drawFreeCurve() {
        let path = new LinePath();
        let treeCurveSpace = this.UiUtil.dip2px(6);
        let lineMindElementContent = this.mindElementData.lineContent;
        let moveToPoint = new Point();

        if (lineMindElementContent.orientation == LineOrientation.RIGHT ||
            lineMindElementContent.orientation == LineOrientation.LEFT) {
            let pointOne = new CGPoint(this.curveStartPoint.x, this.curveStartPoint.y)
            var pointTwo = new CGPoint(this.curveEndPoint.x - (this.curveEndPoint.x - this.curveStartPoint.x) * this.getDrawCurve2EndXRatio(this.curveEndPoint, this.curveStartPoint), this.curveStartPoint.y);
            var pointThree = new CGPoint(this.curveStartPoint.x + (this.curveEndPoint.x - this.curveStartPoint.x) * this.getDrawCurve2EndXRatio(this.curveEndPoint, this.curveStartPoint), this.curveEndPoint.y);
            var pointFour = new CGPoint(lineMindElementContent.endControlPointX, lineMindElementContent.endControlPointY)
            
            pointTwo.x = pointThree.x
            pointTwo.y = pointThree.y
            let amplitude = this.mindElementData.borderThicken ? lineMindElementContent.startControlPointY * 1.5 : lineMindElementContent.startControlPointY * 1.0
            if ((lineMindElementContent.orientation == LineOrientation.RIGHT && pointOne.y >= pointFour.y) ||
                (lineMindElementContent.orientation == LineOrientation.LEFT && pointOne.y <= pointFour.y)) { // 向上
                amplitude = amplitude * -1
            }
            var pointFourStartControl = new CGPoint(this.curveEndPoint.x + (lineMindElementContent.endControlPointX - this.curveEndPoint.x) * 0.40 + amplitude * 0.50,
                                                    lineMindElementContent.endControlPointY + amplitude * 0.5)
            var pointFourEndControl = new CGPoint(this.curveEndPoint.x + (lineMindElementContent.endControlPointX - this.curveEndPoint.x) * 0.75 + amplitude,
                                        lineMindElementContent.endControlPointY - amplitude * 0.8)
            let fullWidth = this.lineWidth
            let fullWidthY = this.lineWidth
            if ((lineMindElementContent.orientation == LineOrientation.RIGHT && pointOne.y >= pointFour.y) ||
                (lineMindElementContent.orientation == LineOrientation.LEFT && pointOne.y >= pointFour.y)) { //右上 左下
                fullWidthY = this.lineWidth
            } else {
                fullWidthY = this.lineWidth * -1.0
            }
            path.moveTo(pointOne.x, pointOne.y - fullWidthY / 2);
            path.cubicTo(
                pointTwo.x,
                pointTwo.y,
                pointThree.x,
                pointThree.y,
                this.curveEndPoint.x,
                this.curveEndPoint.y - fullWidthY / 2);

            if (this.mindElementData.borderThicken) {
                path.cubicTo(
                    pointFourStartControl.x,
                    pointFourStartControl.y - fullWidthY / 2,
                    pointFourEndControl.x,
                    pointFourEndControl.y - fullWidthY / 2,
                    pointFour.x,
                    pointFour.y);
            } else {
                path.cubicTo(
                    pointFourStartControl.x,
                    pointFourStartControl.y - fullWidthY / 2,
                    pointFourEndControl.x,
                    pointFourEndControl.y - fullWidthY / 2,
                    pointFour.x,
                    pointFour.y - fullWidthY / 2);
                path.lineTo(pointFour.x, pointFour.y + fullWidthY / 2);
            }
            path.cubicTo(
                pointFourEndControl.x,
                pointFourEndControl.y + fullWidthY / 2,
                pointFourStartControl.x,
                pointFourStartControl.y + fullWidthY / 2,
                this.curveEndPoint.x,
                this.curveEndPoint.y + fullWidthY / 2);
            if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
                if (pointOne.y >= pointFour.y) { // 向上
                    path.cubicTo(
                        pointThree.x + fullWidth,
                        pointThree.y + fullWidth,
                        pointTwo.x + fullWidth,
                        pointTwo.y + fullWidth,
                        pointOne.x + fullWidth, 
                        pointOne.y + fullWidthY / 2);
                } else {
                    path.cubicTo(
                        pointThree.x + fullWidth,
                        pointThree.y - fullWidth,
                        pointTwo.x + fullWidth,
                        pointTwo.y + fullWidth * 0,
                        pointOne.x + fullWidth, 
                        pointOne.y + fullWidthY / 2);
                }
            } else {
                if (pointOne.y >= pointFour.y) { // 向上                    
                    path.cubicTo(
                        pointThree.x - fullWidth,
                        pointThree.y + fullWidthY,
                        pointTwo.x - fullWidth,
                        pointTwo.y + fullWidthY,
                        pointOne.x - fullWidthY, 
                        pointOne.y + fullWidthY / 2);
                } else {
                    path.cubicTo(
                        pointThree.x - fullWidth,
                        pointThree.y + fullWidthY,
                        pointTwo.x - fullWidth,
                        pointTwo.y + fullWidthY,
                        pointOne.x + fullWidthY, 
                        pointOne.y + fullWidthY / 2);
                }
            }
            
            
            path.lineTo(pointOne.x, pointOne.y + fullWidthY / 2);

            // this.drawCircular(new Point(this.curveEndPoint.x, this.curveEndPoint.y ), 2, Colors.red, 0, 0);
            // this.drawCircular(new Point(pointFour.x, pointFour.y ), 2, Colors.red, 0, 0);
        }
        this.renderCanvas(path)
    }

    drawCircle() {
        let path = new LinePath();
        let lineMindElementContent = this.mindElementData.lineContent;

        let cornerRadius = lineMindElementContent.cornerRadius;
        path.setEffectRadius(0)
        path.moveTo(0, cornerRadius);
        path.circle(cornerRadius, cornerRadius, 0, 0, 1, cornerRadius * 2, cornerRadius);
        path.circle(cornerRadius, cornerRadius, 0, 0, 1, 0, cornerRadius);
        this.renderCanvas(path)
    }

    drawStraightLine(radius) {
        if (arguments.length == 0) {

            let linePath = new LinePath();
            linePath.setEffectRadius(0)

            let lineMindElementContent = this.mindElementData.lineContent;
            radius = new UiUtil().dip2px(10)
            
            if ((lineMindElementContent.orientation == LineOrientation.TOP || 
                lineMindElementContent.orientation == LineOrientation.BOTTOM)) {
                let startSpace = 5;
                let middlePoint = new CGPoint(this.curveStartPoint.x, this.curveStartPoint.y + startSpace);
                if (this.curveStartPoint.y > this.straightEndPoint.y) {
                    middlePoint = new CGPoint(this.curveStartPoint.x, this.curveStartPoint.y - startSpace);
                }

                linePath.moveTo(this.curveStartPoint.x, this.curveStartPoint.y);
                linePath.lineTo(middlePoint.x, middlePoint.y);
                linePath.lineTo(this.straightEndPoint.x, this.straightEndPoint.y);
            } else {
                if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT || 
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
                    if (lineMindElementContent.lineLayout == LineLayout.STRAIGHT_LINE) {
                        linePath.moveTo(this.curveStartPoint.x, this.curveStartPoint.y);
                        linePath.lineTo(this.curveEndPoint.x, this.curveStartPoint.y);
                        linePath.lineTo(this.curveEndPoint.x, this.straightEndPoint.y);
                        this.renderCanvas(linePath);
                        return;
                    }
                    if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                        linePath.moveTo(this.curveStartPoint.x - this.UiUtil.dip2px(6), this.curveStartPoint.y);
                    } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
                        linePath.moveTo(this.curveStartPoint.x + this.UiUtil.dip2px(6), this.curveStartPoint.y);
                    }
                }
                let treeSpace = this.UiUtil.dip2px(20)
                let startSpace = this.UiUtil.dip2px(10)
                let point0 = this.curveStartPoint;
                let point1 = new Point(this.curveEndPoint.x, this.curveEndPoint.y);
                let point2 = new Point(this.straightEndPoint.x, this.straightEndPoint.y);
                if ((this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) &&
                    Math.abs(this.curveEndPoint.y - this.curveStartPoint.y) > treeSpace) {
                    if (this.curveEndPoint.y > this.curveStartPoint.y) {
                        point0 = new Point(this.curveStartPoint.x, this.curveEndPoint.y - treeSpace)
                    } else {
                        point0 = new Point(this.curveStartPoint.x, this.curveEndPoint.y + treeSpace)
                    }
                } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_RIGHT ||
                        this.mindElementData.layout == NodeLayoutType.LAYOUT_LEFT) {
                    if (this.straightEndPoint.y == this.curveEndPoint.y && this.straightEndPoint.x == this.curveEndPoint.x) {
                        linePath.moveTo(this.curveStartPoint.x, this.curveStartPoint.y);
                        linePath.lineTo(this.curveEndPoint.x, this.curveEndPoint.y);
                        linePath.lineTo(this.straightEndPoint.x, this.straightEndPoint.y);
                        this.renderCanvas(linePath);
                        return;
                    }
                    radius = new UiUtil().dip2px(6)
                    if (this.curveEndPoint.x > this.curveStartPoint.x) {
                        point0 = new Point(this.curveStartPoint.x + startSpace, this.curveStartPoint.y)
                    } else {
                        point0 = new Point(this.curveStartPoint.x - startSpace, this.curveStartPoint.y)
                    }
                }
                let insertPoints = Util.getInsertPointBetweenCircleAndLine(point0.x, point0.y, point1.x, point1.y,
                                                        point1.x, point1.y, radius)
                let endPointRadiusPoint = null
                let startPointRadiusPoint = null

                let spacing = 90000000
                
                for (let i = 0; i < insertPoints.length; i++) {
                    let dd = Util.getPointSpacing(point0, insertPoints[i]);
                    if (spacing > dd) {
                        startPointRadiusPoint = insertPoints[i]
                        spacing = dd
                    }
                }
                insertPoints = Util.getInsertPointBetweenCircleAndLine(point2.x, point2.y, point1.x, point1.y,
                                                        point1.x, point1.y, radius)
                spacing = 90000000
                for (let i = 0; i < insertPoints.length; i++) {
                    let dd = Util.getPointSpacing(point2, insertPoints[i]);
                    if (spacing > dd) {
                        endPointRadiusPoint = insertPoints[i]
                        spacing = dd
                    }
                }
                if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT ) {
                    linePath.lineTo(this.curveStartPoint.x, this.curveStartPoint.y);
                } else {
                    linePath.moveTo(this.curveStartPoint.x, this.curveStartPoint.y);
                }

                
                linePath.lineTo(point0.x, point0.y);
                linePath.lineTo(startPointRadiusPoint.x, startPointRadiusPoint.y);
                linePath.cubicTo(
                    point1.x,
                    point1.y,
                    point1.x,
                    point1.y,
                    endPointRadiusPoint.x,
                    endPointRadiusPoint.y);
                linePath.lineTo(point2.x, point2.y);
            }
            this.renderCanvas(linePath)
        } else if (arguments.length == 1) {
            let linePath = new LinePath();
            let lineMindElementContent = this.mindElementData.lineContent;
            linePath.setEffectRadius(radius)
            let radiusBackups = radius;
            radius = 0;
            let pointOne = new Point(this.curveStartPoint.x, this.curveStartPoint.y)
            linePath.moveTo(pointOne.x, pointOne.y);

            if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
                let pointTwo = new Point(this.curveEndPoint.x, this.curveStartPoint.y)
                if (radiusBackups > 0 && this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                    radiusBackups = (this.straightEndPoint.x - this.curveStartPoint.x) / 2
                    linePath.setEffectRadius(radiusBackups)
                } else {
                    linePath.lineTo(pointTwo.x, pointTwo.y); //移除中间弧度
                }
                linePath.lineTo(pointTwo.x, pointTwo.y);
                if (this.curveStartPoint.y > this.curveEndPoint.y) {
                    let pointThree = new Point(this.curveEndPoint.x, this.curveEndPoint.y)
                    linePath.lineTo(pointThree.x, pointThree.y);
                } else if (this.curveStartPoint.y < this.curveEndPoint.y) {
                    let pointThree = new Point(this.curveEndPoint.x, this.curveEndPoint.y)
                    linePath.lineTo(pointThree.x, pointThree.y);
                } else {
                    let pointThree = new Point(this.curveEndPoint.x - 4, this.curveEndPoint.y)
                    linePath.lineTo(pointThree.x, pointThree.y);
                }
            } else if (lineMindElementContent.orientation == LineOrientation.BOTTOM) {
                let moveY = (this.curveEndPoint.y - this.curveStartPoint.y);
                if (lineMindElementContent.lineLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE ||
                    lineMindElementContent.lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE) {
                    moveY = moveY - 5
                }
                let pointTwo = new Point(this.curveStartPoint.x, moveY / 2)
                linePath.lineTo(pointTwo.x, pointTwo.y - 1);//移除中间弧度
                linePath.lineTo(pointTwo.x, pointTwo.y);
                if (this.curveStartPoint.x > this.curveEndPoint.x) {
                    let pointThree = new Point(this.curveEndPoint.x, moveY / 2)
                    linePath.lineTo(pointThree.x, pointThree.y);
                } else if (this.curveStartPoint.x < this.curveEndPoint.x) {
                    let pointThree = new Point(this.curveEndPoint.x, moveY / 2)
                    linePath.lineTo(pointThree.x, pointThree.y);
                } else {
                    let pointThree = new Point(this.curveEndPoint.x, moveY / 2)
                    linePath.lineTo(pointThree.x, pointThree.y);
                }
            } else if (lineMindElementContent.orientation == LineOrientation.TOP) {
                let moveY = (this.curveStartPoint.y - this.curveEndPoint.y);
                if (lineMindElementContent.lineLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE ||
                    lineMindElementContent.lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE) {
                    moveY = moveY + 5
                }
                linePath.lineTo(this.curveStartPoint.x, moveY / 2 + 1);//移除中间弧度
                linePath.lineTo(this.curveStartPoint.x, moveY / 2);
                if (this.curveStartPoint.x > this.curveEndPoint.x) {
                    linePath.lineTo(this.curveEndPoint.x, moveY / 2);
                } else if (this.curveStartPoint.x < this.curveEndPoint.x) {
                    linePath.lineTo(this.curveEndPoint.x, moveY / 2);
                } else {
                    linePath.lineTo(this.curveEndPoint.x, moveY / 2);
                }
            } else if (lineMindElementContent.orientation == LineOrientation.LEFT) {
                let pointTwo = new Point(this.curveEndPoint.x, this.curveStartPoint.y)
                if (radiusBackups > 0 && this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
                    linePath.setEffectRadius(Math.abs(this.straightEndPoint.x - this.curveStartPoint.x) / 2)
                } else {
                    linePath.lineTo(pointTwo.x, pointTwo.y);//移除中间弧度
                }
                linePath.lineTo(pointTwo.x, pointTwo.y);
                if (this.curveStartPoint.y > this.curveEndPoint.y) {
                    let pointThree = new Point(this.curveEndPoint.x, this.curveEndPoint.y + radius)
                    linePath.lineTo(pointThree.x, pointThree.y);
                } else if (this.curveStartPoint.y < this.curveEndPoint.y) {
                    let pointThree = new Point(this.curveEndPoint.x, this.curveEndPoint.y - radius)
                    linePath.lineTo(pointThree.x, pointThree.y);
                } else {
                    let pointThree = new Point(this.curveEndPoint.x, this.curveEndPoint.y)
                    linePath.lineTo(pointThree.x, pointThree.y);
                }
            }
            
            linePath.lineTo(this.straightEndPoint.x, this.straightEndPoint.y);
            this.renderCanvas(linePath)
        }

    }
    //自己添加的一个函数
    renderCanvas(path) {
        let lineMindElementContent = this.mindElementData.lineContent
        this.path.setAttribute("d", path.getLineRadius())
        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_RADIATE) {
            this.path.setAttribute("stroke", Colors.getUiColor(lineMindElementContent.strokeColor))
            this.path.setAttribute("stroke-width", this.lineWidth)
        } else if (this.isFreeLine()) {
            this.path.setAttribute("stroke", Colors.getUiColor(this.color))
            this.path.setAttribute("stroke-width", 0.0)
        } else {
            this.path.setAttribute("stroke", Colors.getUiColor(this.color))
            this.path.setAttribute("stroke-width", this.lineWidth)
        }
        
        if (this.isAnimation) {
            this.path.style.strokeDashoffset = this.path.getTotalLength()
            this.path.style.strokeDasharray = this.path.getTotalLength()
            this.path.style.animation = 'path-line-animation-dash ' + (this.duration / 1000) + 's linear forwards'
        } else {
            if (lineMindElementContent.dottedLine) {
                let lineLenght = this.path.getTotalLength()
                var dottedLineWidth = 15
                if (this.lineWidth >= 5) {
                    dottedLineWidth = 20
                } else if (this.lineWidth >= 4) {
                    dottedLineWidth = 18
                } else if (this.lineWidth >= 3) {
                    dottedLineWidth = 16
                }
                let dotCount = Math.floor(lineLenght / dottedLineWidth)
                if (dotCount % 2 == 1) { //空白开始实线结束
                    dotCount += 1
                }
                dottedLineWidth = lineLenght / dotCount
                let strokeDashScale = dottedLineWidth * 0.25 < (this.lineWidth + 1) ? 0.35 : 0.25

                this.path.style.strokeDashoffset = dottedLineWidth
                this.path.style.strokeDasharray = [dottedLineWidth * (1 - strokeDashScale), dottedLineWidth * strokeDashScale]
            } else {
                this.path.style.strokeDashoffset = 0
                this.path.style.strokeDasharray = 0
            }
            this.path.style.animation = ""
        }
        if (this.isFreeLine()) {
            this.path.setAttribute("fill", Colors.getUiColor(this.color))
        } else {
            this.path.setAttribute("fill", "none")
        }
        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_RADIATE) {
            this.path.setAttribute("fill", Colors.getUiColor(lineMindElementContent.color))
        }
        // this.path.setAttribute("fill", "none")
    }

    drawStraightCurve() {
        let path = new LinePath();
        
        let lineMindElementContent = this.mindElementData.lineContent;
        if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
            if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) {
                let treeSpace = this.UiUtil.dip2px(30)
                path.moveTo(this.curveStartPoint.x, this.curveStartPoint.y);
                path.lineTo(this.curveStartPoint.x, this.curveStartPoint.y);

                if (Math.abs(this.curveStartPoint.y - this.curveEndPoint.y) < treeSpace) {
                    path.lineTo(this.curveStartPoint.x, this.curveEndPoint.y);
                } else if (this.curveStartPoint.y < this.curveEndPoint.y) {
                    path.lineTo(this.curveStartPoint.x, this.curveEndPoint.y - treeSpace);
                } else {
                    path.lineTo(this.curveStartPoint.x, this.curveEndPoint.y + treeSpace);
                }

                path.cubicTo(
                        this.curveStartPoint.x,
                        this.curveEndPoint.y,
                        this.curveStartPoint.x + ((this.curveEndPoint.x - this.curveStartPoint.x) * 0.5),
                        this.curveEndPoint.y,
                        this.curveEndPoint.x,
                        this.curveEndPoint.y);
            } else {
                let space = this.UiUtil.dip2px(12);
                if (Math.abs(this.curveStartPoint.x - this.straightEndPoint.x) <= 14) {
                    space = Math.abs(this.curveStartPoint.x - this.straightEndPoint.x) * 0.4;
                }
                this.curveStartPoint.x = this.curveStartPoint.x;
                path.moveTo(this.curveStartPoint.x, this.curveStartPoint.y);

                path.lineTo(this.curveStartPoint.x + space, this.curveStartPoint.y);

                let spaceH = this.UiUtil.dip2px(10);                
                if (this.curveStartPoint.x < this.straightEndPoint.x) { // 向右
                    spaceH = Math.min((this.straightEndPoint.x - this.curveStartPoint.x) * 0.4, spaceH)
                } else {
                    spaceH = -Math.min((this.curveStartPoint.x - this.straightEndPoint.x) * 0.4, spaceH)
                }
                let cubicSpace = spaceH * 1.5;
                let point2 = new Point(this.straightEndPoint.x - spaceH, this.straightEndPoint.y)
                let point3 = new Point(this.straightEndPoint.x - spaceH - cubicSpace, this.straightEndPoint.y)
                let startPointAndPointDegrees = 180 - Util.getCircleDegreesInPoint(point2, this.curveStartPoint)
                let point3Height = Util.tan(startPointAndPointDegrees) * cubicSpace;
                if (point3Height > 0) {
                    point3Height = Math.min(point3Height, Math.abs(cubicSpace * 3))
                } else {
                    point3Height = Math.max(point3Height, -Math.abs(cubicSpace * 3))
                }
                let point1 = new Point(this.straightEndPoint.x - spaceH - cubicSpace, this.straightEndPoint.y + point3Height * 0.5)                
                let controlPoint = Util.getStaticgetLineIntersectionPoint(point1, this.curveStartPoint, 
                                                                                point2, this.straightEndPoint)
                path.lineToByPoint(point1);
                path.addQuadCurve(point2, controlPoint)
            }
            path.lineTo(this.straightEndPoint.x, this.straightEndPoint.y);
        } else if (lineMindElementContent.orientation == LineOrientation.BOTTOM ||
            lineMindElementContent.orientation == LineOrientation.TOP) {
            let space = this.UiUtil.dip2px(8);
            let pointOne = new CGPoint(this.curveStartPoint.x, this.curveStartPoint.y)
            let pointTwo = new CGPoint(this.curveStartPoint.x + (this.curveEndPoint.x - this.curveStartPoint.x) * 0.1,
                this.curveStartPoint.y + (this.curveEndPoint.y - this.curveStartPoint.y) * 0.42)
            let pointThree = new CGPoint(this.curveEndPoint.x,
                this.curveStartPoint.y + (this.curveEndPoint.y - this.curveStartPoint.y) * 0.40)

            path.moveTo(pointOne.x, pointOne.y);
            let middlePoint = new CGPoint(pointOne.x, pointOne.y + space);
            if (pointOne.y > this.curveEndPoint.y) {
                middlePoint = new CGPoint(pointOne.x, pointOne.y - space);
            }
            path.lineTo(middlePoint.x, middlePoint.y);

            let spaceH = this.UiUtil.dip2px(6);                
            if (middlePoint.y < this.curveEndPoint.y) { // 向下
                spaceH = Math.min((this.curveEndPoint.y - middlePoint.y) * 0.4, spaceH)
            } else {
                spaceH = -Math.min((middlePoint.y - this.curveEndPoint.y) * 0.4, spaceH)
            }
            let cubicSpace = spaceH * 1.5;
            let point2 = new Point(this.curveEndPoint.x, this.curveEndPoint.y - spaceH)
            let point3 = new Point(this.curveEndPoint.x, this.curveEndPoint.y - spaceH - cubicSpace)
            let startPointAndPointDegrees = Util.getCircleDegreesInPoint(point2, middlePoint) - 270
            let point3Height = Util.tan(startPointAndPointDegrees) * cubicSpace;
            if (point3Height > 0) {
                point3Height = Math.min(point3Height, Math.abs(cubicSpace * 3))
            } else {
                point3Height = Math.max(point3Height, -Math.abs(cubicSpace * 3))
            }
            let point1 = new Point(this.curveEndPoint.x + point3Height * 0.5, this.curveEndPoint.y - spaceH - cubicSpace)                
            let controlPoint = Util.getStaticgetLineIntersectionPoint(point1, middlePoint, 
                                                                            point2, this.curveEndPoint)
            path.lineToByPoint(point1);
            path.addQuadCurve(point2, controlPoint)
            path.lineTo(this.curveEndPoint.x, this.curveEndPoint.y);

        } else if (lineMindElementContent.orientation == LineOrientation.LEFT) {
            if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                let treeSpace = this.UiUtil.dip2px(30)
                let pointOne = new CGPoint(this.curveStartPoint.x, this.curveStartPoint.y)
                path.moveTo(pointOne.x, pointOne.y);
                path.lineTo(this.curveStartPoint.x, this.curveStartPoint.y);
                if (Math.abs(this.curveStartPoint.y - this.curveEndPoint.y) < treeSpace) {
                    path.lineTo(this.curveStartPoint.x, this.curveEndPoint.y);
                } else if (this.curveStartPoint.y < this.curveEndPoint.y) {
                    path.lineTo(this.curveStartPoint.x, this.curveEndPoint.y - treeSpace);
                } else {
                    path.lineTo(this.curveStartPoint.x, this.curveEndPoint.y + treeSpace);
                }
                path.cubicTo(
                    this.curveStartPoint.x,
                    this.curveEndPoint.y,
                    this.curveStartPoint.x - ((this.curveStartPoint.x - this.curveEndPoint.x) * 0.5),
                    this.curveEndPoint.y,
                    this.curveEndPoint.x,
                    this.curveEndPoint.y);
            } else {
                this.curveStartPoint.x = this.curveStartPoint.x - new UiUtil().dip2px(lineMindElementContent.lineWidth) / 2;
                let pointOne = new CGPoint(this.curveStartPoint.x, this.curveStartPoint.y)

                let space = this.UiUtil.dip2px(10);
                if (Math.abs(pointOne.x - this.straightEndPoint.x) <= 12) {
                    space = Math.abs(pointOne.x - this.straightEndPoint.x) * 0.5;
                }
                
                path.moveTo(pointOne.x, pointOne.y);
                path.lineTo(this.curveStartPoint.x - space, this.curveStartPoint.y);
    
                let spaceH = this.UiUtil.dip2px(10);                
                if (this.curveStartPoint.x < this.straightEndPoint.x) { // 向右
                    spaceH = Math.min((this.straightEndPoint.x - this.curveStartPoint.x) * 0.4, spaceH)
                } else {
                    spaceH = -Math.min((this.curveStartPoint.x - this.straightEndPoint.x) * 0.4, spaceH)
                }
                let cubicSpace = spaceH * 1.5;
                let point2 = new Point(this.straightEndPoint.x - spaceH, this.straightEndPoint.y)
                let point3 = new Point(this.straightEndPoint.x - spaceH - cubicSpace, this.straightEndPoint.y)
                let startPointAndPointDegrees = 180 - Util.getCircleDegreesInPoint(point2, this.curveStartPoint)
                let point3Height = Util.tan(startPointAndPointDegrees) * cubicSpace;
                if (point3Height > 0) {
                    point3Height = Math.min(point3Height, Math.abs(cubicSpace * 3))
                } else {
                    point3Height = Math.max(point3Height, -Math.abs(cubicSpace * 3))
                }
                let point1 = new Point(this.straightEndPoint.x - spaceH - cubicSpace, this.straightEndPoint.y + point3Height * 0.5)                
                let controlPoint = Util.getStaticgetLineIntersectionPoint(point1, this.curveStartPoint, 
                                                                                point2, this.straightEndPoint)
                path.lineToByPoint(point1);
                path.addQuadCurve(point2, controlPoint)
            }
            path.lineTo(this.straightEndPoint.x, this.straightEndPoint.y);
        }
        this.renderCanvas(path)
    }

    drawCurve() {
        let path = new LinePath();
        
        let lineMindElementContent = this.mindElementData.lineContent;
        if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
            if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) {
                let treeSpace = this.UiUtil.dip2px(30)
                path.moveTo(this.curveStartPoint.x, this.curveStartPoint.y);
                path.lineTo(this.curveStartPoint.x, this.curveStartPoint.y);

                if (Math.abs(this.curveStartPoint.y - this.curveEndPoint.y) < treeSpace) {
                    path.lineTo(this.curveStartPoint.x, this.curveEndPoint.y);
                } else if (this.curveStartPoint.y < this.curveEndPoint.y) {
                    path.lineTo(this.curveStartPoint.x, this.curveEndPoint.y - treeSpace);
                } else {
                    path.lineTo(this.curveStartPoint.x, this.curveEndPoint.y + treeSpace);
                }

                path.cubicTo(
                        this.curveStartPoint.x,
                        this.curveEndPoint.y,
                        this.curveStartPoint.x + ((this.curveEndPoint.x - this.curveStartPoint.x) * 0.5),
                        this.curveEndPoint.y,
                        this.curveEndPoint.x,
                        this.curveEndPoint.y);
            } else {
                let space = this.UiUtil.dip2px(12);
                if (Math.abs(this.curveStartPoint.x - this.straightEndPoint.x) <= 14) {
                    space = Math.abs(this.curveStartPoint.x - this.straightEndPoint.x) * 0.4;
                }
                this.curveStartPoint.x = this.curveStartPoint.x;
                path.moveTo(this.curveStartPoint.x, this.curveStartPoint.y);

                path.lineTo(this.curveStartPoint.x + space, this.curveStartPoint.y);

                path.cubicTo(
                        this.curveStartPoint.x + space + (this.straightEndPoint.x - this.curveStartPoint.x - space) * 0.5,
                        this.straightEndPoint.y,
                        this.curveStartPoint.x + space + (this.straightEndPoint.x - this.curveStartPoint.x - space) * 0.5,
                        this.straightEndPoint.y,
                        this.straightEndPoint.x,
                        this.straightEndPoint.y);
            }
            path.lineTo(this.straightEndPoint.x, this.straightEndPoint.y);
        } else if (lineMindElementContent.orientation == LineOrientation.BOTTOM ||
            lineMindElementContent.orientation == LineOrientation.TOP) {
            let space = 4;
            let pointOne = new CGPoint(this.curveStartPoint.x, this.curveStartPoint.y)
            let pointTwo = new CGPoint(this.curveStartPoint.x + (this.curveEndPoint.x - this.curveStartPoint.x) * 0.1,
                this.curveStartPoint.y + (this.curveEndPoint.y - this.curveStartPoint.y) * 0.42)
            let pointThree = new CGPoint(this.curveEndPoint.x,
                this.curveStartPoint.y + (this.curveEndPoint.y - this.curveStartPoint.y) * 0.40)

            path.moveTo(pointOne.x, pointOne.y);
            let middlePoint = new CGPoint(pointOne.x, pointOne.y + space);
            if (pointOne.y > this.curveEndPoint.y) {
                middlePoint = new CGPoint(pointOne.x, pointOne.y - space);
            }
            path.lineTo(middlePoint.x, middlePoint.y);

            path.cubicTo(
                pointTwo.x,
                pointTwo.y,
                pointThree.x,
                pointThree.y,
                this.curveEndPoint.x,
                this.curveEndPoint.y);

        } else if (lineMindElementContent.orientation == LineOrientation.LEFT) {
            if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                let treeSpace = this.UiUtil.dip2px(30)
                let pointOne = new CGPoint(this.curveStartPoint.x, this.curveStartPoint.y)
                path.moveTo(pointOne.x, pointOne.y);
                path.lineTo(this.curveStartPoint.x, this.curveStartPoint.y);
                if (Math.abs(this.curveStartPoint.y - this.curveEndPoint.y) < treeSpace) {
                    path.lineTo(this.curveStartPoint.x, this.curveEndPoint.y);
                } else if (this.curveStartPoint.y < this.curveEndPoint.y) {
                    path.lineTo(this.curveStartPoint.x, this.curveEndPoint.y - treeSpace);
                } else {
                    path.lineTo(this.curveStartPoint.x, this.curveEndPoint.y + treeSpace);
                }
                path.cubicTo(
                    this.curveStartPoint.x,
                    this.curveEndPoint.y,
                    this.curveStartPoint.x - ((this.curveStartPoint.x - this.curveEndPoint.x) * 0.5),
                    this.curveEndPoint.y,
                    this.curveEndPoint.x,
                    this.curveEndPoint.y);
            } else {
                this.curveStartPoint.x = this.curveStartPoint.x - new UiUtil().dip2px(lineMindElementContent.lineWidth) / 2;
                let pointOne = new CGPoint(this.curveStartPoint.x, this.curveStartPoint.y)

                let space = this.UiUtil.dip2px(10);
                if (Math.abs(pointOne.x - this.straightEndPoint.x) <= 12) {
                    space = Math.abs(pointOne.x - this.straightEndPoint.x) * 0.5;
                }
                
                path.moveTo(pointOne.x, pointOne.y);
                path.lineTo(this.curveStartPoint.x - space, this.curveStartPoint.y);
    
                path.cubicTo(
                    this.curveStartPoint.x - space - (this.curveStartPoint.x - this.straightEndPoint.x - space) * 0.5,
                    this.straightEndPoint.y,
                    this.curveStartPoint.x - space - (this.curveStartPoint.x - this.straightEndPoint.x - space) * 0.5,
                    this.straightEndPoint.y,
                    this.straightEndPoint.x,
                    this.straightEndPoint.y);
            }
            path.lineTo(this.straightEndPoint.x, this.straightEndPoint.y);
        }
        this.renderCanvas(path)
    }


    getDrawCurve2EndXRatio(startPoint, endPoint) {
        let ratio = 0.5;
        if (this.mindElementData == null ||
            this.mindElementData.isEmpty() ||
            this.mindElementData.lineContent == null) {
            return ratio;
        }
        let maxHeight = Math.abs(endPoint.x - startPoint.x);
        let moveHeight = Math.abs(endPoint.y - startPoint.y);
        if (this.mindElementData.lineContent.orientation == LineOrientation.BOTTOM ||
            this.mindElementData.lineContent.orientation == LineOrientation.TOP) {
            maxHeight = Math.abs(endPoint.y - startPoint.y);
            moveHeight = Math.abs(endPoint.x - startPoint.x);
        }
        if (moveHeight == 0) {
            return 0.5;
        }
        ratio = maxHeight / moveHeight;
        if (ratio > 0.5) {
            ratio = 0.5;
        } else if (ratio < 0) {
            ratio = 0.0;
        }
        return ratio;
    }

    drawCurve2() {
        let path = new LinePath();

        let lineMindElementContent = this.mindElementData.lineContent;

        if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
            if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) {
                    let treeCurveSpace = this.UiUtil.dip2px(6);
                    path.moveTo(this.curveStartPoint.x, this.curveStartPoint.y);
                    path.lineTo(this.curveStartPoint.x, this.straightEndPoint.y);
                    if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT) {
                        path.cubicTo(
                            this.curveStartPoint.x + (this.straightEndPoint.x - this.curveStartPoint.x)/2,
                            this.straightEndPoint.y + treeCurveSpace,
                            this.curveStartPoint.x + (this.straightEndPoint.x - this.curveStartPoint.x)/2,
                            this.straightEndPoint.y - treeCurveSpace/3,
                            this.straightEndPoint.x,
                            this.straightEndPoint.y);
                    } else {
                        path.cubicTo(
                            this.curveStartPoint.x + (this.straightEndPoint.x - this.curveStartPoint.x)/3,
                            this.straightEndPoint.y - treeCurveSpace,
                            this.curveStartPoint.x + (this.straightEndPoint.x - this.curveStartPoint.x)/2,
                            this.straightEndPoint.y + treeCurveSpace/3,
                            this.straightEndPoint.x,
                            this.straightEndPoint.y);
                    }
                    
                    path.lineTo(this.straightEndPoint.x, this.straightEndPoint.y);
            } else {
                let pointOne = new CGPoint(this.curveStartPoint.x, this.curveStartPoint.y)
                let pointTwo = new CGPoint(this.curveStartPoint.x + (this.curveEndPoint.x - this.curveStartPoint.x) * 0.5,
                    this.curveStartPoint.y)
                let pointThree = new CGPoint(this.curveStartPoint.x + (this.curveEndPoint.x - this.curveStartPoint.x) * 0.5,
                    this.curveEndPoint.y)
                if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                    pointTwo = new CGPoint(this.curveStartPoint.x + (this.curveEndPoint.x - this.curveStartPoint.x) * 1.1,
                        this.curveStartPoint.y)
                    pointThree = new CGPoint(this.curveStartPoint.x + (this.curveEndPoint.x - this.curveStartPoint.x) * -0.1,
                        this.curveEndPoint.y)
                } else {
                    pointTwo = new CGPoint(this.curveEndPoint.x - (this.curveEndPoint.x - this.curveStartPoint.x) * this.getDrawCurve2EndXRatio(this.curveEndPoint, this.curveStartPoint), this.curveStartPoint.y);
                    pointThree = new CGPoint(this.curveStartPoint.x + (this.curveEndPoint.x - this.curveStartPoint.x) * this.getDrawCurve2EndXRatio(this.curveEndPoint, this.curveStartPoint), this.curveEndPoint.y);
                }
                path.moveTo(pointOne.x, pointOne.y);
                path.lineTo(this.curveStartPoint.x, this.curveStartPoint.y);
    
                path.cubicTo(
                    pointTwo.x,
                    pointTwo.y,
                    pointThree.x,
                    pointThree.y,
                    this.curveEndPoint.x,
                    this.curveEndPoint.y);
    
                path.lineTo(this.straightEndPoint.x, this.straightEndPoint.y);
            }
        } else if (lineMindElementContent.orientation == LineOrientation.BOTTOM ||
            this.mindElementData.lineContent.orientation == LineOrientation.TOP) {
            let pointOne = new CGPoint(this.curveStartPoint.x, this.curveStartPoint.y)
            let pointTwo = new CGPoint(this.curveStartPoint.x + (this.curveEndPoint.x - this.curveStartPoint.x) * 0.1,
                this.curveStartPoint.y + (this.curveEndPoint.y - this.curveStartPoint.y) * 0.42)
            let pointThree = new CGPoint(this.curveEndPoint.x,
                this.curveStartPoint.y + (this.curveEndPoint.y - this.curveStartPoint.y) * 0.60)

            path.moveTo(pointOne.x, pointOne.y);
            path.cubicTo(
                pointTwo.x,
                pointTwo.y,
                pointThree.x,
                pointThree.y,
                this.curveEndPoint.x,
                this.curveEndPoint.y);
        } else if (lineMindElementContent.orientation == LineOrientation.LEFT) {
            if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                    let treeCurveSpace = this.UiUtil.dip2px(6);
                    path.moveTo(this.curveStartPoint.x, this.curveStartPoint.y);
                    path.lineTo(this.curveStartPoint.x, this.straightEndPoint.y);
                    if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT) {
                        path.cubicTo(
                            this.curveStartPoint.x - (this.curveStartPoint.x - this.straightEndPoint.x)/2,
                            this.straightEndPoint.y + treeCurveSpace,
                            this.curveStartPoint.x - (this.curveStartPoint.x  - this.straightEndPoint.x)/2,
                            this.straightEndPoint.y - treeCurveSpace/3,
                            this.straightEndPoint.x,
                            this.straightEndPoint.y);
                    } else {
                        path.cubicTo(
                            this.curveStartPoint.x - (this.curveStartPoint.x - this.straightEndPoint.x)/3,
                            this.straightEndPoint.y - treeCurveSpace,
                            this.curveStartPoint.x - (this.curveStartPoint.x - this.straightEndPoint.x)/2,
                            this.straightEndPoint.y + treeCurveSpace/3,
                            this.straightEndPoint.x,
                            this.straightEndPoint.y);
                    }
                    
                    path.lineTo(this.straightEndPoint.x, this.straightEndPoint.y);
            } else {
                let pointOne = new CGPoint(this.curveStartPoint.x, this.curveStartPoint.y)
                var pointTwo = new CGPoint(this.curveEndPoint.x - (this.curveEndPoint.x - this.curveStartPoint.x) * this.getDrawCurve2EndXRatio(this.curveEndPoint, this.curveStartPoint), this.curveStartPoint.y);
                var pointThree = new CGPoint(this.curveStartPoint.x + (this.curveEndPoint.x - this.curveStartPoint.x) * this.getDrawCurve2EndXRatio(this.curveEndPoint, this.curveStartPoint), this.curveEndPoint.y);
                if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
                    pointTwo = new CGPoint(this.curveStartPoint.x - Math.abs(this.curveEndPoint.x - this.curveStartPoint.x) * 1.1, this.curveStartPoint.y);
                    pointThree = new CGPoint(this.curveStartPoint.x + Math.abs(this.curveEndPoint.x - this.curveStartPoint.x) * -0.1, this.curveEndPoint.y);
                }
                path.moveTo(pointOne.x, pointOne.y);
    
                path.lineTo(this.curveStartPoint.x, this.curveStartPoint.y);
                path.cubicTo(
                    pointTwo.x,
                    pointTwo.y,
                    pointThree.x,
                    pointThree.y,
                    this.curveEndPoint.x,
                    this.curveEndPoint.y);
                path.lineTo(this.straightEndPoint.x, this.straightEndPoint.y);
            }
        }
        this.renderCanvas(path)
    }

    drawArrow() {
        if (this.mindElementData == null || this.mindElementData.lineContent == null) {
            return;
        }
        let lineMindElementContent = this.mindElementData.lineContent;
        let linePath = new LinePath();
        let arrowStart = new Point();
        let arrowEnd = new Point();
        this.curveStartPoint = lineMindElementContent.curveStartPoint();
        this.curveEndPoint = lineMindElementContent.curveEndPoint();
        this.straightEndPoint = lineMindElementContent.straightEndPoint();
        
        let arrowH = (new UiUtil).getArrowWidth(this.curveEndPoint, this.straightEndPoint, this.lineWidth - 1);
        let moveSpace = arrowH / 2;
        arrowH = arrowH - moveSpace;
        if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
            this.straightEndPoint.x = this.straightEndPoint.x - moveSpace - 3;
            arrowStart = new Point(this.straightEndPoint.x - arrowH - 100, this.straightEndPoint.y);
            arrowEnd = new Point(this.straightEndPoint.x - arrowH, this.straightEndPoint.y);
        } else if (lineMindElementContent.orientation == LineOrientation.BOTTOM) {
            this.straightEndPoint.y = this.straightEndPoint.y - moveSpace - 3;
            arrowStart = new Point(this.straightEndPoint.x, this.straightEndPoint.y - arrowH - 100);
            arrowEnd = new Point(this.straightEndPoint.x, this.straightEndPoint.y - arrowH);
        } else if (lineMindElementContent.orientation == LineOrientation.TOP) {
            this.straightEndPoint.y = this.straightEndPoint.y + moveSpace + 3;
            arrowStart = new Point(this.straightEndPoint.x, this.straightEndPoint.y + arrowH + 100);
            arrowEnd = new Point(this.straightEndPoint.x, this.straightEndPoint.y + arrowH);
        } else if (lineMindElementContent.orientation == LineOrientation.LEFT) {
            this.straightEndPoint.x = this.straightEndPoint.x + moveSpace + 3;
            arrowStart = new Point(this.straightEndPoint.x + arrowH + 100, this.straightEndPoint.y);
            arrowEnd = new Point(this.straightEndPoint.x + arrowH, this.straightEndPoint.y);
        }

        let p = (new UiUtil).getArrowPoint(arrowStart, arrowEnd, this.lineWidth - 1);
        if (p.length != 3) {
            return;
        }
        linePath.moveTo(p[0].x, p[0].y);
        linePath.lineTo(p[1].x, p[1].y);
        linePath.lineTo(p[2].x, p[2].y);
        linePath.lineTo(p[0].x, p[0].y);
        //为了解决封闭图形不规则，起始点有缺口的问题。
        linePath.lineTo(p[1].x, p[1].y);

        if (this.arrow == null) {
            this.arrow = document.createElementNS('http://www.w3.org/2000/svg', "path")
            this.context.appendChild(this.arrow)
        }
        this.arrow.setAttribute("d", linePath.getLineRadius())
        this.arrow.setAttribute("stroke", Colors.getUiColor(this.color))
        this.arrow.setAttribute("stroke-width", this.lineWidth)
        this.arrow.setAttribute("fill", Colors.getUiColor(this.color))

    }

    createBackgroundView() {
    }

    drawCircular( point, radius, fillColor, lineWidth, lineColor) {
        let view = document.createElementNS('http://www.w3.org/2000/svg', "circle");
        this.context.appendChild(view)

        let fcolor = 'rgba(0,0,0,0)';
        let lcolor = 'rgba(0,0,0,0)';
        let lWidth = 0;
        if (Colors.isClear(fillColor)) {
            fcolor = Colors.getUiColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor);
            lWidth = 0.1;
        } else {
            fcolor = Colors.getUiColor(fillColor);
            lWidth = 0.1;
        }
        if (!Colors.isClear(lineColor) && lineWidth > 0) {
            lcolor = Colors.getUiColor(lineColor);
            lWidth = lineWidth;

        }
        view.setAttribute("cx", point.x)
        view.setAttribute("cy", point.y)
        view.setAttribute("r", radius)
        view.setAttribute("fill", fcolor)
        view.setAttribute("stroke-width", lWidth)
        view.setAttribute("stroke", lcolor)
        this.circularViewList.push(view)
    }

    isFreeLine() {
        return this.mindElementData.isFreeTreeNode && 
            (this.mindElementData.layout == NodeLayoutType.LAYOUT_RIGHT || this.mindElementData.layout == NodeLayoutType.LAYOUT_LEFT);
    }
}
export default SonSubjectLineView