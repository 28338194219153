// package mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent;

// import android.graphics.Point;

// import java.util.ArrayList;
// import java.util.List;

import MindElementContent from './MindElementContent.js';

import Point from '../../core/base/Point';

// import mind.yushu.com.mindmap.utils.Colors;
import Colors from '../../../utils/Colors';

// import mind.yushu.com.mindmap.viewmodel.datatype.ConnectLineType;
import ConnectLineType from '../../datatype/ConnectLineType';

// import mind.yushu.com.mindmap.viewmodel.datatype.LineLayout;
import LineLayout from '../../datatype/LineLayout';

// import mind.yushu.com.mindmap.viewmodel.datatype.LineOrientation;
import LineOrientation from '../../datatype/LineOrientation';
import Strings from '../../../utils/Strings.js';
import EncircleShapeType from '../../datatype/EncircleShapeType.js';
import IdGenerator from '../../core/base/IdGenerator.js';
import LinePointShape from '../../datatype/LinePointShape.js';
import EncircleTitleLayoutType from '../../datatype/EncircleTitleLayoutType.js';
import UiUtil from '../../../utils/UiUtil.js';

/**
 * Created by tony on 2019/12/23
 */

class LineElementContent extends MindElementContent {
    constructor(startPoint = {x:0,y:0},endPoint = {x:0,y:0},color = Colors.black60,targetId = -1){
        super();
        this.color = color;
        this.strokeColor = Colors.black60;
        this.lineWidth = 1.5;
        this.lineLayout = LineLayout.RIGHT_ANGLE_CORNER_LINE;
        this.encircleTitleType = EncircleTitleLayoutType.TOP_LEFT;
        this.cornerRadius = 4;
        this.orientation = LineOrientation.RIGHT;
        this.connectLineType = ConnectLineType.STRAIGHT_ARROW_LINE;

        this.startPointX = startPoint.x;
        this.startPointY = startPoint.y;
        this.endPointX = endPoint.x;
        this.endPointY = endPoint.y;

        this.curveStartPointX = 0;
        this.curveStartPointY = 0;
        this.curveEndPointX = 0;
        this.curveEndPointY = 0;
        this.straightEndPointX = 0;
        this.straightEndPointY = 0;

        this.targetId = targetId;
        if (targetId > -1) {
            this.targetIds = [targetId];
        } else {
            this.targetIds = [];
        }
        this.encircleNodesPoint = new Array();
        this.lineThicken = false;
        this.dottedLine = false;
        this.nodeConnectLineDottedLine = true;

        this.formPoint = new Array();

        this.startControlPointX = -1;
        this.startControlPointY = -1;

        this.endControlPointX = -1;
        this.endControlPointY = -1;
        this.rightAnglePoints = new Array();
        this.textContent;
        this.encircleShapeType = EncircleShapeType.LAYOUT_TRAPEZOID;
        this.backgroundUrl = "";
        this.padding = 0;
        this.text = "";
        this.startShape = LinePointShape.NORMAL;
        this.endShape = LinePointShape.ARROW;

        this.setLineElementContent(startPoint,endPoint,color,targetId)
    }



    //默认的钩子函数
    setLineElementContent(startPoint,endPoint,color,targetId, straightEndPoint) {
        if(arguments.length == 4){
            this.setStartPoint(startPoint);
            this.setEndPoint(endPoint);
            this.color = color;
            this.targetId = targetId;
            this.targetIds.push(targetId);
        }else if (arguments.length == 5) {
            this.setCurveStartPoint(startPoint);
            this.setCurveEndPoint(endPoint);
            this.setStraightEndPoint(straightEndPoint);
            this.color = color;
            this.targetId = targetId;
        }

        //某个判断条件，用来执行LineElementContentTwo的函数
    }
    //
    setLineElementContentTwo(curveStartPoint,curveEndPoint,straightEndPoint) {
        this.setCurveStartPoint(curveStartPoint);
        this.setCurveEndPoint(curveEndPoint);
        this.setStraightEndPoint(straightEndPoint);
    }

    setStartPoint(startPoint) {
        this.startPointX = startPoint.x;
        this.startPointY = startPoint.y;
    }

    setEndPoint(endPoint) {
        this.endPointX = endPoint.x;
        this.endPointY = endPoint.y;
    }

    setCurveStartPoint(curveStartPoint) {
        this.curveStartPointX = curveStartPoint.x;
        this.curveStartPointY = curveStartPoint.y;
    }

    setCurveEndPoint(curveEndPoint) {
        this.curveEndPointX = curveEndPoint.x;
        this.curveEndPointY = curveEndPoint.y;
    }

    setStraightEndPoint(straightEndPoint) {
        this.straightEndPointX = straightEndPoint.x;
        this.straightEndPointY = straightEndPoint.y;
    }

    startPoint() {
        return new Point(this.startPointX, this.startPointY);// .init(x: CGFloat(startPointX), y: CGFloat(startPointY))
    }

    endPoint() {
        return new Point(this.endPointX, this.endPointY);
    }

    curveStartPoint() {
        return new Point(this.curveStartPointX, this.curveStartPointY);
    }

    curveEndPoint() {
        return new Point(this.curveEndPointX, this.curveEndPointY);
    }

    straightEndPoint() {
        return new Point(this.straightEndPointX, this.straightEndPointY);
    }

    getStartControlPoint() {
        return new Point(this.startControlPointX, this.startControlPointY);
    }

    getEndControlPoint() {
        return new Point(this.endControlPointX, this.endControlPointY);
    }

    copy() {
        let data = new LineElementContent();
        data.x = this.x;
        data.y = this.y;
        data.width = this.width;
        data.height = this.height;

        data.color = this.color;
        data.strokeColor = this.strokeColor;
        data.lineWidth = this.lineWidth;
        data.lineLayout = this.lineLayout;
        data.cornerRadius = this.cornerRadius;
        data.orientation = this.orientation;
        data.text = this.text;

        data.startPointX = this.startPointX;
        data.startPointY = this.startPointY;
        data.endPointX = this.endPointX;
        data.endPointY = this.endPointY;

        data.curveStartPointX = this.curveStartPointX;
        data.curveStartPointY = this.curveStartPointY;
        data.curveEndPointX = this.curveEndPointX;
        data.curveEndPointY = this.curveEndPointY;
        data.straightEndPointX = this.straightEndPointX;
        data.straightEndPointY = this.straightEndPointY;
        data.startControlPointX = this.startControlPointX;
        data.startControlPointY = this.startControlPointY;
        data.endControlPointX = this.endControlPointX;
        data.endControlPointY = this.endControlPointY;
        data.connectLineType = this.connectLineType;
        data.encircleShapeType = this.encircleShapeType;

        data.targetId = this.targetId;
        data.lineThicken = this.lineThicken;

        data.dottedLine = this.dottedLine;
        data.nodeConnectLineDottedLine = this.nodeConnectLineDottedLine;
        data.encircleShapeType = this.encircleShapeType;
        data.backgroundUrl = this.backgroundUrl;
        data.padding = this.padding;
        data.startShape = this.startShape;
        data.endShape = this.endShape;
        data.encircleTitleType = this.encircleTitleType;

        data.targetIds = new Array();
        if (this.targetIds != null && this.targetIds.length > 0) {
            for (let index = 0; index < this.targetIds.length; index++) {
                let item = this.targetIds[index];
                data.targetIds.push(item);
            }
        }

        let encircleNodesPointCount = this.encircleNodesPoint.length;
        data.encircleNodesPoint = [];
        if (encircleNodesPointCount > 0) {
            for (let index = 0; index < encircleNodesPointCount; index++) {
                data.encircleNodesPoint.push(this.encircleNodesPoint[index]);
            }
        }

        if (this.textContent != null) {
            data.textContent = this.textContent.copy();
        }

        let rightAnglePointsCount = this.rightAnglePoints.length;
        data.rightAnglePoints = [];
        if (rightAnglePointsCount > 0) {
            for (let index = 0; index < rightAnglePointsCount; index++) {
                data.rightAnglePoints.push(this.rightAnglePoints[index]);
            }
        }

        let formPointCount = this.formPoint.length;
        data.formPoint = [];
        if (formPointCount > 0) {
            for (let index = 0; index < formPointCount; index++) {
                data.formPoint.push(this.formPoint[index]);
            }
        }
        return data;
    }

    stickStyle(data) {
        if (data == null) {
            return;
        }
        this.color = data.color;
        this.strokeColor = data.strokeColor;
        this.lineWidth = data.lineWidth;
        this.lineLayout = data.lineLayout;
        this.cornerRadius = data.cornerRadius;
        this.orientation = data.orientation;
        this.lineThicken = data.lineThicken;
        this.dottedLine = data.dottedLine;
        this.nodeConnectLineDottedLine = data.nodeConnectLineDottedLine;
        this.encircleShapeType = data.encircleShapeType;
        this.backgroundUrl = data.backgroundUrl;
        this.padding = data.padding;
        this.startShape = data.startShape;
        this.endShape = data.endShape;
        this.encircleTitleType = data.encircleTitleType;

        if (data.textContent != null && this.textContent != null) {
            this.textContent.textBold = data.textContent.textBold;
            this.textContent.textColor = data.textContent.textColor;
            this.textContent.textFontSize = data.textContent.textFontSize;
            this.textContent.textItalics = data.textContent.textItalics;
            this.textContent.textStrikethrough = data.textContent.textStrikethrough;
        }
    }

    stickColorStyle( data) {
        if (data == null) {
            return;
        }
        this.color = data.color;
        this.strokeColor = data.strokeColor;
        this.startShape = data.startShape;
        this.endShape = data.endShape;
        this.encircleTitleType = data.encircleTitleType;

        if (data.textContent != null && this.textContent != null) {
            this.textContent.textColor = data.textContent.textColor;
        }
    }

    isContainText() {
        return this.textContent != null &&  !(new Strings().isEmpty(this.textContent.text));
    }

    equal(data, ignorePoint = false, ignoreSize = false) {
        let result = false;
        if (!ignorePoint) {
            result = result || data.x != this.x;
            result = result || data.y != this.y;
        }
        if (!ignoreSize) {
            result = result || data.width != this.width;
            result = result || data.height != this.height;
        }
        if (!Colors.isClear(data.color) || !Colors.isClear(this.color)) {
            result = result || data.color != this.color;
        }
        if (!Colors.isClear(data.strokeColor) || !Colors.isClear(this.strokeColor)) {
            result = result || data.strokeColor != this.strokeColor;
        }
        result = result || data.lineWidth != this.lineWidth;
        result = result || data.lineLayout != this.lineLayout;
        result = result || data.cornerRadius != this.cornerRadius;
        result = result || data.orientation != this.orientation;
        result = result || data.text != this.text;
        
        if (!ignorePoint) {
            result = result || data.startPointX != this.startPointX;
            result = result || data.startPointY != this.startPointY;
            result = result || data.endPointX != this.endPointX;
            result = result || data.endPointY != this.endPointY;
            result = result || data.curveStartPointX != this.curveStartPointX;
            result = result || data.curveStartPointY != this.curveStartPointY;
            result = result || data.curveEndPointX != this.curveEndPointX;
            result = result || data.curveEndPointY != this.curveEndPointY;
            result = result || data.straightEndPointX != this.straightEndPointX;
            result = result || data.straightEndPointY != this.straightEndPointY;
        }
        
        result = result || data.targetId != this.targetId;
        result = result || data.lineThicken != this.lineThicken;
        result = result || data.dottedLine != this.dottedLine;
        result = result || data.nodeConnectLineDottedLine != this.nodeConnectLineDottedLine;
        result = result || data.encircleNodesPoint.length != this.encircleNodesPoint.length;
        result = result || data.formPoint.length != this.formPoint.length;
        result = result || data.startControlPointX != this.startControlPointX;
        result = result || data.startControlPointY != this.startControlPointY;
        result = result || data.endControlPointX != this.endControlPointX;
        result = result || data.endControlPointY != this.endControlPointY;
        result = result || data.rightAnglePoints.length != this.rightAnglePoints.length;
        result = result || data.connectLineType != this.connectLineType;
        result = result || data.encircleShapeType != this.encircleShapeType;
        result = result || data.padding != this.padding;
        result = result || data.backgroundUrl != this.backgroundUrl;
        result = result || data.targetIds.length != this.targetIds.length;
        result = result || data.startShape != this.startShape;
        result = result || data.endShape != this.endShape;
        result = result || data.encircleTitleType != this.encircleTitleType;

        if (!result && !ignorePoint) {
            let encircleNodesPointCount = this.encircleNodesPoint.length;
            if (encircleNodesPointCount > 0) {
                for ( let index = 0; index < encircleNodesPointCount; index++) {
                    result = result || data.encircleNodesPoint[index].x != this.encircleNodesPoint[index].x;
                    result = result || data.encircleNodesPoint[index].y != this.encircleNodesPoint[index].y;
                }
            }
        }

        result = result || data.textContent != null && this.textContent == null;
        result = result || data.textContent == null && this.textContent != null;
        if (!result && data.textContent != null && this.textContent != null) {
            result = result || !data.textContent.equal(this.textContent, ignorePoint, ignoreSize);
        }

        if (!result && !ignorePoint) {
            let rightAnglePointsCount = this.rightAnglePoints.length;
            if (rightAnglePointsCount > 0) {
                for ( let index = 0; index < rightAnglePointsCount; index++) {
                    result = result || data.rightAnglePoints[index].x != this.rightAnglePoints[index].x;
                    result = result || data.rightAnglePoints[index].y != this.rightAnglePoints[index].y;
                }
            }
        }

        if (!result && !ignorePoint) {
            let formPointCount = this.formPoint.length;
            if (formPointCount > 0) {
                for (let index = 0; index < formPointCount; index++) {
                    result = result || data.formPoint[index].startX != this.formPoint[index].startX;
                    result = result || data.formPoint[index].startY != this.formPoint[index].startY;
                    result = result || data.formPoint[index].endX != this.formPoint[index].endX;
                    result = result || data.formPoint[index].endY != this.formPoint[index].endY;
                }
            }
        }
        return !result;
    }

    checkStrokeColorSetTextColor() {
        if (this.textContent == null) {
            return
        }
        
        if (Colors.isDarkColor(this.strokeColor) &&
            Colors.isDarkColor(this.textContent.textColor)) {
            this.textContent.textColor = Colors.white;
        } else if (!Colors.isDarkColor(this.strokeColor) &&
                   !Colors.isDarkColor(this.textContent.textColor)) {
            this.textContent.textColor = Colors.black;
        }
    }
    
    checkColorSetTextColor(mindBGColor) {
        if (this.textContent == null) {
            return
        }
        
        if (Colors.isDarkColor(mindBGColor) &&
            Colors.isDarkColor(this.textContent.textColor)) {
            this.textContent.textColor = Colors.white
        } else if (!Colors.isDarkColor(mindBGColor) &&
                   !Colors.isDarkColor(this.textContent.textColor)) {
            this.textContent.textColor = Colors.black
        }
    }

    getLineStartMargin() {        
        let lineContent = this;
        let arrowH = 0;
        switch (lineContent.startShape) {
            case LinePointShape.NORMAL:
                arrowH = 0;
                break;
            case LinePointShape.ARROW:
            case LinePointShape.BAMBOO_ARROW:
                arrowH = new UiUtil().getArrowWidth(new Point( 0, 0), new Point( 0, 200), new UiUtil().dip2px(lineContent.lineWidth));
                break;
            case LinePointShape.SIMPLE_ARROW:
                arrowH = new UiUtil().dip2px(1);
                break;
            case LinePointShape.CIRCULAR:
            case LinePointShape.CIRCULAR_HOLLOW:
                arrowH = new UiUtil().dip2px(lineContent.lineWidth) * 2;
                break;
            case LinePointShape.CIRCULAR_RADIATION:
                arrowH = new UiUtil().dip2px(lineContent.lineWidth) * 4;
                break;
            case LinePointShape.T_SHAPED:
                arrowH = new UiUtil().dip2px(lineContent.lineWidth);
                break;
            case LinePointShape.DIAMOND:
                arrowH = new UiUtil().dip2px(lineContent.lineWidth) * 2;
                break;
        }
        return arrowH + new UiUtil().dip2px(3);
    }

    getLineEndMargin() {        
        let lineContent = this;
        if (lineContent == null) {
            return new UiUtil().dip2px(3);
        }
        let arrowH = 0;
        switch (lineContent.endShape) {
            case LinePointShape.NORMAL:
                arrowH = 0;
                break;
            case LinePointShape.ARROW:
            case LinePointShape.BAMBOO_ARROW:
                arrowH = new UiUtil().getArrowWidth(new Point( 0, 0), new Point( 0, 200), new UiUtil().dip2px(lineContent.lineWidth));
                break;
            case LinePointShape.SIMPLE_ARROW:
                arrowH = new UiUtil().dip2px(1);
                break;
            case LinePointShape.CIRCULAR:
            case LinePointShape.CIRCULAR_HOLLOW:
                arrowH = new UiUtil().dip2px(lineContent.lineWidth) * 2;
                break;
            case LinePointShape.CIRCULAR_RADIATION:
                arrowH = new UiUtil().dip2px(lineContent.lineWidth) * 4;
                break;
            case LinePointShape.T_SHAPED:
                arrowH = new UiUtil().dip2px(lineContent.lineWidth);
                break;
            case LinePointShape.DIAMOND:
                arrowH = new UiUtil().dip2px(lineContent.lineWidth) * 2;
                break;
        }
        return arrowH + new UiUtil().dip2px(3);
    }
}

export default LineElementContent