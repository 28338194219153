import Config from "../../../core/core/calcule/Config"
import EncircleNodesPointsCalculation from "../../../core/core/calcule/EncircleNodesPointsCalculation"
import NodesRectCalculation from "../../../core/core/calcule/NodesRectCalculation"
import Colors from "../../../utils/Colors"
import UiUtil from "../../../utils/UiUtil"
import IdGenerator from "../base/IdGenerator"
import LineColorMode from "../base/LineColorMode"
import LineOrientation from "../../datatype/LineOrientation"
import LineLayout from "../../datatype/LineLayout"
import MindElementShapeType from "../../datatype/MindElementShapeType"
import MindElementType from "../../datatype/MindElementType"
import NodeLayoutType from "../../datatype/NodeLayoutType"
import LineElementContent from "../../mindelementdata/mindcontent/LineElementContent"
import BaseLayout from "./BaseLayout"
import Point from "../../../viewmodel/core/base/Point"
import EncircleShapeType from "../../datatype/EncircleShapeType"
import RectsMergeCalculation from "./RectsMergeCalculation"
import LineMindTypeNode from "../../mindelementdata/LineMindTypeNode"
import EncircleTitleLayoutType from "../../datatype/EncircleTitleLayoutType"

class TreeTopLeftLayout extends BaseLayout {

    constructor() {
        super()
    }

    initConfig() {
        super.initConfig();
        this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee);
        this.SonNodeVerticalSpacee = this.addSonSubjectSpcaeVertical(Config.SonNodeVerticalSpacee);
        this.SonNodeHorizontalSpacee = this.addSonSubjectSpcaeHorizontal(Config.SonNodeHorizontalSpacee * 1.3);
        this.NodeHorizontalSpacee = this.addSubjectSpcaeHorizontal(Config.NodeHorizontalSpacee * 1.5);
    }


    onLayout(isChange) {
        if (this.rootTreeNode == null) {
            return;
        }
        this.setElementsPoint(isChange);
        this.hideRootFormLine();
        this.setGeneralizationElementsPoint(isChange);
        // this.setEncirclePoint();
        // this.setFloatExplainPoint(isChange);
    }


    setElementsPoint(isChange) {
        if ((!isChange || this.rootTreeNode.value.y <= -1 || this.rootTreeNode.value.x <= -1) && this.rootTreeNode.value.type == MindElementType.MAIN_SUBJECT) {
            let left = (Config.Mind_Width - new UiUtil().getScreenWidth()) / 2;
            let top = (Config.Mind_Height - new UiUtil().getScreenHeight()) / 2;
            this.rootTreeNode.value.y = (new UiUtil().getScreenHeight() - this.rootTreeNode.value.height) / 2 + top;
            this.rootTreeNode.value.x = (new UiUtil().getScreenWidth() - this.rootTreeNode.value.width) / 2 + left;
        }
        this.initConfig();
        this.setNodeElementsPoint(this.rootTreeNode, isChange);
        this.setNodeElementsLinePoint(this.rootTreeNode, isChange);
    }

    setEncirclePoint() {
        let encircleMindElementDataDict = this.encircleMindElementDataDict.keys();
        let encircleMindElementDataDictCount = encircleMindElementDataDict.length
        for (let index = 0; index < encircleMindElementDataDictCount; index++) {
            let mind = this.encircleMindElementDataDict.get(encircleMindElementDataDict[index]);

            let targetIds = new Array();
            if (mind.lineContent == null) {
                continue;
            }
            if (mind.lineContent.targetIds.isEmpty()) {
                targetIds.push(mind.lineContent.targetId > -1 ? mind.lineContent.targetId : mind.parentNodeId);
            } else {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                continue;
            }
            let textHeight = mind.lineContent != null && mind.lineContent.isContainText() ? mind.lineContent.textContent.height : 0;
            if (mind.lineContent != null && mind.lineContent.isContainText()) {
                mind.lineContent.textContent.x = new EncircleNodesPointsCalculation().space;
                mind.lineContent.textContent.y = 0;
            }
            if (targetIds.length == 1) {
                let node = this.getNodeById(targetIds[0]);
                if (node.isEmpty()) {
                    continue;
                }

                let padding = mind.lineContent == null ? 0 : mind.lineContent.padding;
                let type = mind.lineContent == null ? EncircleShapeType.LAYOUT_TRAPEZOID : mind.lineContent.encircleShapeType;

                let rect = new NodesRectCalculation(this.explainMindElementDataDict).calcule(node);
                mind.x = rect.x - new EncircleNodesPointsCalculation().space - padding;
                mind.y = rect.y - new EncircleNodesPointsCalculation().space - padding - textHeight;
                mind.width = rect.width() + new EncircleNodesPointsCalculation().space * 2 + padding * 2;
                mind.height = rect.height() + new EncircleNodesPointsCalculation().space * 2 + padding * 2 + textHeight;

                let encircleNodesPoint = new Array();
                encircleNodesPoint.push(new Point(0, 0));
                encircleNodesPoint.push(new Point(mind.width, 0));
                encircleNodesPoint.push(new Point(mind.width, mind.height));
                encircleNodesPoint.push(new Point(0, mind.height));
                encircleNodesPoint.push(new Point(0, 0));

                if (mind.lineContent == null) {
                    let lineContent = new LineElementContent(new Point(), new Point(), node.value.styleColor, node.value.id);
                    lineContent.encircleNodesPoint = encircleNodesPoint;
                    lineContent.strokeColor = node.value.styleColor;
                    mind.lineContent = lineContent;
                } else {
                    mind.lineContent.encircleNodesPoint = encircleNodesPoint;
                }
            } else {
                mind.lineContent.encircleShapeType = mind.lineContent.encircleShapeType == EncircleShapeType.LAYOUT_TRAPEZOID ?
                    EncircleShapeType.LAYOUT_RECTANGLE_2 : mind.lineContent.encircleShapeType;

                let rectList = new Array();
                let targetIdsCount = targetIds.length;
                for (let index = 0; index < targetIdsCount; index++) {
                    let id = targetIds[index];
                    let node = this.getNodeById(id);
                    if (node.isEmpty()) {
                        continue;
                    }

                    let rect = new NodesRectCalculation(this.explainMindElementDataDict).calcule(node);
                    rectList.push(rect);
                }
                if (rectList.isEmpty()) {
                    continue;
                }
                let padding = mind.lineContent == null ? 0 : mind.lineContent.padding;

                let rect = new RectsMergeCalculation().calcule(rectList);
                mind.x = rect.x - new EncircleNodesPointsCalculation().space - padding;
                mind.y = rect.y - new EncircleNodesPointsCalculation().space - padding - textHeight;
                mind.width = rect.width() + new EncircleNodesPointsCalculation().space * 2 + padding * 2;
                mind.height = rect.height() + new EncircleNodesPointsCalculation().space * 2 + padding * 2 + textHeight;

                mind.lineContent.encircleNodesPoint = new Array();
            }
            if (mind.lineContent != null && mind.lineContent.isContainText()) {
                if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_MIDDLE ||
                        mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_MIDDLE) {
                    mind.lineContent.textContent.x = (mind.width - mind.lineContent.textContent.width)/2;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_RIGHT ||
                        mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_RIGHT) {
                    mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width - new EncircleNodesPointsCalculation().space;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_FULL) {
                    mind.lineContent.textContent.width = mind.width;
                    mind.lineContent.textContent.x = 0;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_RIGHT) {
                    mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_LEFT) {
                    mind.lineContent.textContent.x = 0;
                    mind.lineContent.textContent.y = 0;
                } else {
                    mind.lineContent.textContent.x = new EncircleNodesPointsCalculation().space;
                    mind.lineContent.textContent.y = 0;
                }
            }
        }
    }

    setNodeElementsLinePoint(node, isChange) {
        this.setElementLine(node, isChange);
        if (node.children.length == 0) {
            return;
        }
        let childrenCount = node.children.length;
        for (let index = 0; index < childrenCount; index++) {
            this.setNodeElementsLinePoint(node.children[index], isChange);
        }
    }

    setNodeElementsPoint(node, isChange) {
        this.setDataPoint(node.value, isChange);
        if (node.children.length == 0 || this.isLayout(node.value)) {
            return;
        }
        if (this.isRootNode(node) || node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
            let childrenCount = node.children.length;
            for (let index = 0; index < childrenCount; index++) {
                this.setNodeElementsPoint(node.children[index], isChange);
            }
        } else {
            this.resetNodeLayout(node);
        }
    }

    setDataPoint(data, isChange) {
        let node = this.getNodeById(data.id);
        if (node.isEmpty() || node.value.isHidden || this.isLayout(node.value)) {
            return;
        }
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        let siblingsNodeDatas = this.getSiblingsMindElementDataById(data.id);
        this.pushCurrentLayoutNode(node);
        if (data.id == this.rootTreeNode.value.id) {
            return;
        } else {
            let allSiblingsNodesPosition = this.getNodeInNodesPosition(siblingsNodeDatas, data);
            if (allSiblingsNodesPosition == 0) {
                let parentNodeTop = parentNodeData.y - 
                (node.value.type == MindElementType.SON_SUBJECT ? this.SonNodeVerticalSpacee : this.NodeVerticalSpacee) - 
                this.getEncircleTopHeight(data) - this.getNodeFloatExplainHeight(parentNode);

                if (this.isRootNode(parentNode)) {
                    if (node.value.type == MindElementType.SON_SUBJECT) {
                        parentNodeTop = parentNodeData.y - this.SonNodeVerticalSpacee * 4 - this.getEncircleTopHeight(data) - this.getNodeFloatExplainHeight(parentNode);
                    } else {
                        parentNodeTop = parentNodeData.y - this.NodeVerticalSpacee * 2 - this.getEncircleTopHeight(data) - this.getNodeFloatExplainHeight(parentNode);
                    }
                } else {
                    parentNodeTop = parentNodeData.y - this.SonNodeVerticalSpacee * 4 - 
                        this.getEncircleTopHeight(data) - this.getNodeFloatExplainHeight(parentNode);
                }
                if (node.value.x <= 0 || node.value.y <= 0) {
                    node.value.x = 10000;
                    node.value.y = 10000;
                }
                let originalX = node.value.x;
                let originalY = node.value.y;
                let layout = this.resetNodeLayout(node);
                let nodeRect = new NodesRectCalculation().calcule(node);

                data.y = parentNodeTop - (nodeRect.y + nodeRect.height() - node.value.y - node.value.height) - this.getNodeHeight(node.value);

                if (this.isFromLeftRightLayout) {
                    if (parentNodeData.id == this.rootTreeNode.value.id) {
                        data.x = parentNodeData.x + parentNodeData.width - data.width - parentNodeData.width / 2 - 
                        (node.value.type == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee) / 2;
                    } else {
                        data.x = parentNodeData.x + parentNodeData.width - data.width - 
                        (node.value.type == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee);
                        if (((parentNodeData.x + parentNodeData.width) - (data.x + data.width)) - (node.value.type == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee) / 2 > parentNodeData.width / 2) {
                            data.x = parentNodeData.x + parentNodeData.width / 2 - (node.value.type == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee) / 2 - data.width;
                        }
                    }
                } else {
                    data.x = parentNodeData.x + parentNodeData.width - data.width - (this.isRootNode(parentNode) ? 
                    (node.value.type == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee) * 2 : 
                    (node.value.type == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee));
                    if (((parentNodeData.x + parentNodeData.width) - (data.x + data.width)) - (node.value.type == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee) / 2 > parentNodeData.width / 2) {
                        data.x = parentNodeData.x + parentNodeData.width / 2 - (node.value.type == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee) / 2 - data.width;
                    }
                }
                this.resetLayoutData(layout, node, node.value.x - originalX, node.value.y - originalY);
            } else {
                let perData = siblingsNodeDatas[allSiblingsNodesPosition - 1];
                let preNode = this.getNodeById(perData.id);
                if (node.value.x <= 0 || node.value.y <= 0) {
                    node.value.x = 10000;
                    node.value.y = 10000;
                }
                let originalX = node.value.x;
                let originalY = node.value.y;
                let layout = this.resetNodeLayout(node);

                let nodeRect = new NodesRectCalculation().calcule(node);
                let preRect = new NodesRectCalculation().calcule(preNode);

                data.y = preRect.y - (nodeRect.y + nodeRect.height() - node.value.y - node.value.height) - this.getNodeHeight(node.value) -
                    this.getEncircleTopHeight(perData) - this.getNodeFloatExplainHeight(preNode)-
                    (data.type == MindElementType.SUBJECT ? this.NodeVerticalSpacee : this.SonNodeVerticalSpacee);
                data.x = perData.x + perData.width - data.width;
                this.resetLayoutData(layout, node, node.value.x - originalX, node.value.y - originalY);
            }
        }
        this.setBorderThicken(node);
    }

    setElementLine(node, isChange) {
        let data = node.value;
        if (data.isHidden || this.isRootNode(node)) {
            return;
        }
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        if (!this.isGeneralizatioData(data) && !this.isRootNode(parentNode) && (parentNodeData.isEmpty() ||
            parentNodeData.layout != NodeLayoutType.LAYOUT_TOP_TREE_LEFT)) {
            return;
        }
        if (parentNodeData.id != IdGenerator.INVALID_ID) {
            let isCreateLineData = false;
            let lineData = this.getLineData(node);
            lineData.parentNodeId = parentNodeData.id;
            let lineLayout = this.getNodeLineLayout(node, lineData);
            if (lineData.id == IdGenerator.INVALID_ID) {
                lineData.id = IdGenerator.shared.getId();
                this.lineMindElementDataDict.put(lineData.id, lineData);
                this.textElementLineMindElementDataDict.put(node.value.id, lineData);
                isCreateLineData = true;
            }
            lineData.isHidden = false;
            lineData.layout = NodeLayoutType.LAYOUT_TOP_TREE_LEFT;
            if (data.type == MindElementType.SUBJECT || data.type == MindElementType.CONTENT_GENERALIZATION) {
                lineData.type = MindElementType.LINE;
                if (this.isUnderline(data)) {
                    lineData.y = data.y + data.height;
                } else {
                    lineData.y = data.y + data.height / 2;
                }
                lineData.x = data.x + data.width;

                if (lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_LINE ||
                    lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE) {
                    lineData.width = this.NodeHorizontalSpacee / 2;
                } else if (lineLayout == LineLayout.CURVE_LINE) {
                    lineData.width = this.NodeHorizontalSpacee / 2;
                } else {
                    lineData.width = this.NodeHorizontalSpacee / 2;
                }
                lineData.height = parentNodeData.y - lineData.y;

                let startPoint = new Point((lineData.width), (lineData.height));
                let endPoint = new Point(0, 0);

                if (lineData.lineContent == null) {
                    lineData.lineContent = new LineElementContent(startPoint, endPoint, 0xff333333, data.id);
                } else {
                    lineData.lineContent.setStartPoint(startPoint);
                    lineData.lineContent.setEndPoint(endPoint);
                }
                lineData.lineContent.orientation = LineOrientation.LEFT;
                lineData.lineContent.lineLayout = lineLayout;

            } else {
                lineData.type = MindElementType.SON_LINE;

                let curveStartPoint = new Point((lineData.width), (lineData.height));
                let curveEndPoint = new Point((data.width), 0);
                let straightEndPoint = new Point(0, 0);

                lineData.y = parentNodeData.y;
                lineData.x = data.x + data.width;

                if (parentNode.value.id == this.rootTreeNode.value.id && this.isFromLeftRightLayout) {
                    if (this.isUnderline(data)) {
                        lineData.height = data.y - lineData.y + data.height;
                        lineData.width = this.SonNodeHorizontalSpacee;
                    } else {
                        lineData.height = data.y - lineData.y + data.height / 2;
                        lineData.width = this.SonNodeHorizontalSpacee;
                    }
                } else {
                    if (this.isUnderline(data)) {
                        lineData.height = data.y - lineData.y + data.height;
                        lineData.width = this.SonNodeHorizontalSpacee;
                    } else {
                        lineData.height = data.y - lineData.y + data.height / 2;
                        lineData.width = this.SonNodeHorizontalSpacee;
                    }
                }
                if (lineLayout == LineLayout.STRAIGHT_LINE ||
                    lineLayout == LineLayout.STRAIGHT_LINE_2) {
                    curveStartPoint = new Point(lineData.width / 2, 0);
                    curveEndPoint = new Point(lineData.width / 4, lineData.height);
                    straightEndPoint = new Point(0, lineData.height);
                } else if (lineLayout == LineLayout.CURVE_LINE ||
                    lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                    lineLayout == LineLayout.STRAIGHT_CURVE_LINE ) {
                    curveStartPoint = new Point(lineData.width / 2, 0);
                    curveEndPoint = new Point(lineData.width / 6, lineData.height);
                    straightEndPoint = new Point(0, lineData.height);

                } else if (lineLayout == LineLayout.CURVE_LINE_2) {
                    curveStartPoint = new Point(lineData.width / 2, 0);
                    curveEndPoint = new Point(0, lineData.height);
                    straightEndPoint = new Point(0, lineData.height);
                } else {
                    curveStartPoint = new Point(lineData.width / 2, 0);
                    curveEndPoint = new Point(lineData.width / 2, lineData.height);
                    straightEndPoint = new Point(0, lineData.height);
                }

                if (lineData.lineContent == null) {
                    lineData.lineContent = new LineElementContent();
                    lineData.lineContent.setLineElementContent(curveStartPoint, curveEndPoint, 0x333333, data.id, straightEndPoint);
                } else {
                    lineData.lineContent.setCurveStartPoint(curveStartPoint);
                    lineData.lineContent.setCurveEndPoint(curveEndPoint);
                    lineData.lineContent.setStraightEndPoint(straightEndPoint);
                }
                lineData.lineContent.orientation = LineOrientation.LEFT;
                lineData.lineContent.lineLayout = lineLayout;
            }

            if (isCreateLineData) {
                // let lineColorMode = new LineColorMode();
                let lineNodeContent = lineData.lineContent;

                let referenceLine = this.getReferenceLine(node.value.id);
                if (!referenceLine.isEmpty()) {
                    if (this.isUnderline(data) && this.isBorderColor(data)) {
                        lineNodeContent.color = data.borderColor;
                    } else {
                        lineNodeContent.color = referenceLine.lineContent.color;
                    }
                    let grandpaNode = this.getNodeById(parentNode.value.parentNodeId);
                    if (referenceLine.lineContent.lineWidth == 0 &&
                            !grandpaNode.isEmpty() &&
                            grandpaNode.value.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
                        lineNodeContent.lineWidth = parentNode.value.borderWidth > 0 ? parentNode.value.borderWidth : 1.5;
                    } else {
                        lineNodeContent.lineWidth = referenceLine.lineContent.lineWidth;
                    }
                    lineNodeContent.dottedLine = referenceLine.lineContent.dottedLine;
                    lineData.isShadow = referenceLine.isShadow;
                } else {
                    lineNodeContent.color = node.value.styleColor;
                    if (this.isUnderline(data)) {
                        lineNodeContent.lineWidth = data.borderWidth;
                    } else {
                        lineNodeContent.lineWidth = this.mindMapStyleColor.getSubjectLineWidth();
                    }
                }
                if (lineData.type == MindElementType.LINE && !this.settingData.lineColor.isEmpty()) {
                    if (!this.originalRootTreeNode.isEmpty() && parentNode.value.id == this.originalRootTreeNode.value.id) {
                        parentNode = this.originalRootTreeNode;
                    }
                    // let parentNodeChildrenSize = parentNode.children.length;
                    // let lineColorLength = this.settingData.lineColor.length;
                    // for (let index = 0; index < parentNodeChildrenSize; index++) {
                    //     if (parentNode.children[index].value.id == node.value.id) {
                    //         let colorPoint = index % lineColorLength;
                    //         lineNodeContent.color = this.settingData.lineColor[colorPoint];
                    //         break;
                    //     }
                    // }
                    let colorPoint = (parentNode.children.length - 1) % this.settingData.lineColor.length;
                    lineNodeContent.color = this.settingData.lineColor[Math.abs(colorPoint)]
                }
                lineData.backgroundColor = Colors.clear;
                lineData.borderColor = Colors.clear;
                lineData.borderWidth = 0;
            }
            this.setLineThicken(lineData);
        }
    }

    setGeneralizationElementsPoint(isChange) {
        let list = this.getGeneralizationMindElementDatas();
        let listCount = list.length;
        for (let index = 0; index < listCount; index++) {
            let node = list[index];
            if (node.value.isHidden || !this.isIncludeGeneralizationInCurrentLayout(node) || this.isLayout(node.value)) {
                continue;
            }
            let mindElementData = node.value;
            let generalizationContent = mindElementData.generalizationContent;
            let top = 1000000;
            let left = 1000000;
            let bottom = 0;
            let right = 0;
            let targetNodes = []
            for (let targetIndex = 0; targetIndex < generalizationContent.targetIds.length; targetIndex++) {
                let targetNode = this.getNodeById(generalizationContent.targetIds[targetIndex]);

                if (targetNode.isEmpty() || targetNode.value.isHidden) {
                    mindElementData.isHidden = true;
                    continue
                }
                targetNodes.push(targetNode)
                for (let j = 0; j < index; j++) {
                    let checkNode = list[j];
                    if (checkNode.value.isHidden) {
                        continue
                    }
                    if (checkNode.value.generalizationContent.targetIds.length == 0) {
                        continue
                    }
                    let checkTargetNode = this.getNodeById(checkNode.value.generalizationContent.targetIds[0]);
                    if (!checkTargetNode.isEmpty() && targetNode.isChildNode(checkTargetNode)) {
                        targetNodes.push(checkNode)
                    }
                }
            }
            for (let targetIndex = 0; targetIndex < targetNodes.length; targetIndex++) {
                let targetNode = targetNodes[targetIndex];
                if (targetNode.isEmpty() || targetNode.value.isHidden) {
                    mindElementData.isHidden = true;
                    continue;
                }
                let targetLeft = targetNode.getNodeLeft();
                let targetTop = targetNode.getNodeTop();
                let targetBottom = targetNode.getNodeBottom();
                let targetRight = targetNode.getNodeRight();

                if (left > targetLeft) {
                    left = targetLeft;
                }
                if (targetTop < top) {
                    top = targetTop;
                }
                if (targetBottom > bottom) {
                    bottom = targetBottom;
                }
                if (targetRight > right) {
                    right = targetRight;
                }
            }
            if (top == 1000000 && right == 0) {
                continue;
            }
            generalizationContent.targetHeight = bottom - top;
            if (node.value.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                node.value.layout == NodeLayoutType.LAYOUT_LEFT ||
                node.value.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
                mindElementData.y = top / 2 + bottom / 2 - mindElementData.height / 2;
                mindElementData.x = left - mindElementData.width - Config.GeneralizationLineWidth - Config.GeneralizationSpacing * 2;
            } else if (node.value.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_RIGHT) {
                mindElementData.y = top / 2 + bottom / 2 - mindElementData.height / 2;
                mindElementData.x = right + Config.GeneralizationLineWidth + Config.GeneralizationSpacing * 2;
            }
            this.setGeneralizationElementLine(node, isChange);

            let childCount = node.children.length;
            for (let index = 0; index < childCount; index++) {
                let child = node.children[index]
                this.setNodeElementsPoint(child, isChange);
            }
            for (let index = 0; index < childCount; index++) {
                let child = node.children[index]
                this.setNodeElementsLinePoint(child, isChange);
            }
        }
    }

    setGeneralizationElementLine(node, isChange) {

        let mindElementData = node.value;
        let generalizationContent = mindElementData.generalizationContent;
        let isCreateLineData = false;
        let lineData = this.getGeneralizationLineData(node);
        if (!isChange || lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            isCreateLineData = true;
        }

        lineData.parentNodeId = mindElementData.id;
        lineData.type = MindElementType.LEFTBRACELINE;
        lineData.y = mindElementData.y + mindElementData.height / 2 - generalizationContent.targetHeight / 2;
        lineData.x = mindElementData.x + mindElementData.width + Config.GeneralizationSpacing;
        lineData.width = Config.GeneralizationLineWidth;
        lineData.height = generalizationContent.targetHeight;
        let generalizationParentNode = this.getNodeById(node.value.generalizationContent.targetIds[0]);

        let startPoint = new Point(0, 0);
        let endPoint = new Point(0, lineData.height);
        if (lineData.lineContent == null) {
            lineData.lineContent = new LineElementContent(startPoint, endPoint, mindElementData.borderColor, mindElementData.id);
        } else {
            lineData.lineContent.setStartPoint(startPoint);
            lineData.lineContent.setEndPoint(endPoint);
        }
        lineData.lineContent.orientation = LineOrientation.LEFT;
        this.generalizationLineMindElementDataDict.put(lineData.id, lineData);

        if (isCreateLineData) {
            lineData.backgroundColor = Colors.clear;
            lineData.borderWidth = 0;
            lineData.borderColor = Colors.clear;
            let referenceLine = this.getReferenceLine(node.value.id);
            if (!referenceLine.isEmpty()) {
                if (this.isUnderline(data) && this.isBorderColor(data)) {
                    lineNodeContent.color = data.borderColor;
                } else {
                    lineNodeContent.color = referenceLine.lineContent.color;
                }
                lineData.lineContent.lineWidth = referenceLine.lineContent.lineWidth == 0 ? 1.5 : referenceLine.lineContent.lineWidth;
                lineData.isShadow = referenceLine.isShadow;
            } else {
                let generalizationParentNodeLine = this.getLineData(generalizationParentNode);
                if (generalizationParentNodeLine.isEmpty()) {
                    lineData.lineContent.lineWidth = generalizationParentNode.value.borderWidth == 0 ? 1.5 : generalizationParentNode.value.borderWidth;
                    let color = generalizationParentNode.value.borderColor;
                    if (color == Colors.clear) {
                        color = generalizationParentNode.value.backgroundColor;
                    }
                    if (color == Colors.clear) {
                        color = Colors.black60;
                    }
                    lineData.lineContent.color = color;
                } else {
                    lineData.lineContent.lineWidth = generalizationParentNodeLine.lineContent.lineWidth;
                    lineData.lineContent.color = generalizationParentNodeLine.lineContent.color;
                }
            }
        }
    }

    getTreeHeight(node) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (this.dataHeightMap.containsKey(node.value.id)) {
            return this.dataHeightMap.get(node.value.id);
        }
        if (this.isRootNode(node) && node.children.length != this.rootTreeNode.children.length) {
            node = this.getNodeById(node.value.id);
        }
        let height = 0;
        if (!this.isRootNode(node) && node.value.layout != NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
            let layout = this.getNodeLayout(node);
            height = layout.getTreeHeight(node);
            layout.clearDatas();
            this.dataHeightMap.put(node.value.id, height);
            return height;
        }

        height = this.getNodeHeight(node.value) + this.getEncircleTopHeight(node.value) + this.getEncircleBottomHeight(node.value);
        height += this.getNodeFloatExplainHeight(node);
        let chlidHeight = 0;
        let nodeGeneralizationHeight = this.getNodeGeneralizationHeight(node);
        height = Math.max(height, nodeGeneralizationHeight);
        let childCount = node.children.length;
        for (let index = 0; index < childCount; index++) {
            let chlid = node.children[index];
            let verticalSpacee = chlid.value.type == MindElementType.SON_SUBJECT ? this.SonNodeVerticalSpacee : this.NodeVerticalSpacee;
            if (index == 0) {
                if (this.isRootNode(node)) {
                    if (node.value.type == MindElementType.SON_SUBJECT) {
                        verticalSpacee = this.SonNodeVerticalSpacee * 4;
                    } else {
                        verticalSpacee = this.NodeVerticalSpacee * 2;
                    }
                } else {
                    verticalSpacee = this.SonNodeVerticalSpacee * 4;
                }
            }
            
            if (!chlid.children.isEmpty()) {
                // chlidHeight += this.getTreeHeight(chlid) + (chlidHeight > 0 ? verticalSpacee : 0);
                chlidHeight += this.getTreeHeight(chlid) + verticalSpacee;
            } else {
                if (chlid.value.isHidden) {
                    continue;
                }
                let nodeHeight = this.getNodeHeight(chlid.value);
                let chlidGeneralizationHeight = this.getNodeGeneralizationHeight(chlid);
                nodeHeight = Math.max(nodeHeight, chlidGeneralizationHeight);
                // chlidHeight += nodeHeight + (chlidHeight > 0 ? verticalSpacee : 0);
                chlidHeight += nodeHeight + verticalSpacee;
            }
        }
        // let nodeHeight = chlidHeight > 0 ? (chlidHeight + height + this.NodeVerticalSpacee) : height;
        
        let treeHeight = chlidHeight + height;
        this.dataHeightMap.put(node.value.id, treeHeight);
        return treeHeight;

    }

    getTreeWidth(node, isContainSelfGeneralization = true) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden || this.rootTreeNode == null) {
            return 0;
        }
        let childCount = node.children.length;
        if (this.isRootNode(node) && childCount != this.rootTreeNode.children.length) {
            node = this.getNodeById(node.value.id);
        }
        childCount = node.children.length;
        if (!this.isRootNode(node) && node.value.layout != NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
            let layout = this.getNodeLayout(node);
            let width = layout.getTreeWidth(node, isContainSelfGeneralization);
            layout.clearDatas();
            return width;
        }

        let width = node.value.width;
        if (isContainSelfGeneralization) {
            let nodeGeneralizationWidth = this.getNodeGeneralizationWidth(node);
            nodeGeneralizationWidth = nodeGeneralizationWidth > 0 ? nodeGeneralizationWidth + Config.GeneralizationLineWidth + Config.GeneralizationSpacing * 2 : 0;
            width = width + nodeGeneralizationWidth;
        }

        let siblingsMaxWidth = 0;
        let childType = node.value.type;
        for (let index = 0; index < childCount; index++) {
            let chlid = node.children[index]
            let chlidWidth = 0;
            if (!chlid.children.isEmpty()) {
                let chlidSonWith = this.getTreeWidth(chlid);
                if (chlidSonWith > chlidWidth) {
                    chlidWidth = chlidSonWith;
                }
            } else {
                if (chlid.value.isHidden) {
                    continue;
                }
                let nodeidth = chlid.value.width;
                let chlidGeneralizationWidth = this.getNodeGeneralizationWidth(chlid);
                chlidGeneralizationWidth = chlidGeneralizationWidth > 0 ? chlidGeneralizationWidth + Config.GeneralizationLineWidth + Config.GeneralizationSpacing * 2 : 0;
                nodeidth = nodeidth + chlidGeneralizationWidth;
                chlidWidth += nodeidth;
            }
            if (siblingsMaxWidth < chlidWidth) {
                siblingsMaxWidth = chlidWidth;
            }
            childType = chlid.value.type;
        }

        if (this.isRootNode(node)) {
            if (this.isFromLeftRightLayout) {
                if (siblingsMaxWidth > (node.value.width / 2 - (childType == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee) / 2)) {
                    return node.value.width / 2 + (childType == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee) / 2 + siblingsMaxWidth;
                } else {
                    return width;
                }
            } else {
                if (siblingsMaxWidth > node.value.width - (childType == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee) * 2) {
                    return (childType == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee) * 2 + siblingsMaxWidth;
                } else {
                    return width;
                }
            }
        } else {
            if (siblingsMaxWidth > node.value.width - (childType == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee)) {
                return (childType == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee) + siblingsMaxWidth;
            } else {
                return width;
            }
        }
    }

    getEncircleTopHeight(data) {
        let encircleMindElementDataDict = this.encircleMindElementDataDict.keys();
        let encircleMindElementDataDictCount = encircleMindElementDataDict.length;
        for (let index = 0; index < encircleMindElementDataDictCount; index++) {
            let mind = this.encircleMindElementDataDict.get(encircleMindElementDataDict[index]);
            if (mind.lineContent == null) {
                return 0;
            }
            let node = this.getNodeById(mind.parentNodeId);
            if (node.isEmpty()) {
                continue;
            }
            let targetIds = new Array();
            if (mind.lineContent != null) {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                targetIds.push(mind.parentNodeId);
            }
            let targetIdsCount = targetIds.length;
            for (let index = 0; index < targetIdsCount; index++) {
                let id = targetIds[index];
                if (id == data.id) {
                    if (this.isTopNode(id, targetIds)) {
                        if (mind.lineContent.isContainText()) {
                            return new EncircleNodesPointsCalculation().space + mind.lineContent.textContent.height + mind.lineContent.padding;
                        } else {
                            return new EncircleNodesPointsCalculation().space + mind.lineContent.padding;
                        }
                    } else {
                        return 0;
                    }
                }
            }
        }
        return 0;
    }

    getEncircleBottomHeight(data) {
        let encircleMindElementDataDict = this.encircleMindElementDataDict.keys();
        let encircleMindElementDataDictCount = encircleMindElementDataDict.length;
        for (let index = 0; index < encircleMindElementDataDictCount; index++) {
            let mind = this.encircleMindElementDataDict.get(encircleMindElementDataDict[index]);
            if (mind.lineContent == null) {
                return 0;
            }
            let node = this.getNodeById(mind.parentNodeId);
            if (node.isEmpty()) {
                continue;
            }
            let targetIds = new Array();
            if (mind.lineContent != null) {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                targetIds.push(mind.parentNodeId);
            }
            let targetIdsCount = targetIds.length;
            for (let index = 0; index < targetIdsCount; index++) {
                let id = targetIds[index];
                if (id == data.id) {
                    if (this.isBottomNode(id, targetIds)) {
                        return new EncircleNodesPointsCalculation().space + mind.lineContent.padding;
                    } else {
                        return 0;
                    }
                }
            }
        }
        return 0;
    }

    getLayoutType() {
        return NodeLayoutType.LAYOUT_TOP_TREE_LEFT;
    }

    getFloatExplainLineOrientation() {
        return LineOrientation.BOTTOM;
    }

}

export default TreeTopLeftLayout