import zhCn from "../common/lang/zh-cn"
import UiUtil from "./UiUtil"

import Strings from "../common/lang/strings";
import getString from "../common/lang/language";
import HashMap from "../viewmodel/core/base/HashMap";
import MindElementShapeType from "../viewmodel/datatype/MindElementShapeType";

class CheckTextIsSelected {
    constructor() {
        this.nodeTextValues = [
            new UiUtil().getString(Strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic),
            new UiUtil().getString(Strings.Mind_Double_Bubble_Edit_Main_Characteristic),
            new UiUtil().getString(Strings.Mind_Organization_Edit_Main_Idea),
            new UiUtil().getString(Strings.Mind_Edit_Main_Idea),
            new UiUtil().getString(Strings.Mind_Edit_Free_Topic),
            new UiUtil().getString(Strings.Mind_Edit_Sub_Topic),
            new UiUtil().getString(Strings.Mind_Organization_Main_Topic),
            new UiUtil().getString(Strings.Mind_Edit_Main_Topic),
            new UiUtil().getString(Strings.Mind_Edit_Summary),
            new UiUtil().getString(Strings.Mind_Time_Edit_Main_Idea),
            new UiUtil().getString(Strings.Mind_Time_Edit_Head),
            new UiUtil().getString(Strings.Mind_Time_Edit_Title),
            new UiUtil().getString(Strings.Mind_Edit_Details),
            new UiUtil().getString(Strings.Mind_Edit_Title),
            new UiUtil().getString(Strings.Mind_Shape_Corner_Text),
            new UiUtil().getString(Strings.Mind_Shape_Start_Text),
            new UiUtil().getString(Strings.Mind_Shape_End_Text),
            new UiUtil().getString(Strings.Mind_Shape_Determine_Text),
            new UiUtil().getString(Strings.Mind_Shape_Ellipse_3D_Text),
            new UiUtil().getString(Strings.Mind_Shape_Cube_Text),
            new UiUtil().getString(Strings.Mind_Shape_Form_Text),
            new UiUtil().getString(Strings.Mind_Shape_Corner_Right_Text),
            new UiUtil().getString(Strings.Mind_Shape_Book_Text),
            new UiUtil().getString(Strings.Mind_Shape_Text),
        ]
        this.nodeShapeText = new HashMap()
        this.nodeShapeText.put(MindElementShapeType.Corner, new UiUtil().getString(Strings.Mind_Shape_Corner_Text));
        this.nodeShapeText.put(MindElementShapeType.Left_Right_Corner_Top_Bottom_Corner, new UiUtil().getString(Strings.Mind_Shape_Start_Text));
        this.nodeShapeText.put(MindElementShapeType.Ellipse, new UiUtil().getString(Strings.Mind_Shape_Start_Text));
        
        this.nodeShapeText.put(MindElementShapeType.Left_Right_Corner_Top_Bottom_Semicircle, new UiUtil().getString(Strings.Mind_Shape_End_Text));
        this.nodeShapeText.put(MindElementShapeType.Semicircle, new UiUtil().getString(Strings.Mind_Shape_End_Text));
        this.nodeShapeText.put(MindElementShapeType.Circular, new UiUtil().getString(Strings.Mind_Shape_End_Text));

        this.nodeShapeText.put(MindElementShapeType.Diamond, new UiUtil().getString(Strings.Mind_Shape_Determine_Text));
        this.nodeShapeText.put(MindElementShapeType.Ellipse_3D, new UiUtil().getString(Strings.Mind_Shape_Ellipse_3D_Text));
        this.nodeShapeText.put(MindElementShapeType.Cube, new UiUtil().getString(Strings.Mind_Shape_Cube_Text));
        this.nodeShapeText.put(MindElementShapeType.Form, new UiUtil().getString(Strings.Mind_Shape_Form_Text));
        this.nodeShapeText.put(MindElementShapeType.Corner_Right, new UiUtil().getString(Strings.Mind_Shape_Corner_Right_Text));
        this.nodeShapeText.put(MindElementShapeType.Book, new UiUtil().getString(Strings.Mind_Shape_Book_Text));
    }

    isSelected(text) {
        if (text == null || text == undefined) {
            return false;
        }
        let length = this.nodeTextValues.length

        for (let index = 0; index < length; index++) {
            let cell = this.nodeTextValues[index]
            if (text.length <= (cell.length + 3) &&
                    (cell == text || text.indexOf(cell + " ") == 0)) {
                return true;
            }
        }
        return false
    }

    getTextByShape(shape) {
        if (shape == null || shape == undefined) {
            return "";
        }
        let text = this.nodeShapeText.get(shape);
        if (text != null) {
            return text;
        }
        return ""
    }
}

export default new CheckTextIsSelected()