import Util from "../../../utils/Util";
import MindElementData from "../../../viewmodel/mindelementdata/MindElementData";
import MindCopyManager from "./MindCopyManager";
import MindElementDataDeserialize from "../dataformat/MindElementDataDeserialize";
import LineMindTypeNode from "../../../viewmodel/mindelementdata/LineMindTypeNode";
import MindElementNodeDeserialize from "../dataformat/MindElementNodeDeserialize";
import HashMap from "../../../viewmodel/core/base/HashMap";

class MindCopyNodeData {
    constructor() {
        this.copyNode = new LineMindTypeNode();
        this.generalizationLines = new Array(); //概要线
        this.generalizationNodes = new Array(); //概要节点
        this.encircles = new Array(); //包裹节点
        this.lineMindElementDataDict = new HashMap(); //主体线字典， Int为线ID data为line
        this.textElementLineMindElementDataDict = new HashMap(); //主体节点对应的线，Int为节点ID，data为line
        this.explainMindElementDataDict = new HashMap(); //注释，Int为节点ID，data为line
        this.setCopyNodeTime = -1;
    }

    toJSONString() {
        let nodeStr = JSON.stringify(this, function (key, val) {
            if (typeof val === "function") {
                return val + '';
            }
            return val
        });
        return nodeStr;
    }

    setData(copyNode,
            encircles,
            generalizationNodes,
            generalizationLines,
            lineMindElementDataDict,
            textElementLineMindElementDataDict,
            explainMindElementDataDict) {
        this.copyNode = copyNode.copy();
        this.encircles = encircles;
        this.generalizationLines = generalizationLines;
        this.generalizationNodes = generalizationNodes
        this.lineMindElementDataDict = lineMindElementDataDict
        this.textElementLineMindElementDataDict = textElementLineMindElementDataDict
        this.explainMindElementDataDict = explainMindElementDataDict;
        this.setCopyNodeTime = Util.getCurrentTime();
        this.save();
    }

    save() {
        let json = this.toJSONString();
        let key = MindCopyManager.CopyNodeDataKey;
        window.localStorage.setItem(key,json,this.setCopyNodeTime)
    }

    getData() {
        let storageKey = MindCopyManager.CopyNodeDataKey;
        let json = window.localStorage.getItem(storageKey)
        let mind = JSON.parse(json)
        if (mind == null || mind["copyNode"] == null || mind["setCopyNodeTime"] == null) {
            return null;
        }
        
        let mindData = MindElementNodeDeserialize.deserialize(mind["copyNode"]);
        if (mindData == null || mindData.isEmpty()) {
            return null;
        }
        this.setCopyNodeTime = mind["setCopyNodeTime"];
        for (var key in mind) {
            let keyValue = mind[key]
            this[key] = keyValue
            if (key == "generalizationLines") {
                let generalizationLine = new Array();
                let generalizationLineSize = keyValue.length
                for (let index = 0; index < generalizationLineSize; index++) {
                    generalizationLine.push(this.deserializationLineMindElementData(keyValue[index]))
                }
                this[key] = generalizationLine
            } else if (key == "generalizationNodes") {
                let generalizationNode = new Array();
                let generalizationNodeSize = keyValue.length
                for (let index = 0; index < generalizationNodeSize; index++) {
                    generalizationNode.push(this.deserializationLineMindTypeNode(keyValue[index]))
                }
                this[key] = generalizationNode
            } else if (key == "encircles") {
                let encircle = new Array();
                let encircleSize = keyValue.length
                for (let index = 0; index < encircleSize; index++) {
                    encircle.push(this.deserializationLineMindElementData(keyValue[index]))
                }
                this[key] = encircle
            } else if (key == "lineMindElementDataDict" || 
                        key == "textElementLineMindElementDataDict") {
                let dataMap = new HashMap()
                if (keyValue["mapObj"] != null) {
                    let mapObj = keyValue["mapObj"]
                    for (var mapKey in mapObj) {
                        if (mapKey.indexOf("mindmap_key_") > -1) {
                            let valueKey = mapKey;
                            let value = this.deserializationLineMindElementData(mapObj[mapKey])
                            if (value == null || value.isEmpty()) {
                                continue
                            }
                            dataMap.put(valueKey, value)
                        }
                    }
                }
                
                this[key] = dataMap
            } else if (key == "explainMindElementDataDict") {
                let explainData = new Array();
                let explainDataSize = keyValue.length
                for (let index = 0; index < explainDataSize; index++) {
                    explainData.push(this.deserializationLineMindElementData(keyValue[index]))
                }
                this[key] = explainData
            }
        }
        this.copyNode = mindData;

        return this.copyNode;
    }

    isHasCopyStyleData() {
        this.copyNode = this.getData();
        if(this.copyNode == undefined){
            return false;
        }
        return !this.copyNode.isEmpty() && this.setCopyNodeTime > 0 && Util.getCurrentTime() - this.setCopyNodeTime < MindCopyManager.TimeDifference;
    }

    deserializationLineMindTypeNode(nodeValue) {
        return MindElementNodeDeserialize.deserialize(nodeValue);
    }

    deserializationLineMindElementData(dataValue) {
        return MindElementDataDeserialize.deserialize(dataValue)
    }
}
export default MindCopyNodeData