// package mind.yushu.com.mindmap.viewmodel.core.base;

// import java.util.ArrayList;
// import java.util.List;

// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.IconElementContent;

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/15
 * Copyright(c) 2020 mindyushu.com
 */

class MindIconTypeData {

    constructor(title = "", data = new Array()) {
        this.title = title;
        this.cells = data;
    }

    MindIconTypeData(title) {
        this(title, new Array());
    }

    MindIconTypeData(title, data) {
        this.title = title;
        this.cells = data;
    }

    addCells(icons) {
        icons.forEach(cell => {
            this.cells.push(cell);
        });
    }
}
export default MindIconTypeData
