/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/24
 * Copyright(c) 2020 mindyushu.com
 */

let DeviceType = {

    UNKNOWN: 0,
    ANDROID: 1,
    IPHONE: 2,
    WEB: 3,
    WINDOWS: 4,
    IPAD: 5,
    ANDROID_PAD: 6,
    MAC: 7,
    LINUX: 8,
    WEIXIN_ANDROID_APP: 9,
    WEIXIN_IOS_APP:10

}
export default DeviceType
