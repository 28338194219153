import VideoStatus from "../../datatype/VideoStatus";
import IdGenerator from "../base/IdGenerator"

/**
 * ProjectName: MindMap
 * Created by tony on 8/14/21
 * Copyright(c) 2020 mindyushu.com
 */

class VideoStatusData {
    constructor() {
        this.status = VideoStatus.NORMAL;
        this.nodeId = IdGenerator.INVALID_ID; 
        this.url = "";
        this.totalSize = 0;
        this.uploadSize = 0;
    }
}
export default VideoStatusData