import Config from "../../../core/core/calcule/Config"
import EncircleNodesPointsCalculation from "../../../core/core/calcule/EncircleNodesPointsCalculation"
import NodesRectCalculation from "../../../core/core/calcule/NodesRectCalculation"
import MindElementCalculation from "../../../core/core/calcule/elementCalculation/MindElementCalculation"
import Colors from "../../../utils/Colors"
import UiUtil from "../../../utils/UiUtil"
import IdGenerator from "../base/IdGenerator"
import LineColorMode from "../base/LineColorMode"
import LineOrientation from "../../datatype/LineOrientation"
import LineLayout from "../../datatype/LineLayout"
import MindElementShapeType from "../../datatype/MindElementShapeType"
import MindElementType from "../../datatype/MindElementType"
import NodeLayoutType from "../../datatype/NodeLayoutType"
import LineElementContent from "../../mindelementdata/mindcontent/LineElementContent"
import BaseLayout from "./BaseLayout"
import Point from "../../../viewmodel/core/base/Point"
import EncircleShapeType from "../../datatype/EncircleShapeType"
import RectsMergeCalculation from "./RectsMergeCalculation"
import EncircleTitleLayoutType from "../../datatype/EncircleTitleLayoutType"

/**
 * ProjectName: MindMap
 * Created by tony on 7/6/21
 * Copyright(c) 2020 mindyushu.com
 */

class HorizontalRightLayout extends BaseLayout {
    constructor() {
        super();
        this.EncircleNodesPointsCalculation = new EncircleNodesPointsCalculation();
    }

    initConfig() {
        super.initConfig();
        this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee);
        if (this.rootTreeNode.children.length == 2) {
            this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee * 3);
        } else if (this.rootTreeNode.children.length == 3) {
            this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee * 1.5 + Config.NodeVerticalSpacee / 2);
        }
        this.SonNodeVerticalSpacee = this.addSonSubjectSpcaeVertical(Config.SonNodeVerticalSpacee);
        if (this.globalLayout == LineLayout.CURVE_LINE_2 ||
            this.globalLayout == LineLayout.CURVE_LINE ||
            this.globalLayout == LineLayout.CURVE_LINE_AVERAGE ||
            this.globalLayout == LineLayout.CURVE_LINE_CIRCULAR ||
            this.globalLayout == LineLayout.STRAIGHT_CURVE_LINE ||
            this.globalLayout == LineLayout.STRAIGHT_CURVE_LINE ||
            this.globalLayout == LineLayout.STRAIGHT_LINE ||
            this.globalLayout == LineLayout.STRAIGHT_LINE_2) {
            this.SonNodeHorizontalSpacee = this.addSonSubjectSpcaeVertical(Config.SonNodeHorizontalSpacee * 1.5);
            this.NodeHorizontalSpacee = this.addSonSubjectSpcaeVertical(Config.NodeHorizontalSpacee + this.getSubjectLineWidth());
        } else {
            this.SonNodeHorizontalSpacee = this.addSonSubjectSpcaeVertical(Config.SonNodeHorizontalSpacee);
            this.NodeHorizontalSpacee = this.addSonSubjectSpcaeVertical(Config.NodeHorizontalSpacee + this.getSubjectLineWidth());
        }
    }

    onLayout(isChange) {
        if (this.rootTreeNode == null) {
            return;
        }
        this.setElementsPoint(isChange);
        this.hideRootFormLine();
        this.setGeneralizationElementsPoint(isChange);
        // this.setEncirclePoint();
        // this.setFloatExplainPoint(isChange);
    }

    setElementsPoint(isChange) {
        if ((!isChange || this.rootTreeNode.value.y <= -1 || this.rootTreeNode.value.x <= -1) && this.rootTreeNode.value.type == MindElementType.MAIN_SUBJECT) {
            let left = (Config.Mind_Width - this.UiUtil.getScreenWidth()) / 2;
            let top = (Config.Mind_Height - this.UiUtil.getScreenHeight()) / 2;
            this.rootTreeNode.value.y = (this.UiUtil.getScreenHeight() - this.rootTreeNode.value.height) / 2 + top;
            this.rootTreeNode.value.x = 20 + left;
        }
        this.initConfig();
        this.setNodeElementsPoint(this.rootTreeNode, isChange);
        this.setNodeElementsLinePoint(this.rootTreeNode, isChange);
    }

    setEncirclePoint() {

        let encircleMindElementDataDictArr = this.encircleMindElementDataDict.keys();
        let encircleMindElementDataDictArrLnegth = encircleMindElementDataDictArr.length;
        for (let index = 0; index < encircleMindElementDataDictArrLnegth; index++) {
            let key = encircleMindElementDataDictArr[index];
            let mind = this.encircleMindElementDataDict.get(key);

            let targetIds = new Array();
            if (mind.lineContent == null) {
                continue;
            }
            if (mind.lineContent.targetIds.isEmpty()) {
                targetIds.push(mind.lineContent.targetId > -1 ? mind.lineContent.targetId : mind.parentNodeId);
            } else {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                continue;
            }
            let textHeight = mind.lineContent != null && mind.lineContent.isContainText() ? mind.lineContent.textContent.height : 0;

            if (mind.lineContent != null && mind.lineContent.isContainText()) {
                mind.lineContent.textContent.x = this.EncircleNodesPointsCalculation.space;
                mind.lineContent.textContent.y = 0;
            }
            if (targetIds.length == 1) {
                let node = this.getNodeById(targetIds[0]);
                if (node.isEmpty()) {
                    continue;
                }
                let padding = mind.lineContent == null ? 0 : mind.lineContent.padding;
                let type = mind.lineContent == null ? EncircleShapeType.LAYOUT_TRAPEZOID : mind.lineContent.encircleShapeType;
                let rect = new NodesRectCalculation(this.explainMindElementDataDict).calcule(node);
                mind.x = rect.x - this.EncircleNodesPointsCalculation.space - padding;
                mind.y = rect.y - this.EncircleNodesPointsCalculation.space - padding - textHeight;
                mind.width = rect.width() + this.EncircleNodesPointsCalculation.space * 2 + padding * 2;
                mind.height = rect.height() + this.EncircleNodesPointsCalculation.space * 2 + padding * 2 + textHeight;

                let points = new EncircleNodesPointsCalculation().calcule(node, type, padding);
                let encircleNodesPoint = new Array();

                points.forEach(point => {
                    encircleNodesPoint.push(new Point((point.x) - mind.x, (point.y) - mind.y));
                })
                if (mind.lineContent == null) {
                    let lineContent = new LineElementContent(new Point(), new Point(), node.value.styleColor, node.value.id);
                    lineContent.encircleNodesPoint = encircleNodesPoint;
                    lineContent.strokeColor = node.value.styleColor;
                    mind.lineContent = lineContent;
                } else {
                    mind.lineContent.encircleNodesPoint = encircleNodesPoint;
                }
            } else {
                mind.lineContent.encircleShapeType = mind.lineContent.encircleShapeType == EncircleShapeType.LAYOUT_TRAPEZOID ?
                    EncircleShapeType.LAYOUT_RECTANGLE_2 : mind.lineContent.encircleShapeType;

                let rectList = new Array();
                let targetIdsLen = targetIds.length;
                for (let index = 0; index < targetIdsLen; index++) {
                    let id = targetIds[index]
                    let node = this.getNodeById(id);
                    if (node.isEmpty()) {
                        continue;
                    }

                    let rect = new NodesRectCalculation(this.explainMindElementDataDict).calcule(node);
                    rectList.push(rect);
                }
                if (rectList.isEmpty()) {
                    continue;
                }
                let padding = mind.lineContent == null ? 0 : mind.lineContent.padding;

                let rect = new RectsMergeCalculation().calcule(rectList);
                mind.x = rect.x - this.EncircleNodesPointsCalculation.space - padding;
                mind.y = rect.y - this.EncircleNodesPointsCalculation.space - padding - textHeight;
                mind.width = rect.width() + this.EncircleNodesPointsCalculation.space * 2 + padding * 2;
                mind.height = rect.height() + this.EncircleNodesPointsCalculation.space * 2 + padding * 2 + textHeight;

                mind.lineContent.encircleNodesPoint = new Array();
            }
            if (mind.lineContent != null && mind.lineContent.isContainText()) {
                if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_MIDDLE ||
                        mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_MIDDLE) {
                    mind.lineContent.textContent.x = (mind.width - mind.lineContent.textContent.width)/2;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_RIGHT ||
                        mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_RIGHT) {
                    mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width - this.EncircleNodesPointsCalculation.space;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_FULL) {
                    mind.lineContent.textContent.width = mind.width;
                    mind.lineContent.textContent.x = 0;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_RIGHT) {
                    mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_LEFT) {
                    mind.lineContent.textContent.x = 0;
                    mind.lineContent.textContent.y = 0;
                } else {
                    mind.lineContent.textContent.x = this.EncircleNodesPointsCalculation.space;
                    mind.lineContent.textContent.y = 0;
                }
            }
        }
    }

    setNodeElementsPoint(node, isChange) {
        this.setDataPoint(node.value, isChange);
        if (node.children.length == 0 || this.isLayout(node.value)) {
            return;
        }
        if (this.isRootNode(node)) {
            for (let index = 0; index < node.children.length; index++) {
                this.setNodeElementsPoint(node.children[index], isChange);
            }
        } else {
            if (node.value.layout == NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_LEFT ||
                node.value.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                node.value.layout == NodeLayoutType.LAYOUT_FORM ||
                node.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL ||
                node.value.layout == NodeLayoutType.LAYOUT_LEFT_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_FISH_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_RIGHT_LEFT ||
                node.value.layout == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE ||
                node.value.layout == NodeLayoutType.LAYOUT_RADIATION_MAP ||
                node.value.layout == NodeLayoutType.LAYOUT_TRIANGLE) {
                node.value.layout = NodeLayoutType.LAYOUT_TREE_RIGHT;
            }
            this.resetNodeLayout(node);
        }
    }

    setNodeElementsLinePoint(node, isChange) {
        this.setElementLine(node, isChange);
        if (node.children.length == 0) {
            return;
        }
        for (let index = 0; index < node.children.length; index++) {
            this.setNodeElementsLinePoint(node.children[index], isChange);
        }
    }

    setDataPoint(data, isChange) { //用于计算坐标点。
        let node = this.getNodeById(data.id);
        if (node.isEmpty() || node.value.isHidden || this.isLayout(data)) {
            return;
        }
        this.pushCurrentLayoutNode(node);
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        let siblingsNodeDatas = this.getSiblingsMindElementDataById(data.id);
        if (this.isRootNode(node)) {
            return;
        } else {
            let allSiblingsNodesPosition = this.getNodeInNodesPosition(siblingsNodeDatas, data);
            if (this.isUnderline(data)) {
                data.y = parentNodeData.y + parentNodeData.height / 2 - data.height;
            } else {
                data.y = parentNodeData.y + parentNodeData.height / 2 - data.height / 2;
            }
            if (allSiblingsNodesPosition == 0) {
                if (node.value.x <= 0) {
                    node.value.x = 10000;
                }
                let originalX = node.value.x;
                let originalY = node.value.y;
                let layout = this.resetNodeLayout(node);
                let nodeRect = new NodesRectCalculation().calcule(node);
                data.x = parentNodeData.x + parentNodeData.width + this.NodeHorizontalSpacee + (nodeRect.width() - (nodeRect.x + nodeRect.width() - node.value.x));
                this.resetLayoutData(layout, node, node.value.x - originalX, node.value.y - originalY);
            } else {
                let preNode = this.getNodeById(siblingsNodeDatas[allSiblingsNodesPosition - 1].id);
                if (node.value.x <= 0) {
                    node.value.x = 10000;
                }
                let originalX = node.value.x;
                let originalY = node.value.y;
                let layout = this.resetNodeLayout(node);
                let nodeRect = new NodesRectCalculation().calcule(node);
                let preRect = new NodesRectCalculation().calcule(preNode);
                data.x = preRect.x + preRect.width() + this.NodeHorizontalSpacee + (nodeRect.width() - (nodeRect.x + nodeRect.width() - node.value.x));
                this.resetLayoutData(layout, node, node.value.x - originalX, node.value.y - originalY);
            }
            this.setBorderThicken(node);
        }
    }

    setElementLine(node, isChange) {
        let data = node.value;

        if (data.isHidden || this.isRootNode(node)) {
            return;
        }
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        if (!this.isGeneralizatioData(data) && !this.isRootNode(parentNode) && (parentNodeData.isEmpty() || parentNodeData.layout != NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT)) {
            return;
        }
        let isCreateLineData = false;
        let lineData = this.getLineData(node);
        lineData.parentNodeId = parentNodeData.id;
        let lineLayout = this.getNodeLineLayout(node, lineData);
        if (lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            this.lineMindElementDataDict.put(lineData.id, lineData);
            this.textElementLineMindElementDataDict.put(node.value.id, lineData);
            isCreateLineData = true;
        }
        let previewNode = parentNodeData;
        let parentNodeChildCount = parentNode.children.length;
        for (let index = 0; index < parentNodeChildCount; index++) {
            if (index == 0 && parentNode.children[index].value.id == data.id) {
                break;
            } else if (index > 0 && parentNode.children[index].value.id == data.id) {
                break;
            }
            previewNode = parentNode.children[index].value;
        }
        lineData.isHidden = false;
        lineData.layout = NodeLayoutType.LAYOUT_RIGHT;
        if (data.type == MindElementType.SUBJECT || data.type == MindElementType.CONTENT_GENERALIZATION) {
            lineData.type = MindElementType.LINE;
            if (this.isUnderline(data)) {
                lineData.y = data.y + data.height;
            } else {
                lineData.y = data.y + data.height / 2;
            }

            if (lineLayout == LineLayout.CURVE_LINE ||
                lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                lineLayout == LineLayout.STRAIGHT_CURVE_LINE ||
                lineLayout == LineLayout.CURVE_LINE_AVERAGE ||
                lineLayout == LineLayout.CURVE_LINE_2 ||
                lineLayout == LineLayout.STRAIGHT_LINE ||
                lineLayout == LineLayout.STRAIGHT_LINE_2 ||
                lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_LINE ||
                lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE) {
                lineData.x = previewNode.x + previewNode.width - this.getSubjectNodeLineSpace(node);
            } else {
                lineData.x = previewNode.x + previewNode.width;
            }
            lineData.width = data.x - lineData.x;
            lineData.height = parentNodeData.y - lineData.y + parentNodeData.height / 2;
            let startPoint = new Point(0, lineData.height);
            let endPoint = new Point(lineData.width, 0);
            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent(startPoint, endPoint, 0x333333, data.id);
            } else {
                lineData.lineContent.setStartPoint(startPoint);
                lineData.lineContent.setEndPoint(endPoint);
            }
            lineData.lineContent.orientation = LineOrientation.RIGHT;
            lineData.lineContent.lineLayout = lineLayout;
        } else {
            lineData.type = MindElementType.SON_LINE;

            if (this.isUnderline(parentNodeData)) {
                lineData.y = parentNodeData.y + parentNodeData.height;
                lineData.x = previewNode.x + previewNode.width;
            } else {
                lineData.y = parentNodeData.y + parentNodeData.height / 2;
                lineData.x = previewNode.x + previewNode.width;
            }

            let curveStartPoint = new Point(0, 0);
            let curveEndPoint = new Point(0, 0);
            let straightEndPoint = new Point(0, 0);

            if (this.isUnderline(data)) {
                lineData.height = data.y - lineData.y + data.height;
                lineData.width = data.x - lineData.x;
            } else {
                lineData.height = data.y - lineData.y + data.height / 2;
                lineData.width = data.x - lineData.x;
            }

            if (lineLayout == LineLayout.STRAIGHT_LINE ||
                lineLayout == LineLayout.STRAIGHT_LINE_2) {
                curveStartPoint = new Point(0, 0);
                curveEndPoint = new Point(lineData.width - lineData.width / 4, lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            } else if (lineLayout == LineLayout.CURVE_LINE ||
                lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                lineLayout == LineLayout.STRAIGHT_CURVE_LINE) {
                curveStartPoint = new Point(0, 0);
                curveEndPoint = new Point(lineData.width - lineData.width / 6, lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            } else if (lineLayout == LineLayout.CURVE_LINE_2 ||
                lineLayout == LineLayout.CURVE_LINE_AVERAGE) {
                curveStartPoint = new Point(0, 0);
                curveEndPoint = new Point(lineData.width, lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            } else {
                curveStartPoint = new Point(0, 0);
                curveEndPoint = new Point(lineData.width / 2, lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            }

            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent();
                lineData.lineContent.setLineElementContent(curveStartPoint, curveEndPoint, 0x333333, data.id, straightEndPoint);
            } else {
                lineData.lineContent.setCurveStartPoint(curveStartPoint);
                lineData.lineContent.setCurveEndPoint(curveEndPoint);
                lineData.lineContent.setStraightEndPoint(straightEndPoint);
            }

            lineData.lineContent.orientation = LineOrientation.RIGHT;
            lineData.lineContent.lineLayout = lineLayout;
        }

        if (isCreateLineData) {
            // let lineColorMode = new LineColorMode();
            let lineNodeContent = lineData.lineContent;

            let referenceLine = this.getReferenceLine(node.value.id);
            if (!referenceLine.isEmpty()) {
                if (this.isUnderline(data) && this.isBorderColor(data)) {
                    lineNodeContent.color = data.borderColor;
                } else {
                    lineNodeContent.color = referenceLine.lineContent.color;
                }
                lineNodeContent.lineWidth = referenceLine.lineContent.lineWidth;
                lineNodeContent.dottedLine = referenceLine.lineContent.dottedLine;
                lineData.isShadow = referenceLine.isShadow;
            } else {
                lineNodeContent.color = node.value.styleColor;
                if (this.isUnderline(data)) {
                    lineNodeContent.lineWidth = data.borderWidth;
                } else {
                    lineNodeContent.lineWidth = this.mindMapStyleColor.getSubjectLineWidth();
                }
            }
            if (lineData.type == MindElementType.LINE && !this.settingData.lineColor.isEmpty()) {
                // let parentNodeChildrenSize = parentNode.children.length;
                // let lineColorLength = this.settingData.lineColor.length;
                // for (let index = 0; index < parentNodeChildrenSize; index++) {
                //     if (parentNode.children[index].value.id == node.value.id) {
                //         let colorPoint = index % lineColorLength;
                //         lineNodeContent.color = this.settingData.lineColor[colorPoint];
                //         break;
                //     }
                // }
                let colorPoint = (parentNode.children.length - 1) % this.settingData.lineColor.length;
                lineNodeContent.color = this.settingData.lineColor[Math.abs(colorPoint)]
            }
            lineData.backgroundColor = Colors.transparent;
            lineData.borderColor = Colors.transparent;
            lineData.borderWidth = 0;
        } else {
            let childSize = parentNode.children.length;
            if (lineData.lineContent.lineWidth == 0 && childSize > 2 &&
                parentNode.children[0].value.id != data.id &&
                parentNode.children[childSize - 1].value.id != data.id) {
                let firstLine = this.getLineData(parentNode.children[0]);
                let lastLine = this.getLineData(parentNode.children[childSize - 1]);

                if (!firstLine.isEmpty() && !lastLine.isEmpty() &&
                    firstLine.lineContent.lineWidth > 0 &&
                    lastLine.lineContent.lineWidth > 0) {
                    lineData.lineContent.lineWidth = firstLine.lineContent.lineWidth;
                }
            }
        }
        lineData.lineContent.lineThicken = false;
    }

    getSubjectNodeLineSpace(node) {
        let margin = this.rootTreeNode.value.width / 4;
        if (this.globalLayout == LineLayout.CURVE_LINE_2) {
            margin = this.isFromLeftRightLayout ? this.rootTreeNode.value.width / 2 : this.rootTreeNode.value.width / 3 * 2;
        }
        let baseWidth = this.rootTreeNode.value.width / 2 - margin;
        let totalLineNumber = this.rootTreeNode.children.length;
        if (totalLineNumber == 0) {
            return 0;
        }
        let topNumber = 0;
        let nodeIndex = -1;
        for (let index = 0; index < totalLineNumber; index++) {
            let cell = this.rootTreeNode.children[index];
            if (cell.value.id == node.value.id) {
                nodeIndex = index;
            }
            if (node.value.type == MindElementType.SUBJECT && !this.isUnderline(node.value)) {
                if (cell.value.y + cell.value.height / 2 < this.rootTreeNode.value.y + this.rootTreeNode.value.height / 2) {
                    topNumber += 1;
                }
            } else {
                if (cell.value.y + cell.value.height < this.rootTreeNode.value.y + this.rootTreeNode.value.height / 2) {
                    topNumber += 1;
                }
            }
        }

        if (nodeIndex == -1) {
            return 0;
        }
        let originalTopNumber = topNumber;
        let originalBottomNumber = totalLineNumber - topNumber;

        let bottomNumber = originalBottomNumber;
        if (originalBottomNumber > originalTopNumber) {
            topNumber = bottomNumber;
            if (nodeIndex > originalTopNumber - 1) {
                nodeIndex = nodeIndex + (originalBottomNumber - originalTopNumber);
            }
        } else if (originalBottomNumber < topNumber) {
            bottomNumber = topNumber;
            if (nodeIndex > topNumber - 1) {
                nodeIndex = nodeIndex + topNumber - originalBottomNumber;
            }
        }
        if (nodeIndex < topNumber) {
            let cellWidth = baseWidth / (topNumber + 1);
            return (topNumber - nodeIndex) * cellWidth + margin;
        } else {
            let cellWidth = baseWidth / (bottomNumber + 1);
            return (nodeIndex - topNumber + 1) * cellWidth + margin;
        }
    }

    setGeneralizationElementsPoint(isChange) {
        let list = this.getGeneralizationMindElementDatas();
        let listCount = list.length;
        for (let index = 0; index < listCount; index++) {
            let node = list[index];
            if (node.value.isHidden ||
                !this.isIncludeGeneralizationInCurrentLayout(node) ||
                this.isLayout(node.value)) {
                continue;
            }
            let mindElementData = node.value;
            let generalizationContent = mindElementData.generalizationContent;
            let top = 1000000;
            let bottom = 0;
            let right = 0;

            for (let index = 0; index < generalizationContent.targetIds.length; index++) {
                let targetNode = this.getNodeById(generalizationContent.targetIds[index]);
                if (targetNode.isEmpty() || targetNode.value.isHidden) {
                    mindElementData.isHidden = true;
                    continue;
                }
                let targetLeft = targetNode.getNodeLeft();
                let targetTop = targetNode.getNodeTop();
                let targetBottom = targetNode.getNodeBottom();
                let targetRight = targetNode.getNodeRight();

                if (targetTop < top) {
                    top = targetTop;
                }
                if (targetBottom > bottom) {
                    bottom = targetBottom;
                }
                if (targetRight > right) {
                    right = targetRight;
                }
            }

            if (top == 1000000 && right == 0) {
                continue;
            }
            generalizationContent.targetHeight = bottom - top;
            mindElementData.y = top / 2 + bottom / 2 - mindElementData.height / 2;
            mindElementData.x = right + Config.GeneralizationLineWidth + Config.GeneralizationSpacing * 2;
            this.setGeneralizationElementLine(node, isChange);
            node.children.forEach(child => {
                this.setNodeElementsPoint(child, isChange);
            })
            node.children.forEach(child => {
                this.setNodeElementsLinePoint(child, isChange);
            })
        }
    }

    setGeneralizationElementLine(node, isChange) {
        let mindElementData = node.value;
        let generalizationContent = mindElementData.generalizationContent;
        let isCreateLineData = false;
        let lineData = this.getGeneralizationLineData(node);
        if (!isChange || lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            isCreateLineData = true;
        }
        let generalizationParentNode = this.getNodeById(node.value.generalizationContent.targetIds[0]);
        lineData.parentNodeId = mindElementData.id;
        lineData.type = MindElementType.LEFTBRACELINE;
        lineData.y = mindElementData.y + mindElementData.height / 2 - generalizationContent.targetHeight / 2;
        lineData.x = mindElementData.x - Config.GeneralizationLineWidth - Config.GeneralizationSpacing;
        lineData.width = Config.GeneralizationLineWidth;
        lineData.height = generalizationContent.targetHeight;


        let startPoint = new Point(0, 0);
        let endPoint = new Point(0, lineData.height);
        if (lineData.lineContent == null) {
            lineData.lineContent = new LineElementContent(startPoint, endPoint, mindElementData.borderColor, mindElementData.id);
        } else {
            lineData.lineContent.setStartPoint(startPoint);
            lineData.lineContent.setEndPoint(endPoint);
        }
        lineData.lineContent.orientation = LineOrientation.RIGHT;
        this.generalizationLineMindElementDataDict.put(lineData.id, lineData);

        if (isCreateLineData) {
            lineData.backgroundColor = Colors.clear;
            lineData.borderWidth = 0;
            lineData.borderColor = Colors.clear;
            let referenceLine = this.getReferenceLine(node.value.id);
            if (!referenceLine.isEmpty()) {
                if (this.isUnderline(data) && this.isBorderColor(data)) {
                    lineNodeContent.color = data.borderColor;
                } else {
                    lineNodeContent.color = referenceLine.lineContent.color;
                }
                lineData.lineContent.lineWidth = referenceLine.lineContent.lineWidth == 0 ? 1.5 : referenceLine.lineContent.lineWidth;
                lineData.isShadow = referenceLine.isShadow;
            } else {
                let generalizationParentNodeLine = this.getLineData(generalizationParentNode);
                if (generalizationParentNodeLine.isEmpty()) {
                    lineData.lineContent.lineWidth = generalizationParentNode.value.borderWidth == 0 ? 1.5 : generalizationParentNode.value.borderWidth;
                    let color = generalizationParentNode.value.borderColor;
                    if (color == Colors.clear) {
                        color = generalizationParentNode.value.backgroundColor;
                    }
                    if (color == Colors.clear) {
                        color = Colors.black60;
                    }
                    lineData.lineContent.color = color;
                } else {
                    lineData.lineContent.lineWidth = generalizationParentNodeLine.lineContent.lineWidth == 0 ? 1.5 : generalizationParentNodeLine.lineContent.lineWidth;
                    lineData.lineContent.color = generalizationParentNodeLine.lineContent.color;
                }
            }
        }
    }

    getTreeHeight(node) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (this.dataHeightMap.containsKey(node.value.id)) {
            return this.dataHeightMap.get(node.value.id);
        }
        if (this.isRootNode(node) && node.children.length != this.rootTreeNode.children.length) {
            node = this.getNodeById(node.value.id);
        }
        let hieght = 0;
        if (this.isRootNode(node) || node.value.layout == NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT) {
            let topHeight = this.getTreeTopHeight(node);
            let bottomHeight = this.getTreeBottomHeight(node);
            hieght = topHeight + bottomHeight;
        } else {
            let layout = this.getNodeLayout(node);
            hieght = layout.getTreeHeight(node);
            layout.clearDatas();
        }
        this.dataHeightMap.put(node.value.id, hieght);
        return hieght;
    }

    getTreeTopHeight(node) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (this.dataTopHeightMap.containsKey(node.value.id)) {
            return this.dataTopHeightMap.get(node.value.id);
        }
        if (this.isRootNode(node) && node.children.length != this.rootTreeNode.children.length) {
            node = this.getNodeById(node.value.id);
        }
        let nodeSelfTop = 0;
        if (((node.value.type == MindElementType.SUBJECT || node.value.type == MindElementType.SON_SUBJECT) && !this.isUnderline(node.value)) ||
            node.value.type == MindElementType.MAIN_SUBJECT) {
            nodeSelfTop = node.value.height / 2;
        } else {
            nodeSelfTop = node.value.height;
        }
        let nodeEncircleTopHeight = this.getEncircleTopHeight(node.value);
        nodeSelfTop += nodeEncircleTopHeight;

        let floatExplainData = this.getNodeExplain(node);
        if (!floatExplainData.isEmpty()) {
            nodeSelfTop += floatExplainData.height + Config.NodeFloatExplainSpace;
            if (!Colors.isClear(floatExplainData.borderColor)) {
                nodeSelfTop += floatExplainData.borderWidth
            }
        }

        if (node.children.isEmpty() || node.children[0].value.isHidden) {
            this.dataTopHeightMap.put(node.value.id, nodeSelfTop);
            return nodeSelfTop;
        }
        if (!this.isRootNode(node) &&
            node.value.layout != NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT) {
            let layoutTop = nodeSelfTop;
            if (node.value.layout == NodeLayoutType.LAYOUT_BOTTOM ||
                node.value.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                node.value.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_FORM ||
                node.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                layoutTop = nodeSelfTop;
            } else if (node.value.layout == NodeLayoutType.LAYOUT_TOP ||
                node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                let layout = this.getNodeLayout(node);
                layoutTop = layout.getTreeHeight(node) - node.value.height + nodeSelfTop;
                layout.clearDatas();
            } else if (node.value.layout == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                let layout = this.getNodeLayout(node);
                layoutTop = layout.getTreeTopHeight(node);
                layout.clearDatas();
            } else if (node.value.layout == NodeLayoutType.LAYOUT_FISH_LEFT) {
                let layout = this.getNodeLayout(node);
                layoutTop = layout.getTreeTopHeight(node);
                layout.clearDatas();
            } else if (node.value.layout == NodeLayoutType.LAYOUT_TRIANGLE) {
                let layout = this.getNodeLayout(node);
                layoutTop = layout.getTreeHeight(node);
                layout.clearDatas();
            }
            this.dataTopHeightMap.put(node.value.id, layoutTop);
            return layoutTop;
        }
        let childCount = node.children.length;
        let firstTop = this.getTreeTopHeight(node.children[0]) + nodeEncircleTopHeight;
        if (childCount == 1) {
            let topHeight = Math.max(nodeSelfTop, firstTop);
            this.dataTopHeightMap.put(node.value.id, topHeight);
            return topHeight;
        }

        let childTotle = nodeEncircleTopHeight + this.getEncircleBottomHeight(node.value);
        for (let index = 0; index < childCount; index++) {
            let cell = node.children[index];
            let height = this.getTreeHeight(cell);
            childTotle += height;
            if (index < childCount - 1) {
                childTotle += cell.value.type == MindElementType.SON_SUBJECT ? this.SonNodeVerticalSpacee : this.NodeVerticalSpacee;
            }
        }
        let lastBottom = this.getTreeBottomHeight(node.children[childCount - 1]);
        let height = (childTotle - firstTop - lastBottom) / 2 + firstTop;
        let topHeight = Math.max(height, nodeSelfTop);
        this.dataTopHeightMap.put(node.value.id, topHeight);
        return topHeight;
    }

    getTreeBottomHeight(node) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (this.dataBottomHeightMap.containsKey(node.value.id)) {
            return this.dataBottomHeightMap.get(node.value.id);
        }
        if (this.isRootNode(node) && node.children.length != this.rootTreeNode.children.length) {
            node = this.getNodeById(node.value.id);
        }
        let nodeSelfBottom = 0;
        if (((node.value.type == MindElementType.SUBJECT || node.value.type == MindElementType.SON_SUBJECT) && !this.isUnderline(node.value)) || node.value.type == MindElementType.MAIN_SUBJECT) {
            nodeSelfBottom = node.value.height / 2 + this.getNodeExplainHeight(node.value) + Config.NodeExplainTop;
        } else {
            nodeSelfBottom = this.getNodeExplainHeight(node.value) + Config.NodeExplainTop;
        }
        let nodeEncircleBottomHeight = this.getEncircleBottomHeight(node.value);
        nodeSelfBottom += nodeEncircleBottomHeight;
        if (node.children.isEmpty() || node.children[0].value.isHidden) {
            this.dataBottomHeightMap.put(node.value.id, nodeSelfBottom);
            return nodeSelfBottom;
        }
        if (!this.isRootNode(node) &&
            node.value.layout != NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT) {
            let layoutBottom = nodeSelfBottom;
            if (node.value.layout == NodeLayoutType.LAYOUT_BOTTOM ||
                node.value.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                node.value.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_FORM ||
                node.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                let layout = this.getNodeLayout(node);
                layoutBottom = layout.getTreeHeight(node) - node.value.height + nodeSelfBottom;
                layout.clearDatas();
            } else if (node.value.layout == NodeLayoutType.LAYOUT_TOP ||
                node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                layoutBottom = nodeSelfBottom;
            } else if (node.value.layout == NodeLayoutType.LAYOUT_TRIANGLE) {
                layoutBottom = 0;
            }
            this.dataBottomHeightMap.put(node.value.id, layoutBottom);
            return layoutBottom;
        }

        let childCount = node.children.length;
        let lastBottom = this.getTreeBottomHeight(node.children[childCount - 1]) + nodeEncircleBottomHeight;
        if (childCount == 1) {
            let bottomHeight = Math.max(lastBottom, nodeSelfBottom);
            this.dataBottomHeightMap.put(node.value.id, bottomHeight);
            return bottomHeight;
        }
        let childTotle = this.getEncircleTopHeight(node.value) + nodeEncircleBottomHeight;
        for (let index = 0; index < childCount; index++) {
            let cell = node.children[index];
            let height = this.getTreeHeight(cell);
            childTotle += height;
            if (index < childCount - 1) {
                childTotle += cell.value.type == MindElementType.SON_SUBJECT ? this.SonNodeVerticalSpacee : this.NodeVerticalSpacee;
            }
        }
        let firstTop = this.getTreeTopHeight(node.children[0]);
        let height = (childTotle - firstTop - lastBottom) / 2 + lastBottom;

        let bottomHeight = Math.max(height, nodeSelfBottom);
        this.dataBottomHeightMap.put(node.value.id, bottomHeight);
        return bottomHeight;
    }

    getTreeWidth(node) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (!this.isRootNode(node) && node.value.layout != NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT) {
            let layout = this.getNodeLayout(node);
            let width = layout.getTreeWidth(node);
            layout.clearDatas();
            return width;
        }
        if (this.isRootNode(node) && node.children.length != this.rootTreeNode.children.length) {
            node = this.getNodeById(node.value.id);
        }
        let width = node.value.width;
        let nodeGeneralizationWidth = this.getNodeGeneralizationWidth(node);
        nodeGeneralizationWidth = nodeGeneralizationWidth > 0 ? nodeGeneralizationWidth + Config.GeneralizationLineWidth + Config.GeneralizationSpacing * 2 : 0;
        width = width + nodeGeneralizationWidth;
        let siblingsMaxWidth = 0;

        let nodeChildrenLen = node.children;
        for (let index = 0; index < nodeChildrenLen; index++) {
            let child = node.children[index];
            let chlidWidth = 0;
            let horizontalSpacee = chlid.value.type == MindElementType.SON_SUBJECT ? this.SonNodeHorizontalSpacee : this.NodeHorizontalSpacee;
            if (!chlid.children.isEmpty()) {
                chlidWidth += this.getTreeWidth(chlid) + horizontalSpacee;
            } else {
                if (chlid.value.isHidden) {
                    continue;
                }
                let nodeidth = chlid.value.width;
                let chlidGeneralizationWidth = this.getNodeGeneralizationWidth(chlid);
                chlidGeneralizationWidth = chlidGeneralizationWidth > 0 ? chlidGeneralizationWidth + Config.GeneralizationLineWidth + Config.GeneralizationSpacing * 2 : 0;
                nodeidth = nodeidth + chlidGeneralizationWidth;
                chlidWidth += nodeidth + horizontalSpacee;
            }
            if (siblingsMaxWidth < chlidWidth) {
                siblingsMaxWidth = chlidWidth;
            }
        }
        return siblingsMaxWidth + width;
    }

    getSiblingsNodesHeight(nodes) {
        if (nodes.length == 0) {
            return 0;
        }
        let height = this.getTreeHeight(this.getNodeById(nodes[0].id));

        if (nodes.length > 1) {
            for (let index = 1; index < nodes.length; index++) {
                let data = nodes[index];
                if (data.isHidden) {
                    continue;
                }
                height += this.getTreeHeight(this.getNodeById(data.id)) + (data.type == MindElementType.SON_SUBJECT ? this.SonNodeVerticalSpacee : this.NodeVerticalSpacee);
            }
        }
        return height;
    }

    getEncircleTopHeight(data) {
        let encircleMindElementDataDictArr = this.encircleMindElementDataDict.keys();
        let encircleMindElementDataDictArrLen = encircleMindElementDataDictArr.length;

        for (let index = 0; index < encircleMindElementDataDictArrLen; index++) {
            let key = encircleMindElementDataDictArr[index];
            let mind = this.encircleMindElementDataDict.get(key);
            if (mind.lineContent == null) {
                return 0;
            }
            let node = this.getNodeById(mind.parentNodeId);
            if (node.isEmpty()) {
                continue;
            }
            let targetIds = new Array();
            if (mind.lineContent != null) {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                targetIds.push(mind.parentNodeId);
            }
            for (let index = 0; index < targetIds.length; index++) {
                let id = targetIds[index];
                if (id == data.id) {
                    if (this.isTopNode(id, targetIds)) {
                        if (mind.lineContent.isContainText()) {
                            return this.EncircleNodesPointsCalculation.space + mind.lineContent.textContent.height + mind.lineContent.padding;
                        } else {
                            return this.EncircleNodesPointsCalculation.space + mind.lineContent.padding;
                        }
                    } else {
                        return 0;
                    }
                }
            }
        }
        return 0;
    }

    getEncircleBottomHeight(data) {
        let encircleMindElementDataDictArr = this.encircleMindElementDataDict.keys();
        let encircleMindElementDataDictArrLen = encircleMindElementDataDictArr.length;

        for (let index = 0; index < encircleMindElementDataDictArrLen; index++) {
            let key = encircleMindElementDataDictArr[index];
            let mind = this.encircleMindElementDataDict.get(key);
            if (mind.lineContent == null) {
                return 0;
            }
            let node = this.getNodeById(mind.parentNodeId);
            if (node.isEmpty()) {
                continue;
            }
            let targetIds = new Array();
            if (mind.lineContent != null) {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                targetIds.push(mind.parentNodeId);
            }
            let targetIdsLen = targetIds.length;
            for (let index = 0; index < targetIdsLen; index++) {
                let id = targetIds[index];
                if (id == data.id) {
                    if (this.isBottomNode(id, targetIds)) {
                        return this.EncircleNodesPointsCalculation.space + mind.lineContent.padding;
                    } else {
                        return 0;
                    }
                }
            }
        }
        return 0;
    }

    getLayoutType() {
        return NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT;
    }

    getFloatExplainLineOrientation() {
        return LineOrientation.BOTTOM;
    }
}


export default HorizontalRightLayout