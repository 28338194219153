class checkBoxView {
    constructor(contentViewSvg, scrollView) {
        this.contentViewSvg = contentViewSvg
        this.isCheckBox = false,
        this.checkBoxParent,
        this.checkBoxChildFrame,
        this.checkBoxChildLine,
        this.scrollView = scrollView
        this.checkBoxData
    }

    initCheckBox() {
        if (this.checkBoxParent != undefined) {  //避免有时候没有把元素删干净。
            this.checkBoxParent.remove();
        }
        this.checkBoxParent = document.createElementNS('http://www.w3.org/2000/svg', 'g')
        this.checkBoxChildFrame = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        // console.log('this.checkBoxParent', this.checkBoxParent);
        // console.log('this.checkBoxChildFrame', this.checkBoxChildFrame);
        this.checkBoxParent.appendChild(this.checkBoxChildFrame)
        this.contentViewSvg.appendChild(this.checkBoxParent)
    }

    computedNowPoint(dargeEvent, startX, startY, clickPointX, clickPointY) {
        let moveX = (dargeEvent.pageX - startX) * (1 / this.scrollView.scale);
        let moveY = (dargeEvent.pageY - startY) * (1 / this.scrollView.scale);
        let obj = {};
        obj.x = clickPointX 
        obj.y = clickPointY
        if (moveX < 0) {
            obj.x = clickPointX + moveX;
        }
        if (moveY < 0) {
            obj.y = clickPointY + moveY;
        }
        obj.width = Math.abs(moveX);
        obj.height = Math.abs(moveY);
        this.checkBoxData = obj
        this.showEncircleLinePitchOnNode(obj);
    }

    showEncircleLinePitchOnNode(data) {
        this.checkBoxParent.style.width = data.width + "px"
        this.checkBoxParent.style.height = data.height + "px"
        this.checkBoxChildFrame.setAttribute('width', data.width)
        this.checkBoxChildFrame.setAttribute('height', data.height)
        this.checkBoxParent.style.transform = "translate(" + data.x + "px," + data.y + "px)"
        this.checkBoxChildFrame.setAttribute('fill', 'rgb(135,206,235,0.8)');
        this.checkBoxChildFrame.setAttribute('stroke', 'rgb(135,206,235,1)');
        this.checkBoxChildFrame.setAttribute('stroke-width', '2px');
        this.checkBoxChildFrame.setAttribute('fill-opacity', '0.1');
        this.checkBoxChildFrame.setAttribute('stroke-opacity', '1');
    }
}
export default checkBoxView