<template>
  <!-- icon弹框 -->
  <div
    id="menu-icon-dialog"
    ref="menuIconDialog"
    class="complieRightMenuIconDialog"
    @mouseenter="$emit('continuousShow')"
    @mouseleave="$emit('hideIconMenu')"
  >
    <div class="MenuIcon-box">
      <div ref="menuIconBigbox" class="menuIcon-priority-bigbox">
        <div class="menuIcon-priority-box" id="menu-icon-box">
          <template v-if="showIconType == 2 && !isMacComputed && tintColor != 1">
            <div
              v-for="(item, index) in MenuIcons"
              :key="index"
              class="menuIcon-priority-content"
              @click="scheduleAdds(item.key, $event)"
            >
              <img v-if="isMac && !isEelctron" :src="item.head" />
              <img v-else :src="item.head" :class="menuIconClass" alt />
              <!-- <img v-else :src="item.head" :style="{filter:'drop-shadow(#' + tintColor.toString(16) + ' ' + (-22) + 'px ' + 0 + 'px)','margin-left':'22px'} " /> -->
            </div>
          </template>
          <template v-else>
            <div
              v-for="(item, index) in MenuIcons"
              :key="index"
              class="menuIcon-priority-content priority_animation"
            >
              <img
                :src="item.head"
                @click="priorityAdd(item.key)"
                ref="priority"
              />
            </div>
          </template>
        </div>
        <div class="line"></div>
        <div class="remove" @click="checkMenuIconElement">
          {{ getString(strings.Remove_Any) }}
        </div>
      </div>
    </div>

    <!-- <div class="MenuIcon-box" v-else-if="showIconType == 1">
      <div ref="menuIconBigbox"  class="menuIcon-priority-bigbox">
        <div class="menuIcon-priority-box">
          <div
            v-for="(item, index) in MenuIcons"
            :key="index"
            class="menuIcon-priority-content"
            @click="scheduleAdds(item.key, $event)"
          >
            <img v-if="isMac && !isEelctron" :src="item.head" />
            <img v-else :src="item.head" :class="menuIconClass" alt />
          </div>
        </div>
        <div class="line"></div>
        <div class="remove" @click="checkMenuIconElement">
          {{ getString(strings.Remove_Any) }}
        </div>
      </div>
    </div>
    <div class="MenuIcon-box" v-else-if="showIconType == 2">
      <div ref="menuIconBigbox"  class="menuIcon-priority-bigbox">
        <div class="menuIcon-priority-box">
          <div
            v-for="(item, index) in MenuIcons"
            :key="index"
            class="menuIcon-priority-content"
            @click="priorityAdd(item.key)"
          >
            <img :src="item.head" />
          </div>
        </div>
        <div class="line"></div>
        <div class="remove" @click="checkMenuIconElement">
          {{ getString(strings.Remove_Any) }}
        </div>
      </div>
    </div>
    <div class="MenuIcon-box" v-else-if="showIconType == 3">
      <div ref="menuIconBigbox"  class="menuIcon-priority-bigbox">
        <div class="menuIcon-priority-box">
          <div
            v-for="(item, index) in MenuIcons"
            :key="index"
            class="menuIcon-priority-content"
            @click="scheduleAdds(item.key, $event)"
          >
            <img v-if="isMac && !isEelctron" :src="item.head" />
            <img v-else :src="item.head" :class="menuIconClass" alt />
    </div>
        </div>
        <div class="line"></div>
        <div class="remove" @click="checkMenuIconElement">
          {{ getString(strings.Remove_Any) }}
        </div>
      </div>
    </div>-->
  </div>
</template> 

<script>
import { mapMutations } from "vuex";
import MindIconsManager from "../../../viewmodel/core/tools/MindIconsManager";
import strings from "../../../common/lang/strings";
import getString from "../../../common/lang/language";
import UiUtil from "../../../utils/UiUtil";
import { iconChangedColor } from "../../../assets/js/GlobalVariable.js";

export default {
  components: {},
  props: [
    "mind",
    "showIconType",
    "showIconX",
    "showIconY",
    "tintColor",
    "rightMenuAccout",
  ],
  data() {
    return {
      isMac: false,
      isEelctron: false,
      switchIcon: null,
      MenuIcons: [],
      viewModel: null,
      strings,
      fullWidth: document.documentElement.clientWidth,
      boxWidth: null,
      isMacComputed:false
    };
  },
  created() {
    //是否是mac
    this.isMacComputed = this.$tools.isMacComputed();
    this.isMac = this.$tools.isMacSafari();
    this.isEelctron = this.$tools.isElectron();
    //切换颜色
    this.menuIconClass = {
      16711680: "menuIcon-icon-data-red",
      2724553: "menuIcon-icon-data-blue",
      14092573: "menuIcon-icon-data-red",
      16215840: "menuIcon-icon-data-yellow",
      2724553: "menuIcon-icon-data-blue",
      14737632: "menuIcon-icon-data-white",

      [iconChangedColor.red]: "menuIcon-icon-data-red",
      [iconChangedColor.yellow]: "menuIcon-icon-data-yellow",
      [iconChangedColor.green]: "menuIcon-icon-data-green",
      [iconChangedColor.blue]: "menuIcon-icon-data-blue",
    }[this.tintColor];
  },
  mounted() {
    this.MenuIconPerSon();
    let screenWidth = new UiUtil().getScreenWidth();
    let screenHeight = new UiUtil().getScreenHeight();
    // console.log(screenWidth,screenHeight);
    if (this.viewModel == null) {
      this.viewModel = this.$store.state.editMindmap;
    }
    this.$nextTick(() => {
      let element = this.$refs.menuIconDialog;
      let root = element.parentNode
      let rootScrollLeft = root.scrollLeft
      let rootScrollTop = root.scrollTop
      let menuIconBigBox = this.$refs.menuIconBigbox;
      if (element && menuIconBigBox) {
        this.boxWidth = menuIconBigBox.clientWidth;
        if (this.mind) {
          const iconDialogSite = JSON.parse(
            localStorage.getItem("iconDialogSite")
          );
          if (!!iconDialogSite) {
            element.style.left =
              iconDialogSite.left < 0
                ? 0
                : (iconDialogSite.left > screenWidth - this.boxWidth
                    ? screenWidth - this.boxWidth
                    : iconDialogSite.left) + "px";
            element.style.top =
              iconDialogSite.top < 0
                ? 0
                : (iconDialogSite.top > screenHeight - 50
                    ? screenHeight - 50
                    : iconDialogSite.top) + "px";
          } else {
            let elementLeft = 0;
            if (this.showIconX < 260 + rootScrollLeft ) {
              elementLeft = this.showIconX - 10;
            } else if (this.showIconX > this.fullWidth - 700 + rootScrollLeft && this.rightMenuAccout) {
              elementLeft = this.fullWidth - 330 - this.boxWidth + rootScrollLeft;
            } else if (this.showIconX > this.fullWidth - 100 + rootScrollLeft) {
              elementLeft = this.fullWidth - 10 - this.boxWidth + rootScrollLeft;
            } else {
              elementLeft = this.showIconX - this.boxWidth / 2 - 10;
            }
            if (this.fullWidth + rootScrollLeft - this.showIconX < this.boxWidth / 2) {
              elementLeft = elementLeft - (this.fullWidth + rootScrollLeft - this.showIconX) - 10;
            }
            element.style.left = elementLeft + "px";
            element.style.top =
              (this.showIconY < 140 + rootScrollTop ? 200 + rootScrollTop : this.showIconY) - 140 + "px";
          }
        } else {
          element.style.left = this.showIconX - 30 + "px";
          element.style.top =
            (this.showIconY < 60 + rootScrollTop ? 120 + rootScrollTop : this.showIconY) - 60 + "px";
        }
      }

      this.scrollInit();
    });

    /*element.onmousedown = (ev) => {
      let disX = ev.clientX - element.offsetLeft;
			let disY = ev.clientY - element.offsetTop;
      const iconDialogSite ={top:0,left:0};
      element.onmousemove = function (e) {
        //计算需要移动的距离
        iconDialogSite.left = e.clientX - disX;
        iconDialogSite.top = e.clientY - disY;
        //console.log("disX:"+disX,"disY: "+disY,"t:"+t,"P: "+P);
        if (iconDialogSite.left >= 0 && iconDialogSite.left <= screenWidth - element.offsetWidth) {
          element.style.left = iconDialogSite.left + 'px';
          localStorage.setItem('iconDialogSite',JSON.stringify(iconDialogSite))
        } 
			  //移动当前元素
        if (iconDialogSite.top >= 0 && iconDialogSite.top <= screenHeight - element.offsetHeight ) {
          element.style.top = iconDialogSite.top + 'px';
          localStorage.setItem('iconDialogSite',JSON.stringify(iconDialogSite))
        } 
        // console.log("window.innerWidth "+window.innerWidth,"window.innerHeight "+window.innerHeight);

      }
       //鼠标松开时，注销鼠标事件，停止元素拖拽。
      element.onmouseup = function (e) {
          element.onmousemove = null;
          element.onmouseup = null;
      };
    }*/
  },
  methods: {
    getString(i) {
      return getString(i);
    },
    MenuIconPerSon() {
      let perSonIcon = MindIconsManager.mindIcons[this.showIconType];

      for (var i = 0; i < perSonIcon.cells.length; i++) {
        let perSonList = perSonIcon.cells[i].key;
        this.MenuIcons.push({
          head: require("../../../../static/img/mapContent/assets/icons/" +
            perSonList +
            ".imageset/" +
            perSonList +
            ".png"),
          key: perSonList,
          title: perSonList.title,
        });
      }

      // if (this.showIconType > 1) {
      //   this.$refs.menuIconBigbox.style.width = "384px";
      //   if (this.showIconType == 2) {
      //     this.MenuIcons.length = 7; //删除数据
      //   }
      // }
    },
    priorityAdd(key) {
      this.viewModel.addIconToNode(key);
      // console.log(key)
    },
    scheduleAdds(key, e) {
      if (this.isMac && !this.isEelctron) {
        let color = (r, g, b) => {
          return ((r << 16) | (g << 8) | b).toString(16);
        };

        this.viewModel.addIconToNode(key, parseInt("0x" + color(255, 0, 0)));
      } else {
        // let styleName = e.target.children[0].className;

        // let colorStyle = window.getComputedStyle(
        //   document.querySelector("." + styleName),
        //   null
        // ).filter;
        // let colorStryleAccout = colorStyle.substring(
        //   colorStyle.indexOf("rgb(") + 4,
        //   colorStyle.indexOf(")")
        // );
        // let r = colorStryleAccout.substring(
        //   colorStryleAccout.indexOf(""),
        //   colorStryleAccout.indexOf(",")
        // );
        // let g = colorStryleAccout.substring(
        //   colorStryleAccout.indexOf(",") + 1,
        //   colorStryleAccout.lastIndexOf(",")
        // );
        // let b = colorStryleAccout.substring(
        //   colorStryleAccout.lastIndexOf(",") + 1,
        //   colorStryleAccout.lastIndexOf("")
        // );
        // let color = (r, g, b) => {
        //   return ((r << 16) | (g << 8) | b).toString(16);
        // };

        // console.log(parseInt("0x" + color(r, g, b)));
        // this.viewModel.addIconToNode(
        //   key,
        //   parseInt("0x" + color(r, g, b)),
        //   this.tintColor
        // );
        this.viewModel.addIconToNode(key);
      }
    },
    checkMenuIconElement(name) {
      let obj = {};
      obj.name = name;
      this.$emit("checkMenuIconElement", obj);
    },
    // 鼠标滚轮控制滚动条横向滚动
    scrollInit() {
      // 获取要绑定事件的元素
      const scrollDiv = document.getElementById("menu-icon-box");
      // document.addEventListener('DOMMouseScroll', handler, false)
      // 添加滚轮滚动监听事件，一般是用下面的方法，上面的是火狐的写法
      if (scrollDiv) {
        scrollDiv.addEventListener("mousewheel", handler, false);
      }
      function handler(event) {
        event.preventDefault();
        // 获取滚动方向
        const detail = event.wheelDelta || event.detail;
        // 定义滚动方向
        const moveForwardStep = 1;
        const moveBackStep = -1;
        // 定义滚动距离
        let step = 0;
        // 判断滚动方向,这里的range可以改，代表滚动幅度，也就是说滚动幅度是自定义的
        const range = 42;
        if (detail < 0) {
          step = moveForwardStep * range;
        } else {
          step = moveBackStep * range;
        }
        // 对需要滚动的元素进行滚动操作
        event.currentTarget.scrollLeft += step;
        // console.log(scrollDiv,event.currentTarget);
      }
    },
  },
  watch: {
    "$store.state.editMindmap"(newval, olval) {
      this.viewModel = newval;
    },
  },
};
</script>
<style scoped>
.complieRightMenuIconDialog {
  position: absolute;
  z-index: 5001;
}

/**优先级 */
.menuIcon-priority-bigbox {
  /* width: 468px; */
  height: 50px;
  padding: 12px 20px;
  background: #ffffff;
  opacity: 1;
  border-radius: 8px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
  position: relative;
  overflow: hidden;
  display: flex;
}
.menuIcon-priority-bigbox span {
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  margin: 0;
}

.menuIcon-priority-bigbox-height {
  /* width: 100%; */
  height: 72px;
  /* background-color: rgba(255,0,0,1);
  margin-bottom: 72px; */
}
.position-loading-title {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
}
.menuIcon-priority-box-position {
  width: 100%;
  height: 30px;
  position: absolute;
  top: 30px;
}
.MenuIcon-priority-right-position {
  position: absolute;
  right: 0;
  top: 4px;
  z-index: 2;
}

.menuIcon-priority-box {
  max-width: 420px;
  white-space: nowrap;
  overflow: auto;
  /*display: flex;
  flex-wrap: wrap;
  justify-content: space-between;  */
}
.menuIcon-priority-box::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.menuIcon-priority-box img {
  width: 26px;
}
.menuIcon-priority-content {
  /* width: 22px; */
  display: inline-block;
  margin-right: 16px;
  cursor: pointer;
  overflow: hidden;
}

.remove {
  float: left;
  margin-top: 3px;
  width: 30px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #333333;
  opacity: 1;
  cursor: pointer;
}
.line {
  float: left;
  margin-top: 8px;
  margin-right: 16px;
  width: 2px;
  height: 10px;
  background: #dedede;
  opacity: 1;
  border-radius: 13px;
}
.menuIcon-icon-data-red {
  /* filter: drop-shadow(#ff0000 0 26px); */
  filter: drop-shadow(#ff0000 0 26px);
  transform: translateY(0px);
  /* margin-left: 20px; */
}
.menuIcon-icon-data-yellow {
  /* filter: drop-shadow(#fcb238 0 26px); */
  filter: drop-shadow(#f76f20 0 26px);
  transform: translateY(0px);
}
.menuIcon-icon-data-green {
  filter: drop-shadow(#1a9c84 0 26px);
  transform: translateY(0px);
}
.menuIcon-icon-data-blue {
  /* filter: drop-shadow(#2992c9 0 26px); */
  filter: drop-shadow(#2992c9 0 26px);
  transform: translateY(0px);
}
.menuIcon-icon-data-white {
  filter: drop-shadow(#e0e0e0 0 26px);
  transform: translateY(0px);
}
</style>
