<template>
  <div
    id="elementIconMenuList"
    class="element-icon-menu-list"
    v-show="
      showLinkMenu ||
      showElementIconMenuList ||
      abstractMenu ||
      showAssMapLink ||
      moreMenu ||
      showResourceModel
    "
    :style="{ left: newMenuX + 'px', top: newMenuY + 'px' }"
  >
    <div class="element-menu-children-list">
      <template v-if="showLinkMenu">
        <div
          v-for="each in linkMenuData"
          :key="each.id"
          @click="checkMenuIconElement(each.name)"
        >
          <div class="each-menu">
            <img :src="each.iconUrl" class="each-icon" alt="" />
            <div class="each-icon-name" :title="getString(each.name)">
              {{ getString(each.name) }}
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="showElementIconMenuList">
        <div
          v-for="each in iconMenuData"
          :key="each.id"
          @click="checkMenuIconElement(each.name)"
        >
          <div class="each-menu">
            <img :src="each.iconUrl" class="each-icon" alt="" />
            <div class="each-icon-name" :title="getString(each.name)">
              {{ getString(each.name) }}
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="abstractMenu">
        <div
          v-for="each in abstractMenuData"
          :key="each.id"
          @click="checkMenuIconElement(each.name)"
        >
          <div class="each-menu">
            <img :src="each.iconUrl" class="each-icon" alt="" />
            <div class="each-icon-name" :title="getString(each.name)">
              {{ getString(each.name) }}
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="showAssMapLink">
        <div
          v-for="each in AssociationMapData"
          :key="each.id"
          @click="checkMenuIconElement(each.name)"
        >
          <div class="each-menu">
            <img :src="each.iconUrl" class="each-icon" alt="" />
            <div class="each-icon-name" :title="getString(each.name)">
              {{ getString(each.name) }}
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="moreMenu">
        <div
          v-for="each in moreMenuData"
          :key="each.id"
          @click="checkMenuIconElement(each.name)"
        >
          <div class="each-menu">
            <img :src="each.iconUrl" class="each-icon" alt="" />
            <div
              class="each-icon-name"
              v-if="
                each.resName == undefined ||
                each.resName == null ||
                each.resName == ''
              "
              :title="getString(each.name)"
            >
              {{ getString(each.name) }}
            </div>
            <div class="each-icon-name" :title="each.resName" v-else>
              {{ each.resName }}
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="showResourceModel">
        <div
          v-for="each in resourceData"
          :key="each.id"
          @click="checkMenuIconElement(each.name)"
        >
          <div class="each-menu">
            <img :src="each.iconUrl" class="each-icon" alt="" />
            <div class="each-icon-name" :title="getString(each.name)">
              {{ getString(each.name) }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import ComplieChildMenuForm from "../ComplieRightMenuChild/ComplieChildMenuForm";
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
export default {
  name: "ComplieLeftContentMenu",
  props: [
    "showElementIconMenuList",
    "newMenuY",
    "newMenuX",
    "showLinkMenu",
    "abstractMenu",
    "moreMenu",
    "showAssMapLink",
    "moreNodeId",
    "showResourceModel",
    "MindPageType",
  ],
  data() {
    return {
      showElementMenuListData: [], // 菜单里面的数据
      iconMenuData: [
        {
          id: 1,
          name: strings.Mind_Edit_Node_Menu_Icon_Style,
          iconUrl: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_icon_af.svg"),
        },
        {
          id: 11,
          name: strings.Mind_Edit_Node_Menu_Delete_Icon,
          iconUrl: require("../../../assets/img/canvasMenu/canvasMenu9.svg"),
        },
      ],
      linkMenuData: [
        {
          id: 2,
          name: strings.Mind_Edit_Node_Menu_Open_Link,
          iconUrl: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_icon_af.svg"),
        },
        {
          id: 12,
          name: strings.Mind_Edit_Node_Menu_Delete_Link,
          iconUrl: require("../../../assets/img/canvasMenu/canvasMenu9.svg"),
        },
      ],
      abstractMenuData: [
        {
          id: 3,
          name: strings.Mind_Edit_Node_Menu_Edit_Remarks,
          iconUrl: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_icon_af.svg"),
        },
        {
          id: 13,
          name: strings.Mind_Edit_Node_Menu_Delete_Remarks,
          iconUrl: require("../../../assets/img/canvasMenu/canvasMenu9.svg"),
        },
      ],
      AssociationMapData: [
        {
          id: 4,
          name: strings.Mind_Association_Map_See,
          iconUrl: require("../../../assets/img/canvasMenu/canvasMenu6.svg"),
        },
        {
          id: 14,
          name: strings.Mind_Association_Map_Delete,
          iconUrl: require("../../../assets/img/canvasMenu/canvasMenu9.svg"),
        },
      ],
      resourceData: [
        {
          id: 9,
          name: strings.Mind_Resource_See,
          iconUrl: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_enclosure.svg"),
        },
        {
          id: 19,
          name: strings.Mind_Resource_Delete,
          iconUrl: require("../../../assets/img/canvasMenu/canvasMenu9.svg"),
        },
      ],
      moreMenuData: [],
      strings: strings,
    };
  },
  created() {},
  components: {
    ComplieChildMenuForm,
  },
  methods: {
    ...mapMutations(["showCropperImg", "showCropperImgUrl"]),
    getString(i) {
      return getString(i);
    },
    checkMenuIconElement(name) {
      let obj = {};
      obj.name = name;
      this.$emit("checkMenuIconElement", obj);
    },
  },
  watch: {
    showLinkMenu(val) {
      // console.log('val',val)
    },
    moreNodeId(newVal) {
      let moreNode = EditMindmapVM.getNodeById(newVal);
      // console.log(newVal);
      if (!moreNode.isEmpty()) {
        if (moreNode.value.remarksElementContent != null) {
          let obj = {};
          obj.name = strings.Mind_Edit_Node_Menu_Edit_Remarks;
          obj.id = 5;
          obj.iconUrl = require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_icon_af.svg");
          this.moreMenuData.push(obj);
          let objDel = {};
          objDel.id = 15;
          objDel.name = strings.Mind_Edit_Node_Menu_Delete_Remarks;
          objDel.iconUrl = require("../../../assets/img/canvasMenu/canvasMenu9.svg");
          if (
            this.$route.name == "s" ||
            this.$route.name == "c" ||
            (this.$route.name == "arvhiveMap" &&
              this.MindPageType != "arvhiveMapEdit") ||
            (this.$route.name == "gmap" && this.MindPageType != "groupMapEdit")
          ) {
          } else {
            this.moreMenuData.push(objDel);
          }
          // if(this.MindPageType=="arvhiveMapEdit" || this.MindPageType == "groupMapEdit"){

          // }
        }
        if (moreNode.value.linkElementContent != null) {
          let obj = {};
          obj.name = strings.Mind_Edit_Node_Menu_Open_Link;
          obj.id = 6;
          obj.iconUrl = require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_icon_af.svg");
          this.moreMenuData.push(obj);
          let objDel = {};
          objDel.id = 16;
          objDel.name = strings.Mind_Edit_Node_Menu_Delete_Link;
          objDel.iconUrl = require("../../../assets/img/canvasMenu/canvasMenu9.svg");
          if (
            this.$route.name == "s" ||
            this.$route.name == "c" ||
            (this.$route.name == "arvhiveMap" &&
              this.MindPageType != "arvhiveMapEdit") ||
            (this.$route.name == "gmap" && this.MindPageType != "groupMapEdit")
          ) {
          } else {
            this.moreMenuData.push(objDel);
          }
        }
        if (moreNode.value.connectMapElementContent != null) {
          let obj = {};
          obj.name = strings.Mind_Association_Map_See;
          obj.id = 7;
          obj.iconUrl = require("../../../assets/img/canvasMenu/canvasMenu6.svg");
          this.moreMenuData.push(obj);
          let objDel = {};
          objDel.id = 17;
          objDel.name = strings.Mind_Association_Map_Delete;
          objDel.iconUrl = require("../../../assets/img/canvasMenu/canvasMenu9.svg");
          if (
            this.$route.name == "s" ||
            this.$route.name == "c" ||
            (this.$route.name == "arvhiveMap" &&
              this.MindPageType != "arvhiveMapEdit") ||
            (this.$route.name == "gmap" && this.MindPageType != "groupMapEdit")
          ) {
          } else {
            this.moreMenuData.push(objDel);
          }
        }
        if (moreNode.value.resourceContent != null) {
          let obj = {};
          obj.name = strings.Mind_Resource_See;
          obj.id = 8;
          obj.resName = decodeURIComponent(moreNode.value.resourceContent.name);

          obj.iconUrl = require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_enclosure.svg");
          this.moreMenuData.push(obj);
          let objDel = {};
          objDel.name = strings.Mind_Resource_Delete;
          objDel.id = 18;
          objDel.iconUrl = require("../../../assets/img/canvasMenu/canvasMenu9.svg");
          if (
            this.$route.name == "s" ||
            this.$route.name == "c" ||
            (this.$route.name == "arvhiveMap" &&
              this.MindPageType != "arvhiveMapEdit") ||
            (this.$route.name == "gmap" && this.MindPageType != "groupMapEdit")
          ) {
          } else {
            this.moreMenuData.push(objDel);
          }
        }
      }
    },
    moreMenu(newVal) {
      if (!newVal) {
        this.moreMenuData = [];
      }
    },
  },
};
</script>

<style lang='less' scoped>
/* 元素菜单列表开始 */
.element-icon-menu-list {
  // width: 150px;
  // height: 300px;
  position: absolute;
  left: 200px;
  top: 300px;
  z-index: 999;
  background-color: #fff;
  user-select: "none";
  box-shadow: 0px 3px 10px rgba(80, 80, 80, 0.16);
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  .hiddenImgInput {
    display: none;
  }

  .element-menu-children-list {
    border-bottom: 1px solid #ddd8d8;
    .each-menu {
      display: flex;
      align-items: center;
      width: 160px;
      height: 28px;
      cursor: pointer;
      position: relative;
      .checkMindShape {
        display: none;
        position: absolute;
        left: 160px;
        top: 0;
        background-color: #fff;
      }

      .each-icon {
        width: 17px;
        height: 17px;
        display: block;
        margin-left: 20px;
      }
      .each-icon-name {
        margin-left: 11px;
        font-size: 13px;
        color: #292929;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &:hover {
        background-color: #e0e0e0;
      }
      &:hover .checkMindShape {
        display: block;
        z-index: 999;
      }
    }

    &:last-child {
      border-bottom: transparent;
    }
  }
}
/* 元素菜单列表结束 */
</style>