import LineElementContent from "../../mindelementdata/mindcontent/LineElementContent"
import CGPoint from "../base/basedata/CGPoint";
import TextContent from "../../mindelementdata/mindcontent/TextContent";
import GeneralizationContent from "../../mindelementdata/mindcontent/GeneralizationContent";
import MindElementData from "../../mindelementdata/MindElementData";
import StyleModelTyle from "../../datatype/StyleModelTyle";
import MindType from "../../datatype/MindType";
import NodeLayoutType from "../../datatype/NodeLayoutType";
import LineLayout from "../../datatype/LineLayout";
import Colors from "../../../utils/Colors";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/8/9
 * Copyright(c) 2020 mindyushu.com
 */

class StyleModelCellData {

    constructor() {
        this.INVALID_ID = -1;

        this.id = -1;
        this.dbId = -1;
        this.previewUrl = "";
        this.styleModelTyle = StyleModelTyle.Normal;

        this.mindType = MindType.LINE_MAP;
        this.globalLayout = NodeLayoutType.LAYOUT_RIGHT;
        this.globalLineLayout = LineLayout.CURVE_LINE_2;
        this.mindBGColor = Colors.black;

        this.rootData = new MindElementData().emptyMindNode();
        this.subjectDatas = new Array();
        this.subjectLineData = new Array();
        this.sonSubjectData = new MindElementData().emptyMindNode();

        this.generalizationData = new MindElementData().emptyMindNode();
        this.generalizationLineData = new MindElementData().emptyMindNode();
        this.nodeConnectLineData = new MindElementData().emptyMindNode();

        this.freeNodes = new MindElementData().emptyMindNode();
        this.freeSonSubjectNodes = new MindElementData().emptyMindNode();
        this.freeNodesLine = new MindElementData().emptyMindNode();
        this.encircleData = new MindElementData().emptyMindNode();
    }



    isEmpty() {
        return this.id == new StyleModelCellData().INVALID_ID;
    }

    empty() {
        return new StyleModelCellData();
    }

    setEncircleDataStyle(value) {
        this.encircleData.id = 1;
        this.encircleData.lineContent = new LineElementContent(new CGPoint(), new CGPoint(), 0, -1);
        this.encircleData.lineContent.textContent = new TextContent("");
        this.encircleData.stickStyle(value);
    }

    setFreeNodesStyle(value) {
        this.freeNodes.id = 1;
        this.freeNodes.textContent = new TextContent("");
        this.freeNodes.stickStyle(value);
    }

    setFreeSonSubjectNodesStyle(value) {
        this.freeSonSubjectNodes.id = 1;
        this.freeSonSubjectNodes.textContent = new TextContent("");
        this.freeSonSubjectNodes.stickStyle(value);
    }

    setFreeNodesLineStyle(value) {
        this.freeNodesLine.id = 1;
        this.freeNodesLine.lineContent = new LineElementContent(new CGPoint(), new CGPoint(), 0, -1);
        this.freeNodesLine.stickStyle(value);
    }

    setGeneralizationDataStyle(value) {
        this.generalizationData.id = 1;
        this.generalizationData.generalizationContent = new GeneralizationContent("");
        this.generalizationData.stickStyle(value);
    }

    setGeneralizationLineDataStyle(value) {
        this.generalizationLineData.id = 1;
        this.generalizationLineData.lineContent = new LineElementContent(new CGPoint(), new CGPoint(), 0, -1);
        this.generalizationLineData.stickStyle(value);
    }

    setNodeConnectLineDataStyle(value) {
        this.nodeConnectLineData.id = 1;
        this.nodeConnectLineData.lineContent = new LineElementContent(new CGPoint(), new CGPoint(), 0, -1);
        this.nodeConnectLineData.lineContent.textContent = new TextContent("");
        this.nodeConnectLineData.stickStyle(value);
    }

    setRootDataStyle(value) {
        this.rootData.id = 1;
        this.rootData.textContent = new TextContent("");
        this.rootData.stickStyle(value);
    }

    setSubjectDataStyle(value, index) {
        if (arguments.length == 1) {
            index = -1
        }
        if ((index < 0 || this.subjectDatas.length < index + 1)) {
            let subject = new MindElementData().emptyMindNode();
            subject.id = 1;
            subject.textContent = new TextContent("");
            subject.stickStyle(value);
            this.subjectDatas.push(subject);
        } else {
            let subject = this.subjectDatas[index];
            subject.id = 1;
            subject.textContent = new TextContent("");
            subject.stickStyle(value);
        }
    }

    setSubjectLineDataStyle(value, index) {
        if (arguments.length == 1) {
            index = -1
        }
        if (index < 0 || this.subjectLineData.length < index + 1) {
            let line = new MindElementData().emptyMindNode();
            line.id = 1;
            line.lineContent = new LineElementContent(new CGPoint(), new CGPoint(), 0, -1);
            line.stickStyle(value);
            this.subjectLineData.push(line);
        } else {
            let line = this.subjectLineData[index];
            line.id = 1;
            line.lineContent = new LineElementContent(new CGPoint(), new CGPoint(), 0, -1);
            line.stickStyle(value);
        }
    }

    setSonSubjectDataStyle(value) {
        this.sonSubjectData.id = 1;
        this.sonSubjectData.textContent = new TextContent("");
        this.sonSubjectData.stickStyle(value);
    }

    format(vaule) {
        let mind = JSON.parse(vaule)
        if (mind != null) {
            return mind;
        }
        return new StyleModelCellData();
    }

    toJSONString() {
        let nodeStr = JSON.stringify(this);
        return nodeStr;
    }
}

export default StyleModelCellData