/**
 * ProjectName: MindMap
 * Created by tony on 9/9/21
 * Copyright(c) 2020 mindyushu.com
 */

import Config from "../../../core/core/calcule/Config";
import Colors from "../../../utils/Colors";
import UiUtil from "../../../utils/UiUtil";
import Util from "../../../utils/Util";
import Point from "../../../viewmodel/core/base/Point";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
import PriorityView from "./PriorityView";
import TaskProgressView from "./TaskProgressView";

class TaskLabelView {

    constructor(context, MindElementData,mindTextSizeChangedDelegate) {
        this.context = context;
        this.data = MindElementData;
        this.mindTextSizeChangedDelegate = mindTextSizeChangedDelegate;
        this.label;
        this.explain;
        this.contentView;
        this.priorityView;
        this.bottomProgressView;
        this.progressView;
        this.taskProgressView;
        this.priorityView;
        this.foreignObjectBox;
        this.foreignObjectBoxRight;
        this.bottomProgressViewHeight = new UiUtil().dip2px(3);
        this.progressViewHeight = new UiUtil().dip2px(3);
        this.cellSize = Config.MindTaskPriorityCellSize;
        this.cellSpace = Config.MindTaskPriorityCellSpace;
        this.initView();
    }

    initView() {
        if (this.data.taskContent == null) {
            return;
        }
        this.foreignObjectBox = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
        this.foreignObjectBoxRight = document.createElement("div");
        this.label = document.createElement("div");
        this.explain = document.createElement("div");
        // this.contentView = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
        this.contentView = document.createElement("div");
        this.bottomProgressView = document.createElement("div");
        this.progressView = document.createElement("div");
        this.taskProgressView = new TaskProgressView(this.foreignObjectBox, this.data,this.mindTextSizeChangedDelegate);

        this.foreignObjectBoxRight.appendChild(this.label);
        this.foreignObjectBoxRight.appendChild(this.contentView);
        this.contentView.appendChild(this.bottomProgressView);
        this.priorityView = new PriorityView(this.contentView, this.data,this.mindTextSizeChangedDelegate);
        this.bottomProgressView.appendChild(this.progressView);
        this.foreignObjectBoxRight.appendChild(this.explain);
        this.foreignObjectBox.appendChild(this.foreignObjectBoxRight);
        this.context.appendChild(this.foreignObjectBox);

        // this.foreignObjectBoxRight.addEventListener('click', (ev)=>{
        //     window.event ? window.event.cancelBubble = true : ev.stopPropagation();
        // });

        this.label.addEventListener('mouseup', (ev)=>{
            if (ev.button == 2) {
            window.event ? window.event.cancelBubble = true : ev.stopPropagation();
            this.mindTextSizeChangedDelegate.onSelected(this.data, new Point(-1, -1), { type: 'editClick' }, ev);
 
            }
        });
        this.explain.addEventListener('mouseup', (ev)=>{
            if (ev.button == 2) {
            window.event ? window.event.cancelBubble = true : ev.stopPropagation();
            this.mindTextSizeChangedDelegate.onSelected(this.data, new Point(-1, -1), { type: 'editClick' }, ev);
            }
        });
        this.bottomProgressView.addEventListener('mouseup', (ev)=>{
            if (ev.button == 2) {
            window.event ? window.event.cancelBubble = true : ev.stopPropagation();
            this.mindTextSizeChangedDelegate.onSelected(this.data, new Point(-1, -1), { type: 'editClick' }, ev);
            }
        });
    }

    setPosition(width, height, left, top) {
        if (this.data.taskContent == null) {
            this.foreignObjectBox.innerHTML = null;
            this.foreignObjectBox.setAttribute('width', width);
            this.foreignObjectBox.setAttribute('height', height);
            this.foreignObjectBox.setAttribute('x', left);
            this.foreignObjectBox.setAttribute('y', top);
            return;
        }
        let bgColor = 0;
        if (!Colors.isClear(this.data.backgroundColor)) {
            bgColor = this.data.backgroundColor;
        } else {
            bgColor = EditMindmapVM.mindBGColor;
        }
        let isDarkColor = Colors.isDarkColor(bgColor);
        let newTime = Util.getCurrentTimeInsecond();
        let taskProgressColor = Colors.progressColor;
        let textColor = Colors.getUiColorByAlpha(Colors.black60, 0.6)
        let taskBottomProgressColor = Colors.getUiColorByAlpha (Colors.black50, 0.4)
        if (isDarkColor) {
            taskBottomProgressColor = Colors.getUiColorByAlpha (Colors.white, 0.69);
            textColor = Colors.getUiColorByAlpha(Colors.white, 0.8)
        }

        if (this.data.taskContent.taskProgress >= 100 ||
            (this.data.taskContent.endTime <= 0 || this.data.taskContent.endTime > Util.getCurrentTimeInsecond())) {
            taskProgressColor = isDarkColor ? Colors.getUiColorByAlpha(Colors.progressDarkColorColor, 0.6) : Colors.getUiColorByAlpha(Colors.progressColor, 0.85);
        } else {
            taskProgressColor = isDarkColor ? Colors.getUiColorByAlpha(Colors.progressVigilanceDarkColorColor, 0.49) : Colors.getUiColorByAlpha(Colors.progressVigilanceColor, 0.89);
        }
        this.foreignObjectBox.setAttribute('width', width);
        this.foreignObjectBox.setAttribute('height', height);
        this.foreignObjectBox.setAttribute('x', left);
        this.foreignObjectBox.setAttribute('y', top);
        this.foreignObjectBox.style.userSelect = "none";

        let labelWidht = width-height;
        let labelHeight = (height-this.bottomProgressViewHeight)/2;

        this.foreignObjectBoxRight.style.width = labelWidht + 'px';
        this.foreignObjectBoxRight.style.height = height + 'px';
        this.foreignObjectBoxRight.style.display = "inline-block";

        this.label.style.overflow = "hidden";
        this.label.style.textOverflow = "ellipsis";
        this.label.innerHTML = this.data.explain;
        this.label.style.width = (labelWidht + 1) + 'px';
        this.label.style.height = labelHeight + 'px';
        this.label.style.fontSize = Config.OutlineGeneralizationTextSize + 'px';
        this.label.style.lineHeight = labelHeight + "px";
        this.label.style.color = Colors.getUiColor(textColor);
        this.label.style.backgroundColor = Colors.getUiColor(Colors.clear);
        this.label.innerText = this.data.taskContent.getContentText();

        this.contentView.style.height = this.bottomProgressViewHeight + 'px';
        this.contentView.style.width = labelWidht + 'px';
        this.contentView.style.lineHeight = this.bottomProgressViewHeight + "px";

        // this.contentView.setAttribute('width', labelWidht);
        // this.contentView.setAttribute('height', this.bottomProgressViewHeight);
        // this.contentView.setAttribute('x', left);
        // this.contentView.setAttribute('y', top);
        // this.contentView.style.userSelect = "none";

        // this.priorityView
        let bottomProgressViewWidht = labelWidht - 20;
        this.bottomProgressView.style.height = this.bottomProgressViewHeight + 'px';
        this.bottomProgressView.style.width = bottomProgressViewWidht + 'px';
        this.bottomProgressView.style.lineHeight = this.bottomProgressViewHeight + "px";
        this.bottomProgressView.style.backgroundColor = Colors.getUiColor(taskBottomProgressColor);
        this.bottomProgressView.style.borderRadius = this.bottomProgressViewHeight + "px";
        
        let progressViewWidht;
        if(newTime >= this.data.taskContent.endTime){
            progressViewWidht = bottomProgressViewWidht;
        }else if(newTime <= this.data.taskContent.startTime || this.data.taskContent.startTime <= 0){
            progressViewWidht = 0;
        }else if(newTime > this.data.taskContent.startTime && newTime < this.data.taskContent.endTime){
            let time1 = this.data.taskContent.endTime - this.data.taskContent.startTime;
            let time2 = newTime - this.data.taskContent.startTime;
            progressViewWidht = bottomProgressViewWidht*(time2/time1);
        }
        this.progressView.style.height = this.progressViewHeight + 'px';
        this.progressView.style.width = progressViewWidht + 'px';
        this.progressView.style.backgroundColor = Colors.getUiColor(taskProgressColor);
        this.progressView.style.borderRadius = this.bottomProgressViewHeight + "px";

        this.explain.style.overflow = "hidden";
        this.explain.style.textOverflow = "ellipsis";
        this.explain.innerHTML = this.data.explain;
        this.explain.style.width = (labelWidht + 2) + 'px';
        this.explain.style.height = labelHeight + 'px';
        this.explain.style.fontSize = Config.OutlineGeneralizationTextSize + 'px';
        // this.explain.style.display = "inline-block";
        this.explain.style.lineHeight = labelHeight + "px";
        this.explain.style.color = Colors.getUiColor(textColor);
        this.explain.style.backgroundColor = Colors.getUiColor(Colors.clear);
        this.explain.innerText = this.data.taskContent.getExplain();

    }

    refreshView(data,width, height, left, top) {
        this.data = data;
        if (data.taskContent == null) {
            return;
        }
        this.setPosition(width, height, left, top);
        this.taskProgressView.refreshView();
        this.priorityView.refreshView();

    }

    remove() {
        if (this.foreignObjectBox != null && this.foreignObjectBox.parentNode != null) {
            this.foreignObjectBox.parentNode.removeChild(this.foreignObjectBox);
            this.foreignObjectBox = null;
        }
    }

    
}

export default TaskLabelView

