<template>
  <div class="login-content-box">
    
    <div class="login-right">
      <div class="row">
        <div class="login-subtext">首次登录请绑定手机号</div>
        <div class="user-info" v-if="thirdPartyLoginUser">
           <img class="user-profile" :src="thirdPartyLoginUser.headPortrait" alt="" width="44px" height="44px" />
           <div class="user-name">
            {{thirdPartyLoginUser.userName}}
          </div>
        </div>
        <div class="lright-cont">
          <div class="login-content">
            <div class="login">
              <a-form :form="form" @submit="handleSubmitLogin">
                <div class="drop-down d-quhao">
                  <img
                    src="../../../assets/img/code/phone_number_logo.png"
                    alt=""
                    style="float: left; margin-top: 9px"
                  />
                  <country-code-selector
                    :countryCode.sync="countryCode"
                    class="qudeng"
                    style="margin-left: 17px"
                  ></country-code-selector>
                </div>
                <div class="modal-backdrop fade in" style="z-index: 1"></div>
                <a-form-item>
                  <a-input
                    class="login-phone-number"
                    oninput="if(value.length>11)value=value.slice(0,11);value=value.replace(/[^\d]/g,'')"
                    :placeholder="
                      getString(strings.User_Lolgin_Please_Enter_Phone_Number)
                    "
                    @blur="phoneNumberBlurForValidate"
                    ref="phoneNumber"
                    id="kodeid"
                    allow-clear
                    v-decorator="[
                      'phoneNumber',
                      {
                        rules: [{ validator: phoneNumber }],
                      },
                    ]"
                  >
                  </a-input>
                </a-form-item>
                <div class="drop-down">
                  <img src="../../../assets/img/code/captcha1220.png" alt="" />
                </div>
                <a-form-item>
                  <div class="dr-agroup">
                    <a-input
                      allow-clear
                      oninput="if(value.length>6)value=value.slice(0,6);value=value.replace(/[^\d]/g,'')"
                      :placeholder="
                        getString(
                          strings.User_Lolgin_Please_Enter_Verification_Code
                        )
                      "
                      ref="CodeValue"
                      v-decorator="[
                        'captcha',
                        {
                          rules: [
                            {
                              required: true,
                              message: getString(
                                strings.Input_Please_Enter_Verification_Code
                              ),
                            },
                            {
                              validator: validataToCode,
                            },
                          ],
                        },
                      ]"
                    >
                    </a-input>
                    <div @click="handleSubmitCode">
                      <a-button
                        class="dra-btn scode"
                        disabled
                        v-if="showTimeData"
                      >
                        {{ timeData }}
                        <span>s</span>
                      </a-button>
                      <a-button class="dra-btn" v-else>{{
                        getString(strings.User_Lolgin_Obtain)
                      }}</a-button>
                    </div>
                  </div>
                </a-form-item>
                <section class="enter-invitation-code" v-if="enterInvitationCode">
                  <span @click="enterInvitationCode=!enterInvitationCode"> {{ getString(strings.Mind_Group_Enter_Invitation_Code) }}</span>
                </section>
                <section v-else>
                  <div class="drop-down">
                    <img src="../../../assets/img/code/Invitation_code_logo.png" alt="" />
                  </div>
                  <div class="drop-down-before">{{getString(strings.User_Lolgin_Optional)}}</div>
                  <a-form-item style="margin-bottom: 0;">
                    <div class="dr-agroup">
                      <a-input allow-clear :placeholder="
                                getString(
                                  strings.User_Lolgin_Please_Enter_Invitation_Code
                                )
                              " ref="invitation" v-decorator="[
                                'invitation',
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: getString(
                                        strings.User_Lolgin_Please_Correct_Enter_Invitation_Code
                                      ),
                                    },
                                    {
                                      validator: invitationToCode,
                                    },
                                  ],
                                },
                                maxLength=12
                              ]">
                      </a-input>
                    </div>
                  </a-form-item>
                </section>
                <a-form-item v-bind="tailFormItemLayout">
                  <a-button
                    class="Login-Button"
                    type="primary"
                    html-type="submit"
                  >
                    确定
                  </a-button>
                </a-form-item>
              </a-form>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countryCodeSelector from "vue-country-code-selector";
import { mapMutations, mapState } from "vuex";
import {
  postUserLogin,
  postUserCaptcha,
  postUserMe,
} from "../../../common/netWork/base_api";
import strings from "../../../common/lang/strings";
import getString from "../../../common/lang/language";
import { doLogin, setJwt } from "../../../common/netWork/base";
import sign from "../../../assets/css/login/sign.less";
import regExp from "../regular.js";
const { phoneNumberRegExp } = regExp;

export default {
  name: "Verifications",
  inject: ['reload'],
  props: {
    thirdPartyLoginUser: Object,
    currentThirdPartyCode: {
      type: Object,
      defualt() {
        return {};
      },
    },
  },
  data() {
    return {
      kodess: "",
      confirmDirty: false,
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      },
      tailFormItemLayout: {
        wrapperCol: {},
      },
      SignInData: {
        areaCode: "",
        phoneNumber: "",
        invitation:""
      },
      ShowCode: true,
      timeData: "",
      showTimeData: false,
      countryCode: 86,
      phoneNumberValidateCallback: null,
      strings: strings,
      enterInvitationCode: true, //输入邀请码
      bindingMobileInfo:{},//要绑定手机号的信息
    };
  },
  components: {
    //国家区号
    countryCodeSelector,
  },
  beforeCreate() {
    //验证
    this.form = this.$form.createForm(this, { name: "register" });
  },
  created() {},
  mounted() {
    let phoneCode = window.localStorage.getItem("uponenuber");
    if (
      phoneCode != "" &&
      phoneCode != null &&
      phoneCode != undefined &&
      phoneCode != "undefined"
    ) {
      this.form.setFieldsValue({
        phoneNumber: phoneCode,
      });

      window.localStorage.setItem("uponenuber", "");
    }
   
    // this.bus.$on("BindingMobileInfo", (msg) => {
    //   this.bindingMobileInfo = msg;
    //   

    // });
    const bindingMobileInfo = JSON.parse(sessionStorage.getItem('BindingMobileInfo'));
    if (!!bindingMobileInfo) {
      this.bindingMobileInfo=bindingMobileInfo;
    }

  },
  methods: {
    ...mapMutations([
      "changeLogin",
      "userLogin",
      "setUserIfLogin",
      "showLoginModel",
      "setRefreshMyGroupList"
    ]),
    getString(i) {
      return getString(i);
    },
    loginRequest(obj) {
      let that = this;
      return new Promise(function (resolve, reject) {
        postUserLogin(
          obj,
          (res) => {
            doLogin(res);
            resolve(res);
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
          }
        );
      });
    },
    userMessage(obj = {}) {
      let that = this;
      return new Promise(function (resolve, reject) {
        postUserMe(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
          }
        );
      });
    },
    onClick({ key }) {
      this.conters = key;
    },
    // 获取验证码
    handleSubmitCode(e) {
      this.SignInData.areaCode = "+" + this.countryCode;
      this.SignInData.phoneNumber = this.$refs.phoneNumber.value;
      const SignInData = this.SignInData;
      // console.log(SignInData);
      this.form.validateFieldsAndScroll(
        ["phoneNumber"],
        {
          first: true,
        },
        (err, values) => {
          if (!err) {
            postUserCaptcha(
              SignInData,
              (res) => {
                if (res == false) {
                  this.showTimeData = res;
                } else {
                  this.showTimeData = true;
                  this.timeData = 60;
                  let countDown = setInterval(() => {
                    if (this.timeData < 1) {
                      this.timeData = 0;
                      this.showTimeData = false;
                      clearInterval(countDown);
                    } else {
                      this.timeData--;
                    }
                  }, 1000);
                }
              },
              (error) => {
                this.$message.error(error.desc);
              }
            );
          }
        }
      );
    },

    // 登录
    handleSubmitLogin(e) {
      e.preventDefault();
      this.SignInData.areaCode = "+" + this.countryCode;
      this.SignInData.phoneNumber = this.$refs.phoneNumber.value;
      //输入邀请码
      if (!this.enterInvitationCode) {
        this.SignInData.invitation = this.$refs.invitation.value;
      }
      const SignInData = this.SignInData;
      // console.log(SignInData);
      var _this = this;
      this.form.validateFieldsAndScroll(
        ["phoneNumber", "captcha"],
        { first: true },
        (err, values) => {
          if (!err) {
            values.areaCode = this.SignInData.areaCode;
            if (!this.enterInvitationCode) {
              values.invitationCode = this.$refs.invitation.value;
            }
            // console.log(this.bindingMobileInfo);
            values.type = this.bindingMobileInfo.type;
            values.accessToken = this.bindingMobileInfo.accessToken;
            values.avatar = '';
            values.username = '';
            values.openId = '';
            // console.log(values);

            this.loginRequest(values)
              .then((data) => {
                setJwt(data.jwt);
                this.$message.success(this.currentThirdPartyCode.label+getString(strings.Message_Tips_Scan_Code_Login_Success));
                if (data.hasPassword) {//是否设置过密码，如果没有设置则弹出“设置密码”界面
                  this.$emit('loginSucceededFun');
                }else{
                  this.$emit('loginSucceededFun');
                  // sessionStorage.setItem('SetPassworldMobile',values.phoneNumber);
                  setTimeout(() => {
                    this.showLoginModel(true);
                  }, 100);
                  return
                }
               
                return this.userMessage();
              })
              .then((data) => {
                this.showLoginModel(false);
                this.setUserIfLogin(true);
                this.$emit("ShowHome", false);
                //刷新群组页面信息
                this.setRefreshMyGroupList();
              });
          }
        }
      );
    },
    // 失去焦点
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },

    // 登录注册切换

    ClickLoginShow() {
      this.$emit("ClickLoginShow", "Sign");
    },
    // 忘记密码
    ForgetPassWorld() {
      this.$emit("ClickLoginShow", "FindPassworld");
    },
    //密码登录
    ForgetLogin() {
      this.$emit("ClickLoginShow", "Login");
    },
    // 手机号验证
    phoneNumber(rule, value, callback) {
      this.phoneNumberValidateCallback = callback;
      if (value.substring(0, 2) == 90) {
        callback();
        return;
      }
      if ("" + this.countryCode == "86") {
        var iphoneNumber = phoneNumberRegExp;
        if (!(!iphoneNumber.test(value) && value)) {
          callback();
        }
      } else {
        if ((value + "").length > 4 && (value + "").length < 20) {
          callback();
        }
      }
    },
    //手机号验证焦点离开判断
    phoneNumberBlurForValidate(e) {
      let value = this.$refs.phoneNumber.value;
      if (this.phoneNumberValidateCallback == null) {
        return;
      }
      if (value.substring(0, 2) == 90) {
        this.phoneNumberValidateCallback();
        return;
      }
      if ("" + this.countryCode == "86") {
        var iphoneNumber = phoneNumberRegExp;
        if (!iphoneNumber.test(value) && value) {
          this.phoneNumberValidateCallback("请输入正确的手机号!");
        } else {
          this.phoneNumberValidateCallback();
        }
      } else {
        if ((value + "").length <= 4 || (value + "").length >= 20) {
          this.phoneNumberValidateCallback("请输入正确的手机号!");
        } else {
          this.phoneNumberValidateCallback();
        }
      }
    },
    // 验证码验证
    validataToCode(rule, value, callback) {
      const iCaptcha = /^[0-9]*$/;
      if (!iCaptcha.test(value) && value) {
        callback("验证码错误");
      } else {
        callback();
      }
    },
    //邀请码验证
    invitationToCode(rule, value, callback) {
      const iCaptcha = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
      if (!iCaptcha.test(value) && value) {
        callback("邀请码输入错误");
      } else {
        callback();
      }
    },
     
  },
  
  destroy() {
    clearInterval();
  },
};
</script>

<style scoped>
.Login-content {
  background-size: cover;
  min-height: 1920px;
  min-height: 99vh;
}

.hello {
  min-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-content-box {
  height: 505px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: inherit;
  position: relative;

background-color: #ffffff;
}
 
.login-right {
  /* padding: 1em 8.5em; */
  margin-top: -80px;
}
.login-right .row {
  width: 363px;
}
.login-subtext{
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 22px;
  color: #333333;
  position: absolute;
  top: 66px;
}
.user-info{
  width: 100%;
  margin-top: 150px;
  display: flex;
  flex-flow: column;
  align-items: center;

}
.user-profile{
  border-radius: 50%;
}
.user-name{
  margin-top: 12px;
  font-size: 14px;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 20px;
  color: #333333;
  opacity: 0.5;
}
.login-right .lright-cont {
  width: 94%;
  margin-left: 1em;
  min-width: auto;
}

.login-right .lc-titel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lc-titel h2 {
  font-size: 24px;
  font-family: Microsoft YaHei;
  line-height: 31px;
  color: #404040;
  opacity: 1;
}
.sign-a {
  color: #fd492b;
  float: right;
  margin-right: 9px;
  line-height: 45px;
}
/*QR code*/
.Code-titel {
  width: 100%;
  text-align: center;
}
 
.Code-bottom-text {
  text-align: center;
}
.Code-bottom-text p {
  margin-top: 5px;
}
 
.Code-content img {
  width: 100px;
  height: 100px;
}
.Code-box {
  width: 100px;
  margin: 0 auto;
}
.login-content {
  margin: auto;
}
.login-content .ai-group {
  width: 334px;
  height: 56px;
  padding-left: 20%;
  border-radius: 4px;
}
.login-content .pcode {
  padding-left: 13% !important;
}
.login {
  height: 100%;
  position: relative;
  margin-top: 20px;
}

.Login-Button {
  width: 334px;
  height: 44px;
  margin-top: 20px;
}
.checked-box {
  position: relative;
  top: -30px !important;
}
#register_agreement {
  border-color: red;
}
/* 更改 */
.ant-btn-primary {
  background-color: #fd492b !important;
  border-color: #fd492b !important;
  border-radius: 26px;
  font-size: 18px;
}
.ant-btn-primary:hover {
  background-color: #ff7354 !important;
  border-color: #ff7354 !important;
}
.ho-a:hover {
  color: #ff7354;
}
.password-a {
  color: #fd492b;
  float: right;
}
.password-label {
  color: #fd492b;
  float: left;
}
.dr-agroup {
  display: flex;
  width: 334px;
}
.dr-agroup .ant-input-affix-wrapper input {
  padding-left: 13%;
}
.dr-agroup .dra-btn {
  float: right;
  width: 59px;
  height: 44px;
  background: #fd492b;
  border: none;
  font-size: 16px;
  color: #fff;
  opacity: 1;
  border-radius: 4px;
  margin-left: 10px;
  padding: unset;
}
.drop-down {
  position: absolute;
  z-index: 5;
  margin-top: 10px;
  margin-left: 15px;
}
.drop-down-before {
  position: absolute;
  z-index: 5;
  margin-top: 10px;
  margin-right: 30px;
  right: 0;
  color: #b3b3b3;
}
.d-quhao {
  margin-top: 2px;
}
.flag-list-box {
  background-color: #fff !important;
  width: 304px !important;
}
.login-content .phonr {
  padding-left: 56%;
}
.ant-select-selection {
  border: none;
  background: none;
}
.login-content .dr-deon {
  margin: 8px;
  font-size: 14px;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 20px;
  color: #333333;
}
.login-content .lbtwo {
  margin-bottom: unset;
  padding-top: 20px;
}

 .enter-invitation-code {
    width: 100%;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #666666;
    text-align: right;
    cursor: pointer;
  }
  
</style>
<style lang="less" scoped>

@media screen and(min-width:250px) and(max-width:500px) {
  .login-content-box {
    height: 100%;
  }
   
  .hello {
    width: 100%;
    min-width: 0;
    .login-right {
      padding: 0;
      margin-top: 0;
      margin: 0 auto;
      width: 100%;
      .row {
        width: 100%;
      }
      .lright-cont {
        margin: 10px;
      }
    }
  }
  
  .get-back {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 2;
    font-size: 16px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  
  .login-content {
    padding-top: 0;
    .login {
      margin-top: 15px;
      .ant-form {
        width: 100%;
        .ant-form-item {
          .ant-col {
            .ant-form-item-control {
              .ant-form-item-children {
                .dr-agroup {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
    .lbtwo {
      padding-top: 0px;
      height: 23px;
    }
  }
  .Login-Button {
    height: 44x;
    margin-top: 11px;
    width: 100%;
  }
  .lc-titel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    h2 {
      line-height: 42px;
      font-size: 18px;
    }
    .lc-titel-a {
      width: auto !important;
    }
  }
  .flag-list-box {
    width: 258px !important;
  }
}
</style>
<style>
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: red;
  border: none;
  color: rgba(0, 0, 0, 0.8);
}
.ant-form-item {
  margin-bottom: 24px;
}
.login-content .ai-group:hover {
  border: 1px #555 solid;
}
.ant-input:focus {
  border: 1px #555 solid;
  box-shadow: unset;
}
</style>

