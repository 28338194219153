class HistoryData {
    constructor() {
        this.list = [];
    }

    toJSONString() {
        let json = JSON.stringify(this, function (key, val) {
            if (typeof val === "function") {
                return val + '';
            }
            return val
        });
        return json;
    }
}
export default HistoryData