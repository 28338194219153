import StyleModelCellData from "../../../viewmodel/core/minddata/StyleModelCellData";
import MindElementType from "../../../viewmodel/datatype/MindElementType";
import StyleModelTyle from "../../../viewmodel/datatype/StyleModelTyle";

class StyleManager {
    // private static StyleManager sharedInstance = null;

    constructor() {
        this.sharedInstance = null;
        this.customStyleList = new Array();
        this.normalList = new Array();
    }

    get() {
        if (this.sharedInstance == null) {
            this.sharedInstance = new StyleManager();
        }
        return this.sharedInstance;
    }

    loadStyle() {
        
    }

    importStyle( id) {
        let data = this.getStyleModelCellDataById(id, StyleModelTyle.Custom);
        if (data.isEmpty()) {
            return;
        }

        let newId = this.getNormalId();
        data.id = 666 + newId;
        data.previewUrl = "style_model_preview_" + data.id;
        data.styleModelTyle = StyleModelTyle.Normal;

        let nodes = data.toJSONString();
        if (nodes != null && nodes.length() > 0) {
        }
    }

    removeCustom( id) {
        if (this.customStyleList.length == 0) {
            return;
        }
    }

    getStyleFormatToString( id) {
        return this.getStyleFormatToString1(id, StyleModelTyle.Custom);
    }

    getStyleFormatToString1( id,  type) {
        let style =  this.getStyleModelCellDataById(id, type);
        if (style.isEmpty()) {
            return "";
        }
        let mindNodeJson = style.toJSONString();
        return mindNodeJson != null ? mindNodeJson : "";
    }

    setStyleData( data) {
        if (data.styleModelTyle == StyleModelTyle.Normal) {
//            styleList.append(data)
        } else if (data.styleModelTyle == StyleModelTyle.Custom) {
            data.id = this.getCustomId();
            this.customStyleList.push(data);
        }
    }

    setDataStyle( styleId, value) {
        let style =  this.getStyleModelCellDataById(styleId, StyleModelTyle.Custom);
        if (style.isEmpty()) {
            return;
        }
        
        switch (value.type) {
            case MindElementType.MAIN_SUBJECT:
            style.setRootDataStyle(value);
                break;
            case MindElementType.SUBJECT:
            style.setSubjectDataStyle(value);
                break;
            case MindElementType.SON_SUBJECT:
            style.setSonSubjectDataStyle(value);
                break;
            case MindElementType.CONTENT_GENERALIZATION:
            style.setGeneralizationDataStyle(value);
                break;
            case MindElementType.NODE_CONNECT_LINE:
            style.setNodeConnectLineDataStyle(value);
                break;
            case MindElementType.LINE:
            style.setSubjectLineDataStyle(value);
                break;
            case MindElementType.LEFTBRACELINE:
            style.setGeneralizationLineDataStyle(value);
                break;
            case MindElementType.BAOWEI_VIEW:
            style.setEncircleDataStyle(value);
                break;
            default:
        }
    }

    setFreeNodesStyle( styleId, value) {
        let style =  this.getStyleModelCellDataById(styleId, StyleModelTyle.Custom);
        if (style.isEmpty() || value.type == MindElementType.SON_SUBJECT) {
            return;
        }
        if (value.type == MindElementType.SUBJECT) {
            style.setFreeNodesStyle(value);
        } else if (value.type == MindElementType.SON_SUBJECT) {
            style.setFreeSonSubjectNodesStyle(value);
        }
    }

    setFreeSonSubjectNodesStyle( styleId, value) {
        let style =  this.getStyleModelCellDataById(styleId, StyleModelTyle.Custom);
        if (style.isEmpty() || value.type == MindElementType.SUBJECT) {
            return;
        }
        style.setFreeSonSubjectNodesStyle(value);
    }

    setFreeNodesLineStyle( styleId, value) {
        let style =  this.getStyleModelCellDataById(styleId, StyleModelTyle.Custom);
        if (style.isEmpty()) {
            return;
        }
        style.setFreeNodesLineStyle(value);
    }

    getStyleModelCellDataById1( id) {
        return this.getStyleModelCellDataById(id, StyleModelTyle.Normal);
    }
    
    getStyleModelCellDataById( id, type) {
        if (type == StyleModelTyle.Normal) {
            let fileName = "";
            let normalListCount = this.normalList.length
            for (var index = 0; index < normalListCount; index++) {
                let  cell = this.normalList[index];
                if (cell.id == id) {
                    fileName = cell.file;
                    break;
                }
            }
            if (fileName.length() > 0) {
                try {
                    // let inputStream = this.MindMapApplication.getSharedContext().getAssets().open("style/" + fileName + ".json");
                    // let json = new String(ByteUtil.Stream.getBytes(inputStream), "utf-8");
                    // let data = new StyleModelCellData().format(json);
                    // if (!data.isEmpty()) {
                    //     return data;
                    // }

                } catch (e) {
                    console.log(e);
                }

            }
        } else {
            let customStyleListCount = this.customStyleList.length
            for (var index = 0; index < customStyleListCount; index++) {
                let  cell = this.customStyleList[index];
                if (cell.id == id) {
                    return cell;
                }
            }
        }

        return new StyleModelCellData().empty();
    }

    getCustomId() {
        let id = 10000;
        let customStyleListCount = this.customStyleList.length
        for (var index = 0; index < customStyleListCount; index++) {
            let  cell = this.customStyleList[index];
            if (cell.id >= id) {
                id = cell.id + 1;
            }
        }
        return id;
    }

    getNormalId() {
        let id = 0;
        let normalListCount = this.normalList.length
        for (var index = 0; index < normalListCount; index++) {
            let  cell = this.normalList[index];
            if (cell.id >= id) {
                id = cell.id + 1;
            }
        }
        return id;
    }
}
export default new StyleManager()
