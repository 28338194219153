<template>
  <div v-show="showAboutProject" @click="showAboutModal(false)" class="about-modal">
      <div @click.stop="showAboutModal(true)" class="about-modal-center">
          <div class="about-header">
              <div class="about-header-left">
               <img class="about-header-icon" src="../../assets/img/code/logo02.svg" alt="" />
               <!-- <div>
                   {{ getString(strings.Mind_About) }}{{ getString(strings.About_App_Name) }}
               </div> -->
              </div>
               <div class="close-about" @click.stop="showAboutModal(false)">
                   <a-icon type="close" />
               </div>
          </div>
          <div class="about-content">
            <img class="about-content-image" src="../../assets/img/code/biglogo.png" alt="" />
            <div class="about-content-text">
                {{ getString(strings.About_App_Name) }}
            </div>
          </div>
          <div class="about-footer">
              <div>{{ getString(strings.About_App_Name) }}</div>
              <div>{{ getString(strings.Mind_Version_Number) }}{{ pac.version }} <span @click="jumpOfficialWebsite('https://www.mindyushu.com/downloads.html')" class="update-project">检测更新</span></div>
              <div>{{ getString(strings.Mind_Copy_Rights) }}</div>
          </div>
      </div>
  </div>
</template>

<script>
import getString from "../../common/lang/language";
import strings from "../../common/lang/strings";

if ( typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0 ) {
    var {ipcRenderer} = window.require('electron')
    // var ipcRenderer = window.ipcRenderer
}
export default {
    props: [
        "showAboutProject"
    ],
    data() {
        return {
            pac: '',
            strings:strings,
        }
    },
    created() {
        this.pac = require( "../../../package.json");
    },
    methods : {
        getString(i) {
            return getString(i);
        },
        showAboutModal(val,isVersion) {
            // if( !val ) {
                let req = {}
                req.show = val;
                req.isVersion = isVersion;
                this.$emit('showAboutModal',req);
            // } 
        },
        jumpOfficialWebsite(val) {
        // let a =  window.open('https://www.mindyushu.com/');
        // a.close();
         this.showAboutModal(true,true);
        // if (arguments.length == 1) {
        //     ipcRenderer.send('open-url', val);
        // }else {
        //     ipcRenderer.send('open-url', 'https://www.mindyushu.com/');
        // }
        },
    }
}
</script>

<style lang="less">
    .about-modal{
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.1);
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999;
        .about-modal-center {
            position: absolute;
            width: 556px;
            height: 404px;
            border-radius: 10px;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background-color: #fff;
            .about-header{
                display: flex;
                background: #F6F6F6;
                justify-content: space-between;
                align-items: center;
                padding-top: 10px;
                padding-left: 20px;
                padding-right: 20px;
                border-radius: 10px 10px 0 0;
                .about-header-left {
                    display: flex;
                    .about-header-icon{
                        width: 40px;
                        height: 30px;
                        margin-right: 5px;
                    }
                }
                .close-about {
                    cursor: pointer;
                }
            }
            .about-content {
                background: #F6F6F6;
                height: 250px;
                padding-top: 50px;
                .about-content-image{
                    display: block;
                    width: 98px;
                    height: 98px;
                    margin: 0 auto;
                }
                .about-content-text{
                    text-align: center;
                    font-size: 24px;
                    color: #fe3512;
                    margin-top: 21px;
                    font-weight: 700;
                }
            }
            .about-footer {
                height: 106px;
                text-align: center;
                color: #666;
                font-size: 12px;
                padding-top: 17px;
                div {
                    margin-bottom: 10px;
                }
                .update-project {
                    color: #EC705A;
                    text-decoration:underline;
                    cursor: pointer;
                }
            }
        }
    }
</style>