<template>
  <div v-show="showBox" class="big-img-box">
    <div class="big-img-list" alt="" @click="hideImg">
      <div
        v-show="!showMaterial"
        class="center-img-box"
        id="moveFrame"
        :style="{
          marginLeft: -showIndex * fullWidth + 'px',
          width: fullWidth * imgUrlList.length + 'px',
        }"
      >
        <div
          class="center-big-img"
          v-for="(item, index) in imgUrlList"
          :key="index"
          :style="{
            backgroundSize: backgroundSize,
            width: fullWidth + 'px',
          }"
          alt="">
          <img
            class="center-big-img-tag"
            :src="getImgUrl(item)"
            :key="index"
            :style="{
            }"
            alt=""
          >
        </div>
      </div>
      <div
        v-show="showMaterial"
        class="center-big-bg-img"
        :style="{
          backgroundImage: 'url(' + imgUrl + ')',
          backgroundSize: backgroundSize,
        }"
        alt=""
      ></div>
    </div>
    <div class="move-btn" v-show="!showMaterial">
      <div
        @click="pre()"
        style="float: left; cursor: pointer; margin-left: 50px; user-select:none"
      >
        <img
          style="width: 54px; user-select:none"
          src="../../../../src/assets/img/page/pre_img.svg"
          alt="上一张"
        />
      </div>
      <div
        @click="nex()"
        style="float: right; cursor: pointer; margin-right: 50px; user-select:none"
      >
        <img
          style="width: 54px; user-select:none"
          src="../../../../src/assets/img/page/nex_img.svg"
          alt="下一张"
        />
      </div>
    </div>
    <!-- <img class="center-big-img" :style="{backgroundUrl:''}" :src="imgUrl" alt=""> -->
  </div>
</template>

<script>
import HttpServerConfig from "../../../common/HttpServerConfig";
import LoadLocalXJMind from '../../../viewmodel/core/tools/mapfile/LoadLocalXJMind';
export default {
  data() {
    return {
      showBox: false,
      imgUrl: "",
      backgroundSize: "contain",
      imgUrlList: [],
      fullWidth: document.documentElement.clientWidth,
      showIndex: 0,
      showMaterial: false,
    };
  },
  created() {},
  mounted() {
    this.bus.$on("examineImg", (msg) => {
      this.showBox = true;
      if (msg.typeName == "material") {
        //素材库
        this.showMaterial = true;
        this.imgUrl = HttpServerConfig.getMediaSrc(msg.src);
      } else if (msg.imgUrlList) {
        //图片列表
        this.showMaterial = false;
        this.imgUrl = HttpServerConfig.getMediaSrc(msg.src);
        this.imgUrlList = [];
        this.imgUrlList = msg.imgUrlList;
        if (this.imgUrl && this.imgUrlList) {
          let imgurlContent = this.imgUrlList.length;
          for (var index = 0; index < imgurlContent; index++) {
            let url = HttpServerConfig.getMediaSrc(this.imgUrlList[index]);
            if (this.imgUrl == url) {
              this.showIndex = index;
              break;
            }
          }
        }
      } else {
        this.showMaterial = true;
        this.imgUrl = HttpServerConfig.getMediaSrc(msg.src);
      }
    });
  },
  methods: {
    hideImg() {
      this.showBox = false;
    },
    getImgUrl(src) {
      let url = LoadLocalXJMind.getLocalFilePath(HttpServerConfig.getMediaSrc(src));
      // console.log("getImgUrl: ", url, src);
      return url
    },
    nex() {
      if (this.imgUrlList && this.showIndex == this.imgUrlList.length - 1) {
        this.$message.error("已经是最后一张了");
        return;
      }
      this.showIndex++;
      let move = document.getElementById("moveFrame");
      move.style.marginLeft = move.style.marginLeft - this.fullWidth + "px";
    },
    pre() {
      if (this.showIndex == 0) {
        this.$message.success("当前是第一张不能再往前了");
        return;
      }
      this.showIndex--;
      let move = document.getElementById("moveFrame");
      move.style.marginLeft = move.style.marginLeft + this.fullWidth + "px";
      // document.getElementsByClassName('.center-big-img').eq(this.showIndex).animate({'margin-left':'0'},500);
    },
  },
  watch: {
    imgUrlList: {
      handler(newVal, oldVal) {
        // console.log(this.imgUrlList);
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.big-img-box {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.5);
  .big-img-list {
    position: absolute;
    width: 100%;
    height: 90%;
    left: 50%;
    top: 50%;
    overflow: hidden;
    transform: translate(-50%, -50%);

    .center-img-box {
      position: relative;
      // width: 50000px;
      height: 100%;

      .center-big-img {
        position: relative;
        height: 90%;
        background-position: center center;
        background-repeat: no-repeat;
        float: left;
        align-content: center;
        align-items: center;

        .center-big-img-tag {
          max-width: 100%;
          max-height: 100%;
          align-content: center;
          align-items: center;
          transform: translate(-50%, -50%);
          left: 50%;
          top: 50%;
          position: absolute;
          -moz-user-select: none;
          -o-user-select:none;
          -khtml-user-select:none;
          -webkit-user-select:none;
          -ms-user-select:none;
          user-select:none;
        }
      }
    }
  }
}
.move-btn {
  position: fixed;
  width: 100%;
  bottom: 50%;
}

.center-big-bg-img {
  position: absolute;
  width: 90%;
  height: 90%;
  background-position: center center;
  background-repeat: no-repeat;
  // background-size: contain;
  // height: 70%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>