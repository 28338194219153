const cmykColors = [
    {
        colorsValue: [{
            id: 1,
            cmykValue: '(0,100,100,45)',
            rgbValue: '(139,0,22)',
            hexValue: '#8b0016'
        }, {
            id: 2,
            cmykValue: '(0,100,100,25)',
            rgbValue: '(178,0,31)',
            hexValue: '#b2001f'
        }, {
            id: 3,
            cmykValue: '(0,100,100,15)',
            rgbValue: '(197,0,35)',
            hexValue: '#c50023'
        }, {
            id: 4,
            cmykValue: '(0,100,100,0)',
            rgbValue: '(223,0,41)',
            hexValue: '#df0029'
        }, {
            id: 5,
            cmykValue: '(0,85,70,0)',
            rgbValue: '(229,70,70)',
            hexValue: '#e54646'
        }, {
            id: 6,
            cmykValue: '(0,65,50,0)',
            rgbValue: '(238,124,107)',
            hexValue: '#ee7c6b'
        }, {
            id: 7,
            cmykValue: '(0,45,30,0)',
            rgbValue: '(245,168,154)',
            hexValue: '#f5a89a'
        }, {
            id: 8,
            cmykValue: '(0,20,10,0)',
            rgbValue: '(252,218,213)',
            hexValue: '#fcdad5'
        }]
    }, {
        colorsValue: [{
            id: 9,
            cmykValue: '(0,90,80,45)',
            rgbValue: '(142,30,32)',
            hexValue: '#8e1e20'
        }, {
            id: 10,
            cmykValue: '(0,90,80,25)',
            rgbValue: '(182,41,43)',
            hexValue: '#b6292b'
        }, {
            id: 11,
            cmykValue: '(0,90,80,15)',
            rgbValue: '(200,46,49)',
            hexValue: '#c82e31'
        }, {
            id: 12,
            cmykValue: '(0,90,80,0)',
            rgbValue: '(223,53,57)',
            hexValue: '#e33539'
        }, {
            id: 13,
            cmykValue: '(0,70,65,0)',
            rgbValue: '(235,113,83)',
            hexValue: '#eb7153'
        }, {
            id: 14,
            cmykValue: '(0,55,50,0)',
            rgbValue: '(241,147,115)',
            hexValue: '#f19373'
        }, {
            id: 15,
            cmykValue: '(0,40,35,0)',
            rgbValue: '(246,178,151)',
            hexValue: '#f6b297'
        }, {
            id: 16,
            cmykValue: '(0,20,20,0)',
            rgbValue: '(252,217,196)',
            hexValue: '#fcd9c4'
        }]
    }, {
        colorsValue: [{
            id: 17,
            cmykValue: '(0,60,100,45)',
            rgbValue: '(148,83,5)',
            hexValue: '#945305'
        }, {
            id: 18,
            cmykValue: '(0,60,100,25)',
            rgbValue: '(189,107,9)',
            hexValue: '#bd6b09'
        }, {
            id: 19,
            cmykValue: '(0,60,100,15)',
            rgbValue: '(208,119,11)',
            hexValue: '#d0770b'
        }, {
            id: 20,
            cmykValue: '(0,60,100,0)',
            rgbValue: '(236,135,14)',
            hexValue: '#ec870e'
        }, {
            id: 21,
            cmykValue: '(0,50,80,0)',
            rgbValue: '(240,156,66)',
            hexValue: '#f09c42'
        }, {
            id: 22,
            cmykValue: '(0,40,60,0)',
            rgbValue: '(245,177,109)',
            hexValue: '#f5b16d'
        }, {
            id: 23,
            cmykValue: '(0,25,40,0)',
            rgbValue: '(250,206,156)',
            hexValue: '#face9c'
        }, {
            id: 24,
            cmykValue: '(0,15,20,0)',
            rgbValue: '(253,226,202)',
            hexValue: '#fde2ca'
        }]
    }, {
        colorsValue: [{
            id: 25,
            cmykValue: '(0,40,100,45)',
            rgbValue: '(151,109,0)',
            hexValue: '#976d00'
        }, {
            id: 26,
            cmykValue: '(0,40,100,25)',
            rgbValue: '(193,140,0)',
            hexValue: '#c18c00'
        }, {
            id: 27,
            cmykValue: '(0,40,100,15)',
            rgbValue: '(213,155,0)',
            hexValue: '#d59800'
        }, {
            id: 28,
            cmykValue: '(0,40,100,0)',
            rgbValue: '(241,175,0)',
            hexValue: '#f1af00'
        }, {
            id: 29,
            cmykValue: '(0,30,80,0)',
            rgbValue: '(243,194,70)',
            hexValue: '#f3c246'
        }, {
            id: 30,
            cmykValue: '(0,25,60,0)',
            rgbValue: '(249,204,118)',
            hexValue: '#f9cc76'
        }, {
            id: 31,
            cmykValue: '(0,15,40,0)',
            rgbValue: '(252,224,166)',
            hexValue: '#fce0a6'
        }, {
            id: 32,
            cmykValue: '(0,10,20,0)',
            rgbValue: '(254,235,208)',
            hexValue: '#feebd0'
        }]
    }, {
        colorsValue: [{
            id: 33,
            cmykValue: '(0,0,100,45)',
            rgbValue: '(156,153,0)',
            hexValue: '#9c9900'
        }, {
            id: 34,
            cmykValue: '(0,0,100,25)',
            rgbValue: '(199,195,0)',
            hexValue: '#c7c300'
        }, {
            id: 35,
            cmykValue: '(0,0,100,15)',
            rgbValue: '(220,216,0)',
            hexValue: '#dcd800'
        }, {
            id: 36,
            cmykValue: '(0,0,100,0)',
            rgbValue: '(249,244,0)',
            hexValue: '#f9f400'
        }, {
            id: 37,
            cmykValue: '(0,0,80,0)',
            rgbValue: '(252,245,76)',
            hexValue: '#fcf54c'
        }, {
            id: 38,
            cmykValue: '(0,0,60,0)',
            rgbValue: '(254,248,134)',
            hexValue: '#fef889'
        }, {
            id: 39,
            cmykValue: '(0,0,40,0)',
            rgbValue: '(255,250,179)',
            hexValue: '#fffab3'
        }, {
            id: 40,
            cmykValue: '(0,0,25,0)',
            rgbValue: '(255,251,209)',
            hexValue: '#fffbd1'
        }]
    }, {
        colorsValue: [{
            id: 41,
            cmykValue: '(60,0,100,45)',
            rgbValue: '(54,117,23)',
            hexValue: '#367517'
        }, {
            id: 42,
            cmykValue: '(60,0,100,25)',
            rgbValue: '(72,150,32)',
            hexValue: '#489620'
        }, {
            id: 43,
            cmykValue: '(60,0,100,15)',
            rgbValue: '(80,166,37)',
            hexValue: '#50a625'
        }, {
            id: 44,
            cmykValue: '(60,0,100,0)',
            rgbValue: '(91,189,43)',
            hexValue: '#5bbd2b'
        }, {
            id: 45,
            cmykValue: '(50,0,80,0)',
            rgbValue: '(131,199,93)',
            hexValue: '#83c75d'
        }, {
            id: 46,
            cmykValue: '(35,0,60,0)',
            rgbValue: '(175,215,136)',
            hexValue: '#afd788'
        }, {
            id: 47,
            cmykValue: '(25,0,40,0)',
            rgbValue: '(200,226,177)',
            hexValue: '#c8e2b1'
        }, {
            id: 48,
            cmykValue: '(12,0,20,0)',
            rgbValue: '(230,241,216)',
            hexValue: '#e6f1d8'
        }]
    }, {
        colorsValue: [{
            id: 49,
            cmykValue: '(100,0,90,45)',
            rgbValue: '(0,98,65)',
            hexValue: '#006241'
        }, {
            id: 50,
            cmykValue: '(100,0,90,25)',
            rgbValue: '(0,127,84)',
            hexValue: '#007f54'
        }, {
            id: 51,
            cmykValue: '(100,0,90,15)',
            rgbValue: '(0,140,94)',
            hexValue: '#008c5e'
        }, {
            id: 52,
            cmykValue: '(100,0,90,0)',
            rgbValue: '(0,160,107)',
            hexValue: '#00a06b'
        }, {
            id: 53,
            cmykValue: '(80,0,75,0)',
            rgbValue: '(0,174,114)',
            hexValue: '#00ae72'
        }, {
            id: 54,
            cmykValue: '(60,0,55,0)',
            rgbValue: '(103,191,127)',
            hexValue: '#67bf7f'
        }, {
            id: 55,
            cmykValue: '(45,0,35,0)',
            rgbValue: '(152,208,185)',
            hexValue: '#98d0b9'
        }, {
            id: 56,
            cmykValue: '(25,0,20,0)',
            rgbValue: '(201,228,214)',
            hexValue: '#c9e4d6'
        }]
    }, {
        colorsValue: [{
            id: 57,
            cmykValue: '(100,0,40,45)',
            rgbValue: '(0,103,107)',
            hexValue: '#00676b'
        }, {
            id: 58,
            cmykValue: '(100,0,40,25)',
            rgbValue: '(0,132,137)',
            hexValue: '#008489'
        }, {
            id: 59,
            cmykValue: '(100,0,40,15)',
            rgbValue: '(0,146,152)',
            hexValue: '#009298'
        }, {
            id: 60,
            cmykValue: '(100,0,40,0)',
            rgbValue: '(0,166,173)',
            hexValue: '#00a6ad'
        }, {
            id: 61,
            cmykValue: '(80,0,30,0)',
            rgbValue: '(0,178,191)',
            hexValue: '#00b2bf'
        }, {
            id: 62,
            cmykValue: '(60,0,25,0)',
            rgbValue: '(110,195,201)',
            hexValue: '#6ec3c9'
        }, {
            id: 63,
            cmykValue: '(45,0,20,0)',
            rgbValue: '(153,209,211)',
            hexValue: '#99d1d3'
        }, {
            id: 64,
            cmykValue: '(25,0,10,0)',
            rgbValue: '(202,229,232)',
            hexValue: '#cae5e8'
        }]
    }, {
        colorsValue: [{
            id: 65,
            cmykValue: '(100,60,0,45)',
            rgbValue: '(16,54,103)',
            hexValue: '#103667'
        }, {
            id: 66,
            cmykValue: '(100,60,0,25)',
            rgbValue: '(24,71,133)',
            hexValue: '#184785'
        }, {
            id: 67,
            cmykValue: '(100,60,0,15)',
            rgbValue: '(27,79,147)',
            hexValue: '#1b4f93'
        }, {
            id: 68,
            cmykValue: '(100,60,0,0)',
            rgbValue: '(32,90,167)',
            hexValue: '#205aa7'
        }, {
            id: 69,
            cmykValue: '(85,50,0,0)',
            rgbValue: '(66,110,180)',
            hexValue: '#426eb4'
        }, {
            id: 70,
            cmykValue: '(65,40,0,0)',
            rgbValue: '(115,136,193)',
            hexValue: '#7388c1'
        }, {
            id: 71,
            cmykValue: '(50,25,0,0)',
            rgbValue: '(148,170,214)',
            hexValue: '#94aad6'
        }, {
            id: 72,
            cmykValue: '(30,15,0,0)',
            rgbValue: '(191,202,230)',
            hexValue: '#bfcae6'
        }]
    }, {
        colorsValue: [{
            id: 73,
            cmykValue: '(100,90,0,45)',
            rgbValue: '(33,21,81)',
            hexValue: '#211551'
        }, {
            id: 74,
            cmykValue: '(100,90,0,25)',
            rgbValue: '(45,30,105)',
            hexValue: '#2d1e69'
        }, {
            id: 75,
            cmykValue: '(100,90,0,15)',
            rgbValue: '(50,34,117)',
            hexValue: '#322275'
        }, {
            id: 76,
            cmykValue: '(100,90,0,0)',
            rgbValue: '(58,40,133)',
            hexValue: '#3a2885'
        }, {
            id: 77,
            cmykValue: '(85,80,0,0)',
            rgbValue: '(81,31,144)',
            hexValue: '#511f90'
        }, {
            id: 78,
            cmykValue: '(75,65,0,0)',
            rgbValue: '(99,91,162)',
            hexValue: '#635ba2'
        }, {
            id: 79,
            cmykValue: '(60,55,0,0)',
            rgbValue: '(130,115,176)',
            hexValue: '#8273b0'
        }, {
            id: 80,
            cmykValue: '(45,40,0,0)',
            rgbValue: '(160,149,196)',
            hexValue: '#a095c4'
        }]
    }, {
        colorsValue: [{
            id: 81,
            cmykValue: '(80,100,0,45)',
            rgbValue: '(56,4,75)',
            hexValue: '#38044b'
        }, {
            id: 82,
            cmykValue: '(80,100,0,25)',
            rgbValue: '(73,7,97)',
            hexValue: '#490761'
        }, {
            id: 83,
            cmykValue: '(80,100,0,15)',
            rgbValue: '(82,9,108)',
            hexValue: '#52096c'
        }, {
            id: 84,
            cmykValue: '(80,100,0,0)',
            rgbValue: '(93,12,123)',
            hexValue: '#5d0c7b'
        }, {
            id: 85,
            cmykValue: '(65,85,0,0)',
            rgbValue: '(121,55,139)',
            hexValue: '#79378b'
        }, {
            id: 86,
            cmykValue: '(55,65,0,0)',
            rgbValue: '(140,99,164)',
            hexValue: '#8c63a4'
        }, {
            id: 87,
            cmykValue: '(40,50,0,0)',
            rgbValue: '(170,135,184)',
            hexValue: '#aa87b8'
        }, {
            id: 88,
            cmykValue: '(25,30,0,0)',
            rgbValue: '(201,181,212)',
            hexValue: '#c9b5d4'
        }]
    }, {
        colorsValue: [{
            id: 89,
            cmykValue: '(40,100,0,45)',
            rgbValue: '(100,0,75)',
            hexValue: '#64004b'
        }, {
            id: 90,
            cmykValue: '(40,100,0,25)',
            rgbValue: '(120,0,98)',
            hexValue: '#780062'
        }, {
            id: 91,
            cmykValue: '(40,100,0,15)',
            rgbValue: '(143,0,109)',
            hexValue: '#8f006d'
        }, {
            id: 92,
            cmykValue: '(40,100,0,0)',
            rgbValue: '(162,0,124)',
            hexValue: '#a2007c'
        }, {
            id: 93,
            cmykValue: '(35,80,0,0)',
            rgbValue: '(143,0,109)',
            hexValue: '#af4a92'
        }, {
            id: 94,
            cmykValue: '(25,60,0,0)',
            rgbValue: '(197,124,172)',
            hexValue: '#c57cac'
        }, {
            id: 95,
            cmykValue: '(20,40,0,0)',
            rgbValue: '(210,166,199)',
            hexValue: '#d2a6c7'
        }, {
            id: 96,
            cmykValue: '(10,20,0,0)',
            rgbValue: '(232,211,227)',
            hexValue: '#e8e3d3'
        }]
    }, {
        colorsValue: [{
            id: 97,
            cmykValue: '(0,0,0,0)',
            rgbValue: '(255,255,255)',
            hexValue: '#ffffff'
        }, {
            id: 98,
            cmykValue: '(4,3,2,0)',
            rgbValue: '(241,241,241)',
            hexValue: '#F1F1F1'
        }, {
            id: 99,
            cmykValue: '(0,0,0,30)',
            rgbValue: '(194,194,194)',
            hexValue: '#c2c2c2'
        }, /*{
            id:100,
            cmykValue: '(0,0,0,35)',
            rgbValue: '(183,183,183)',
            hexValue: '#b7b7b7'
        },*/ {
            id: 101,
            cmykValue: '(0,0,0,45)',
            rgbValue: '(160,160,160)',
            hexValue: '#a0a0a0'
        }, /*{
            id:102,
            cmykValue: '(0,0,0,55)',
            rgbValue: '(137,137,137)',
            hexValue: '#898989'
        },*/ {
            id: 103,
            cmykValue: '(0,0,0,65)',
            rgbValue: '(112,112,112)',
            hexValue: '#707070'
        }, {
            id: 104,
            cmykValue: '(0,0,0,75)',
            rgbValue: '(85,85,85)',
            hexValue: '#555555'
        }, {
            id: 105,
            cmykValue: '(0,0,0,85)',
            rgbValue: '(54,54,54)',
            hexValue: '#363636'
        }, {
            id: 106,
            cmykValue: '(0,0,0,99)',
            rgbValue: '(2,2,2)',
            hexValue: '#020202'
        }].reverse()
    }
]
export {
    cmykColors
}