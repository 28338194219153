// package mind.yushu.com.mindmap.viewmodel.core.base;

import Colors from "../../../utils/Colors"
import LineColorMode from "./LineColorMode"
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/6
 * Copyright(c) 2020 mindyushu.com
 */

class BubbleColorMode extends LineColorMode {

    getSonSubjectViewFillColor() {
        return Colors.white;
    }

    pgetSonSubjectViewBorderWidth() {
        return 1.5;
    }

    getSonSubjectViewBorderColor() {
        return Colors.black40;
    }

}
export default BubbleColorMode
